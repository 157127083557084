type OauthState = {
  redirect_path?: string
}

export default {
  stringify(state: OauthState) {
    return JSON.stringify(state)
  },
  parse(state: string): OauthState {
    try {
      const parsed = JSON.parse(state) as unknown
      if (typeof parsed === 'object') {
        return parsed as OauthState
      }
    } catch {
      return {}
    }

    return {}
  }
}
