import {configureStore} from '@reduxjs/toolkit'
import {PERSIST, PURGE, REHYDRATE, FLUSH, PAUSE, REGISTER} from 'redux-persist'

import {listenerMiddleware} from './listenerMiddleware'
import {rootReducer} from './rootReducer'

/** Не используйте напрямую, для подписки можно использовать startAppListening */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['listenerMiddleware/']
      }
    }).prepend(listenerMiddleware.middleware)
})
