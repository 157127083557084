export enum Lock {
  BrowserHistory
}

const locks: {[k: number]: number} = {}

export function acquire(name: Lock) {
  locks[name] = locks[name] || 0
  locks[name]++
}

export function release(name: Lock) {
  locks[name]--
  if (locks[name] < 0) {
    throw new Error('Unexpected release lock')
  }
}

export function isLocked(name: Lock) {
  return !!locks[name]
}
