import {useExp3} from '@eda-restapp/configs'

import {useApiQuery} from '@restapp/core-api'

export const useRelatedPartnersQuery = (selectedPlaceIds: number[]) => {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')

  const oldAuthorsQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/partner/login/list',
    method: 'GET',
    params: {place_ids: selectedPlaceIds.map(String)},
    initialData: {logins: []},
    select: (data) => {
      return {
        partners: data.logins.map(({login, partner_id}) => ({
          email: login,
          partner_id: partner_id
        }))
      }
    },
    enabled: !supportMultiChatConfig.enableUnifiedChatsListEndpoint
  })

  const authorsQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/related_partners',
    method: 'POST',
    body: {place_ids: selectedPlaceIds.map(String)},
    initialData: {partners: []},
    enabled: supportMultiChatConfig.enableUnifiedChatsListEndpoint
  })

  return supportMultiChatConfig.enableUnifiedChatsListEndpoint ? authorsQuery : oldAuthorsQuery
}
