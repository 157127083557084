import type {CountryCodeType} from '@eda-restapp/i18n'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

type InternationalSliceState = {
  countryCode: CountryCodeType | null
}

const initialState: InternationalSliceState = {
  countryCode: null
}

export const internationalSlice = createSlice({
  name: 'international',
  initialState: () => initialState,
  reducers: {
    clearCountryCode(state) {
      state.countryCode = null
    },
    setCountryCode(state, action: PayloadAction<CountryCodeType>) {
      state.countryCode = action.payload
    }
  }
})

export const {clearCountryCode, setCountryCode} = internationalSlice.actions
