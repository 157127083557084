import {errorLogger} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import moment from 'moment'
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation, useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import {isApiError} from '@restapp/shared-api'

import {selectSurvey} from '../selectors'
import {setSurvey, setSurveyStatus} from '../slice'
import type {Survey, SurveyAnswerToApi} from '../types'
import {CommunicationStatus, CommunicationType} from '../types'
import {getAnswer, checkAnswerCanSubmit, checkIsVisiblePage} from '../utils/survey'

type UseSurveyModalOptions = {
  enabled?: boolean
}

type SurveyMetaInfo = {
  nextNearestShowDate: string
  remainingShowsCount: number
}

type SurveyMetaInfoStorageShape = {
  [surveyId: string]: SurveyMetaInfo
}

const SURVEY_META_INFO_STORAGE_KEY = 'communications-surveys'

export function useSurvey({enabled}: UseSurveyModalOptions = {}) {
  const {placesIds, selectedPlaceId} = usePlaces()
  const dispatch = useDispatch()

  const survey = useSelector(selectSurvey)

  useApiQuery({
    url: '/4.0/restapp-front/places/v1/notifications',
    method: 'POST',
    body: {services: [CommunicationType.SURVEY]},
    params: {place_id: selectedPlaceId, limit: 1},
    select: (response) => response.feed.survey?.[0],
    staleTime: 5 * 60_000,
    refetchInterval: 10 * 60_000,
    enabled: Boolean(enabled) && placesIds.length > 0,
    onSuccess: (data) => {
      if (data) {
        dispatch(setSurvey(data))
      }
    }
  })

  const submitAnswersMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications/log-status',
    method: 'POST'
  })

  const surveyMarkRead = useCallback(
    (id: string) => {
      submitAnswersMutation.mutate({
        body: {
          id,
          service: CommunicationType.SURVEY
        }
      })
      dispatch(setSurveyStatus(CommunicationStatus.READ))
    },
    [dispatch, submitAnswersMutation]
  )

  const submitAnswers = useCallback(async () => {
    if (!survey) {
      return
    }

    try {
      const answers = survey.content.pages
        .filter((page) => checkIsVisiblePage(page, survey.content.pages))
        .flatMap((page) => page.questions)
        .filter((question) => checkAnswerCanSubmit(question.answer))
        .map((question) => {
          return {
            question: question.question,
            answer: getAnswer(question.answer) as SurveyAnswerToApi
          }
        })

      await submitAnswersMutation.mutateAsync({
        body: {
          service: 'survey',
          id: survey.id,
          answers
        }
      })
      dispatch(setSurveyStatus(CommunicationStatus.READ))
    } catch (error) {
      if (isApiError(error)) {
        errorLogger({message: 'communications:log_status:survey', error})
        Snackbar.show({
          type: 'error',
          text: error.message
        })
      } else {
        if (typeof error === 'object' && error instanceof Error) {
          errorLogger({message: 'communications:submitAnswers', error})
        }
      }
    }
  }, [dispatch, submitAnswersMutation, survey])

  return useMemo(
    () => ({
      survey,
      surveyMethods: {
        submitAnswers,
        getSurveyMetaInfo,
        setSurveyMetaInfo,
        clearSurveyMetaInfo,
        checkCanShowSurvey,
        surveyMarkRead
      }
    }),
    [surveyMarkRead, submitAnswers, survey]
  )
}

const getSurveyMetaInfo = (surveyId: string): SurveyMetaInfo | null => {
  try {
    const meta = JSON.parse(
      localStorage.getItem(SURVEY_META_INFO_STORAGE_KEY) || '{}'
    ) as Partial<SurveyMetaInfoStorageShape>
    return meta[surveyId] || null
  } catch {
    return null
  }
}

const setSurveyMetaInfo = (surveyId: string, metainfo: SurveyMetaInfo): void => {
  try {
    localStorage.setItem(
      SURVEY_META_INFO_STORAGE_KEY,
      JSON.stringify({
        [surveyId]: metainfo
      })
    )
  } catch {
    return
  }
}

const clearSurveyMetaInfo = () => {
  localStorage.removeItem(SURVEY_META_INFO_STORAGE_KEY)
}

const checkCanShowSurvey = (survey: Survey, surveyMeta: SurveyMetaInfo): boolean => {
  const isCompleted = survey.status === 'read'
  const hasRemainingShows = surveyMeta.remainingShowsCount > 0
  const isIntervalPassed = moment().isSameOrAfter(moment(surveyMeta.nextNearestShowDate))

  return !isCompleted && hasRemainingShows && isIntervalPassed
}
