import {t} from '@eda-restapp/i18n'

import type {useFileUploadType} from './types'

const validateImage = (
  imgFile: File,
  restrictions: useFileUploadType['restrictions'],
  validate: useFileUploadType['validate']
) => {
  const {minWidth = 0, minHeight = 0, maxWidth, maxHeight, maxFileSize} = restrictions || {}

  const img = new Image()

  const promise = new Promise<void>((resolve, reject) => {
    img.onload = () => {
      if (validate) {
        const error = validate(imgFile, img, restrictions)
        if (error) {
          reject(error)
        } else {
          resolve()
        }
      }

      if (minWidth > img.width || minHeight > img.height) {
        reject(
          t('shared-ui.validate-image.image-size-more', 'Изображение должно быть больше чем {minWidth}x{minHeight}', {
            minWidth,
            minHeight
          })
        )
      }
      if (minWidth > img.width || minHeight > img.height) {
        reject(
          t('shared-ui.validate-image.image-size-less', 'Изображение должно быть меньше чем {maxWidth}x{maxHeight}', {
            maxWidth,
            maxHeight
          })
        )
      }
      if (maxFileSize && imgFile.size > maxFileSize) {
        reject(
          t('shared-ui.validate-image.image-size-bigger', 'Размер изображения не должен превышать {maxFileSize} MB', {
            maxFileSize: Math.ceil(maxFileSize / 1024 / 1024)
          })
        )
      }

      resolve()
    }
  })

  img.src = URL.createObjectURL(imgFile)

  return promise.then(() => URL.revokeObjectURL(img.src))
}

export default validateImage
