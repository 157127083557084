import {useMemo} from 'react'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'

const REFETCH_INTERVAL = 60 * 1000

type PlaceMap = {
  [key: number]: number
}

const useModerationStatus = () => {
  const {placesIds} = usePlaces()

  const placeHeroPhotoModerationQuery = useApiQuery({
    url: '/4.0/restapp-front/places/v1/moderation/status',
    method: 'GET',
    params: {
      place_id: placesIds
    },
    enabled: placesIds.length > 0,
    refetchInterval: REFETCH_INTERVAL,
    refetchOnSocketEvent: 'place_hero_moderated'
  })

  const menuModerationQuery = useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-menu/v1/moderation/status',
    method: 'GET',
    params: {
      place_id: placesIds
    },
    enabled: placesIds.length > 0,
    refetchInterval: REFETCH_INTERVAL
  })

  const placeErrors = useMemo(() => {
    const counts: PlaceMap = {}
    const collectCount = ({place_id: placeId, reject: {total}}: {place_id: number; reject: {total: number}}) => {
      counts[placeId] = (counts[placeId] ?? 0) + total
    }
    menuModerationQuery.data?.moderation_status.forEach(collectCount)
    placeHeroPhotoModerationQuery.data?.moderation_status.forEach(collectCount)

    return counts
  }, [menuModerationQuery.data, placeHeroPhotoModerationQuery.data])

  const placeErrorsCount = placeHeroPhotoModerationQuery.data?.total ?? 0
  const menuErrorsCount = menuModerationQuery.data?.total ?? 0
  const totalErrors = placeErrorsCount + menuErrorsCount

  return {
    hasErrors: totalErrors > 0,
    totalErrors,
    checkPlaceHasErrors: (placeId: number) => placeErrors[placeId] > 0,
    getPlaceErrorsCount: (placeId: number) => placeErrors[placeId]
  }
}

export default useModerationStatus
