import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

import {useServiceBrand} from '@restapp/shared'

export const usePassportHelpURL = () => {
  const restappAuthConfig = useExp3('restapp_auth')
  const serviceBrand = useServiceBrand()
  const {lang} = useI18n()

  let urlString = ''

  switch (serviceBrand) {
    case 'YandexEats':
      urlString = restappAuthConfig.passport.helpURL.eats
      break
    case 'YangoEats':
      urlString = restappAuthConfig.passport.helpURL.yango
      break
    case 'YandexEda':
      urlString = restappAuthConfig.passport.helpURL.eda
      break
  }

  const url = new URL(urlString)
  url.searchParams.append('lang', lang)

  return url.toString()
}
