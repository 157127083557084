import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, ChevronLeftMIcon} from '@eda-restapp/ui'
import React from 'react'

import styles from './BackButton.module.css'

type BackButtonProps = {
  onClick: () => void
}

export const BackButton: React.FC<BackButtonProps> = ({onClick}) => {
  const {t} = useI18n()

  return (
    <Box
      className={styles.container}
      p='s'
      gap='xs'
      onClick={onClick}
      alignItems='center'
      data-testid='select-partner-back'
    >
      <ChevronLeftMIcon />

      <Typography variant='caption1'>
        {t('core-auth.select-partner-uid.back', 'Назад', {
          value: 'Назад'
        })}
      </Typography>
    </Box>
  )
}
