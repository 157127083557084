import type {RefObject} from 'react'
import {useEffect, useState} from 'react'

export const useIsScrolledHorizontally = (elementRef: RefObject<Element>) => {
  const [isScrolledHorizontally, setIsScrolledHorizontally] = useState(false)

  useEffect(() => {
    const element = elementRef.current
    if (!element) {
      return
    }

    const handleHorizontalScroll = () => {
      setIsScrolledHorizontally(element.scrollLeft > 0)
    }

    element.addEventListener('scroll', handleHorizontalScroll)
    return () => element.removeEventListener('scroll', handleHorizontalScroll)
  })

  return isScrolledHorizontally
}
