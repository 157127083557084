import type {VendorApi} from '@restapp/shared-api'

import type {IApi} from '../api'
import type {ISnackbarNotifier} from '../lib/snackbar-notifier'

export interface IEnv {
  api: IApi<VendorApi>
  snackbarNotifier: ISnackbarNotifier
}

export enum AppEvent {
  Unauthorized = 'unauthorized',
  NetworkError = 'connection_lost',
  NoPartnerIdError = 'no_partner_id_error',
  NetworkSuccess = 'connection_success'
}

export enum PassportError {
  PASSPORT_TOKEN_ERROR = 'PASSPORT_TOKEN_ERROR',
  WRONG_PASSPORT_ACCOUNT = 'WRONG_PASSPORT_ACCOUNT'
}

export interface UnauthorizedEventData {
  /**
   * Запрос на котором произошло событие Unauthorized
   */
  url: string
  method: string
  status: number
  error_slug?: PassportError
}
