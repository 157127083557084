import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography} from '@eda-restapp/ui'
import {isNull} from 'lodash-es'
import type moment from 'moment-timezone'
import React, {type Dispatch, useCallback} from 'react'

import type {PlaceDisablingReasonCode} from '@restapp/shared-api'

import {CustomPeriodSelectorContainer} from '../../../CustomPeriodSelector/CustomPeriodSelector.container'
import type {PlaceDisableParams} from '../../../../DisablePlaceModal.types'
import {DisablePlaceReasonChipsGroup} from '../../../DisablePlaceReasonChipsGroup/DisablePlaceReasonChipsGroup'
import {DisablePlacePeriodChipsGroup} from '../../../DisablePlacePeriodChipsGroup/DisablePlacePeriodChipsGroup'
import {PlaceDisableReason, Timers} from '../../../../DisablePlaceModal.constants'

type IDisablePlaceSettingsProps = {
  disablingParams?: PlaceDisableParams[]
  disablePeriodDuration: number | null
  disableReasonCode: PlaceDisablingReasonCode | null
  placeTimezone: string
  onChangeCustomDisabledPeriodDuration: Dispatch<React.SetStateAction<moment.Moment | null>>
  onChangeDisablePeriodDuration: (periodDuraation: number) => void
  onChangeDisableReasonCode: (reasonCode: PlaceDisablingReasonCode) => void
}

export const DisablePlaceSettings: React.FC<IDisablePlaceSettingsProps> = ({
  disablingParams,
  disablePeriodDuration,
  disableReasonCode,
  placeTimezone,
  onChangeCustomDisabledPeriodDuration,
  onChangeDisablePeriodDuration,
  onChangeDisableReasonCode
}) => {
  const {t} = useI18n()

  // For <high load>-reason only allow to disable restaurant until the end of the day
  const maxTurnOffDaysForReason =
    disableReasonCode === PlaceDisableReason.NOT_KEEP_UP
      ? 0
      : disablingParams?.find((disableParam) => disableParam.value === disableReasonCode)?.days_limit

  const handleCustomTimePickerChange = useCallback(
    (customDate: moment.Moment) => {
      onChangeCustomDisabledPeriodDuration(customDate)
    },
    [onChangeCustomDisabledPeriodDuration]
  )

  return (
    <Box flexDirection='column' gap='s'>
      <Box flexDirection='column'>
        <Typography variant='caption1'>
          {t('core-legacy.disable-place-modal.content-disable-reason-chips-group-label', 'Почему нужно отключить:')}
        </Typography>
        <DisablePlaceReasonChipsGroup
          disablingParams={disablingParams}
          disableReasonCode={disableReasonCode}
          onChangeDisableReasonCode={onChangeDisableReasonCode}
        />
      </Box>

      {!isNull(disableReasonCode) && (
        <Box flexDirection='column'>
          <Typography variant='caption1'>
            {t('core-legacy.disable-place-modal.content-disable-time-chips-group-label', 'На сколько:')}
          </Typography>
          <DisablePlacePeriodChipsGroup
            disablingParams={disablingParams}
            disablePeriodDuration={disablePeriodDuration}
            disableReasonCode={disableReasonCode}
            onChangeDisablePeriodDuration={onChangeDisablePeriodDuration}
          />
        </Box>
      )}
      {disablePeriodDuration === Timers.OTHER && (
        <CustomPeriodSelectorContainer
          disableReasonCode={disableReasonCode}
          maxTurnOffDaysCount={maxTurnOffDaysForReason}
          placeTimezone={placeTimezone}
          onChange={handleCustomTimePickerChange}
        />
      )}
    </Box>
  )
}
