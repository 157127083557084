import {makeStyles} from 'tss-react/mui'
import cssVar from 'polished/lib/helpers/cssVar'

export const HelpIconStyles = {
  root: {
    maxHeight: 20,
    minHeight: 20,
    maxWidth: 20,
    minWidth: 20
  },
  icon: {
    fontSize: 20
  },
  popper: {
    zIndex: Number(cssVar('--zIndexTooltip', 10000))
  },
  tooltip: {}
}

const useStyles = makeStyles()(HelpIconStyles)

export default useStyles
