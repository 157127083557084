import {useLayoutEffect, useEffect, useState, useRef} from 'react'

/**
 * Костыль для Safari: Safari не умеет определять высоту потомка grid/flexbox, если у родителя высота задана через проценты.
 *
 * https://www.w3.org/TR/CSS21/visudet.html#propdef-height
 *
 * Ситуация такая: есть родительский контейнер (grid/flex), у которого не задана точная высота, а задана в процентах. В этом случае потомок должен быть ограничен родителем и в свою очередь расширяет родителя, у которого может быть задан max-height, но поскольку точная высота родителя не задана, Safari считает что она auto, потомок наследует это свойство и может так получиться, что потомок оказывается больше родителя.

 * Данный хук проверяет если встретилась такая ситуация и исправляет, выставляя точную высоту на контейнер (вместо 100%), в результате чего Safari дальше сам правильно определяет размеры.
 *
 * Необходимо использовать в контейнере с одним потомком.
 */
const useFixSafariGrid = (parentRef: React.RefObject<HTMLElement>) => {
  const localRef = useRef<HTMLElement>(null)
  const [height, setHeight] = useState<number | undefined>()
  const ref = parentRef ?? localRef

  const containerHeight = ref.current?.clientHeight
  const children = ref.current?.children
  const childrenHeight = children ? Array.from(children).find(Boolean)?.clientHeight : 0

  useEffect(() => {
    const refresh = () => {
      setHeight(undefined)

      if (ref.current) {
        ref.current.style.height = ''
      }
    }

    window.addEventListener('resize', refresh, true)

    return () => {
      window.removeEventListener('resize', refresh, true)
    }
  }, [ref])

  useLayoutEffect(() => {
    if (!height && containerHeight && childrenHeight && containerHeight < childrenHeight) {
      setHeight(containerHeight)

      if (ref.current) {
        ref.current.style.height = containerHeight.toString()
      }
    }
  }, [containerHeight, childrenHeight, height, ref])
}

export default useFixSafariGrid
