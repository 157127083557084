import {useExp3} from '@eda-restapp/configs'
import {useChannelSubscription, ordersChannel} from '@eda-restapp/microfrontend'
import {usePermission} from '@eda-restapp/permissions'

import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'
import type {VendorApi} from '@restapp/shared-api'

export function useActiveOrders<TSelectedData>({
  enabled,
  select,
  refetchOnMount
}: Pick<
  ApiQueryConfig<'/4.0/restapp-front/eats-restapp-orders/v1/active', 'GET', TSelectedData>,
  'enabled' | 'select' | 'refetchOnMount'
> = {}) {
  const hasActiveOrdersRole = usePermission('permission.orders.active')
  const {activeOrdersPollingSeconds, activeOrdersPollingWithSocketSeconds} = useExp3('restapp_orders')

  const query = useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-orders/v1/active',
    method: 'GET',
    refetchOnSocketEvent: ['order_new', 'order_changed_items', 'order_changed_status'],
    refetchInterval: activeOrdersPollingSeconds * 1000,
    refetchIntervalWithSocket: activeOrdersPollingWithSocketSeconds * 1000,
    refetchIntervalInBackground: true,
    select,
    enabled: hasActiveOrdersRole && enabled,
    refetchOnMount
  })

  useChannelSubscription(ordersChannel, (msg) => {
    if (msg.type === 'refresh' && msg.sender === 'children') {
      // Т.к этот хук используется много где для определения наличия новых заказов
      // Из нового раздела отправляем refresh когда какой-то из заказов изменился (например нажата кнопка принятия)
      // нам нужно обновить стейт тут, в коре, для этого оно и надо
      void query.refetch()
    }
  })

  return query
}

export function selectUnacceptedOrdersIds(
  data: VendorApi['/4.0/restapp-front/eats-restapp-orders/v1/active']['GET']['response']
): string[] {
  return data.orders
    .filter((order) => order.status === 'new' && !order.change_initialized_at && !order.change_draft_created_at)
    .map((order) => order.order_nr)
}
