import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {ScheduleRange} from '@restapp/shared/utils'

import type {RegularScheduleState} from '../../../types/schedule'

const defaultSchedule = {
  days: [],
  from: 0,
  to: 0,
  allDay: false
}

const initialState: RegularScheduleState = {
  initialSchedule: [defaultSchedule],
  schedule: [defaultSchedule],
  similarSchedulePlaces: []
}

export const placesRegularScheduleSlice = createSlice({
  name: 'placesRegularSchedule',
  initialState,
  reducers: {
    setInitialSchedule(state: RegularScheduleState, action: PayloadAction<ScheduleRange[]>) {
      state.initialSchedule = action.payload
    },
    setSchedule(state: RegularScheduleState, action: PayloadAction<ScheduleRange[]>) {
      state.schedule = action.payload
    },
    setDays(state: RegularScheduleState, action: PayloadAction<{index: number; value: number[]}>) {
      const {index, value} = action.payload

      state.schedule[index].days = value
    },
    setFrom(state: RegularScheduleState, action: PayloadAction<{index: number; value: number}>) {
      const {index, value} = action.payload

      state.schedule[index].from = value
    },
    setTo(state: RegularScheduleState, action: PayloadAction<{index: number; value: number}>) {
      const {index, value} = action.payload

      state.schedule[index].to = value
    },
    setAllDay(state: RegularScheduleState, action: PayloadAction<{index: number; value: boolean}>) {
      const {index, value} = action.payload

      state.schedule[index].allDay = value
    },
    addSchedule(state: RegularScheduleState) {
      state.schedule.push(defaultSchedule)
    },
    removeSchedule(state: RegularScheduleState, action: PayloadAction<number>) {
      state.schedule.splice(action.payload, 1)
    },
    setSimilarPlaces(state: RegularScheduleState, action: PayloadAction<number[]>) {
      state.similarSchedulePlaces = action.payload
    },
    resetSchedule() {
      return initialState
    }
  }
})
