import React, {type CSSProperties, type FC} from 'react'

import {PlusBanner} from './components/PlusBanner/PlusBanner'
import {usePlusBannerForAnyPlace} from './usePlusBannerForAnyPlace'

type PlusBannerForAnyPlaceProps = {
  style?: CSSProperties
}

export const PlusBannerForAnyPlace: FC<PlusBannerForAnyPlaceProps> = ({style}) => {
  const {visible} = usePlusBannerForAnyPlace()

  if (!visible) {
    return null
  }

  return <PlusBanner style={style} />
}
