import {t} from '@eda-restapp/i18n'
import type moment from 'moment'

import {isToday} from './isToday'

export function formatSla(hours: number) {
  if (hours < 0) {
    return t('main-chats.formating.vremya-na-reshenie-vishlo', 'Время на решение вышло')
  }

  if (hours < 1) {
    return t('main-chats.formating.menshe-chasa', 'Меньше часа')
  }

  return t('main-chats.formating.hours-to-resolve-count-text', '{count} час/а/ов', {
    none: '{count} часов',
    one: '{count} час',
    some: '{count} часа',
    many: '{count} часов',
    count: hours
  })
}

export function formatUpdatedDate(date: moment.Moment) {
  if (isToday(date)) {
    return t('main-chats.formating.today-in', 'Сегодня в {date}', {date: date.format('HH:mm')})
  }

  return date.format('HH:mm DD.MM.YYYY')
}
