import {useState} from 'react'

import type {UseStoryOptions} from './StoryModal'

export function useStoryProgress({pagesCount, autoplay, onEnd}: UseStoryOptions) {
  const [currentPage, setCurrentPage] = useState(0)
  const [isPaused, setIsPaused] = useState(!autoplay)

  const nextPage = () => {
    if (currentPage < pagesCount - 1) {
      setCurrentPage(currentPage + 1)
    } else {
      onEnd?.()
    }
  }

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const play = () => setIsPaused(false)
  const pause = () => setIsPaused(true)

  return {
    currentPage,
    isPaused,
    nextPage,
    prevPage,
    play,
    pause
  }
}
