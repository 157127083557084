import {omit} from 'lodash-es'
import React, {useRef, useState} from 'react'

import Sticky from '@restapp/core-legacy/common/components/Sticky'

import {HeaderStickyRowContext} from '../../context/HeaderStickyRowContext'
import type {RowDataType} from '../../types'

import {VERTICAL_PADDING} from './Header.constants'
import type {HeaderStylesProps} from './Header.styles'
import {useHeaderStyles} from './Header.styles'

const HeaderStickyRowContextProvider = HeaderStickyRowContext.Provider

type HeaderProps = {
  classes?: HeaderStylesProps['classes']
  children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({classes, children}) => {
  const {classes: c} = useHeaderStyles(undefined, {props: {classes}})

  const [stickyRowHeightMap, setStickyRowHeightMap] = useState<{[position: string]: RowDataType}>({})

  const rootRef = useRef<HTMLHeadingElement>(null)

  const sortedStickyRowHeights = Object.keys(stickyRowHeightMap)
    .sort()
    .map((position) => stickyRowHeightMap[position])

  const registerStickyRowHeight = (data: RowDataType) => {
    setStickyRowHeightMap({
      ...stickyRowHeightMap,
      [data.position]: data
    })
  }

  const unregisterStickyRowHeight = (position: number) => {
    setStickyRowHeightMap(omit(stickyRowHeightMap, position))
  }

  const changeStickyRowHeightRegistration = (prevPos: number, data: RowDataType) => {
    setStickyRowHeightMap({
      ...omit(stickyRowHeightMap, prevPos),
      [data.position]: data
    })
  }

  const getStickyOffset = (position: number) =>
    sortedStickyRowHeights
      .filter((_, i) => i < position)
      .reduce((sum, data) => (typeof data.height === 'number' ? sum + data.height : sum), 0) + VERTICAL_PADDING

  return (
    <Sticky className={c.root} as={'header'}>
      <div className={c.inner} ref={rootRef}>
        <HeaderStickyRowContextProvider
          value={{
            registerStickyRowHeight,
            unregisterStickyRowHeight,
            changeStickyRowHeightRegistration,
            getStickyOffset
          }}
        >
          {children}
        </HeaderStickyRowContextProvider>
      </div>
    </Sticky>
  )
}

export default Header
