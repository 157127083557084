import {useServiceBrand} from '@restapp/shared'

export const usePassportName = () => {
  const serviceBrand = useServiceBrand()

  switch (serviceBrand) {
    case 'YandexEats':
      return 'Yandex ID'
    case 'YangoEats':
      return 'Yango ID'
    default:
      return 'Яндекс ID'
  }
}
