import type {Selector} from 'react-redux'

import {createHeaders} from '@restapp/shared/utils/createAuthHeaders'

import type {AuthStateSlice} from '../types'

import {selectOauthToken} from './selectOauthToken'
import {selectPartnerId} from './selectPartnerId'
import {selectToken} from './selectToken'

/**
 * Селектор для получения авторизационных заголовков для API.
 */
export const selectAuthHeaders: Selector<AuthStateSlice, Record<string, string>> = (state) => {
  const token = selectToken(state)
  const partnerId = selectPartnerId(state)
  const oauthToken = selectOauthToken(state)

  return createHeaders(token, oauthToken, partnerId)
}
