import {makeStyles} from 'tss-react/mui'

export default makeStyles<void, 'icon'>({name: 'SupportContacts'})((theme, props, classes) => {
  return {
    supportContactsBlock: {
      margin: '0 auto 8px',
      width: '100%',
      maxWidth: 514,
      alignSelf: 'center',
      justifyContent: 'center'
    },
    supportContactsLabel: {
      marginBottom: 0,
      paddingBottom: 16,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '0.5px solid #D2D0CC',
      textAlign: 'center',
      letterSpacing: '-0.01em',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '19px',
      color: '#21201F',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: '23px'
      },

      '& > :first-child': {
        textAlign: 'start'
      }
    },
    supportCardsBlock: {
      marginTop: 11,
      display: 'flex',
      justifyContent: 'center'
    },
    supportContactsCard: {
      marginBottom: 36,
      fontSize: 16,
      lineHeight: '19px',
      color: '#1F2021',
      textAlign: 'center',

      [theme.breakpoints.up('md')]: {
        '&:not(:last-child)': {
          marginRight: 32,
          marginBottom: 24
        }
      }
    },
    supportContactsCardFlag: {
      marginBottom: 8
    },
    supportContactsCardPhone: {
      marginBottom: 4
    },
    supportContactNoSelectedCountry: {
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontWeight: 500,
      lineHeight: '18px',
      color: '#999C9E',

      [theme.breakpoints.up('md')]: {
        paddingTop: 25
      },

      '& > span': {
        textAlign: 'center'
      }
    },
    supportInfo: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    supportInfoLabel: {
      marginBottom: 1,
      fontSize: 13,
      lineHeight: '14px',
      color: '#9E9B98'
    },
    supportInfoData: {
      fontSize: 16,
      lineHeight: '17px',
      color: '#21201F'
    },
    signLink: {
      padding: '17px 0 10px 0',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 18,
      color: '#000000',
      fontWeight: 'bold',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',

      [`& .${classes.icon}`]: {
        marginLeft: 14
      },

      [theme.breakpoints.up('md')]: {
        paddingBottom: 53,
        justifyContent: 'center',
        borderTop: 'none'
      }
    },
    icon: {}
  }
})
