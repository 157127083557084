import {useExp3} from '@eda-restapp/configs'
import {selectOauthToken, selectPartnerId, selectToken, setOauthToken, setPartnerId, setToken} from '@restapp/core-auth'
import {useIsEnabledInCurrentNetwork} from '@restapp/shared'
import {useI18n} from '@eda-restapp/i18n'
import type {FC} from 'react'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useMatch, useNavigate} from 'react-router-dom'
import {Spinner} from '@restapp/shared-ui'
import {eventLogger} from '@eda-restapp/logger'
import {getDirectToken, setDirectToken} from '@eda-restapp/microfrontend'

import {useBrandRedirect} from './useBrandRedirect'

const TRANSFER_ROUTE = '/transfer-data'

const ACCESS_TOKEN_PARAM = 'access_token'
const X_TOKEN_PARAM = 'x_token'
const DIRECT_TOKEN_PARAM = 'direct_token'
const PARTNER_ID_PARAM = 'partner_id'
const INAPP_REDIRECT_PATH_PARAM = 'inapp-redirect-path' // подумать

export const SeamlessTransferToOtherDomain: FC<{children?: React.ReactNode}> = ({children}) => {
  const sttodConfig = useExp3('restapp_sttod')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useI18n()

  const enabled = useIsEnabledInCurrentNetwork(sttodConfig.enableTransfer)
  const needRedirect = enabled && window.location.origin === sttodConfig.startURL

  const brandRedirect = useBrandRedirect()

  const endUrl = brandRedirect.needRedirect ? brandRedirect.endUrl : sttodConfig.endURL

  const match = useMatch(TRANSFER_ROUTE)

  const oauthToken = useSelector(selectOauthToken)
  const token = useSelector(selectToken)
  const partnerId = useSelector(selectPartnerId)
  const directToken = getDirectToken()

  useEffect(() => {
    if (!match) {
      return
    }

    const hashParams = window.location.hash
      .substring(1)
      .split('&')
      .map((value) => value.split('='))

    hashParams.forEach(([paramName, paramValue]) => {
      switch (paramName) {
        case ACCESS_TOKEN_PARAM:
          dispatch(setOauthToken(paramValue))
          return
        case X_TOKEN_PARAM:
          dispatch(setToken(paramValue))
          return
        case DIRECT_TOKEN_PARAM:
          setDirectToken(paramValue)
          return
        case PARTNER_ID_PARAM:
          dispatch(setPartnerId(paramValue))
          return
      }
    })

    const searchParams = new URLSearchParams(window.location.search)
    const inAppRedirectPath = searchParams.get(INAPP_REDIRECT_PATH_PARAM)

    navigate(inAppRedirectPath || '/')

    eventLogger({
      name: 'sttod:handled_redirect',
      additional: {
        fields: hashParams.map((param) => param[0]),
        navigatedTo: inAppRedirectPath ?? undefined,
        isBrandRedirect: brandRedirect.needRedirect
      }
    })
  }, [dispatch, match, navigate, brandRedirect.needRedirect])

  useEffect(() => {
    if (!needRedirect && !brandRedirect.needRedirect) {
      return
    }

    const hashParams = []

    if (oauthToken) {
      hashParams.push(`${ACCESS_TOKEN_PARAM}=${oauthToken}`)
    }

    if (token) {
      hashParams.push(`${X_TOKEN_PARAM}=${token}`)
    }

    if (directToken) {
      hashParams.push(`${DIRECT_TOKEN_PARAM}=${directToken}`)
    }

    if (partnerId) {
      hashParams.push(`${PARTNER_ID_PARAM}=${partnerId}`)
    }

    const inAppRedirectPath = window.location.pathname
    const redirectURL = new URL(TRANSFER_ROUTE, endUrl)

    redirectURL.hash = hashParams.join('&')
    redirectURL.searchParams.set(INAPP_REDIRECT_PATH_PARAM, inAppRedirectPath)

    eventLogger({
      name: 'sttod:redirecting_to_other_domain',
      additional: {
        fields: hashParams.map((param) => param.split('=')[0]),
        redirectedTo: endUrl,
        inAppRedirectPath,
        isBrandRedirect: brandRedirect.needRedirect
      }
    })

    window.location.replace(redirectURL.toString())
    window.dispatchEvent(new Event('oauthcomplete'))
  }, [directToken, enabled, needRedirect, brandRedirect.needRedirect, oauthToken, partnerId, endUrl, token])

  if (match || needRedirect || brandRedirect.needRedirect) {
    return (
      <Spinner
        slug='sttod-data-setup'
        caption={t('bootstrap.sttod.loading-data', 'Загружаем данные пользователя')}
        absolute
      />
    )
  }

  return <>{children}</>
}
