import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {PlusSymbol} from '@eda-restapp/ui'
import React, {type CSSProperties, type FC, useEffect} from 'react'
import {useInView} from 'react-intersection-observer'

import {usePlaces} from '@restapp/core-places'

import {usePlusDrawer} from '../../../../hooks/usePlusDrawer'

import {useStyles} from './PlusBanner.styles'

type PlusBannerProps = {
  placeId?: number
  style?: CSSProperties
}

export const PlusBanner: FC<PlusBannerProps> = ({style, placeId}) => {
  const {classes: c} = useStyles()
  const {t} = useI18n()
  const {setSelectedPlaceId} = usePlaces()

  const {ref, inView} = useInView({triggerOnce: true, threshold: 0})

  const plusDrawer = usePlusDrawer()
  const onClickManagePlus = () => {
    if (placeId) {
      setSelectedPlaceId(placeId)
    }
    plusDrawer.open()
  }
  const openPlusDrawer = () => {
    eventLogger({name: 'plus-banner-want-in-plus'})
    onClickManagePlus()
  }

  useEffect(() => {
    if (inView) {
      eventLogger({
        name: 'on_screen',
        value: 'show-plus-banner'
      })
    }
  }, [inView])

  return (
    <div ref={ref}>
      <div className={c.root} style={style}>
        <div className={c.icon}>
          <PlusSymbol fontSize='32px' />
        </div>

        <div>
          {t(
            'shared-places.plus-banner.text',
            'Подключите Яндекс Плюс — чтобы привлекать более лояльных пользователей сервисов {eatsServiceBrandLabel} и {dcServiceBrandLabel}. Они заказывают чаще, и средний чек у них выше'
          )}
        </div>

        <div
          data-testid={'plus-banner-link' /* Баннер Плюса | Ссылка Хочу в Плюс */}
          className={c.link}
          onClick={openPlusDrawer}
        >
          {t('shared-places.plus-banner.btn-text', 'Хочу в Плюс')}
        </div>
      </div>
    </div>
  )
}
