import React from 'react'
import type {PopoverProps} from '@mui/material'
import {Popover} from '@mui/material'
import useStyles from './Popover.style'

type ClickHandler = (e: React.MouseEvent<HTMLElement>) => void

export interface Props extends Partial<PopoverProps> {
  className?: string
  control: (handleClick: ClickHandler) => React.ReactNode
  onOpen?: () => void
  onClose?: () => void
}

const PopoverWithControl: React.FC<Props> = ({control, className, children, onOpen, onClose, ...anchorProps}) => {
  const {classes: c} = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleClick: ClickHandler = (event) => {
    setAnchorEl(event.currentTarget)
    onOpen?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClose?.()
  }

  return (
    <div className={className}>
      {control(handleClick)}
      <Popover
        classes={{paper: c.paper}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleClose}
        {...anchorProps}
      >
        {children}
      </Popover>
    </div>
  )
}

export default PopoverWithControl
