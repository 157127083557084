import cn from 'classnames'
import React, {type CSSProperties, type ReactNode} from 'react'

import styles from './HeaderRightControls.module.css'

type HeaderRightControlsProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}
export const HeaderRightControls = ({children, style, className}: HeaderRightControlsProps) => {
  return (
    <div style={style} className={cn(styles.root, className)}>
      {children}
    </div>
  )
}
