import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'CountrySelectSplit'})((theme) => {
  return {
    root: {
      position: 'absolute',
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      paddingBottom: 24,
      alignItems: 'center',
      textAlign: 'center',
      overflow: 'auto',
      scrollbarWidth: 'none' /* Mozilla */,

      '&::-webkit-scrollbar': {
        display: 'none' /* Chrome */
      },

      [theme.breakpoints.up('md')]: {
        paddingBottom: 128
      }
    },
    header: {
      margin: '21px 34px 40px',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '27px',

      [theme.breakpoints.up('md')]: {
        marginBottom: 88
      }
    },
    countryCardsBlock: {
      display: 'grid',
      gridGap: 16

      // This styles for future, when there will be more 10 table cells
      // [theme.breakpoints.up('md')]: {
      //   gridTemplateRows: 'repeat(auto-fit, minmax(60px, 1fr))',
      //   gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))',
      //   gridAutoFlow: 'column',
      //   height: '100%',
      // }
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column'
    },
    menuItemCountryName: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px'
    }
  }
})
