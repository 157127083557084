import {makeStyles} from 'tss-react/mui'

const BTN_SIZE = '48px'

export const useStyles = makeStyles<{withBtns?: boolean}>()((theme, {withBtns}) => {
  return {
    root: {
      display: 'grid',
      columnGap: '18px',
      gridAutoFlow: 'column',
      gridTemplateColumns: withBtns ? `${BTN_SIZE} 1fr ${BTN_SIZE}` : '1fr',
      alignItems: 'center'
    },

    btn: {
      width: BTN_SIZE,
      height: BTN_SIZE,
      borderRadius: '50%',

      '&:disabled': {
        opacity: 0.5
      }
    },

    rail: {
      borderRadius: '8px !important',
      height: '6px !important',
      backgroundColor: '#EDF0F1 !important'
    },

    track: {
      borderRadius: '8px !important',
      height: '6px !important'
    },

    thumb: {
      width: '24px !important',
      height: '24px !important',
      marginLeft: '-1px !important',
      marginTop: '-2px !important',
      border: '3px solid #FFFFFF !important',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1) !important',

      '&:hover': {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2) !important'
      }
    }
  }
})
