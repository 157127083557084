import {useExp3} from '@eda-restapp/configs'
import {DEFAULT_SERVICE_BRAND_LANGUAGE, type ServiceBrandType, type SUPPORTED_LANGUAGES} from '@eda-restapp/i18n'

export const useDefaultServiceBrandLanguage = (serviceBrand: ServiceBrandType) => {
  const commonConfig = useExp3('restapp_common')

  const configDefaultServiceBrandLanguage =
    commonConfig.configDefaultServiceBrandsLanguage &&
    (commonConfig.configDefaultServiceBrandsLanguage[serviceBrand] as SUPPORTED_LANGUAGES)

  return configDefaultServiceBrandLanguage || DEFAULT_SERVICE_BRAND_LANGUAGE[serviceBrand]
}
