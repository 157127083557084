import {useExp3} from '@eda-restapp/configs'

import {useApiQuery} from '@restapp/core-api'

export const useHasUnseenFeedback = () => {
  const {unseenFeedbacksEnabled, refetchIntervalUnseenFeedback} = useExp3('restapp_feedback')
  const refetchInterval = refetchIntervalUnseenFeedback ? refetchIntervalUnseenFeedback * 60 * 10000 : undefined

  return useApiQuery({
    url: '/4.0/restapp-front/eats-place-rating/v1/new-feedbacks/check',
    method: 'GET',
    select: (data) => data.new_feedbacks_flag,
    refetchInterval: refetchInterval,
    enabled: unseenFeedbacksEnabled
  })
}
