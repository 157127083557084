import moment from 'moment'

import type {IChat} from '../types'

export const getChatSlaHours = (chat: Pick<IChat, 'status' | 'sla'>): number | null => {
  if (chat.status !== 'solved' && chat.sla) {
    return moment(chat.sla).diff(moment(), 'hours')
  }

  return null
}
