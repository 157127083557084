import type {ConfigsWithDefaults} from '@eda-restapp/configs'
import {ChevronRightMIcon} from '@eda-restapp/ui'
import {Paper} from '@mui/material'
import cn from 'classnames'
import React, {forwardRef} from 'react'

import {OutsideLink} from '@restapp/shared-ui'

import useStyles from './HelpMenu.style'

type HelpMenuProps = {
  section: ConfigsWithDefaults['restapp_support_links']['sections'][0]
  className?: string
  onItemClick?: () => void
}

// eslint-disable-next-line react/display-name
const HelpMenu = forwardRef(({section, onItemClick, ...props}: HelpMenuProps, ref: React.Ref<HTMLDivElement>) => {
  const {classes: c} = useStyles()

  return (
    <Paper ref={ref} {...props} className={cn(c.paper, props.className)}>
      <section className={c.header}>
        <header className={cn(c.itemCommon, c.title)}>{section.title}</header>
      </section>
      <section className={c.list}>
        {section.links.map(
          ({link, title, slug, enabled}) =>
            enabled && (
              <OutsideLink
                slug={slug}
                href={link}
                key={title}
                className={cn(c.itemCommon, c.item)}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  onItemClick?.()
                }}
              >
                <span>{title}</span>
                <ChevronRightMIcon className={c.chevron} />
              </OutsideLink>
            )
        )}
      </section>
    </Paper>
  )
})

export default HelpMenu
