import {createSelector} from '@reduxjs/toolkit'
import {isEqual, compact} from 'lodash-es'

import {optionEntitySelectors} from '../entities'
import {selectMenu, addGroupIdArg, addIdArg} from '../utils'

export const selectCurrentOptions = createSelector(selectMenu, (state) => state.options.current)
export const selectInitialOptions = createSelector(selectMenu, (state) => state.options.initial)
export const selectJoinByGroup = createSelector(selectMenu, (state) => state.options.joinByGroup)

export const all = createSelector(selectCurrentOptions, optionEntitySelectors.selectAll)
export const total = createSelector(selectCurrentOptions, optionEntitySelectors.selectTotal)
export const byItemId = createSelector([all, addIdArg], (all, id) => all.filter((option) => option._itemId === id))

export const byGroupId = createSelector(
  [selectCurrentOptions, selectJoinByGroup, addGroupIdArg],
  (current, joinByGroup, id) => {
    // тут теряется сортировка к сожалению, но она вроде и не нужна
    // зато работает в 2 раза быстрее чем выборка из всех опций
    const optionIdsInGroup = joinByGroup[id] || []
    const populatedOptions = optionIdsInGroup.map((optionId) => current.entities[optionId])
    return compact(populatedOptions)
  }
)

export const isDirty = createSelector(
  [selectCurrentOptions, selectInitialOptions],
  (current, initial) =>
    // Эти проверки будут работать т.к optionEntitySelectors соблюдает сортировку
    !isEqual(optionEntitySelectors.selectAll(current), optionEntitySelectors.selectAll(initial))
)
