import {useI18n} from '@eda-restapp/i18n'
import {resetNavigation} from '@eda-restapp/utils'
import {Container, ThemeProvider} from '@mui/material'
import {StyledEngineProvider} from '@mui/material/styles'
import {toString} from 'lodash-es'
import React from 'react'

import {theme, Button} from '@restapp/shared-ui'

import useStyles from './GlobalErrorPage.styles'

interface GlobalErrorPageProps {
  error: unknown
  eventId?: string
}

function GlobalErrorPage({error, eventId}: GlobalErrorPageProps) {
  const {t} = useI18n()
  const {classes: c} = useStyles()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container className={c.container}>
          <div className={c.title}>{t('shared-boundary.global-error-page.title', 'Ошибка')}</div>

          <div className={c.text}>{t('shared-boundary.global-error-page.text', 'Извините, что-то пошло не так.')}</div>

          <div>
            <div className={c.error}>{toString(error)}</div>

            {!!eventId && <div className={c.error}>{`EventId: ${eventId}`}</div>}
          </div>

          <Button className={c.button} onClick={() => resetNavigation()} variant='contained' color='primary'>
            {t('shared-boundary.global-error-page.button', 'Перезагрузить приложение')}
          </Button>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default GlobalErrorPage
