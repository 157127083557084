import type {XivaNotification} from './types'

export function parseXivaMessage(message: string): XivaNotification {
  try {
    const xivaNotification: XivaNotification = JSON.parse(message)

    return xivaNotification
  } catch {
    throw new Error(`Failed to parse Xiva message: "${message}"`)
  }
}
