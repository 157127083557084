import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'

import {useApiMutation} from '@restapp/core-api'

import useCampaignsApi from './useCampaignsApi'
import useDirect from './useDirect'

type IUseCampaignUnlinkByUser = {
  onSettled?: () => void
  campaignCount: number
}

export const useCampaignRemoveByUser = ({onSettled, campaignCount}: IUseCampaignUnlinkByUser) => {
  const {t} = useI18n()
  const {directToken} = useDirect()
  const {invalidate} = useCampaignsApi()

  const {isLoading, mutate} = useApiMutation(
    {
      url: '/4.0/restapp-front/marketing/v1/ad/delete_for_user',
      method: 'POST'
    },
    {
      onSettled,
      fallbackErrorMessage: t('promotion.errors.remove-campaigns-failed', 'При удалении кампаний произошла ошибка'),
      onSuccess: () => {
        invalidate()

        Snackbar.success({
          text: t('promotion.success.campaigns-removed', '{count} кампаний успешно удалено', {
            one: 'Кампания успешно удалена',
            some: '{count} кампаний успешно удалено',
            many: '{count} кампаний успешно удалено',
            count: campaignCount
          })
        })
      }
    }
  )

  const deleteCampaignsByUser = directToken
    ? (yandex_uid: number) =>
        mutate({
          body: {
            yandex_uid
          },
          headers: {
            Authorization: directToken
          }
        })
    : undefined

  return {
    isLoading,
    deleteCampaignsByUser
  }
}
