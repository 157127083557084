import {eventLogger, metrika} from '@eda-restapp/logger'
import {useLocation} from 'react-router-dom'

import type {BusyModeEvent, BusyModeEventReferrer, BusyModeEventSnapshot, BusyModeEventSlug} from './types'

const getReferrer = (pathname: string): BusyModeEventReferrer => (pathname.includes('places') ? 'places' : 'orders')

const getTimeShiftData = (additionalMinutes?: number, durationMinutes?: number | string) => {
  return {
    additionalMinutes: additionalMinutes ? String(additionalMinutes) : '',
    durationMinutes: durationMinutes ? String(durationMinutes) : ''
  }
}

const generateEventSnapshot = (event: BusyModeEvent, pathname: string): BusyModeEventSnapshot => {
  const {placeId, additionalMinutes, durationMinutes, prevAdditionalMinutes, prevDurationMinutes} = event

  return {
    initialBusy: {
      placeId,
      referrer: getReferrer(pathname),
      ...getTimeShiftData(prevAdditionalMinutes, prevDurationMinutes)
    },
    savedBusy: {
      placeId,
      referrer: getReferrer(pathname),
      ...getTimeShiftData(additionalMinutes, durationMinutes)
    }
  }
}

const generateEventSlug = (event: BusyModeEvent): BusyModeEventSlug => {
  // additionalMinutes может отсутствовать только если отключают busy mode
  if (!event.additionalMinutes) {
    return 'busy_mode:disable:save'
  }

  // prevExtraTime есть, только если busy mode включен и сейчас меняют значения
  return event.prevAdditionalMinutes ? 'busy_mode:change:save' : 'busy_mode:enable:save'
}

export const useBusyModeTracker = () => {
  const {pathname} = useLocation()

  return (event: BusyModeEvent) => {
    eventLogger({
      name: 'busy_mode_action',
      value: generateEventSlug(event),
      additional: generateEventSnapshot(event, pathname)
    })
    metrika({
      target: 'busy_mode_action',
      params: {
        eventSlug: generateEventSlug(event),
        eventData: generateEventSnapshot(event, pathname)
      }
    })
  }
}
