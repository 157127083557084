import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {HumanUser, useIsDesktopStrict, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'

import {Tooltip} from '@restapp/shared-ui'

import styles from './SidebarAccountElement.module.css'

type LegacySidebarLogoutProps = {
  email: string
  mini: boolean
}

/**
 * @deprecated
 */
export const LegacySidebarLogout: React.FC<LegacySidebarLogoutProps> = ({email, mini}) => {
  const isDesktop = useIsDesktopStrict()
  const {t} = useI18n()

  const newYearConfig = useExp3('restapp_new_year')

  return (
    <Tooltip
      classes={{tooltip: styles.tooltip}}
      title={t('main-layout.legacy-sidebar-logout.logout-tooltip', 'Выход')}
      disableHoverListener={!mini || !isDesktop}
      disableFocusListener={!isDesktop}
      disableTouchListener={!isDesktop}
      placement='right'
    >
      <div
        className={cn(styles.nameWrapper, {
          [styles.newYearNameWrapper]: newYearConfig.enabled,
          [styles.paddingWhenMini]: mini
        })}
      >
        <Link
          className={styles.iconLink}
          to='/logout'
          data-testid={'ui-sidebar-section-Выход' /*UI | Ссылка на раздел в сайдбаре. link.name - название раздела*/}
        >
          <div className={styles.icon}>
            <HumanUser />
          </div>
        </Link>
        {!mini && (
          <div className={styles.logoutWrapper}>
            <Tooltip
              classes={{tooltip: styles.tooltip}}
              title={email}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={1000}
              disableHoverListener={!isDesktop}
              disableFocusListener={!isDesktop}
              disableTouchListener={!isDesktop}
            >
              <Typography variant='caption1' thickness='bold' className={styles.email}>
                {email}
              </Typography>
            </Tooltip>
            <Link
              to='/logout'
              data-testid={
                'ui-sidebar-section-Выход' /*UI | Ссылка на раздел в сайдбаре. link.name - название раздела*/
              }
            >
              <Typography variant='caption1'>{t('main-layout.sidebar-container.exit', 'Выйти')}</Typography>
            </Link>
          </div>
        )}
      </div>
    </Tooltip>
  )
}
