import {useIsMobileStrict} from '@eda-restapp/ui'
import React, {type ReactNode} from 'react'

type MobileDeviceProps = {
  children: ReactNode
}

export const MobileDevice: React.FC<MobileDeviceProps> = ({children}) => {
  const isMobile = useIsMobileStrict()

  if (!isMobile) {
    return null
  }

  return <>{children}</>
}

type DesktopDeviceProps = {
  children: ReactNode
  withoutTablet?: boolean
  isNotXSmall?: boolean
}

export const DesktopDevice: React.FC<DesktopDeviceProps> = ({children}) => {
  const isMobile = useIsMobileStrict()

  if (isMobile) {
    return null
  }

  return <>{children}</>
}
