import {useExp3} from '@eda-restapp/configs'
import type {CSSProperties, FC} from 'react'
import {useDispatch} from 'react-redux'

import {chatsSlice} from '../../../slice/chatsSlice'

import {SingleChatPlate} from './SingleChatPlate'

type SingleChatPlateContainerProps = {
  style?: CSSProperties
}

export const SingleChatPlateContainer: FC<SingleChatPlateContainerProps> = ({style}) => {
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')

  const dispatch = useDispatch()

  if (!supportSingleChatConfig.supportPagePlate) {
    return null
  }

  return <SingleChatPlate style={style} onClick={() => dispatch(chatsSlice.actions.openSingleChat({}))} />
}
