import type {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

type OneColumnClasses = 'root'

export type OneColumnStylesProps = {
  classes?: Partial<Record<OneColumnClasses, string>>
}

export const useOneColumnStyles = makeStyles({name: 'OneColumn'})((theme: Theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: 'none',
      [theme.breakpoints.up('md')]: {
        maxWidth: 600
      },
      '@media (min-width: 960px)': {
        maxWidth: 750
      },
      '@media (min-width: 1440px)': {
        maxWidth: 1000
      },
      ['@media print']: {
        maxWidth: 'none'
      }
    }
  }
})
