import {get, toString} from 'lodash-es'
import type {ComponentType, ComponentProps} from 'react'
import React from 'react'

import Suspense from './components/Suspense'

type SuspenseProps = ComponentProps<typeof Suspense>

export default function withSuspense<TProps>(
  OriginalComponent: ComponentType<TProps & JSX.IntrinsicAttributes>,
  suspenseProps?: SuspenseProps
) {
  return function WrappedComponent(props: TProps & JSX.IntrinsicAttributes) {
    return (
      <Suspense slug={toString(get(props, 'slug'))} {...suspenseProps}>
        <OriginalComponent {...props} />
      </Suspense>
    )
  }
}
