import {Box} from '@eda-restapp/ui'
import cn from 'classnames'

import {Skeleton} from '@restapp/shared-ui'

import styles from './ActivePartnerSkeleton.module.css'

const WrappedSkeleton = ({firstInOrder, split}: {firstInOrder?: boolean; split?: 'm' | 'l'}) => {
  return (
    <Skeleton
      className={cn(styles.skeleton, {
        [styles.skeletonFirstOrderContainer]: firstInOrder,
        [styles.skeletonSplitLargeModifier]: split === 'l',
        [styles.skeletonSplitMediumModifier]: split === 'm'
      })}
    />
  )
}

const IconSceletons = () => {
  return (
    <Box className={styles.skeletonIconsContainer} flexDirection='row-reverse'>
      <Skeleton className={cn(styles.skeletonCircle, styles.skeletonLeftPush)} />
      <Skeleton className={cn(styles.skeletonCircle, styles.skeletonLeftPush)} />
      <Skeleton className={cn(styles.skeletonCircle, styles.skeletonLeftPush)} />
      <Skeleton className={cn(styles.skeletonCircle, styles.skeletonLeftPush)} />
      <Skeleton className={cn(styles.skeletonCircle)} />
    </Box>
  )
}

export const ActivePartnerSkeleton = () => {
  return (
    <Box justifyContent='center' alignItems='center' className={styles.skeletonsLayout}>
      <Box flexDirection='column' className={styles.skeletonContainer}>
        <WrappedSkeleton firstInOrder split='l' />
        <WrappedSkeleton split='m' />
        <IconSceletons />
      </Box>
    </Box>
  )
}
