import {makeStyles} from 'tss-react/mui'

import {S} from '../style'

export const DrawerStyles = {
  root: {},
  drawerPaper: {
    position: 'absolute',
    width: '100%'
  },
  closeIconWrapper: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    borderRadius: '50%',
    position: 'absolute',
    top: 16,
    right: 8,
    height: 40,
    width: 40,
    padding: 8,
    zIndex: 10,

    [S.mobileQuery]: {
      top: 10
    },

    ['@media (hover: hover)']: {
      '&:hover': {
        backgroundColor: '#EDF0F1',
        transition: 'background-color .3s ease'
      }
    }
  },
  drawerPaperDesktop: {
    maxWidth: 520
  }
} as const

export const useStyles = makeStyles()(DrawerStyles)
