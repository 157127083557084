import type moment from 'moment-timezone'

import {Timers} from './DisablePlaceModal.constants'

type CalculateAvailableAtProps = {
  customDisableDuration: moment.Moment | null
  chipsDisableDuration: Timers | null
  placeCurrentTime: moment.Moment
}

export const calculateAvailableAt = ({
  customDisableDuration,
  chipsDisableDuration,
  placeCurrentTime
}: CalculateAvailableAtProps) => {
  if (!chipsDisableDuration || (chipsDisableDuration === Timers.OTHER && !customDisableDuration)) {
    return null
  }

  const TimeSchemes = {
    [Timers.QUARTER_AN_HOUR]: () => placeCurrentTime.add(15, 'minutes'),
    [Timers.HALF_AN_HOUR]: () => placeCurrentTime.add(30, 'minutes'),
    [Timers.HOUR]: () => placeCurrentTime.add(1, 'hours'),
    [Timers.TWO_HOUR]: () => placeCurrentTime.add(2, 'hours'),
    [Timers.TILL_END_OF_DAY]: () => placeCurrentTime.endOf('day'),
    [Timers.OTHER]: () => customDisableDuration
  }

  return TimeSchemes[chipsDisableDuration]()
}
