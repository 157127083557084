import type {Selector, Store} from '@reduxjs/toolkit'
import {isEqual} from 'lodash-es'
import {Observable} from 'rxjs'
import {distinctUntilChanged} from 'rxjs/operators'

export default function fromRedux<S, T>(store: Store<S>, selector: Selector<S, T>): Observable<T> {
  const value$ = new Observable<T>((observer) => {
    observer.next(selector(store.getState()))

    store.subscribe(() => {
      const value = selector(store.getState())
      observer.next(value)
    })
  })

  return value$.pipe(distinctUntilChanged(isEqual as <T>(a: T, b: T) => boolean))
}
