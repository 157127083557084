import {useExp3} from '@eda-restapp/configs'
import {Widget, presets, YandexSingleBlockChatConfig, blockUIFactory} from '@yandex-int/messenger.widget'
import {get} from 'lodash-es'
import {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {selectOauthToken} from '@restapp/core-auth'

import {chatsSlice} from '../../../slice/chatsSlice'
import {messengerErrorLogger, messengerEventLogger} from '../../logger'

const errorLogger = messengerErrorLogger.namespace('unread_counter_by_chats_watcher')
const eventLogger = messengerEventLogger.namespace('unread_counter_by_chats_watcher')

/** @deprecated Скорее всего отказываемся от этого компонента в пользу каунтера в ручке списка EDARESTAPP-10613 */
export const UnreadCounterByChatsWatcher = () => {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const oauthToken = useSelector(selectOauthToken)
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      if (!ref.current || !supportMultiChatConfig.backgroundUnreadCounterByChats) {
        return
      }

      const config = new YandexSingleBlockChatConfig({
        ...presets.backend[supportMultiChatConfig.backend],
        flags: {
          unreadCountersByChats: 1
        },
        fullscreen: false,
        workspaceId: supportMultiChatConfig.workspace,
        serviceId: supportMultiChatConfig.serviceId,
        authToken: oauthToken ? `OAuth ${oauthToken}` : undefined
      })

      const widget = new Widget(config)

      widget.events.unreadCountersByChats.addListener((data) => {
        try {
          const unreadByChats = get(data, 'data.value') as Record<string, number>

          dispatch(
            chatsSlice.actions.setUnreadByChats(
              Object.fromEntries(
                Object.entries(unreadByChats).filter(
                  ([chatId, count]) => typeof chatId === 'string' && typeof count === 'number'
                )
              )
            )
          )

          eventLogger({name: 'unreadCountersByChats', additional: {unreadByChats}})
        } catch (error) {
          errorLogger({error: error instanceof Error ? error.message : String(error)})
        }
      })

      widget.events.chatLoadError.addListener(() => {
        errorLogger({
          error: new Error('chatLoadError'),
          additional: {config: {...config.values, authToken: config.values.authToken?.length}}
        })
      })

      widget.events.error.addListener(() => {
        errorLogger({
          error: new Error('error'),
          additional: {config: {...config.values, authToken: config.values.authToken?.length}}
        })
      })

      widget.events.ready.addListener(() => {
        eventLogger({name: 'ready'})
      })

      const ui = blockUIFactory()

      widget.setUI(ui)
      widget.init()

      ui.mount(ref.current)

      widget.show()

      return () => widget.destroy()
    } catch (error) {
      errorLogger({error: error instanceof Error ? error.message : String(error)})
    }
  }, [
    dispatch,
    oauthToken,
    supportMultiChatConfig.backend,
    supportMultiChatConfig.backgroundUnreadCounterByChats,
    supportMultiChatConfig.serviceId,
    supportMultiChatConfig.workspace
  ])

  return <div ref={ref} style={{display: 'none'}} />
}
