import {useI18n} from '@eda-restapp/i18n'
import {Typography, Box, Button, OutsideLink} from '@eda-restapp/ui'
import {TextField} from '@mui/material'
import cn from 'classnames'
import type {FormikErrors, FormikHelpers} from 'formik'
import {Formik, Form} from 'formik'
import React from 'react'
import {Link} from 'react-router-dom'

import {usePassportHelpURL, usePassportName} from '@restapp/core-auth/passport/hooks'
import {Field} from '@restapp/shared-forms'

import {OauthButton} from './OauthButton'
import useAuthStyles from './OldAuth.styles'

interface LoginFormValues {
  email: string
  password: string
}

export interface AuthProps {
  login: (email: string, password: string) => Promise<string | undefined>
  isPassportAuthAvailable?: boolean
}

function OldAuth({login, isPassportAuthAvailable}: AuthProps) {
  const {t} = useI18n()
  const passportHelpURL = usePassportHelpURL()
  const passportName = usePassportName()

  const {classes: c} = useAuthStyles()

  // Боремся с подскроллом в WkWebview
  const onBlur = () => {
    window.setTimeout(() => window.scrollTo(0, 0), 100)
  }

  const handleSubmit = async (values: LoginFormValues, {setErrors}: FormikHelpers<LoginFormValues>) => {
    const error = await login(values.email, values.password)
    if (error) {
      setErrors({password: error})
    }
  }

  const validateForm = (values: LoginFormValues): FormikErrors<LoginFormValues> => {
    const errors: FormikErrors<LoginFormValues> = {}
    if (!values.email) {
      errors.email = t('core-legacy.auth.email-empty', 'Эл.почта не указана')
    }
    if (!values.password) {
      errors.password = t('core-legacy.auth.password-empty', 'Пароль не указан')
    }
    const hasCyrillicChars = /[\u0400-\u04FF]/.test(values.password)
    if (hasCyrillicChars) {
      errors.password = t('core-auth.auth.password-has-forbidden-characters', 'Пароль содержит недопустимые символы')
    }

    return errors
  }

  return (
    <div className={c.root}>
      <Formik<LoginFormValues>
        initialValues={{email: '', password: ''}}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({isSubmitting, isValid}) => (
          <Form>
            <div data-testid={'email-field' /*Авторизация | Блок с полем email*/} className={c.inputWrap}>
              <Field name='email'>
                {({field, meta}) => (
                  <TextField
                    variant='standard'
                    {...field}
                    className={c.input}
                    error={Boolean(meta.error)}
                    helperText={meta.error || ' '}
                    onBlur={onBlur}
                    label={t('core-legacy.auth.elektronnaya-pochta', 'Электронная почта')}
                    type='email'
                    autoComplete='email'
                    inputProps={{'data-testid': 'auth-email' /*Авторизация | Поле ввода email*/}}
                  />
                )}
              </Field>
            </div>
            <div data-testid={'password-field' /*Авторизация | Блок с полем password*/} className={c.inputWrap}>
              <Field name='password'>
                {({field, meta}) => (
                  <TextField
                    variant='standard'
                    {...field}
                    className={c.input}
                    error={Boolean(meta.error)}
                    helperText={meta.error || ' '}
                    onBlur={onBlur}
                    label={t('core-legacy.auth.parol', 'Пароль')}
                    type='password'
                    autoComplete='current-password'
                    inputProps={{'data-testid': 'auth-password' /*Авторизация | Поле ввода пароля*/}}
                  />
                )}
              </Field>
            </div>
            <div className={cn(c.inputWrap, c.bottomGroup)}>
              <Button.Text disabled={!isValid} loading={isSubmitting} fullWidth type='submit' data-testid='auth-submit'>
                {t('core-legacy.auth.voiti', 'Войти')}
              </Button.Text>
              <Link
                className={c.forgotPassword}
                to='/forgot-password'
                data-testid={'auth-forgot-password' /*Авторизация | Кнопка забыл пароль*/}
              >
                {t('core-legacy.auth.zabil-parol', 'Забыл пароль')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>

      {isPassportAuthAvailable && (
        <Box className={c.passportAuthContainer} flex={1} flexDirection='column' gap='m' mt='l' alignItems='center'>
          <Box flexDirection='row' gap='l' alignItems='center'>
            <div className={c.divider} />

            <Typography
              data-testid={'or-login-with' /*Авторизация | или войти с помощью */}
              color='minor'
              variant='caption1'
              thickness='regular'
            >
              {t('core-auth.auth-container.or-login-with', 'или войти с помощью', {
                value: 'или войти с помощью'
              })}
            </Typography>

            <div className={c.divider} />
          </Box>

          <OauthButton />

          <Typography
            data-testid={'about-passport-auth' /*Авторизация | Пометка о наличии паспортной авторизации */}
            className={c.passportAuthCaption}
            variant='caption1'
            color='minor'
            thickness='regular'
          >
            {t('core-auth.auth-container.about-passport-auth', 'Пометка о наличии паспортной авторизации', {
              value:
                'Теперь вход в личный кабинет доступен с {domain}. Используйте этот способ, если уже привязали аккаунт.',
              domain: passportName
            })}{' '}
            <OutsideLink style={{whiteSpace: 'nowrap'}} href={passportHelpURL} target={'_blank'}>
              {t('core-auth.auth-container.about-new-auth', 'Про новую авторизацию', {
                value: 'О новой авторизации'
              })}
            </OutsideLink>
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default OldAuth
