import {useI18n} from '@eda-restapp/i18n'
import {SettingsOutlineIcon, Drawer, Box} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useState} from 'react'
import type {ReactNode, FC} from 'react'

import {Button} from '@restapp/shared-ui/Button'

import styles from './FiltersModal.module.css'

type FiltersModalProps = {
  className?: string
  children: ReactNode
}

export const FiltersModal: FC<FiltersModalProps> = ({className, children}) => {
  const {t} = useI18n()

  const [openMobileFilterModal, setOpenMobileFilterModal] = useState<boolean>(false)

  const handleCloseFiltersModal = () => {
    setOpenMobileFilterModal(false)
  }

  return (
    <>
      <Button
        slug='filters_modal_open'
        className={cn(styles.btn, className)}
        onClick={() => setOpenMobileFilterModal(true)}
      >
        <SettingsOutlineIcon />
      </Button>

      <Drawer.Panel opened={openMobileFilterModal} onClose={handleCloseFiltersModal}>
        <Drawer.Container>
          <Drawer.Header
            onClose={handleCloseFiltersModal}
            crossBtn
            text={t('shared-ui.filters-modal.title', 'Фильтры')}
          />
          <Drawer.Content>
            <Box px='m' pt='m' className={styles.drawerContent} flexDirection='column'>
              {children}
            </Box>
          </Drawer.Content>
        </Drawer.Container>
      </Drawer.Panel>
    </>
  )
}
