import {makeStyles} from 'tss-react/mui'

const widgetPadding = 16

export const useStyles = makeStyles({name: 'InfoWidget'})((theme) => {
  return {
    root: {
      backgroundColor: '#F7F9FA',
      position: 'relative',
      borderRadius: 16,
      display: 'flex',
      padding: widgetPadding,
      '& > * + *': {
        marginLeft: 10
      }
    },
    info: {
      flex: 0,
      minWidth: 20
    },
    iconMinor: {
      color: 'var(--theme-iconMinor, #bec1c4)'
    },
    iconWarning: {
      color: 'var(--theme-warning, #fcb900)'
    },
    centerIcon: {
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    column: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignSelf: 'center',
      overflowY: 'auto',
      maxHeight: 'inherit',
      padding: `${widgetPadding}px 0`,
      marginTop: -widgetPadding,
      marginBottom: -widgetPadding
    },
    close: {
      position: 'absolute',
      right: 10,
      top: -10,
      zIndex: 1,

      '& svg': {
        cursor: 'pointer'
      },

      '& path': {
        fill: '#BEC1C4'
      }
    },
    mobileChevron: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    desktopButton: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
        marginTop: 8
      }
    }
  }
})
