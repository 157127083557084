import {makeStyles} from 'tss-react/mui'

export const useSectionFormStyles = makeStyles<{showIconOnMobile?: boolean}, 'root' | 'withSectionFields'>({
  name: 'SectionForm'
})((theme, props, classes) => {
  return {
    root: {
      padding: '18px 16px 16px',
      background: '#FFFFFF',
      borderRadius: '24px',

      [theme.breakpoints.up('md')]: {
        padding: '24px 32px'
      }
    },
    withSectionFields: {
      [`&.${classes.root}`]: {
        padding: 0,
        borderRadius: 0,
        background: 'transparent',

        [theme.breakpoints.up('md')]: {
          background: '#FFFFFF',
          borderRadius: '24px',
          padding: '24px 32px'
        }
      }
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 18,

      [theme.breakpoints.up('md')]: {
        paddingBottom: 24
      },

      [`.${classes.root}.${classes.withSectionFields} &`]: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
          display: 'flex'
        }
      }
    },
    title: {
      flex: 1,
      fontSize: '20px',
      lineHeight: '23px',
      letterSpacing: '-0.01em',
      color: '#1F2021',
      fontWeight: 500,

      [theme.breakpoints.up('md')]: {
        fontWeight: 700
      }
    },
    icon: {
      display: props.showIconOnMobile ? 'flex' : 'none',
      marginRight: 16,
      fontSize: 24,

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        marginRight: 8
      }
    },
    content: {}
  }
})
