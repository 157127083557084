import type {TextConfig} from '../../../types/common'

import drawBlurredRoundRect from './drawBlurredRoundRect'
import drawTextLine from './drawTextLine'
import measureLines from './measureLines'
import splitTextToLines from './splitTextToLines'

const drawTextConfig = ({
  textConfig: {text, lineHeight, fontSize, box, fontWeight, color},
  ctx,
  canvasRect
}: {
  textConfig: TextConfig
  drawBack?: boolean
  ctx: CanvasRenderingContext2D
  canvasRect: {width: number; height: number}
}) => {
  const font = `${fontWeight} ${fontSize}px/${lineHeight}px YS Text`
  ctx.save()
  ctx.font = font
  const lines = splitTextToLines({lineHeight, text, box, ctx, canvasRect})

  const {width: textWidth, height: textHeight} = measureLines(ctx, lines, lineHeight)

  ctx.restore()

  const {fill, filter, yPadding, xPadding} = box
  const expand = lines.length > 1 && !box.radius && fill

  if (fill) {
    const height = textHeight + (yPadding ?? 0) * 2
    const width = textWidth + (xPadding ?? 0) * 2
    const top = 'top' in box ? box.top : canvasRect.height - box.bottom - height
    const left = 'left' in box ? box.left : canvasRect.width - box.right - width
    const simpleRadius = box.radius ? box.radius : height / 2
    const radius = expand ? simpleRadius / 2 : simpleRadius

    drawBlurredRoundRect(ctx, {left, top, width, height, radius, fill, filter})
  }

  /**
   * Если линии внутри коробки, их нужно сдвинуть на величину паддинга коробки
   */
  const packedLines = fill
    ? lines.map(({x, y, text}) => {
        return {
          x: 'left' in box ? x + (xPadding ?? 0) : x - (xPadding ?? 0),
          y: 'top' in box ? y + (yPadding ?? 0) : y - (yPadding ?? 0),
          text
        }
      })
    : lines

  packedLines.forEach(
    drawTextLine({font, ctx, textBaseline: 'bottom', textAlign: 'left' in box ? 'start' : 'end', color})
  )
}

export default drawTextConfig
