import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'

interface UseBusyModeQueryOptions {
  placeId?: number
}

export function useBusyModeQuery({placeId}: UseBusyModeQueryOptions) {
  const {getPlaceById} = usePlaces()
  const {t} = useI18n()

  const placeName = placeId ? getPlaceById(placeId)?.name : undefined

  return useApiQuery({
    url: '/4.0/restapp-front/places/v1/busy-mode',
    method: 'GET',
    params: {place_id: placeId || NaN},
    enabled: placeId !== undefined,
    staleTime: 60_000,
    onError: (error) => {
      Snackbar.error({
        text:
          (!!placeName &&
            t(
              'shared-places.use-busy-mode-query.no-place-busy-mode-data-error',
              'Не смогли получить данные по высокой загрузке для ресторана «{placeName}» — обновите страницу или попробуйте позже',
              {
                placeName
              }
            )) ||
          error.responseData?.message ||
          error.message,
        errorCode: error.traceId
      })
    }
  })
}
