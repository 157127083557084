import {useI18n} from '@eda-restapp/i18n'
import {Drawer} from '@eda-restapp/ui'
import type {FC, ReactNode} from 'react'
import React from 'react'

type CourierFeedbackProps = {
  isOpen?: boolean
  children?: ReactNode
  onClose?: () => void
}

export const CourierFeedback: FC<CourierFeedbackProps> = ({isOpen, children, onClose}) => {
  const {t} = useI18n()

  return (
    <Drawer.Panel opened={isOpen} onClose={onClose}>
      <Drawer.Container scroll='scroll'>
        <Drawer.Header
          onClose={onClose}
          crossBtn
          text={t('shared-microfrontend.courier-feedback.title', 'Оценить работу курьера')}
          data-testid={'courier-rate-drover-title' /*Заголовок дровера оценки курьера*/}
        />

        <Drawer.Content>{children}</Drawer.Content>
      </Drawer.Container>
    </Drawer.Panel>
  )
}
