import type {AppNotifications, NotificationsChannel} from '@eda-restapp/microfrontend'
import type {XivaNotification} from '../types'

interface NotificationAdditionalMeta {
  clicked: boolean
  source: AppNotifications['meta']['source']
}

export async function broadcastXivaNotification(
  channel: NotificationsChannel,
  xivaNotification: XivaNotification,
  additionalMeta: NotificationAdditionalMeta
): Promise<void> {
  const notificationData = xivaNotification.eventData
  if (!notificationData) {
    throw new Error('No eventData in xivaNotification')
  }

  const meta = {
    id: xivaNotification.id,
    notification_body: xivaNotification.notification_body,
    ...additionalMeta
  }

  switch (notificationData.event) {
    case 'place_hero_moderated': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'order_new': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'order_changed_status': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'order_changed_items': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'order_changed_courier': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'order_updated_courier': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'place_leader_changed': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'place_pull_all': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'place_toggle': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'no_unread_messages': {
      const {event} = notificationData
      await channel.broadcast({event, meta})
      break
    }
    case 'new_message': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'new_yamb_message': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'new_yamb_csat_message': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'place_blocked': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'bonus-points.low_balance_push': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'bonus-points.burn_soon_push': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    case 'bonus-points.end_soon_push': {
      const {event, ...data} = notificationData
      await channel.broadcast({event, data, meta})
      break
    }
    default: {
      throw new Error('unsupported push event')
    }
  }
}
