import {makeStyles} from 'tss-react/mui'

export type InputClasses =
  | 'root'
  | 'clearIcon'
  | 'outlined'
  | 'filled'
  | 'labelRoot'
  | 'labelFocused'
  | 'labelError'
  | 'labelDisabled'
  | 'labelShrink'
  | 'labelAsterisk'
  | 'inputFormControl'
  | 'inputFocused'
  | 'inputError'
  | 'inputDisabled'
  | 'inputInput'
  | 'inputAdornedStart'
  | 'inputAdornedEnd'
  | 'helperTextRoot'
  | 'noHelperText'
  | 'helperText'

export default makeStyles({name: 'Input'})({
  root: {},
  clearIcon: {
    fontSize: 16,
    color: '#bec1c4',
    cursor: 'pointer',
    transition: 'color 0.3s ease',

    '&:hover': {
      color: '#A7AAAC'
    }
  },
  outlined: {
    border: '1px solid #DCDEE0',
    '&:not($inputError):not($inputFocused):not($inputDisabled):hover': {
      border: '1px solid #999C9E'
    }
  },
  filled: {
    backgroundColor: '#F2F4F5',
    border: '2px solid transparent'
  },
  //#region Label
  labelRoot: {
    color: '#1F2021',
    fontSize: 14,
    lineHeight: '18px',
    transform: 'none',

    '&&': {
      color: '#1F2021'
    }
    // '&$labelFocused, &$labelError, &$labelDisabled': {
    //   color: '#1F2021'
    // }
  },
  labelFocused: {},
  labelError: {},
  labelDisabled: {},
  labelShrink: {
    transform: 'translate(0, 0) scale(1)'
  },
  labelAsterisk: {
    color: '#F5523A'
  },
  //#endregion Label

  //#region Input
  inputFormControl: {
    minHeight: 40,
    borderRadius: 12,
    padding: 1,
    transition: 'border-color 0.2s ease, box-shadow 0.2s ease',

    'label + &': {
      marginTop: 24
    }
  },
  inputFocused: {
    '&&': {
      boxShadow: '0 0 0 1px #FCE45E',
      border: '1px solid #FCE45E'
    }
  },
  inputError: {
    '&&': {
      boxShadow: '0 0 0 1px #F5523A',
      border: '1px solid #F5523A'
    }
  },
  inputDisabled: {},
  inputInput: {
    padding: '10px 8px',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
    height: '1.1876em'
  },
  inputAdornedStart: {
    paddingLeft: 9,
    '&$focused, &$error': {
      paddingLeft: 8
    }
  },
  inputAdornedEnd: {
    paddingRight: 9,
    '&$focused, &$error': {
      paddingRight: 8
    }
  },
  //#endregion Input

  //#region Helper text
  helperTextRoot: {
    color: '#999C9E',
    fontSize: 14,
    lineHeight: '18px'
  },
  noHelperText: {
    visibility: 'hidden',
    opacity: 0,
    maxHeight: 0,
    marginTop: 0,
    transition: 'max-height 0.15s ease-in, visibility 0s 0.15s, opacity 0.15s linear, margin-top 0.15s linear'
  },
  helperText: {
    visibility: 'visible',
    opacity: 1,
    maxHeight: '50px', // должно быть заведомо больше фактического размера, но не слишком, чтобы не афектить время анимации
    marginTop: 4,
    transition: 'max-height 0.15s ease-in, opacity 0.15s linear, margin-top 0.15s linear'
  }
  //#endregion Helper text
})
