import {useRef, useEffect} from 'react'
import type {RefObject} from 'react'

export type UseCanvasReturnType = {
  ref: RefObject<HTMLCanvasElement>
  width?: number
  height?: number
  stringify: (canvas: HTMLCanvasElement) => string
  canvas: HTMLCanvasElement | null
}

type UseCanvasPropsType = {
  draw?: (context: CanvasRenderingContext2D) => void
  width?: number
  imageWidth?: number
  height?: number
  imageHeight?: number
}

const stringify = (canvas: HTMLCanvasElement) => canvas.toDataURL()

export const useCanvas = ({draw, width, height}: UseCanvasPropsType = {}): UseCanvasReturnType => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvas = canvasRef.current
  const context = canvas?.getContext('2d')

  useEffect(() => {
    if (context && draw) {
      const animationFrameId = window.requestAnimationFrame(() => {
        draw(context)
      })

      return () => {
        window.cancelAnimationFrame(animationFrameId)
      }
    }
  }, [draw, context])

  return {ref: canvasRef, width, height, canvas, stringify}
}
