import {errorLogger, eventLogger} from '@eda-restapp/logger'
import type {ReactElement} from 'react'
import {useState, useEffect} from 'react'
import {Workbox, type WorkboxEvent, type WorkboxEventMap} from 'workbox-window'

type ServiceWorkerProps = {
  children: (workbox: Workbox) => ReactElement
}

export const ServiceWorker = ({children}: ServiceWorkerProps) => {
  const [workbox] = useState(() => new Workbox('/sw.js'))

  useWorkboxLogs(workbox)

  useEffect(() => {
    workbox
      .register()
      .then(() => {
        workbox.messageSW({type: 'GET_VERSION'}).then((version) => {
          if (typeof version === 'string') {
            eventLogger({
              value: 'sw:version',
              name: 'sw',
              additional: {version}
            })
          }
        })
      })
      .catch((reason) => {
        errorLogger({
          level: 'error',
          sourceType: 'sw',
          error: reason,
          additional: {eventSlug: 'sw:workbox-registration-error'}
        })
      })
  }, [workbox])

  return children(workbox)
}

const loggableEvents: Array<keyof WorkboxEventMap> = ['installed', 'activated', 'redundant', 'controlling', 'waiting']

const useWorkboxLogs = (workbox: Workbox): void => {
  useEffect(() => {
    const writeWorkboxLog = (event: WorkboxEvent<keyof WorkboxEventMap>) => {
      eventLogger({name: `sw:lifecycle:${event.type}`, additional: {...event}})
    }

    loggableEvents.forEach((event) => workbox.addEventListener(event, writeWorkboxLog))

    return () => loggableEvents.forEach((event) => workbox.removeEventListener(event, writeWorkboxLog))
  }, [workbox])
}
