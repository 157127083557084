import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {usePermission} from '@eda-restapp/permissions'
import {Box, useIsDesktopStrict, useIsTabletStrict} from '@eda-restapp/ui'
import moment from 'moment'
import React, {useState, useMemo, useEffect} from 'react'
import {useInView} from 'react-intersection-observer'

import {usePlaces, usePlacesApi} from '@restapp/core-places'
import type {Service, PlacePlus, ShortRestaurant, YaPlusStatusResponse} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'

import {useContractPlaces} from '../../hooks/useContractPlaces'
import {useDisabledPlusForDCPlace} from '../../hooks/useDisabledPlusForDCPlace'
import {useYaPlusEnable} from '../../hooks/useYaPlusEnable'
import {useYaPlusStatus} from '../../hooks/useYaPlusStatus'

import {ConnectFirstTimeCondition} from './ConnectFirstTimeCondition'
import ConnectMoreCondition from './ConnectMoreCondition'
import {EnablePlusModal} from './EnablePlusModal'
import {filterContractPlacesToRequest} from './utils/filterContractPlacesToRequest'

type IEnablePlusModalTypes = 'success' | 'onlyContract' | 'partSuccess'

type IFilterContractPlace = PlacePlus & {
  enableServices?: Service[]
}

type IServicesStartDate = {dc?: string; eda?: string}

export const PlusDrawer = () => {
  const {t} = useI18n()

  const isDesktopStrict = useIsDesktopStrict()
  const isTabletStrict = useIsTabletStrict()
  const isDesktop = isDesktopStrict || isTabletStrict
  const canManagePlus = usePermission([
    'permission.tools.common',
    'permission.tools.subscription.common',
    'permission.plus.edit'
  ])

  const {selectedPlaceId, getPlaceById} = usePlaces()
  const place = getPlaceById(selectedPlaceId)
  const {places, placesIds} = usePlacesApi()
  const [enablePlusModalVariant, setEnablePlusModalVariant] = useState<IEnablePlusModalTypes>('success')
  const [isOpenPlusEnable, setIsOpenPlusEnable] = useState<boolean>(false)
  const [isConnectMorePlacesToPlus, setIsConnectMorePlacesToPlus] = useState<boolean>(false)
  const [filteredContractPlaces, setFilteredContractPlaces] = useState<IFilterContractPlace[]>([])
  const [amountPlacesWithEnablePlus, setAmountPlacesWithEnablePlus] = useState<number>(0)

  const {ref, inView} = useInView({triggerOnce: true, threshold: 0})

  const initialServices = useMemo(
    () =>
      places?.reduce<Service[]>((places, place) => {
        if (place.services && place.services.enabled) {
          place.services.enabled.map((service) => {
            if (!places.includes(service)) {
              places.push(service)
            }
          })
        }

        return places
      }, []),
    [places]
  )

  const [modalService, setModalService] = useState<Service[]>(initialServices || ['eda', 'dc'])
  const [selectedServices, setSelectedServices] = useState<Service[]>(initialServices || ['eda', 'dc'])
  const [selectedPlaces, setSelectedPlaces] = useState<number[]>([])
  const [successModalStartDate, setSuccessModalStartDate] = useState<IServicesStartDate>({
    dc: undefined,
    eda: undefined
  })

  const {enabled, plusCommission, plusEnableTimeInHours} = useExp3('restapp_plus')

  const {data: plusPlaces, isLoading} = useYaPlusStatus(placesIds ? placesIds : [], {
    enabled: enabled,
    select: (data) => data.places,
    refetchInterval: 10_000
  })

  const enableMutation = useYaPlusEnable()

  const isDisableForDc = useDisabledPlusForDCPlace(place)

  const plusPlacesIds = useMemo(() => plusPlaces?.map((item) => item.place_id), [plusPlaces])

  const {places: contractPlaces, placeById: getContractInfoById} = useContractPlaces(plusPlacesIds || [])

  const drawerPlacesMap = useMemo(() => {
    if (!places) {
      return {}
    }

    return places.reduce<Record<number, PlacePlus>>((places, place) => {
      const plus = plusPlaces?.find((item) => item.place_id === place.id)

      if (!plus) {
        return places
      }

      const contractInfo = getContractInfoById(place.id)

      const isNotEnabledPlace = !place.services?.enabled || place.services?.enabled.length < 1

      const ratingError = isNotEnabledPlace
        ? t(
            'shared-places.plus-drawer.not-enabled-place',
            'Ресторан не подключён к сервисам {eatsServiceBrandLabel} и {dcServiceBrandLabel}'
          )
        : plus.place_info_by_service?.find((plusInfo) => plusInfo.statuses?.[0]?.reasons?.[0])?.statuses?.[0]
            ?.reasons?.[0].message

      places[place.id] = {
        ...place,
        ratingError: ratingError,
        status: isNotEnabledPlace
          ? YaPlusStatusEnum.CANNOT_BE_ACTIVATED
          : plus.place_info_by_service?.find((plusInfo) => plusInfo.statuses?.[0])?.statuses?.[0]?.status,
        contractInfo,
        isAvailable:
          !isNotEnabledPlace &&
          plus.place_info_by_service?.some(
            (plusInfo) => plusInfo.statuses?.[0].status === YaPlusStatusEnum.CAN_BE_ACTIVATED
          ),
        plus: plus.place_info_by_service,
        showInfo: false
      }

      return places
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places, plusPlaces, getContractInfoById])

  const drawerPlaces = useMemo(() => (drawerPlacesMap ? Object.values(drawerPlacesMap) : []), [drawerPlacesMap])

  const hasContractPlaces =
    contractPlaces &&
    contractPlaces?.length > 0 &&
    contractPlaces.some(
      (item) => item.services.length > 0 && item.services.some((service) => service.contract_type === 'contract')
    )

  const availablePlaces = useMemo(
    () =>
      drawerPlaces?.filter(
        (place) =>
          place.isAvailable &&
          place.plus?.find((item) =>
            item.statuses.some((plusInfo) => plusInfo.status === YaPlusStatusEnum.CAN_BE_ACTIVATED)
          )
      ),
    [drawerPlaces]
  )

  const isOnePlace = drawerPlaces && drawerPlaces.length === 1

  const isSingleServicePlaces = Boolean(!isOnePlace && initialServices && initialServices.length < 2)

  const isOneAvailableUnitedPlace =
    isOnePlace && drawerPlaces[0]?.services && drawerPlaces[0].services.enabled?.length === 2

  const isOneAvailableNotUnitedPlace =
    isOnePlace && drawerPlaces[0]?.services && drawerPlaces[0].services.enabled?.length < 2

  const isOneFullContractPlace =
    isOnePlace &&
    hasContractPlaces &&
    contractPlaces[0].services.every((service) => service.contract_type === 'contract')

  const isOneUnitedContractPlace =
    isOnePlace &&
    hasContractPlaces &&
    contractPlaces[0].services.length > 1 &&
    contractPlaces[0].services.some((service) => service.contract_type === 'contract') &&
    !isOneFullContractPlace

  const isEdaPartContractUnitedPlace =
    isOneUnitedContractPlace &&
    contractPlaces[0].services.some((item) => item.service === 'eda' && item.contract_type === 'contract')

  const isDcPartContractUnitedPlace =
    isOneUnitedContractPlace &&
    contractPlaces[0].services.some((item) => item.service === 'dc' && item.contract_type === 'contract')

  const isUnavailable = drawerPlaces?.every((place) =>
    place.plus?.some(
      (plusInfo) =>
        plusInfo.statuses[0].status === YaPlusStatusEnum.CANNOT_BE_ACTIVATED ||
        place.status === YaPlusStatusEnum.CANNOT_BE_ACTIVATED
    )
  )

  const isDisabledState =
    isUnavailable || isDisableForDc || (availablePlaces && availablePlaces.length === 0) || selectedPlaces.length === 0

  const successEnablePlus = (data: YaPlusStatusResponse, isPartSuccess: boolean) => {
    const startDcDate = data.places
      .find((place) => place?.place_info_by_service?.find((serviceInfo) => serviceInfo.service === 'dc'))
      ?.place_info_by_service?.find((serviceInfo) => serviceInfo.service === 'dc')?.statuses[0].starts

    const startEdaDate = data.places
      .find((place) => place?.place_info_by_service?.find((serviceInfo) => serviceInfo.service === 'eda'))
      ?.place_info_by_service?.find((serviceInfo) => serviceInfo.service === 'eda')?.statuses[0].starts

    setSuccessModalStartDate({dc: startDcDate, eda: startEdaDate})

    if (isPartSuccess) {
      setEnablePlusModalVariant('partSuccess')
    } else {
      setEnablePlusModalVariant('success')
    }

    setIsOpenPlusEnable(true)
  }

  const enablePlus = (placesIds: number[], enableServices: Service[]) => {
    eventLogger({name: 'plus-enable-button'})

    const chosenPlaces = placesIds
      .map((id) => drawerPlacesMap[id])
      .filter(Boolean)
      .filter((place) => place?.services?.enabled && place?.services?.enabled.length > 0) as ShortRestaurant[]

    if (
      isOneFullContractPlace ||
      (isEdaPartContractUnitedPlace && enableServices.length < 2 && enableServices.includes('eda')) ||
      (isDcPartContractUnitedPlace && enableServices.length < 2 && enableServices.includes('dc'))
    ) {
      setFilteredContractPlaces(chosenPlaces)
      setIsOpenPlusEnable(true)
      setEnablePlusModalVariant('onlyContract')

      return
    }

    let requestServices: Service[]
    let isPartSuccess = false

    const {contractPlaces, placesForRequest} = filterContractPlacesToRequest(chosenPlaces, enableServices)

    setAmountPlacesWithEnablePlus(placesForRequest.length)

    if (contractPlaces.length > 0) {
      isPartSuccess = true

      setFilteredContractPlaces(contractPlaces)

      if (placesForRequest.length === 0) {
        setIsOpenPlusEnable(true)
        setEnablePlusModalVariant('onlyContract')

        return
      }
    }

    const hasUnitedPlaces = chosenPlaces.some((item) => item.services && item.services?.enabled?.length > 1)

    const chosenPlacesServices = placesForRequest?.reduce<Service[]>((placesServices, place) => {
      if (placesServices.length > 1) {
        return placesServices
      }

      if (place.enableServices) {
        place.enableServices.forEach((service) => {
          if (!placesServices.includes(service)) {
            placesServices.push(service)
          }
        })
      }

      return placesServices
    }, [])

    if (hasUnitedPlaces && contractPlaces.length < 1) {
      requestServices = enableServices
    } else {
      requestServices = chosenPlacesServices
    }

    setModalService(requestServices)

    const requestCashbacks =
      placesForRequest.map((place) => {
        eventLogger({
          name: 'plus-drawer-enable-plus',
          additional: {services: enableServices, placeServices: place?.enableServices}
        })

        return {
          place_id: place.id,
          cashback: plusCommission.toString(),
          starts: moment().add(plusEnableTimeInHours, 'hours').toISOString(),
          services:
            (requestServices.length === 2 && place?.enableServices?.length === 2) || requestServices.length === 1
              ? requestServices
              : place?.enableServices
        }
      }) || []

    enableMutation.mutate(
      {
        body: {
          cashbacks: requestCashbacks
        }
      },
      {
        onSuccess: (data) => successEnablePlus(data, isPartSuccess)
      }
    )
  }

  const handleCloseEnablePlusModal = () => {
    if (!isOnePlace) {
      setSelectedServices(initialServices || ['eda', 'dc'])
    }

    setFilteredContractPlaces([])
    setSuccessModalStartDate({dc: undefined, eda: undefined})
    setIsOpenPlusEnable(false)
  }
  useEffect(() => {
    if (inView) {
      eventLogger({
        name: 'on_screen',
        value: 'plus-drawer-opened'
      })
    }
  }, [inView])

  useEffect(() => {
    if ((isOneAvailableNotUnitedPlace || isOneAvailableUnitedPlace) && availablePlaces && availablePlaces?.[0]) {
      setSelectedPlaces([availablePlaces[0]?.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneAvailableNotUnitedPlace, isOneAvailableUnitedPlace, availablePlaces])

  return (
    <>
      <Box data-testid={'plus-drawer-box' /* Шторка Плюса */} ref={ref} flexDirection='column' flex={1}>
        {isConnectMorePlacesToPlus ? (
          <ConnectMoreCondition
            selectedPlaces={selectedPlaces}
            selectedServices={selectedServices}
            drawerPlaces={drawerPlaces}
            isSingleServicePlaces={isSingleServicePlaces}
            isDisabledState={isDisabledState}
            setSelectedPlaces={setSelectedPlaces}
            setSelectedServices={setSelectedServices}
            switchBack={() => setIsConnectMorePlacesToPlus(false)}
            enablePlus={enablePlus}
          />
        ) : (
          <ConnectFirstTimeCondition
            canManagePlus={canManagePlus}
            drawerPlaces={drawerPlaces}
            initialServices={initialServices}
            selectedPlaces={selectedPlaces}
            selectedServices={selectedServices}
            isUnavailable={isUnavailable}
            isOneFullContractPlace={isOneFullContractPlace}
            isDisabledState={isDisabledState}
            isLoading={isLoading}
            isDesktop={isDesktop}
            isFetchLoading={enableMutation.isLoading}
            isOneAvailableUnitedPlace={isOneAvailableUnitedPlace}
            isSingleServicePlaces={isSingleServicePlaces}
            setSelectedPlaces={setSelectedPlaces}
            setSelectedServices={setSelectedServices}
            setIsConnectMorePlacesToPlus={setIsConnectMorePlacesToPlus}
            enablePlus={enablePlus}
          />
        )}
      </Box>

      {isOpenPlusEnable && (
        <EnablePlusModal
          services={modalService ?? ['eda']}
          startDate={successModalStartDate}
          close={handleCloseEnablePlusModal}
          opened={isOpenPlusEnable}
          isOnePlace={isOnePlace}
          isOneUnitedPlace={isOneAvailableUnitedPlace}
          variant={enablePlusModalVariant}
          contractPlaces={filteredContractPlaces}
          amountPlacesWithEnablePlus={amountPlacesWithEnablePlus}
        />
      )}
    </>
  )
}
