import {useExp3} from '@eda-restapp/configs'
import {useIsMobileStrict} from '@eda-restapp/ui'

const useDisabledByDevice = () => {
  const isMobile = useIsMobileStrict()
  const {mobile_enabled} = useExp3('restapp_promotion')

  return isMobile && !mobile_enabled
}

export default useDisabledByDevice
