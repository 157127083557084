import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, UserAvatar, ChevronRightMIcon, Clamp} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type CSSProperties} from 'react'

import styles from './ActivePartnerId.module.css'

export type ActivePartnerIdProps = {
  className?: string
  style?: CSSProperties
  name: string
  placesCount: number
  linkedUsers: {login: string; avatar?: string}[]
  onChangePartnerId?: () => void
}

const MAX_DISPLAYED_AVATARS = 7

export const ActivePartnerId: React.FC<ActivePartnerIdProps> = ({
  name,
  placesCount,
  linkedUsers,
  onChangePartnerId,
  className,
  style
}) => {
  const {t} = useI18n()

  return (
    <div className={cn(styles.root, className)} style={style}>
      <Typography variant='body2' thickness='medium' mb='xs' className={styles.name}>
        <Clamp lines={1}>{name}</Clamp>
      </Typography>
      <Typography variant='caption1'>
        {t('core-auth.passport.active-partner-id.places-count', '{count} ресторанов', {
          one: '{count} ресторан',
          some: '{count} ресторана',
          many: '{count} ресторанов',
          none: 'Нет ресторанов',
          count: placesCount
        })}{' '}
        ·{' '}
        {t('core-auth.passport.active-partner-id.users-count', '{count} пользователей', {
          one: '{count} пользователь',
          some: '{count} пользователя',
          many: '{count} пользователей',
          count: linkedUsers.length
        })}
      </Typography>
      {linkedUsers.length > 1 && (
        <Box mt='s' mb='xs'>
          {linkedUsers.slice(0, MAX_DISPLAYED_AVATARS).map((user, i) => (
            <UserAvatar
              style={{zIndex: linkedUsers.length - i}}
              key={user.login}
              className={styles.avatar}
              src={user.avatar}
            />
          ))}
          {linkedUsers.length > MAX_DISPLAYED_AVATARS && (
            <div className={cn(styles.avatar, styles.moreAvatars)}>+{linkedUsers.length - MAX_DISPLAYED_AVATARS}</div>
          )}
        </Box>
      )}

      {onChangePartnerId && (
        <Box data-testid='change-partner-id-button' className={styles.changePartnerAction} onClick={onChangePartnerId}>
          {t('core-auth.passport.active-partner-id.change-partner-id', 'Сменить кабинет')}
          <ChevronRightMIcon />
        </Box>
      )}
    </div>
  )
}
