import {Box} from '@eda-restapp/ui'
import React, {type CSSProperties, forwardRef, type MouseEvent, type ReactNode} from 'react'

type TooltipBaseProps = {
  children: ReactNode
  style: CSSProperties
  className?: string
  close: () => void
}

type TooltipBaseRef = HTMLDivElement

export const TooltipBase = forwardRef<TooltipBaseRef, TooltipBaseProps>(
  ({className, style, children, close}, forwardedRef) => (
    <Box
      ref={forwardedRef}
      className={className}
      style={style}
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        close()
      }}
    >
      {children}
    </Box>
  )
)

TooltipBase.displayName = 'TooltipBase'
