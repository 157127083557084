import {makeStyles} from 'tss-react/mui'

import {VERTICAL_PADDING, BOTTOM_BORDER} from './Header.constants'

type HeaderClasses = 'root' | 'inner'

export type HeaderStylesProps = {
  classes?: Partial<Record<HeaderClasses, string>>
}

const headerStyles = {
  root: {
    top: 0,
    width: '100%',
    zIndex: 100,
    borderBottom: `solid ${BOTTOM_BORDER}px #E8EBED`,
    background: '#ffffff',
    padding: `${VERTICAL_PADDING}px 0`,
    ['@media print']: {
      display: 'none'
    }
  },
  inner: {
    width: '100%',
    height: '100%'
  }
}

export const useHeaderStyles = makeStyles({name: 'NewHeader'})(headerStyles)
