import type {ChangeEvent} from 'react'
import React from 'react'
import {makeStyles} from 'tss-react/mui'
import type {RadioProps as MUIRadioProps} from '@mui/material/Radio'
import CheckSvg from './Check.svg'
import {Radio as MuiRadio} from '@mui/material'
import {eventLogger} from '@eda-restapp/logger'

const useRadioStyles = makeStyles()({
  icon: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: '#E2E4E7'
  },
  checked: {
    backgroundColor: '#FCDA30',
    backgroundImage: `url(${CheckSvg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  disabled: {
    background: '#F4F6F7'
  }
})

type RadioProps = MUIRadioProps & {
  slug?: string
}

function Radio(props: RadioProps) {
  const {classes, cx} = useRadioStyles()
  const onChangeWithTracker = (evt: ChangeEvent<HTMLInputElement>, val: boolean) => {
    eventLogger({name: 'checkbox_change', value: props.slug, additional: {eventData: val}})
    props.onChange?.(evt, val)
  }

  return (
    <MuiRadio
      onChange={onChangeWithTracker}
      icon={<span className={cx(classes.icon, props.disabled && classes.disabled)} />}
      checkedIcon={<span className={cx(classes.icon, classes.checked)} />}
      {...props}
    />
  )
}

export default Radio
