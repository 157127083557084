import {EventEmitter} from 'events'
import {clamp} from 'lodash-es'
import type React from 'react'

import {FALLBACK_SNACKBAR_ERROR_MESSAGE} from '../constants/messages/errors'
import {nanoid} from 'nanoid'
import {Snackbar} from '@eda-restapp/snackbars'

const enum EmitterEvent {
  CURRENT_CHANGED = 'CURRENT_CHANGED'
}
type SnackbarType = 'success' | 'error' | 'info'

export type SnackbarConfig = {
  type: SnackbarType
  message?: string
  error?: {
    traceId?: string
  }
  showTime?: number
  icon?: React.ReactNode
  showCloseBtn?: boolean
  onClick?: () => void
  background?: string
  textColor?: string
}

export type Snackbar = {
  id: string
  type: SnackbarType
  message: React.ReactNode
  showTime: number
  error?: {
    traceId?: string
  }
  icon?: React.ReactNode
  showCloseBtn?: boolean
  onClick?: () => void
  background?: string
  textColor?: string
}

interface SnackbarNotifierConfig {
  enableNewSnackbars?: boolean
  defaultMessage?: string
  defaultShowTime?: number
  defaultErrorShowTime?: number
}

const DEFAULT_SHOW_TIME = 6000 // 5 сек + 1 сек на анимацию
const DEFAULT_ERROR_SHOW_TIME = 16000

export interface ISnackbarNotifier {
  current: Readonly<Snackbar> | undefined
  enqueue(config: SnackbarConfig): string
  remove(id: string): void
  onChangeCurrent(cb: (currentSnackbar?: Snackbar) => void): () => void
}

class SnackbarNotifier implements ISnackbarNotifier {
  private queue = [] as Snackbar[]
  private internalCurrent?: Snackbar
  private timerId?: number
  private emitter = new EventEmitter()

  constructor(private getConfig: () => SnackbarNotifierConfig) {}

  get current(): Readonly<Snackbar> | undefined {
    return this.internalCurrent
  }

  enqueue(config: SnackbarConfig) {
    const cfg = this.getConfig()

    const defaultMessage = cfg.defaultMessage || FALLBACK_SNACKBAR_ERROR_MESSAGE.default
    const defaultShowTime = cfg.defaultShowTime || DEFAULT_SHOW_TIME
    const defaultErrorShowTime = cfg.defaultErrorShowTime || DEFAULT_ERROR_SHOW_TIME

    const id = nanoid(10)

    let showTime = defaultShowTime

    if (config.showTime !== undefined) {
      showTime = clamp(config.showTime, 1_000, Infinity)
    } else if (config.error || config.type === 'error') {
      showTime = defaultErrorShowTime
    }

    const message = config.message || defaultMessage

    if (cfg.enableNewSnackbars) {
      Snackbar.show({
        id,
        type: config.type,
        text: message,
        errorCode: config.error?.traceId,
        showTime,
        onClick: config.onClick,
        background: config?.background,
        textColor: config?.textColor
      })

      return id
    }

    this.queue.push({
      ...config,
      message,
      showTime,
      id
    })

    if (!this.internalCurrent) {
      this.next()
    }

    return id
  }

  remove(id: string) {
    if (this.internalCurrent && this.internalCurrent.id === id) {
      this.next()
    } else {
      this.queue = this.queue.filter((snackbar) => snackbar.id !== id)
    }
  }

  onChangeCurrent = (cb: (currentSnackbar?: Snackbar) => void) => {
    this.emitter.addListener(EmitterEvent.CURRENT_CHANGED, cb)

    return () => {
      this.emitter.removeListener(EmitterEvent.CURRENT_CHANGED, cb)
    }
  }

  private next = () => {
    clearTimeout(this.timerId)

    this.internalCurrent = this.queue.shift()
    this.emitter.emit(EmitterEvent.CURRENT_CHANGED, this.internalCurrent)

    if (this.internalCurrent) {
      this.timerId = setTimeout(this.next, this.internalCurrent.showTime) as any as number
    }
  }
}

export default SnackbarNotifier
