import {makeStyles} from 'tss-react/mui'

export const useSubscriptionButtonTooltipStyles = makeStyles<void, 'copyBtn' | 'text' | 'label' | 'icon'>({
  name: 'SubscriptionButtonTooltip'
})((theme, _params, classes) => {
  return {
    tooltip: {
      marginLeft: '16px',
      [theme.breakpoints.up('md')]: {
        marginLeft: 'unset'
      },

      '& button': {
        width: '100%',
        height: 32,
        marginTop: 16
      },

      [`& .${classes.copyBtn}`]: {
        height: 40
      }
    },
    telegramIcon: {
      color: '#FFFFFF'
    },
    title: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '19px',
      textAlign: 'left',

      '& span': {
        fontWeight: 400
      },

      [`& .${classes.text}`]: {
        marginTop: 4,
        color: '#F5F4F2',
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '15px',
        opacity: 0.7
      }
    },
    subText: {
      textAlign: 'left',
      fontWeight: 400,
      fontSize: 13,
      marginTop: 4,
      color: '#F5F4F2',
      lineHeight: '15px'
    },
    closeIcon: {
      position: 'absolute',
      right: '-3px',
      top: '-3px',
      color: '#84878A',
      cursor: 'pointer',

      '&:hover': {
        color: '#FFFFFF'
      }
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 24,
      position: 'relative'
    },
    tgImg: {
      width: '100%'
    },
    copyBtn: {
      position: 'relative',
      width: '100%',
      height: 32,
      borderRadius: 8,
      backgroundColor: 'rgb(67, 68, 69)',
      display: 'flex',
      gap: 6,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginTop: 16,
      fontWeight: 500,
      color: '#FFFFFF',
      lineHeight: '18px',

      '&:hover': {
        backgroundColor: 'rgb(77, 78, 79)'
      },

      [`& .${classes.text}`]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#FFFFFF'
      },
      [`& .${classes.label}`]: {
        color: '#9E9B98',
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '13px'
      },
      [`& .${classes.icon}`]: {
        position: 'absolute',
        right: 8,
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#999C9E',
        fontSize: 20
      }
    },
    text: {},
    label: {},
    icon: {},
    actions: {
      display: 'flex'
    },
    btn: {
      backgroundColor: '#FCE000',
      borderRadius: 8,
      lineHeight: '18px',
      whiteSpace: 'nowrap',

      '&:hover': {
        backgroundColor: '#FFED60'
      }
    },
    btnSecondary: {
      marginRight: 8,
      backgroundColor: '#3F4042',
      color: '#FFFFFF',

      '&:hover': {
        backgroundColor: '#5c5d60'
      }
    }
  }
})
