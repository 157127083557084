import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {partnerUidChannel, type PartnerUidMessage, useChannelSubscription} from '@eda-restapp/microfrontend'
import type {ReactNode} from 'react'
import React, {useEffect} from 'react'
import {useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, useMatch, useNavigate} from 'react-router-dom'

import {invalidPartnerId, selectOauthToken, selectPartnerId, setPartnerId} from '@restapp/core-auth'
import {useEnv} from '@restapp/core-di/hooks'
import {AppEvent} from '@restapp/core-legacy/types'

import {usePassportEnabled} from '../../hooks'
import {SelectPartnerUidContainer} from '../SelectPartnerUid'

import {SELECT_PARTNER_UID_PATH} from './getPartnerUidSelectorPath'

type PartnerUidGuardProps = {
  children: ReactNode
}

export const PartnerUidGuard: React.FC<PartnerUidGuardProps> = ({children}) => {
  const match = useMatch(SELECT_PARTNER_UID_PATH)
  const passportEnabled = usePassportEnabled()
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const partnerId = useSelector(selectPartnerId)
  const oauthToken = useSelector(selectOauthToken)
  const dispatch = useDispatch()

  useHandlePartnerUidErrors()

  useChannelSubscription<PartnerUidMessage>(
    partnerUidChannel,
    ({type, payload}) => {
      if (!passportEnabled.enabled) {
        return
      }

      if (type === 'ChangePartnerUid') {
        dispatch(setPartnerId(payload.partnerUid))
        void queryClient.invalidateQueries(['/4.0/restapp-front/partners/v2/info', 'GET'])

        queryClient.removeQueries()

        /* Если ты находишься на /select-account, и тебе прилетает partnerId -> уходим со страницы*/
        match && navigate('/')
      }
    },
    {ignoreCurrentWindow: true}
  )

  /* Броадкастим новый partnerId во все вкладки на нашем домене */
  const broadcastNewPartnerUid = (partnerUid: string) => {
    eventLogger({
      name: 'passport:change_partner_uid',
      additional: {oldPartnerUid: partnerId, newPartnerUid: partnerUid}
    })
    dispatch(setPartnerId(partnerUid))
    void queryClient.invalidateQueries(['/4.0/restapp-front/partners/v2/info', 'GET'])

    queryClient.removeQueries()

    partnerUidChannel
      .broadcast({
        type: 'ChangePartnerUid',
        payload: {
          partnerUid
        }
      })
      .catch((err) => {
        errorLogger({
          level: 'error',
          error: err instanceof Error ? err : new Error('passport:failed_to_broadcast_partner_id'),
          message: err instanceof Error ? err.message : 'passport:failed_to_broadcast_partner_id',
          additional: {eventSlug: 'passport:failed_to_broadcast_partner_id'}
        })
      })
  }

  if (match && oauthToken) {
    return <SelectPartnerUidContainer onSelectPartnerUid={broadcastNewPartnerUid} />
  }

  if (oauthToken && !partnerId) {
    return <Navigate to={SELECT_PARTNER_UID_PATH} />
  }

  return <>{children}</>
}

const useHandlePartnerUidErrors = () => {
  const env = useEnv()
  const dispatch = useDispatch()
  const partnerId = useSelector(selectPartnerId)

  useEffect(() => {
    const errorRequestHandler = () => {
      errorLogger({
        level: 'error',
        error: new Error('passport:invalid_partner_id_request_error'),
        message: 'passport:invalid_partner_id_request_error',
        additional: {partnerId, eventSlug: 'passport:invalid_partner_id_request_error'}
      })

      if (partnerId) {
        dispatch(invalidPartnerId())
      }
    }

    env.api.events.addListener(AppEvent.NoPartnerIdError, errorRequestHandler)

    return () => {
      env.api.events.removeListener(AppEvent.NoPartnerIdError, errorRequestHandler)
    }
  }, [dispatch, env.api.events, partnerId])
}
