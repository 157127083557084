import {makeStyles} from 'tss-react/mui'

import businessPromoIcon from './assets/businessPromo.png'
import promoIcon from './assets/promo.png'

export const useSubscriptionButtonStyles = makeStyles<void, 'icon' | 'businessIcon'>({name: 'SubscriptionButton'})((
  theme,
  _params,
  classes
) => {
  return {
    container: {
      position: 'relative'
    },
    root: {
      width: '100%',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 12,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      cursor: 'pointer',
      transition: '.125s opacity',
      background: 'linear-gradient(105.05deg, #8B4DDA 19.87%, #6512CF 74.17%), #6226AF',
      paddingRight: 4,
      overflow: 'hidden',

      '&:hover': {
        opacity: 0.9
      },

      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        width: 'max-content'
      }
    },
    withoutSubscribtion: {
      color: '#FFFFFF',

      [theme.breakpoints.up('md')]: {
        paddingRight: 24
      },

      [`& .${classes.icon}`]: {
        fontSize: 43
      }
    },
    withSubscription: {
      paddingLeft: 12,
      background:
        'radial-gradient(75.61% 406.82% at 6.83% 0%, rgba(111, 33, 210, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #EDF0F1',

      [theme.breakpoints.up('md')]: {
        paddingRight: 16,
        paddingLeft: 16
      },

      [`& .${classes.icon}`]: {
        fontSize: 43
      }
    },
    promoSubscription: {
      color: '#FFFFFF',

      [theme.breakpoints.up('md')]: {
        paddingRight: 30
      },

      [`& .${classes.icon}`]: {
        width: 42,
        height: 40,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `url(${promoIcon})`
      }
    },
    icon: {
      marginRight: 8
    },
    iconArrow: {
      marginLeft: 'auto'
    },
    settingsIcon: {
      position: 'absolute',
      top: -6,
      right: -6,
      fontSize: 16,
      padding: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '-0.2px 1.1px 2.8px rgb(0 0 0 / 20%)'
    },
    text: {
      flexGrow: 1,
      textAlign: 'center'
    },
    businessIcon: {},
    businessPromoText: {
      fontSize: 14,
      fontWeight: 500,
      color: 'white',
      flexGrow: 1,
      textAlign: 'center'
    },
    businessPromoSubscriptionButton: {
      background: 'linear-gradient(99.04deg, #73757A 16.09%, #3F4042 38.78%, #25282E 87.26%)',

      [`& .${classes.businessIcon}`]: {
        width: 42,
        height: 40,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `url(${businessPromoIcon})`
      },

      [theme.breakpoints.up('md')]: {
        width: '221px'
      }
    }
  }
})
