import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'TimeRange'})({
  root: {},
  disabled: {
    position: 'relative',
    padding: '11px 16px',
    display: 'flex',
    flex: 1,
    width: '100%',
    minWidth: 240,
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 'none',
    borderRadius: 8,
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.3,
    cursor: 'default',
    userSelect: 'none',
    background: '#F7F9FA',
    color: '#999C9E'
  },
  timeInputBlock: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > div': {
      flex: 1
    },

    '& > div:first-child': {
      marginRight: 8
    }
  },
  timeInput: {
    height: 40,

    '&.Mui-disabled': {
      color: '#999C9E'
    }
  },
  error: {
    margin: '5px 0 0',
    width: '100%',
    color: '#e85b34'
  }
})
