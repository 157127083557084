import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Modal, Button, Multiline, Box, RadioGroup, Typography} from '@eda-restapp/ui'
import type {FC} from 'react'
import React, {useState, useEffect} from 'react'

import {Link} from '@restapp/shared-ui'

import styles from './SubscriptionFeedbackModal.module.css'

interface SubscriptionFeedbackProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const MULTILINE_MAX_HEIGHT = 178
const MULTILINE_MIN_HEIGHT = 80

const SubscriptionFeedbackModal: FC<SubscriptionFeedbackProps> = ({isOpen, onClose, onSubmit}) => {
  const {t, tRaw} = useI18n()

  const [comment, setComment] = useState<string>('')
  const [reason, setReason] = useState<string>()

  const handleChangeComment = (value: string) => setComment(value)

  const OTHER = t('shared-places.subscription-feedback-modal.other-reason-label', 'Другое')
  const allReasons = [
    t('shared-places.subscription-feedback-modal.difficult-reason-label', 'Не получилось разобраться'),
    t('shared-places.subscription-feedback-modal.no-services-reason-label', 'Нет нужных услуг'),
    t('shared-places.subscription-feedback-modal.good-reason-label', 'Всё хорошо и без подписки'),
    t('shared-places.subscription-feedback-modal.price-reason-label', 'Не подходит цена'),
    OTHER
  ]

  const handleSubmit = () => {
    metrika({
      target: 'subscription_feedback_modal_submit',
      params: {
        reason,
        feedback: comment,
        path: location.pathname
      }
    })

    onSubmit()
  }

  const handleClose = () => {
    metrika({
      target: 'subscription_feedback_modal_close',
      params: {
        path: location.pathname
      }
    })

    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      metrika({
        target: 'subscription_feedback_modal_open',
        params: {
          path: location.pathname
        }
      })
    }
  }, [isOpen])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Header
        title={t('shared-places.subscription-feedback-modal.title', 'Расскажите, почему решили отключить подписку')}
      />
      <Modal.Content>
        <Box flexDirection='column' gap='m'>
          <RadioGroup.List name='sub-reasons' onChange={(_evt, value) => setReason(value)} value={reason ?? ''}>
            {allReasons.map((reason) => (
              <RadioGroup.Item key={reason} value={reason} label={reason} className={styles.radioItem} />
            ))}
          </RadioGroup.List>

          <Box onClick={() => void setReason(OTHER)}>
            <Multiline.Minimal
              name={'comment'}
              value={comment}
              onChange={(evt) => handleChangeComment(evt.target.value)}
              placeholder={t('shared-places.subscription-feedback-modal.rasskazhite-podrobnee', 'Расскажите подробнее')}
              minHeight={MULTILINE_MIN_HEIGHT}
              maxHeight={MULTILINE_MAX_HEIGHT}
            />
          </Box>

          <Typography variant='body2' style={{display: 'block'}}>
            {tRaw(
              'shared-places.subscription-feedback-modal.settings-info',
              'Снова подключить подписку можно в разделе {link}.',
              {
                link: (
                  <Link to={'/tools/subscription'} onClick={handleClose}>
                    {t('shared-places.use-subscription-success-dialog.link-text-subcription', '«Подписка»')}
                  </Link>
                )
              }
            )}
          </Typography>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Button.Text
          variant='primary'
          fullWidth
          color='primary'
          disabled={!reason || (reason === OTHER && !comment)}
          onClick={handleSubmit}
        >
          {t('shared-places.subscription-feedback-modal.otpravit', 'Отправить')}
        </Button.Text>
      </Modal.Footer>
    </Modal>
  )
}

export default SubscriptionFeedbackModal
