import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {HolidayScheduleState, HolidaySchedule} from '../../../types/schedule'

const defaultSchedule: HolidaySchedule = {
  timeFrom: 0,
  timeTo: 0,
  allDay: false,
  disabledAllDay: false
}

const initialState: HolidayScheduleState = {
  initialSchedule: [defaultSchedule],
  schedule: [defaultSchedule],
  similarSchedulePlaces: []
}

export const placesHolidayScheduleSlice = createSlice({
  name: 'placesHolidaySchedule',
  initialState,
  reducers: {
    setInitialSchedule(state: HolidayScheduleState, action: PayloadAction<HolidaySchedule[]>) {
      state.initialSchedule = action.payload
    },
    setSchedule(state: HolidayScheduleState, action: PayloadAction<HolidaySchedule[]>) {
      state.schedule = action.payload
    },
    setDateFrom(state: HolidayScheduleState, action: PayloadAction<{date?: Date; index: number}>) {
      const {date, index} = action.payload

      state.schedule[index].dateFrom = String(date)
    },

    setDateTo(state: HolidayScheduleState, action: PayloadAction<{date?: Date; index: number}>) {
      const {date, index} = action.payload

      state.schedule[index].dateTo = String(date)
    },

    setTimeFrom(state: HolidayScheduleState, action: PayloadAction<{minutes: number; index: number}>) {
      const {minutes, index} = action.payload

      state.schedule[index].timeFrom = minutes
    },

    setTimeTo(state: HolidayScheduleState, action: PayloadAction<{minutes: number; index: number}>) {
      const {minutes, index} = action.payload

      state.schedule[index].timeTo = minutes
    },

    setAllDay(state: HolidayScheduleState, action: PayloadAction<{value: boolean; index: number}>) {
      const {value, index} = action.payload

      if (value && state.schedule[index].disabledAllDay) {
        state.schedule[index].disabledAllDay = false
      }

      state.schedule[index].allDay = value
    },

    setDisabledAllDay(state: HolidayScheduleState, action: PayloadAction<{value: boolean; index: number}>) {
      const {value, index} = action.payload

      if (value && state.schedule[index].allDay) {
        state.schedule[index].allDay = false
      }

      state.schedule[index].disabledAllDay = value
    },
    addSchedule(state: HolidayScheduleState) {
      state.schedule.push(defaultSchedule)
    },
    removeSchedule(state: HolidayScheduleState, action: PayloadAction<number>) {
      state.schedule.splice(action.payload, 1)
    },
    setSimilarPlaces(state: HolidayScheduleState, action: PayloadAction<number[]>) {
      state.similarSchedulePlaces = action.payload
    },
    resetSchedule() {
      return initialState
    }
  }
})
