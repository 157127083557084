import {get} from 'lodash-es'

export type Options = {
  fireImmediately?: boolean
}

export type PollingResponseType = {
  stop: () => void
  start: () => void
  isStarted: () => boolean
}

export const createPolling = (fn: () => void, pollInterval: number = 5000, options?: Options): PollingResponseType => {
  let intervalId: number | null
  let keepPolling = false
  const fireImmediately = get(options, 'fireImmediately', true)

  const poll = () => {
    ;(fireImmediately || keepPolling) && fn()
    intervalId = window.setTimeout(poll, pollInterval)

    return intervalId
  }

  const stop = () => {
    keepPolling = false

    if (intervalId) {
      clearTimeout(intervalId)
      intervalId = null
    }
  }

  const start = () => {
    stop()
    const id = poll()
    keepPolling = true
    return id
  }

  return {
    stop,
    start,
    isStarted() {
      return keepPolling
    }
  }
}
