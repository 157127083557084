import {useI18n} from '@eda-restapp/i18n'
import {SettingsOutlineIcon} from '@eda-restapp/ui'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {MultiplePlacesDropdown, usePlaces} from '@restapp/core-places'
import {Header, HeaderRow, SidebarBurger} from '@restapp/main-layout'
import {useServiceBrand} from '@restapp/shared'
import {Button} from '@restapp/shared-ui/Button'
import RefreshButton from '@restapp/shared-ui/RefreshButton'

import {chatsSlice} from '../../slice/chatsSlice'
import NewChatButton from '../NewChatButton/NewChatButton'

import ChatsFiltersModal from './ChatsFiltersModal'

const useStyles = makeStyles()({
  filtersBtn: {
    minWidth: 0,
    padding: '0 8px',
    marginRight: 16,
    marginLeft: 'auto',
    position: 'relative'
  },
  changedFiltersCount: {
    borderRadius: '50%',
    backgroundColor: '#FCE45E',
    position: 'absolute',
    top: 3,
    right: 3,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    height: 16,
    minWidth: 16
  },
  restaurantSelectMobile: {
    width: '100%',
    display: 'block'
  },
  yangoTitle: {
    fontFamily: 'Yango Headline'
  },
  refreshBtn: {
    marginRight: 16
  }
})

type ChatsHeaderMobile = {
  onCreateChat: (restaurantId: number) => void
  onRefresh: () => Promise<unknown>
}

function ChatsHeaderMobile({onCreateChat, onRefresh}: ChatsHeaderMobile) {
  const {t} = useI18n()
  const {classes: c, cx} = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const serviceBrand = useServiceBrand()

  const {placesIds} = usePlaces()

  const dirtyCounter = useSelector(chatsSlice.selectors.selectFiltersDirtyCount)

  function handleClickFilters() {
    setIsModalOpen(true)
  }

  return (
    <>
      <Header>
        <HeaderRow stickyPosition={0}>
          <SidebarBurger />
          <h1 className={cx({[c.yangoTitle]: serviceBrand === 'YangoEats'})}>
            {t('main-chats.chats-header-mobile.podderzhka', 'Поддержка')}
          </h1>
          <Button className={c.filtersBtn} onClick={handleClickFilters}>
            <SettingsOutlineIcon />
            {dirtyCounter > 0 && <span className={c.changedFiltersCount}>{dirtyCounter}</span>}
          </Button>
          <RefreshButton classes={{root: c.refreshBtn}} onUpdate={onRefresh} />
          <NewChatButton
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onCreateChat={onCreateChat}
          />
        </HeaderRow>
        {placesIds.length > 1 && (
          <HeaderRow>
            <MultiplePlacesDropdown className={c.restaurantSelectMobile} />
          </HeaderRow>
        )}
      </Header>
      <ChatsFiltersModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

export default ChatsHeaderMobile
