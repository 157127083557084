import {useExp3} from '@eda-restapp/configs'
import {Suspense, lazy} from 'react'

const ChatContainer = lazy(
  () => import(/* webpackChunkName: "@restapp/main-chats/ambient" */ './containers/ChatContainer/ChatContainer')
)

const UnreadCounterWatcher = lazy(async () => {
  const {UnreadCounterWatcher} = await import(
    /* webpackChunkName: "@restapp/main-chats/ambient" */ './messenger/components/UnreadCounterWatcher/UnreadCounterWatcher'
  )
  return {default: UnreadCounterWatcher}
})

const SingleChatDrawer = lazy(async () => {
  const {SingleChatDrawer} = await import(
    /* webpackChunkName: "@restapp/main-chats/ambient" */ './messenger/components/SingleChatDrawer/SingleChatDrawer'
  )
  return {default: SingleChatDrawer}
})

const MultiChatDrawer = lazy(async () => {
  const {MultiChatDrawer} = await import(
    /* webpackChunkName: "@restapp/main-chats/ambient" */ './messenger/components/MultiChatDrawer/MultiChatDrawer'
  )
  return {default: MultiChatDrawer}
})

const ChatsRouterBinding = lazy(async () => {
  const {ChatsRouterBinding} = await import(
    /* webpackChunkName: "@restapp/main-chats/ambient" */ './components/ChatsRouterBinding/ChatsRouterBinding'
  )
  return {default: ChatsRouterBinding}
})
export const AmbientChatsModules = () => {
  const supportChatsConfig = useExp3('restapp_support_chats')

  return (
    <Suspense fallback={null}>
      {supportChatsConfig?.enabled && <ChatContainer />}
      <MultiChatDrawer />
      <UnreadCounterWatcher />
      <ChatsRouterBinding />
      <SingleChatDrawer />
    </Suspense>
  )
}
