import {useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'

import {selectOauthToken, selectPartnerId, selectToken} from '../../../../selectors'
import {setToken} from '../../../../slice'

export const useDeleteXTokenOnPartnerUidSetup = () => {
  const partnerUid = useSelector(selectPartnerId)
  const oauthToken = useSelector(selectOauthToken)
  const token = useSelector(selectToken)
  const queryClient = useQueryClient()

  const dispatch = useDispatch()

  if (partnerUid && oauthToken) {
    if (token) {
      dispatch(setToken(null))

      queryClient.removeQueries()
    }
  }
}
