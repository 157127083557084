import type {RatesDefaults, RatesSection} from '../../types/rates'

const groupPrices = ({
  default_click_price,
  min_click_price,
  max_click_price,
  default_week_budget,
  min_week_budget,
  adv_price,
  adv_budget,
  region_slug,
  country_code
}: RatesDefaults): RatesSection => {
  return {
    adv_price: adv_price ?? {
      min: min_click_price,
      max: max_click_price,
      default: default_click_price
    },
    adv_budget: adv_budget ?? {
      min: min_week_budget,
      default: default_week_budget
    },
    ...(!!country_code && {country_code}),
    ...(!!region_slug && {region_slug})
  }
}

export default groupPrices
