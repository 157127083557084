import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Modal} from '@eda-restapp/ui'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {useApiQueryInvalidate} from '@restapp/core-api'
import {usePassportRestrictionPage} from '@restapp/core-auth/passport/components/PassportMigrationContainer'
import {ErrorBoundary} from '@restapp/shared-boundary'
import {Dialog, useModal} from '@restapp/shared-modals'

import SurveyModalLayout from '../../components/Surveys/SurveyModal/SurveyModalLayout'
import {useSurvey} from '../../hooks/useSurvey'
import {resetAnswers} from '../../slice'
import type {Survey} from '../../types'

const checkIsAllowedLocation = ({survey, isPageBlocked}: {isPageBlocked: boolean; survey: Survey | null}) => {
  const isDefaultAllowed = survey?.info.section
    ? survey?.info.section.some((sectionUrl) => location.pathname.startsWith(sectionUrl))
    : true

  return isDefaultAllowed && !isPageBlocked
}

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
const SurveyContainer = () => {
  const {t} = useI18n()
  const communicationsConfig = useExp3('restapp_communications')
  const location = useLocation()
  const {isPageBlocked} = usePassportRestrictionPage()

  const surveyModal = useModal({modalName: 'survey'})
  const dispatch = useDispatch()

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false)

  const {survey, surveyMethods} = useSurvey({enabled: !surveyModal.isOpen})

  const notificationsInvalidation = useApiQueryInvalidate(['/4.0/restapp-front/places/v1/notifications', 'POST'])

  useEffect(() => {
    const tryShowSurvey = () => {
      const isAllowedLocation = checkIsAllowedLocation({
        survey,
        isPageBlocked
      })

      if (survey && survey.info.show_as_fullscreen && survey.status !== 'read' && isAllowedLocation) {
        const metaInfo = surveyMethods.getSurveyMetaInfo(survey.id)
        if (metaInfo) {
          const canShowSurvey = surveyMethods.checkCanShowSurvey(survey, metaInfo)

          if (canShowSurvey) {
            surveyModal.open()
            surveyMethods.setSurveyMetaInfo(survey.id, {
              remainingShowsCount: metaInfo.remainingShowsCount - 1,
              nextNearestShowDate: moment().add(communicationsConfig.surveyRepeatIntervalHours, 'hours').toISOString()
            })
          }
        } else {
          surveyModal.open()
          surveyMethods.setSurveyMetaInfo(survey.id, {
            remainingShowsCount: survey.info.show_count_after_closed || 0,
            nextNearestShowDate: moment().add(communicationsConfig.surveyRepeatIntervalHours, 'hours').toISOString()
          })
        }
      }
    }
    tryShowSurvey()

    const MS_IN_HOUR = 1000 * 60 * 60
    const timeoutId = setTimeout(tryShowSurvey, communicationsConfig.surveyRepeatIntervalHours * MS_IN_HOUR)
    return () => clearTimeout(timeoutId)
  }, [survey, communicationsConfig, location])

  if (!survey) {
    return null
  }

  const handleClose = () => {
    setIsCloseConfirmationOpen(true)
  }

  const handleCloseConfirm = () => {
    const metaInfo = surveyMethods.getSurveyMetaInfo(survey.id)

    if (!metaInfo) {
      surveyMethods.setSurveyMetaInfo(survey.id, {
        remainingShowsCount: survey.info.show_count_after_closed || 0,
        nextNearestShowDate: moment().toISOString()
      })
    } else {
      const remainingShowsCount = metaInfo.remainingShowsCount - 1

      surveyMethods.setSurveyMetaInfo(survey.id, {
        remainingShowsCount,
        nextNearestShowDate: metaInfo.nextNearestShowDate
      })

      if (remainingShowsCount <= 0) {
        surveyMethods.surveyMarkRead(survey.id)
      }
    }

    setIsCloseConfirmationOpen(false)
    dispatch(resetAnswers())
    surveyModal.close()
  }

  const handleCloseCancel = () => setIsCloseConfirmationOpen(false)

  const handleSubmit = () => {
    void surveyMethods.submitAnswers().then(() => {
      surveyModal.close()
      notificationsInvalidation.invalidate()
      surveyMethods.clearSurveyMetaInfo()
    })
  }

  return (
    <>
      <Modal open={surveyModal.isOpen} onClose={handleClose}>
        <ErrorBoundary slug='survey-container'>
          <SurveyModalLayout survey={survey} onSubmit={handleSubmit} />
        </ErrorBoundary>
      </Modal>
      <Dialog
        visible={isCloseConfirmationOpen}
        title={t('main-communications.survey-modal.viiti-iz-oprosa', 'Выйти из опроса?')}
        applyText={t('main-communications.survey-modal.viiti', 'Выйти')}
        cancelText={t('main-communications.survey-modal.ostatsya', 'Остаться')}
        progress={false}
        onConfirm={handleCloseConfirm}
        onClose={handleCloseCancel}
      />
    </>
  )
}

export default SurveyContainer
