import type {VendorApi} from '@restapp/shared-api'
import {getConfigsStore} from '@eda-restapp/configs'
import {selectAuthHeaders} from '@restapp/core-auth'
import Api from '@restapp/core-legacy/api'
import {selectDeviceHeaders} from '@restapp/shared/selectors/selectDeviceHeaders'
import {startAppListening} from '@restapp/store'

// TODO: В будущем нужно разнести в отдельные файлам по смыслу
export const configsStore = getConfigsStore()

export const api = new Api<VendorApi>()

configsStore.events.subscribe({
  name: 'change',
  callback: ({configs}) => {
    api.setConfig(configs.restapp_api)
  }
})

startAppListening({
  predicate: () => true,
  effect: (_action, listenerApi) => {
    const state = listenerApi.getState()
    const authHeaders = selectAuthHeaders(state)
    const deviceHeaders = selectDeviceHeaders(state)

    api.setHeaders({
      ...authHeaders,
      ...deviceHeaders
    })
  }
})
