import {useExp3} from '@eda-restapp/configs'
import {APP_LANGUAGES_LABELS, SUPPORTED_LANGUAGES, useAvailableForServiceLanguages, useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {usePermission} from '@eda-restapp/permissions'
import Menu, {type MenuProps} from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'
import Cookies from 'js-cookie'
import React, {memo, useState} from 'react'

import {useAuth, useUserInfo} from '@restapp/core-auth/hooks'
import {Button} from '@restapp/shared-ui/Button'

import {useAvailableForCountryLanguages, useCountryCode, useDefaultCountryLanguage, useServiceBrand} from '../../hooks'
import {useAvailableCountryLanguages, useAvailableLanguagesByCountryCode, getLanguageLabelByCode} from '../../utils'

import useLanguageSelectorStyles from './LaguageSelector.style'

type LanguageMenuProps = Omit<MenuProps, 'open'> & {open?: boolean}
export type LanguageSelectorProps = LanguageMenuProps & {
  classes?: Partial<ReturnType<typeof useLanguageSelectorStyles>['classes']>
}

/** Language selection component. Represents a button of a given size (48x48) with an inscription of the selected language code (for example, EN), by clicking on which
 a list of available languages drops out according to the serviceName (domain) and the selected country (on Natives) */

export const LanguageSelector = memo(
  ({classes, ...props}: LanguageSelectorProps): React.ReactElement<LanguageSelectorProps> => {
    const commonConfig = useExp3('restapp_common')
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const {lang, setLocale} = useI18n()
    const serviceBrand = useServiceBrand()
    const {classes: c} = useLanguageSelectorStyles(undefined, {props: {classes}})
    const newYearConfig = useExp3('restapp_new_year')
    const showKKLang = usePermission('permission.custom.show_language_KK')
    const hideKKLang = usePermission('permission.custom.hide_language_KK')

    // Collecting lang from cookie (it sets always after selecting lang, so we won't reset on default for country after 1st auth or selecting lang, that differs from default)
    const cookieLang = Object.values(SUPPORTED_LANGUAGES).find((lang) => lang === Cookies.get('language'))
    const {countryCode} = useCountryCode()
    const availableServiceLanguages = useAvailableLanguagesByCountryCode(countryCode)
    const defaultCountryLanguage = useDefaultCountryLanguage(countryCode)
    const availableForCountryLanguages = useAvailableCountryLanguages(countryCode)
    // Getting languages info for logged in User
    const {isLoggedIn} = useAuth()
    const userCountryCode = useUserInfo()?.country_code
    const userAvailableServiceLanguages = useAvailableForServiceLanguages(serviceBrand)
    const userDefaultCountryLanguage = useDefaultCountryLanguage(userCountryCode)
    const userAvailableForCountryLanguages = useAvailableForCountryLanguages({
      countryCode: userCountryCode,
      isLoggedIn,
      canSeeKKLang: showKKLang && !hideKKLang
    })

    // For Native - reset lang for country default if cookie-lang not available for selected country
    if (
      process.env.NODE_ENV !== 'development' &&
      !commonConfig.languageSelectorShowDevLanguages &&
      !isLoggedIn &&
      availableForCountryLanguages &&
      cookieLang &&
      !availableForCountryLanguages.includes(cookieLang) &&
      defaultCountryLanguage &&
      lang !== defaultCountryLanguage
    ) {
      setLocale(defaultCountryLanguage)
    }

    // Reset lang for country default if on Auth-page user selected lang, that not allowed for his country
    if (
      process.env.NODE_ENV !== 'development' &&
      !commonConfig.languageSelectorShowDevLanguages &&
      isLoggedIn &&
      userAvailableForCountryLanguages &&
      !userAvailableForCountryLanguages.includes(lang) &&
      userDefaultCountryLanguage &&
      lang !== userDefaultCountryLanguage
    ) {
      setLocale(userDefaultCountryLanguage)
    }

    const availableLanguagesList =
      userAvailableForCountryLanguages ||
      availableForCountryLanguages ||
      availableServiceLanguages ||
      userAvailableServiceLanguages

    const LANGUAGES = availableLanguagesList.reduce<Partial<Record<SUPPORTED_LANGUAGES, string>>>(
      (result, languageCode) => {
        result[languageCode] = getLanguageLabelByCode(languageCode)

        return result
      },
      {}
    )

    if (process.env.NODE_ENV !== 'production' || commonConfig.languageSelectorShowDevLanguages) {
      Object.entries(APP_LANGUAGES_LABELS).forEach(([languageCode, languageLabel]) => {
        LANGUAGES[languageCode as SUPPORTED_LANGUAGES] = languageLabel
      })
    }

    if (Object.values(LANGUAGES).length <= 1) {
      return <></>
    }

    const isLanguageListOpen = Boolean(anchorEl)

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      event.currentTarget.classList.toggle('active')
      setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
      setAnchorEl(null)
      document.getElementById('language-selector-button')?.classList.remove('active')
    }

    const handleLanguageClick = (language: SUPPORTED_LANGUAGES) => {
      setLocale(language)
      handleMenuClose()

      eventLogger({
        name: 'select_language',
        value: 'language-selector:selected-language-tracking',
        additional: {
          chosenLanguage: language
        }
      })
    }

    return (
      <div className={c.root}>
        <Button
          id='language-selector-button'
          aria-controls={isLanguageListOpen ? 'language-selector-menu-list' : undefined}
          aria-haspopup='true'
          aria-expanded={isLanguageListOpen ? 'true' : undefined}
          className={c.button}
          disableElevation
          onClick={handleButtonClick}
          data-testid='language-selector-button'
        >
          {lang.toLocaleUpperCase()}
        </Button>
        <Menu
          {...props}
          id='language-selector-menu-list'
          data-testid={'ui-language-selector-menu-list' /* UI | List with available laguages to set*/}
          aria-labelledby='language-selector-button'
          anchorEl={anchorEl}
          open={isLanguageListOpen}
          className={cn(c.menu, {[c.newYearMenu]: newYearConfig.enabled})}
          onClose={handleMenuClose}
        >
          {Object.entries(LANGUAGES).map(([language, languageLabel]) => (
            <MenuItem
              key={language}
              className={c.menuItem}
              value={language}
              selected={language === lang}
              onClick={() => handleLanguageClick(language as SUPPORTED_LANGUAGES)}
              data-testid={`language-selector-menu-item-${language}`}
            >
              {languageLabel}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
)
LanguageSelector.displayName = 'LanguageSelector'
