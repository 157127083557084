import {eventLogger} from '@eda-restapp/logger'
import React from 'react'
import type {CSSProperties, FC, ReactNode} from 'react'

import {useApiQuery} from '@restapp/core-api'

import {getTreeNode} from './getTreeNode/getTreeNode'
import {SupportTree} from './SupportTree'
import type {SupportTreeWidget} from './SupportTree.types'

export type SupportTreeContainerProps = {
  path: string[]
  onChange: (id: string) => void

  children: (widgets?: SupportTreeWidget[]) => ReactNode

  className?: string
  style?: CSSProperties
}

export const SupportTreeContainer: FC<SupportTreeContainerProps> = ({path, onChange, children, className, style}) => {
  const treeQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/topic_tree',
    method: 'GET',
    suspense: true
  })

  const onTopicClick = (id: string) => {
    eventLogger({
      name: 'support_tree:click_on_topic',
      value: id,
      additional: {
        path
      }
    })

    onChange(id)
  }

  const tree = treeQuery.data?.tree || []

  if (!path.length) {
    return <SupportTree elements={tree} onElementClick={onTopicClick} className={className} style={style} />
  }

  const currentTreeElement = getTreeNode(tree, path)

  if (currentTreeElement.widgets) {
    return children(currentTreeElement.widgets)
  }

  if (currentTreeElement.tree_elements) {
    return (
      <SupportTree
        elements={currentTreeElement.tree_elements}
        onElementClick={onTopicClick}
        className={className}
        style={style}
      />
    )
  }

  throw new Error("Current tree element doesn`t have 'widgets' or 'tree_elements' property")
}
