import {createReducer} from '@reduxjs/toolkit'

import {userLogout} from '@restapp/core-auth/slice'
import {makeReducerPersistent} from '@restapp/store'

import {selectPlaces} from '../../actions'
import {PLACES_DROPDOWN_PERSIST_KEY} from '../../constants'

interface PlacesDropdownValueState {
  selected: number[]
}

const initialState: PlacesDropdownValueState = {
  selected: []
}

const placesDropdownValueReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(selectPlaces, (state, action) => {
      return {...state, selected: action.payload}
    })
    .addCase(userLogout, () => initialState)
)

export default makeReducerPersistent(placesDropdownValueReducer, PLACES_DROPDOWN_PERSIST_KEY)
