import {createSlice} from '@reduxjs/toolkit'

import {
  createItem,
  enableAll,
  removeCategory,
  removeItem,
  resetAll,
  setNormalizeItems,
  toggleOption,
  upsertItem,
  applyCurrentState
} from '../actions'
import {OPTIONS_SLICE_NAME} from '../constants'
import {optionEntityAdapter, optionEntitySelectors} from '../entities'
import type {OptionsSliceState, Option} from '../types'
import {getOptionVirtualId, getGroupVirtualId, setJoin} from '../utils'

export const slice = createSlice({
  name: OPTIONS_SLICE_NAME,
  initialState: (): OptionsSliceState => {
    return {
      current: optionEntityAdapter.getInitialState(),
      initial: optionEntityAdapter.getInitialState(),
      joinByGroup: {}
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNormalizeItems, (state, action) => {
      optionEntityAdapter.removeAll(state.current)
      optionEntityAdapter.removeAll(state.initial)
      state.joinByGroup = {}

      const result: Option[] = []

      for (const item of action.payload) {
        for (const group of item.modifierGroups) {
          for (const option of group.modifiers) {
            const groupVirtualId = getGroupVirtualId(item.id, group.id)
            const optionVirtualId = getOptionVirtualId(item.id, group.id, option.id)
            setJoin(state.joinByGroup, groupVirtualId, optionVirtualId)

            result.push({
              ...option,
              reactivatedAt: option.reactivatedAt ?? null,
              _categoryId: item.categoryId,
              _itemId: item.id,
              _groupId: groupVirtualId,
              _virtualId: optionVirtualId
            })
          }
        }
      }

      optionEntityAdapter.setAll(state.current, result)
      optionEntityAdapter.setAll(state.initial, result)
    })

    builder.addCase(createItem, (state, action) => {
      for (const group of action.payload.modifierGroups) {
        for (const option of group.modifiers) {
          const groupVirtualId = getGroupVirtualId(action.payload.id, group.id)
          const optionVirtualId = getOptionVirtualId(action.payload.id, group.id, option.id)

          setJoin(state.joinByGroup, groupVirtualId, optionVirtualId)

          optionEntityAdapter.setOne(state.current, {
            ...option,
            reactivatedAt: option.reactivatedAt ?? null,
            _categoryId: action.payload.categoryId,
            _itemId: action.payload.id,
            _groupId: groupVirtualId,
            _virtualId: optionVirtualId
          })
        }
      }
    })

    builder.addCase(upsertItem, (state, action) => {
      const optionsInItem = optionEntitySelectors
        .selectAll(state.current)
        .filter((option) => option._itemId === action.payload.id)
        .map((option) => {
          delete state.joinByGroup[option._groupId]

          return option._virtualId
        })

      optionEntityAdapter.removeMany(state.current, optionsInItem)

      for (const group of action.payload.modifierGroups) {
        for (const option of group.modifiers) {
          const groupVirtualId = getGroupVirtualId(action.payload.id, group.id)
          const optionVirtualId = getOptionVirtualId(action.payload.id, group.id, option.id)

          optionEntityAdapter.setOne(state.current, {
            ...option,
            reactivatedAt: option.reactivatedAt ?? null,
            _categoryId: action.payload.categoryId,
            _itemId: action.payload.id,
            _groupId: groupVirtualId,
            _virtualId: optionVirtualId
          })

          setJoin(state.joinByGroup, groupVirtualId, optionVirtualId)
        }
      }
    })

    builder.addCase(removeCategory, (state, action) => {
      const optionsInCategory = optionEntitySelectors
        .selectAll(state.current)
        .filter((option) => option._categoryId === action.payload)
        .map((option) => {
          delete state.joinByGroup[option._groupId]

          return option._virtualId
        })

      optionEntityAdapter.removeMany(state.current, optionsInCategory)
    })

    builder.addCase(removeItem, (state, action) => {
      const optionsInItem = optionEntitySelectors
        .selectAll(state.current)
        .filter((option) => option._itemId === action.payload)
        .map((option) => {
          delete state.joinByGroup[option._groupId]

          return option._virtualId
        })

      optionEntityAdapter.removeMany(state.current, optionsInItem)
    })

    builder.addCase(resetAll, (state) => {
      state.current = state.initial

      state.joinByGroup = {}
      for (const option of Object.values(state.initial.entities)) {
        if (option) {
          setJoin(state.joinByGroup, option._groupId, option._virtualId)
        }
      }
    })

    builder.addCase(applyCurrentState, (state) => {
      state.initial = state.current
    })

    builder.addCase(toggleOption, (state, action) => {
      optionEntityAdapter.updateOne(state.current, {
        id: action.payload.id,
        changes: {
          available: action.payload.value,
          reactivatedAt: action.payload.reactivatedAd
        }
      })
    })

    builder.addCase(enableAll, (state) => {
      for (const option of Object.values(state.current.entities)) {
        if (option) {
          option.available = true
          option.reactivatedAt = null
        }
      }
    })
  }
})
