import type {ReactNode} from 'react'

import {useApiQuery} from '@restapp/core-api'

import {useGetAvatarUrl} from '../../../hooks/useGetAvatarURL'
import {usePassportBind} from '../hooks/usePassportBind'
import {usePassportRestrictionPage} from '../hooks/usePassportRestrictionPage'

import {PassportRestrictionPage} from './PassportRestrictionPage'

type PassportRestrictionPageContainerProps = {
  headerTitle?: string
  children: ReactNode
}

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
export const PassportRestrictionPageContainer = ({headerTitle, children}: PassportRestrictionPageContainerProps) => {
  const passportBind = usePassportBind()
  const getAvatarUrl = useGetAvatarUrl()
  const {isPageBlocked} = usePassportRestrictionPage()

  const linkedAccountsQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/linked-accounts',
    method: 'GET',
    staleTime: 60_000 * 60
  })

  if (!linkedAccountsQuery.data || !passportBind || !isPageBlocked) {
    return children
  }

  const linkedAccounts = linkedAccountsQuery.data?.accounts.length || 0
  const sendedInvites = linkedAccountsQuery.data?.invites?.length || 0
  const maxLinkedAccountLimitReached =
    linkedAccounts + sendedInvites >= (linkedAccountsQuery.data?.meta.accounts_limit ?? 0)
  const hasLinkedAccounts = linkedAccountsQuery.data && linkedAccountsQuery.data?.accounts.length > 0

  const linkedAccountsWithAvatars =
    linkedAccountsQuery.data?.accounts.map((account) => {
      return {...account, avatar: getAvatarUrl(account.avatar)}
    }) ?? []

  return (
    <PassportRestrictionPage
      suggestedAccount={passportBind.suggestedAccount}
      headerTitle={headerTitle}
      accounts={linkedAccountsWithAvatars}
      hasLinkedAccounts={hasLinkedAccounts}
      maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
      onBindOtherAccount={passportBind.onBindOtherAccount}
      onBindSuggestedAccount={passportBind.onBindSuggestedAccount}
      onAuthWithLinkedAccount={passportBind.onAuthWithLinkedAccount}
    />
  )
}
