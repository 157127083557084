import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Informer, Typography, Alert} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {Header, HeaderRow, Page, PageContent, SidebarBurger} from '@restapp/main-layout'
import type {LinkedAccount} from '@restapp/shared-api/types/passport'
import {OutsideLink} from '@restapp/shared-ui'

import type {ExternalAccountSuggest} from '../../../../types'
import {usePassportHelpURL} from '../../../hooks'
import {LinkedAccounts} from '../PassportModals/PassportModal/ModalWithLinkedAccounts/LinkedAccounts'

import styles from './PassportRestrictionPage.module.css'
import {PassportRestrictionPageButtons} from './PassportRestrictionPageButtons/PassportRestrictionPageButtons'

export type PassportRestrictionPageProps = {
  accounts: LinkedAccount[]
  headerTitle?: string
  suggestedAccount?: ExternalAccountSuggest
  maxLinkedAccountLimitReached?: boolean
  hasLinkedAccounts?: boolean
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
  onAuthWithLinkedAccount(account: LinkedAccount): void
}
export const PassportRestrictionPage = ({
  headerTitle,
  suggestedAccount,
  accounts,
  maxLinkedAccountLimitReached,
  hasLinkedAccounts,
  onBindSuggestedAccount,
  onBindOtherAccount,
  onAuthWithLinkedAccount
}: PassportRestrictionPageProps) => {
  const {t} = useI18n()
  const helpUrl = usePassportHelpURL()

  const handleBindSuggestedAccount = () => {
    eventLogger({name: 'passport:restriction_page:click_bind_suggested_account'})
    onBindSuggestedAccount()
  }

  const handleBindOtherAccount = () => {
    if (suggestedAccount) {
      eventLogger({name: 'passport:restriction_page:click_bind_other_account'})
    } else {
      eventLogger({name: 'passport:restriction_page:click_bind'})
    }

    onBindOtherAccount()
  }

  const handleAuthWithLinkedAccounts = (account: LinkedAccount) => {
    eventLogger({name: 'passport:restriction_page:click_auth_with_linked_account'})
    onAuthWithLinkedAccount(account)
  }

  return (
    <Page>
      <Header>
        <HeaderRow>
          <SidebarBurger />
          <h1>{headerTitle ?? t('core-auth.passport.passport-restriction-page.default-header', 'Главная')}</h1>
        </HeaderRow>
      </Header>
      <PageContent classes={{root: styles.main}} disablePadding>
        <Informer type='error'>
          <div className={styles.informerContent}>
            <Typography variant='body2'>
              {t(
                'core-auth.passport.passport-restriction-page.error-informer-text',
                'Если продолжать авторизоваться по электронной почте, доступ будет ограничен – вы сможете только принимать заказы'
              )}
            </Typography>
            <OutsideLink href={helpUrl} target='_blank'>
              <Typography variant='body2' lineHeight='tight'>
                {t('core_auth.passport.passport-restriction-page.about-new-auth', 'О новой авторизации')}
              </Typography>
            </OutsideLink>
          </div>
        </Informer>
        <div className={styles.contentContainer}>
          <div className={cn(styles.content, {[styles.contentWithLinkedAccounts]: hasLinkedAccounts})}>
            <div className={styles.iconContainer}>
              <Alert className={styles.icon} />
            </div>
            <div className={styles.titleContainer}>
              <Typography variant='body1' thickness='bold'>
                {t('core_auth.passport.passport-restriction-page.title', 'Доступ к разделу ограничен')}
              </Typography>
            </div>
            <div className={styles.descriptionContainer}>
              <Typography variant='body2'>
                {t(
                  'core_auth.passport.passport-restriction-page.description',
                  'Привяжите Яндекс ID, чтобы пользоваться личным кабинетом без ограничений'
                )}
              </Typography>
            </div>
            {hasLinkedAccounts && (
              <div className={styles.linkedAccountsContainer}>
                <LinkedAccounts accounts={accounts} onAuthWithLinkedAccount={handleAuthWithLinkedAccounts} />
              </div>
            )}
            <div className={hasLinkedAccounts ? styles.buttonsContainerWithLinkedAccounts : styles.buttonsContainer}>
              <PassportRestrictionPageButtons
                suggestedAccount={suggestedAccount}
                onBindOtherAccount={handleBindOtherAccount}
                onBindSuggestedAccount={handleBindSuggestedAccount}
                hasLinkedAccounts={hasLinkedAccounts}
                maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
              />
            </div>
          </div>
        </div>
      </PageContent>
    </Page>
  )
}
