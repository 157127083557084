import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import {useLocation} from 'react-router-dom'

import {useOldHeavyPlaces} from '@restapp/core-places'
import type {TariffType} from '@restapp/shared-api'
import {useDiscountInfo, useSubscriptions} from '@restapp/shared-places'

import type {TrialInfo} from '../types'

import {useSubscriptionsTariffs} from './useSubscriptionsTariffs'
import {useUpdateSubscription} from './useUpdateSubscription'

export const useSubscriptionEditTariff = ({
  placesIds = [],
  suspense = false
}: {
  placesIds?: number[]
  suspense?: boolean
}) => {
  const restappTools = useExp3('restapp_tools')
  const subscriptionEnabled = restappTools.enabled && Boolean(restappTools.subscriptionPage?.enabled)
  const {
    subscriptionPage: {disabledEditingSubscription, discountAlertEnabled}
  } = useExp3('restapp_tools')

  const location = useLocation()

  const tariffQuery = useSubscriptionsTariffs({enabled: subscriptionEnabled})

  const {getPlaceById, places} = useOldHeavyPlaces({suspense})
  const hasDCPlaces = places.some((place) => place.services?.enabled.includes('dc'))

  const subscriptionsQuery = useSubscriptions<{
    commonTariff?: TariffType
    hasPaidNextTariff: boolean
    hasPaidSubscription: boolean
    trialTariffInfo?: TrialInfo
    subscriptionsIds: number[]
  }>(
    {placesIds},
    {
      select: (data) => {
        const firstTariffType = data.subscriptions[0]?.tariff_info.type
        const hasPaidNextTariff = data.subscriptions.some(
          (subscription) => subscription.next_tariff && subscription.next_tariff !== 'free'
        )
        const everyTariffEqual = data.subscriptions.every(
          (subscription) => subscription.tariff_info.type === firstTariffType
        )
        const hasPaidSubscription = data.subscriptions.some((subscription) => subscription.tariff_info.type !== 'free')
        const trialTariffInfo =
          data.subscriptions.length === 1 && data.subscriptions[0].is_trial
            ? {
                placeId: data.subscriptions[0].place_id,
                type: firstTariffType,
                validUntil: data.subscriptions[0].valid_until,
                validUntilIso: data.subscriptions[0].valid_until_iso
              }
            : undefined

        return {
          subscriptionsIds: data.subscriptions?.map(({place_id}) => place_id),
          commonTariff: everyTariffEqual ? firstTariffType : undefined,
          hasPaidNextTariff,
          hasPaidSubscription,
          trialTariffInfo: trialTariffInfo
        }
      },
      enabled: subscriptionEnabled && !!placesIds.length,
      suspense
    }
  )

  const {updateSubscription} = useUpdateSubscription()

  const reachSubmit = (params: {
    placeIds: number[]
    tariff: TariffType
    isSameTariffSubmitted: boolean
    fromTariff?: TariffType
  }) => {
    metrika({
      target: 'submit_subscription_tariff',
      params: {...params, path: location.pathname}
    })
  }

  const discountQuery = useDiscountInfo(subscriptionsQuery.data?.subscriptionsIds ?? [], {
    enabled: discountAlertEnabled && Boolean(subscriptionsQuery.data?.subscriptionsIds.length),
    suspense
  })
  const hasDiscount = !!discountQuery.data?.enabled && !subscriptionsQuery.data?.hasPaidSubscription

  return {
    subscriptionsIds: subscriptionsQuery.data?.subscriptionsIds ?? [],
    canHasSubscription: Boolean(subscriptionsQuery.data?.subscriptionsIds.length),
    initialTariff: subscriptionsQuery.data?.commonTariff,
    tariffs: tariffQuery.data?.tariffs ?? [],
    hasDiscount,
    hasDCPlaces,
    trialTariffInfo: subscriptionsQuery.data?.trialTariffInfo,
    hasPaidNextTariff: subscriptionsQuery.data?.hasPaidNextTariff,
    hasPaidSubscription: subscriptionsQuery.data?.hasPaidSubscription,
    currentPlace:
      subscriptionsQuery.data?.subscriptionsIds?.length === 1
        ? getPlaceById(subscriptionsQuery.data.subscriptionsIds[0])
        : undefined,
    isSinglePlace: placesIds.length === 1,
    isLoading: tariffQuery.isLoading || subscriptionsQuery.isLoading,
    isFetched: subscriptionsQuery.isFetched,
    daysBeforeFinishDiscount: discountQuery.data?.days_before_finish ?? 0,
    disabledEditingSubscription,
    discountAlertEnabled,
    defaultTariffCount: 3,
    getTariffByType: (tariffType?: TariffType) =>
      tariffQuery.data?.tariffs?.find((tariffItem) => tariffItem.type === tariffType),
    updateSubscription,
    reachSubmit
  }
}
