import type {IApiError, TypedAxiosStrictInstance} from '@restapp/shared-api'

type FakeRequestConfig = {
  resolveImmediately: boolean
}

export default class FakeRequest {
  private queue: (() => void)[]
  private mock: jest.MockedFunction<TypedAxiosStrictInstance['request']>
  private resolveImmediatelyValue: boolean

  constructor(private cfg?: FakeRequestConfig) {
    this.mock = jest.fn()
    this.queue = []
    this.resolveImmediatelyValue = this.getResolveImmediatelyValue(cfg)
  }

  get mockedRequest() {
    return this.mock
  }

  set resolveImmediately(val: boolean) {
    this.resolveImmediatelyValue = val
  }

  reset() {
    this.mock = jest.fn()
    this.queue = []
    this.resolveImmediatelyValue = this.getResolveImmediatelyValue(this.cfg)
  }

  resolveNextRequest() {
    this.queue.pop()?.()
  }

  responseWith<TReject extends boolean>(
    newResponse: TReject extends true ? IApiError : unknown,
    reject: TReject | false = false
  ) {
    this.mock.mockImplementation(() => this.getPromise(newResponse, reject))
  }

  responseOnceWith<TReject extends boolean>(
    newResponse: TReject extends true ? IApiError : unknown,
    reject: TReject | false = false
  ) {
    this.mock.mockImplementationOnce(() => this.getPromise(newResponse, reject))
  }

  private getPromise(response: unknown, mustReject: boolean) {
    return new Promise((resolve, reject) => {
      const settleRequest = () => (mustReject ? reject(response) : resolve(response))

      if (this.resolveImmediatelyValue) {
        settleRequest()
      } else {
        this.queue.push(settleRequest)
      }
    })
  }

  private getResolveImmediatelyValue(cfg?: FakeRequestConfig) {
    return cfg?.resolveImmediately ?? false
  }
}
