import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'AddressLink'})(() => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    link: {
      color: '#0668BE',
      cursor: 'pointer'
    },
    copyIcon: {
      marginLeft: 6
    }
  }
})
