import {Box, Switch, Typography} from '@eda-restapp/ui'
import React, {useState} from 'react'
import {uniqueId} from 'lodash-es'

type TimeSelectorSwitchProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  active: boolean
  name: string
  label?: string
}

export const TimeSelectorSwitch: React.FC<TimeSelectorSwitchProps> = ({onChange, active, name, label}) => {
  const [id] = useState(() => uniqueId('time_switch'))
  return (
    <Box data-testid={`time-selector-${name}` /*Свитч с лейблом */} gap='m'>
      <Switch name={name} active={active} onChange={onChange} id={id} />
      {!!label && (
        <Typography Component='label' variant='body2' htmlFor={id} style={{cursor: 'pointer', width: 'max-content'}}>
          {label}
        </Typography>
      )}
    </Box>
  )
}
