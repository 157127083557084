import {useNavigate} from 'react-router-dom'

import {useSubscriptionRoute} from '@restapp/shared-places'

type SupportedWidgetSlugs = 'open_smart_price_modal' | 'open_subscription_modal'
type SlugResolver = () => void

interface UseWidgetSlugResolverResult {
  isSupportedUrl: (slug: string) => slug is SupportedWidgetSlugs
  resolveUrl: (slug: SupportedWidgetSlugs) => void
}

export default function useWidgetUrlResolver(): UseWidgetSlugResolverResult {
  const {goToSubcriptionStart} = useSubscriptionRoute()
  const navigate = useNavigate()

  const slugToResolver: Record<SupportedWidgetSlugs, SlugResolver> = {
    open_smart_price_modal: () => navigate({pathname: '/tools/smart-price'}),
    open_subscription_modal: goToSubcriptionStart
  }

  return {
    isSupportedUrl: (slug): slug is SupportedWidgetSlugs => Object.keys(slugToResolver).includes(slug),
    resolveUrl: (slug: SupportedWidgetSlugs) => slugToResolver[slug]()
  }
}
