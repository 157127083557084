import {eventLogger} from '@eda-restapp/logger'
import cn from 'classnames'
import React, {type ButtonHTMLAttributes, forwardRef, type MouseEvent} from 'react'

import styles from './LinkButton.module.css'

type LinkButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  slug?: string
}

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({className = '', type = 'button', slug, onClick, ...props}, ref) => {
    const onClickWithTracker = (event: MouseEvent<HTMLButtonElement>) => {
      eventLogger({
        name: 'button_click',
        value: slug,
        additional: {eventData: typeof props.children === 'string' ? props.children : undefined}
      })
      onClick?.(event)
    }

    return (
      <button ref={ref} className={cn(styles.button, className)} type={type} {...props} onClick={onClickWithTracker} />
    )
  }
)
