import {useExp3} from '@eda-restapp/configs'
import {first} from 'lodash-es'

import {usePlaces} from '@restapp/core-places'

import {useYaPlusStatus} from '../../hooks/useYaPlusStatus'

export function usePlusBannerForPlace(placeId: number) {
  const {enablePlusBanner} = useExp3('restapp_plus')

  const {getPlaceById} = usePlaces({
    suspense: true,
    enabled: enablePlusBanner
  })

  const place = getPlaceById(placeId)
  const isDcPlace = Boolean(place?.services?.enabled.includes('dc'))

  const plusStatusQuery = useYaPlusStatus([placeId], {
    enabled: Boolean(enablePlusBanner && isDcPlace),
    select: (data) => data.places.find((place) => place.place_id === placeId),
    suspense: true
  })

  const plusNotActive = plusStatusQuery.data?.place_info_by_service?.some(
    (plusInfo) =>
      first(plusInfo.statuses)?.status === 'can_be_activated' ||
      first(plusInfo.statuses)?.status === 'can_not_be_activated' ||
      first(plusInfo.statuses)?.status === 'deactivating'
  )

  return {
    visible: enablePlusBanner && isDcPlace && plusNotActive
  }
}
