import {useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {setSidebarVisibility} from '../actions'
import {selectIsSidebarVisible} from '../selectors'

const useSidebar = () => {
  const dispatch = useDispatch()
  const isVisible = useSelector(selectIsSidebarVisible)

  return useMemo(() => {
    return {
      setVisibility: (val: boolean) => {
        dispatch(setSidebarVisibility(val))
      },
      isVisible
    }
  }, [dispatch, isVisible])
}

export default useSidebar
