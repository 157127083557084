export const PlacesListStyles = {
  root: {},
  listItem: {},
  icon: {
    minWidth: 'unset',
    marginRight: 8
  },
  listPadding: {
    padding: '8px 16px'
  },
  placeName: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    color: '#1F2021'
  },
  placeAddress: {
    fontSize: 14,
    lineHeight: '18px',
    color: '#1F2021'
  },
  itemGutters: {
    padding: '5px 0'
  }
}
