import {useEffect, useRef, useState} from 'react'

type UseFullScreenOnCloseDelayOptions = {
  initialDelay?: number
  canStartTimer?: boolean
}

type UseFullScreenOnCloseDelayResult = {
  delay: number
}

export const useFullScreenOnCloseDelay = ({
  initialDelay = 0,
  canStartTimer = false
}: UseFullScreenOnCloseDelayOptions): UseFullScreenOnCloseDelayResult => {
  const initialDelayRef = useRef(initialDelay)
  const canStartTimerRef = useRef(canStartTimer)
  const [delay, setDelay] = useState(initialDelayRef.current)

  useEffect(() => {
    let internalCount = initialDelayRef.current
    if (canStartTimerRef.current && internalCount > 0) {
      const intervalId = setInterval(() => {
        if (internalCount) {
          internalCount--
          setDelay((prev) => prev - 1)
        } else {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [])

  return {
    delay
  }
}
