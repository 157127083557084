import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles<void, 'error'>({name: 'TelegramButtonStyles'})((theme, props, classes) => {
  return {
    error: {},

    root: {
      padding: 0,
      margin: 0,
      border: 'none',
      backgroundColor: 'white',
      cursor: 'pointer',
      display: 'flex',
      fontSize: 16,
      alignItems: 'center',
      minHeight: 56,
      width: '100%',
      lineHeight: '18px',

      '& > *': {
        marginRight: 16
      },

      '& > *:last-child': {
        marginRight: 0,
        marginLeft: 'auto'
      }
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left'
    },

    sub: {
      fontSize: 14,
      color: '#999C9E',

      [`.${classes.error} &`]: {
        color: '#FA3E2C'
      }
    }
  }
})
