import {difference} from 'lodash-es'

import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'
import type {TelegramListItem} from '@restapp/shared-api'

type UseTelegramListOptions = Partial<
  Omit<
    ApiQueryConfig<'/4.0/restapp-front/communications/v1/telegram/list', 'POST', SelectedData>,
    'select' | 'url' | 'body' | 'method'
  >
>

type LoginsCollectionType = {
  login: string
  placesIds: number[]
  is_bot_active?: boolean
}

type SelectedData = {
  filledData: TelegramListItem[]
  loginsCollection: Map<string, LoginsCollectionType>
  dataMap: Map<number, TelegramListItem>
  inactiveBotCount: number
}

export function useTelegramList(placeIds: number[], options?: UseTelegramListOptions) {
  const {data, ...rest} = useApiQuery({
    staleTime: 1000 * 60 * 3, //minutes
    ...options,
    url: '/4.0/restapp-front/communications/v1/telegram/list',
    method: 'POST',
    body: {
      place_ids: placeIds
    },
    select({payload, meta}) {
      // id плейсов которые не пришли в ответе
      const emptyPlaces = difference(
        placeIds,
        payload.map(({place_id}) => place_id)
      )

      // payload с недостающими плесами
      const filledData = [...payload].concat(
        emptyPlaces.map((id) => {
          return {
            place_id: id,
            logins: []
          }
        })
      )

      const dataMap = new Map(filledData.map((item) => [item.place_id, item]))

      /** Список логинов без дублирования в других плейсах */
      const loginsCollection = new Map<string, LoginsCollectionType>()
      let inactiveBotCount = 0

      filledData?.forEach(({logins, place_id}) => {
        logins.forEach(({login, is_bot_active}) => {
          if (loginsCollection.has(login)) {
            const selectLogin = loginsCollection.get(login)

            if (selectLogin) {
              selectLogin.placesIds.push(place_id)
              loginsCollection.set(login, selectLogin)
            }
          } else {
            !is_bot_active && ++inactiveBotCount
            loginsCollection.set(login, {login, is_bot_active, placesIds: [place_id]})
          }
        })
      })

      return {
        dataMap,
        filledData,
        loginsCollection,
        inactiveBotCount,
        meta
      }
    }
  })

  return {
    ...data,
    ...rest
  }
}
