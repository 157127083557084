import {type FormikErrors, type FormikTouched} from 'formik'

type ErrorType = undefined | string | boolean | ErrorType[] | {[key: string]: ErrorType}
type TouchedType = boolean | TouchedType[] | {[key: string]: TouchedType}

function convertErrorsToTouched(value: ErrorType): TouchedType {
  if (Array.isArray(value)) {
    return value.map(convertErrorsToTouched)
  }

  if (typeof value === 'object') {
    const result: TouchedType = {}
    for (const key of Object.keys(value)) {
      const touched = convertErrorsToTouched(value[key])

      if (touched) {
        result[key] = touched
      }
    }
    return result
  }

  return typeof value === 'string' || !!value
}

export default function getInitialTouched<T>(initialErrors: FormikErrors<T> | undefined): FormikTouched<T> {
  return convertErrorsToTouched(initialErrors as ErrorType) as FormikTouched<T>
}
