import {eventLogger} from '@eda-restapp/logger'
import {Select as MUSelect, type SelectChangeEvent, type SelectProps as MuiSelectProps} from '@mui/material'
import cn from 'classnames'
import {omit} from 'lodash-es'
import React, {type FC, useState} from 'react'

import IconComponentDefault from './IconComponentDefault'
import {Action} from './tracker.constants'
import useStyles from './Select.style'

export type SelectProps = Omit<MuiSelectProps, 'error' | 'variant'> & {
  isLocked?: boolean
  error?: boolean | ((touched: boolean) => boolean)
  multiple?: boolean
  slug?: string
  variant?: 'filled' | 'outlined' | 'standard'
  onChangeValue?(value: unknown): void
}

const Select: FC<SelectProps> = ({variant = 'filled', ...props}) => {
  const {error, isLocked, IconComponent, onBlur, onChange, onChangeValue} = props
  const selectProps = omit(props, 'IconComponent', 'onChangeValue', 'children', 'isLocked', 'slug')
  const {classes: c} = useStyles()

  const [touched, setIsTouched] = useState<boolean>(false)

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    if (onChangeValue) {
      onChangeValue(event.target.value)
    }

    if (onChange) {
      onChange(event, child)
    }
  }

  const handleChangeWithTracker = (evt: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    eventLogger({name: Action.CHANGE, value: props.slug, additional: {eventData: evt.target.value}})
    handleChange(evt, child)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!touched) {
      setIsTouched(true)
    }

    onBlur && onBlur(e)
  }

  return (
    <MUSelect
      variant={variant}
      IconComponent={IconComponent ?? (() => <IconComponentDefault isLocked={isLocked} />)}
      disabled={isLocked}
      {...selectProps}
      MenuProps={{
        ...selectProps.MenuProps,
        classes: {...selectProps.MenuProps?.classes, list: cn(c.padding, selectProps.MenuProps?.classes?.list)}
      }}
      className={cn(c.baseInput, selectProps.className)}
      classes={{...selectProps.classes, select: cn(c.select, selectProps.classes?.select)}}
      error={typeof error === 'function' ? error(touched) : error}
      onChange={handleChangeWithTracker}
      onBlur={handleBlur}
    >
      {props.children}
    </MUSelect>
  )
}

export default Select
