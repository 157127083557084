import type {Theme} from '@mui/material/'
import {makeStyles} from 'tss-react/mui'

type NavTabClasses = 'link' | 'linkActive' | 'mark' | 'inner'

export type NavTabStylesProps = {
  classes?: Partial<Record<NavTabClasses, string>>
}

// const NavTabStyles =

export const useNavTabStyles = makeStyles({
  name: 'NavTab'
})((theme: Theme) => {
  return {
    link: {
      color: '#999C9E',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      padding: '4px 0 6px',
      userSelect: 'none',
      flex: 1,
      textAlign: 'center',
      transition: 'all .3s ease',
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        flex: 'none',
        textAlign: 'left'
      },
      '&:hover': {
        color: '#1F2021'
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 2,
        backgroundColor: '#1F2021',
        transform: 'scaleX(0)',
        transformOrigin: 'left'
      }
    },
    inner: {
      position: 'relative'
    },
    linkActive: {
      color: '#1F2021',
      '&::before': {
        transition: 'all .3s ease',
        transform: 'scaleX(1)'
      }
    },
    mark: {
      position: 'absolute',
      display: 'block',
      width: 8,
      height: 8,
      top: '-2px',
      left: '100%',
      background: '#F5523A',
      boxShadow: '0px 1px 6px rgba(245, 82, 58, 0.4)',
      borderRadius: '100%',

      [theme.breakpoints.up('md')]: {
        width: 12,
        height: 12,
        top: '-4px',
        left: '100%'
      }
    }
  }
})
