import {useI18n} from '@eda-restapp/i18n'
import {Box} from '@eda-restapp/ui'
import moment from 'moment'
import React, {type FC, useEffect, useRef, useState} from 'react'
import DayPicker, {DateUtils, type DayModifiers, type Modifier} from 'react-day-picker'
import '!!style-loader!css-loader!react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'

import {Button} from '@restapp/shared-ui/Button'
import ModalWrap from '@restapp/shared-ui/Modal/Wrap'

import type {PortalComponentProps} from '../../../../hocs/withPortals'

import useDatePeriodModalStyles from './DatePeriodModal.styles'

export type PeriodTypeModal = {
  from?: Date
  to?: Date
  min?: Date
  max?: Date
  initialMonth?: Date
  numberOfMonths?: number
  canChangeMonth?: boolean
} | null

type DatePeriodModalProps = {
  canChangeMonth?: boolean
  disabled?: boolean
  disabledDays?: Modifier
  fixedStart?: boolean
  from?: Date
  initialMonth?: Date
  loading?: boolean
  max?: Date
  min?: Date
  numberOfMonths?: number
  saveButtonText?: string | number
  to?: Date
  onChange?: (range: {from?: Date | null; to?: Date | null}) => void
  onApply?: (range: {from?: Date | null; to?: Date | null}) => void
} & PortalComponentProps<PeriodTypeModal>

type State = {
  from?: Date
  to?: Date
}

/**
 * @deprecated
 */
export const DatePeriodModal: FC<DatePeriodModalProps> = (props) => {
  const {
    canChangeMonth = false,
    disabled,
    disabledDays,
    fixedStart,
    from,
    initialMonth,
    loading,
    max,
    min,
    numberOfMonths,
    saveButtonText,
    to,
    close,
    onApply,
    onChange
  } = props
  const [currentValue, setCurrentValue] = useState<State>({from, to})
  const currentRef = useRef<HTMLDivElement>(null)
  const dayPickerRef = useRef<DayPicker>(null)

  const {classes: c} = useDatePeriodModalStyles()
  const {lang, t} = useI18n()

  useEffect(() => {
    if (!currentRef.current || !dayPickerRef.current) {
      return
    }

    if (initialMonth) {
      const processedMinDate = moment(min).startOf('month')
      const processedInitialDate = moment(initialMonth).startOf('month')
      const monthsCountBetweenCurrentAndMin = processedInitialDate.diff(processedMinDate, 'month')

      const initialMonthElement = dayPickerRef.current.dayPicker.querySelector<HTMLDivElement>(
        `.DayPicker-Month:nth-child(${monthsCountBetweenCurrentAndMin + 1})`
      )

      // debugger

      if (initialMonthElement) {
        const scrollTop = initialMonthElement.offsetTop

        currentRef.current.scrollTop = scrollTop

        return
      }
    }

    currentRef.current.scrollTop = currentRef.current.scrollHeight
  }, [initialMonth, min])

  const getDisabledDays = () => {
    if (!min && !max) {
      return undefined
    }

    const processedArr = {
      ...(min && {before: min}),
      ...(max && {after: max})
    }

    return (disabledDays ? [processedArr, disabledDays] : [processedArr]) as Modifier[]
  }

  const getNumberOfMonths = () => {
    if (numberOfMonths) {
      return numberOfMonths
    }

    const processedMinMonth = moment(min).startOf('month')
    const processedMaxMonth = moment(max).startOf('month')

    const isSameMonth = moment(max).isSame(moment(min), 'month')
    const minMaxMonthQuantity = processedMaxMonth.diff(processedMinMonth, 'month')

    if (minMaxMonthQuantity === 0) {
      return isSameMonth ? 1 : 2
    }

    return minMaxMonthQuantity + 1
  }

  const handleDayClick = (day12: Date, modifiers: DayModifiers) => {
    const day = new Date(day12.getFullYear(), day12.getMonth(), day12.getDate()) // https://github.com/gpbl/react-day-picker/issues/473

    if (modifiers.disabled) {
      return
    }

    const range = DateUtils.addDayToRange(day, {from: currentValue.from, to: currentValue.to})

    if (fixedStart) {
      setCurrentValue({from: currentValue.from, to: range.to as State['to']})

      return
    }

    if (!fixedStart && range.to && currentValue.to !== undefined) {
      setCurrentValue({from: day, to: undefined})
      onChange &&
        onChange({
          from: day,
          to: day
        })

      return
    }

    setCurrentValue(range as State)
    onChange && onChange(range)
  }

  const onApplyHandle = () => {
    onApply?.({from: currentValue.from, to: currentValue.to})

    return close({from: currentValue.from, to: currentValue.to || currentValue.from})
  }

  const modifiers = {start: currentValue.from, end: currentValue.to}
  const selectedDays = [currentValue.from, {from: currentValue.from, to: currentValue.to}] as Modifier[]

  return (
    <ModalWrap<PeriodTypeModal> outsideClose {...props}>
      <Box className={c.root} data-testid='date-picker' flexDirection='column' justifyContent='flex-start'>
        <div ref={currentRef} className={c.dayPicker}>
          <DayPicker
            canChangeMonth={canChangeMonth}
            numberOfMonths={getNumberOfMonths()}
            disabledDays={getDisabledDays()}
            localeUtils={MomentLocaleUtils}
            locale={lang}
            pagedNavigation={false}
            initialMonth={min || undefined}
            modifiers={modifiers}
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
            ref={dayPickerRef}
          />
        </div>
        <Box className={c.buttonContainer} justifyContent='space-between'>
          <Button variant='contained' size='large' onClick={() => close(null)}>
            {t('core-legacy.index.zakrit', 'Закрыть')}
          </Button>
          <Button
            disabled={disabled}
            loading={loading}
            variant='contained'
            size='large'
            color='primary'
            onClick={onApplyHandle}
          >
            {saveButtonText !== undefined ? saveButtonText : t('core-legacy.index.ok', 'Ок')}
          </Button>
        </Box>
      </Box>
    </ModalWrap>
  )
}
