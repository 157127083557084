/**
 * Слаг раздела
 * Все страницы раздела должны иметь одинаковый слаг,
 * например роуты /places, /places/:id и т.д.
 */
export enum SectionSlug {
  /**
   * Главная/dashboard
   */
  home = 'dashboard',
  /**
   * Рестораны
   */
  places = 'places',
  /**
   * Заказы
   */
  active = 'orders',
  /**
   * Меню
   */
  menu = 'menu',
  /**
   * Статистика
   */
  metrics = 'metrics',
  /**
   * Place Leaders (Reliable Partner)
   */
  placeLeaders = 'place_leaders',
  /**
   * История
   */
  history = 'history',
  /**
   * График работы
   */
  schedule = 'schedule',
  /**
   * Зоны доставки
   */
  'shipping-zone' = 'shipping-zone',
  /**
   * Время готовки
   */
  cooking = 'cooking',
  /**
   * Продвижение
   */
  promotion = 'promotion',
  /**
   * Акции
   */
  promos = 'promos',
  /**
   * Новости
   */
  notifications = 'notifications',
  /**
   * Поддержка
   */
  chats = 'chats',
  /**
   * База знаний
   */
  support = 'support',
  /**
   * Внешняя ссылка на предложение идеи
   */
  suggestIdea = 'suggest_idea',
  /**
   * Отзывы
   */
  feedback = 'feedback',
  /**
   * Инструменты партнера
   */
  tools = 'tools',
  /**
   * Финансы
   */
  finance = 'finance'
}

// Данное расширение содержит значения, не используемые на бакенде в SectionFeedback
export enum SectionSlug {
  /**
   * Сотрудники
   */
  employees = 'employees',
  /**
   * Управление аккаунтом
   */
  account = 'account',
  /**
   * Роут разлогина
   */
  logout = 'logout',
  /**
   * Native debug
   */
  nativeDebug = 'native-test',
  /**
   * Тестовая среда интеграций
   */
  integrationTesting = 'integrations-testing',
  /**
   * Подписка
   */
  subscription = 'subscription',
  /**
   * Программа лояльности
   */
  programLoyaltyList = 'program_loyalty_list'
}
