import {makeStyles} from 'tss-react/mui'

export default makeStyles({
  name: 'GlobalErrorPage'
})({
  container: {
    textAlign: 'center',
    paddingTop: '10vh'
  },

  title: {
    fontSize: 24,
    fontWeight: 'bold'
  },

  text: {
    marginTop: 20,
    fontSize: 16
  },

  error: {
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#f0f0f0',
    color: '#707070',
    fontSize: 14,
    marginTop: 16,
    maxWidth: '100%',
    userSelect: 'all',
    overflow: 'auto'
  },

  button: {
    marginTop: 24
  }
})
