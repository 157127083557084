import {Typography, CopyButton, Clamp} from '@eda-restapp/ui'
import React from 'react'
import type {FC, ReactNode} from 'react'

import styles from './MultiChatInfoRow.module.css'

export type MultiChatInfoModalRowProps = {
  title: string
  value: ReactNode
  withCopy?: boolean
  withClamp?: boolean
}

export const MultiChatInfoModalRow: FC<MultiChatInfoModalRowProps> = ({title, value, withCopy, withClamp}) => {
  const renderValue = () => {
    if (typeof value === 'string') {
      return (
        <Typography variant='body2' lineHeight='tight'>
          {withClamp ? <Clamp className={styles.clamp}>{value}</Clamp> : value}
        </Typography>
      )
    }

    return value
  }

  return (
    <div className={styles.row}>
      <div className={styles.rowItem}>
        <Typography color='minor' variant='body2' lineHeight='tight'>
          {title}
        </Typography>
      </div>

      <div className={styles.rowItem}>
        {renderValue()}

        {withCopy && typeof value === 'string' && (
          <div className={styles.copyButtonContainer}>
            <CopyButton value={value} />
          </div>
        )}
      </div>
    </div>
  )
}
