export const getSerializedParams = <T>(params: Record<string, T>): string => {
  if (!params) {
    return ''
  }

  const keys = Object.keys(params)

  return keys
    .filter((key) => params[key] !== undefined)
    .map((key) => {
      const value = params[key]

      if (Array.isArray(value)) {
        if (value.length === 0) {
          return `${key}=[]`
        }
        const escapeValues = value.map(encodeURI).join(',')
        return `${key}=${escapeValues}`
      }
      return `${key}=${encodeURI(String(value))}`
    })
    .join('&')
}
