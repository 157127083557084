import {useI18n} from '@eda-restapp/i18n'

export const useBadgeOptionsByStatus = (status?: 'open' | 'solved' | 'pending') => {
  const {t} = useI18n()

  if (!status) {
    return
  }

  const getBadgeTextByStatus = () => {
    switch (status) {
      case 'open':
        return t('main-chats.multi-chat-status-badge.status-open', 'Новый')
      case 'solved':
        return t('main-chats.multi-chat-status-badge.status-solved', 'Завершен')
      case 'pending':
        return t('main-chats.multi-chats-status-badge.status-pending', 'В работе')
    }
  }

  const getBadgeColorByStatus = () => {
    switch (status) {
      case 'open':
        return '#BEC1C4'
      case 'solved':
        return '#00CA50'
      case 'pending':
        return '#0596FA'
    }
  }

  return {
    text: getBadgeTextByStatus(),
    color: getBadgeColorByStatus()
  }
}
