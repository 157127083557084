import {useI18n} from '@eda-restapp/i18n'
import {Box, ChevronRightMIcon, SearchIcon, Typography, useIsMobileStrict, UserAvatar} from '@eda-restapp/ui'
import React, {useMemo, useState} from 'react'

import type {LinkedAccount} from '@restapp/shared-api/types/passport'
import {Button} from '@restapp/shared-ui/Button'
import {Input} from '@restapp/shared-ui/Input/Input'

import {PassportIcon} from '../../../../../PassportIcon'

import styles from './LinkedAccounts.module.css'

export type LinkedAccountsProps = {
  accounts: LinkedAccount[]
  onAuthWithLinkedAccount: (account: LinkedAccount) => void
}

export const LinkedAccounts: React.FC<LinkedAccountsProps> = ({accounts, onAuthWithLinkedAccount}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  const [searchQuery, setSearchQuery] = useState('')

  const searchResult = useMemo(() => {
    const searchQueryLowercased = searchQuery.toLowerCase().trim()
    return accounts.filter((account) =>
      [account.login, account.display_name].join('').toLowerCase().includes(searchQueryLowercased)
    )
  }, [searchQuery, accounts])

  const linkedAccountsCount = t(
    'core-auth.passport.linked-accounts.linked-accounts-count',
    'Привязан/о {count} аккаунт/а/ов',
    {
      none: 'Аккаунты не привязаны',
      one: 'Привязан {count} аккаунт',
      some: 'Привязано {count} аккаунта',
      many: 'Привязано {count} аккаунтов',
      count: accounts.length
    }
  )

  const hasOneAccount = accounts.length === 1

  return (
    <Box className={styles.listContainer} flexDirection='column'>
      <Box px='m' py='s' alignItems='center' className={styles.title}>
        <Typography variant='body2'>{linkedAccountsCount}</Typography>
      </Box>

      <Box gap={hasOneAccount ? undefined : 's'} flexDirection='column'>
        <Box px='m'>
          {!hasOneAccount && (
            <Input
              classes={{inputFormControl: styles.inputFormControl, outlined: styles.outlined}}
              startAdornment={<SearchIcon color='var(--colors-cold-gray-300, #999C9E)' />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              placeholder={t('core-auth.passport.linked-accounts.enter-account-name', 'Введите название аккаунта')}
            />
          )}
        </Box>

        <Box pb='s' flexDirection='column'>
          {searchResult.length == 0 && (
            <Box className={styles.notFound} px='m' py='s'>
              <Typography variant={'caption1'}>
                {t('core-auth.passport.linked-accounts.not-found', 'Ничего не найдено')}
              </Typography>
            </Box>
          )}
          {searchResult.map((account) => (
            <div key={account.login} className={styles.disableLink}>
              <Box
                key={account.login}
                onClick={isMobile ? () => onAuthWithLinkedAccount(account) : undefined}
                className={styles.row}
                pl='s'
                pr='m'
              >
                <Box className={styles.rowContent} alignItems='center' flexGrow={1}>
                  <Box flexGrow={1}>
                    <Box p='s' flexShrink={0} justifyContent='center' alignItems='center'>
                      <UserAvatar size={40} src={account.avatar} style={{borderRadius: '50%'}} />
                    </Box>

                    <Box flexDirection='column' alignItems='flex-start' justifyContent='center'>
                      <Typography
                        variant='body2'
                        thickness='medium'
                        data-testid={
                          'auth-password-popup-login' /*Авторизация | Логин в попапе "Используйте Яндекс ID для входа"*/
                        }
                      >
                        {account.login}
                      </Typography>

                      <Typography variant='caption1'>{account.display_name}</Typography>
                    </Box>
                  </Box>

                  {isMobile && <ChevronRightMIcon data-testid='ui-icon-AuthPasswordYLog' />}

                  {!isMobile && (
                    <Button
                      onClick={() => onAuthWithLinkedAccount(account)}
                      variant='outlined'
                      size='small'
                      className={styles.button}
                      data-testid={
                        'auth-password-Y-log-in-btn' /*Авторизация | Кнопка "Я Войти" в попапе "Используйте Яндекс ID для входа"*/
                      }
                    >
                      <Box gap='xs'>
                        <PassportIcon className={styles.buttonImg} />

                        <Typography variant='caption1' thickness='medium'>
                          {t('core-auth.passport.linked-accounts.log-in-button', 'Войти')}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
