import type {Category, MenuItem} from '@restapp/shared-api'

import soup from './assets/soup.jpg'

export const categories: Category[] = [
  {
    id: '1',
    available: true,
    name: 'Супы',
    sortOrder: 100
  },
  {
    id: '2',
    available: true,
    name: 'Горячее',
    sortOrder: 120
  },
  {
    id: '3',
    available: true,
    name: 'Суши',
    sortOrder: 110
  },
  {
    id: '4',
    available: false,
    name: 'Сладкое',
    sortOrder: 100
  },
  {
    id: '5',
    available: false,
    name: 'Напитки',
    sortOrder: 120,
    reactivatedAt: '2022-02-09T21:00:00+00:00'
  }
]

export const menuItems: MenuItem[] = [
  {
    id: '1',
    categoryId: '1',
    available: false,
    name: 'Борщ',
    price: 125,
    description: 'Вкусный борщ',
    images: [],
    thumbnails: [],
    measure: 350,
    measureUnit: 'г',
    sortOrder: 100,
    vat: 100,
    reactivatedAt: null,
    modifierGroups: []
  },
  {
    id: '2',
    categoryId: '1',
    available: true,
    name: 'Суп с фрикадельками, вермешелью и грибами',
    price: 125,
    description: 'Вкусный суп',
    images: [],
    thumbnails: [{url: soup}],
    measure: 250,
    measureUnit: 'г',
    sortOrder: 110,
    vat: 100,
    modifierGroups: [
      {
        id: '500',
        maxSelectedModifiers: 1,
        minSelectedModifiers: 0,
        modifiers: [
          {
            available: true,
            id: '500',
            maxAmount: 1,
            minAmount: 1,
            name: 'Белый',
            price: 5
          }
        ],
        name: 'Хлеб',
        sortOrder: 100
      }
    ]
  },
  {
    id: '3',
    categoryId: '5',
    available: true,
    name: 'Сок',
    price: 25,
    description: 'Вкусный сок',
    images: [],
    thumbnails: [],
    measure: 300,
    measureUnit: 'мл',
    sortOrder: 110,
    vat: 100,
    reactivatedAt: null,
    modifierGroups: [
      {
        id: '501',
        maxSelectedModifiers: 2,
        minSelectedModifiers: 1,
        modifiers: [
          {
            available: true,
            id: '120',
            maxAmount: 1,
            minAmount: 1,
            name: 'Апельсиновый',
            price: 5,
            reactivatedAt: null
          },
          {
            available: false,
            id: '121',
            maxAmount: 1,
            minAmount: 1,
            name: 'Ананасовый',
            price: 5,
            reactivatedAt: null
          }
        ],
        name: 'На выбор',
        sortOrder: 100
      }
    ]
  },
  {
    id: '4',
    categoryId: '6',
    available: true,
    name: 'Поке с тунцом',
    price: 525,
    description: 'Вкусный поке с тунцом',
    images: [],
    thumbnails: [],
    measure: 350,
    measureUnit: 'г',
    sortOrder: 120,
    vat: 100,
    reactivatedAt: null,
    modifierGroups: [
      {
        id: '501',
        maxSelectedModifiers: 2,
        minSelectedModifiers: 2,
        modifiers: [
          {
            available: true,
            id: '120',
            maxAmount: 1,
            minAmount: 1,
            name: 'Апельсиновый',
            price: 5,
            reactivatedAt: null
          },
          {
            available: false,
            id: '121',
            maxAmount: 1,
            minAmount: 1,
            name: 'Ананасовый',
            price: 5,
            reactivatedAt: null
          }
        ],
        name: 'На выбор',
        sortOrder: 100
      }
    ]
  }
]
