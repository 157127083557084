import {MenuIcon} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC} from 'react'

import {useSidebar} from '../../hooks'

import styles from './SidebarBurger.module.css'

type SidebarBurgerProps = {
  className?: string
}

export const SidebarBurger: FC<SidebarBurgerProps> = ({className}) => {
  const {setVisibility} = useSidebar()

  return (
    <button
      className={cn(styles.root, className)}
      onClick={() => setVisibility(true)}
      data-testid={'ui-sidebar-burger' /*UI | Кнопка показа/скрытия сайдбара*/}
    >
      <MenuIcon color='var(--theme-text, #1f2021)' fontSize={40} />
    </button>
  )
}
