import React from 'react'

import {OutsideLink} from '@restapp/shared-ui'

import useAddressLinkStyles from './AddressLink.styles'

export type AddressLinkProps = {
  className?: string
  classes?: Partial<Record<keyof typeof useAddressLinkStyles, string>>
} & {
  address: string
}

const AddressLink: React.FC<AddressLinkProps> = ({address, classes}) => {
  const {classes: c} = useAddressLinkStyles(undefined, {props: {classes}})

  return (
    <div className={c.root}>
      <OutsideLink className={c.link} href={`https://yandex.ru/maps/?text=${address}`} target='_blank' rel='noreferrer'>
        {address}
      </OutsideLink>
    </div>
  )
}

export default AddressLink
