import {useI18n} from '@eda-restapp/i18n'
import {Box, ChevronRightMIcon, Clamp, Typography} from '@eda-restapp/ui'
import React from 'react'

import styles from './PartnerUidItem.module.css'

type PartnerUidItemProps = {
  email: string
  restaurantCount: number
  onClick: () => void
}

export const PartnerUidItem: React.FC<PartnerUidItemProps> = ({email, restaurantCount, onClick}) => {
  const {t} = useI18n()

  return (
    <Box
      className={styles.container}
      onClick={onClick}
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      data-testid='select-partner-uid-item'
    >
      <div className={styles.textContainer}>
        <Typography variant='body2'>
          <Clamp lines={2}>{email}</Clamp>
        </Typography>

        <Typography color='minor' variant='caption2'>
          {t('core-auth.partner-uid-item.restaurant-count', 'Количество ресторанов', {
            none: '{count} ресторанов',
            one: '{count} ресторан',
            some: '{count} ресторана',
            many: '{count} ресторанов',
            count: restaurantCount
          })}
        </Typography>
      </div>

      <ChevronRightMIcon />
    </Box>
  )
}
