import type {FieldProps} from 'formik'
import {useFormikContext, useField} from 'formik'
import {get} from 'lodash-es'
import {type ReactNode, type ChangeEvent} from 'react'
import {useEvent} from 'react-use-event-hook'

type PossibleValuesTypes =
  | string
  | number
  | boolean
  | null
  | (string | number | boolean | null)[]
  | ChangeEvent
  | undefined

type ChildrenProps<T extends PossibleValuesTypes> = FieldProps<T>

// Ограниченная версия FieldConfig из formik
type Props<T extends PossibleValuesTypes> = {
  children: (props: ChildrenProps<T>) => ReactNode
  name: string
}

const isReactChangeEvent = (v: unknown): v is ChangeEvent => {
  // По наличию этих двух полей делаем вывод что это SyntheticEvent
  // Можно было бы проверять type === 'change' но чет стремно
  if (typeof get(v, 'persist') === 'function' && typeof get(v, 'preventDefault') === 'function') {
    return true
  }

  return false
}

/** Т.к формик в onChange обрабатывает только события, нужен этот кастомный onChange */
const getOnChange = <T extends PossibleValuesTypes>({field, form}: ChildrenProps<T>, shouldValidate?: boolean) => {
  const originalOnChange = field.onChange

  return (value: PossibleValuesTypes) => {
    if (isReactChangeEvent(value)) {
      originalOnChange(value)
    } else {
      void form.setFieldValue(field.name, value, shouldValidate)
    }
  }
}

function Field<T extends PossibleValuesTypes>({children, ...props}: Props<T>) {
  const form = useFormikContext()
  const [field, meta] = useField(props)

  field.onChange = useEvent(getOnChange({field, meta, form}, form.validateOnChange))

  return children?.({
    field,
    meta,
    form
  })
}

export default Field
