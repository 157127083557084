import {makeStyles} from 'tss-react/mui'
import type {Theme} from '@mui/material/styles'

export const PhoneNumberStyles = (theme: Theme) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    title: {
      display: 'block',
      color: '#21201F',
      fontSize: 14,
      lineHeight: '18px'
    },
    phone: {
      display: 'block',
      color: '#21201F',
      fontSize: 16,
      lineHeight: '17px',
      fontWeight: 500
    }
  }) as const

export default makeStyles()(PhoneNumberStyles)
