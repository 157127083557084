import {useExp3} from '@eda-restapp/configs'
import {getBrand, getServiceBrandByCountryCode} from '@eda-restapp/i18n'

import {useCountryCode} from './useCountryCode'

export const useServiceBrand = () => {
  const commonConfig = useExp3('restapp_common')
  const {countryCode} = useCountryCode()
  const brand = getBrand()

  if (commonConfig.configDomainHost) {
    return commonConfig.configDomainHost
  }

  const nativeServiceBrand = getServiceBrandByCountryCode(countryCode)

  return nativeServiceBrand ?? brand
}
