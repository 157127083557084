import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, Modal} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import {Button} from '@restapp/shared-ui'
import {HelpLink} from '@restapp/shared-support'
import {getAutostopFinishTime} from '@restapp/shared-places'

import styles from './AutostopDescriptionModal.module.css'

type AutostopDescriptionModalProps = {
  placeBlockFinishDate?: string
}

export const AutostopDescriptionModal: FC<AutostopDescriptionModalProps> = ({placeBlockFinishDate}) => {
  const {t} = useI18n()

  return (
    <>
      <Modal.Header
        title={
          getAutostopFinishTime(placeBlockFinishDate) ||
          t('core-legacy.autostop-description-modal.restoran-otklyuchen', 'Ресторан отключен')
        }
      />
      <Modal.Content>
        <Box flexDirection='column' pt='s'>
          <Typography variant='body2' mb='l'>
            {t(
              'core-legacy.autostop-description-modal.vash-restoran-otmenil-zakaz-poetomu',
              'Ваш ресторан отменил заказ, поэтому он временно отключён.'
            )}
          </Typography>
          <Typography variant='body2' mb='s'>
            {t(
              'core-legacy.autostop-description-modal.eto-pozvolit-vam-razobratsya-chto-p',
              'Это позволит вам разобраться, что пошло не так, — а клиенты не будут сталкиваться с негативным опытом.'
            )}
          </Typography>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <HelpLink className={styles.footerHelpLink} linkKey='legacy.place-autostop'>
          <Button className={styles.button} color='primary' size='large' variant='contained'>
            {t('core-legacy.autostop-description-modal.vozmozhnie-resheniya', 'Возможные решения')}
          </Button>
        </HelpLink>
      </Modal.Footer>
    </>
  )
}
