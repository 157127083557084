import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation, useMatch, useNavigate} from 'react-router-dom'

import {Spinner} from '@restapp/shared-ui'

import {OAUTH_REDIRECT_PATH} from '../../../constants'
import {setOauthToken} from '../../../slice'
import {OauthStateEncoder} from '../../helpers/OauthStateEncoder'

import {parseOauthRedirectHash} from './parseOauthRedirectHash'

export const CatchOauthRedirect: React.FC<{enabled: boolean; children: React.ReactNode}> = ({children, enabled}) => {
  const match = useMatch(OAUTH_REDIRECT_PATH)
  const dispatch = useDispatch()
  const {t} = useI18n()
  const navigate = useNavigate()
  const location = useLocation()
  const enabledAndMatched = enabled && match

  useEffect(() => {
    if (!enabledAndMatched) {
      return
    }

    const {token, state} = parseOauthRedirectHash(location.hash)

    if (token) {
      eventLogger({name: 'passport:received_oauth_token'})
      dispatch(setOauthToken(token))
    }

    if (!state) {
      navigate('/')
    } else {
      const oauthState = OauthStateEncoder.parse(state)
      const redirectPathFromState = oauthState.redirect_path

      navigate(redirectPathFromState || '/')
    }

    window.dispatchEvent(new Event('oauthcomplete'))
  }, [dispatch, navigate, location, enabledAndMatched])

  if (enabledAndMatched) {
    return (
      <Spinner
        slug='CatchOauthRedirect'
        absolute
        caption={t('core-auth.catch-oauth-redirect.spinner-caption', 'Авторизуем пользователя')}
      />
    )
  }

  return <>{children}</>
}
