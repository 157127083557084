import {useState} from 'react'
import moment from 'moment'
import type {DayRangeProps, UseDayRangeReturnType} from './types'

type Props = {
  initialTo?: Date
  initialFrom?: Date
  dateFormat?: string
}

export const useDayRange = ({
  min = moment().subtract(2, 'years').toDate(),
  max = moment().add(1, 'years').toDate(),
  initialTo,
  initialFrom,
  initialMonth = moment().toDate(),
  dateFormat,
  ...rest
}: Props & DayRangeProps = {}): UseDayRangeReturnType => {
  const [from, setDateFrom] = useState<Date | undefined>(initialFrom)
  const [to, setDateTo] = useState<Date | undefined>(initialTo)

  const componentProps = {
    ...rest,
    from,
    to,
    min: min || moment().toDate(),
    max: max || moment().toDate(),
    onFromChange: setDateFrom,
    onToChange: setDateTo,
    initialMonth: initialMonth
  }

  const momentFrom = from && moment(from).startOf('day')
  const momentTo = to && moment(to).endOf('day')

  return {
    from: momentFrom?.toDate(),
    to: momentTo?.toDate(),
    fromAsString: momentFrom?.format(dateFormat),
    toAsString: momentTo?.format(dateFormat),
    componentProps
  }
}
