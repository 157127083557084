import {createSlice} from '@reduxjs/toolkit'

type ServiceSliceState = {
  appVersionStatus: 'up_to_date' | 'update_available' | 'update_accepted'
}

const initialState: ServiceSliceState = {
  appVersionStatus: 'up_to_date'
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updateAvailable(state) {
      state.appVersionStatus = 'update_available'
    },
    acceptUpdate(state) {
      state.appVersionStatus = 'update_accepted'
    }
  }
})

export const {acceptUpdate, updateAvailable} = serviceSlice.actions

type ServiceSlice = {[serviceSlice.name]: ReturnType<typeof serviceSlice.reducer>}

export const selectUpdateAvailable = (state: ServiceSlice) =>
  state[serviceSlice.name]?.appVersionStatus === 'update_available'

export const selectUpdateAccepted = (state: ServiceSlice) =>
  state[serviceSlice.name]?.appVersionStatus === 'update_accepted'
