import {errorLogger, eventLogger} from '@eda-restapp/logger'
import type {OperatorFunction, Subject} from 'rxjs'
import {concat, of, throwError, timer} from 'rxjs'
import {catchError, filter, mergeMap, retryWhen, tap} from 'rxjs/operators'

interface Config {
  statusSubject: Subject<boolean>
  retryInterval: (retryCount: number) => number
  maxRetryCount?: number
}

/**
 * Следит за состоянием соединения (RxJs потока)
 *  - перезапускает соедение если произошла ошибка
 *  - выдерживает паузу в retryInterval милисекунд между попытками
 *  - транслирует состояние соединения (true/false) в переданный subject
 *
 * @param statusSubject subject для приёма статуса соединения
 * @param retryInterval пауза между попытками в ms
 * @param maxRetryCount максимальное количество ретраев
 */
export default function guardConnection<T>({
  statusSubject,
  retryInterval,
  maxRetryCount
}: Config): OperatorFunction<T, T> {
  const startIndicator = Math.random()

  return (observable) =>
    concat(of(startIndicator), observable).pipe(
      tap({
        next: (message) => {
          if (message === startIndicator) {
            eventLogger({
              name: 'notifications:notifier_socket_connection_started'
            })
          } else {
            statusSubject.next(true)
          }
        },
        error: () => {
          statusSubject.next(false)
        },
        complete: () => {
          statusSubject.next(false)
        }
      }),
      filter((message): message is T => message !== startIndicator),
      retryWhen<T>((errors) =>
        errors.pipe(
          mergeMap((error, retryAttempt) => {
            if (maxRetryCount && retryAttempt >= maxRetryCount - 1) {
              return throwError(error)
            }

            return timer(retryInterval(retryAttempt))
          })
        )
      ),
      catchError((error) => {
        void errorLogger({
          message: 'notifications:notifier_socket_connection_cannot_estabilished',
          level: 'error',
          error: new Error('notifications:notifier_socket_connection_cannot_estabilished', {cause: error})
        })

        return of(error)
      })
    )
}
