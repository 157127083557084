import {usePlaces} from '@restapp/core-places'
import {isEmpty} from 'lodash-es'
import type {FC} from 'react'

// Хотим чтобы при инициализации приложения у нас всегда были выбранные рестораны иначе это приводит к проблемам
// поэтому если в сторе нет выбранных ресторанов (пользователь еще не тыкал селектор) то выбираем все
export const AutoSelectAllPlaces: FC = () => {
  const {selectedPlaceIds, setSelectedPlaceIds} = usePlaces({
    onSuccess: (data) => {
      if (isEmpty(selectedPlaceIds)) {
        setSelectedPlaceIds(data.payload.map((place) => place.id))
      }
    }
  })

  return null
}
