import {useI18n} from '@eda-restapp/i18n'
import {useState, useRef, useEffect, useCallback} from 'react'
import type {RefObject, ChangeEvent} from 'react'

import {parseFormattedFloat} from '@restapp/promotion/utils'
import {parseFormattedInt} from '@restapp/shared/utils'
import {formatNumber} from '@restapp/shared/utils/Format'

import {MONEY_INPUT_FIXED_DECIMALS} from '../features/recommendations/constants'

type Validate = ({value, numberValue}: {value: string; numberValue: number}) => boolean

type UseMoneyInputPropsType = {
  defaultValue?: number
  /**
   * Мемоизируйте функцию валидации в родительском компоненте с помощью useCallback.
   * validate используется для повторной валидации в useEffect-e, если используемые
   * значения изменились.
   * Напр. конфиг, или пользовательский ввод, из соседнего поля, от которого зависит
   * текущее
   */
  validate?: Validate
  onChange?: (value?: number) => void
  /**
   * Копейки
   */
  withDecimals?: boolean
}

export type UseMoneyInputReturnType = {
  value: string
  isValid: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  numberValue: number
  inputRef: RefObject<HTMLInputElement>
  setNumber: (value: number) => void
  setNumberWithDecimals: (value: string) => void
}

const INITIAL_VALUE = ''

export default function useMoneyInput({
  defaultValue,
  validate = () => true,
  onChange: onChangeExternal,
  withDecimals
}: UseMoneyInputPropsType = {}): UseMoneyInputReturnType {
  const {lang} = useI18n()
  const inputRef = useRef<HTMLInputElement>(null)

  const isDirty = useRef(false)

  const parseFormattedValue = useCallback(
    (value: string) => (withDecimals ? parseFormattedFloat(value, lang) : parseFormattedInt(value)),
    [withDecimals, lang]
  )

  const formatNumberWithDecimals = useCallback(
    (value: number | bigint) =>
      formatNumber(
        value,
        lang,
        withDecimals
          ? {
              minimumFractionDigits: MONEY_INPUT_FIXED_DECIMALS ? 2 : 0,
              maximumFractionDigits: 2,
              useGrouping: true
            }
          : {useGrouping: true}
      ),
    [withDecimals, lang]
  )

  const getDefaultValue = () => (defaultValue === undefined ? INITIAL_VALUE : formatNumberWithDecimals(defaultValue))

  const [currentValue, setCurrentValue] = useState(getDefaultValue())

  const setNumber = (value: number) => {
    if (value > Number.MAX_SAFE_INTEGER) {
      return
    }

    isDirty.current = true

    return setCurrentValue(formatNumberWithDecimals(value))
  }

  const setNumberWithDecimals = (value: string) => {
    setCurrentValue(value)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    const numberValue = parseFormattedValue(value)

    onChangeExternal?.(numberValue)

    setNumber(numberValue)
  }

  const updateDefaultValue = () => {
    if (isDirty.current) {
      return
    }

    setCurrentValue(getDefaultValue())
  }

  const resetIsDirtyOnUnmount = () => () => {
    isDirty.current = false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateDefaultValue, [defaultValue])
  useEffect(resetIsDirtyOnUnmount, [])

  const returnValue = isDirty.current ? currentValue : currentValue || getDefaultValue()
  const returnNumberValue = parseFormattedValue(returnValue)

  const isValid = validate({
    value: returnValue,
    numberValue: returnNumberValue
  })

  return {
    value: returnValue,
    isValid,
    onChange,
    numberValue: returnNumberValue,
    inputRef,
    setNumber,
    setNumberWithDecimals
  }
}
