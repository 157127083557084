import type {NotificationsChannel} from '@eda-restapp/microfrontend'
import type {Observable} from 'rxjs'
import type {MeaningfulNotification} from '@restapp/core-notifications'
import {parseXivaMessage} from '../parseXivaMessage'
import {broadcastXivaNotification} from '../broadcastXivaNotification'
import {errorLogger, eventLogger} from '@eda-restapp/logger'

type Dependencies = {
  socketEvent$: Observable<MeaningfulNotification>
  channel: NotificationsChannel
}

export const broadcastWebSocketNotifications = ({socketEvent$, channel}: Dependencies) => {
  channel.leaderElector.awaitLeadership().then(() => {
    socketEvent$.subscribe(async (event) => {
      try {
        const xivaNotification = parseXivaMessage(event.message)

        await broadcastXivaNotification(channel, xivaNotification, {source: 'socket', clicked: false})

        eventLogger({
          name: 'socket',
          value: 'broadcastWebSocketNotifications',
          additional: {eventData: xivaNotification.eventData?.event || 'unknown_event_name'}
        })
      } catch (error) {
        errorLogger({
          level: 'error',
          error:
            error instanceof Error
              ? error
              : new Error('Error broadcasting socket to notifications channel', {cause: error}),
          additional: {eventSlug: 'notifications:subscribe-notifications-channel'}
        })
      }
    })
  })
}
