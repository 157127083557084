import {useI18n} from '@eda-restapp/i18n'
import {Banner, LegacyButton as Button, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import type {FC} from 'react'
import React from 'react'

import styles from './BannerHolidaySchedule.module.css'

export type BannerHolidayScheduleProps = {
  className?: string
  backgroundImage?: string
  backgroundLayer: string
  onClick: () => void
}

export const BannerHolidaySchedule: FC<BannerHolidayScheduleProps> = ({
  className,
  backgroundImage,
  backgroundLayer,
  onClick
}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  return (
    <Banner
      className={className}
      backgroundLayer={backgroundLayer}
      illustrationBgImage={backgroundImage}
      illustrationClassName={styles.bannerIllustration}
      footerMarginTop={isMobile ? 135 : undefined}
      header={t('shared-places.banner-holiday-disabled-places.header-text', 'Заполните праздничное расписание')}
      content={
        <Typography variant='caption1' lineHeight='loose'>
          {t(
            'shared-places.banner-holiday-disabled-places.description-text',
            'Чтобы принимать заказы, настройте расписание и активируйте ресторан в разделе «Мои рестораны».'
          )}
        </Typography>
      }
      footer={
        <Button size='m' variant='light' styles={{padding: '0 44px'}} onClick={onClick}>
          <Typography variant='body2' thickness='medium'>
            {t('shared-places.banner-holiday-disabled-places.badge-label', 'Заполнить расписание')}
          </Typography>
        </Button>
      }
    />
  )
}
