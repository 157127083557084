import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import {addListener, isAnyOf} from '@reduxjs/toolkit'
import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import useLocalStorageState from 'use-local-storage-state'

import {usePlaces} from '@restapp/core-places'
import {MenuStore} from '@restapp/shared-menu'

export function useStoplistNotificationMetric(notificationType: 'modal' | 'snackbar') {
  const stoplistNotificationsConfig = useExp3('restapp_stoplist_notifications')
  const dispatch = useDispatch()
  const {selectedPlaceId} = usePlaces()

  const [displayedAt, setDisplayedAt] = useLocalStorageState(
    `stoplistAvailabilityNotification:${notificationType}:displayedAt`,
    {defaultValue: 0}
  )

  useEffect(() => {
    const msInMinute = 60_000
    const thresholdMs = stoplistNotificationsConfig.metricTimeThresholdAfterPushing * msInMinute

    if (!displayedAt || Date.now() - displayedAt > thresholdMs) {
      return
    }

    const matchStoplistAction = isAnyOf(
      MenuStore.actions.common.toggleItem,
      MenuStore.actions.common.toggleCategory,
      MenuStore.actions.common.toggleOption
    )

    const unsubscribe = dispatch(
      addListener({
        matcher: matchStoplistAction,
        effect: (action) => {
          if (Date.now() - displayedAt < thresholdMs) {
            metrika({
              target: `used_stoplist_after_stoplist_availability_notification_${notificationType}`,
              params: {placeId: selectedPlaceId, itemId: action.payload.id, notificationDisplayedAt: displayedAt}
            })

            setDisplayedAt(0)
          }
        }
      })
    ) as unknown as () => void

    return () => {
      unsubscribe()
    }
  }, [
    dispatch,
    displayedAt,
    notificationType,
    selectedPlaceId,
    setDisplayedAt,
    stoplistNotificationsConfig.metricTimeThresholdAfterPushing
  ])

  const onNotificationDisplayed = useCallback(() => {
    metrika({target: `stoplist_availability_notification_show_${notificationType}`})
    setDisplayedAt(Date.now())
  }, [notificationType, setDisplayedAt])

  return onNotificationDisplayed
}
