import type {RefObject} from 'react'
import {useEffect, useState} from 'react'

const useIsOnScreen = (ref: RefObject<HTMLElement>, threshold = 1, margin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([target]) => {
        setIntersecting(target.isIntersecting)
      },
      {threshold, rootMargin: margin}
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export default useIsOnScreen
