import React, {useRef} from 'react'
import {CSSTransition} from 'react-transition-group'
import {Box, ChevronUpMIcon, LegacyButton as Button} from '@eda-restapp/ui'
import {useI18n} from '@eda-restapp/i18n'
import cn from 'classnames'

import styles from './SnackbarsListControls.module.css'

export type SnackbarsListControlsProps = {
  className?: string
  visible: boolean
  onClearAll: () => void
  onCollapse: () => void
  transitionMs: number
}

export const SnackbarsListControls: React.FC<SnackbarsListControlsProps> = ({
  className,
  visible,
  onClearAll,
  onCollapse,
  transitionMs
}) => {
  const {t} = useI18n()
  const transitionRef = useRef<HTMLDivElement>(null)

  return (
    <CSSTransition nodeRef={transitionRef} in={visible} timeout={transitionMs} unmountOnExit classNames={{...styles}}>
      <Box ref={transitionRef} className={className} gap='s'>
        <Button className={styles.control} onClick={onClearAll} size='s' variant='light'>
          {t('ui.snackbars.clear', 'Очистить все')}
        </Button>
        <Button className={cn(styles.control, styles.controlCollapse)} size='s' variant='light' onClick={onCollapse}>
          <ChevronUpMIcon />
        </Button>
      </Box>
    </CSSTransition>
  )
}
