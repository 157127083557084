import {makeStyles} from 'tss-react/mui'

export const useBusyModeModalLayoutStyles = makeStyles({name: 'BusyModeModalLayout'})({
  tabs: {
    marginTop: 8
  },
  tab: {
    minHeight: 40
  },
  emptyTab: {
    display: 'none'
  },
  warning: {
    marginTop: 8,
    fontSize: 14,
    alignItems: 'center'
  },
  busyModeEnabledPlaceCaption: {
    marginTop: -4
  }
})
