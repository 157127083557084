import cn from 'classnames'
import React from 'react'

import type {OneColumnStylesProps} from './OneColumn.styles'
import {useOneColumnStyles} from './OneColumn.styles'

type OneColumnProps = {
  classes?: OneColumnStylesProps['classes']
  className?: string
  children?: React.ReactNode
}

const OneColumn: React.FC<OneColumnProps> = ({classes, className, children}) => {
  const {classes: c} = useOneColumnStyles(undefined, {props: {classes}})

  return <div className={cn(c.root, className)}>{children}</div>
}

export default OneColumn
