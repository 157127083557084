import {eventLogger} from '@eda-restapp/logger'
import {CrossLIcon} from '@eda-restapp/ui'
import {Drawer, type DrawerProps as DrawerPropsLib} from '@mui/material'
import cn from 'classnames'
import React, {type ReactNode, useEffect} from 'react'

import type {DrawerStyles} from './Drawer.style'
import {useStyles} from './Drawer.style'

export type DrawerProps = {
  children: ReactNode
  slug?: string
  open?: boolean
  mobile?: boolean
  onClose?(): void
} & {
  className?: string
  classes?: Partial<Record<keyof typeof DrawerStyles, string>>
} & Partial<Omit<DrawerPropsLib, 'onClose'>>

/**
 * Drawer UI component
 * @deprecated Используйте 'Drawer' из '@eda-restapp/ui'
 */
function DrawerUI({anchor = 'right', slug, children, classes, mobile, onClose, open, ...rest}: DrawerProps) {
  const {classes: c} = useStyles(undefined, {props: {classes}})
  const handleClose = () => {
    eventLogger({name: 'drawer', value: slug, additional: {eventData: 'close'}})
    onClose?.()
  }

  useEffect(() => {
    if (open) {
      eventLogger({name: 'drawer', value: slug, additional: {eventData: 'open'}})
    }
  }, [open, slug])

  return (
    <Drawer
      classes={{root: c.root, paper: cn(c.drawerPaper, {[c.drawerPaperDesktop]: !mobile})}}
      open={open}
      anchor={anchor}
      keepMounted={mobile}
      data-testid={'ui__drawer' /* UI | Шторка */}
      onClose={handleClose}
      {...rest}
    >
      {handleClose && (
        <div data-testid={'close-drawer-button' /* Дровер | Кнопка закрытия*/} className={c.closeIconWrapper}>
          <CrossLIcon onClick={() => handleClose()} />
        </div>
      )}
      {children}
    </Drawer>
  )
}

export default DrawerUI
