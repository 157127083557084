import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

const URL = '/4.0/restapp-front/marketing/v1/ad/cpm/edit_campaign'

const useUpdateCPM = () => {
  const {isLoading, isSuccess, isError, mutateAsync} = useApiMutation({
    url: URL,
    method: 'POST'
  })

  const update = (directToken: string, body: VendorApi[typeof URL]['POST']['body']) =>
    mutateAsync({
      headers: {
        Authorization: directToken
      },
      body
    })

  return {
    update,
    isSuccess,
    isLoading,
    isError
  }
}

export default useUpdateCPM
