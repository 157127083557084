import type {ComponentType} from 'react'
import React from 'react'
import type {Location, NavigateFunction} from 'react-router-dom'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

export type WithRouterV6<Params extends {[K in keyof Params]?: string} = {}> = {
  navigate: NavigateFunction
  location: Location
  params: Readonly<Params>
}

export default function withRouterV6<Props extends JSX.IntrinsicAttributes>(
  OriginalComponent: ComponentType<Props>
): ComponentType<Props> {
  return function WithRouterV6HOC(props: Props) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    return <OriginalComponent {...props} {...{navigate, location, params}} />
  }
}
