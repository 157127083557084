import type {INotification} from '@restapp/shared-api'

import type {CommunicationStatus, NotificationType} from '../types'
import {CommunicationType} from '../types'

export function mapNotificationsItem(notification: INotification): NotificationType {
  return {
    type: CommunicationType.NOTIFICATION,
    status: notification.status as CommunicationStatus,
    id: notification.id,
    created: notification.created,
    ...notification.payload
  }
}
