import {Box, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import type {CSSProperties} from 'react'
import React, {useEffect} from 'react'

import Sticky from '@restapp/core-legacy/common/components/Sticky'
import {useServiceBrand} from '@restapp/shared'

import {useHeaderContext} from '../../hooks'

import type {HeaderRowStylesProps} from './HeaderRow.styles'
import {useHeaderRowStyles} from './HeaderRow.styles'

const DEFAULT_HEADER_ROW_HEIGHT = 55 // px

type HeaderProps = {
  stickyPosition?: number
  height?: string | number
  className?: string
  classes?: HeaderRowStylesProps['classes']
  style?: CSSProperties
  children?: React.ReactNode
}

const HeaderRow: React.FC<HeaderProps> = ({stickyPosition, height, className, classes, children, style}) => {
  const {classes: c} = useHeaderRowStyles(undefined, {props: {classes}})

  const serviceBrand = useServiceBrand()
  const context = useHeaderContext()
  const isMobile = useIsMobileStrict()

  const getHeight = () => (height !== undefined ? height : DEFAULT_HEADER_ROW_HEIGHT)

  const isSticky = stickyPosition !== undefined

  useEffect(() => {
    if (context && isSticky) {
      context.registerStickyRowHeight({
        position: stickyPosition,
        height: getHeight()
      })
    }
    return () => {
      if (context && isSticky) {
        context.unregisterStickyRowHeight(stickyPosition)
      }
    }
  }, [])

  useEffect(() => {
    if (context && isSticky) {
      context.changeStickyRowHeightRegistration(stickyPosition, {
        position: stickyPosition,
        height: getHeight()
      })
    }
  }, [stickyPosition])

  if (isSticky) {
    const top = context && isSticky ? context.getStickyOffset(stickyPosition) : 0

    return (
      <Sticky className={c.sticky} top={top}>
        <Box
          className={cn(className, c.root, {
            [c.yangoFontHeader]: serviceBrand === 'YangoEats'
          })}
          alignItems='center'
          px={isMobile ? 'm' : 'l'}
          style={{top, height: `${getHeight()}px`}}
        >
          {children}
        </Box>
      </Sticky>
    )
  }

  return (
    <Box
      className={cn(className, c.root)}
      alignItems='center'
      px={isMobile ? 'm' : 'l'}
      style={{height: `${getHeight()}px`, ...style}}
    >
      {children}
    </Box>
  )
}

export default HeaderRow
