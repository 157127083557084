import type {IApiError} from '@restapp/shared-api'
import {HttpStatusCode} from '@restapp/shared-api'

import {createMockFactory} from './createMockFactory'

export const mockApiError = createMockFactory<IApiError>({
  name: 'ApiError',
  message: '',
  responseData: {
    code: '500',
    message: 'Fatal error'
  },
  requestConfig: {
    url: '/orders',
    method: 'GET'
  },
  stack: '',
  status: HttpStatusCode.INTERNAL_SERVER_ERROR,
  responseHeaders: {},
  statusText: 'Internal error',
  traceId: '123'
})
