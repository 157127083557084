import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'LanguageSelector'})({
  root: {
    position: 'relative'
  },
  button: {
    '&&': {
      padding: 0,
      width: 48,
      minWidth: 48,
      height: 48,
      borderRadius: 16,
      background: '#292B31',
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '15px',
      textAlign: 'center',
      color: '#999C9E',

      '&:hover, &:active, &.active': {
        background: 'rgba(0, 0, 0, 0.2)',
        color: '#999C9E'
      }
    }
  },
  menu: {
    '& .MuiMenu-paper': {
      marginTop: 8,
      marginBottom: 8,
      padding: 0,
      width: 201,
      background: '#FFFFFF',
      boxShadow: '0px 8px 20px rgba(117, 115, 111, 0.2)',
      borderRadius: 12,

      '& .MuiPaper-root': {
        margin: 20
      }
    },
    '& .MuiList-padding': {
      padding: 4
    },
    '& .MuiMenuItem-root': {
      height: 40,
      fontSize: 14,
      lineHeight: '15px',

      '&.Mui-selected': {
        background: '#EDF0F1'
      },
      '&:hover': {
        background: '#F4F6F7'
      }
    }
  },
  newYearMenu: {
    '& .MuiMenu-paper': {
      background: '#1B1D32'
    },
    '& .MuiMenuItem-root': {
      color: '#ffffff',

      '&.Mui-selected': {
        background: '#000000'
      },
      '&:hover': {
        background: '#000000'
      }
    }
  },
  menuItem: {
    height: 40,
    borderRadius: 12
  }
})
