import {createEntityAdapter} from '@reduxjs/toolkit'

import type {Category, Group, GroupVirtualId, Item, Option, OptionVirtualId} from './types'

export const categoryEntityAdapter = createEntityAdapter<Category, string>({
  selectId: (category) => category.id,
  sortComparer: (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
})

export const groupEntityAdapter = createEntityAdapter<Group, GroupVirtualId>({
  selectId: (group) => group._virtualId,
  sortComparer: (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
})

export const itemEntityAdapter = createEntityAdapter<Item, string>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
})

export const optionEntityAdapter = createEntityAdapter<Option, OptionVirtualId>({
  selectId: (option) => option._virtualId,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

// Эти селекторы не предназначены для использования за пределами стора
// т.к они принимают только кусочек слайса в котором лежит стейт адаптера
// и нужны они для создания других селекторов - не экспортируйте их наружу
// Не используйте их в редусерах для мутаций, т.к они возвращают Object.freeze объект
export const categoryEntitySelectors = categoryEntityAdapter.getSelectors()
export const groupEntitySelectors = groupEntityAdapter.getSelectors()
export const itemEntitySelectors = itemEntityAdapter.getSelectors()
export const optionEntitySelectors = optionEntityAdapter.getSelectors()
