import {usePartnerLocation} from './usePartnerLocation'

type EnabledType = 'all' | 'forCorpNetwork' | 'disabled'

export const useIsEnabledInCurrentNetwork = (value: EnabledType) => {
  const partnerLocation = usePartnerLocation({enabled: value === 'forCorpNetwork'})

  switch (value) {
    case 'all':
      return true
    case 'forCorpNetwork':
      return partnerLocation.inCorpNetwork
    case 'disabled':
      return false
    default:
      return false
  }
}
