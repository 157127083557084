import {t} from '@eda-restapp/i18n'
// Запросы
export const ERROR_REQUEST_OPERATIONS = {
  get changes() {
    return t(
      'core-legacy.errors.oshibka-izmeneniya-ne-sokhranilis',
      'Ошибка: изменения не сохранились. Уже чиним, попробуйте ещё раз'
    )
  },
  get load() {
    return t('core-legacy.errors.vo-vremya-zagruzki-proizoshla-oshibk', 'Во время загрузки произошла ошибка')
  },
  get save() {
    return t('core-legacy.errors.vo-vremya-sokhraneniya-proizoshla-os', 'Во время сохранения произошла ошибка')
  }
}

export const FALLBACK_SNACKBAR_ERROR_MESSAGE = {
  get default() {
    return t(
      'core-api.use-api-mutation.izvinite-chto-to-poshlo-ne-tak-pop',
      'Извините, что-то пошло не так. Попробуйте ещё раз.'
    )
  }
}
