import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

import {useSupportLinkPrep} from '@restapp/shared-support'

import {usePassportName} from './usePassportName'

export const useUsefulLinks = () => {
  const authConfig = useExp3('restapp_auth')

  const {t} = useI18n()
  const passportName = usePassportName()

  const usefulLinks = [
    {
      label: t('core-auth.passport-drawer.how-to-create-yandex-id', 'Как создать аккаунт {domain}', {
        domain: passportName
      }),
      link: useSupportLinkPrep(authConfig.passport.usefulLinksURL.createId)
    },
    {
      label: t(
        'core-auth.passport-drawer.how-to-restore-yandex-id-password',
        'Как восстановить пароль от аккаунта {domain}',
        {domain: passportName}
      ),
      link: useSupportLinkPrep(authConfig.passport.usefulLinksURL.changePassword)
    },
    {
      label: t(
        'core-auth.passport-drawer.how-to-login-to-vendor-with-phone',
        'Как входить в Яндекс Еда Вендор по номеру телефона'
      ),
      link: useSupportLinkPrep(authConfig.passport.usefulLinksURL.loginByPhone)
    },
    {
      label: t(
        'core-auth.passport-drawer.how-to-send-access-to-new-employee',
        'Как выдать доступ к личному кабинету новому сотруднику'
      ),
      link: useSupportLinkPrep(authConfig.passport.usefulLinksURL.inviteEmployee)
    },
    {
      label: t(
        'core-auth.passport-drawer.how-to-delete-binded-user-from-account',
        'Как удалить привязанного пользователя из личного кабинета'
      ),
      link: useSupportLinkPrep(authConfig.passport.usefulLinksURL.deleteEmployee)
    }
  ]

  return usefulLinks
}
