import React from 'react'
import cn from 'classnames'

import {useSectionFormStyles} from './SectionForm.style'

type Props = {
  title: React.ReactNode
  icon: React.ReactNode
  className?: string
  showIconOnMobile?: boolean
  withSectionFields?: boolean
  classes?: Partial<ReturnType<typeof useSectionFormStyles>['classes']>
  children?: React.ReactNode
}

const SectionForm: React.FC<Props> = ({
  title,
  icon,
  className,
  classes,
  showIconOnMobile,
  withSectionFields,
  children
}) => {
  const {classes: c} = useSectionFormStyles({showIconOnMobile}, {props: {classes}})

  return (
    <section
      className={cn(
        {
          [c.root]: true,
          [c.withSectionFields]: withSectionFields
        },
        className
      )}
    >
      <div className={c.header}>
        <div className={c.icon}>{icon}</div>
        <h2 className={c.title}>{title}</h2>
      </div>
      <div className={c.content}>{children}</div>
    </section>
  )
}

export default SectionForm
