import type {Line} from '../types'

const measureLines = (ctx: CanvasRenderingContext2D, lines: Line[], lineHeight: number) => {
  const longestLine = lines.reduce((acc, line) => (line.text.length > acc.length ? line.text : acc), '')
  const metrics = ctx.measureText(longestLine)
  const width = metrics.width
  const height = lines.length * lineHeight

  return {width, height}
}

export default measureLines
