import type {TariffType} from 'packages/shared-api'
import {useLocation} from 'react-router-dom'

import {useSubscriptionPagesLocationState} from '../useSubscriptionPagesLocationState'

export default function useSubscriptionRoute() {
  const location = useLocation()

  const {goTo} = useSubscriptionPagesLocationState()

  const prevPath = location.pathname
  const subscriptionPath = '/tools/subscription'

  return {
    openTariffModal: (placesIds: number[]) =>
      goTo({pathname: subscriptionPath, state: {prevPath, isOpenTariffModal: true, placesIds}}),
    goToSubcription: () => goTo({pathname: subscriptionPath, state: {prevPath}}),
    goToSubcriptionPlaces: (tab?: TariffType) => goTo({pathname: `${subscriptionPath}/places`, state: {tab}}),
    goToSubcriptionStart: () => goTo({pathname: `${subscriptionPath}/start`, state: {prevPath}}),
    goToTGAccounts: () => goTo({pathname: `${subscriptionPath}/bot`, state: {prevPath}}),
    goToEditTGAccounts: () => goTo({pathname: `${subscriptionPath}/bot/edit`, state: {prevPath}})
  }
}
