import type {FC, ComponentProps} from 'react'
import React, {forwardRef} from 'react'
import {MenuItem} from '@mui/material'

type OptionProps = Omit<ComponentProps<typeof MenuItem>, 'ref'>

const Option: FC<OptionProps> = forwardRef<HTMLLIElement, OptionProps>(({children, ...restProps}, ref) => {
  const isMayChildrenBeTitle = typeof children === 'string' || typeof children === 'number'

  return (
    <MenuItem {...restProps} title={isMayChildrenBeTitle ? String(children) : undefined} ref={ref}>
      {children}
    </MenuItem>
  )
})

export default Option
