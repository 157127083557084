import {getServiceBrandLabels, useI18n} from '@eda-restapp/i18n'
import type {SelectProps} from '@mui/material'
import React from 'react'

import type {Service} from '@restapp/shared-api/types/places'

import {Option, Select} from '../Select'

type IProps = {
  value?: Service | 'all'
  className?: string
  slug?: string
  classes?: SelectProps['classes']
  onChange: (value: Service | 'all') => void
}

export const ServicesSelect: React.FC<IProps> = ({value = 'all', slug, className, classes, onChange}) => {
  const {t} = useI18n()
  const {dcServiceBrandLabel, eatsServiceBrandLabel} = getServiceBrandLabels()

  const servicesName: {[key in Service as string]: string} = {
    eda: eatsServiceBrandLabel,
    dc: dcServiceBrandLabel
  }

  return (
    <Select
      data-testid={'service-selector' /*Фильтр по сервису*/}
      className={className}
      classes={classes}
      value={value}
      onChangeValue={onChange}
      slug={slug}
    >
      <Option value={'all'}>{t('shared-ui.services-select.all-services', 'Все сервисы')}</Option>
      {Object.keys(servicesName).map((service) => (
        <Option
          data-testid={`service-${service}` /*Селектор сервиса | Опция в выпадающем списке */}
          key={service}
          value={service}
        >
          {servicesName[service]}
        </Option>
      ))}
    </Select>
  )
}
