import {useExp3} from '@eda-restapp/configs'
import {notificationsChannel, useChannelSubscription} from '@eda-restapp/microfrontend'
import UnreadCounter from '@yandex-int/messenger.unread-counter'
import type {FC} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiQueryInvalidate} from '@restapp/core-api'
import {selectIsLoggedIn, selectOauthToken} from '@restapp/core-auth'

import {chatsSlice} from '../../../slice/chatsSlice'
import {getCounterUrl} from '../../getCounterUrl'
import {messengerEventLogger, messengerErrorLogger} from '../../logger'

const errorLogger = messengerErrorLogger.namespace('unread_counter_watcher')
const eventLogger = messengerEventLogger.namespace('unread_counter_watcher')

export const UnreadCounterWatcher: FC = () => {
  useNotificationsWatcher()
  const dispatch = useDispatch()
  const oauthToken = useSelector(selectOauthToken)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')

  useEffect(() => {
    try {
      const isDisabledByConfig =
        !supportMultiChatConfig.backgroundUnreadCounter && !supportSingleChatConfig.backgroundUnreadCounter

      if (!isLoggedIn || isDisabledByConfig) {
        return
      }

      /** @deprecated Скорее всего отказываемся от этого в пользу каунтера в ручке support_chat/v1/partner/notifications/count EDARESTAPP-10613 */
      const unreadCounter = new UnreadCounter({
        url: getCounterUrl(supportMultiChatConfig.backend),
        authToken: oauthToken ? `OAuth ${oauthToken}` : undefined,
        serviceId: supportMultiChatConfig.serviceId,
        workspaceId: supportMultiChatConfig.workspace,
        withCountChats: true,
        callback: (data) => {
          if (data.value !== undefined) {
            dispatch(chatsSlice.actions.setUnreadMessagesCount(data.value))
          }

          if (data.chatCount !== undefined) {
            dispatch(chatsSlice.actions.setUnreadChatsCount(data.chatCount))
          }

          eventLogger({
            name: 'callback',
            value: data.value,
            additional: {data}
          })
        }
      })

      unreadCounter.start()

      return () => {
        unreadCounter.stop()
      }
    } catch (error) {
      errorLogger({
        error: error instanceof Error ? error : String(error),
        additional: {
          isLoggedIn,
          oauthTokenLength: oauthToken?.length
        }
      })
    }
  }, [
    dispatch,
    isLoggedIn,
    oauthToken,
    supportMultiChatConfig.backend,
    supportMultiChatConfig.backgroundUnreadCounter,
    supportMultiChatConfig.serviceId,
    supportMultiChatConfig.workspace,
    supportSingleChatConfig.backgroundUnreadCounter
  ])

  return null
}

function useNotificationsWatcher() {
  const chatListInvalidateQuery = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v1/chat/list', 'GET'])
  const chatListInvalidateV2Query = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v2/chat/list', 'POST'])
  const notificationCountInvalidateQuery = useApiQueryInvalidate([
    '/4.0/restapp-front/support_chat/v1/partner/notifications/count',
    'GET'
  ])

  useChannelSubscription(notificationsChannel, ({event}) => {
    const eventsFilter: (typeof event)[] = [
      'new_message',
      'no_unread_messages',
      'new_yamb_message',
      'new_yamb_csat_message'
    ]

    if (eventsFilter.includes(event)) {
      notificationCountInvalidateQuery.invalidate()
      chatListInvalidateV2Query.invalidate()
      chatListInvalidateQuery.invalidate()
    }
  })
}
