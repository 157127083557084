import {useEffect} from 'react'
import {useExp3} from '@eda-restapp/configs'

import axios from 'axios'
import moment from 'moment'

import {updateTimeDiff} from '@restapp/shared/utils'
import {useEnv} from '@restapp/core-di/hooks'
import {useDispatch} from 'react-redux'
import {updateAvailable} from '../serviceSlice'
import {eventLogger} from '@eda-restapp/logger'

export const AppUpdateNoSwSupport = () => {
  const restappCommon = useExp3('restapp_common')
  const env = useEnv()
  const dispatch = useDispatch()

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    void fetchManifest()

    const manifestPollInterval = restappCommon.bundle_update_interval
    const pollInterval = manifestPollInterval ? manifestPollInterval * 1000 : 180_000

    let errorEmitTimeout: number

    const interval = setInterval(async () => {
      if (isPageRefreshScheduled) {
        window.clearInterval(interval)

        return
      }

      try {
        const {updateRequired, manifest} = await fetchManifest()

        if (updateRequired) {
          isPageRefreshScheduled = true

          const JSFiles = Object.keys(manifest).filter((key) => key.endsWith('.js'))
          JSFiles.map((file) =>
            preload(manifest[file]).catch(() => {
              eventLogger({name: 'appUpdate:failedPreload', additional: {file}})
            })
          )

          dispatch(updateAvailable())
          eventLogger({name: 'appUpdate:updateButtonAvailable:no_sw_support'})
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error('Error fetch manifest')
      }
    }, pollInterval)

    return () => {
      window.clearInterval(interval)
      window.clearTimeout(errorEmitTimeout)
    }
  }, [dispatch, env, restappCommon.bundle_update_interval])

  return null
}

let prevVendorBundleLink: string
let prevMainBundleLink: string

// Если checkForNewBundles вызовется несколько раз,
// пока висит таймаут на обновление страницы, это приведёт к созданию новых таймаутов.
// Чтобы этого избежать, нужен этот глобальный флаг
let isPageRefreshScheduled = false

const preloadedSet = new Map<string, Promise<void>>([])

const preload = (src: string, rel = 'preload', as = 'script'): Promise<void> => {
  if (!preloadedSet.has(src)) {
    const promiseload = new Promise<void>((resolve, reject) => {
      const link = document.createElement('link')
      link.setAttribute('rel', rel)
      link.setAttribute('href', src)
      link.setAttribute('as', as)
      link.setAttribute('crossorigin', 'anonymous')

      link.onload = () => resolve(undefined)
      link.onerror = () => reject(new Error(`Failed to load ${src}`))
      document.head.appendChild(link)
    })
    preloadedSet.set(src, promiseload)
    return promiseload
  } else {
    return preloadedSet.get(src) || Promise.reject()
  }
}

async function fetchManifest(): Promise<{updateRequired: boolean; manifest: Record<string, string>}> {
  const timestampStart = Date.now()
  const {data, headers} = await axios.get(`/manifest.json?_t=${Date.now()}`)

  const timestampEnd = Date.now()

  const diff = moment(headers.date).diff(moment(), 'ms')

  // Проверяем, что запрос выполнился меньше, чем за секунду
  if (timestampEnd - timestampStart < 1000) {
    updateTimeDiff(diff)
  }

  const vendorBundleLink = data['vendors.js']
  const mainBundleLink = data['main.js']
  const bundleHasChanged =
    (prevVendorBundleLink && prevVendorBundleLink !== vendorBundleLink) ||
    (prevMainBundleLink && prevMainBundleLink !== mainBundleLink)

  if (bundleHasChanged) {
    return {updateRequired: true, manifest: data}
  }

  prevVendorBundleLink = vendorBundleLink
  prevMainBundleLink = mainBundleLink

  return {updateRequired: false, manifest: data}
}
