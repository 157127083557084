import {useCallback} from 'react'

import {useApiQuery} from '@restapp/core-api'
import {usePlacesApi} from '@restapp/core-places'

const usePlaceRatingValidation = () => {
  const {placesIds, isLoading: isLoadingPlaces} = usePlacesApi()

  const {data: placesRating, isLoading: isLoadingRating} = useApiQuery({
    url: '/4.0/restapp-front/eats-place-rating/v1/places-rating',
    method: 'GET',
    enabled: Boolean(placesIds?.length),
    params: placesIds?.length
      ? {
          place_ids: placesIds.join(',')
        }
      : undefined
  })

  const isPlaceRatingOk = useCallback(
    (ratingThreshold: number, placeId: number) => {
      const placeRating = placesRating?.places_rating.find((rating) => rating.place_id === placeId)

      if (placeRating?.rating && ratingThreshold) {
        return placeRating.rating >= ratingThreshold
      }

      return true
    },
    [placesRating]
  )

  return {isPlaceRatingOk, isLoading: isLoadingPlaces || isLoadingRating}
}

export default usePlaceRatingValidation
