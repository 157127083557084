import {t} from '@eda-restapp/i18n'

const EMAIL_CORRECTIVE = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/gi

export const getEmailFilterError = (emailFilterValue: string) => {
  EMAIL_CORRECTIVE.lastIndex = 0

  if (!emailFilterValue) {
    return t('shared.get-email-filter-error.empty-filter-error-text', 'Введите e-mail')
  } else if (!EMAIL_CORRECTIVE.test(emailFilterValue)) {
    return t('shared.get-email-filter-error.incorrect-filter-error-text', 'Неверный формат e-mail')
  } else {
    return ''
  }
}
