import {useI18n} from '@eda-restapp/i18n'
import type {SelectProps} from '@mui/material/Select'
import moment from 'moment'
import React, {type FC, useState} from 'react'

import {
  DatePeriodModal,
  type PeriodTypeModal
} from '@restapp/core-legacy/common/portals/modals/DatePeriodModal/DatePeriodModal'
import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'

import {Option, Select} from '../Select'

import {
  ALL_DATE_PERIOD,
  CUSTOM_DATE_PERIOD,
  DEFAULT_DATE_PERIODS,
  SELECTABLE_PERIODS,
  USER_DATE_PERIOD,
  DAYS_DATE_PERIOD
} from './constants'
import type {DatePeriod, DatePeriodValue} from './DatePeriodSelector.types'

type HandleChangeProps = DatePeriod & 'custom'

type DatePeriodSelectorProps = {
  isAllPeriods?: boolean
  calendarPeriod?: boolean
  className?: string
  classes?: SelectProps['classes']
  fullWidth?: boolean
  // Если необходимо показывать период(напр. "неделя") не от сегодня.
  baseDate?: moment.Moment
  isLocked?: boolean
  max?: moment.Moment
  min?: moment.Moment
  lastDays?: number
  periods?: DatePeriod[]
  value: DatePeriodValue
  slug?: string
  onChange(value: Required<DatePeriodValue>): void
} & WithPortalProps

const DatePeriodSelector: FC<DatePeriodSelectorProps> = ({
  calendarPeriod,
  className,
  classes,
  fullWidth,
  value,
  isAllPeriods = false,
  baseDate = moment(),
  isLocked = false,
  max = moment(),
  min = moment().subtract(2, 'years'),
  lastDays = 5,
  periods = DEFAULT_DATE_PERIODS,
  onChange,
  openPortal,
  slug
}) => {
  const {t} = useI18n()

  /** Need to fix multiple opening DatePeriodModal */
  const [isGettingCustomPeriod, setIsGettingCustomPeriod] = useState(false)

  const getCustomPeriod = async (): Promise<{from: moment.Moment; to: moment.Moment} | null> => {
    // @ts-ignore
    const period = await openPortal<PeriodTypeModal>(<DatePeriodModal min={min.toDate()} max={max.toDate()} />).closed

    setIsGettingCustomPeriod(false)

    if (!period) {
      return null
    }

    return {from: moment(period.from), to: moment(period.to)}
  }

  const handlePeriodChange = async (period: HandleChangeProps) => {
    if (period === CUSTOM_DATE_PERIOD || isGettingCustomPeriod) {
      return
    }

    if (period === USER_DATE_PERIOD) {
      setIsGettingCustomPeriod(true)

      const customPeriod = await getCustomPeriod()

      if (customPeriod) {
        onChange({period: CUSTOM_DATE_PERIOD, ...customPeriod})
      }

      return
    }

    if (period === DAYS_DATE_PERIOD) {
      const periodDate = {
        period,
        from: baseDate?.clone().startOf('day').subtract(lastDays, period),
        to: baseDate?.clone()
      }

      onChange(periodDate)
      return
    }

    if (period === ALL_DATE_PERIOD) {
      onChange({period: ALL_DATE_PERIOD, from: min, to: max})
    }

    onChange({
      period,
      // https://github.com/moment/moment/issues/727#issuecomment-268902861
      from: calendarPeriod
        ? baseDate?.clone().startOf(period === 'week' ? 'isoWeek' : period)
        : baseDate?.clone().subtract(1, period),
      to: baseDate?.clone()
    })
  }

  const renderDependentOptions = () => {
    const isMaxValueEqualBaseDate = baseDate.isSame(max, 'date')

    const filteredPeriods = isAllPeriods
      ? periods
      : periods.filter((period) => {
          const isItemCustomOrAll = [CUSTOM_DATE_PERIOD, ALL_DATE_PERIOD].includes(period)

          if (isItemCustomOrAll) {
            return true
          }

          const hasPeriodDiff =
            moment(max)
              .add(1, 'days')
              .startOf('day')
              .diff(
                min.startOf('day'),
                period as Exclude<DatePeriod, typeof CUSTOM_DATE_PERIOD | typeof ALL_DATE_PERIOD>
              ) >= 1

          return isMaxValueEqualBaseDate && hasPeriodDiff
        })

    return filteredPeriods.map((item) => (
      <Option
        data-testid={`period-${item}` /*Селектор даты | Опция выпадающего списка */}
        key={item}
        value={item === CUSTOM_DATE_PERIOD ? USER_DATE_PERIOD : item}
      >
        {item === DAYS_DATE_PERIOD
          ? t('shared-ui.date-period-selector.last-days-plural', 'Последние {count} день/ей/я', {
              none: 'Последние {count} дней',
              one: 'Последний {count} день',
              some: 'Последние {count} дня',
              many: 'Последние {count} дней',
              count: lastDays
            })
          : SELECTABLE_PERIODS[item]}
      </Option>
    ))
  }

  return (
    <Select
      slug={slug}
      className={className}
      classes={classes}
      fullWidth={fullWidth}
      isLocked={isLocked}
      value={value.period}
      onChangeValue={handlePeriodChange}
      data-testid='date-period-selector'
    >
      {renderDependentOptions()}
      {value.period === CUSTOM_DATE_PERIOD && (
        <Option value={CUSTOM_DATE_PERIOD}>
          {value.from?.format('DD.MM.YYYY')} - {value.to?.format('DD.MM.YYYY')}
        </Option>
      )}
    </Select>
  )
}

export default withPortals(DatePeriodSelector)
