import {useI18n} from '@eda-restapp/i18n'
import {Tooltip, Typography} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import styles from './OperatorInfoTooltip.module.css'

type OperatorInfoTooltipProps = {
  anchorEl: HTMLElement
  showDisableText?: boolean
}

export const OperatorInfoTooltip: FC<OperatorInfoTooltipProps> = ({anchorEl, showDisableText}) => {
  const {t} = useI18n()

  return (
    <Tooltip.Info
      data-testid={'plus-drawer-operator-info-tooltip' /* Шторка Плюса | Подсказка на кнопке для оператора */}
      anchorEl={anchorEl}
      placement='bottom'
      offsetDistance={12}
      className={styles.tooltip}
    >
      <Typography variant='caption1' color='invert'>
        {showDisableText
          ? t('shared-places.operator-info-tooltip.disable', 'Отключить Плюс могут только \nадминистраторы')
          : t('shared-places.operator-info-tooltip.enable', 'Подключить Плюс могут только \nадминистраторы')}
      </Typography>
    </Tooltip.Info>
  )
}
