import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

type MutationBody = VendorApi['/4.0/restapp-front/v1/place-subscriptions/v1/place/update-subscriptions']['POST']['body']

export const useUpdateSubscription = () => {
  const {mutate} = useApiMutation(
    {
      url: '/4.0/restapp-front/v1/place-subscriptions/v1/place/update-subscriptions',
      method: 'POST'
    },
    {affectedQueryKeys: [['/4.0/restapp-front/v1/place-subscriptions/v1/place/get-subscriptions', 'POST' as never]]}
  )

  return {
    updateSubscription: (data: MutationBody, onSuccess?: () => void) => mutate({body: data}, {onSuccess})
  }
}
