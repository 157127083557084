import {useEffect} from 'react'
import {useLocation, useBlocker} from 'react-router-dom'

type Props = {
  openUnsavedAlert: () => Promise<boolean>
  shouldBlock: boolean
  onContinue?: () => void
  unblockLocation?: string
}

const useOpenUnSavedAlertEffect = ({shouldBlock, unblockLocation, onContinue, openUnsavedAlert}: Props) => {
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const blocker = useBlocker((navigation) => {
    // проверяем пути
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const nextLocationPathname = navigation.nextLocation.pathname
    if (shouldBlock) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (unblockLocation && nextLocationPathname.includes(unblockLocation)) {
        return false
      } else if (nextLocationPathname !== location.pathname) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  })

  useEffect(() => {
    if (blocker.state == 'blocked') {
      void openUnsavedAlert().then((isApplied) => {
        if (!isApplied) {
          blocker.proceed()
          if (onContinue) {
            onContinue()
          }
        } else {
          blocker.reset()
        }
      })
    }
  }, [blocker.state])
}

export default useOpenUnSavedAlertEffect
