import {useDeliveryName} from '@eda-restapp/i18n'
import {DeliveryIcon as Icon} from '@eda-restapp/ui'
import React from 'react'

import type {ShortRestaurant} from '@restapp/shared-api'

type DeliveryIconProps = {
  fixZIndex?: boolean
  size?: number | string
  /**
   * @deprecated - use place prop instead
   */
  type?: 'native' | 'marketplace'
  place?: Pick<ShortRestaurant, 'services' | 'type'>
  disabled?: boolean
}

/**
 * @deprecated - use DeliveryIcon from common/ui
 */
export const DeliveryIcon: React.FC<DeliveryIconProps> = ({size, type, place, disabled = false}) => {
  const getDeliveryName = useDeliveryName()
  const deliveryName = getDeliveryName({
    placeServices: place?.services,
    placeType: place?.type ?? (type as 'native' | 'marketplace')
  })

  // FIXME: Support old usage
  // UPD: use DeliveryIcon from common/ui
  if (type && !place) {
    const iconName = `eda-${type}` as const

    return <Icon deliverySlug={iconName} size={size?.toString()} disabled={disabled} />
  }

  if (!place?.services) {
    return null
  }

  return (
    <Icon deliverySlug={deliveryName.slug} tooltip={deliveryName.name} size={size?.toString()} disabled={disabled} />
  )
}
