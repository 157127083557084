export const getPromotionMainPath = () => '/promotion'
export const getPromotionPlacesPath = () => '/promotion/places'
export const getPromotionOauthCallbackPath = () => '/promotion/oauth-callback'
export const getPromotionBannerPath = () => '/promotion/banner'
export const getPromotionCreateCPCPath = () => '/promotion/create-cpc'
export const getPromotionStartPagePath = () => '/promotion/start'
export const getPromotionEnableModalPath = () => `${getPromotionPlacesPath()}/enable`
export const getPromotionOnboardingPath = () => '/promotion/onboarding'

export const getPromotionStatisticsPath = (placeId?: number) =>
  placeId ? `/metrics/promotion/?placeId=${placeId}` : '/metrics/promotion'

export const getPromotionCPAStatisticsPath = ({
  placeId,
  showExportDialog
}: {
  placeId?: number
  showExportDialog?: boolean
}) =>
  placeId
    ? `/metrics/promotion-cpa/?placeId=${placeId}${showExportDialog ? '&showExportDialog=true' : ''}`
    : `/metrics/promotion-cpa${showExportDialog ? '?showExportDialog=true' : ''}`

export const getPromotionDrawerPath = (campaign_id: string) => `${getPromotionPlacesPath()}/?campaignId=${campaign_id}`
export const getPromotionBalancePath = () => `${getPromotionPlacesPath()}/?modal=payment`
