import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, Typography, Button, useIsMobileStrict, Modal} from '@eda-restapp/ui'
import React, {useEffect} from 'react'

import {usePlaces} from '@restapp/core-places'
import {RestaurantListItem} from '@restapp/shared-ui'

import {useSubscriptionConfirmFreeTariffFeatures} from '../../../hooks/useSubscriptionConfirmFreeTariffFeatures'

import styles from './SubscriptionConfirmFreeTariffModal.module.css'

interface SubscriptionConfirmFreeTariffModalProps {
  visible: boolean
  placeId?: number
  isBusiness?: boolean
  onConfirm: () => void
  onClose: () => void
}

const SubscriptionConfirmFreeTariffModal: React.FC<SubscriptionConfirmFreeTariffModalProps> = ({
  visible,
  placeId,
  isBusiness,
  onConfirm,
  onClose
}) => {
  const {t} = useI18n()
  const {getPlaceById} = usePlaces()

  const features = useSubscriptionConfirmFreeTariffFeatures(isBusiness)

  const place = placeId ? getPlaceById(placeId) : undefined

  const isMobile = useIsMobileStrict()

  const handleClose = () => {
    metrika({
      target: 'free_tariff_confirmation_modal_close',
      params: {
        placeId,
        isBusiness,
        path: location.pathname
      }
    })

    onClose()
  }

  const handleConfirm = () => {
    metrika({
      target: 'free_tariff_confirmation_modal_confirm',
      params: {
        placeId,
        isBusiness,
        path: location.pathname
      }
    })

    onConfirm()
  }

  useEffect(() => {
    if (visible) {
      metrika({
        target: 'free_tariff_confirmation_modal_open',
        params: {
          placeId,
          isBusiness,
          path: location.pathname
        }
      })
    }
  }, [visible, placeId, isBusiness])

  return (
    <Modal open={visible} onClose={handleClose}>
      <Modal.Header title={t('shared-places.subscription-confirm-free-tariff.title', 'Точно отключить подписку?')} />
      <Modal.Content>
        <Box data-testid='dialog-message' flexDirection='column' gap='m'>
          <Typography variant='body2' mb={isMobile ? 's' : undefined}>
            {t(
              'shared-places.subscription-confirm-free-tariff.list-title-description',
              'Вы потеряете очень много инструментов для роста заказов'
            )}
          </Typography>

          {!!place && <RestaurantListItem place={place} />}

          <Box className={styles.featuresTable}>
            {features.map((feature) => {
              const Icon = feature.icon
              return (
                <Box alignItems='center' py='s' key={feature.slug} className={styles.item}>
                  <Box className={styles.iconWrap} p='s' mr='s'>
                    <Icon className={styles.icon} />{' '}
                  </Box>
                  <Typography variant='body2'>{feature.title}</Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 's' : 'm'}>
          <Button.Text data-testid='ui-dialog-apply-button' variant='secondary' onClick={handleConfirm} fullWidth>
            {t('shared-places.subscription-confirm-free-tariff.cancel-btn', 'Отключить')}
          </Button.Text>
          <Button.Text data-testid='ui-dialog-cancel-button' fullWidth onClick={handleClose}>
            {t('shared-places.subscription-confirm-free-tariff.apply-btn', 'Не отключать')}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}

export default SubscriptionConfirmFreeTariffModal
