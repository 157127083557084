import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {
  Box,
  Button,
  ChevronRightMIcon,
  Clamp,
  CrossLIcon,
  Drawer,
  Informer,
  OutsideLink,
  Tooltip,
  Typography,
  useIsMobileStrict,
  UserAvatar
} from '@eda-restapp/ui'
import React, {useState} from 'react'

import {usePassportName} from '@restapp/core-auth/passport/hooks'
import {useUsefulLinks} from '@restapp/core-auth/passport/hooks/useUsefulLinks'

import type {ExternalAccountSuggest} from '../../../../types'
import {useGetAvatarUrl} from '../../../hooks/useGetAvatarURL'
import {PassportIcon} from '../../PassportIcon'

import styles from './PassportDrawer.module.css'
import {PassportDrawerCards} from './PassportDrawerCards'

type PassportDrawerProps = {
  open: boolean
  suggestedAccount?: ExternalAccountSuggest
  onClose: () => void
  onBindOtherAccount: () => void
  onBindSuggestedAccount: () => void
  maxLinkedAccountLimitReached?: boolean
}

export const PassportDrawer: React.FC<PassportDrawerProps> = ({
  suggestedAccount,
  open,
  onClose,
  onBindOtherAccount,
  onBindSuggestedAccount,
  maxLinkedAccountLimitReached
}) => {
  const authConfig = useExp3('restapp_auth')

  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const passportName = usePassportName()
  const usefulLinks = useUsefulLinks()
  const getAvatarUrl = useGetAvatarUrl()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleBindOtherAccount = () => {
    if (suggestedAccount) {
      eventLogger({name: 'passport:drawer:click_bind_other_account'})
    } else {
      eventLogger({name: 'passport:drawer:click_bind'})
    }

    onBindOtherAccount()
  }

  const handleBindSuggestedAccount = () => {
    eventLogger({name: 'passport:drawer:click_bind_suggested_account'})
    onBindSuggestedAccount()
  }

  return (
    <Drawer.Panel opened={open} onClose={onClose}>
      <Drawer.Container>
        <Drawer.Content className={styles.container}>
          <Box flexDirection='row' justifyContent='space-between' className={styles.header}>
            {isMobile && <Box flex={1} />}
            <Box flexDirection='row' flexGrow={1} className={styles.headerTextContainer}>
              <Typography
                variant={isMobile ? 'body2' : 'title4'}
                thickness={isMobile ? 'regular' : 'medium'}
                style={{lineHeight: 1.12}}
              >
                <Clamp lines={isMobile ? 1 : 5}>
                  {isMobile
                    ? t('core-auth.passport-drawer.title-mobile', 'Привяжите {domain} до 31 окт.', {
                        domain: passportName
                      })
                    : t(
                        'core-auth.passport-drawer.title-desktop',
                        'Привяжите к личному кабинету свой {domain} до 31 октября',
                        {domain: passportName}
                      )}
                </Clamp>
              </Typography>
            </Box>
            <Box justifyContent='flex-end' flex={1}>
              <CrossLIcon className={styles.cross} onClick={onClose} />
            </Box>
          </Box>

          <Box py='m' className={styles.bindingSteps}>
            <Box flex={1} gap='l' flexDirection='column'>
              <span className={styles.stepNumber}>1</span>

              <Box gap='xs' flexDirection='column'>
                <Typography color='invert' variant='caption1' thickness='medium'>
                  {t('core-auth.passport-drawer.step1.title', 'Выберите аккаунт для привязки')}
                </Typography>

                <Typography color='invert' variant='caption2' thickness='regular'>
                  {t(
                    'core-auth.passport-drawer.step1.caption',
                    'В рабочих целях лучше использовать отдельный аккаунт, но можно привязать и личный'
                  )}
                </Typography>
              </Box>
            </Box>

            <Box flex={1} gap='l' flexDirection='column'>
              <span className={styles.stepNumber}>2</span>

              <Box gap='xs' flexDirection='column'>
                <Typography color='invert' variant='caption1' thickness='medium'>
                  {t('core-auth.passport-drawer.step2.title', 'Привяжите и авторизуйтесь в аккаунте {domain}', {
                    domain: passportName
                  })}
                </Typography>

                <Typography color='invert' variant='caption2' thickness='regular'>
                  {t('core-auth.passport-drawer.step2.caption', 'Введите логин, пароль и предоставьте доступ сервису')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box pt='l' flexDirection='column' className={styles.newOpportunities}>
            <Informer type='error'>
              <Typography variant='body2' lineHeight='normal'>
                {t(
                  'core-auth.passport-drawer.error-informer-about-restrictions',
                  'Возможности личного кабинета с авторизацией по электронной почте будут ограничены – вы сможете только принимать заказы. \n\nПривяжите Яндекс ID, чтобы пользоваться личным кабинетом без ограничений'
                )}
              </Typography>
            </Informer>
            <Box flexDirection='column' gap='l'>
              <Typography variant='body1' thickness='medium'>
                {t('core-auth.passport-drawer.new-opportunities-with-yandex-id', 'Новые возможности с {domain}', {
                  domain: passportName
                })}
              </Typography>

              <PassportDrawerCards />
            </Box>
          </Box>
          {authConfig.showUsefulLinksSection && (
            <Box
              py='l'
              gap='l'
              flexGrow={1}
              className={styles.usefulLinks}
              flexDirection='column'
              data-testid='useful-links-passport-drawer'
            >
              <Typography variant='body1' thickness='medium'>
                {t('core-auth.passport-drawer.useful-links', 'Полезные ссылки')}
              </Typography>

              <Box flexDirection='column' style={{gap: '18.5px'}}>
                {usefulLinks.map(({label, link}, index) => (
                  <React.Fragment key={link}>
                    {index !== 0 && <div className={styles.divider} />}
                    <OutsideLink style={{color: 'inherit'}} href={link} target='_blank'>
                      <Box gap='s' flexDirection='row' justifyContent='space-between'>
                        <Typography variant='body2'>{label}</Typography>

                        <ChevronRightMIcon />
                      </Box>
                    </OutsideLink>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          )}
        </Drawer.Content>
        <Drawer.Footer className={styles.footer}>
          {anchorEl && maxLinkedAccountLimitReached && (
            <Tooltip.Info anchorEl={anchorEl} placement='top'>
              <Typography variant={'caption1'} thickness={'medium'}>
                {t(
                  'core-auth.passport.passport-drawer.too-much-accounts-linked',
                  'Уже привязано максимальное количество аккаунтов'
                )}
              </Typography>
            </Tooltip.Info>
          )}

          <Box flexDirection='column' gap='s' style={{width: '100%'}}>
            <Button.MultiCenter
              ref={setAnchorEl}
              disabled={maxLinkedAccountLimitReached}
              variant={suggestedAccount ? 'secondary' : 'dark'}
              onClick={handleBindOtherAccount}
              fullWidth
              startIcon={
                !suggestedAccount && <PassportIcon style={maxLinkedAccountLimitReached ? {opacity: 0.5} : {}} />
              }
              data-testid='auth-password-btn-drawer'
            >
              {suggestedAccount
                ? t('core-auth.passport-drawer.bind-other-account', 'Привязать другой аккаунт')
                : t('core-auth.passport-drawer.bind-account', 'Привязать {domain}', {domain: passportName})}
            </Button.MultiCenter>

            {suggestedAccount && !maxLinkedAccountLimitReached && (
              <Button.MultiSpace
                variant='dark'
                startIcon={<PassportIcon />}
                endIcon={<UserAvatar src={getAvatarUrl(suggestedAccount.avatar)} />}
                fullWidth
                onClick={handleBindSuggestedAccount}
              >
                {t('core-auth.passport-drawer.login-with-suggested-account', 'Привязать {login}', {
                  login: suggestedAccount.login
                })}
              </Button.MultiSpace>
            )}
          </Box>
        </Drawer.Footer>
      </Drawer.Container>
    </Drawer.Panel>
  )
}
