import type {MutableRefObject} from 'react'
import {useState, useRef, useEffect} from 'react'

export function useHover<T extends HTMLElement>(selfOnly?: boolean): [MutableRefObject<T | null>, boolean] {
  const leaveEventName = selfOnly ? 'mouseout' : 'mouseleave'
  const enterEventName = selfOnly ? 'mouseover' : 'mouseenter'

  const [value, setValue] = useState<boolean>(false)
  const ref = useRef<T | null>(null)

  useEffect(() => {
    const node = ref.current
    const handleMouseOver = (): void => setValue(true)
    const handleMouseOut = (): void => setValue(false)

    if (node) {
      node.addEventListener(enterEventName, handleMouseOver)
      node.addEventListener(leaveEventName, handleMouseOut)

      return () => {
        node.removeEventListener(enterEventName, handleMouseOver)
        node.removeEventListener(leaveEventName, handleMouseOut)
      }
    }
  }, [ref.current])
  return [ref, value]
}
