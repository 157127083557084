import {Box} from '@eda-restapp/ui'
import React from 'react'

import type {IChat} from '../../types'

import {ChatListItem} from './ChatListItem'

type ChatsListProps = {
  chats: IChat[]
  onClickChat(chat: {chat_id?: string}): void
}

export function ChatsList({chats, onClickChat}: ChatsListProps) {
  return (
    <Box style={{gap: 12}} flexDirection='column'>
      {chats.map((chat) => (
        <ChatListItem
          key={`${chat.chat_id} ${chat.ticket_id} ${chat.topic} ${chat.created_at}`}
          chat={chat}
          onClick={() => onClickChat(chat)}
        />
      ))}
    </Box>
  )
}
