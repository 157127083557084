import {Snackbar as SnackbarUI} from '@eda-restapp/ui'
import React, {useEffect, useState} from 'react'

import type {ISnackbarNotifier, Snackbar} from '../../../../lib/snackbar-notifier'

import styles from './SnackbarNotifier.module.css'

interface SnackbarNotifierProps {
  snackbarNotifier: Pick<ISnackbarNotifier, 'onChangeCurrent' | 'remove'>
}

function SnackbarNotifier({snackbarNotifier}: SnackbarNotifierProps) {
  const [current, setCurrent] = useState<Snackbar>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => snackbarNotifier.onChangeCurrent(setCurrent), [])

  if (!current) {
    return null
  }

  const handleClose = () => {
    snackbarNotifier.remove(current.id)
  }

  const handleClick = () => {
    current.onClick?.()
    snackbarNotifier.remove(current.id)
  }

  return (
    <div className={styles.root}>
      <SnackbarUI
        key={current.id}
        className={styles.item}
        type={current.type}
        icon={current.icon}
        message={current.message}
        errorCode={current.error?.traceId}
        onClose={current.type === 'error' || current.showCloseBtn ? handleClose : undefined}
        onClick={current.onClick ? handleClick : undefined}
      />
    </div>
  )
}

export default SnackbarNotifier
