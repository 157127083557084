import React from 'react'

import type {Service} from '@restapp/shared-api/types/places'

import {DeliveryClubSquare, YandexEdaSquare, DoubleServiceSquare} from '@eda-restapp/ui'

type ServiceIconProps = {
  className?: string
  services: Service[]
}
const ServiceIcon = ({services, className}: ServiceIconProps) => {
  if (services.length > 1) {
    return <DoubleServiceSquare fontSize='40px' className={className} />
  }

  return services[0] === 'dc' ? (
    <DeliveryClubSquare fontSize='40px' className={className} />
  ) : (
    <YandexEdaSquare fontSize='40px' className={className} />
  )
}

export default ServiceIcon
