import React from 'react'
import {makeStyles} from 'tss-react/mui'

type OuterProps = {
  className?: string
  lineClamp?: number
  children: React.ReactNode
  style?: React.CSSProperties
  'data-testid'?: string
}

const DEFAULT_LINE_CLAMP = 2

const useLineClampStyles = makeStyles<{lineClamp?: number}>()((theme, {lineClamp}) => {
  return {
    root: {
      WebkitLineClamp: lineClamp || DEFAULT_LINE_CLAMP,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    }
  }
})

/** @deprecated Use \<Clamp\> from common-package */
function LineClamp(props: OuterProps) {
  const {className, lineClamp, children} = props

  const {classes: c, cx} = useLineClampStyles({lineClamp})

  return (
    <span data-testid={props['data-testid']} style={props.style} className={cx(c.root, className)}>
      {children}
    </span>
  )
}

export default LineClamp
