import {makeStyles} from 'tss-react/mui'

import {S} from '../style'

export default makeStyles({
  name: 'BasicCard'
})({
  cardRoot: {
    width: 285,
    minWidth: 285,
    borderRadius: 16,
    boxShadow: '0px 8px 20px rgba(122, 136, 150, 0.2)',

    [S.mobileQuery]: {
      width: 328,
      minWidth: 328
    }
  },

  mediaContainer: {
    position: 'relative'
  },

  contentContainer: {
    [S.mobileQuery]: {
      borderLeft: '2px solid #EDF0F1',
      borderRight: '2px solid #EDF0F1',
      borderBottom: '2px solid #EDF0F1'
    }
  },

  cardMedia: {
    height: 123
  },

  badge: {
    width: 'max-content',
    position: 'absolute',
    top: 8,
    right: 8,
    fontSize: 13,
    backgroundColor: '#fa6a3c',
    color: '#ffffff',
    borderRadius: '100px'
  },

  dcBadge: {
    backgroundColor: '#1CC052'
  },

  cardContent: {
    flexDirection: 'column',
    padding: '0 16px',
    color: '#1F2021'
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '56px'
  },

  title: {
    fontSize: 16,
    lineHeight: '19px',
    margin: 0
  },

  bodyContainer: {
    display: 'flex',
    alignItems: 'start',
    height: 126,

    [S.mobileQuery]: {
      height: 'auto'
    }
  },

  body: {
    fontSize: 14,
    lineHeight: '18px'
  },

  cardActions: {
    padding: '16px 16px 0',
    margin: 0,

    '& *': {
      width: '100%'
    }
  },
  cardFooter: {
    padding: '8px 16px 16px',
    color: '#999C9E',
    textAlign: 'center',
    '&+$empty': {
      paddingTop: 0
    }
  },
  empty: {
    height: '16px',
    padding: 0
  }
})
