import {useExp3} from '@eda-restapp/configs'
import {uniq} from 'lodash-es'
import {useMemo, useState, useCallback} from 'react'

import {type CampaignStatus, CampaignType} from '../../types/campaign'
import type {Service} from '../../types/places'
import useCampaignGroups from '../useCampaignGroups'
import useCampaignsApi from '../useCampaignsApi'
import useCPACampaignsApi from '../useCPACampaignsApi'

import type {UseCampaignFiltersProps} from './types'
import {campaignStatusSorter, campaignTypeSorter} from './utils'

const useCampaignFilters = (): UseCampaignFiltersProps => {
  const promotionConfig = useExp3('restapp_promotion')
  const {campaigns: allCPCCampaigns, isLoading: isCPCLoading, isError: isCPCError} = useCampaignsApi()
  const {
    campaigns: allCPACampaigns,
    isLoading: isCPALoading,
    isError: isCPAError
  } = useCPACampaignsApi({enabled: promotionConfig?.isCpaEnabled})

  const [campaignStatusFilter, setCampaignStatusFilter] = useState<CampaignStatus | 'all'>('all')
  const [campaignTypeFilter, setCampaignTypeFilter] = useState<CampaignType | 'all'>('all')
  const [campaignServiceFilter, setCampaignServiceFilter] = useState<Service | 'all'>('all')

  const {groups: sortedGroups, groupsHash, isLoading: isLoadingGroups} = useCampaignGroups()

  const campaignStatuses: (CampaignStatus | 'all')[] = useMemo(() => {
    return ['all', ...uniq(sortedGroups?.map((campaign) => campaign.groupStatus)).sort(campaignStatusSorter)]
  }, [sortedGroups])

  const campaignTypes: (CampaignType | 'all')[] = useMemo(() => {
    return ['all', ...uniq(sortedGroups?.map((campaign) => campaign.campaignType)).sort(campaignTypeSorter)]
  }, [sortedGroups])

  const filteredCampaigns = useMemo(
    () =>
      sortedGroups?.filter(
        (group) =>
          (group.campaignType !== CampaignType.cpa || group.activated_by_user) &&
          (campaignStatusFilter === 'all' || group.groupStatus === campaignStatusFilter) &&
          (campaignTypeFilter === 'all' || group.campaignType === campaignTypeFilter) &&
          (campaignServiceFilter === 'all' || group.services.includes(campaignServiceFilter))
      ),
    [campaignStatusFilter, campaignTypeFilter, campaignServiceFilter, sortedGroups]
  )

  const cpaCampaignsToActivate = useMemo(
    () => sortedGroups?.filter((campaign) => campaign.campaignType === CampaignType.cpa && !campaign.activated_by_user),
    [sortedGroups]
  )

  const hasPlacesToFilter = useMemo(() => !!sortedGroups?.length, [sortedGroups])

  const resetFilters = useCallback(() => {
    setCampaignStatusFilter('all')
    setCampaignTypeFilter('all')
    setCampaignServiceFilter('all')
  }, [])

  return {
    isError: isCPCError || isCPAError,
    isLoading: isCPCLoading || isCPALoading || isLoadingGroups,
    groupsHash,
    hasPlacesToFilter,
    campaignStatusFilter,
    campaignTypeFilter,
    campaignServiceFilter,
    setCampaignStatusFilter,
    setCampaignTypeFilter,
    setCampaignServiceFilter,
    campaigns: filteredCampaigns,
    cpaCampaignsToActivate,
    campaignStatuses,
    campaignTypes,
    allCampaigns:
      allCPCCampaigns || allCPACampaigns ? [...(allCPCCampaigns || []), ...(allCPACampaigns || [])] : undefined,
    resetFilters,
    isShowServiceFilter: promotionConfig.services.isShowServiceFilter
  }
}

export default useCampaignFilters
