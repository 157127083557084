import {useCallback} from 'react'
import type {InvalidateQueryFilters} from 'react-query'
import {useMutation} from 'react-query'

import type {VendorApi as Api} from '@restapp/shared-api'

import {useApiDeps} from './hooks/useApiDeps'
import type {ApiQueryKey, ApiResponse} from './types'

export type InvalidateFilters<
  TUrl extends keyof Api = keyof Api,
  TMethod extends keyof Api[TUrl] = keyof Api[TUrl]
> = Pick<InvalidateQueryFilters<ApiResponse<Api[TUrl][TMethod]>>, 'refetchPage'>

export default function useApiQueryInvalidate<
  TUrl extends keyof Api = keyof Api,
  TMethod extends keyof Api[TUrl] = keyof Api[TUrl]
>(queryKey: ApiQueryKey<TUrl, TMethod>, filters?: InvalidateFilters<TUrl, TMethod>, onSuccess?: () => void) {
  const {queryClient} = useApiDeps()

  const invalidationMutation = useMutation({
    mutationFn: useCallback(
      (filtersOverride?: Partial<typeof filters>) =>
        queryClient.invalidateQueries(queryKey, {...filters, ...filtersOverride}),
      [queryClient, queryKey, filters]
    ),
    onSuccess
  })

  const invalidate = useCallback(
    (filtersOverride?: Partial<typeof filters>) => invalidationMutation.mutate(filtersOverride),
    [invalidationMutation]
  )

  const invalidateAsync = useCallback(
    (filtersOverride?: Partial<typeof filters>) => invalidationMutation.mutateAsync(filtersOverride),
    [invalidationMutation]
  )

  return {invalidate, invalidateAsync, isInProgress: invalidationMutation.isLoading}
}
