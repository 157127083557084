import {placesChannel} from '@eda-restapp/microfrontend'

import type {MutationOptions, Response, TVariables} from '@restapp/core-api'
import {useApiDeps, useApiMutation} from '@restapp/core-api'
import {FALLBACK_SNACKBAR_ERROR_MESSAGE} from '@restapp/core-legacy/constants/messages/errors'

type MutationVariables = TVariables<'/4.0/restapp-front/places/v2/enable', 'POST'>

type EnablePlaceMutationOptions = MutationOptions<
  Response<'/4.0/restapp-front/places/v2/enable', 'POST'>,
  MutationVariables
>
type UseEnablePlaceOptions = Partial<Pick<EnablePlaceMutationOptions, 'onSuccess' | 'onSettled' | 'onError'>>

export default function useEnablePlace() {
  const {snackbar} = useApiDeps()

  const {isLoading, mutate} = useApiMutation(
    {
      url: '/4.0/restapp-front/places/v2/enable',
      method: 'POST'
    },
    {
      affectedQueryKeys: [
        ['/4.0/restapp-front/places/v1/suggest', 'GET' as never],
        ['/4.0/restapp-front/places/v1/search', 'GET' as never],
        ['/4.0/restapp-front/places/v2/search', 'GET' as never]
      ],
      onSettled: () => {
        void placesChannel.broadcast({type: 'refresh'})
      },
      onError: (errorResponse) => {
        snackbar.enqueue({
          type: 'error',
          message:
            errorResponse?.responseData?.details?.errors[0].message ||
            errorResponse?.responseData?.message ||
            FALLBACK_SNACKBAR_ERROR_MESSAGE.default,
          error: errorResponse
        })
      }
    }
  )

  return {
    isLoading,
    enablePlace: (place_ids: number[], options?: UseEnablePlaceOptions) => mutate({body: {place_ids}}, options)
  }
}
