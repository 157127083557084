import {
  ChevronLeftMIcon,
  ChevronRightMIcon,
  Modal,
  Typography,
  PauseCircleIcon,
  PlayFillIcon,
  Box
} from '@eda-restapp/ui'
import cn from 'classnames'
import type {ReactNodeArray, MouseEvent, ReactElement} from 'react'
import React, {useEffect, useRef, useState} from 'react'

import {isIos, isSupportTouch} from '@restapp/shared/utils'

import PageProgress from './PageProgress'
import styles from './StoryModal.module.css'
import {useStoryProgress} from './useStoryProgress'

const DEFAULT_PAGE_TIME = 5000

export interface StoryProps {
  open: boolean
  onClose: () => void
  autoplay?: boolean
  title: string
  children: ReactNodeArray
  pageTime?: number
  onStoryEnd?(): void
}

export interface UseStoryOptions {
  pagesCount: number
  autoplay?: boolean
  onEnd?: () => void
}

function StoryModal({
  open,
  onClose,
  autoplay = false,
  children,
  onStoryEnd,
  title,
  pageTime = DEFAULT_PAGE_TIME
}: StoryProps): ReactElement {
  const story = useStoryProgress({pagesCount: children.length, onEnd: onStoryEnd, autoplay})
  const [showPlayPauseAnim, setShowPlayPauseAnim] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollTop = 0
    }
  }, [story.currentPage])

  const handleClick = () => {
    const selection = document.getSelection()
    if (selection && selection.toString()) {
      return
    }

    story.isPaused ? story.play() : story.pause()
    setShowPlayPauseAnim(true)
  }

  const handleClickTouch = (e: MouseEvent) => {
    const middleX = window.innerWidth / 2

    if (e.clientX < middleX) {
      story.prevPage()
    } else {
      story.nextPage()
    }
  }

  const handleTouchStart = () => {
    document.body.style.userSelect = 'none'
    // tslint:disable-next-line
    document.body.style.webkitUserSelect = 'none'
    story.pause()
  }
  const handleTouchEnd = () => {
    document.body.style.userSelect = 'auto'
    // tslint:disable-next-line
    document.body.style.webkitUserSelect = 'auto'
    story.play()
  }

  return (
    <Modal open={open} onClose={onClose} className={isIos ? styles.disableSelection : undefined} ref={rootRef}>
      <Modal.Header title={title} />
      <Modal.Content>
        <Typography variant='body2' lineHeight='loose'>
          <div
            className={styles.modalBody}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onClick={isSupportTouch ? handleClickTouch : handleClick}
          >
            {showPlayPauseAnim && (
              <Box className={styles.playPauseContainer}>
                {story.isPaused ? (
                  <PauseCircleIcon fontSize={33} className={styles.playPause} />
                ) : (
                  <Box
                    alignItems='center'
                    justifyContent='center'
                    style={{width: 33, height: 33}}
                    className={styles.playPause}
                  >
                    <Box
                      alignItems='center'
                      justifyContent='center'
                      style={{background: 'black', width: 24, height: 24, borderRadius: '100px'}}
                    >
                      <PlayFillIcon fontSize={12} color='white' />
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            <div className={styles.content}>
              {/* Render all children to preload images */}
              {children.map((child, i) => (
                <div key={i} className={cn({[styles.hidden]: i !== story.currentPage})}>
                  {child}
                </div>
              ))}
            </div>
          </div>
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <div className={styles.controlsWrapper}>
          {!isSupportTouch && children.length > 1 && (
            <div className={styles.control} onClick={story.prevPage}>
              <ChevronLeftMIcon
                data-testid={'ui-modal-footer-left-button' /* UI | Кнопка "влево" в футере модального окна */}
              />
            </div>
          )}

          <div className={styles.progress}>
            {children.map((_, i) => (
              <PageProgress
                key={i}
                complete={i < story.currentPage}
                active={story.currentPage === i}
                pause={story.isPaused}
                pageDuration={pageTime}
                onPageTimeout={story.nextPage}
              />
            ))}
          </div>

          {!isSupportTouch && children.length > 1 && (
            <div className={styles.control} onClick={story.nextPage}>
              <ChevronRightMIcon
                data-testid={'ui-modal-footer-right-button' /* UI | Кнопка "вправо" в футере модального окна */}
              />
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default StoryModal
