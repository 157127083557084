import React, {type ReactElement} from 'react'
import {useSelector} from 'react-redux'

import {CatchOauthRedirect} from '@restapp/core-auth'
import {LogoutMatcher} from '@restapp/core-auth/components/LogoutMatcher/LogoutMatcher'
import {WatchAuthChannel} from '@restapp/core-auth/components/WatchAuthChannel/WatchAuthChannel'
import {selectOauthToken, selectToken} from '@restapp/core-auth/selectors'

type AuthGuardProps = {
  children: (isAuthorized: boolean) => ReactElement
}

export const AuthGuard: React.FC<AuthGuardProps> = ({children}) => {
  const token = useSelector(selectToken)
  const oauthToken = useSelector(selectOauthToken)

  return (
    <>
      <WatchAuthChannel />
      <CatchOauthRedirect enabled>
        <LogoutMatcher>{children(Boolean(token || oauthToken))}</LogoutMatcher>
      </CatchOauthRedirect>
    </>
  )
}
