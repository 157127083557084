import {useExp3} from '@eda-restapp/configs'
import {errorLogger} from '@eda-restapp/logger'
import {authChannel, type AuthChannelMessage, useChannelSubscription} from '@eda-restapp/microfrontend'
import {isAnyOf} from '@reduxjs/toolkit'
import {useEffect} from 'react'
import {useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {selectPartnerId, setOauthToken, userLogout} from '@restapp/core-auth'
import {startAppListening} from '@restapp/store'

export const WatchAuthChannel = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const authConfig = useExp3('restapp_auth')

  useChannelSubscription<AuthChannelMessage>(
    authChannel,
    (msg) => {
      if (msg.type === 'Logout') {
        navigate('/logout')

        queryClient.removeQueries()
      }
      if (msg.type === 'Login') {
        if (authConfig.enableAuthInMultipleTabs) {
          window.location.reload()
        }
      }
      // TODO: https://st.yandex-team.ru/EDARESTAPP-10252 добавить отправку и обработку ChangeAuthCredentials (смена partnerId и oauthToken)
    },
    {ignoreCurrentWindow: true}
  )

  useEffect(() => {
    const listenerUnsubscribe = startAppListening({
      matcher: isAnyOf(userLogout, setOauthToken),
      effect: (action, {getOriginalState}) => {
        /**
         * Если вкладка неактивная, то НЕ броадкастим ивенты
         * !!! Очень важное условие, иначе возникает рекурсия при нескольких вкладках
         * */
        if (document.hidden) {
          return
        }

        const partnerId = selectPartnerId(getOriginalState())

        if (setOauthToken.match(action)) {
          authChannel
            .broadcast({
              type: 'Login'
            })
            .catch((error) => {
              errorLogger({
                level: 'error',
                error: error instanceof Error ? error : new Error('Failed to broadcast login'),
                additional: {
                  partnerId
                }
              })
            })
        }

        if (userLogout.match(action)) {
          authChannel
            .broadcast({
              type: 'Logout'
            })
            .catch((error) => {
              errorLogger({
                level: 'error',
                error: error instanceof Error ? error : new Error('Failed to broadcast logout'),
                additional: {
                  partnerId
                }
              })
            })

          queryClient.removeQueries()
        }
      }
    })

    return () => listenerUnsubscribe()
  }, [authConfig.enableAuthInMultipleTabs, queryClient])

  return null
}
