import {useCallback, useState} from 'react'

import useMoneyInput from '@restapp/promotion/hooks/useMoneyInput'

import {BudgetAllocation, CampaignType} from '../../types/campaign'
import {usePromotionRegionRates} from '../usePromotionRegionRates'
import {useWeekBudget} from '../useWeekBudget'

import type {UsePromotionSetupReturnType} from './types'

type UsePromotionSetupPropsType = {
  placeIds: number[]
  campaignType: CampaignType
  price?: number
  defaultPrice?: number
  budget?: number
  isNewCampaign?: boolean
  withDecimals?: boolean
}

export const usePromotionSetup = ({
  campaignType,
  placeIds,
  price,
  budget,
  isNewCampaign = false,
  defaultPrice,
  withDecimals
}: UsePromotionSetupPropsType): UsePromotionSetupReturnType => {
  const [budgetPeriod, setBudgetPeriod] = useState(
    campaignType === CampaignType.cpm ? BudgetAllocation.weekly : undefined
  )

  const costDefaults = usePromotionRegionRates(placeIds, campaignType, budgetPeriod)
  const {
    minClickPrice,
    maxClickPrice,
    minWeekBudgetPrice,
    defaultCpaBudgetPrice,
    minCpaBudgetPrice,
    maxCpaBudgetPrice
  } = costDefaults

  const validateClickCost = useCallback(
    (min?: number, max?: number) =>
      ({numberValue}: {numberValue: number}) =>
        !!min && !!max && numberValue >= min && numberValue <= max,
    []
  )

  const initialCPCValue = (() => {
    if (!isNewCampaign) {
      return price
    }

    return price || defaultPrice || costDefaults.defaultClickPrice
  })()

  const initialCPAValue = isNewCampaign ? costDefaults.defaultCpaPrice : price

  const costInput = useMoneyInput({
    defaultValue: initialCPCValue,
    validate: validateClickCost(minClickPrice, maxClickPrice),
    withDecimals
  })
  const cpaInput = useMoneyInput({
    defaultValue: initialCPAValue,
    withDecimals,
    validate: validateClickCost(costDefaults.defaultCpaMinPrice, costDefaults.defaultCpaMaxPrice)
  })

  const defaultBudget = campaignType === CampaignType.cpm ? costDefaults.defaultWeekBudgetPrice : undefined

  const weekBudgetProps = useWeekBudget({
    minWeekBudgetPrice,
    /**
     * value для CPC означает isWeekBudgetOn, для CPM нет такого переключателя,
     * поэтому сразу используем значение по умолчанию costDefaults.defaultWeekBudgetPrice
     */
    value: budget ?? defaultBudget,
    defaultValue: costDefaults.defaultWeekBudgetPrice,
    clickValueToCompare: costInput.numberValue,
    withDecimals
  })

  const cpaBudgetProps = useWeekBudget({
    minWeekBudgetPrice: minCpaBudgetPrice,
    maxWeekBudgetPrice: maxCpaBudgetPrice,
    value: budget,
    defaultValue: defaultCpaBudgetPrice,
    clickValueToCompare: cpaInput.numberValue,
    withDecimals
  })

  return {
    cpaInput,
    costInput,
    budgetPeriod,
    setBudgetPeriod: setBudgetPeriod,
    campaignType,
    weekBudgetProps,
    cpaBudgetProps,
    costDefaults
  }
}
