import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {errorLogger} from '@eda-restapp/logger'
import {Box, Spinner} from '@eda-restapp/ui'
import type {FC} from 'react'
import {useState, useEffect, useMemo} from 'react'

import {AssentSubscriptionUzbekistan} from './AssentSubscriptionUzbekistan'
import {iframeId} from './AssentSubscriptionUzbekistan.const'
import {useAssentSubscriptionUzbekistan, useTimeoutLogger} from './AssentSubscriptionUzbekistan.hooks'

const isFormMessage = (ev: MessageEvent) => {
  const frame = document.querySelector(`#${iframeId}`)
  return Boolean(frame && 'contentWindow' in frame && frame.contentWindow === ev.source)
}

export const AssentSubscriptionUzbekistanContainer: FC = () => {
  const {close, isOpen} = useAssentSubscriptionUzbekistan()
  const [isLoading, setIsLoading] = useState(true)

  const {lang} = useI18n()
  const subscribeAssentConfig = useExp3('restapp_subscribe_assent')

  const src = useMemo(() => {
    if (!subscribeAssentConfig.formLink) {
      return 'about:blank'
    }

    return `${subscribeAssentConfig.formLink}?iframe=1&lang=${lang}`
  }, [lang, subscribeAssentConfig.formLink])

  const timeoutLogger = useTimeoutLogger()

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      timeoutLogger.start()
    }
  }, [isOpen, timeoutLogger])

  useEffect(() => {
    const cb = (ev: MessageEvent) => {
      try {
        if (isFormMessage(ev)) {
          if (ev.data === 'ping' && isLoading) {
            // Форма загрузилась
            setIsLoading(false)
            timeoutLogger.stop()
          }
        }
      } catch (error) {
        errorLogger({
          sourceType: `${iframeId}-loading-error`,
          level: 'error',
          error: error instanceof Error ? error : new Error(`${iframeId}-loading-catch-error`)
        })
      }
    }

    window.addEventListener('message', cb)
    return () => window.removeEventListener('message', cb)
  }, [isLoading, timeoutLogger])

  if (!subscribeAssentConfig.enabled) {
    return null
  }

  return (
    <AssentSubscriptionUzbekistan isOpen={isOpen} onClose={close}>
      <Box flexDirection='column' flexGrow={1} alignItems='center' justifyContent='center'>
        {isLoading && <Spinner />}

        <Box
          pt='m'
          pb='xl'
          px='m'
          flexGrow={1}
          flexShrink={0}
          style={{display: isLoading ? 'none' : undefined, width: '100%', height: '100%'}}
        >
          <iframe id={iframeId} name={iframeId} frameBorder={0} src={src} width='100%' height='100%' />
        </Box>
      </Box>
    </AssentSubscriptionUzbekistan>
  )
}
