import {useOldHeavyPlaces} from './useOldHeavyPlaces'

type OptionsType = {
  suspense?: boolean
}

export function usePlaceCurrency(opt?: OptionsType) {
  const {getPlaceById, isLoading} = useOldHeavyPlaces({suspense: opt?.suspense})

  return {
    isLoading,
    getPlaceCurrency: (placeId: number) =>
      getPlaceById(placeId)?.currency ?? {code: 'RUB', sign: '₽', decimal_places: 2}
  }
}
