export enum PickupStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  AVAILABLE = 'available',
  IN_PROCESS = 'in_process',
  UNAVAILABLE = 'unavailable'
}

export type PickupFeature =
  | {
      place_id: number
      status: PickupStatus.UNAVAILABLE | PickupStatus.DISABLED
      dc_commission?: {
        percent: number
      }
      commission?: {
        percent: number
      }
    }
  | {
      place_id: number
      status: PickupStatus.AVAILABLE | PickupStatus.ENABLED | PickupStatus.IN_PROCESS
      dc_commission?: {
        percent: number
      }
      commission: {
        percent: number
      }
    }
