import type {FC} from 'react'
import React from 'react'
import {Tab as MUITab} from '@mui/material'
import {useStyles} from './Tab.style'

type MUITabProps = Parameters<typeof MUITab>[0]
type DefinedTabProps = Pick<MUITabProps, 'value' | 'icon' | 'label' | 'onClick' | 'onChange'>
type TabProps = {
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
} & DefinedTabProps

const Tab: FC<TabProps> = ({classes, className, ...tabProps}) => {
  const {classes: c} = useStyles(undefined, {
    props: {
      classes: classes
    }
  })

  return (
    <MUITab
      {...tabProps}
      className={className}
      classes={{
        root: c.root,
        selected: c.selected
      }}
    />
  )
}

export default Tab
