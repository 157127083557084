import {useI18n} from '@eda-restapp/i18n'
import {RadioGroup} from '@eda-restapp/ui'
import type {FC} from 'react'
import React, {useMemo} from 'react'

import styles from './QuizRadioGroup.module.css'

type QuizRadioGroupProps = {
  onChange: (value: string) => void
  value?: string
}

const QuizRadioGroup: FC<QuizRadioGroupProps> = ({onChange, value}) => {
  const {t} = useI18n()

  const quizData = useMemo(
    () => [
      {
        name: 'quiz-first',
        value: t('shared-places.disable-plus-quiz.id-didnt-work-out', 'Не получилось разобраться')
      },
      {
        name: 'quiz-second',
        value: t('shared-places.disable-plus-quiz.all-is-okey-without-plus', 'Всё хорошо и без Плюса')
      },
      {
        name: 'quiz-third',
        value: t('shared-places.disable-plus-quiz.it-doesnt-suit-me', 'Не подходит комиссия')
      },
      {
        name: 'quiz-fourh',
        value: t('shared-places.disable-plus-quiz.other', 'Другое')
      }
    ],
    [t]
  )

  return (
    <RadioGroup.List name='plus-quiz' onChange={(_evt, value) => onChange(value)} value={value ?? ''}>
      {quizData.map((item) => (
        <>
          <RadioGroup.Item className={styles.radioItem} key={item.value} value={item.value} label={item.value} />
        </>
      ))}
    </RadioGroup.List>
  )
}

export default QuizRadioGroup
