import {useI18n} from '@eda-restapp/i18n'
import {usePermission} from '@eda-restapp/permissions'
import {Box, LegacyButton as Button, Typography} from '@eda-restapp/ui'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {usePlaces} from '@restapp/core-places'
import type {PlacePlus, Service} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'

import {OperatorInfoTooltip} from '../../OperatorInfoTooltip/OperatorInfoTooltip'
import {PlacesListPlus} from '../../PlacesListPlus'

import SinglePlaceCondition from './SinglePlaceCondition'

interface ActivePlusConditionsProps {
  onDisable: (id: number, service: Service[]) => void
  enablePlus: (values: number[], services: Service[]) => void
  places?: PlacePlus[]
  setIsModalOpen: (value: boolean) => void
  setSelectedServices: (value: Service[]) => void
}

const ActivePlusConditions: React.FC<ActivePlusConditionsProps> = ({
  onDisable,
  places,
  enablePlus,
  setIsModalOpen,
  setSelectedServices
}) => {
  const {t} = useI18n()
  const navigate = useNavigate()
  const {setSelectedPlaceId} = usePlaces()
  const [enablePlusTooltipAnchorEl, setEnablePlusTooltipAnchorEl] = useState<HTMLElement | null>(null)

  const canManagePlus = usePermission([
    'permission.tools.common',
    'permission.tools.subscription.common',
    'permission.plus.edit'
  ])

  const isServicesNotActive =
    places &&
    places.some((place) =>
      place.plus?.some(
        (plusInfo) =>
          plusInfo.statuses[0].status === YaPlusStatusEnum.CAN_BE_ACTIVATED ||
          plusInfo.statuses[0].status === YaPlusStatusEnum.CANNOT_BE_ACTIVATED
      )
    )

  const drawerActivePlaces = places
    ?.filter((place) => {
      const isActive = place.plus?.find(
        (item) =>
          item.statuses[0].status === YaPlusStatusEnum.ACTIVE ||
          item.statuses[0].status === YaPlusStatusEnum.ACTIVATING ||
          item.statuses[0].status === YaPlusStatusEnum.WAIT_TO_START ||
          item.statuses[0].status === YaPlusStatusEnum.DEACTIVATING
      )

      return place.status === YaPlusStatusEnum.ACTIVE || isActive
    })
    .reduce<PlacePlus[]>(
      (places, place) => [
        ...places,
        {
          ...place,
          showStats: true,
          showInfo: true
        }
      ],
      []
    )

  return (
    <Box flexDirection='column'>
      {places && places?.length > 1 && drawerActivePlaces && (
        <>
          <PlacesListPlus
            title={t('shared-places.active-plus-conditions.restaurants-plus', 'Рестораны в Плюсе')}
            places={drawerActivePlaces}
            onDisable={onDisable}
            placesCount={places.length}
            initialExpanded={false}
            onShowStatistics={(placeId: number) => {
              setSelectedPlaceId(placeId)
              navigate('/metrics/plus')
            }}
          />
          {isServicesNotActive && (
            <Box mt='m' justifyContent='space-evenly' gap='s' flexWrap='wrap'>
              <Button
                size='m'
                data-testid={'plus-connect-more-button' /* Плюс | Кнопка Подключить еще */}
                variant='secondary'
                fullWidth
                onClick={() => setIsModalOpen(true)}
                disabled={!canManagePlus}
                ref={setEnablePlusTooltipAnchorEl}
              >
                <Typography variant='caption1' thickness='medium'>
                  {t('shared-places.active-plus-conditions.enable-for-button-text', 'Подключить ещё')}
                </Typography>
              </Button>
            </Box>
          )}
        </>
      )}

      {enablePlusTooltipAnchorEl && !canManagePlus && <OperatorInfoTooltip anchorEl={enablePlusTooltipAnchorEl} />}

      {places && places.length < 2 && places[0].plus && (
        <SinglePlaceCondition
          placePlus={places[0].plus}
          placeId={places[0].id}
          onDisable={onDisable}
          onEnable={enablePlus}
          setSelectedServices={setSelectedServices}
        />
      )}
    </Box>
  )
}

export default ActivePlusConditions
