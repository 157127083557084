import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

type AuthSliceState = {
  /** @deprecated Переходим на oauth пасспорта */
  token: string | null
  oauthToken: string | null
  partnerId: string | null
}

const initialState: AuthSliceState = {
  token: null,
  partnerId: null,
  oauthToken: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: () => initialState,
  reducers: {
    /** @deprecated Переходим на oauth пасспорта*/
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload
    },
    setOauthToken(state, action: PayloadAction<string>) {
      state.oauthToken = action.payload
    },
    setPartnerId(state, action: PayloadAction<string>) {
      state.partnerId = action.payload
    },
    invalidPartnerId(state) {
      state.partnerId = null
    },
    invalidOauthToken(state) {
      state.oauthToken = null
    },
    userLogout(state) {
      state.token = null
      state.oauthToken = null
      state.partnerId = null
    },
    unauthorized(state) {
      state.token = null
      state.oauthToken = null
      state.partnerId = null
    }
  }
})

export const {setToken, setPartnerId, setOauthToken, userLogout, unauthorized, invalidPartnerId, invalidOauthToken} =
  authSlice.actions
