import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Box, Modal, Button, Typography, useIsMobileStrict, Switch} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React, {type FC} from 'react'

import type {ShortRestaurant} from '@restapp/shared-api'
import {PlacesList} from '@restapp/shared-places'
import {RestaurantListItem} from '@restapp/shared-ui'

import styles from './SubscriptionConfirmSaveModal.module.css'

type SubscriptionConfirmSaveModalProps = {
  visible: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string[]
  isAllAvailableState: boolean
  allAvailableSubscriptionPlacesAmount: number
  isAllAvailableDefault: boolean
  selectedPlaces: ShortRestaurant[]
  cost: string
  hideMultiselectChoice?: boolean
  isEmptyLocalSelectedPlacesIds: boolean
  onChangeSwitch: () => void
  onChangeSubscriptionPlaces: () => void
  onChooseSubscriptionPlaces: () => void
}

export const SubscriptionConfirmSaveModal: FC<SubscriptionConfirmSaveModalProps> = ({
  visible,
  onClose,
  onConfirm,
  onChangeSwitch,
  onChangeSubscriptionPlaces,
  onChooseSubscriptionPlaces,
  title,
  description,
  isAllAvailableState,
  allAvailableSubscriptionPlacesAmount,
  isAllAvailableDefault,
  selectedPlaces,
  isEmptyLocalSelectedPlacesIds,
  cost,
  hideMultiselectChoice
}) => {
  const {t} = useI18n()
  const toolsConfig = useExp3('restapp_tools')
  const isMobile = useIsMobileStrict()

  const isSinglePlace = selectedPlaces.length === 1
  const selectedSinglePlace = isSinglePlace ? selectedPlaces[0] : undefined
  const isDisplaySinglePlaceItem = toolsConfig.enableSubscriptionMultiselectChoice
    ? hideMultiselectChoice
    : isSinglePlace

  return (
    <Modal open={visible} onClose={onClose}>
      <Modal.Header title={title} />
      <Modal.Content>
        <Box
          data-testid={'dialog-description' /* Диалоговое окно | Описание */}
          className={styles.description}
          flexDirection='column'
        >
          {description.map((text) => (
            <p key={text}>{text}</p>
          ))}
        </Box>

        {!!selectedSinglePlace && isDisplaySinglePlaceItem && (
          <RestaurantListItem className={styles.currentPlace} place={selectedSinglePlace} />
        )}

        <Box mt='m' gap='m' flexDirection={isMobile ? 'column' : 'row'}>
          <Box
            flexGrow={1}
            gap='xs'
            flexDirection='column'
            className={cn(styles.item, isSinglePlace && styles.itemSinglePlace)}
          >
            <Typography variant='caption1' color='minor'>
              {t('shared-places.subscription-confirm-save-modal.label-date', 'Начало периода')}
            </Typography>

            <Typography variant='body2' lineHeight='tight'>
              {moment().add(1, 'days').format('DD.MM.YYYY')}
            </Typography>
          </Box>

          {!isSinglePlace && !toolsConfig.enableSubscriptionMultiselectChoice && (
            <Box
              flexGrow={1}
              gap='xs'
              flexDirection='column'
              className={cn(styles.item, isSinglePlace && styles.itemSinglePlace)}
            >
              <Typography variant='caption1' color='minor'>
                {t('shared-places.subscription-confirm-save-modal.label-places', 'Подключается')}
              </Typography>

              <Typography variant='body2' lineHeight='tight'>
                {t('shared-places.subscription-confirm-save-modal.value-places', '{count} ресторан/а/ов', {
                  none: 'Нет ресторанов',
                  one: '{count} ресторан',
                  some: '{count} ресторана',
                  many: '{count} ресторанов',
                  count: selectedPlaces.length
                })}
              </Typography>
            </Box>
          )}

          <Box
            flexGrow={1}
            gap='xs'
            flexDirection='column'
            className={cn(styles.item, isSinglePlace && styles.itemSinglePlace)}
          >
            <Typography variant='caption1' color='minor'>
              {t('shared-places.subscription-confirm-save-modal.label-cost', 'Стоимость периода')}
            </Typography>

            <Typography variant='body2' lineHeight='tight'>
              {cost}
            </Typography>
          </Box>
        </Box>

        {toolsConfig.enableSubscriptionMultiselectChoice && !hideMultiselectChoice && (
          <Box mt='m' flexDirection='column' gap='m'>
            <Box className={styles.divider} />

            <Box justifyContent='space-between'>
              <Box flexDirection='column'>
                <Typography variant='body2' lineHeight='tight' thickness='medium'>
                  {t(
                    'shared-places.subscription-confirm-save-modal.activate-in-available-restaurants',
                    'Подключить в доступных ресторанах'
                  )}
                </Typography>
                <Typography variant='caption1' lineHeight='normal' mt='xs' color='minor'>
                  {t(
                    'shared-places.subscription-confirm-save-modal.we-advice-to-activate-in-all-restaurants',
                    'Советуем подключать Подписку во всех ресторанах'
                  )}
                </Typography>
              </Box>

              <Switch size='m' active={isAllAvailableState} onChange={onChangeSwitch} />
            </Box>

            {(!isAllAvailableDefault || !isEmptyLocalSelectedPlacesIds || isAllAvailableState) && (
              <PlacesList.Container
                type='dropdown'
                isDefaultExpanded={false}
                classNameList={styles.dropdownList}
                headerTitle={
                  <>
                    {isAllAvailableState ? (
                      <Typography variant='body2' thickness='medium' lineHeight='tight'>
                        {t(
                          'shared-places.subscription-confirm-save-modal.available-to-activate',
                          '{count} ресторан/а/ов',
                          {
                            none: 'Нет ресторанов',
                            one: 'Доступен {count} ресторан',
                            some: 'Доступно {count} ресторана',
                            many: 'Доступно {count} ресторанов',
                            count: selectedPlaces.length
                          }
                        )}
                      </Typography>
                    ) : (
                      <Typography variant='body2' thickness='medium' lineHeight='tight'>
                        {t(
                          'shared-places.subscription-confirm-save-modal.chosen-to-activate',
                          '{count} ресторан/а/ов',
                          {
                            none: 'Нет ресторанов',
                            one: 'Выбран {count} из {amount} ресторанов',
                            some: 'Выбрано {count} из {amount} ресторанов',
                            many: 'Выбрано {count} из {amount} ресторанов',
                            count: selectedPlaces.length,
                            amount: allAvailableSubscriptionPlacesAmount
                          }
                        )}
                      </Typography>
                    )}
                  </>
                }
              >
                {selectedPlaces.map((place) => (
                  <PlacesList.Item place={place} key={place.id} />
                ))}
              </PlacesList.Container>
            )}

            {!isAllAvailableState && (!isAllAvailableDefault || !isEmptyLocalSelectedPlacesIds) && (
              <Button.Text variant='secondary' size='l' fullWidth onClick={onChangeSubscriptionPlaces}>
                {t('shared-places.subscription-confirm-save-modal.change', 'Изменить')}
              </Button.Text>
            )}

            {!isAllAvailableState && isAllAvailableDefault && isEmptyLocalSelectedPlacesIds && (
              <Button.Text variant='primary' size='l' fullWidth onClick={onChooseSubscriptionPlaces}>
                {t('shared-places.subscription-confirm-save-modal.choose-places', 'Выбрать рестораны')}
              </Button.Text>
            )}
          </Box>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Box gap={isMobile ? 's' : 'm'} flexDirection={isMobile ? 'column' : 'row'}>
          <Button.Text variant='secondary' data-testid='ui-dialog-cancel-button' onClick={onClose} fullWidth>
            {t('shared-places.subscription-confirm-save-modal.text-btn-cancel', 'Отмена')}
          </Button.Text>
          <Button.Text
            data-testid='ui-dialog-apply-button'
            variant='primary'
            onClick={onConfirm}
            fullWidth
            disabled={isEmptyLocalSelectedPlacesIds && !isAllAvailableState && isAllAvailableDefault}
          >
            {t('shared-places.subscription-confirm-save-modal.text-btn-apply', 'Подключить')}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}
