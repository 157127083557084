import type {AxiosError} from 'axios'

import type HttpStatusCode from './HttpStatusCode'
import type {ApiErrorResponse} from './types/apiErrorResponse'

const TRACE_ID_HEADER = 'x-yatraceid'

export interface IApiError extends Error {
  readonly status?: HttpStatusCode
  readonly statusText?: string
  readonly traceId?: string
  readonly requestConfig: {
    readonly url?: string
    readonly method?: string
    readonly params?: object
    readonly data?: unknown
    readonly headers?: Record<string, string>
  }
  readonly responseHeaders?: Record<string, string>
  readonly responseData?: ApiErrorResponse
}

export class ApiError extends Error implements IApiError {
  readonly status?: HttpStatusCode
  readonly statusText?: string
  readonly traceId?: string

  readonly requestConfig: Readonly<{
    url?: string
    method?: string
    params: object
    data: unknown
    headers: Record<string, string>
  }>

  readonly responseHeaders?: Record<string, string>
  readonly responseData?: ApiErrorResponse

  constructor(error: AxiosError<ApiErrorResponse>) {
    super(`[${error.response?.status || 'NETWORK_ERROR'}] ${error.config?.url || 'NO_URL'}`)

    this.status = error.response?.status
    this.statusText = error.response?.statusText

    this.responseHeaders = error.response?.headers as Record<string, string> | undefined
    this.traceId = this.responseHeaders?.[TRACE_ID_HEADER]

    this.requestConfig = {
      url: error.config?.url,
      method: error.config?.method,
      params: error.config?.params as object,
      data: error.config?.data,
      headers: error.config?.headers as Record<string, string>
    }

    this.responseData = error.response?.data

    // Error properties
    this.stack = error.stack
    this.name = ApiError.name
  }
}

export function isApiError(error: unknown): error is ApiError {
  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }

  return error instanceof Error && error.name === ApiError.name
}
