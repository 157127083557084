import {useEffect, useState, useCallback} from 'react'

import type {TextConfig} from '../../types/common'
import {useCanvas} from '../useCanvas'

import back from './back.png'
import drawTextConfig from './utils/drawTextConfig'
import getImageConfig from './utils/getImageConfig'

type UseConstructorPropsType = {
  /**
   * лучше мемоизировать при использовании - от этого будет зависеть количество рендеров
   */
  textConfigs: TextConfig[]
  imageUrl?: string
  width: number
  height: number
}

const useConstructor = ({textConfigs, imageUrl, width, height}: UseConstructorPropsType) => {
  const [image, setImage] = useState<HTMLImageElement>()
  const [backImage, setBackImage] = useState<HTMLImageElement>()

  useEffect(() => {
    if (back) {
      const img = new Image()
      img.src = back
      img.setAttribute('crossorigin', 'anonymous')
      img.onload = () => {
        setBackImage(img)
      }
    }
    if (imageUrl) {
      const img = new Image()
      img.src = imageUrl
      img.setAttribute('crossorigin', 'anonymous')
      img.onload = () => {
        setImage(img)
      }
    }
  }, [imageUrl])

  const imageConfig = image && getImageConfig({image, width, height})

  const draw = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (imageConfig && backImage) {
        ctx.save()

        // Автокроп, стратегия такая же как у object-fit: cover
        // Изображение может быть большего разрешения чем width/height -> оно будет скейлиться
        ctx.drawImage(
          image,
          imageConfig.shiftWidth,
          imageConfig.shiftHeight,
          imageConfig.fitWidth,
          imageConfig.fitHeight,
          0,
          0,
          imageConfig.fitWidth,
          imageConfig.fitHeight
        )
        ctx.scale(imageConfig.ratio, imageConfig.ratio)

        ctx.drawImage(backImage, 0, 0, backImage.width, backImage.height)

        const canvasRect = {width, height}

        textConfigs.forEach((textConfig) => drawTextConfig({textConfig, ctx, canvasRect}))
        ctx.restore()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageConfig, backImage, textConfigs] // только при изменении текста при доступности image
  )

  const canvasProps = useCanvas({width: imageConfig?.fitWidth, height: imageConfig?.fitHeight, draw})

  return canvasProps
}

export default useConstructor
