import {getStandPath} from '@eda-restapp/microfrontend'
import type {FC} from 'react'
import React, {useEffect} from 'react'
import {useLocation, useMatch, useNavigate} from 'react-router-dom'
import {errorLogger, eventLogger} from '@eda-restapp/logger'

const REDIRECT_HANDLER_URL = '/handle-redirect'
const INAPP_REDIRECT_PATH_PARAM = 'inapp-redirect-path'

export const RedirectHandler: FC<{children?: React.ReactNode}> = ({children}) => {
  const isHandlerURL = useMatch(REDIRECT_HANDLER_URL)
  const navigate = useNavigate()
  const redirectConditions = useRedirectConditions(!!isHandlerURL)

  useEffect(() => {
    if (redirectConditions === null) {
      if (isHandlerURL) {
        navigate('/')
      }

      return
    }

    if (redirectConditions.needHardRedirect) {
      const redirectURL = `${window.origin}${redirectConditions.redirectPath}`

      eventLogger({
        name: 'redirect_handler:executed_hard_redirect',
        additional: {
          destinationURL: cleanupPathFromQueryParamsAndHash(redirectConditions.redirectPath)
        }
      })

      window.location.replace(redirectURL)
    } else {
      eventLogger({
        name: 'redirect_handler:executed_soft_redirect',
        additional: {
          destinationURL: cleanupPathFromQueryParamsAndHash(redirectConditions.redirectPath)
        }
      })

      navigate(redirectConditions.redirectPath)
    }
  }, [isHandlerURL, navigate, redirectConditions])

  return redirectConditions === null ? <>{children}</> : null
}

const useRedirectConditions = (enabled: boolean) => {
  const location = useLocation()

  if (!enabled) {
    return null
  }

  const searchParams = new URLSearchParams(location.search)
  const inAppRedirectPath = searchParams.get(INAPP_REDIRECT_PATH_PARAM)

  if (inAppRedirectPath === null) {
    errorLogger({
      level: 'error',
      error: new Error('redirect_handler:empty_inapp_redirect_path'),
      message: 'redirect_handler:empty_inapp_redirect_path',
      additional: {eventSlug: 'redirect_handler:empty_inapp_redirect_path'}
    })

    return null
  }

  const decodedInAppRedirectPath = decodeURIComponent(inAppRedirectPath)

  if (!isInnerPath(decodedInAppRedirectPath)) {
    errorLogger({
      level: 'error',
      error: new Error('redirect_handler:invalid_redirect_path'),
      message: 'redirect_handler:invalid_redirect_path',
      additional: {
        eventData: cleanupPathFromQueryParamsAndHash(decodedInAppRedirectPath),
        eventSlug: 'redirect_handler:invalid_inapp_redirect_path'
      }
    })

    return null
  }

  searchParams.delete(INAPP_REDIRECT_PATH_PARAM)

  let redirectPath = decodedInAppRedirectPath
  if (searchParams.size) {
    redirectPath += '?' + searchParams.toString()
  }

  if (location.hash) {
    redirectPath += location.hash
  }

  return {
    needHardRedirect: !!getStandPath(decodedInAppRedirectPath),
    redirectPath
  }
}

const isInnerPath = (path: string) => path.startsWith('/')

const cleanupPathFromQueryParamsAndHash = (path: string) => path.split('?')[0].split('#')[0]
