/* eslint-disable i18n-custom-rules/no-literal-string */
import {useMemo} from 'react'

import {usePlacesApi} from '@restapp/core-places'

export const useMarketBusiness = (enabled: boolean) => {
  const {places} = usePlacesApi({enabled})

  const canShowMarketBusiness = useMemo(
    () => enabled && cityForMarket?.some((city) => places?.some((place) => place.address.includes(city))),
    [enabled, places]
  )

  return {
    canShowMarketBusiness
  }
}

/**
 * Список городов, в которых показывать инструмент Маркет для бизнеса
 */
const cityForMarket = [
  'Москва',
  'Санкт-Петербург',
  'Екатеринбург',
  'Краснодар',
  'Нижний Новгород',
  'Казань',
  'Ростов-на-Дону',
  'Челябинск',
  'Воронеж',
  'Владимир',
  'Самара',
  'Пермь',
  'Уфа',
  'Новосибирск',
  'Ижевск',
  'Долгопрудный',
  'Липецк',
  'Киров',
  'Тюмень',
  'Химки',
  'Тула',
  'Чебоксары',
  'Путилково',
  'Набережные Челны',
  'Ярославль',
  'Саратов',
  'Волгоград',
  'Рязань',
  'Красногорск',
  'Смоленск',
  'Тверь',
  'Белгород',
  'Тольятти',
  'Брянск',
  'Калуга',
  'Курск',
  'Сочи',
  'Мытищи',
  'Балашиха',
  'Ульяновск',
  'Пенза',
  'Йошкар-Ола',
  'Иваново',
  'Красноярск',
  'Подольск',
  'Таганрог',
  'Вологда',
  'Видное',
  'Октябрьский',
  'Городской округ Октябрьский',
  'Копейский городской округ',
  'Мошковский район',
  'Рыбинский район',
  'Оренбург',
  'Подосинки',
  'Тамбов',
  'Ставрополь',
  'Пятигорск',
  'Домодедово',
  'Люберцы',
  'Кострома',
  'Королёв',
  'Одинцово',
  'Великий Новгород',
  'Омск',
  'Коломна',
  'Волжский',
  'Красноярский район',
  'Дзержинск',
  'Барнаул',
  'Череповец',
  'Рыбинск',
  'Обнинск',
  'Щелково',
  'Старый Оскол',
  'Новороссийск',
  'Раменское',
  'Сергиев Посад',
  'Курган',
  'Реутов',
  'Серпухов',
  'Саранск',
  'Гатчина',
  'Томск',
  'Орёл',
  'Истра',
  'Нижний Тагил',
  'Кемерово',
  'Пушкино',
  'Омский район',
  'Пушкинский район',
  'Ленинградская',
  'Новочеркасск',
  'Энгельс',
  'Волгодонск',
  'Армавир',
  'Анапа',
  'Ивантеевка',
  'Зеленоград',
  'Шахты',
  'Шушары',
  'Ковров',
  'Ногинск',
  'Миасс',
  'Наро-Фоминск',
  'Коммунарка',
  'Жуковский',
  'Геленджик',
  'Майкоп',
  'Альметьевск',
  'Дзержинский',
  'Кисловодск',
  'Боровск',
  'Апрелевка',
  'Березники',
  'Яблоновский',
  'Лобня',
  'Каменск-Уральский',
  'Кстово',
  'Ступино',
  'Полазна',
  'Батайск',
  'Всеволожск',
  'Златоуст',
  'Выборг',
  'Бор',
  'Котельники',
  'Дмитров',
  'Дубна',
  'Городской округ Чехов',
  'Железнодорожный',
  'Нефтекамск',
  'Электрогорск',
  'Нахабино',
  'Волжск',
  'Жигулёвск',
  'Луховицы',
  'Сосновый Бор',
  'Фрязино',
  'Солнечногорск',
  'Троицк',
  'Троицкий городской округ',
  'Железногорск',
  'Невинномысск',
  'Елец',
  'Красное Село',
  'Саров',
  'Колпино',
  'Кудрово',
  'Новокуйбышевск',
  'Электросталь',
  'Стерлитамак',
  'Кириши',
  'Елабуга',
  'Азнакаево',
  'Дедовск',
  'Верхняя Пышма',
  'Клин',
  'Можайск',
  'Балаково',
  'Алексеевка',
  'Сириус',
  'Первоуральск',
  'Павловская',
  'Чехов',
  'Верещагино',
  'Туапсе',
  'Светогорск',
  'Копейск',
  'Темиргоевская',
  'Заволжье',
  'Павлово',
  'Петергоф',
  'Пушкин',
  'Лиски',
  'Азов',
  'Среднеуральск',
  'Тихвин',
  'Донской',
  'Сарапул',
  'Кабардинка',
  'Городец',
  'Орехово-Зуево',
  'Коледино',
  'Семибратово',
  'Димитровград',
  'Павловская Слобода',
  'Новоивановское',
  'Андреевка',
  'Сиверский',
  'Посёлок имени Свердлова',
  'Муром',
  'Павловский Посад',
  'Талдом',
  'Ломоносов',
  'Адлер',
  'Новокузнецк',
  'Старая Купавна',
  'Приозерск',
  'Мурино',
  'ИЦ Сколково',
  'Парголово',
  'Славянск-на-Кубани',
  'Новозавидовский',
  'Ромашково',
  'Удомля',
  'Кореновск',
  'Протвино',
  'Нижнекамск',
  'Сясьстрой',
  'Воскресенск',
  'Узловая',
  'Черкесск',
  'Никольское',
  'Красногвардейский район',
  'Динская',
  'Микулино',
  'Орск',
  'Шатура',
  'Иноземцево',
  'Малаховка',
  'Бугульма',
  'Асбест',
  'Звенигород',
  'Волоколамск',
  'Горный Щит',
  'Петровское',
  'Красково',
  'Павловск',
  'Павловский район',
  'Зерноград',
  'Ишимбай',
  'Кронштадт',
  'Янино-1',
  'Краснознаменск',
  'Чайковский',
  'Красноармейск',
  'Пикалево',
  'Бузулук',
  'Черепичный',
  'Усть-Лабинск',
  'Заречный',
  'Городской округ ЗАТО Заречный',
  'Арзамас',
  'Кропоткин',
  'Михайловка',
  'Кубинка',
  'Камышин',
  'Кунгур',
  'Новоуральск',
  'Новая Москва',
  'Ряжск',
  'Радужный',
  'Коломенский городской округ',
  'Аннолово',
  'Лысьва',
  'Рогачёво',
  'Безенчук',
  'Советский район',
  'Городской округ Новосибирск',
  'Сызрань',
  'Лосино-Петровский',
  'Витязево',
  'Новомосковск',
  'Гуково',
  'Богородск',
  'Воткинск',
  'Дрожжино',
  'Крупино',
  'Каменск-Шахтинский',
  'Мисайлово',
  'Берёзовский',
  'Арамиль',
  'Кингисепп',
  'Румянцево',
  'Пущино',
  'Вязовка',
  'Лыткарино',
  'Бердск',
  'Загорянский',
  'Михайловск',
  'Шпаковский район',
  'Аксай',
  'Волхов',
  'Ерёмино',
  'Дивеево',
  'Озёры',
  'Соликамск',
  'Зубово',
  'Новомосковский административный округ',
  'Выкса',
  'Егорьевск',
  'Марьино',
  'Крымск',
  'Александров',
  'Красный Восток',
  'Кашира',
  'Мирный',
  'Малоярославец',
  'Посёлок им. Воровского',
  'Спартак',
  'Сестрорецк',
  'Верхние Серги',
  'Ейск',
  'Староминская',
  'Вёшки',
  'Голицыно',
  'Новозыбков',
  'Углич',
  'Набережные Моркваши',
  'Сысерть',
  'Кирово-Чепецк',
  'Успенское',
  'Скопинский район',
  'Купанское',
  'Барсуки',
  'Ржев',
  'Новочебоксарск',
  'Касимово',
  'Натальино',
  'Гжель',
  'Томилино',
  'Бийск',
  'Подолино',
  'Российский',
  'Городской округ Краснодар',
  'Нагорное',
  'Молодежный',
  'Усть-Кинельский',
  'Ташла',
  'Алёшино',
  'Бронницы',
  'Архангельское',
  'Слободской',
  'Старая',
  'ЛМС',
  'Мякинино',
  'деревня Барвиха',
  'Новотитаровская',
  'Заневка',
  'Соболиха',
  'Зеленодольск',
  'Гусь-Хрустальный',
  'Быково',
  'Ессентуки',
  'Озерецкое',
  'Коммунар',
  'Кукмор',
  'Заветный',
  'Виллози',
  'Новое Атлашево',
  'Верховье',
  'Жуковский район',
  'Тихорецк',
  'Волосово',
  'Искитим',
  'Кировск',
  'Соколово',
  'Покров',
  'ВНИИССОК',
  'Новоселье',
  'Новая Адыгея',
  'Губкин',
  'Прикубанский округ',
  'Мичуринск',
  'Самосырово',
  'Поселение Внуковское',
  'Щербинка',
  'Луга',
  'Рошаль',
  'Сухое',
  'Вязники',
  'Краснообск',
  'Руза',
  'Щекино',
  'Северская',
  'Первомайский район',
  'Старосырово',
  'Шамонино',
  'Должанская',
  'Артёмовский',
  'Лермонтов',
  'Металлострой',
  'Белореченск',
  'Медведево',
  'Черноголовка',
  'Туймазы',
  'Чистополь',
  'Оса',
  'Плодородный',
  'Гай',
  'Одоев',
  'Новомихайловский',
  'Кущёвская',
  'Дубровка',
  'Поляны',
  'Южный',
  'Константиновка',
  'Рощино',
  'Сосновский район',
  'Каневская',
  'Назия',
  'Западный округ',
  'Орлово',
  'Ликино-Дулево',
  'Десна',
  'Мистолово',
  'Бугровское сельское поселение',
  'Шлиссельбург',
  'Исаково',
  'Велигонты',
  'Сальск',
  'Эсто-Садок',
  'Богатые Сабы',
  'Берёзовый',
  'Сарженка',
  'Нововоронеж',
  'Выселки',
  'Лабинск',
  'Засечное',
  'Железнодорожный район',
  'Супсех',
  'Медовка',
  'Фёдоровское',
  'Глазов',
  'Крючково',
  'Битца',
  'Кулебаки',
  'Вязьма',
  'Львовский',
  'Кимовск',
  'Горбатовка',
  'Чусовой',
  'Отрадный',
  'Семилуки',
  'Климово',
  'Сергач',
  'Беседы',
  'Тимашевск',
  'Минеральные Воды',
  'Селятино',
  'Шульгино',
  'Иннополис',
  'Ржавки',
  'Орёл-Изумруд',
  'Шарапово',
  'Грибки',
  'Васильево',
  'Приозерский район',
  'Кузнецк',
  'Троицкий административный округ',
  'Гулькевичи',
  'Сухой Лог',
  'Западный',
  'Мильково',
  'Шатки',
  'Невьянск',
  'Свердловский',
  'Марушкино',
  'Салават',
  'Акулово',
  'Куялово',
  'Юбилейный',
  'Шаховская',
  'Симагино',
  'Курумоч',
  'Колтуши',
  'Балахна',
  'Новомелково',
  'Лесной',
  'Пушкинский район',
  'Бирюч',
  'Приладожский',
  'Донецк',
  'Лапино',
  'Тучково',
  'Льгов',
  'Гаврилов-Ям',
  'Ростов',
  'Битимка',
  'Большие Вязёмы',
  'Новохоперск',
  'Запрудня',
  'Энем',
  'Егорлыкская',
  'Кольчугино',
  'Чебаркуль',
  'Верхняя Хава',
  'Тарасовка',
  'Кузьмолово',
  'Дубровки',
  'Ярцево',
  'Аро',
  'Тобольск',
  'Сатис',
  'Павлищево',
  'Октябрьский район',
  'Жуков',
  'Красная Пахра',
  'Сырский',
  'Весёлый',
  'Джубга',
  'Бутово',
  'Удельная',
  'Исток',
  'Хоста',
  'Елизаветинка',
  'Белая Калитва',
  'Старая Пустошь',
  'Ждановский',
  'Цибино',
  'Аннинское городское поселение',
  'Александровка',
  'Рамонь',
  'Лаишево',
  'Трошково',
  'Чёрная Грязь',
  'Лопатино',
  'Казанская',
  'Платнировская',
  'Отрадное',
  'Кировский район',
  'Новоусманский район',
  'Запорожское',
  'Лупаново',
  'Лениногорск',
  'Растуново',
  'Архипо-Осиповка',
  'Котовск',
  'Лаговское',
  'Янино-2',
  'Саратовская',
  'Цемдолина',
  'Московский',
  'Старомышастовская',
  'Поселок Управленческий',
  'Свитино',
  'Ишеевка',
  'Фролово',
  'Клёново',
  'Нытва',
  'Новониколаевский',
  'Круглое',
  'Дубранивка',
  'Ильинское',
  'Благовещенск',
  'Молоково',
  'Каменный Брод',
  'Людиново',
  'Николаевка',
  'Никитовка',
  'Аничково',
  'Посёлок Тельмана',
  'Конаково',
  'Рабитицы',
  'Красное Поле',
  'Новое Девяткино',
  'Воронежская',
  'Ангелово',
  'Задонск',
  'Дятьково',
  'Городищи',
  'Голубое',
  'Таболово',
  'Курьи',
  'Возрождение',
  'Высокая Гора',
  'Посёлок Володарского',
  'Торосово',
  'Лазаревское',
  'Вышний Волочёк',
  'Красная Горка',
  'Нылга',
  'Пучково',
  'Лесные Поляны',
  'Алексин',
  'Камешково',
  'Павловское',
  'Бухарово',
  'Обухово',
  'Понежукай',
  'Петушки',
  'Пушкинский район',
  'Шебекино',
  'Богородицк',
  'Усмань',
  'Акишово',
  'Апшеронск',
  'Юровка',
  'Чёрное',
  'Стахановский',
  'Кузнецово',
  'Ямное',
  'Разумное',
  'Шевченко',
  'Хвалынск',
  'Горячий Ключ',
  'Вольгинский',
  'Переславль-Залесский',
  'Качаброво',
  'Шахово',
  'Медвежьи Озёра',
  'Покровское',
  'Былово',
  'Джигинка',
  'Константиново',
  'Томинский',
  'Красногвардеец',
  'Торжок',
  'Балтым',
  'Островцы',
  'Рузаевка',
  'Ромашки',
  'Строитель',
  'Яковлевский район',
  'Нововолково',
  'Шуя',
  'Мысхако',
  'Новинки',
  'Немчиновка',
  'Старица',
  'Ржавка',
  'Ливенка',
  'Репное',
  'Никулино',
  'Невская Дубровка',
  'Манихино',
  'Котлы',
  'Кузнечиха',
  'Дорохово',
  'Дороничи',
  'Посёлок Ульяновского лесопарка',
  'Рославль',
  'Ленинск-Кузнецкий',
  'Сосново',
  'Ямищево',
  'Монино',
  'Юрово',
  'Верхнее Мячково',
  'Анисовский',
  'Кимры',
  'Остафьево',
  'Синьково',
  'Моносеево',
  'Воробьи',
  'Моршанск',
  'Прочноокопская',
  'Песочный',
  'Суворово',
  'Старый Городок',
  'Речицы',
  'Прикамский',
  'Перевалово',
  'Нефедьево',
  'Приморско-Ахтарск',
  'Новая Усмань',
  'Мамыри',
  'Канаш',
  'Агалатово',
  'Токсово',
  'Кировград',
  'Ревда',
  'Никульское',
  'Некрасовский',
  'Большой Исток',
  'Прохорово',
  'Раздоры',
  'Первомайское',
  'Терновое',
  'Зеленогорск',
  'Ленинаван',
  'Лисий Нос',
  'Хотьково',
  'Кинель-Черкассы',
  'Новая Купавна',
  'Индустриальный',
  'Вышегород',
  'Клинцы',
  'Стрельна',
  'Электроугли',
  'Посёлок имени Морозова',
  'Фролы',
  'Деденево',
  'Сельцо',
  'Костерёво',
  'Аносино',
  'Дагомыс',
  'Южноуральск',
  'Реконструктор',
  'Кабицыно',
  'Столбище',
  'Якунино',
  'Софрино',
  'Стройкерамика',
  'Вахруши',
  'Тимофеевка',
  'Верея',
  'Тереньга',
  'Сафоново',
  'Красный Бор',
  'Медвёдовская',
  'Посёлок фабрики имени 1-го Мая',
  'Заречье',
  'Новый Быт',
  'Осиново',
  'Балашов',
  'Остров',
  'Развилковское сельское поселение',
  'Голиково',
  'Светлый Яр',
  'Кавалерский',
  'Матвеев Курган',
  'Зарайск',
  'Самарское',
  'Воскресенское',
  'Берёзки',
  'Игнатовка',
  'Глухово',
  'Яльгелево',
  'Рассказово',
  'Горячеводский',
  'Посёлок совхоза имени Ленина',
  'Понетаевка',
  'Линёво',
  'Благовар',
  'Чапаевск',
  'Боровичи',
  'Новая Ольховка',
  'Ильинский',
  'Посёлок совхоза Сафоновский',
  'Поселение Сосенское',
  'Романовка',
  'Щапово',
  'Займо-Обрыв',
  'Часцы',
  'Яхрома',
  'Садовый',
  'Беляная Гора',
  'Янтарный',
  'Истье',
  'Васильевское',
  'Новосадовый',
  'Порошкино',
  'Большаково',
  'Оредеж',
  'Городское поселение Солнечногорск',
  'Курчатов',
  'Мензелинск',
  'Нарышкино',
  'Боровский',
  'Высоково',
  'Верхнетроицкое',
  'Заинск',
  'Внуково',
  'Посёлок завода Мосрентген',
  'Новобессергеневка',
  'Софьино',
  'Шелаболиха',
  'Лысково',
  'Солослово',
  'Каменушка',
  'Рахманово',
  'Усть-Ижора',
  'Верхний Мамон',
  'Ропша',
  'Кинешма',
  'Приморск',
  'Зверево',
  'Оринкино',
  'Щемилово',
  'Бокситогорск',
  'Сяськелево',
  'Опалиха',
  'Куровское',
  'Жуково',
  'Еманжелинск',
  'Нижние Серги',
  'Кисловка',
  'Тимохово',
  'Дрезна',
  'Рефтинский',
  'Воскресенское городское поселение',
  'Новошахтинск',
  'Новосемейкино',
  'Шепси',
  'Грязи',
  'Целина',
  'Кольцово',
  'Железноводск',
  'Карабаново',
  'Старотитаровская',
  'Старая Ладога',
  'Дединово',
  'Фурманов',
  'Шувое',
  'Ленинский',
  'Столбово',
  'Вятское',
  'Раздорская',
  'Сивково',
  'Софрино-1',
  'Сергеевка',
  'Калач-на-Дону',
  'Витаминкомбинат',
  'Кузьмоловский',
  'Тбилисская',
  'Ухтохма',
  'Сукко',
  'Пикколово',
  'Новоульяновск',
  'Форносовское городское поселение',
  'Орловка',
  'Приволжский',
  'Алабино',
  'Краснокамск',
  'Новобратцевский',
  'Краснознаменский',
  'Развилка',
  'Орджоникидзевский район',
  'Чкаловск',
  'Болтино',
  'Кострово',
  'Жуковка',
  'Одинцовский район',
  'Ковернино',
  'Красный Ключ',
  'Верхние Осельки',
  'Козет',
  'Булгаково',
  'Молоди',
  'Сертолово',
  'Боброво',
  'Жилетово',
  'Толстопальцево',
  'Лесной городок',
  'Старицы',
  'Анастасиевская',
  'Свень',
  'Войскорово',
  'Антоново',
  'Полевской',
  'Радумля',
  'Ильский',
  'Подстепки',
  'Федино',
  'Пересвет',
  'Новоалексеевское',
  'Молоковское сельское поселение',
  'Поселок ВНИИСС',
  'Тосно',
  'Красная Поляна',
  'Богданович',
  'Новая',
  'Киржач',
  'Вартемяги',
  'Лешково',
  'Поселение Рязановское',
  'Асино',
  'Десногорск',
  'Евлашево',
  'Чернянка',
  'Новостройка',
  'Калининец',
  'Беломестное',
  'Краевско-Армянское',
  'Виноградово',
  'Михнево',
  'Борисоглебск',
  'деревня Печерск',
  'Щепкинское сельское поселение',
  'Марьянская',
  'Мелеуз',
  'Темрюк',
  'Левашово',
  'Таремское',
  'Промышленный район',
  'Грицовский',
  'Придорожный',
  'Тюменяк',
  'Ленинский проспект',
  'Посёлок имени Цюрупы',
  'Энколово',
  'Скопин',
  'Ащерино',
  'Прокопьевск',
  'Туношна',
  'Волоколамское городское поселение',
  'Луховка',
  'Текстильщики',
  'Курганинск',
  'Поварово',
  'Птичное',
  'Пригородный',
  'Сергиевск',
  'Знаменский',
  'Красный Сад',
  'Каменногорск',
  'Петро-Славянка',
  'Хапо-Ое',
  'Медынь',
  'Навашино',
  'Ляхово',
  'Сапроново',
  'Виноградный',
  'Кулешовка',
  'Леонтьево',
  'Усть-Качка',
  'Окуловка',
  'Незнамово',
  'Молзино',
  'Белая'
]
