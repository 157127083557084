import {useExp3, getConfigsStore} from '@eda-restapp/configs'
import {getBrand, useI18n} from '@eda-restapp/i18n'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import {selectAuthHeaders, useAuth, useUserInfo} from '@restapp/core-auth'
import {getApplication} from '@restapp/shared/utils'
import {incrementalDelay} from '@restapp/shared/utils/IncrementalDelay/incrementalDelay'

const configsStore = getConfigsStore()

/**
 * Управляет поллингом конфигов\
 * Подразумевается что он используется только один раз на все приложение, включая фреймы\
 */
export default function WatchConfigs() {
  const {isLoggedIn} = useAuth()
  const userInfo = useUserInfo()
  const {lang} = useI18n()
  const restappCommon = useExp3('restapp_common')
  const authHeaders = useSelector(selectAuthHeaders)

  const args = {
    application: getApplication(),
    domainHost: getBrand(),
    isLoggedIn: isLoggedIn ? 1 : 0,
    /**
     * @deprecated вместо него partnerId
     */
    email: userInfo?.email,
    countryCode: userInfo?.country_code,
    language: lang.toLocaleUpperCase(),
    partnerId: userInfo?.partner_uuid
  }

  useQuery(
    'configs',
    async ({signal}) => {
      const response = await configsStore.fetcher.fetch({
        args: args,
        signal: signal,
        requestHeaders: authHeaders
      })

      if (response instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(response)
        throw response
      }

      return response
    },
    {
      retry: true,
      retryDelay: incrementalDelay,
      queryHash: `configs ${Object.entries(args)
        .map(([name, value]) => `${name}=${value}`)
        .sort()
        .join(' ')}`,
      refetchInterval: restappCommon.configs_poll_interval * 1_000,
      refetchIntervalInBackground: true,
      staleTime: 10_000,
      refetchOnReconnect: 'always',
      notifyOnChangeProps: []
    }
  )

  return null
}
