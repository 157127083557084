import {eventLogger} from '@eda-restapp/logger'
import MUCheckbox, {type CheckboxProps as MUICheckboxProps} from '@mui/material/Checkbox'
import type {InputBaseComponentProps} from '@mui/material/InputBase'
import React, {type ChangeEvent, type FC} from 'react'

import Tooltip from '../Tooltip'

import checkedIconSrc from './icons/checked.svg'
import iconSrc from './icons/idle.svg'
import indeterminateIconSrc from './icons/indeterminate.svg'
import iconSrcDisabled from './icons/idle-disabled.svg'
import checkedIconSrcDisabled from './icons/checked-disabled.svg'
import indeterminateIconSrcDisabled from './icons/indeterminate-disabled.svg'

type CheckboxProps = {
  className?: string
  tooltipText?: string
  inputProps?: InputBaseComponentProps
  slug?: string
  dimension?: number
} & MUICheckboxProps

const ICON_DIMENSION = 20

export const Checkbox: FC<CheckboxProps> = ({
  tooltipText,
  onChange,
  slug,
  dimension = ICON_DIMENSION,
  ...restProps
}) => {
  const {disabled, icon} = restProps

  const onChangeWithTracker = (evt: ChangeEvent<HTMLInputElement>, value: boolean) => {
    eventLogger({
      name: 'checkbox_change',
      value: slug,
      additional: {
        value
      }
    })
    onChange?.(evt, value)
  }

  const renderCheckboxComponent = () => (
    <MUCheckbox
      data-testid={'list-item-checkbox' /*Чекбокс | Checkbox для вкл/выкл контента */}
      onChange={onChangeWithTracker}
      role={'checkbox'}
      icon={
        icon || (
          <div
            data-testid={'disabled-checkbox' /*Неактивный чекбокс | Общий компонент*/}
            style={{
              backgroundImage: `url(${disabled ? iconSrcDisabled : iconSrc})`,
              backgroundSize: 'cover',
              minWidth: dimension,
              minHeight: dimension
            }}
          />
        )
      }
      checkedIcon={
        <div
          data-testid={'enabled-checkbox' /*Активный чекбокс | Общий компонент*/}
          style={{
            backgroundImage: `url(${disabled ? checkedIconSrcDisabled : checkedIconSrc})`,
            backgroundSize: 'cover',
            minWidth: dimension,
            minHeight: dimension
          }}
        />
      }
      indeterminateIcon={
        <div
          data-testid={'checkbox-semi-black' /*Чекбокс | Checkbox наполовину окрашен в черный*/}
          style={{
            backgroundImage: `url(${disabled ? indeterminateIconSrcDisabled : indeterminateIconSrc})`,
            backgroundSize: 'cover',
            minWidth: dimension,
            minHeight: dimension
          }}
        />
      }
      {...restProps}
    />
  )

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <span>{renderCheckboxComponent()}</span>
    </Tooltip>
  ) : (
    renderCheckboxComponent()
  )
}
