/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
import MobileDetect from 'mobile-detect'

export const md = new MobileDetect(window.navigator.userAgent, 530)

export const isSupportTouch = navigator.maxTouchPoints > 1
export const isTablet = Boolean(md.tablet() || !md.isPhoneSized())
// detectmobilebrowsers.com

export const isIos = md.os() === 'iOS'

export const isNativeVendorIos = Boolean(window.webkit?.messageHandlers.restappNative)
const isNativeVendorAndroid = Boolean(window.AndroidInterface)
export const isNativeVendor = isNativeVendorIos || isNativeVendorAndroid

export const getNativePlatform = (): 'ios' | 'android' | undefined => {
  if (isNativeVendorIos) {
    return 'ios'
  }
  if (isNativeVendorAndroid) {
    return 'android'
  }

  return undefined
}

// тип приложения, на котором сейчас работаем
type AppType = 'restapp_web_desktop' | 'restapp_web_mobile' | 'restapp_native_android' | 'restapp_native_ios'

export const getApplication = (): AppType => {
  if (isNativeVendorAndroid) {
    return 'restapp_native_android'
  }

  if (isNativeVendorIos) {
    return 'restapp_native_ios'
  }

  if (isSupportTouch && md.isPhoneSized(767)) {
    return 'restapp_web_mobile'
  }

  return 'restapp_web_desktop'
}
