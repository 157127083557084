import {t} from '@eda-restapp/i18n'
import {floor} from 'lodash-es'

interface Time {
  day: number
  hour: number
  min: number
}

export const convertMinToTime = (minute: number): Time => {
  let hour = floor(minute / 60)
  const min = minute % 60
  const day = floor(hour / 24)
  hour = hour % 24

  return {
    day,
    hour,
    min
  }
}

export const timeHumanize = (time: Time) => {
  const {day, hour, min} = time

  return {
    day: {
      value: day,
      label: t('shared.convert.days-postfix-label', '{count} день/дня/дней', {
        none: 'дней',
        one: 'день',
        some: 'дня',
        many: 'дней',
        count: day
      })
    },
    hour: {
      value: hour,
      label: t('shared.convert.hours-postfix-label', '{count} час/часа/часов', {
        none: 'часов',
        one: 'час',
        some: 'часа',
        many: 'часов',
        count: hour
      })
    },
    min: {
      value: min,
      label: t('shared.convert.minutes-postfix-label', '{count} минута/минуты/минут', {
        none: 'минут',
        one: 'минута',
        some: 'минуты',
        many: 'минут',
        count: min
      })
    }
  }
}
