import type {FC, ReactNode} from 'react'

import {usePassportEnabled} from '../../../hooks'
import {usePassportBind} from '../hooks/usePassportBind'

import {PassportModals} from './PassportModals'

type PassportModalsContainerProps = {
  children: ReactNode
}
export const PassportModalsContainer: FC<PassportModalsContainerProps> = ({children}) => {
  const passportBind = usePassportBind()
  const passportEnabled = usePassportEnabled()

  if (!passportBind) {
    return <>{children}</>
  }

  return (
    <>
      {children}

      {!passportEnabled.hideModalsAndDrawers && (
        <PassportModals
          suggestedAccount={passportBind?.suggestedAccount}
          onBindOtherAccount={() => passportBind?.onBindOtherAccount()}
          onBindSuggestedAccount={() => passportBind?.onBindSuggestedAccount()}
          onAuthWithLinkedAccount={(account) => passportBind?.onAuthWithLinkedAccount(account)}
        />
      )}
    </>
  )
}
