import type {FC, SyntheticEvent} from 'react'
import React from 'react'
import type {TabsTypeMap} from '@mui/material'
import {makeStyles} from 'tss-react/mui'
import {Tabs as MUITabs} from '@mui/material'

import {tabsStyles} from './Tabs.style'
import {eventLogger} from '@eda-restapp/logger'

type MUITabsProps = TabsTypeMap['props']
type DefinedTabsProps = Pick<MUITabsProps, 'onChange' | 'value'>
export type TabsProps = DefinedTabsProps & {
  slug?: string
  children?: React.ReactNode
  className?: string
  classes?: Partial<Record<keyof typeof tabsStyles, string>>
}

const useStyles = makeStyles()(tabsStyles)

const Tabs: FC<TabsProps> = ({children, className, classes, slug, ...tabsProps}) => {
  const {classes: c} = useStyles(undefined, {
    props: {
      classes: classes
    }
  })

  const onChangeWithTracker = (event: SyntheticEvent<Element, Event>, value: unknown) => {
    eventLogger({name: 'tab_change', value: slug, additional: {eventData: value}})
    tabsProps.onChange?.(event, value)
  }

  return (
    <div className={className}>
      <MUITabs
        scrollButtons={false}
        variant='standard'
        classes={{
          root: c.root,
          flexContainer: c.flexContainer,
          indicator: c.indicator
        }}
        {...tabsProps}
        onChange={onChangeWithTracker}
      >
        {children}
      </MUITabs>
    </div>
  )
}

export default Tabs
