import cn from 'classnames'
import React from 'react'

import type {DelimiterStyle} from './Delimiter.style'
import useStyles from './Delimiter.style'

type Props = {
  className?: string
  classes?: Partial<Record<keyof typeof DelimiterStyle, string>>
} & {
  rightContent: string
  leftContent?: number
  dataTestId?: string
}

const Delimiter: React.FC<Props> = ({rightContent, leftContent, dataTestId = '', classes, className}: Props) => {
  const {classes: c} = useStyles(undefined, {props: {classes}})

  return (
    <div className={cn(c.root, className)}>
      <div>{rightContent}</div>
      {!!leftContent && <div data-testid={dataTestId}>{leftContent}</div>}
    </div>
  )
}

export default Delimiter
