import cn from 'classnames'
import type {ReactElement, ReactNode} from 'react'
import React from 'react'
import {TextField, FormControl, FormHelperText, InputLabel, Autocomplete} from '@mui/material'
import {get, toString} from 'lodash-es'

import type {TagInputStyleProps} from './TagInput.styles'
import {useTagInputStyles} from './TagInput.styles'
import {eventLogger} from '@eda-restapp/logger'

type TagInputProps<T extends string> = {
  placeholder?: string
  value?: T[]
  onChange?: (value: T[]) => unknown
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  classes?: TagInputStyleProps['classes']
  error?: boolean
  label?: string
  hint?: string
  labelAppend?: ReactNode
  name?: string
  id?: string
  slug?: string
  beforeInput?: ReactNode
  appendInput?: ReactNode
  changeOnSpace?: boolean
}

const SPACE_KEYCODE = 32

function TagInput<T extends string = string>({
  changeOnSpace = false,
  value = [],
  onChange,
  placeholder,
  disabled,
  classes,
  error,
  onBlur,
  label,
  hint,
  labelAppend,
  name,
  id,
  slug,
  beforeInput,
  appendInput
}: TagInputProps<T>): ReactElement | null {
  const {classes: c} = useTagInputStyles(undefined, {
    props: {classes}
  })
  const computedPlaceholder = placeholder && !value?.length ? placeholder : undefined

  //#region Tracker
  const onChangeTracked = (value: T[]) => {
    eventLogger({name: 'tag_input', value: slug, additional: {type: 'onChange', value}})
    onChange?.(value)
  }
  //#endregion Tracker

  const onKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (changeOnSpace && Array.isArray(value) && ev.keyCode === SPACE_KEYCODE) {
      // не даем ввести пробел
      ev.preventDefault()

      // Извлекаем и выдаем наружу текущее значение инпута в виде массива
      const newItem = toString(get(ev.target, 'value')).trim() as unknown as T
      const newValue = [...value].concat([newItem])

      if (newItem) {
        onChangeTracked(newValue)
      }
    }
  }

  return (
    <div className={c.root} data-testid='password-drawer-input'>
      <FormControl variant='standard' error={error} fullWidth>
        <InputLabel
          disableAnimation
          classes={{
            root: c.labelRoot
          }}
        >
          {!!label && (
            <div className={c.label}>
              <span>{label}</span>
              {labelAppend}
            </div>
          )}

          {!!beforeInput && <div className={c.beforeInput}>{beforeInput}</div>}

          <div className={c.inputWrap}>
            <Autocomplete<T, true, true, true>
              freeSolo
              multiple
              autoSelect
              disableClearable
              id={id}
              value={value}
              options={[]}
              disabled={disabled}
              onChange={(_evt, value) => onChangeTracked(value as T[])}
              onKeyDown={onKeyDown}
              className={cn({[c.error]: error})}
              classes={{
                inputRoot: c.inputRoot,
                tag: c.tag,
                input: c.input
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    variant='standard'
                    {...params}
                    onBlur={onBlur}
                    name={name}
                    placeholder={computedPlaceholder}
                  />
                  {!!hint && (
                    <FormHelperText
                      classes={{
                        root: c.hintRoot
                      }}
                    >
                      {hint}
                    </FormHelperText>
                  )}
                </>
              )}
            />

            {!!appendInput && <div className={c.appendInput}>{appendInput}</div>}
          </div>
        </InputLabel>
      </FormControl>
    </div>
  )
}

export default TagInput
