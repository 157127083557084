import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Button, Dialog} from '@eda-restapp/ui'
import type {FC} from 'react'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router'

import {setNotificationTimeouts} from '../../../../helpers/setNotificationTimeouts'
import {useStoplistNotificationMetric} from '../../../../hooks/useStoplistNotificationMetric'
import {useWeekday} from '../../../../hooks/useWeekday'
import type {WeeklySchedule} from '../../../../StoplistNotifications.types'

type StoplistAvailabilityNotificationModalProps = {
  modalSchedule: WeeklySchedule
}

export const StoplistAvailabilityNotificationModal: FC<StoplistAvailabilityNotificationModalProps> = ({
  modalSchedule
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const weekday = useWeekday()
  const {t} = useI18n()
  const navigate = useNavigate()
  const onModalDisplayed = useStoplistNotificationMetric('modal')

  useEffect(() => {
    const showModal = () => {
      onModalDisplayed()
      setIsModalOpen(true)
    }

    const modalTimeoutIds = setNotificationTimeouts(weekday, modalSchedule, showModal)

    return () => modalTimeoutIds?.forEach((tid) => clearTimeout(tid))
  }, [weekday, modalSchedule, onModalDisplayed])

  const handleMenuButtonClick = () => {
    metrika({target: 'stoplist_availability_notification_modal_button_click'})
    setIsModalOpen(false)
    navigate('/menu')
  }

  const handleClose = () => {
    metrika({target: 'stoplist_availability_notification_modal_close'})
    setIsModalOpen(false)
  }

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      <Dialog.Header
        enableCross
        text={t('stoplist-notifications.availability-modal.header', 'Проверьте доступность позиций')}
      />
      <Dialog.Content>
        {t(
          'stoplist-notifications.availability-modal.content',
          'Недоступность блюд повышает отмены заказов и недовольство пользователей.\n\nДобавьте позиции в стоп-лист, если необходимо.'
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <Button.Text fullWidth onClick={handleMenuButtonClick}>
          {t('stoplist-notifications.availability-modal.button-text', 'В Меню')}
        </Button.Text>
      </Dialog.Footer>
    </Dialog>
  )
}
