import {makeStyles} from 'tss-react/mui'

export const useNewsTabsStyles = makeStyles({name: 'NewsTabs'})({
  tabs: {
    margin: '0 -16px 12px'
  },
  tabsScrollable: {
    padding: '0 16px'
  },
  noIndicator: {
    display: 'none'
  },
  selectWrapper: {
    paddingRight: 16
  }
})
