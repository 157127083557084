import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {useCallback, useMemo} from 'react'

import {useOldHeavyPlaces} from '@restapp/core-places'

import {ALL_SERVICES, DEFAULT_SERVICES} from '../constants'
import {CampaignType} from '../types/campaign'
import type {OldFullRestaurant, RestaurantCountryCode, Service, ShortRestaurant} from '../types/places'

import useAvailableCampaignTypes from './useAvailableCampaignTypes'
import useCampaignsApi from './useCampaignsApi'
import useIsAvailableBusinessType from './useIsAvailableBusinessType'
import usePlaceRatingValidation from './usePlaceRatingValidation'

const isDCPlaceEnabled = (place: ShortRestaurant) => Boolean(place.services?.enabled?.includes('dc'))
const isEdaPlaceEnabled = (place: ShortRestaurant) => Boolean(place.services?.enabled?.includes('eda'))

const usePlacesToEnable = () => {
  const {t} = useI18n()
  const {cities_available_for_banner_promotion, cpc, cpm} = useExp3('restapp_promotion')

  const {isAvailableBusinessType} = useIsAvailableBusinessType()
  const heavyPlaces = useOldHeavyPlaces()

  const {campaigns, ratingThreshold, isLoading: isLoadingCampaigns} = useCampaignsApi()

  const {getAvailableCampaignTypes: getFreeDcTypes} = useAvailableCampaignTypes({
    campaigns: campaigns?.filter((campaign) => campaign.service === 'dc')
  })
  const {getAvailableCampaignTypes: getFreeEdaTypes} = useAvailableCampaignTypes({
    campaigns: campaigns?.filter((campaign) => campaign.service === 'eda')
  })

  const {isPlaceRatingOk, isLoading: isLoadingRating} = usePlaceRatingValidation()

  const cpmAllowedByPlaceId = useMemo(
    () =>
      cities_available_for_banner_promotion
        ? heavyPlaces.places?.reduce((acc, place) => {
            acc.set(
              place.id,
              cities_available_for_banner_promotion.some((city) => place.address.includes(city))
            )

            return acc
          }, new Map<number, boolean>())
        : undefined,
    [heavyPlaces.places, cities_available_for_banner_promotion]
  )

  const isCpmAvailable = useCallback((id: number) => cpmAllowedByPlaceId?.get(id), [cpmAllowedByPlaceId])

  const getPlaceError = useMemo(
    () =>
      getFreeDcTypes &&
      getFreeEdaTypes &&
      (({
        campaignType,
        ratingThreshold,
        place,
        services,
        country
      }: {
        campaignType: CampaignType
        ratingThreshold: number
        place: OldFullRestaurant
        services: Service[]
        country?: RestaurantCountryCode
      }) => {
        const canAddAsDC = isDCPlaceEnabled(place) && services.includes('dc')
        const canAddAsEda = isEdaPlaceEnabled(place) && services.includes('eda')

        if (!canAddAsDC && !canAddAsEda) {
          return t('promotion.errors.place-is-unavailable-for-selection', 'Ресторан недоступен для выбора')
        }

        if (!isAvailableBusinessType(place.id)) {
          return t('promotion.errors.cannot-start-campaign-for-this-place', 'Нельзя начать продвижение для этой точки')
        }

        if (!isPlaceRatingOk(ratingThreshold, place.id)) {
          return t('promotion.use-places-to-enable.rating-below-threshold', 'Рейтинг ниже {ratingThreshold}', {
            ratingThreshold
          })
        }

        if (!isCpmAvailable(place.id) && campaignType === CampaignType.cpm) {
          return t('promotion.use-places-to-enable.city-not-allowed', 'Город недоступен для продвижения')
        }

        const canAddCampaignTypeAsEda = canAddAsEda && getFreeEdaTypes(place.id).includes(campaignType)
        const canAddCampaignTypeAsDC = canAddAsDC && getFreeDcTypes(place.id).includes(campaignType)

        if (!canAddCampaignTypeAsEda && !canAddCampaignTypeAsDC) {
          return t('promotion.use-places-to-enable.campaign-already-started', 'Продвижение уже запущено для этой точки')
        }

        if (country && place.country_code !== country) {
          return t(
            'promotion.use-places-to-enable.place-country-not-in-selection',
            'Ресторан не находится в выбранной стране'
          )
        }

        return undefined
      }),
    [getFreeEdaTypes, getFreeDcTypes, isCpmAvailable, isPlaceRatingOk, isAvailableBusinessType, t]
  )

  const cpcServices: Service[] = cpc.services.dc.enabled ? ALL_SERVICES : DEFAULT_SERVICES
  const cpmServices: Service[] = cpm.services.dc.enabled ? ALL_SERVICES : DEFAULT_SERVICES

  const placesAllowedForCpc =
    getPlaceError && ratingThreshold !== undefined
      ? heavyPlaces.places?.filter(
          (place) => !getPlaceError({campaignType: CampaignType.cpc, ratingThreshold, place, services: cpcServices})
        )
      : undefined
  const placesAllowedForCpm =
    getPlaceError && ratingThreshold !== undefined
      ? heavyPlaces.places?.filter(
          (place) => !getPlaceError({campaignType: CampaignType.cpm, ratingThreshold, place, services: cpmServices})
        )
      : undefined

  const dcPlacesAllowedForCpc = placesAllowedForCpc?.filter(isDCPlaceEnabled)
  const edaPlacesAllowedForCpc = placesAllowedForCpc?.filter(isEdaPlaceEnabled)

  const canStartCpcCampaign = placesAllowedForCpc ? placesAllowedForCpc.length > 0 : undefined
  const canStartCpmCampaign = placesAllowedForCpm ? placesAllowedForCpm.length > 0 : undefined
  const canStartCampaign = canStartCpcCampaign || canStartCpmCampaign
  const canStartCpcDCCampaign = dcPlacesAllowedForCpc
    ? cpc.services.dc.enabled && dcPlacesAllowedForCpc.length > 0
    : undefined
  const canStartCpcEdaCampaign = edaPlacesAllowedForCpc ? edaPlacesAllowedForCpc.length > 0 : undefined

  const isLoading = isLoadingCampaigns || heavyPlaces.isLoading || isLoadingRating

  return {
    places: heavyPlaces.places,
    placesAllowedForCpc,
    dcPlacesAllowedForCpc,
    edaPlacesAllowedForCpc,
    placesAllowedForCpm,
    isLoading,
    getPlaceError,
    canStartCampaign,
    canStartCpcCampaign,
    canStartCpmCampaign,
    canStartCpcDCCampaign,
    canStartCpcEdaCampaign,
    getFreeDcTypes,
    getFreeEdaTypes
  }
}

export default usePlacesToEnable
