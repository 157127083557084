import {metrika} from '@eda-restapp/logger'
import {Button, ChevronRightMIcon, Box} from '@eda-restapp/ui'
import React, {type FC, type ReactNode, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import styles from './SuggestButtonNav.module.css'

type SuggestButtonNavProps = {
  startIcon?: ReactNode
  children?: ReactNode
}

export const SuggestButtonNav: FC<SuggestButtonNavProps> = ({startIcon, children}) => {
  const location = useLocation()

  useEffect(() => {
    metrika({
      target: 'suggest_promotion_show_button',
      params: {
        location: location.pathname
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button.Base baseClasses={styles.root} fullWidth variant='light'>
      <Box alignItems='center' gap='s' px='s' flexGrow={1}>
        {!!startIcon && (
          <Box flexShrink={0} flexGrow={0}>
            {startIcon}
          </Box>
        )}

        {children}

        <ChevronRightMIcon className={styles.iconEnd} fontSize='24px' />
      </Box>
    </Button.Base>
  )
}
