import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'CountrySelector'})((theme) => {
  return {
    button: {
      padding: '0 0 0 6px',
      height: 'fit-content',

      '& .MuiButton-label': {
        fontWeight: 400,
        textAlign: 'end',

        [theme.breakpoints.up('md')]: {
          padding: 'initial',
          fontSize: 16
        }
      }
    },
    countrySelectButtonLabel: {
      display: 'flex',
      alignItems: 'center',

      '& > :not(:first-child)': {
        display: 'none',

        [theme.breakpoints.up('md')]: {
          display: 'block'
        }
      }
    },
    countrySelectButtonLabelFlag: {
      [theme.breakpoints.up('md')]: {
        marginRight: 13
      },

      '& > svg': {
        display: 'block'
      }
    },
    menuItem: {
      fontSize: 14,

      '& > :first-child': {
        marginRight: 17
      }
    }
  }
})
