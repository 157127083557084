import {useExp3} from '@eda-restapp/configs'

const DEFAULT_CLICK_PRICE = 25
const DEFAULT_MIN_CLICK_PRICE = 10
const DEFAULT_MAX_CLICK_PRICE = 1000

const DEFAULT_WEEK_BUDGET_PRICE = 10000
const DEFAULT_MIN_WEEK_BUDGET_PRICE = 1000

const usePromotionCostConfigs = () => {
  const promotionCostConfig = useExp3('restapp_promotion_cost')

  const defaultClickPrice = promotionCostConfig?.default_click_price || DEFAULT_CLICK_PRICE
  const minClickPrice = promotionCostConfig?.default_min_click_price || DEFAULT_MIN_CLICK_PRICE
  const maxClickPrice = promotionCostConfig?.default_max_click_price || DEFAULT_MAX_CLICK_PRICE

  const defaultWeekBudgetPrice = promotionCostConfig?.default_week_budget || DEFAULT_WEEK_BUDGET_PRICE
  const minWeekBudgetPrice = promotionCostConfig?.default_min_week_budget || DEFAULT_MIN_WEEK_BUDGET_PRICE

  const countryRates = promotionCostConfig?.country_rates || []
  const regionRates = promotionCostConfig?.region_rates || []

  const defaultCpaBudgetPrice = promotionCostConfig.cpa_budget?.default
  const minCpaBudgetPrice = promotionCostConfig.cpa_budget?.min
  const maxCpaBudgetPrice = promotionCostConfig.cpa_budget?.max

  return {
    defaultCpaPrice: promotionCostConfig.default_cpa_price,
    defaultCpaMinPrice: promotionCostConfig.default_cpa_min_price,
    defaultCpaMaxPrice: promotionCostConfig.default_cpa_max_price,
    defaultClickPrice,
    minClickPrice,
    maxClickPrice,

    defaultWeekBudgetPrice,
    minWeekBudgetPrice,

    defaultCpaBudgetPrice,
    minCpaBudgetPrice,
    maxCpaBudgetPrice,

    countryRates,
    regionRates,

    /**
     * Недельный бюджет
     */
    bannerBudget: promotionCostConfig?.banner_budget,
    /**
     * Бюджет за весь период
     */
    bannerBudgetAllPeriod: promotionCostConfig?.banner_budget_all_period,
    bannerPrice: promotionCostConfig?.banner_price
  }
}

export default usePromotionCostConfigs
