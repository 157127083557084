import {errorLogger} from '@eda-restapp/logger'
import type {GetDerivedStateFromError} from 'react'
import React, {Component} from 'react'

import GlobalErrorPage from './GlobalErrorPage'

interface GlobalErrorBoundaryProps {
  children?: React.ReactNode
}

interface GlobalErrorBoundaryState {
  hasError: boolean
  error?: unknown
  eventId?: string
}

/**
 * Идея за отдельным глобальным обработчиком в том что в него попадают самые стремные необработанные ошибки,
 * он должен быть один на сервис и в идеале никогда не срабатывать, т.к все обрабатывается и ловится раньше.
 */
class GlobalErrorBoundary extends Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: undefined
    }
  }

  static getDerivedStateFromError: GetDerivedStateFromError<{}, GlobalErrorBoundaryState> = (error: unknown) => {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    errorLogger({
      sourceType: 'error-boundary',
      level: 'critical',
      error: error,
      additional: {eventData: errorInfo.componentStack}
    })
    const eventId = Math.random().toString(16).slice(2).toUpperCase()

    this.setState((state) => {
      return {...state, eventId: eventId}
    })
  }

  render() {
    if (this.state.hasError) {
      return <GlobalErrorPage error={this.state.error} eventId={this.state.eventId} />
    }

    return this.props.children
  }
}

export default GlobalErrorBoundary
