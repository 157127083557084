import type {Disposable} from '../../types'

type Dispose = () => void

export default class Disposer implements Disposable {
  private _disposeList: Dispose[] = []

  add(...disposeList: Dispose[]) {
    this._disposeList.push(...disposeList)
  }

  dispose() {
    for (const dispose of this._disposeList) {
      dispose()
    }
  }
}
