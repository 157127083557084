import {useExp3} from '@eda-restapp/configs'
import {useCopyToClipboard} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {Telegram, Copy2Icon, Tooltip, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useMemo, useState} from 'react'
import {useMatch, useNavigate} from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import type {TelegramListItem} from '@restapp/shared-api'
import {Button} from '@restapp/shared-ui'

import TGBackground from './assets/tg.png'
import {useSubscriptionButtonTooltipStyles} from './SubscriptionButtonTooltip.styles'

interface IProps {
  localStorageStateKey: string
  telegramList?: TelegramListItem[]
  hasPaidSubscription?: boolean
  showPromoByType?: 'free' | 'business' | 'business_plus'
  hideTooltip?: boolean
  openMessengerSettings: () => void
  openSubscription: () => void
  children?: React.ReactNode
}

export const SubscriptionButtonTooltip: React.FC<IProps> = ({
  children,
  localStorageStateKey,
  telegramList,
  hasPaidSubscription,
  showPromoByType,
  hideTooltip,
  openMessengerSettings,
  openSubscription
}) => {
  const {t} = useI18n()
  const {classes: c} = useSubscriptionButtonTooltipStyles()
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<HTMLElement | null>(null)
  const [copyTooltipAnchorEl, setCopyTooltipAnchorEl] = useState<HTMLElement | null>(null)

  const isMobile = useIsMobileStrict()
  const matchStatistics = useMatch('/metrics')
  const navigate = useNavigate()

  const clipboard = useCopyToClipboard({copiedTimeout: 2000})
  const toolsConfig = useExp3('restapp_tools')

  const [isFirstShow, setIsFirstShow] = useLocalStorageState(localStorageStateKey, {
    defaultValue: true
  })

  const hasTelegramLogins = useMemo(
    () => (telegramList || []).some((item) => !!(item.logins || []).length),
    [telegramList]
  )
  /**
   * Ищем неактивированные логины
   * Объединяем в один массив
   * Фильтруем дубликаты
   */
  const telegramsNotActive = useMemo(
    () =>
      (telegramList || [])
        .filter((item) => (item.logins || []).filter((val) => !val.is_bot_active).length)
        .flatMap((item) => item.logins)
        .map((item) => item.login)
        .filter(function (elem, index, self) {
          return index === self.indexOf(elem)
        }),
    [telegramList]
  )

  const closeTooltip = () => setIsFirstShow(false)

  const renderText = () => {
    if (showPromoByType) {
      return (
        <>
          <div>
            {t(
              'shared-places.subscription-button-tooltip.tools-for-growing-orders',
              'Инструменты для роста заказов бесплатно'
            )}
          </div>
          <div className={c.text}>
            {t(
              'shared-places.subscription-button-tooltip.business-subscribition-active',
              'Подписка Бизнес тарифа для ресторанов уже активна'
            )}
          </div>
        </>
      )
    }

    if (hasPaidSubscription && telegramList) {
      if (!telegramsNotActive.length) {
        if (matchStatistics) {
          return t(
            'shared-places.subscription-button-tooltip.set-telegram-for-statistics',
            'Добавьте Telegram для всех ресторанов в подписке и получайте ежедневные отчёты о своей работе'
          )
        }

        return t(
          'shared-places.subscription-button-tooltip.set-telegram-default',
          'Настройте Telegram-бот и получайте ежедневные отчёты о своей работе'
        )
      }

      return (
        <>
          {t(
            'shared-places.subscription-button-tooltip.set-telegram-bots-on-active',
            'Запустите Telegram-бот для пользователей с именем:'
          )}{' '}
          <span>@{telegramsNotActive.join(', @')}</span>
        </>
      )
    }

    return t(
      'shared-places.subscription-button-tooltip.get-statistics-reports-in-telegram-bot',
      'Получайте уведомления о блюдах в стоп-листе, новых отзывах и отменах'
    )
  }

  const renderButton = () => {
    if (showPromoByType === 'business_plus') {
      return (
        <div className={c.actions}>
          <Button
            variant={'contained'}
            className={c.btn}
            onClick={() => {
              closeTooltip()
              navigate({pathname: '/tools/subscription'})
            }}
          >
            {t(
              'shared-places.subscription-button-tooltip.control-subscribition',
              'Надпись на кнопке для перехода на страницу Инструментов',
              {value: 'Управлять подпиской'}
            )}
          </Button>
        </div>
      )
    }

    if (showPromoByType === 'business') {
      return (
        <div className={c.actions}>
          <Button
            variant={'contained'}
            className={cn(c.btn, c.btnSecondary)}
            onClick={() => {
              closeTooltip()
              navigate({pathname: '/tools'})
            }}
          >
            {t(
              'shared-places.subscription-button-tooltip.go-to-tools',
              'Надпись на кнопке для перехода на страницу Инструментов',
              {value: 'К инструментам'}
            )}
          </Button>
          <Button
            variant={'contained'}
            className={c.btn}
            onClick={() => {
              closeTooltip()
              navigate({
                pathname: toolsConfig.infoWidgetTrialLink?.pathname,
                search: toolsConfig.infoWidgetTrialLink?.search
              })
            }}
          >
            {t(
              'shared-places.subscription-button-tooltip.go-to-subscription-page',
              'Надпись на кнопке для перехода на страницу с информацией о подписке',
              {value: 'К условиям'}
            )}
          </Button>
        </div>
      )
    }

    if (hasPaidSubscription && telegramList) {
      if (toolsConfig.subscriptionPage.telegramBotLogin && telegramsNotActive.length) {
        return (
          <>
            <div ref={setCopyTooltipAnchorEl}>
              <Button
                className={c.copyBtn}
                onClick={() => clipboard.copy(toolsConfig.subscriptionPage.telegramBotLogin)}
              >
                <span className={c.text}>
                  <span className={c.label}>
                    {t('shared-places.subscription-button-tooltip.label-link-tg-bot', 'Ссылка на бота')}
                  </span>
                  <span>{toolsConfig.subscriptionPage.telegramBotLogin}</span>
                </span>
                <Copy2Icon className={c.icon} />
              </Button>
            </div>
            {copyTooltipAnchorEl && (
              <Tooltip.Info anchorEl={copyTooltipAnchorEl} placement='bottom' open={clipboard.copied}>
                {t('shared-places.subscription-button-tooltip.copy', 'Скопировано')}
              </Tooltip.Info>
            )}
          </>
        )
      }

      if (!telegramsNotActive.length) {
        if (matchStatistics) {
          return (
            <Button variant='contained' color='primary' onClick={openMessengerSettings}>
              {t('shared-places.subscription-button-tooltip.add-bot', 'Добавить Telegram')}
            </Button>
          )
        }

        return (
          <Button variant='contained' color='primary' onClick={openMessengerSettings}>
            {t('shared-places.subscription-button-tooltip.setting', 'Настроить бот')}
          </Button>
        )
      }
    }

    return (
      <Button variant='contained' color='primary' onClick={openSubscription}>
        {t('shared-places.subscription-button-tooltip.let-is-try', 'Давайте попробуем')}
      </Button>
    )
  }

  const renderImage = () => {
    if (showPromoByType) {
      return <img className={c.tgImg} src={toolsConfig.promoButtonTooltipImage} />
    }

    if (toolsConfig.subscriptionPage.telegramBotLogin && !!telegramsNotActive.length) {
      return <img className={c.tgImg} src={TGBackground} />
    }

    return <Telegram className={c.telegramIcon} />
  }

  if (!children) {
    return null
  }

  if (hasPaidSubscription && !telegramsNotActive.length && hasTelegramLogins && !showPromoByType) {
    return <>{children}</>
  }

  return (
    <>
      <div ref={setTooltipAnchorEl} style={{width: '100%'}}>
        {children}
      </div>

      {tooltipAnchorEl && (
        <Tooltip.Onboarding
          className={c.tooltip}
          anchorEl={tooltipAnchorEl}
          open={isFirstShow && !hideTooltip}
          placement={isMobile ? 'bottom' : 'bottom-start'}
          onClose={closeTooltip}
        >
          <div>
            <div className={c.head}>{renderImage()}</div>
            <div className={c.title}>{renderText()}</div>
            {renderButton()}
          </div>
        </Tooltip.Onboarding>
      )}
    </>
  )
}
