import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {LegacyButton as Button} from '@eda-restapp/ui'
import {Dialog as MUIDialog} from '@mui/material'
import React, {useEffect} from 'react'
import {GlobalStyles} from 'tss-react'
import {makeStyles} from 'tss-react/mui'

import {S} from '@restapp/shared-ui'

import {EventType} from '../../tracker.constants'
import type {DialogProps, DialogParams} from '../../types'
import SlideTransition from '../SlideTransition'

const MAX_BUTTON_LENGTH = 25

interface Props extends DialogProps, Partial<DialogParams> {
  children?: React.ReactNode
  disablePortal?: boolean
  cancelDisabled?: boolean
}

const useStyles = makeStyles<void, 'action'>()((_theme, _params, classes) => {
  return {
    wrap: {
      padding: '24px 24px 0',

      [S.mobileQuery]: {
        padding: '16px 16px 0'
      },

      '& > * + *': {
        marginTop: 16
      }
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '28px'
    },
    disableOutsideArea: {
      pointerEvents: 'none'
    },
    root: {
      padding: 0,
      background: '#ffffff',
      borderRadius: 24,
      minWidth: 250,
      maxWidth: 444,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      pointerEvents: 'auto',
      boxShadow: 'none',
      margin: 16,
      overflow: 'hidden'
    },
    content: {
      overflow: 'hidden'
    },
    maxButton: {
      [`& .${classes.action}`]: {
        flexWrap: 'wrap',
        '& button': {
          width: '100%'
        }
      }
    },
    action: {
      display: 'flex',
      margin: '16px -8px -8px',
      padding: '0 24px 24px',

      [S.mobileQuery]: {
        flexWrap: 'wrap',
        margin: '19px -5px -5px',
        padding: '0 8px 8px'
      },

      '& button': {
        margin: 8,
        [S.mobileQuery]: {
          margin: 5
        }
      }
    },
    message: {
      fontSize: 16,
      lineHeight: '19px'
    },
    [S.mobileQuery]: {
      contentClass: {
        padding: 16,
        width: '100%'
      }
    }
  }
})

export default function Dialog({
  children,
  cancelText,
  applyText = '',
  outsideClose = true,
  cancelDisabled = false,
  title,
  visible,
  onClose,
  onConfirm,
  progress,
  classes,
  disablePortal,
  slug
}: Props & {
  className?: string
  classes?: Partial<Record<keyof typeof useStyles, string>>
}) {
  const {t} = useI18n()
  cancelText = cancelText || t('shared-modals.dialog.zakrit', 'Закрыть')
  const {classes: c, cx} = useStyles(undefined, {
    props: {
      classes: classes
    }
  })

  useEffect(() => {
    if (visible) {
      eventLogger({
        name: EventType.DIALOG,
        value: slug,
        additional: {eventData: 'open'}
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onCloseWithTracker = () => {
    eventLogger({name: EventType.DIALOG, value: slug, additional: {eventData: 'close'}})
    onClose()
  }

  const onConfirmWithTracker = () => {
    eventLogger({name: EventType.DIALOG, value: slug, additional: {eventData: 'confirm'}})
    onConfirm()
  }

  return (
    <MUIDialog
      disableRestoreFocus
      disablePortal={disablePortal}
      TransitionComponent={SlideTransition}
      open={visible}
      onClose={(_, reason) => {
        if (outsideClose || !['backdropClick', 'escapeKeyDown'].includes(reason)) {
          onCloseWithTracker()
        }
      }}
      style={{
        zIndex: 200000
      }}
      classes={{
        paper: cx(c.root, {[c.maxButton]: (cancelText + applyText).length > MAX_BUTTON_LENGTH})
      }}
    >
      <GlobalStyles
        styles={{
          strong: {
            fontWeight: 'bold'
          }
        }}
      />
      <div className={c.content} data-testid={'ui-dialog' /*UI | Dialog root*/}>
        <div className={c.wrap}>
          {!!title && (
            <h2 data-testid={'dialog-title' /* Диалоговое окно | Заголовок */} className={c.title}>
              {title}
            </h2>
          )}
          <div className={c.message} data-testid={'dialog-message' /* Диалоговое окно | Сообщение */}>
            {children}
          </div>
        </div>
        <div className={c.action}>
          <Button
            fullWidth
            variant={applyText ? 'secondary' : 'primary'}
            disabled={cancelDisabled}
            onClick={onCloseWithTracker}
            data-testid={'ui-dialog-cancel-button' /*UI | Верхняя кнопка в компоненте Dialog*/}
          >
            {cancelText}
          </Button>
          {!!applyText && (
            <Button
              fullWidth
              loading={progress}
              onClick={onConfirmWithTracker}
              data-testid={'ui-dialog-apply-button' /*UI | Нижняя кнопка в компоненте Dialog*/}
            >
              {applyText}
            </Button>
          )}
        </div>
      </div>
    </MUIDialog>
  )
}
