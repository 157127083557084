export enum ResultType {
  base64 = 'base64',
  text = 'text'
}

export type useFileUploadType = {
  disabled?: boolean
  validate?: (files: File, img: HTMLImageElement, restrictions: useFileUploadType['restrictions']) => string | undefined
  /**
   *  Ограничения для изображения, будет передана в validate 2-м параметром. Можно заполнять частично, в таком случае для незаполненных параметров будут использоваться значения по умолчанию
   */
  restrictions?: {
    fileTypes?: string[]
    /**
     * Размер в байтах
     */
    maxFileSize?: number
    minWidth?: number
    minHeight?: number
    maxWidth?: number
    maxHeight?: number
  }
  resultType?: ResultType
  buttonText?: string
  buttonTextReplace?: string
  /**
   * Показывать "Заменить фотографию"
   */
  forceReplaceText?: boolean
  /**
   * При изменении вызывается cancel (undefined игнорируется)
   */
  forceCancelOnChange?: string
}

export type UseFileUploadReturnType = {
  file?: File
  fileAsString?: string
  fileAsBase64?: string
  error?: string
  dirty: boolean
  props: {
    disabled: boolean
    text: string
    accept?: string[]
    onChange: (data: File | File[]) => void
    width: string
  }
}
