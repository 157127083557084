import React from 'react'

import {selectUnacceptedOrdersIds, useActiveOrders} from '@restapp/orders'

import SidebarBadge, {BadgesType} from '../SidebarBadge'

const NewOrdersBadge: React.FC = () => {
  const {data: newOrdersCount = 0} = useActiveOrders({
    select: (data) => selectUnacceptedOrdersIds(data).length,
    refetchOnMount: false
  })

  return newOrdersCount ? <SidebarBadge type={BadgesType.NOTIFY_RED} /> : null
}

export default NewOrdersBadge
