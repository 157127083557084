import {useI18n} from '@eda-restapp/i18n'
import {ChevronDownMIcon, ChevronUpMIcon, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useState, useRef} from 'react'

import useHasScroll from '@restapp/core-legacy/hooks/useHasScroll'
import type {PlacePlus, Service} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'

import useStyles from './PlacesListPlus.style'
import {RestaurantListItemPlus} from './RestaurantListItemPlus'

interface Props {
  isSelect?: boolean
  places?: PlacePlus[]
  initialExpanded?: boolean
  onShowStatistics?: (placeId: number) => void
  title?: string
  placesCount?: number
  onDisable?: (id: number, service: Service[]) => void
  selectedPlaces?: number[]
}

const PlacesListPlus: React.FC<Props> = ({
  places,
  initialExpanded,
  onShowStatistics,
  onDisable,
  title,
  isSelect,
  placesCount,
  selectedPlaces
}) => {
  const {t} = useI18n()
  const {classes: c} = useStyles()
  const isMobile = useIsMobileStrict()

  const listRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(initialExpanded ?? (places && places.length > 1))
  const {hasVerticalScroll} = useHasScroll(listRef)

  const isExpandable = places ? places.length > 0 : false

  const handleClick = () => {
    if (!isExpandable) {
      return
    }

    setIsExpanded((prev) => !prev)
  }

  const availablePlaces = places?.filter(
    (place) =>
      place.isAvailable && place.plus?.find((item) => item.statuses[0].status === YaPlusStatusEnum.CAN_BE_ACTIVATED)
  )

  const nonDisabledPlaces = places ? places.filter((place) => !place.ratingError) : []

  const renderCounter = (count: number) =>
    t('promotion.places-list-plus.restaurants-count-text', '{count} ресторан/а/ов', {
      none: '{count} ресторанов',
      one: '{count} ресторан',
      some: '{count} ресторана',
      many: '{count} ресторанов',
      count
    })

  const renderHelpTextForAvailableRestaurants = (count: number, amount: number) => {
    if (selectedPlaces) {
      return t(
        'promotion.places-list-plus.restaurants-chosen-count-text',
        'Выбрано/ан {count} из {amount} ресторанов',
        {
          one: 'Выбран {count} из {amount} ресторанов',
          some: 'Выбрано {count} из {amount} ресторанов',
          many: 'Выбрано {count} из {amount} ресторанов',
          count,
          amount
        }
      )
    } else {
      return t('promotion.places-list-plus.restaurants-available-count-text', 'Доступно/ен {count} ресторан/а/ов', {
        one: 'Доступен {count} ресторан',
        some: 'Доступно {count} ресторана',
        many: 'Доступно {count} ресторанов',
        count
      })
    }
  }

  const renderHelpTextForConnectRestaurants = (countActivePlaces: number, placesCount: number) =>
    t('promotion.places-list-plus.restaurants-connect-count-text', 'Подключен/о {count} из {placesCount}', {
      one: 'Подключен {count} из {placesCount}',
      some: 'Подключено {count} из {placesCount}',
      many: 'Подключено {count} из {placesCount}',
      count: countActivePlaces,
      placesCount
    })

  const placesToRender = selectedPlaces ? nonDisabledPlaces.filter((item) => selectedPlaces.includes(item.id)) : places

  return (
    <div className={c.root} data-testid={'plus-service-selector-places-list-block' /* Плюс | Блок Рестораны в Плюсе */}>
      {!(isMobile && !isExpandable) && !isSelect && (
        <div
          data-testid={'plus-places-list-title' /* Плюс | Заголовок Рестораны в Плюсе */}
          className={cn(c.title)}
          onClick={handleClick}
        >
          {isMobile && !placesCount && places ? renderCounter(places.length) : title}
          {isExpanded ? <ChevronUpMIcon /> : <ChevronDownMIcon />}
        </div>
      )}
      {!isMobile && isExpandable && !isSelect && !placesCount && places && (
        <div className={c.counter}>{renderCounter(places.length)}</div>
      )}
      {isExpandable && !isSelect && !!placesCount && places && (
        <div data-testid={'plus-places-counter' /* Плюс | Количество подключенных ресторанов */} className={c.counter}>
          {renderHelpTextForConnectRestaurants(places.length, placesCount)}
        </div>
      )}
      {isSelect && (
        <>
          <div className={cn(c.select, isExpanded && c.openSelect)} onClick={handleClick}>
            <Typography
              data-testid={'plus-selector-text' /* Плюс | Текст в селекторе */}
              className={cn(availablePlaces?.length === 0 && places?.length !== 0 && c.errorText)}
              Component='label'
              variant='body2'
              lineHeight='normal'
              thickness='regular'
              justifyContent='space-between'
            >
              {availablePlaces?.length === 0 &&
                places?.length !== 0 &&
                t('promotion.places-list-plus.restaurants-text-unavailable', 'Нет доступных ресторанов')}
              {availablePlaces?.length === 0 &&
                places?.length === 0 &&
                t('promotion.places-list-plus.all-restaurants-text-unavailable', 'Все доступные рестораны подключены')}
              {availablePlaces &&
                availablePlaces?.length !== 0 &&
                renderHelpTextForAvailableRestaurants(
                  selectedPlaces ? selectedPlaces.length : availablePlaces.length,
                  places?.length || 0
                )}
              {isExpanded ? <ChevronUpMIcon /> : <ChevronDownMIcon />}
            </Typography>
          </div>
          <div className={cn(isExpanded && c.selectDivider)}></div>
        </>
      )}
      {isExpanded && (
        <div
          data-testid={'plus-places-list' /* Плюс | Список ресторанов */}
          className={cn(c.listWrapper, hasVerticalScroll && c.listWrapperOverflowed, isSelect && c.selectListWrapper)}
        >
          <div className={cn(c.list, isExpanded && isSelect && c.selectList)} ref={listRef}>
            {placesToRender &&
              placesToRender.map((place) => {
                const {id, status, showStats, ratingError, plus, showInfo, contractInfo} = place

                const hasContract = contractInfo?.some((service) => service.contract_type === 'contract')

                return (
                  <RestaurantListItemPlus
                    key={id}
                    ratingError={ratingError}
                    hasContract={hasContract}
                    plus={plus}
                    place={place}
                    status={status}
                    showInfo={showInfo}
                    showStats={showStats}
                    onClick={() => onShowStatistics && onShowStatistics(id)}
                    onDisable={(service) => onDisable?.(id, service)}
                  />
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default PlacesListPlus
