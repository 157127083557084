import {makeStyles} from 'tss-react/mui'

export default makeStyles({
  name: 'SuspenseErrorPage'
})({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    flexDirection: 'column'
  },

  title: {
    fontSize: 24,
    fontWeight: 'bold',
    maxWidth: '100%'
  },

  text: {
    overflow: 'auto',
    marginTop: 20,
    textAlign: 'center',
    maxWidth: '100%',
    fontSize: 16
  },

  error: {
    maxWidth: '100%',
    userSelect: 'all',
    overflow: 'auto',
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#f0f0f0',
    color: '#707070',
    fontSize: 14,
    marginTop: 16
  },

  button: {
    maxWidth: '100%',
    marginTop: 24
  }
})
