import {createRedirectPath} from '@eda-restapp/microfrontend'

import {OAUTH_REDIRECT_PATH} from '../../../constants'

type OauthUrlParams = {
  passportUrl: string
  oauthUrl: string
  passportQuery?: string
  state?: string
  clientId: string
  login?: string
}

export function createOauthUrl(params: OauthUrlParams): string {
  const oauthSearchParams = new URLSearchParams({
    client_id: params.clientId,
    response_type: 'token',
    redirect_uri: createRedirectPath(OAUTH_REDIRECT_PATH)
  })

  if (params.state) {
    oauthSearchParams.set('state', params.state)
  }

  const oauthUrl = `${params.oauthUrl}?${oauthSearchParams.toString()}`
  const passportSearchParams = new URLSearchParams(params.passportQuery)
  passportSearchParams.set('retpath', oauthUrl)
  passportSearchParams.set('backpath', window.location.toString())

  if (params.login) {
    passportSearchParams.set('login', params.login)
  }

  return `${params.passportUrl}?${passportSearchParams.toString()}`
}
