import React from 'react'

import {ChevronDownMIcon, LockMiniIcon} from '@eda-restapp/ui'

import useStyles from './IconComponentDefault.style'

type IconComponentDefaultProps = {
  isLocked?: boolean
}

const IconComponentDefault: React.FC<IconComponentDefaultProps> = ({isLocked}) => {
  const {classes: c} = useStyles()

  return <>{isLocked ? <LockMiniIcon classes={{root: c.lock}} /> : <ChevronDownMIcon classes={{root: c.root}} />}</>
}

export default IconComponentDefault
