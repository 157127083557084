import {makeStyles} from 'tss-react/mui'

export default makeStyles<void, 'header' | 'rootHighlighted'>({name: 'ModalHeader'})((theme, props, classes) => {
  return {
    root: {
      position: 'relative',
      padding: '24px 40px 16px',
      minHeight: 61,
      flexShrink: 0,
      zIndex: 1,
      backgroundColor: '#ffffff',
      fontSize: 32,
      fontWeight: 700,

      [theme.breakpoints.up('md')]: {
        borderRadius: '24px 24px 0 0'
      },

      [`&:not(.${classes.rootHighlighted})`]: {
        padding: '19px 48px',
        borderBottom: '1px solid #DCDEE0',
        fontWeight: 500,
        fontSize: 16,
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
          padding: '24px 40px 16px',
          borderBottom: 'none',
          fontWeight: 700,
          fontSize: 32,
          textAlign: 'start'
        }
      }
    },
    rootHighlighted: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '40px 16px 16px',
      width: '100%',
      transition: 'padding-top .2s ease',

      [theme.breakpoints.up('md')]: {
        position: 'relative',
        padding: '24px 40px 16px',
        width: 'auto',
        transition: 'initial'
      },

      [`& .${classes.header}`]: {
        whiteSpace: 'normal'
      }
    },
    rootShrinked: {
      marginTop: 0,
      padding: '19px 48px',
      borderBottom: '1px solid #DCDEE0',
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [theme.breakpoints.up('md')]: {
        padding: 'initial',
        borderBottom: 'none',
        fontWeight: 700,
        fontSize: 32,
        textAlign: 'start',
        textOverflow: 'clip'
      }
    },
    header: {
      display: '-webkit-box',
      font: 'inherit',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [theme.breakpoints.up('md')]: {
        textOverflow: 'clip',
        whiteSpace: 'normal'
      }
    },
    yangoFont: {
      fontFamily: 'Yango Headline'
    }
  }
})
