import getRatio from './getRatio'

const getImageConfig = ({image, width, height}: {image: HTMLImageElement; width: number; height: number}) => {
  const ratio = getRatio({width, height, image})
  const fitWidth = ratio * width
  const fitHeight = ratio * height
  const shiftWidth = (image.width - fitWidth) / 2
  const shiftHeight = (image.height - fitHeight) / 2

  return {
    ratio,
    fitWidth,
    fitHeight,
    shiftWidth,
    shiftHeight
  }
}

export default getImageConfig
