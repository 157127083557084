import {usePermission} from '@eda-restapp/permissions'
import React from 'react'
import {Helmet} from 'react-helmet'

import {useActiveOrders, selectUnacceptedOrdersIds} from '@restapp/orders'
import {useServiceTitle} from '@restapp/shared'

const AppTitle: React.FC<{withActiveOrders?: boolean}> = ({withActiveOrders = false}) => {
  const hasAccess = usePermission('permission.orders.active')
  const {data: newOrdersCount = 0} = useActiveOrders({
    select: (data) => selectUnacceptedOrdersIds(data).length,
    enabled: withActiveOrders
  })

  let favicon = '/favicon.ico'
  let headerTitle = useServiceTitle()

  if (withActiveOrders && newOrdersCount && hasAccess) {
    favicon = '/favicon-new.ico'
    headerTitle = `· (${newOrdersCount}) ${headerTitle}`
  }

  return (
    <Helmet defer={false} title={headerTitle}>
      <link rel='icon' href={`${favicon}?v=${VERSION}`} />
    </Helmet>
  )
}

export default AppTitle
