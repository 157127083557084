import {Box, Skeleton} from '@eda-restapp/ui'
import React, {useMemo} from 'react'
import type {FC} from 'react'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'

import {MultiChatInfo} from './MultiChatInfo'

export type MultiChatInfoContainerProps = {
  ticketId: string
}

export const MultiChatInfoContainer: FC<MultiChatInfoContainerProps> = ({ticketId}) => {
  const chatsListQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/chat/list',
    method: 'POST',
    body: {
      filters: {
        ticket_id: ticketId
      }
    },
    select: (data) => {
      if (!data.chats[0]) {
        return null
      }

      return data.chats[0]
    }
  })

  const {getPlaceById, isLoading: isPlacesLoading} = usePlaces()

  const place = useMemo(
    () => chatsListQuery.data?.place_id && getPlaceById(parseInt(chatsListQuery.data?.place_id)),
    [chatsListQuery.data?.place_id, getPlaceById]
  )

  if (!chatsListQuery.data || chatsListQuery.isLoading || isPlacesLoading) {
    return (
      <Box gap='m' flexDirection='column'>
        <Skeleton size='l' />
        <Skeleton size='l' />
        <Skeleton size='l' />
        <Skeleton size='l' />
        <Skeleton size='l' />
        <Skeleton size='l' />
      </Box>
    )
  }

  return (
    <MultiChatInfo
      status={chatsListQuery.data.status}
      updatedAt={chatsListQuery.data.updated_at}
      createdAt={chatsListQuery.data.created_at}
      ticketId={chatsListQuery.data.ticket_id}
      orderNr={chatsListQuery.data.order_nr}
      authorEmail={chatsListQuery.data.author_email}
      placeName={place ? place.name : undefined}
      placeAddress={place ? place.address : undefined}
      topic={chatsListQuery.data.topic}
    />
  )
}
