/* eslint-disable compat/compat */
import {useCallback} from 'react'
import {Snackbar} from '@eda-restapp/snackbars'

type NotificationOptions = {
  title?: string
  type?: 'success' | 'error' | 'info'
  message?: string
  onClick?: () => void
  onClose?: () => void
}

const NOTIFICATION_TIMEOUT = 7000

export const useSnackbarNotification = () => {
  const showSnackbarNotification = useCallback((options: NotificationOptions) => {
    try {
      Snackbar.show({
        type: options.type ?? 'info',
        text: [options.title, options.message].filter(Boolean).join('\n'),
        onClick: options.onClick
          ? () => {
              window.focus()
              options.onClick?.()
            }
          : undefined,
        showTime: NOTIFICATION_TIMEOUT
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [])

  return {
    showSnackbarNotification
  }
}
