import cn from 'classnames'
import React, {type FC} from 'react'

import styles from './Chip.module.css'

type ChipProps = {
  className?: string
  checked?: boolean
  disabled?: boolean
  name?: string
  value: string | number
  onChange?: () => void
  children?: React.ReactNode
}

export const Chip: FC<ChipProps> = ({className, checked, children, disabled, name, value, onChange}) => {
  const keyId = `${name}-${value}`

  return (
    <div
      data-testid={`ui-chip-${value}` /* UI | Chip. value - выбираемое значение*/}
      className={cn(
        styles.root,
        {
          [styles.disabled]: disabled
        },
        className
      )}
    >
      <input className={styles.input} id={keyId} value={value} checked={checked} type='checkbox' onChange={onChange} />
      <label className={styles.label} htmlFor={keyId}>
        {children}
      </label>
    </div>
  )
}
