import {useI18n} from '@eda-restapp/i18n'
import React from 'react'
import type {FC} from 'react'

import {MultiChatStatusBadge} from '../MultiChatStatusBadge/MultiChatStatusBadge'

import {MultiChatInfoModalRow} from './components/MultiChatInfoRow/MultiChatInfoRow'
import styles from './MultiChatInfo.module.css'

const formatDate = (date: string) => {
  return new Date(date).toLocaleString()
}

export type MultiChatInfoProps = {
  status?: 'open' | 'solved' | 'pending'
  updatedAt?: string
  createdAt?: string
  ticketId?: string
  orderNr?: string
  authorEmail?: string
  placeName?: string
  placeAddress?: string
  topic?: string
}

export const MultiChatInfo: FC<MultiChatInfoProps> = ({
  status,
  updatedAt,
  createdAt,
  ticketId,
  orderNr,
  authorEmail,
  placeName,
  placeAddress,
  topic
}) => {
  const {t} = useI18n()

  return (
    <div className={styles.container}>
      {!!status && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.status', 'Статус')}
          value={<MultiChatStatusBadge status={status} />}
        />
      )}

      {!!updatedAt && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.updated_at', 'Обновлено')}
          value={formatDate(updatedAt)}
        />
      )}

      {!!createdAt && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.created_at', 'Создано')}
          value={formatDate(createdAt)}
        />
      )}

      {!!ticketId && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.appeal-id', 'ID обращения')}
          value={ticketId}
          withCopy
          withClamp
        />
      )}

      {!!orderNr && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.order-nr', 'Заказ №')}
          value={orderNr}
          withCopy
          withClamp
        />
      )}

      {!!topic && <MultiChatInfoModalRow title={t('main-chats.multi-chat-info-modal.topic', 'Тема')} value={topic} />}

      {!!authorEmail && (
        <MultiChatInfoModalRow title={t('main-chats.multi-chat-info-modal.author', 'Автор')} value={authorEmail} />
      )}

      {!!placeName && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.restaurant', 'Название ресторана')}
          value={placeName}
        />
      )}

      {!!placeAddress && (
        <MultiChatInfoModalRow
          title={t('main-chats.multi-chat-info-modal.restaurant_address', 'Адрес')}
          value={placeAddress}
        />
      )}
    </div>
  )
}
