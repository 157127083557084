import {makeStyles} from 'tss-react/mui'

export default makeStyles<void, 'contained' | 'containedPrimary'>({name: 'ButtonStyles'})((theme, props, classes) => {
  return {
    //#region MuiButton classes overrides
    root: {
      textTransform: 'initial',
      fontWeight: 500,
      fontSize: 14,
      height: 40,
      borderRadius: 12,
      padding: '0 16px',
      color: '#1F2021',

      '&:disabled': {
        color: '#999C9E'
      }
    },
    childrenSpan: {
      width: 'auto',
      all: 'inherit',
      display: 'contents'
    },
    sizeSmall: {
      '&&&': {
        height: 32,
        borderRadius: 8
      }
    },
    sizeLarge: {
      '&&&': {
        height: 56,
        fontSize: 18,
        fontWeight: 500,
        borderRadius: 16
      }
    },
    text: {
      '&:hover': {
        color: '#3F4042',
        background: 'transparent'
      }
    },
    contained: {
      backgroundColor: theme.palette.secondary.dark,

      '&:hover': {
        backgroundColor: '#E2E4E7'
      },

      '&:disabled': {
        backgroundColor: '#F7F9FA'
      }
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.light,

      '&:hover': {
        backgroundColor: '#F6D101'
      },

      '&:disabled': {
        backgroundColor: '#F7F9FA'
      }
    },
    //#endregion MuiButton classes overrides
    loadingClass: {
      [`&.${classes.contained}`]: {
        backgroundColor: theme.palette.secondary.dark
      },
      [`&.${classes.containedPrimary}`]: {
        backgroundColor: theme.palette.primary.light
      }
    },
    tooltip: {}
  }
})
