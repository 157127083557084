import {eventLogger} from '@eda-restapp/logger'
import type {FC, ReactNode} from 'react'
import {useQueryClient} from 'react-query'
import {useDispatch} from 'react-redux'
import {Navigate, useMatch, useNavigate} from 'react-router-dom'

import {invalidOauthToken, setPartnerId} from '../../../../slice'

import {PassportBinding} from './PassportBinding'

const BINDING_ROUTE = '/bind-passport'

type PassportBindingContainerProps = {
  children: ReactNode
}
export const PassportBindingContainer: FC<PassportBindingContainerProps> = ({children}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const passportBindingMatch = useMatch(BINDING_ROUTE)
  const queryClient = useQueryClient()

  if (passportBindingMatch) {
    const {secret, partnerId} = parseBindingParams(location.search)

    if (!secret) {
      return <Navigate to='/' replace />
    }

    return (
      <PassportBinding
        secret={secret}
        onBindingFinished={(isNewBind) => {
          if (partnerId) {
            dispatch(setPartnerId(partnerId))

            queryClient.removeQueries()
          }

          eventLogger({name: 'passport:successful_bind', additional: {isNewBind, partnerId}})

          navigate('/')
        }}
        onBindingFail={() => {
          eventLogger({name: 'passport:error_bind'})

          dispatch(invalidOauthToken())
          navigate('/')
        }}
      />
    )
  }

  return children
}

function parseBindingParams(locationSearch: string): {secret: string | null; partnerId: string | null} {
  const searchParams = new URLSearchParams(locationSearch)

  return {secret: searchParams.get('secret'), partnerId: searchParams.get('linked_partner')}
}
