import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import moment from 'moment-timezone'
import React, {type FC} from 'react'

import {convertTimeToMinutes} from '@restapp/shared/utils'
import {Option, Select} from '@restapp/shared-ui'

import styles from './CustomPeriodSelector.module.css'
import type {SelectChangeEvent} from '@mui/material'

type DayPeriodSet = {
  value: number
  text: string
}

type DatePeriodSet = {
  value: string
  text: string
}

type CustomPeriodSelectorProps = {
  dayPeriodSet: DayPeriodSet[]
  placeTimezone: string
  selectedDay?: number
  timePeriodSet: DatePeriodSet[]
  onSelectedDayChange: (value: number) => void
  onSelectedTimeChange: (value: string) => void
}

export const CustomPeriodSelector: FC<CustomPeriodSelectorProps> = ({
  dayPeriodSet,
  placeTimezone,
  selectedDay,
  timePeriodSet,
  onSelectedDayChange,
  onSelectedTimeChange
}) => {
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  const handleSelectedDayChange = (event: SelectChangeEvent<unknown>) => onSelectedDayChange(Number(event.target.value))

  const handleSelectedTimeChange = (event: SelectChangeEvent<unknown>) =>
    onSelectedTimeChange(event.target.value as string)

  return (
    <Box className={styles.root} flexDirection='column'>
      <Box mb='s'>
        <Typography variant='caption1'>
          {t('shared-ui.custom-period-selector.selector-label', 'Действует до:')}
        </Typography>
      </Box>
      <Box flexDirection={isMobile ? 'column' : undefined} gap='s'>
        <Select
          data-testid={'disable-reasons-date-selector' /*Свой период отключения ресторана | Селектор даты */}
          className={styles.dateSelect}
          fullWidth={isMobile}
          value={selectedDay}
          onChange={handleSelectedDayChange}
        >
          {dayPeriodSet.map((date) => (
            <Option
              data-testid={`date-option-${date.value}` /*Свой период отключения ресторана | Опции в селекторе дат */}
              key={date.value}
              value={date.value}
            >
              {date.text}
            </Option>
          ))}
        </Select>
        <Select
          data-testid={'disable-reasons-time-selector' /*Свой период отключения ресторана | Селектор времени */}
          MenuProps={{classes: {list: styles.timeSelectList}}}
          className={styles.timeSelect}
          fullWidth={isMobile}
          onChange={handleSelectedTimeChange}
        >
          {timePeriodSet.map((date: DatePeriodSet, index) => {
            const timeFromNowDiff =
              index < timePeriodSet.length - 1
                ? convertTimeToMinutes(timePeriodSet[index + 1].value) -
                  convertTimeToMinutes(moment().tz(placeTimezone).format('HH:mm'))
                : -1

            return (
              <Option
                data-testid={
                  `time-option-${date.value}` /*Свой период отключения ресторана | Опции в селекторе времени */
                }
                // Set the focus to the period that is the next closest to the current time (difference less than 30 min)
                autoFocus={timeFromNowDiff >= 0 && timeFromNowDiff < 30 && selectedDay === 0}
                key={date.value}
                value={date.value}
              >
                {date.text}
              </Option>
            )
          })}
        </Select>
      </Box>
    </Box>
  )
}
