import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Modal, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import type {PlaceBlock} from '@restapp/shared-api'
import {HelpLink} from '@restapp/shared-support'

import {BlockInfoElement} from './components/BlockInfoElement/BlockInfoElement'
import styles from './BlockDescriptionModal.module.css'

type BlockDescriptionModalProps = {
  blockInfo?: PlaceBlock
  isAutostopInfo?: boolean
  isBlockTypeInInfinityList: boolean
  isSupportButtonEnabled: boolean
  isSupportLinkEnabled: boolean
  onHelpButtonClick: () => void
  onModalClose: () => void
}

export const BlockDescriptionModal: FC<BlockDescriptionModalProps> = ({
  blockInfo,
  isAutostopInfo = false,
  isBlockTypeInInfinityList,
  isSupportButtonEnabled,
  isSupportLinkEnabled,
  onHelpButtonClick,
  onModalClose
}) => {
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  return (
    <Modal testId='place-block-description-modal' open onClose={onModalClose}>
      <Modal.Header
        title={
          isAutostopInfo
            ? t('core-legacy.block-description-modal.autostop-modal-header', 'Ресторан временно отключён')
            : t('core-legacy.block-description-modal.modal-header', 'Ресторан временно отключён сервисом')
        }
      />

      <Modal.Content>
        <Box flexDirection='column' gap='l'>
          {!!blockInfo?.reason_title && (
            <Typography className={styles.contentTitleText} lineHeight='normal' variant='body2'>
              {blockInfo?.reason_title}
              {isSupportLinkEnabled && (
                <>
                  {' '}
                  <HelpLink linkKey='core-legacy.block-description-modal-reason-title'>
                    {t('main-place-leaders.achievement-card-item.card-item-note-help-link-text', 'Справке')}
                  </HelpLink>
                </>
              )}
            </Typography>
          )}

          <Box flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 'm' : 'l'}>
            <Box flex={1} flexDirection='column' gap='m'>
              <BlockInfoElement blockInfo={blockInfo} blockInfoId='block-start-date' />
              <BlockInfoElement
                blockInfo={blockInfo}
                blockInfoId='block-end-date'
                isBlockTypeInInfinityList={isBlockTypeInInfinityList}
              />
            </Box>

            <Box flex={1} flexDirection='column' gap='m'>
              <BlockInfoElement blockInfo={blockInfo} blockInfoId='block-source' />
              <BlockInfoElement blockInfo={blockInfo} blockInfoId='block-reason' />
            </Box>
          </Box>
        </Box>
      </Modal.Content>

      <Modal.Footer>
        <Box flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 's' : 'm'}>
          {isSupportButtonEnabled && (
            <Button.Text fullWidth onClick={onHelpButtonClick} variant='secondary'>
              {t('core-legacy.block-description-modal.modal-footer-support-button-label', 'В Поддержку')}
            </Button.Text>
          )}

          <Button.Text fullWidth onClick={onModalClose}>
            {t('core-legacy.block-description-modal.modal-footer-action-button-label', 'Понятно')}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}
