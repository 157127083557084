import {SvgIcon} from '@mui/material'
import React from 'react'

interface Props {
  className: string
  arrowsColor?: string
}

export const TariffBadgeWhiteIcon: React.FC<Props> = ({className, arrowsColor}) => (
  <SvgIcon classes={{root: className}} viewBox={'0 0 16 16'}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.8385 7.27822L13.6184 5.88109L13.789 4.03137C13.8218 3.70341 13.5988 3.40168 13.2839 3.32953L11.4736 2.92285L10.529 1.32238C10.3585 1.04033 10.0108 0.922268 9.70254 1.05345L8.00368 1.78153L6.30483 1.05345C6.0031 0.922268 5.6489 1.04033 5.48492 1.32238L4.53382 2.91629L2.73001 3.32297C2.40861 3.39512 2.18559 3.69029 2.21839 4.01825L2.38893 5.87453L1.16234 7.27822C0.945886 7.52748 0.945886 7.8948 1.16234 8.14405L2.38893 9.54118L2.21839 11.3975C2.18559 11.7254 2.40861 12.0272 2.72345 12.0993L4.53382 12.5125L5.47836 14.1064C5.6489 14.3885 6.0031 14.5066 6.30483 14.3754L8.00368 13.6342L9.70254 14.3623C10.0043 14.4934 10.3585 14.3754 10.5225 14.0933L11.467 12.4994L13.2774 12.0862C13.5988 12.014 13.8152 11.7123 13.7824 11.3843L13.6119 9.53462L14.8319 8.13749C15.0549 7.8948 15.0549 7.52748 14.8385 7.27822Z'
        fill='white'
      />
      <g clipPath='url(#clip0_3271_91661)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.30456 5.38664C7.79531 5.3196 7.27816 5.42247 6.83333 5.67929C6.3885 5.93611 6.04085 6.33254 5.84428 6.80709C5.72626 7.09201 5.66667 7.39554 5.66667 7.70002C5.66666 7.88411 5.51742 8.03335 5.33333 8.03335C5.14923 8.03334 5 7.8841 5 7.70001C5 7.30856 5.07662 6.9183 5.22836 6.55197C5.48109 5.94183 5.92808 5.43214 6.5 5.10194C7.07193 4.77174 7.73683 4.63948 8.39158 4.72568C8.78471 4.77744 9.16099 4.90622 9.5 5.10194C9.65943 5.19398 9.71406 5.39785 9.62201 5.55728C9.52997 5.71671 9.3261 5.77134 9.16667 5.67929C8.90298 5.52705 8.61033 5.4269 8.30456 5.38664Z'
          fill={arrowsColor ?? '#000000'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.69544 10.0134C8.20469 10.0804 8.72184 9.97756 9.16667 9.72073C9.6115 9.46391 9.95915 9.06748 10.1557 8.59294C10.2737 8.30801 10.3333 8.00449 10.3333 7.70001C10.3333 7.51591 10.4826 7.36668 10.6667 7.36668C10.8508 7.36668 11 7.51592 11 7.70002C11 8.09146 10.9234 8.48172 10.7716 8.84806C10.5189 9.45819 10.0719 9.96788 9.5 10.2981C8.92807 10.6283 8.26317 10.7605 7.60842 10.6743C7.21529 10.6226 6.83901 10.4938 6.5 10.2981C6.34057 10.206 6.28594 10.0022 6.37799 9.84275C6.47003 9.68331 6.6739 9.62869 6.83333 9.72073C7.09702 9.87297 7.38967 9.97312 7.69544 10.0134Z'
          fill={arrowsColor ?? '#000000'}
        />
        <path
          d='M6.41768 7.36667H4.24928C4.14279 7.36667 4.07927 7.48535 4.13834 7.57396L5.22254 9.20026C5.27531 9.27942 5.39164 9.27942 5.44442 9.20026L6.52862 7.57396C6.58769 7.48535 6.52417 7.36667 6.41768 7.36667Z'
          fill={arrowsColor ?? '#000000'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.99962 7.66642C3.86671 7.46706 4.00963 7.20001 4.24923 7.20001H6.41763C6.65724 7.20001 6.80016 7.46706 6.66725 7.66642L5.58305 9.29272C5.4643 9.47084 5.20256 9.47084 5.08382 9.29272L3.99962 7.66642ZM4.31152 7.53335L5.33343 9.06622L6.35535 7.53335H4.31152Z'
          fill={arrowsColor ?? '#000000'}
        />
        <path
          d='M11.7507 8.03336H9.58229C9.4758 8.03336 9.41228 7.91467 9.47135 7.82606L10.5555 6.19977C10.6083 6.1206 10.7246 6.1206 10.7774 6.19977L11.8616 7.82606C11.9207 7.91467 11.8572 8.03336 11.7507 8.03336Z'
          fill={arrowsColor ?? '#000000'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.33263 7.7336C9.19972 7.93297 9.34263 8.20001 9.58224 8.20001H11.7506C11.9902 8.20001 12.1332 7.93297 12.0003 7.7336L10.9161 6.10731C10.7973 5.92919 10.5356 5.92919 10.4168 6.10731L9.33263 7.7336ZM9.64453 7.86668L10.6664 6.33381L11.6884 7.86668H9.64453Z'
          fill={arrowsColor ?? '#000000'}
        />
      </g>
      <defs>
        <clipPath id='clip0_3271_91661'>
          <rect width='8' height='8' fill='white' transform='translate(4 3.70001)' />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
