import {isNaN} from 'lodash-es'

import {useApiQuery} from '@restapp/core-api'

type PlaceCookingTimeConfig = {
  enabled?: boolean
}

export const usePlaceCookingTime = (placeId: number, config?: PlaceCookingTimeConfig) =>
  useApiQuery({
    url: '/4.0/restapp-front/places/v1/preparation-time',
    method: 'GET',
    params: {
      id: placeId
    },
    enabled: config?.enabled && !isNaN(placeId),
    select: (data) => data.payload
  })
