import {makeStyles} from 'tss-react/mui'

import dayPickerStyle from '@restapp/shared-ui/DatePicker/style'

export default makeStyles({name: 'DatePeriodModal'})((theme) => ({
  root: {
    padding: 0,
    background: '#ffffff',
    borderRadius: 0,
    minWidth: 250,
    maxWidth: 440,
    overflow: 'hidden',
    overflowY: 'auto',
    textAlign: 'center',
    height: '100%',
    maxHeight: '100dvh',

    [theme.breakpoints.up('md')]: {
      height: '90%',
      maxHeight: 700,
      borderRadius: 4
    }
  },
  buttonContainer: {
    padding: '12px 16px',
    gap: 20,
    width: '100%',
    boxShadow: '0px 6px 11px rgba(0, 0, 0, 0.3)',

    '& > button': {
      width: '100%'
    }
  },
  dayPicker: {
    flex: '1 0 auto',
    width: '100%',
    overflowY: 'auto',
    height: '83%',
    ...dayPickerStyle
  }
}))
