import {eventLogger} from '@eda-restapp/logger'
import moment from 'moment-timezone'
import React, {type FC, useCallback, useEffect, useState} from 'react'

import type {FullRestaurant, OldFullRestaurant, PlaceDisablingReasonCode} from '@restapp/shared-api'
import {useDisablePlace} from '@restapp/shared-places'

import {PlaceDisableReason, Timers} from '../../DisablePlaceModal.constants'
import type {PlaceDisableParams} from '../../DisablePlaceModal.types'
import {calculateAvailableAt} from '../../utils'

import {validateForm} from './components/helpers/validateForm'
import {usePauseTimeErrorMessage} from './components/hooks/usePauseTimeErrorMessage'
import {DisablePlaceWithoutOrdersModal} from './DisablePlaceWithoutOrdersModal'

export type DisablePlaceWithoutOrdersModalContainerProps = {
  disableReasons?: PlaceDisableParams[]
  place: FullRestaurant | OldFullRestaurant
  placeTimezone?: string
  onCloseClick(): void
}

export const DisablePlaceWithoutOrdersModalContainer: FC<DisablePlaceWithoutOrdersModalContainerProps> = ({
  disableReasons,
  place,
  placeTimezone = moment.tz.guess(),
  onCloseClick
}) => {
  const [reasonCode, setReasonCode] = useState<PlaceDisablingReasonCode | null>(null)
  const [timeId, setTimeId] = useState<Timers | null>(null)
  const [customMinutes, setCustomMinutes] = useState<moment.Moment | null>(null)

  const placeCurrentTime = moment().tz(placeTimezone)

  const ProblemSchemes = {
    [PlaceDisableReason.NOT_KEEP_UP]: () => placeCurrentTime.endOf('day').add(1, 'minutes'),
    [PlaceDisableReason.EVENT]: () => placeCurrentTime.add(3, 'days').add(1, 'minutes'),
    [PlaceDisableReason.OTHER]: () => placeCurrentTime.add(7, 'days').add(1, 'minutes')
  }

  const availableAt = calculateAvailableAt({
    customDisableDuration: customMinutes,
    chipsDisableDuration: timeId,
    placeCurrentTime: moment().tz(placeTimezone)
  })
  const maxTime = reasonCode ? ProblemSchemes[reasonCode]?.().format() : undefined
  const errorMessage = usePauseTimeErrorMessage(availableAt ? availableAt.format() : null, maxTime, reasonCode)

  const {isLoading: isPlaceDisabling, disablePlace} = useDisablePlace(place.id, {
    onSuccess: () => {
      eventLogger({
        name: 'place_status',
        additional: {eventData: {state: 'disable', place_id: place.id, name: place.name}}
      })
    },
    onSettled: () => {
      onCloseClick()
    }
  })

  const isValidSettings = !!reasonCode && validateForm(availableAt, maxTime, reasonCode, timeId)
  const isSomeDisablePeriodSettingsExist = disableReasons?.find((disableParams) => disableParams.durations.length)
  const isNotSelectedAllDisableSettings =
    !reasonCode || (isSomeDisablePeriodSettingsExist && !timeId) || (timeId === Timers.OTHER && !customMinutes)

  const handleReasonChange = useCallback((reasonCode: PlaceDisablingReasonCode) => {
    setReasonCode(reasonCode)
    setTimeId(null)
  }, [])

  useEffect(() => {
    if (timeId !== Timers.OTHER && customMinutes) {
      setCustomMinutes(null)
    }
  }, [customMinutes, timeId, setCustomMinutes])

  const handleCustomTimePickerChange = useCallback((customDate: moment.Moment) => {
    setCustomMinutes(customDate)
  }, [])

  const handleDisablePlace = () => {
    if (!availableAt || !reasonCode) {
      return
    }

    disablePlace({
      availableAt: moment.tz(availableAt, placeTimezone ?? moment.tz.guess()),
      cancelOrders: false,
      reasonCode
    })
  }

  return (
    <DisablePlaceWithoutOrdersModal
      disableReasons={disableReasons}
      errorMessage={errorMessage}
      isNotSelectedAllDisableSettings={isNotSelectedAllDisableSettings}
      isPlaceDisabling={isPlaceDisabling}
      isValidSettings={isValidSettings}
      placeAddress={place.address}
      placeName={place.name}
      placeTimezone={placeTimezone}
      placeType={place.type}
      reasonCode={reasonCode}
      timeId={timeId}
      handleCustomTimePickerChange={handleCustomTimePickerChange}
      handleDisablePeriodDurationChange={setTimeId}
      onChangeDisableReasonCodeClick={handleReasonChange}
      onCloseClick={onCloseClick}
      onDisableButtonClick={handleDisablePlace}
    />
  )
}
