import {SUPPORTED_LANGUAGES} from '@eda-restapp/i18n'

/** This func parses the selected language for the language with which the support works */
export const getQuerySearchLang = (lang: SUPPORTED_LANGUAGES) => {
  switch (lang) {
    case SUPPORTED_LANGUAGES['ES-LA']: {
      return 'es'
    }

    default:
      return lang
  }
}
