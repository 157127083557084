import {getConfigsStore} from '@eda-restapp/configs'
import {Observable} from 'rxjs'

type ConfigsStore = ReturnType<typeof getConfigsStore>
type Configs = ReturnType<ConfigsStore['getConfigs']>

export function fromConfigs<T>(keySelector: (configs: Configs) => T): Observable<T> {
  const configsStore = getConfigsStore()

  return new Observable<T>((observer) => {
    observer.next(keySelector(configsStore.getConfigs()))

    configsStore.events.subscribe({
      name: 'change',
      callback: (payload) => {
        observer.next(keySelector(payload.configs))
      }
    })
  })
}
