import type {Theme} from '@mui/material/styles'
import {makeStyles} from 'tss-react/mui'

type PageContentClasses = 'root' | 'rootWithPlaceholder' | 'rootPadding'

export type PageContentStylesProps = {
  classes?: Partial<Record<PageContentClasses, string>>
  disablePadding?: boolean
}

export const usePageContentStyles = makeStyles({name: 'PageContent'})((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      minHeight: 'fit-content'
    },
    rootPadding: {
      padding: '0 16px',

      [theme.breakpoints.up('md')]: {
        padding: '0 24px'
      }
    },
    rootWithPlaceholder: {
      height: '100%',
      display: 'flex'
    }
  }
})
