import type {ComponentType} from 'react'
import React, {Component} from 'react'
import {wrapDisplayName} from 'recompose'
import hoistNonReactStatics from 'hoist-non-react-statics'
import PropTypes from 'prop-types'

export type WithIdProps = {
  provideId(type?: IdTypes, cached?: boolean): number
  resetId(type?: IdTypes): void
  provideDOMId(type?: IdTypes, cached?: boolean): string
}

export enum IdTypes {
  Default,
  Portals
}

const contextTypes = {
  ['EdaAppProvideId']: PropTypes.func,
  ['EdaAppResetId']: PropTypes.func
}

// Еще одна лабуда для withPortals, надо выпилить. https://st.yandex-team.ru/EDARESTAPP-7651
export function withId<P>(OriginalComponent: ComponentType<P & WithIdProps>): ComponentType<P> {
  class WrappedComponent extends Component<P> {
    static displayName = wrapDisplayName(OriginalComponent, 'withId')
    static contextTypes = contextTypes
    private cache: {
      [k in IdTypes]?: number
    } = {}

    provideDOMId = (type: IdTypes = IdTypes.Default, cached = true) => 'id_' + this.provideId(type, cached)

    provideId = (type: IdTypes = IdTypes.Default, cached = false): number => {
      if (!cached) {
        // tslint:disable-next-line
        // @ts-ignore
        return this.context['EdaAppProvideId'](type)
      }

      if (this.cache[type] === undefined) {
        // tslint:disable-next-line
        // @ts-ignore
        this.cache[type] = this.context['EdaAppProvideId'](type)
      }

      return this.cache[type]!
    }

    resetId = (type: IdTypes = IdTypes.Default) => {
      // tslint:disable-next-line
      // @ts-ignore
      this.context['EdaAppResetId'](type)
      delete this.cache[type]
    }

    render() {
      return (
        <OriginalComponent
          {...this.props}
          provideId={this.provideId}
          resetId={this.resetId}
          provideDOMId={this.provideDOMId}
        />
      )
    }
  }

  hoistNonReactStatics(WrappedComponent, OriginalComponent)

  return WrappedComponent
}

export function withIdProvider<P extends {}>(OriginalComponent: ComponentType<P>): ComponentType<P> {
  class DOMElementIdProvider extends Component<P> {
    static displayName = wrapDisplayName(OriginalComponent, 'withIdProvider')
    static childContextTypes = contextTypes
    private counters: {
      [k in IdTypes]?: number
    } = {}

    getChildContext() {
      return {
        ['EdaAppProvideId']: this.provideId,
        ['EdaAppResetId']: this.resetId
      }
    }

    provideId = (type: IdTypes) => {
      if (this.counters[type] === undefined) {
        this.counters[type] = 0
      }

      return ++this.counters[type]!
    }

    resetId = (type: IdTypes) => {
      this.counters[type] = 0
    }

    render() {
      return <OriginalComponent {...this.props} />
    }
  }

  hoistNonReactStatics(DOMElementIdProvider, OriginalComponent)

  return DOMElementIdProvider
}

export const DOMElementIdProvider = withIdProvider(({children}: {children: React.ReactNode}) => <>{children}</>)
