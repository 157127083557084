import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {DeviceInfo} from '@restapp/core-native'

interface INativeState {
  deviceInfo: DeviceInfo | null
}

const initialState: INativeState = {
  deviceInfo: null
}

export const nativeSlice = createSlice({
  name: 'native',
  initialState,
  reducers: {
    setDeviceInfo(state, action: PayloadAction<DeviceInfo>) {
      state.deviceInfo = action.payload
    }
  }
})

export const {setDeviceInfo} = nativeSlice.actions
