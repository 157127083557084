import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

type MutationBody =
  VendorApi['/4.0/restapp-front/api/v1/client/restaurants/:id/update-preparation-time']['POST']['body']

export const useUpdatePreparationTime = (placeId: number) => {
  const {mutate, isLoading, isError} = useApiMutation(
    {
      url: `/4.0/restapp-front/api/v1/client/restaurants/${placeId}/update-preparation-time` as '/4.0/restapp-front/api/v1/client/restaurants/:id/update-preparation-time',
      method: 'POST'
    },
    {
      affectedQueryKeys: [['/4.0/restapp-front/places/v1/preparation-time', 'GET' as never]]
    }
  )

  return {
    isLoading: isLoading,
    isError,
    updatePrepTime: (data: MutationBody, onSuccess?: () => void) =>
      mutate(
        {
          body: {
            isInfinite: data.isInfinite,
            minutes: data.minutes,
            validUntil: data.minutes < 1 ? null : data.validUntil
          }
        },
        {onSuccess}
      )
  }
}
