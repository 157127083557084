import React, {type FC} from 'react'
import cn from 'classnames'
import {HelpLink} from '@restapp/shared-support'

import {withStyles} from 'tss-react/mui'

type PlaceEnabledHelpIconProps = {
  className?: string
  classes?: Partial<Record<keyof typeof styles, string>>
  size?: 's' | 'm'
  onClick?: () => void
}

const PlaceEnabledHelpIcon: FC<PlaceEnabledHelpIconProps> = ({classes: c = {}, size, onClick}) => {
  return (
    <HelpLink linkKey='legacy.self-reg' onClick={onClick} className={cn(c.root, size === 'm' && c.medium)}>
      <p>?</p>
    </HelpLink>
  )
}

const styles = {
  root: {
    color: '#000000',
    minWidth: 32,
    minHeight: 32,
    width: 32,
    height: 32,
    borderRadius: 100,
    backgroundColor: '#E2E4E7',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 16,

    '& p': {
      alignSelf: 'center',
      margin: 0
    }
  },
  medium: {
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40
  }
} as const

export default withStyles(PlaceEnabledHelpIcon, styles, {name: 'RestaurantHelpLink'})
