import {useI18n} from '@eda-restapp/i18n'
import {errorLogger} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import React from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useQueryState} from 'react-router-use-location-state'

import {useApiQuery} from '@restapp/core-api'
import {selectPartnerId} from '@restapp/core-auth/selectors'
import {Spinner} from '@restapp/shared-ui'

import {usePassportName} from '../../hooks'

import {SelectPartnerUid} from './SelectPartnerUid'

type SelectPartnerUidContainerProps = {
  onSelectPartnerUid: (partnerUid: string) => void
}

export const SelectPartnerUidContainer: React.FC<SelectPartnerUidContainerProps> = ({onSelectPartnerUid}) => {
  const {t} = useI18n()
  const navigate = useNavigate()
  const partnerId = useSelector(selectPartnerId)
  const passportName = usePassportName()
  const [partnerIdInQuery, setPartnerIdInQuery] = useQueryState('partnerId', '')
  const [retpathQuery] = useQueryState('retpath', '/')

  const availablePartners = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/available-partners',
    method: 'GET',
    onSuccess: ({partners}) => {
      const partnerIdFromQueryIsValid =
        !!partnerIdInQuery && partners.some((partner) => partner.partner_uuid === partnerIdInQuery)

      if (partnerIdFromQueryIsValid) {
        selectPartnerUidWithRetpathRedirect(partnerIdInQuery)

        return
      } else {
        setPartnerIdInQuery('')
      }

      if (partners.length === 1) {
        selectPartnerUidWithRetpathRedirect(partners[0].partner_uuid)
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 60_000 * 10
  }).data

  const selectPartnerUidWithRetpathRedirect = (partnerUid: string) => {
    onSelectPartnerUid(partnerUid)
    goToRetpath()
  }

  const goToRetpath = () => {
    navigate(retpathQuery)
  }

  const onLogout = () => {
    navigate('/logout')
  }

  if (!availablePartners) {
    return (
      <Spinner absolute caption={t('core-auth.passport.select-partner-uid', 'Загружаем список личных кабинетов')} />
    )
  }

  if (availablePartners.partners.length === 0) {
    errorLogger({
      level: 'error',
      error: new Error('passport:no_binded_account'),
      message: 'passport:no_binded_accounts',
      additional: {eventSlug: 'passport:no_binded_accounts'}
    })

    Snackbar.error({
      text: t(
        'core-auth.passport.select-partner-uid.account-not-found',
        'Аккаунт не найден. Войдите с помощью электронной почты или используйте другой {passportName}',
        {passportName}
      )
    })
    onLogout()
  }

  return (
    <SelectPartnerUid
      availablePartners={availablePartners.partners}
      showBackButton={!!partnerId}
      showLogoutButton={!partnerId}
      onBackButtonClick={goToRetpath}
      onLogoutButtonClick={onLogout}
      onPartnerUidSelect={selectPartnerUidWithRetpathRedirect}
    />
  )
}
