import type {PaymentJson} from './types'

export const parseDirectData = (data: unknown): PaymentJson | Error => {
  try {
    if (typeof data === 'string') {
      if (data === 'payment:complete' || data === 'payment:fail') {
        return {type: 'payment-complete', source: 'YandexTrustPaymentForm'}
      }
      if (data === 'payment:loaded') {
        return {type: 'sr'}
      }
      return JSON.parse(data) as PaymentJson
    }

    return data as PaymentJson
  } catch (error) {
    return new Error('Error parsing direct message', {cause: error})
  }
}
