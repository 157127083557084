import {useOnClickOutside} from '@eda-restapp/hooks'
import type {FC, CSSProperties, ReactNode, FunctionComponentElement, RefCallback} from 'react'
import React, {cloneElement, useState} from 'react'
import type {PopperChildrenProps} from 'react-popper'
import {usePopper} from 'react-popper'

import {TooltipBase} from './TooltipBase'

type TooltipBaseContainerProps = {
  opened?: boolean
  children?: FunctionComponentElement<{ref: RefCallback<HTMLElement>; style?: CSSProperties}>
  close: () => void
  tooltipBody: ReactNode
  className?: string
  placement?: PopperChildrenProps['placement']
  offset?: [number | null | undefined, number | null | undefined]
  disabled?: boolean
}

export const TooltipBaseContainer: FC<TooltipBaseContainerProps> = ({
  children,
  tooltipBody,
  opened,
  className,
  placement = 'top',
  offset = [0, 16],
  close,
  disabled
}) => {
  const [hint, setHint] = useState<HTMLDivElement | null>(null)
  const [ref, setRef] = useState<HTMLElement | null>(null)

  useOnClickOutside({current: hint}, () => {
    close()
  })

  const popper = usePopper(ref, hint, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset
        }
      }
    ]
  })

  return (
    <>
      {children && cloneElement(children, {ref: setRef})}
      {opened && !disabled && (
        <TooltipBase
          ref={setHint}
          {...popper.attributes.popper}
          close={close}
          style={popper.styles.popper}
          className={className}
        >
          {tooltipBody}
        </TooltipBase>
      )}
    </>
  )
}
