import {Typography} from '@mui/material'
import React from 'react'

import type {SurveyContentPage} from '../../../types'

import SurveyAnswer from './SurveyAnswer'
import {useSurveyPageStyles} from './SurveyPage.style'

interface SurveyPageProps {
  page: SurveyContentPage
}

function SurveyPage({page}: SurveyPageProps) {
  const {classes: c} = useSurveyPageStyles()

  return (
    <>
      {page.questions.map((question) => (
        <div className={c.root} key={question.question}>
          <Typography
            className={c.question}
            style={{
              fontSize: question.question_font,
              fontWeight: question.question_is_bold ? 'bold' : undefined
            }}
          >
            {question.question}
          </Typography>
          <p
            className={c.description}
            style={{
              fontSize: question.description_font,
              fontWeight: question.description_is_bold ? 'bold' : undefined
            }}
          >
            {question.description}
          </p>
          <SurveyAnswer answer={question.answer} />
        </div>
      ))}
    </>
  )
}

export default SurveyPage
