import {useExp3} from '@eda-restapp/configs'
import {Box} from '@eda-restapp/ui'
import moment from 'moment'
import React from 'react'

import type {Service} from '@restapp/shared-api'

import PlusConditions from '../../PlusConditions'

export interface EnablePlusConditionsProps {
  services: Service[]
  commission: number
}

const EnablePlusConditions: React.FC<EnablePlusConditionsProps> = ({services, commission}) => {
  const {plusEnableTimeInHours} = useExp3('restapp_plus')

  const startDate = moment().add(plusEnableTimeInHours, 'hours')

  return (
    <Box data-testid={'plus-conditions-block' /* Плюс | Блок с условиями подключения Плюса */} flexDirection='column'>
      {services.length > 1 ? (
        services.map((service, i) => (
          <PlusConditions
            data-testid={`plus-conditions-${service}` /* Плюс | Условия в сервисе */}
            key={service}
            service={service}
            commission={String(commission)}
            startDate={startDate}
            mt={i > 0 ? 'm' : undefined}
          />
        ))
      ) : (
        <PlusConditions
          service={services[0]}
          data-testid={`plus-conditions-${services[0]}` /* Плюс | Условия в сервисе */}
          commission={String(commission)}
          startDate={startDate}
        />
      )}
    </Box>
  )
}

export default EnablePlusConditions
