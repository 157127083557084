import {useApiQuery} from '@restapp/core-api'

export const useServiceSchedule = (placeId: number) =>
  useApiQuery({
    url: '/4.0/restapp-front/places/v2/service-schedule',
    method: 'GET',
    params: {
      place_id: placeId
    }
  })
