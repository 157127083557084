import {useExp3} from '@eda-restapp/configs'
import {getHrefWithTarget} from '@eda-restapp/ui'
import React, {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {useApiQuery} from '@restapp/core-api'

import {GlobalAlert} from '@restapp/shared/components'
import {useDispatch, useSelector} from 'react-redux'
import {acceptUpdate, selectUpdateAvailable} from '@restapp/bootstrap/serviceSlice'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'

const DEFAULT_REPLAY_TIMEOUT_MINUTES = 30
const ONE_MINUTE_IN_MS = 60_000

const EXTERNAL_LINK_TARGET = '_blank'

export const GlobalAlertContainer: React.FC = () => {
  const {t} = useI18n()
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const isAppUpdateAvailable = useSelector(selectUpdateAvailable)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [currentSearchParams, setCurrentSearchParams] = useSearchParams()

  const restappAlertConfig = useExp3('restapp_alert')

  const {data, failureCount, isSuccess} = useApiQuery({
    url: '/4.0/restapp-front/communications/v1/alerts',
    method: 'GET',
    refetchOnWindowFocus: false
  })

  const alerts = failureCount >= 1 && !isSuccess ? restappAlertConfig.alerts : data?.alerts
  const currentShowingAlert = isVisible && alerts?.[0]

  if (isAppUpdateAvailable) {
    return (
      <GlobalAlert
        backgroundColor='black'
        textColor='white'
        text={t('core-legacy.global-alert.app-outdated', 'Ваша версия приложения устарела')}
        button={
          <GlobalAlert.Button
            background='white'
            text={t('core-legacy.global-alert.update-app', 'Обновить')}
            textColor='black'
            onClick={() => dispatch(acceptUpdate())}
          />
        }
      />
    )
  }

  if (!currentShowingAlert) {
    return null
  }

  const handleClick = () => {
    if (!currentShowingAlert.button) {
      return
    }

    const {action_type, action_data} = currentShowingAlert.button

    if (action_type === 'link') {
      navigate(action_data)
    }

    if (action_type === 'external_link') {
      window.open(getHrefWithTarget(action_data, EXTERNAL_LINK_TARGET), EXTERNAL_LINK_TARGET)?.focus()
    }

    if (action_type === 'update_query') {
      const searchParams = new URLSearchParams(currentSearchParams)

      new URLSearchParams(action_data).forEach((val, key) => {
        searchParams.set(key, val)
      })

      setCurrentSearchParams(searchParams)
    }

    eventLogger({
      name: 'passport:global_alert_button_clicked',
      additional: {
        alertId: currentShowingAlert.id
      }
    })
  }

  const getReplayTimeoutInMs = () => {
    if (currentShowingAlert?.replay_timeout_minutes && currentShowingAlert?.replay_timeout_minutes >= 0) {
      return currentShowingAlert.replay_timeout_minutes * ONE_MINUTE_IN_MS
    } else {
      return DEFAULT_REPLAY_TIMEOUT_MINUTES * ONE_MINUTE_IN_MS
    }
  }

  const handleClose = () => {
    setIsVisible(false)

    setTimeout(() => {
      setIsVisible(true)
    }, getReplayTimeoutInMs())
  }

  return (
    <GlobalAlert
      onClose={currentShowingAlert.can_close ? handleClose : undefined}
      backgroundColor={currentShowingAlert.background_color}
      text={currentShowingAlert.text}
      textColor={currentShowingAlert.text_color}
      textAlign={currentShowingAlert.text_align}
      crossButtonColor={currentShowingAlert.cross_button_color}
      crossButtonOpacity={currentShowingAlert.cross_button_opacity}
      button={
        !!currentShowingAlert.button && (
          <GlobalAlert.Button
            background={currentShowingAlert.button.background_color}
            text={currentShowingAlert.button.text}
            textColor={currentShowingAlert.button.text_color}
            iconSrc={currentShowingAlert.button.icon_url}
            onClick={handleClick}
          />
        )
      }
    />
  )
}
