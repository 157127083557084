import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'

export const usePlaceBlockFinishAtDateLabel = (blockFinishAt?: string) => {
  const {t} = useI18n()

  if (!blockFinishAt || moment(blockFinishAt).isBefore(moment())) {
    return ''
  }

  const currentDate = moment().format('YYYY-MM-DD')
  const nextDayDate = moment().add(1, 'days').format('YYYY-MM-DD')
  const checkingDate = moment(blockFinishAt).format('YYYY-MM-DD')

  if (moment(checkingDate).isSame(currentDate)) {
    return moment(blockFinishAt).format('HH:mm')
  }

  // Checking if place-block ends tomorrow
  if (moment(checkingDate).isSame(nextDayDate)) {
    return t('main-places.use-place-block-finish-at-label.tommorow-block-finish-label', 'завтра, {time}', {
      time: moment(blockFinishAt).format('HH:mm')
    })
  }

  return moment(blockFinishAt).format('D MMM, HH:mm')
}
