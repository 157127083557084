import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {ArrowLeftIcon, Box, Clamp, Drawer, InfoIcon, Skeleton, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import {type FC, lazy, useCallback, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation, useApiQueryInvalidate, useApiQuery} from '@restapp/core-api'
import {ErrorBoundary, Suspense} from '@restapp/shared-boundary'
import {startAppListening, useAppStore} from '@restapp/store'

import {SupportTreeContainer} from '../../../components/SupportTree'
import {chatsSlice} from '../../../slice/chatsSlice'
import {useBadgeOptionsByStatus} from '../../hooks/useBadgeOptionsByStatus'
import {messengerEventLogger} from '../../logger'
import {MultiChatInfoContainer} from '../MultiChatInfo/MultiChatInfo.container'
import {MultiChatInfoModal} from '../MultiChatInfoModal/MultiChatInfoModal'
import {MultiChatStatusBadge} from '../MultiChatStatusBadge/MultiChatStatusBadge'
import {SingleChatDropdown} from '../SingleChatDropdown'
import {SupportWidget} from '../SupportWidget'

import styles from './MultiChatDrawer.module.css'

const eventLogger = messengerEventLogger.namespace('multi_chat_drawer')

const Widget = lazy(() =>
  import(/* webpackChunkName: "@restapp/main-chats" */ '../MultiChatWidget').then((module) => {
    return {default: module.MultiChatWidget}
  })
)
export const MultiChatDrawer: FC = () => {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const invalidateList = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v2/chat/list', 'POST'])
  const invalidateNotificationCount = useApiQueryInvalidate([
    '/4.0/restapp-front/support_chat/v1/partner/notifications/count',
    'GET'
  ])
  const errorHandler = useErrorBoundaryHandler()
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()
  const dispatch = useDispatch()

  useEffect(
    () =>
      startAppListening({
        actionCreator: chatsSlice.actions.closeMultiChatDrawer,
        effect: () => {
          invalidateList.invalidate()
          invalidateNotificationCount.invalidate()
        }
      }),
    [invalidateList, invalidateNotificationCount]
  )

  const [infoModalOpened, setInfoModalOpened] = useState<boolean>(false)
  const opened = useSelector(chatsSlice.selectors.selectMultiChatDrawerState)
  const treePath = useSelector(chatsSlice.selectors.selectSupportTreePath)
  const canTravelBack = useSelector(chatsSlice.selectors.selectSupportTreeCanTravelBack)
  const chatId = useSelector(chatsSlice.selectors.selectMultiChatId)
  const ticketId = useSelector(chatsSlice.selectors.selectMultiChatTicketId)
  const createParams = useSelector(chatsSlice.selectors.selectMultiChatCreateParams)

  const leafId = treePath[treePath.length - 1] || supportMultiChatConfig.fallbackTopic
  const chatWidgetView = !!chatId || !!ticketId

  const chatsListQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/chat/list',
    method: 'POST',
    body: {
      filters: {
        ticket_id: ticketId
      }
    },
    select: (data) => {
      if (!data.chats[0]) {
        return null
      }

      return data.chats[0]
    },
    enabled: chatWidgetView && !!ticketId,
    refetchInterval: supportMultiChatConfig.chatInfoPolingRateMs
  })

  const createChatMutation = useApiMutation(
    {
      url: '/4.0/restapp-front/support_chat/v2/create_chat',
      method: 'POST'
    },
    {
      onSuccess: ({chat_id, ticket_id}) => {
        dispatch(chatsSlice.actions.openMultiChatDrawer({chatId: chat_id, ticketId: ticket_id}))
        invalidateList.invalidate()
      }
    }
  )

  const createChatWithFormsMutation = useApiMutation(
    {
      url: '/4.0/restapp-front/support_chat/v2/create_chat_with_forms',
      method: 'POST'
    },
    {
      onSuccess: ({chat_id, ticket_id}) => {
        dispatch(chatsSlice.actions.openMultiChatDrawer({chatId: chat_id, ticketId: ticket_id}))
        invalidateList.invalidate()
      }
    }
  )

  const createChatHandler = () => {
    if (leafId) {
      createChatMutation.mutate({
        body: {
          leaf_slug_id: leafId,
          order_nrs: createParams.orderId ? [createParams.orderId] : undefined,
          place_ids: createParams.placeId ? [createParams.placeId].map(String) : undefined
        }
      })
    }
  }

  const createChatWithFormsHandler = (answerKey: string) => {
    if (leafId) {
      createChatWithFormsMutation.mutate({
        body: {
          leaf_id: leafId,
          place_ids: createParams.placeId ? [createParams.placeId].map(String) : undefined,
          forms_answer_key: answerKey
        }
      })
    }
  }

  const showInfoButton = isMobile && !!ticketId && chatWidgetView

  return (
    <>
      <Drawer.Panel opened={opened} onClose={() => dispatch(chatsSlice.actions.closeMultiChatDrawer())}>
        <Drawer.Container className={cn(chatWidgetView && styles.drawerContainer)}>
          <Drawer.Header
            onClose={() => dispatch(chatsSlice.actions.closeMultiChatDrawer())}
            startIcon={
              (showInfoButton || canTravelBack) && (
                <DrawerStartIcon
                  showInfoButton={showInfoButton}
                  showBackButton={canTravelBack}
                  onInfoModalOpen={() => setInfoModalOpened(true)}
                  onTravelBack={() => dispatch(chatsSlice.actions.supportTreeBack())}
                />
              )
            }
            rounded={chatWidgetView && !isMobile}
            crossBtn
          >
            {chatsListQuery.isLoading && <Skeleton size='m' style={{maxWidth: '50%'}} />}
            {!chatsListQuery.isLoading && (
              <DrawerHeaderContent
                status={chatsListQuery.data?.status}
                topic={chatsListQuery.data?.topic || t('main-chats.messenger.multi-chat-header', 'Новое обращение')}
              />
            )}
          </Drawer.Header>

          {!!ticketId && chatWidgetView && !isMobile && (
            <SingleChatDropdown title={t('main-chats.multi-chat-drawer.info-about-appeal', 'Информация по обращению')}>
              <MultiChatInfoContainer ticketId={ticketId} />
            </SingleChatDropdown>
          )}

          <Drawer.Content className={cn(chatWidgetView && styles.drawerContent)}>
            <Box flexGrow={1} style={{position: 'relative'}}>
              <ErrorBoundary slug='messenger_multi_chat_error_boundary' onError={errorHandler}>
                <Suspense slug='messenger_multi_chat_error_suspense' onError={errorHandler}>
                  {chatWidgetView && (
                    <Widget chatId={chatId} ticketId={ticketId} readOnly={chatsListQuery.data?.status === 'solved'} />
                  )}

                  {!chatWidgetView && (
                    <Box px='s' pt={isMobile ? 'm' : undefined} flexGrow={1}>
                      <SupportTreeContainer
                        className={styles.supportTree}
                        path={treePath}
                        onChange={(id) => dispatch(chatsSlice.actions.supportTreeForward(id))}
                      >
                        {(widgets) => {
                          return (
                            <Box flexDirection='column' flexGrow={1} pb='m' px={isMobile ? 's' : 'm'}>
                              {widgets?.map((widget) => (
                                <SupportWidget
                                  key={widget.id}
                                  widget={widget}
                                  isNewChatCreating={createChatMutation.isLoading}
                                  isNewChatWithFormsCreating={createChatWithFormsMutation.isLoading}
                                  onNewChat={createChatHandler}
                                  onFormSent={createChatWithFormsHandler}
                                />
                              ))}
                            </Box>
                          )
                        }}
                      </SupportTreeContainer>
                    </Box>
                  )}
                </Suspense>
              </ErrorBoundary>
            </Box>
          </Drawer.Content>
        </Drawer.Container>
      </Drawer.Panel>

      {!!ticketId && chatWidgetView && isMobile && (
        <MultiChatInfoModal ticketId={ticketId} open={infoModalOpened} onClose={() => setInfoModalOpened(false)} />
      )}
    </>
  )
}

function useErrorBoundaryHandler() {
  const store = useAppStore()
  return useCallback(
    (error: unknown) => {
      eventLogger({
        name: 'redux_snapshot',
        value: String(error),
        additional: {
          chatsSliceSnapshot: chatsSlice.selectSlice(store.getState())
        }
      })
    },
    [store]
  )
}

export type DrawerHeaderContentProps = {
  status?: 'open' | 'solved' | 'pending'
  topic: string
}

export const DrawerHeaderContent: FC<DrawerHeaderContentProps> = ({status, topic}) => {
  const isMobile = useIsMobileStrict()

  const badgeOptions = useBadgeOptionsByStatus(status)

  return (
    <div className={styles.drawerHeaderContent}>
      <Typography variant={isMobile ? 'body2' : 'title4'} lineHeight={isMobile ? 'tight' : 'normal'} thickness='medium'>
        <Clamp style={{wordBreak: 'break-word'}}>{topic}</Clamp>
      </Typography>

      {isMobile && badgeOptions?.text && (
        <Typography variant='caption1' lineHeight='tight' thickness='regular' color='minor'>
          {badgeOptions.text}
        </Typography>
      )}

      {!isMobile && status && <MultiChatStatusBadge status={status} />}
    </div>
  )
}

export type DrawerStartIconProps = {
  showInfoButton?: boolean
  showBackButton?: boolean

  onInfoModalOpen: () => void
  onTravelBack: () => void
}

export const DrawerStartIcon: FC<DrawerStartIconProps> = ({
  showInfoButton,
  showBackButton,
  onInfoModalOpen,
  onTravelBack
}) => {
  if (showInfoButton) {
    return <InfoIcon className={styles.infoIcon} onClick={onInfoModalOpen} />
  }

  if (showBackButton) {
    return <ArrowLeftIcon onClick={onTravelBack} />
  }

  return null
}
