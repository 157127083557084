import {useMemo, useCallback} from 'react'

import {useApiQuery} from '@restapp/core-api'
import type {IPlaceContractInfo} from '@restapp/shared-api/types/places'

export const useContractPlaces = (placeIds: number[]) => {
  const places = useApiQuery({
    url: '/4.0/restapp-front/places/v1/contracts',
    method: 'GET',
    params: {
      place_ids: placeIds
    },
    enabled: !!placeIds.length
  }).data?.places

  const mapIdToPlace = useMemo(
    () =>
      places?.reduce((acc, nextPlace) => {
        acc.set(nextPlace.place_id, nextPlace.services)

        return acc
      }, new Map<number, IPlaceContractInfo['services']>()),
    [places]
  )

  return {
    places,
    placeById: useCallback((placeId: number) => mapIdToPlace?.get(placeId), [mapIdToPlace])
  }
}
