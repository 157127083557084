import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Button, Box, Typography, Modal, Multiline} from '@eda-restapp/ui'
import type {FC} from 'react'
import React, {useState} from 'react'

import QuizRadioGroup from './QuizRadioGroup'

type DisablePlusQuizProps = {
  opened: boolean
  onClose: () => void
  onSubmit: () => void
}

const MULTILINE_MAX_HEIGHT = 178
const MULTILINE_MIN_HEIGHT = 80

const DisablePlusQuiz: FC<DisablePlusQuizProps> = ({opened, onClose, onSubmit}) => {
  const {t} = useI18n()
  const [comment, setComment] = useState<string>('')
  const [value, setValue] = useState<string>()

  const handleChangeComment = (value: string) => setComment(value)

  const resetValues = () => {
    setValue(undefined)
    setComment('')
  }

  const handleSubmit = () => {
    metrika({
      target: 'disable-plus-subscribition',
      params: {
        comment,
        radioValue: value
      }
    })

    resetValues()
    onSubmit()
  }

  const handleClose = () => {
    resetValues()
    onClose()
  }

  return (
    <Modal open={opened} onClose={handleClose}>
      <Modal.Header title={t('shared-places.disable-plus-quiz.why-disable-plus', 'Почему решили отключить Плюс?')} />
      <Modal.Content>
        <Box flexDirection='column' gap='m'>
          <QuizRadioGroup onChange={setValue} value={value} />
          <Multiline.Minimal
            name={'comment'}
            value={comment}
            onChange={(evt) => handleChangeComment(evt.target.value)}
            placeholder={t('shared-places.disable-plus-quiz.share-what-was-wrong', 'Поделитесь, что было не так')}
            minHeight={MULTILINE_MIN_HEIGHT}
            maxHeight={MULTILINE_MAX_HEIGHT}
          />

          <Typography variant='body2'>
            {t(
              'shared-places.disable-plus-quiz.you-can-return-back-to-plus',
              'Вернуться в Плюс можно в разделах «Рестораны» и «Инструменты»'
            )}
          </Typography>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Button.Text disabled={!value} fullWidth onClick={handleSubmit}>
          {t('shared-places.disable-plus-quiz.send', 'Отправить')}
        </Button.Text>
      </Modal.Footer>
    </Modal>
  )
}

export default DisablePlusQuiz
