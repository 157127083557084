import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {
  setNormalizeCategories,
  removeCategory,
  resetAll,
  toggleCategory,
  enableAll,
  applyCurrentState
} from '../actions'
import {CATEGORIES_SLICE_NAME} from '../constants'
import {categoryEntityAdapter} from '../entities'
import type {CategoriesSliceState, CategoryType, Update} from '../types'

export const slice = createSlice({
  name: CATEGORIES_SLICE_NAME,
  initialState: (): CategoriesSliceState => {
    return {
      current: categoryEntityAdapter.getInitialState(),
      initial: categoryEntityAdapter.getInitialState()
    }
  },
  reducers: {
    create: (state, action: PayloadAction<CategoryType>) => {
      categoryEntityAdapter.upsertOne(state.current, action.payload)
    },

    update: (state, action: PayloadAction<Update<CategoryType>>) => {
      categoryEntityAdapter.updateOne(state.current, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setNormalizeCategories, (state, action) => {
      categoryEntityAdapter.removeAll(state.current)
      categoryEntityAdapter.removeAll(state.initial)

      const categoryEntity = action.payload.map((category) => {
        return {
          ...category,
          reactivatedAt: category.reactivatedAt ?? null
        }
      })

      categoryEntityAdapter.setAll(state.current, categoryEntity)
      categoryEntityAdapter.setAll(state.initial, categoryEntity)
    })

    builder.addCase(removeCategory, (state, action) => {
      categoryEntityAdapter.removeOne(state.current, action.payload)
    })

    builder.addCase(resetAll, (state) => {
      state.current = state.initial
    })

    builder.addCase(applyCurrentState, (state) => {
      state.initial = state.current
    })

    builder.addCase(toggleCategory, (state, action) => {
      categoryEntityAdapter.updateOne(state.current, {
        id: action.payload.id,
        changes: {
          available: action.payload.value,
          reactivatedAt: action.payload.reactivatedAd
        }
      })
    })

    builder.addCase(enableAll, (state) => {
      for (const category of Object.values(state.current.entities)) {
        if (category) {
          category.available = true
          category.reactivatedAt = null
        }
      }
    })
  }
})
