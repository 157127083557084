import {createRedirectPath} from '@eda-restapp/microfrontend'

import {getPromotionOauthCallbackPath} from '../../constants'

export const createOauthUrl = (
  oauthLink: {options: string[]; origin: string; path: string},
  redirectQueryParams?: {
    /**
     * Where to redirect after callback completed
     */
    inAppRedirect?: string
    /**
     * Basically allow for testing stands work
     */
    basename?: string
  }
): string => {
  const oauthUrl = new URL(oauthLink.path, oauthLink.origin)

  for (const option of oauthLink.options) {
    const [name, value] = option.split('=')

    if (name && value) {
      oauthUrl.searchParams.set(name, value)
    }
  }

  const redirectUriParams = new URLSearchParams()

  if (redirectQueryParams?.inAppRedirect) {
    redirectUriParams.set('inAppRedirect', redirectQueryParams.inAppRedirect)
  }

  if (redirectQueryParams?.basename) {
    redirectUriParams.set('basename', redirectQueryParams.basename)
  }

  // encodeURIComponent - for correct decode of internal query-params
  const promotionCallbackPathWithParams = `${getPromotionOauthCallbackPath()}?${encodeURIComponent(
    redirectUriParams.toString()
  )}`
  oauthUrl.searchParams.set('redirect_uri', createRedirectPath(promotionCallbackPathWithParams))

  return oauthUrl.toString()
}
