import {useExp3} from '@eda-restapp/configs'
import {type CountryCodeType, type SUPPORTED_LANGUAGES, useDomainDividedCountriesInfo} from '@eda-restapp/i18n'

import {useDefaultServiceBrandLanguage} from './useDefaultServiceBrandLanguage'
import {useServiceBrand} from './useServiceBrand'

export const useDefaultCountryLanguage = (countryCode?: CountryCodeType | null): SUPPORTED_LANGUAGES | null => {
  const commonConfig = useExp3('restapp_common')
  const serviceBrand = useServiceBrand()

  const defaultServiceBrandLanguage = useDefaultServiceBrandLanguage(serviceBrand)
  const domainDividedCountriesInfo = useDomainDividedCountriesInfo()

  if (!countryCode) {
    return null
  }

  const configDefaultCountryLanguage =
    commonConfig.configDefaultCountriesLanguages && commonConfig.configDefaultCountriesLanguages[countryCode]

  if (configDefaultCountryLanguage) {
    return configDefaultCountryLanguage as SUPPORTED_LANGUAGES
  }

  // Fallback on default serviceBrand-language if some mismatch
  return (
    domainDividedCountriesInfo[serviceBrand][countryCode]?.defaultCountryLanguageCode || defaultServiceBrandLanguage
  )
}
