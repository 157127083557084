import {useExp3} from '@eda-restapp/configs'
import {useCourierRateStore} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {Box, GwcSpinner} from '@eda-restapp/ui'
import type {FC} from 'react'
import {useState, useEffect, useMemo} from 'react'
import {useEvent} from 'react-use-event-hook'

import {useApiQuery} from '@restapp/core-api'
import {useOldHeavyPlaces} from '@restapp/core-places'

import {CourierFeedback} from './CourierFeedback'
import {useCourierFeedback} from './useCourierFeedback'
import {useCourierFeedbackBridge} from './useCourierFeedbackBridge'

const iframeId = 'courier-feedback-form'

const isFormMessage = (ev: MessageEvent) => {
  const frame = document.querySelector(`#${iframeId}`)
  return Boolean(frame && 'contentWindow' in frame && frame.contentWindow === ev.source)
}

export const CourierFeedbackContainer: FC = () => {
  useCourierFeedbackBridge()

  const {close, isOpen, orderNr} = useCourierFeedback()
  const [isLoading, setIsloading] = useState(true)

  const {lang} = useI18n()
  const ordersCourierRateConfig = useExp3('restapp_orders_courier_rate')

  const orderQuery = useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-orders/v1/order',
    method: 'GET',
    enabled: isOpen && !!orderNr,
    suspense: false,
    params: {order_nr: orderNr || ''}
  })

  const placeId = orderQuery.data?.place_id || 0

  const placesQuery = useOldHeavyPlaces({enabled: isOpen && !!placeId, suspense: false})

  const countryCode = placesQuery.getPlaceById(placeId)?.country_code

  const src = useMemo(() => {
    if (!ordersCourierRateConfig.formLink) {
      return 'about:blank'
    }

    let result = ordersCourierRateConfig.formLink

    result += '?iframe=1'
    result += `&lang=${lang}`

    if (ordersCourierRateConfig.orderNrFieldId && orderNr) {
      result += `&${ordersCourierRateConfig.orderNrFieldId}=${orderNr}`
    }

    if (ordersCourierRateConfig.countryFieldId && countryCode) {
      result += `&${ordersCourierRateConfig.countryFieldId}=${countryCode}`
    }

    return result
  }, [
    countryCode,
    ordersCourierRateConfig.countryFieldId,
    ordersCourierRateConfig.formLink,
    lang,
    orderNr,
    ordersCourierRateConfig.orderNrFieldId
  ])

  const timeoutLogger = useTimeoutLogger(orderNr || 'no-order-id', src)

  useEffect(() => {
    if (isOpen) {
      setIsloading(true)
      timeoutLogger.start()
    }
  }, [isOpen, timeoutLogger])

  const courierRateStore = useCourierRateStore()

  useEffect(() => {
    const cb = (ev: MessageEvent) => {
      try {
        if (isFormMessage(ev)) {
          if (ev.data === 'ping' && isLoading === true) {
            // Форма загрузилась
            setIsloading(false)
            timeoutLogger.stop()
          }

          if (typeof ev.data === 'string' && ev.data.startsWith('{')) {
            const data = JSON.parse(ev.data) as unknown

            if (data && typeof data === 'object' && 'message' in data && data.message === 'sent') {
              // Форма была отправлена
              orderNr && courierRateStore.save(orderNr)
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    window.addEventListener('message', cb)
    return () => window.removeEventListener('message', cb)
  }, [isLoading, timeoutLogger, courierRateStore, orderNr])

  // Задерживает обновление isOpen на 300ms если оно стало false
  // Нужно чтобы при закрытии модалки контент не пропадал резко
  const defferedIsOpen = useConditionalDefer(isOpen, isOpen === false, 300)

  if (!defferedIsOpen) {
    return null
  }

  return (
    <CourierFeedback isOpen={isOpen} onClose={close}>
      <Box flexDirection='column' flexGrow={1} alignItems='center' justifyContent='center'>
        {isLoading && <GwcSpinner />}

        <Box
          pt='m'
          pb='xl'
          px='m'
          flexGrow={1}
          flexShrink={0}
          style={{display: isLoading ? 'none' : undefined, width: '100%', height: '100%'}}
        >
          <iframe id={iframeId} name={iframeId} frameBorder={0} src={src} width='100%' height='100%' />
        </Box>
      </Box>
    </CourierFeedback>
  )
}

function useConditionalDefer<T>(value: T, defer: boolean, timeout: number) {
  const [state, setState] = useState(value)

  useEffect(() => {
    const capturedValue = value

    if (defer) {
      const id = setTimeout(() => {
        setState(capturedValue)
      }, timeout)

      return () => window.clearTimeout(id)
    }

    setState(capturedValue)
  }, [value, defer, timeout])

  return state
}

function useTimeoutLogger(orderNr: string, src: string) {
  const sendEvent = useEvent((type: 'error' | 'success') => {
    if (type === 'error') {
      errorLogger({
        level: 'error',
        error: new Error('courier-feedback-loading-timeout'),
        additional: {orderNr, src, eventSlug: 'courier-feedback-loading-error'}
      })
    } else {
      eventLogger({name: 'courier-feedback-loading-successfully', additional: {orderNr, src}})
    }
  })

  return useMemo(() => {
    let id = 0

    return {
      start: () => {
        id = window.setTimeout(() => {
          sendEvent('error')
        }, 3_000)
      },

      stop: () => {
        window.clearTimeout(id)
        sendEvent('success')
      }
    }
  }, [sendEvent])
}
