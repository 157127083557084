import {useI18n} from '@eda-restapp/i18n'
import {light, type Theme as GWCTheme, ThemeProvider} from '@eda-restapp/ui'
import styles from '@eda-restapp/ui/src/css/colors.module.css'
import {getLangDirection} from '@eda-restapp/utils'
import createCache from '@emotion/cache'
import {CacheProvider} from '@emotion/react'
import {type Theme as MuiTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import React, {type FC, useEffect, useMemo} from 'react'
import {TssCacheProvider} from 'tss-react'

import {theme} from '@restapp/shared-ui'

type ThemesProviderProps = {
  children: React.ReactNode
}

const muiCache = createCache({
  key: 'mui',
  prepend: true
})

const tssCache = createCache({
  key: 'tss'
})

const ThemesProvider: FC<ThemesProviderProps> = ({children}) => {
  const {lang} = useI18n()

  const {dir} = getLangDirection(lang)

  const themeMemo = useMemo(() => {
    return {
      UIKit: {...light, dir} as GWCTheme,
      MUI: {...theme, direction: dir} as MuiTheme
    }
  }, [dir])

  useEffect(() => {
    document.body.classList.add(styles.colorSchemeLight as string)
    return () => void document.body.classList.remove(styles.colorSchemeLight as string)
  }, [])

  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <MuiThemeProvider theme={themeMemo.MUI}>
          {/* <StyledEngineProvider injectFirst> */}
          <ThemeProvider theme={themeMemo.UIKit} isGlobal>
            {children}
          </ThemeProvider>
          {/* </StyledEngineProvider> */}
        </MuiThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  )
}

export default ThemesProvider
