import cn from 'classnames'
import {throttle} from 'lodash-es'
import type {FC, HTMLAttributes} from 'react'
import React, {useRef, useEffect} from 'react'
import {makeStyles} from 'tss-react/mui'

import {S} from '../../style'

type BodyModalProps = {
  withoutPadding?: boolean
  children: React.ReactNode
} & {
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
} & HTMLAttributes<HTMLDivElement>

const RESIZE_THROTTLE = 300

const BodyModal: FC<BodyModalProps> = ({classes, children, className, withoutPadding, ...htmlDivProps}) => {
  const {classes: c} = useStyles(undefined, {props: {classes}})
  const bodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const body = bodyRef.current
    const bodyNextSibling = body?.nextElementSibling as HTMLElement

    if (!body || !bodyNextSibling) {
      return
    }

    const updateShadow = () => {
      const hasScroll = body.scrollHeight > body.offsetHeight
      bodyNextSibling.style.boxShadow = hasScroll ? '0px -4px 20px rgba(117, 115, 111, 0.2)' : ''
    }

    const throttledCallback = throttle(updateShadow, RESIZE_THROTTLE)
    const resizeObserver = new ResizeObserver(throttledCallback)
    resizeObserver.observe(body)

    return () => {
      resizeObserver.disconnect()
      throttledCallback.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={bodyRef}
      className={cn(c.root, className, {
        [c.withoutPadding]: withoutPadding
      })}
      {...htmlDivProps}
    >
      {children}
    </div>
  )
}

const useStyles = makeStyles({name: 'BodyModal'})({
  root: {
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '0 40px 20px',

    [S.mobileQuery]: {
      padding: '16px 16px 20px'
    }
  },
  withoutPadding: {
    padding: 0
  }
})

export default BodyModal
