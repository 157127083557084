import {useSelector} from 'react-redux'

import {selectIsLoggedIn} from '../selectors'

export interface Result {
  isLoggedIn: boolean
}

export default function useAuth(): Result {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return {
    isLoggedIn
  }
}
