import type {FieldProps, FieldRenderProps} from 'react-final-form'
import {Field} from 'react-final-form'
import React from 'react'

const identity = (value: any): any => value
/* Гарантирует, что поле формы останется пустым, а не удалится
 *
 * См. https://github.com/final-form/react-final-form/issues/130
 */
export default function FieldNonEmpty<
  FieldValue = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<FieldValue>,
  T extends HTMLElement = HTMLElement
>(props: FieldProps<FieldValue, RP, T>): React.ReactElement {
  return <Field {...(props as any)} parse={identity} />
}
