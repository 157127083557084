import {createSelector} from '@reduxjs/toolkit'
import {isEqual} from 'lodash-es'

import {itemEntitySelectors} from '../entities'
import {selectMenu, addIdArg} from '../utils'

export const selectCurrentItems = createSelector(selectMenu, (state) => state.items.current)
export const selectInitialItems = createSelector(selectMenu, (state) => state.items.initial)

export const all = createSelector(selectCurrentItems, itemEntitySelectors.selectAll)
export const byId = createSelector([selectCurrentItems, addIdArg], itemEntitySelectors.selectById)
export const initialById = createSelector([selectInitialItems, addIdArg], itemEntitySelectors.selectById)
export const total = createSelector(selectCurrentItems, itemEntitySelectors.selectTotal)
export const isDirty = createSelector(
  [selectCurrentItems, selectInitialItems],
  (current, initial) =>
    // Эти проверки будут работать т.к optionEntitySelectors соблюдает сортировку
    !isEqual(itemEntitySelectors.selectAll(current), itemEntitySelectors.selectAll(initial))
)
