import {makeStyles} from 'tss-react/mui'

const useSubscriptionSuccessDialogStyles = makeStyles({name: 'SubscriptionSuccessDialog'})({
  root: {},

  textBlock: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1F2021',
    '& > * + *': {
      margin: 0,
      marginTop: '16px'
    },
    '& a': {
      color: '#0668BE'
    }
  },

  telegramButton: {
    '&&': {
      borderTop: '1px solid #DCDEE0',
      marginTop: 24,
      marginBottom: -16
    }
  }
})

export default useSubscriptionSuccessDialogStyles
