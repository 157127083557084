import {useI18n} from '@eda-restapp/i18n'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import {usePlusInfoStyles} from './PlusInfo.styles'

export interface PlusInfoProps {
  className?: string
  cashback: string
  startDate: moment.Moment
}

function PlusInfo({className, cashback, startDate}: PlusInfoProps) {
  const {t, tRaw} = useI18n()
  const {classes: c} = usePlusInfoStyles()

  const isFutureDate = startDate.isAfter(moment())

  return (
    <div className={cn(c.root, className)}>
      <div className={c.row}>
        {tRaw('main-places.plus-info.plus-cashback-text', '{cashbackLabel}{cashback}', {
          cashbackLabel: <div className={c.label}>{t('main-places.plus-info.keshbek-plyusa', 'Кешбэк Плюса:')}</div>,
          cashback: (
            <div className={c.value}>{t('main-places.plus-info.cashback-value-text', '{cashback}%', {cashback})}</div>
          )
        })}
      </div>
      <div className={c.row}>
        <div className={c.label}>
          {t('main-places.plus-info.start-of-plus-cashback-label-text', '<Начнет действовать>/<Действует> с:', {
            none: 'Действует с:',
            one: 'Начнет действовать с:',
            some: 'Начнет действовать с:',
            many: 'Начнет действовать с:',
            count: Number(isFutureDate)
          })}
        </div>
        <div className={c.value}>{startDate.format('DD.MM.YYYY')}</div>
      </div>
    </div>
  )
}

export default PlusInfo
