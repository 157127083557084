import type {ISurvey, ISurveyAnswerUnion, ISurveyQuestion} from '@restapp/shared-api'

import type {
  Survey,
  SurveyAnswerPredefined,
  SurveyAnswerUnion,
  SurveyContentPage,
  SurveyAnswerToApi,
  SurveyQuestion
} from '../types'
import {CommunicationType} from '../types'

export function checkIsVisiblePage(page: SurveyContentPage, allPages: SurveyContentPage[]) {
  const {dependencies} = page.answer
  if (!dependencies) {
    // Зависимостей от других вопросов нет, значит показываем
    return true
  }

  // Проверяем что на вопросы от которых зависит данный вопрос даны соответстующие ответы
  return dependencies.every((dependency) => {
    // Страница вопроса от которой зависит текущий вопрос
    const dependencyPage = allPages[dependency.answer_index]
    if (!dependencyPage) {
      // В админке указали неверный id вопроса
      throw new Error('Invalid dependency question index')
    }

    if (!checkIsVisiblePage(dependencyPage, allPages)) {
      // У этого вопроса есть свои зависимости и они не выполнены, значит он скрыт,
      // следовательно скрываем и текущий вопрос который от него зависит
      return false
    }

    // Рейтинг и текст не поддерживаем
    if (
      dependencyPage.answer.type === 'rating' ||
      dependencyPage.answer.type === 'text' ||
      dependencyPage.answer.type === 'emoji'
    ) {
      throw new Error('Not supported dependency answer type')
    }

    // Получаем индексы ответов пользователя на вопрос от которого зависим
    const userAnswerIndexes = getAnswerIndexes(dependencyPage.answer)

    // Если ответ есть в списке зависимостей то вопрос нужно показать.
    // Условие подходит как для вопросов с одним ответом, так и с множественным
    return userAnswerIndexes.some((answerIndex) => dependency.answers.includes(answerIndex))
  })
}

function getAnswerIndexes(answer: SurveyAnswerPredefined) {
  return answer.answers.reduce((selectedIndexes: number[], answer, i) => {
    if (answer.selected) {
      selectedIndexes.push(i)
    }
    return selectedIndexes
  }, [])
}

export function checkIsValidPage(page: SurveyContentPage) {
  return page.questions.every(
    (question) => !question.answer_required || checkAnswerCanSubmit(question.answer, question.answer_required)
  )
}

export function checkAnswerCanSubmit(answer: SurveyAnswerUnion, answerRequired?: boolean) {
  switch (answer.type) {
    case 'text':
      return Boolean(answer.text)
    case 'rating':
    case 'emoji':
      return Boolean(answer.rating)
    case 'multiple':
      if (answerRequired) {
        return answer.answers.some((answer) => answer.selected)
      }
      return true
    case 'single':
      return answer.answers.some((answer) => answer.selected)
  }
}

export function surveyCanSubmitAnswers(survey: Survey) {
  return survey.content.pages.every((page) => !checkIsVisiblePage(page, survey.content.pages) || checkIsValidPage(page))
}

export function getAnswer(answer: SurveyAnswerUnion): SurveyAnswerToApi | undefined {
  switch (answer.type) {
    case 'text':
      return {
        type: answer.type,
        answer: answer.text
      }
    case 'rating':
      if (answer.rating === null) {
        throw new Error('Rating could not be null')
      }
      return {
        type: answer.type,
        rating: answer.rating
      }
    case 'single':
    case 'multiple': {
      const selectedAnswers = answer.answers.filter((answer) => answer.selected)
      if (answer.type === 'single' && selectedAnswers.length === 0) {
        throw new Error('At least one answer must be selected')
      }

      return {
        type: answer.type,
        answers: selectedAnswers.map((answer) => answer.text)
      }
    }
  }
}

export function mapSurvey(survey: ISurvey): Survey {
  return {
    type: CommunicationType.SURVEY,
    id: survey.id,
    created: survey.created,
    status: survey.status,
    ...mapSurveyPayload(survey.payload)
  } as Survey
}

function mapSurveyPayload(payload: ISurvey['payload']) {
  return {
    ...payload,
    content: {
      content_type: payload.content.content_type,
      pages: payload.content.pages.map((page, pageIndex) => ({
        ...page,
        questions: page.questions.map((question, questionIndex) =>
          mapSurveyQuestion({
            question,
            pageIndex,
            questionIndex
          })
        )
      }))
    }
  }
}

function mapSurveyQuestion({
  question,
  questionIndex,
  pageIndex
}: {
  question: ISurveyQuestion
  pageIndex: number
  questionIndex: number
}): SurveyQuestion {
  return {
    ...question,
    answer: mapSurveyAnswer({answer: question.answer, pageIndex, questionIndex})
  }
}

function mapSurveyAnswer({
  answer,
  pageIndex,
  questionIndex
}: {
  answer: ISurveyAnswerUnion
  pageIndex: number
  questionIndex: number
}): SurveyAnswerUnion {
  switch (answer.type) {
    case 'text':
      return {
        ...answer,
        pageIndex,
        questionIndex,
        text: ''
      }
    case 'rating':
      return {
        ...answer,
        pageIndex,
        questionIndex,
        rating: null
      }
    case 'single':
    case 'multiple':
      return {
        ...answer,
        pageIndex,
        questionIndex,
        answers: answer.answers.map((answer) => ({
          ...answer,
          text: answer.text || '',
          selected: false
        }))
      }
    case 'emoji':
      return {
        ...answer,
        pageIndex,
        questionIndex,
        rating: null
      }
  }
}
