import type {ComponentProps} from 'react'
import React from 'react'

import {HelpLink} from '@restapp/shared-support'
import {InfoWidget, WidgetTypes} from '@restapp/shared-ui'

import useStyles from './FormErrorsWidget.style'

const FormErrorsWidget: React.FC<{
  className?: string
  errors: string[]
  link?: ComponentProps<typeof HelpLink>['linkKey']
  linkText?: string
  allowHtml?: boolean
}> = ({errors, link, linkText, allowHtml = false, className}) => {
  const {classes: c} = useStyles()

  return (
    <InfoWidget className={className} type={WidgetTypes.ERROR} noIcon>
      <div className={c.content}>
        {errors?.map((reason) => (
          <div className={c.reason} key={reason} dangerouslySetInnerHTML={allowHtml ? {__html: reason} : undefined}>
            {allowHtml ? undefined : reason}
          </div>
        ))}
        {!!link && !!linkText && (
          <HelpLink className={c.fullConstraintsBtn} linkKey={link}>
            {linkText}
          </HelpLink>
        )}
      </div>
    </InfoWidget>
  )
}

export default FormErrorsWidget
