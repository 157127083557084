import {useI18n} from '@eda-restapp/i18n'
import {Tooltip, useIsMobileStrict, QuestionOutlineIcon, Box} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'
import React, {useState} from 'react'

import styles from './TooltipIcon.module.css'

type TooltipIconProps = {
  tooltipText?: string
  iconClassName?: string
}

export const TooltipIcon: FC<TooltipIconProps> = ({tooltipText, iconClassName}) => {
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<HTMLElement | null>(null)
  const {t} = useI18n()

  const isMobile = useIsMobileStrict()

  return (
    <>
      <Box Component='span' ml='xs' className={cn(styles.infoIconWrapper, iconClassName)} ref={setTooltipAnchorEl}>
        <QuestionOutlineIcon color='#1F2021' fontSize={16} className={styles.infoIcon} />
      </Box>
      {tooltipAnchorEl && (
        <Tooltip.Help
          anchorEl={tooltipAnchorEl}
          className={styles.tooltipInfo}
          placement={isMobile ? 'bottom' : 'bottom-start'}
        >
          {tooltipText ??
            t(
              'shared-places.contract-restaurant-hint.contract-place-tooltip-info',
              'Вы работаете с нами по бумажному договору, поэтому для Плюса подготовим дополнительное соглашение. Подпишите его и отправьте нам — Плюс подключится на следующий день.'
            )}
        </Tooltip.Help>
      )}
    </>
  )
}
