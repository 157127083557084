import {useExp3} from '@eda-restapp/configs'
import {last} from 'lodash-es'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import type {YaPlusFeatureDTO} from '@restapp/shared-api/types'
import {YaPlusStatusEnum} from '@restapp/shared-api/types'

const checkPlacePlustStatus = (place: YaPlusFeatureDTO, status: YaPlusStatusEnum) =>
  place.place_info_by_service?.some((serviceInfo) => last(serviceInfo.statuses)?.status === status)

export function usePlusBannerForAnyPlace() {
  const {enablePlusBanner} = useExp3('restapp_plus')

  const {placesIds} = usePlaces({
    suspense: true,
    enabled: enablePlusBanner
  })

  const placesPlusStatusQuery = useApiQuery({
    url: '/4.0/restapp-front/promo/v1/plus/status',
    method: 'POST',
    body: {
      place_ids: placesIds
    },
    enabled: Boolean(enablePlusBanner && placesIds.length),
    suspense: true
  })

  const userHasPlaceWithoutPlus = Boolean(
    placesPlusStatusQuery.data?.places.some(
      (place) =>
        checkPlacePlustStatus(place, YaPlusStatusEnum.CAN_BE_ACTIVATED) ||
        checkPlacePlustStatus(place, YaPlusStatusEnum.CANNOT_BE_ACTIVATED) ||
        checkPlacePlustStatus(place, YaPlusStatusEnum.DEACTIVATING)
    )
  )

  return {
    visible: enablePlusBanner && userHasPlaceWithoutPlus
  }
}
