import {useServiceBrand} from './useServiceBrand'

export const useServiceTitle = () => {
  const serviceBrand = useServiceBrand()

  switch (serviceBrand) {
    case 'YandexEats':
      return 'Yandex Eats Vendor'
    case 'YangoEats':
      return 'Yango Vendor'
    default:
      return 'Яндекс Еда Вендор'
  }
}
