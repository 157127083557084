import type {ApiQueryConfig} from '@restapp/core-api'
import {useApiQuery} from '@restapp/core-api'

type DefaultData = Parameters<NonNullable<ApiQueryConfig<'/4.0/restapp-front/places/v1/info', 'GET'>['select']>>

type UseOrderByIdOptions<TSelectedData> = Partial<
  Omit<ApiQueryConfig<'/4.0/restapp-front/places/v1/info', 'GET', TSelectedData>, 'url' | 'body' | 'method'>
>

export function usePlaceInfo<TSelectedData = DefaultData>(
  placeId: number,
  options?: UseOrderByIdOptions<TSelectedData>
) {
  const {data, isLoading, isError} = useApiQuery({
    method: 'GET',
    url: '/4.0/restapp-front/places/v1/info',
    params: {
      place_id: placeId
    },
    ...options
  })

  return {
    data,
    isLoading: isLoading,
    isError: isError
  }
}
