import React, {useRef, useLayoutEffect, useState, type CSSProperties} from 'react'
import {useI18n} from '@eda-restapp/i18n'
import {CrossMIcon} from '@eda-restapp/ui'
import styles from './SnackbarsClearButton.module.css'
import cn from 'classnames'

export type SnackbarsClearButtonProps = {
  className?: string
  onClick: () => void
}

export const SnackbarsClearButton: React.FC<SnackbarsClearButtonProps> = ({className, onClick}) => {
  const {t} = useI18n()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [widthWithText, setWidthWithText] = useState(0)

  useLayoutEffect(() => {
    // Запомним ширину кнопки с текстом
    // Потом скукожим ее до крестика и передадим ширину кнопки с текстом в css
    // При ховере будем раскукоживать до этой ширины, чтобы вмещался весь текст
    const offsetWidth = buttonRef.current?.offsetWidth
    if (offsetWidth) {
      setWidthWithText(offsetWidth)
    }
  }, [])

  return (
    <button
      ref={buttonRef}
      className={cn(styles.root, {[styles.collapsed]: !!widthWithText}, className)}
      style={
        {
          '--widthWithText': `${widthWithText}px`
        } as CSSProperties
      }
      onClick={onClick}
    >
      <CrossMIcon className={styles.crossIcon} fill='#21201F' fontSize={22} />
      <span className={styles.text}>{t('ui.snackbars.clear', 'Очистить все')}</span>
    </button>
  )
}
