import {makeStyles} from 'tss-react/mui'

export const useNewsHeadStyles = makeStyles({name: 'NewsHead'})({
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 14
  },
  moreButton: {
    marginLeft: 4
  },
  moreButtonPressed: {
    background: 'rgba(0, 0, 0, 0.04)'
  },
  menu: {
    marginTop: 8
  }
})
