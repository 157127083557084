import {StarFilled} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import type {SurveyAnswerRating, SetAnswer} from '../../../../types'

import Emojis from './Emojis'
import {useRatingStyles} from './RatingAnswer.style'

interface RatingAnswerProps {
  className?: string
  answer: SurveyAnswerRating

  setAnswer(answer: SetAnswer): void
}

function RatingAnswer({className, answer, setAnswer}: RatingAnswerProps) {
  const {classes: c} = useRatingStyles()

  const isEmoji = answer.url === 'emoji' && answer.max_rating === 5

  const handleSetRating = (value: number) => {
    setAnswer({
      type: answer.type,
      questionIndex: answer.questionIndex,
      pageIndex: answer.pageIndex,
      value
    })
  }

  if (isEmoji) {
    return (
      <Emojis
        emojiIndex={answer.rating ? answer.rating - 1 : -1}
        onEmojiClick={(emojiIndex) => handleSetRating(emojiIndex + 1)}
      />
    )
  }

  return (
    <div className={cn(c.root, className)}>
      {new Array(answer.max_rating).fill(0).map((_, i) => (
        <StarFilled
          key={i}
          onClick={() => handleSetRating(i + 1)}
          className={cn(c.star, {
            [c.starFilled]: answer.rating && i < answer.rating
          })}
          data-testid={'communications__survey-rating-star' /*Коммуникации | Иконка-звезда в опросах с рейтингом */}
        />
      ))}
    </div>
  )
}

export default RatingAnswer
