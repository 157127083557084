import type {Campaign} from '../../types/campaign'
import {BudgetAllocation, CampaignStatus, CampaignType} from '../../types/campaign'
import type {GroupType} from '../../types/group'

export const getUUID = (campaign: Campaign): string =>
  campaign.campaign_id ? String(campaign.campaign_id) : (campaign.campaign_uuid ?? '')

/**
 * Выбирает общие проперти из кампании,
 * подготавливает к объединению в группировке по campaignUUID
 * обогащает информацией из places и конфигов
 */
const toGroupType = (campaign: Campaign): GroupType => {
  const cpmBudgetPeriod =
    campaign.campaign_type === CampaignType.cpm ? (campaign.budget_allocation ?? BudgetAllocation.weekly) : undefined

  const cpcBudgetPeriod =
    (campaign.campaign_type === CampaignType.cpc || campaign.campaign_type === undefined) && campaign.weekly_spend_limit
      ? BudgetAllocation.weekly
      : undefined

  const groupPrice = (() => {
    switch (campaign.campaign_type) {
      case CampaignType.cpm:
        return campaign.averagecpm
      case CampaignType.cpa:
        return campaign.bid
      default:
        return campaign.average_cpc
    }
  })()

  const groupStatus = (() => {
    if (campaign.campaign_type === CampaignType.cpa) {
      return campaign.is_active ? CampaignStatus.ACTIVE : CampaignStatus.PAUSED
    }
    return campaign.status
  })()

  return {
    campaignUUID: getUUID(campaign),
    campaignId: campaign.campaign_id,
    advertId: campaign.advert_id,
    cpmAdvertId: campaign.campaign_type === CampaignType.cpm ? campaign.cpm_advert_id : undefined,
    budgetPeriod: campaign.campaign_type === CampaignType.cpm ? cpmBudgetPeriod : cpcBudgetPeriod,
    placeId: campaign.place_id,
    hasAccess: campaign.has_access ?? true,
    hasAccessVendor: campaign.has_access_vendor ?? true,
    ownerAvatarSrc: campaign.owner?.avatar,
    ownerLogin: campaign.owner?.login,
    ownerDisplayName: campaign.owner?.display_name ?? campaign.owner?.login,
    yandexUID: campaign.owner?.yandex_uid,
    created_at: campaign.created_at,
    started_at: campaign.started_at,
    suspended_at: campaign.suspended_at,
    campaignType: campaign.campaign_type ?? CampaignType.cpc,
    services: [campaign.service ?? 'eda'],
    price: groupPrice,
    decimal_price: campaign.campaign_type === CampaignType.cpc ? campaign.decimal_averagecpc : undefined,
    budget: campaign.campaign_type === CampaignType.cpm ? campaign.spend_limit : campaign.weekly_spend_limit,
    decimal_budget: campaign.campaign_type === CampaignType.cpc ? campaign.decimal_weekly_spend_limit : undefined,
    hasLowRatingError: campaign.status === 'suspended' && campaign.is_rating_status_ok === false,
    clicks: campaign.stats?.clicks.sum ?? 0,
    clicksDelta: campaign.stats?.clicks.delta ?? 0,
    orders: campaign.stats?.ad_orders.sum ?? 0,
    ordersDelta: campaign.stats?.ad_orders.delta ?? 0,
    shows: campaign.stats?.shows.sum ?? 0,
    showsDelta: campaign.stats?.shows.delta ?? 0,
    groupStatus: groupStatus,
    campaigns: [
      {
        ...campaign,
        status: groupStatus
      }
    ],
    statusesCounter: {
      [CampaignStatus.ACTIVE]: groupStatus === CampaignStatus.ACTIVE ? 1 : 0,
      [CampaignStatus.SUSPENDED]: groupStatus === CampaignStatus.SUSPENDED ? 1 : 0,
      [CampaignStatus.PROCESS]:
        groupStatus === CampaignStatus.PROCESS || groupStatus === CampaignStatus.UPDATING ? 1 : 0,
      [CampaignStatus.CREATE_ERROR]: groupStatus === CampaignStatus.CREATE_ERROR ? 1 : 0,
      [CampaignStatus.ARCHIVED]: groupStatus === CampaignStatus.ARCHIVED ? 1 : 0,
      [CampaignStatus.PAUSED]: groupStatus === CampaignStatus.PAUSED ? 1 : 0
    },
    activated_by_user: campaign.campaign_type === CampaignType.cpa ? campaign.activated_by_user : undefined
  }
}

const STATUS_PRIORITY: CampaignStatus[] = [
  CampaignStatus.UPDATING,
  CampaignStatus.PROCESS,
  CampaignStatus.ACTIVE,
  CampaignStatus.SUSPENDED,
  CampaignStatus.CREATE_ERROR,
  CampaignStatus.ARCHIVED,
  CampaignStatus.PAUSED
]

/**
 *  Выбирает наиболее приоритетный статус из 2-х
 */
const chooseStatus = (a: CampaignStatus, b: CampaignStatus): CampaignStatus =>
  STATUS_PRIORITY.find((status) => status === a || status === b) ?? CampaignStatus.ACTIVE

/**
 *  Собирает строковый ключ для группы рекламный кампаний по связке сервисы-рестораны
 *
 *  @param group GroupType
 *  @returns string
 */
export const getGroupKeyByServicesAndPlaces = (group: GroupType): string =>
  group.services.join('-') + group.campaigns.reduce((a, c) => a + '-' + c.place_id, '')

/**
 *  Сливает N кампаний для N плейсов в 1 кампанию типа GroupType
 */
export const mergeCampaigns = (campaigns: Campaign[]): GroupType => {
  if (campaigns.length === 1) {
    return toGroupType(campaigns[0])
  }

  return campaigns.map(toGroupType).reduce((accCampaign, campaign) => {
    const groupStatus = chooseStatus(accCampaign.groupStatus, campaign.groupStatus)

    return {
      ...campaign,
      placeId: accCampaign.placeId, // берем всегда первый плейс
      advertId:
        groupStatus === campaign.groupStatus || !accCampaign.advertId ? campaign.advertId : accCampaign.advertId,
      cpmAdvertId:
        groupStatus === campaign.groupStatus || !accCampaign.cpmAdvertId
          ? campaign.cpmAdvertId
          : accCampaign.cpmAdvertId,
      campaigns: accCampaign.campaigns.concat(campaign.campaigns),
      groupStatus,
      hasAccessVendor: accCampaign.hasAccessVendor && campaign.hasAccessVendor,
      services: accCampaign.services.includes(campaign.services[0])
        ? accCampaign.services
        : accCampaign.services.concat(campaign.services).sort().reverse(),
      hasLowRatingError: accCampaign.hasLowRatingError || campaign.hasLowRatingError,
      statusesCounter: {
        [CampaignStatus.ACTIVE]: accCampaign.statusesCounter.active + campaign.statusesCounter.active,
        [CampaignStatus.SUSPENDED]: accCampaign.statusesCounter.suspended + campaign.statusesCounter.suspended,
        [CampaignStatus.PROCESS]: accCampaign.statusesCounter.process + campaign.statusesCounter.process,
        [CampaignStatus.CREATE_ERROR]: accCampaign.statusesCounter.create_error + campaign.statusesCounter.create_error,
        [CampaignStatus.ARCHIVED]: accCampaign.statusesCounter.archived + campaign.statusesCounter.archived,
        [CampaignStatus.PAUSED]: accCampaign.statusesCounter.paused + campaign.statusesCounter.paused
      },
      clicks: accCampaign.clicks + campaign.clicks,
      clicksDelta: accCampaign.clicksDelta + campaign.clicksDelta,
      orders: accCampaign.orders + campaign.orders,
      ordersDelta: accCampaign.ordersDelta + campaign.ordersDelta,
      shows: accCampaign.shows + campaign.shows,
      showsDelta: accCampaign.showsDelta + campaign.showsDelta
    }
  })
}
