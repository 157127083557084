type Resolve<T> = (value: T) => void
type Reject = (error: unknown) => void

export default class Deferred<T> implements PromiseLike<T> {
  resolve: Resolve<T>
  reject: Reject
  private readonly _promise: Promise<T>

  constructor() {
    let resolve: Resolve<T> | undefined
    let reject: Reject | undefined

    this._promise = new Promise<T>((promiseResolve, promiseReject) => {
      resolve = promiseResolve
      reject = promiseReject
    })

    this.resolve = resolve as Resolve<T>
    this.reject = reject as Reject
  }

  then<TResult1 = T, TResult2 = never>(
    onFulfilled?: ((value: T) => PromiseLike<TResult1> | TResult1) | undefined | null,
    onRejected?: ((reason: unknown) => PromiseLike<TResult2> | TResult2) | undefined | null
  ): PromiseLike<TResult1 | TResult2> {
    return this._promise.then(onFulfilled, onRejected)
  }

  toPromise() {
    return this._promise
  }
}
