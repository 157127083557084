import {errorLogger} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import {isApiError} from '@restapp/shared-api'

import {selectNews} from '../selectors'
import {
  readAllNews,
  readOneNews,
  readOnePoolNews,
  resetNews,
  setNews,
  setNewsFilter,
  setNewsHasMore,
  setNewsPollingCursor,
  setNewsSort,
  setPoolPollingCursor
} from '../slice'
import {CommunicationType} from '../types'
import type {NewsFilter, NewsSort} from '../types'
import {getTopic} from '../utils/news'

export function useNews() {
  const dispatch = useDispatch()
  const {selectedPlaceId} = usePlaces()

  const newsModel = useSelector(selectNews)

  const loadNewsMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications',
    method: 'POST'
  })

  const readAllMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications/read-all',
    method: 'POST'
  })

  const markReadMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications/log-status',
    method: 'POST'
  })

  const load = useCallback(
    (isLoadingMore?: boolean, customParams?: {filter?: NewsFilter; sort?: NewsSort; cursor?: string}) => {
      loadNewsMutation.mutate(
        {
          body: {services: [CommunicationType.NEWS]},
          params: {
            place_id: selectedPlaceId,
            cursor: isLoadingMore ? newsModel.pollingCursor : undefined,
            limit: newsModel.pollingLimit,
            order: customParams?.sort ?? newsModel.sort,
            topic: getTopic(customParams?.filter ?? newsModel.filter)
          }
        },
        {
          onSuccess: (data) => {
            const newCursor = data.cursor

            if (!isLoadingMore) {
              dispatch(resetNews())
            }

            const {news} = data.feed
            const newHasMore = news ? news.length > 0 : false

            if (news) {
              dispatch(setNews({items: news, isLoadingMore}))
            }
            if (newHasMore !== newsModel.hasMore) {
              dispatch(setNewsHasMore(newHasMore))
            }
            dispatch(setPoolPollingCursor(newCursor))
          },
          onError: (error) => {
            if (isApiError(error)) {
              errorLogger({message: 'communications:polling', error})

              Snackbar.show({
                type: 'error',
                text: error.message
              })
            }
          }
        }
      )
    },
    [
      dispatch,
      loadNewsMutation,
      newsModel.filter,
      newsModel.hasMore,
      newsModel.pollingCursor,
      newsModel.pollingLimit,
      newsModel.sort,
      selectedPlaceId
    ]
  )

  const reset = useCallback(() => {
    dispatch(resetNews())
  }, [dispatch])

  const loadMore = useCallback(() => {
    load(true)
  }, [load])

  const reload = useCallback(
    (customParams?: {filter?: NewsFilter; sort?: NewsSort}) => {
      dispatch(setNewsPollingCursor(undefined))
      load(false, customParams)
    },
    [dispatch, load]
  )

  const markRead = useCallback(
    (id: string) => {
      markReadMutation.mutate({
        body: {
          service: CommunicationType.NEWS,
          id
        },
        params: {
          place_id: selectedPlaceId
        }
      })
      // Так как 2 источника данных надо сообщить обоим, что новость прочитана. https://st.yandex-team.ru/EDARESTAPP-9812
      dispatch(readOnePoolNews(id))
      dispatch(readOneNews(id))
    },
    [dispatch, markReadMutation, selectedPlaceId]
  )

  const readAll = useCallback(() => {
    readAllMutation.mutate({
      body: {
        service: CommunicationType.NEWS
      },
      params: {
        place_id: selectedPlaceId
      }
    })
    dispatch(readAllNews())
  }, [dispatch, readAllMutation, selectedPlaceId])

  const setFilter = useCallback(
    (filter: NewsFilter) => {
      dispatch(setNewsFilter(filter))
      reload({filter})
    },
    [dispatch, reload]
  )

  const setSort = useCallback(
    (sort: NewsSort) => {
      dispatch(setNewsSort(sort))
      reload({sort})
    },
    [dispatch, reload]
  )

  return useMemo(
    () => ({
      hasMore: newsModel.hasMore,
      filter: newsModel.filter,
      sort: newsModel.sort,
      items: newsModel.items,
      isLoading: loadNewsMutation.isLoading,
      loadMore,
      reload,
      readAll,
      markRead,
      setFilter,
      setSort,
      reset
    }),
    [loadMore, loadNewsMutation.isLoading, markRead, newsModel, readAll, reload, reset, setFilter, setSort]
  )
}
