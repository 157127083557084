import {RESTAURANT_DISABLE_REASONS} from '@restapp/shared'

export enum PlaceDisableReason {
  EVENT = RESTAURANT_DISABLE_REASONS.HOLIDAYS,
  NOT_KEEP_UP = RESTAURANT_DISABLE_REASONS.RUSH_HOUR,
  OTHER = RESTAURANT_DISABLE_REASONS.SELF_BY_VENDOR
}

export enum Timers {
  QUARTER_AN_HOUR = 15,
  HALF_AN_HOUR = 30,
  HOUR = 60,
  TWO_HOUR = 120,
  TILL_END_OF_DAY = 1440,
  OTHER
}
