import React, {memo} from 'react'
import {AppMapping, LinkMapping} from './AppMapping'
import type {IFrameRoute} from './GlobalRouting/GlobalRouting'
import GlobalRouting from './GlobalRouting/GlobalRouting'
import {PageRootWrapper, SidebarContainer, SwipeHandler} from '@restapp/main-layout'
import {Spinner} from '@restapp/shared-ui'
import type {NavigationElement, SectionSlug} from '@restapp/shared-api'
import useNavigation from './useNavigation'
import {useI18n} from '@eda-restapp/i18n'

interface NavigationSelectedData {
  logoPath: string
  mainNavElements: NavigationElement[]
  footerNavElements: NavigationElement[]
  routesIds: SectionSlug[]
  labelsMap: Record<string, string>
  mainRouteId: SectionSlug
  routesIFrame: IFrameRoute[]
}

const sidebarMap = {...AppMapping, ...LinkMapping}

const AuthorizedAppRoot: React.FC = memo(() => {
  const {t} = useI18n()

  const navigationQuery = useNavigation<NavigationSelectedData>({
    select: (response) => {
      /**
       * Главные элементы навигации для отрисовки дерева меню
       */
      const mainNavElements = response.groups.find((group) => group.id === 'main-navigation')?.nav || []
      /**
       * Элементы навигации футера для отрисовки дерева меню
       */
      const footerNavElements = response.groups.find((group) => group.id === 'footer-navigation')?.nav || []

      /**
       * Содержит плоский массив всех навигационных элементов, необходимый для создания роутов
       */
      const allNavElements = mainNavElements.concat(footerNavElements).flatMap((el: NavigationElement) => {
        // Собираем все вложенные роуты
        if (el.children) {
          return el.children
        }

        return el
      })

      return {
        logoPath: response.logo.image.path,
        mainNavElements,
        footerNavElements,

        routesIFrame: allNavElements
          .filter((navElement) => navElement.type === 'iframe' && navElement.link?.path && navElement.id)
          .map((navElement) => {
            return {
              id: String(navElement.id) as SectionSlug,
              path: String(navElement.link?.path)
            }
          }),

        routesIds: allNavElements
          .filter((navElement) => navElement.type === 'component')
          .map((componentNavElement) => componentNavElement.id),
        labelsMap: allNavElements
          .filter((navElement) => navElement.type === 'component' || navElement.type === 'iframe')
          .reduce(
            (acc, navElement) => {
              acc[navElement.id] = navElement.label
              return acc
            },
            {} as Record<SectionSlug, string>
          ),
        mainRouteId: response.main.component.id
      }
    }
  })

  if (navigationQuery.isLoading || !navigationQuery.data) {
    return (
      <Spinner
        slug='loading-navigation-configuration'
        absolute
        caption={t('core-legacy.authorized-app-root.loading-sidebar', 'Загружаем навигацию')}
      />
    )
  }

  const sidebar = (
    <SidebarContainer
      sidebarMap={sidebarMap}
      logoPath={navigationQuery.data.logoPath}
      mainNavElements={navigationQuery.data.mainNavElements}
      footerNavElements={navigationQuery.data.footerNavElements}
    />
  )

  return (
    <PageRootWrapper Sidebar={sidebar}>
      <SwipeHandler>
        <GlobalRouting
          routesMap={AppMapping}
          labelsMap={navigationQuery.data.labelsMap}
          routesIds={navigationQuery.data.routesIds}
          mainRouteId={navigationQuery.data.mainRouteId}
          routesIFrame={navigationQuery.data.routesIFrame}
        />
      </SwipeHandler>
    </PageRootWrapper>
  )
})
AuthorizedAppRoot.displayName = 'AuthorizedAppRoot'

export default AuthorizedAppRoot
