import React, {type FC} from 'react'

import type {ShortRestaurant} from '@restapp/shared-api/types/places'

import {DeliveryIcon} from '../../DeliveryIcon'
import {Item} from '../Item'

type RestaurantListItemProps = {
  place: Pick<ShortRestaurant, 'name' | 'address' | 'services' | 'type'>
  disabled?: boolean
  className?: string
  disabledIcon?: boolean
  children?: React.ReactNode
}

export const RestaurantListItem: FC<RestaurantListItemProps> = ({
  place,
  children,
  disabled,
  className,
  disabledIcon = false
}) => {
  const icon = <DeliveryIcon place={place} disabled={disabledIcon} />

  return (
    <Item icon={icon} disabled={disabled} title={place.name} subTitle={place.address} className={className}>
      {children}
    </Item>
  )
}
