import {ordersChannel, useChannelSubscription} from '@eda-restapp/microfrontend'

import {useCourierFeedback} from './useCourierFeedback'

export function useCourierFeedbackBridge() {
  const {open} = useCourierFeedback()

  useChannelSubscription(ordersChannel, (msg) => {
    if (msg.type === 'order-courier-feedback' && msg.payload.orderNr) {
      open(msg.payload.orderNr)
    }
  })
}
