export function formatNumber(
  n: number | bigint,
  lang?: string,
  option?: Intl.NumberFormatOptions & {notation?: string}
) {
  return new Intl.NumberFormat([lang || 'ru', 'en'], {
    useGrouping: false,
    minimumFractionDigits: 0,
    ...option
  }).format(n)
}
