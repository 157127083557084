import {useDragScroll, useIsScrollable} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {Box, CopyButton, Clamp} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React, {useRef} from 'react'

import {useIsScrolledHorizontally} from '@restapp/core-legacy/hooks/useIsScrolledHorizontally'
import {usePlaces} from '@restapp/core-places'
import {MultiChatStatusBadge} from '@restapp/main-chats/messenger/components/MultiChatStatusBadge/MultiChatStatusBadge'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableContainer, TableRow} from '@restapp/shared-ui'
import {useAppSelector} from '@restapp/store'

import type {IChat} from '../../types'
import {formatSla} from '../../utils/formating'
import {getChatSlaHours} from '../../utils/getChatSlaHours'
import {UnreadBadge} from '../UnreadBadge'

import styles from './ChatsTable.module.css'

type ChatsTableProps = {
  chats: IChat[]
  onClickChat(chat: {chat_id?: string}): void
}

export function ChatsTable({chats, onClickChat}: ChatsTableProps) {
  const {t} = useI18n()
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const {placesIds} = usePlaces({suspense: true})
  const displayPlaceCell = placesIds.length > 1

  const isTableHasScroll = useIsScrollable(tableContainerRef)
  const isTableScrolled = useIsScrolledHorizontally(tableContainerRef)

  useDragScroll(tableContainerRef, 'horizontal', isTableHasScroll.horizontal)

  return (
    <TableContainer
      ref={tableContainerRef}
      className={cn(styles.tableContainer, isTableScrolled && styles.shadowRight)}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell className={styles.statusCell}>
              {t('main-chats.chats-table.status', 'Статус')}
            </TableHeaderCell>

            <TableHeaderCell className={styles.topicCell}>{t('main-chats.chats-table.tema', 'Тема')}</TableHeaderCell>

            {displayPlaceCell && <TableHeaderCell>{t('main-chats.chats-table.restoran', 'Ресторан')}</TableHeaderCell>}

            <TableHeaderCell align='center'>{t('main-chats.chats-table.number-zakaza', '№ Заказа')}</TableHeaderCell>

            <TableHeaderCell>{t('main-chats.chats-table.avtor', 'Автор')}</TableHeaderCell>

            <TableHeaderCell align='right'>{t('main-chats.chats-table.sozdano', 'Создано')}</TableHeaderCell>

            <TableHeaderCell>{t('main-chats.chats-table.vremya-do-resheniya', 'Время до решения')}</TableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {chats.map((chat, i) => (
            <ChatsTableRow
              testId={`chats-table-row-${i}` /*Обращения | Кликабельная строка в таблице чатов*/}
              displayPlaceCell={displayPlaceCell}
              key={`${chat.chat_id} ${chat.ticket_id} ${chat.topic} ${chat.created_at}`}
              onClick={() => onClickChat(chat)}
              chat={chat}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type ChatsTableRowProps = {
  chat: IChat
  displayPlaceCell: boolean
  testId?: string
  onClick(): void
}

function ChatsTableRow({chat, testId, onClick, displayPlaceCell}: ChatsTableRowProps) {
  const {getPlaceById} = usePlaces()
  const multiChatUnreadCount = useAppSelector((state) =>
    chatsSlice.selectors.selectMultiChatUnreadCountById(state, chat.chat_id || '')
  )

  const unreadCount = chat.new_messages_counter || multiChatUnreadCount

  const chatSla = getChatSlaHours({status: chat.status, sla: chat.sla})
  const chatPlace = chat.place_id ? getPlaceById(Number(chat.place_id)) : undefined

  function formatDate(date?: moment.Moment) {
    if (!date) {
      return '—'
    }

    return (
      <>
        <div>{date.format('DD.MM.YYYY')}</div>
        <div className={styles.secondaryText}>{date.format('HH:mm')}</div>
      </>
    )
  }

  function renderSLA(chatSla: number | null) {
    if (chatSla) {
      return formatSla(chatSla)
    }

    return <div className={styles.emptyString}>—</div>
  }

  return (
    <TableRow data-testid={testId} classes={{root: styles.row}} onClick={onClick}>
      <TableCell className={cn(styles.cell, styles.statusCell)}>
        <MultiChatStatusBadge status={chat.status} />
      </TableCell>

      <TableCell className={cn(styles.cell, styles.topicCell)}>
        <Box gap='xs'>
          <Clamp className={styles.clamp}>{chat.topic}</Clamp>
          {!!unreadCount && <UnreadBadge count={unreadCount} />}
        </Box>
      </TableCell>

      {displayPlaceCell && (
        <TableCell className={cn(styles.cell, styles.restaurantCell)}>
          {chatPlace?.name ? <div>{chatPlace?.name}</div> : <div className={styles.emptyString}>—</div>}
          <div className={styles.secondaryText}>{chatPlace?.address}</div>
        </TableCell>
      )}

      <TableCell align='center' className={cn(styles.cell, styles.orderIdCell)}>
        {chat.order_nr ? (
          <Box gap='s' className={styles.orderId}>
            <div />
            {chat.order_nr}
            <CopyButton className={styles.orderIdCopy} value={chat.order_nr} />
          </Box>
        ) : (
          <div className={styles.emptyString}>—</div>
        )}
      </TableCell>

      <TableCell className={cn(styles.cell, styles.authorCell)}>{chat.author_email}</TableCell>

      <TableCell align='right' className={cn(styles.cell, styles.createdAtCell)}>
        {formatDate(moment(chat.created_at))}
      </TableCell>

      <TableCell className={cn(styles.cell, styles.slaCell, {[styles.error]: chatSla !== null && chatSla < 0})}>
        {renderSLA(chatSla)}
      </TableCell>
    </TableRow>
  )
}
