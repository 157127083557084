import React from 'react'
import type {UserAvatarStyles} from './UserAvatar.style'
import useStyles from './UserAvatar.style'
import {Avatar} from '@mui/material'
import DefaultAvatar from './assets/avatar.svg'
import cn from 'classnames'
import {NotifySign, Error} from '@eda-restapp/ui'
import {Tooltip} from '@restapp/shared-ui'

export type UserAvatarProps = {
  disabled?: boolean
  name: string
  subtitle?: string
  src?: string
  statusBlock?: JSX.Element
  isNotify?: boolean
  alertMessage?: string
  isColumn?: boolean
} & {
  className?: string
  classes?: Partial<Record<keyof typeof UserAvatarStyles, string>>
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const {classes, disabled = false, name, subtitle, src, alertMessage, isNotify, isColumn} = props
  const {classes: c} = useStyles(undefined, {props: {classes}})

  return (
    <div className={c.root}>
      <div data-testid={'direct-avatar' /*Аватар логина в Яндекс Id | Весь элемент*/} className={c.avatar}>
        <Avatar
          className={cn(c.avatarIcon, disabled && c.avatarIconDisabled)}
          imgProps={{
            draggable: false
          }}
          src={src || DefaultAvatar}
        />
        {disabled && <Error fontSize={17} className={c.error} />}
        {!name && isNotify && (
          <NotifySign
            data-testid={
              'red-point-on-avatar' /*Аватар логина в ЯндексId | Красная точка если не все поля директа заполены */
            }
            className={c.notify}
          />
        )}
      </div>
      <div className={cn(c.avatarContainer, isColumn && c.avatarAsColumn)}>
        <div
          data-testid={'direct-login' /*Логин пользователя в директе */}
          className={cn(c.name, disabled && c.disabled)}
        >
          {name}
        </div>
        {!!subtitle && <div className={cn(c.subtitle, disabled && c.disabled)}>{subtitle}</div>}
      </div>

      {Boolean(name) && !isColumn && (
        <div className={c.notifyContainer}>
          {isNotify && (
            <NotifySign
              data-testid={
                'red-point-on-avatar' /*Аватар логина в ЯндексId | Красная точка если не все поля директа заполены */
              }
              className={cn(c.notify, c.notifyAfterName)}
            />
          )}
        </div>
      )}

      {!!alertMessage && !isColumn && (
        <Tooltip
          title={alertMessage}
          classes={{
            popper: c.alertTooltip
          }}
          placement='bottom'
        >
          <div>
            <Error
              data-testid={
                'error-icon-on-avatar' /*Поповер о пользователе директа | Значек ошибки при незаполненных полях*/
              }
              className={c.errorIcon}
            />
          </div>
        </Tooltip>
      )}

      {isColumn && !!alertMessage && (
        <div
          data-testid={'alert-message' /*Модал пользователя директа | Сообщение об ошибке */}
          className={c.fullAlertMessage}
        >
          {alertMessage}
        </div>
      )}
    </div>
  )
}

export default UserAvatar
