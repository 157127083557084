import {S} from '../style'

export const SectionStyles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0',
    borderBottom: '1px solid #eeeeee'
  },
  titleLayer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  tagContainer: {
    marginLeft: 8
  },
  title: {
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 'bold'
  },
  content: {
    paddingRight: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [S.mobileQuery]: {
      paddingRight: 0
    }
  },
  arrow: {
    marginLeft: 8,
    alignSelf: 'center',
    transition: '.3s',
    width: '16px!important',
    height: '16px!important',
    backgroundSize: 'cover!important'
  },
  arrowUp: {
    transform: 'rotate(180deg)'
  }
} as const
