import {useExp3} from '@eda-restapp/configs'

import React from 'react'
import {useI18n} from '@eda-restapp/i18n'
import {Button, InfoWidget, OutsideLink} from '@restapp/shared-ui'
import {Box, Typography, Modal} from '@eda-restapp/ui'

export interface AutostopInfoForNonComitentProps {}

/**
 * @description - Модалка с информацией об отключении ресторана для партнера, который не указал еду как своего комиссионера
 */
const AutostopInfoForNonComitent: React.FC<AutostopInfoForNonComitentProps> = () => {
  const {t, tRaw} = useI18n()
  const placeConfig = useExp3('restapp_place')

  return (
    <>
      <Modal.Header
        title={t('core-legacy.autostop-info-for-non-comitent.place-temporarily-disabled', 'Ресторан временно отключён')}
      />
      <Modal.Content>
        <InfoWidget type='warning'>
          <Typography variant='body2'>
            {t(
              'core-legacy.autostop-info-for-non-comitent.warning-widget-text',
              'Ваш ресторан временно отключен от платформы из-за того, что вы не являетесь комитентом Yandex Eats'
            )}
          </Typography>
        </InfoWidget>
        <Box
          mt='l'
          flexDirection='column'
          data-testid={'uz-place-non-comitent-popup-content' /*Попап для некомитентов в UZ | Инструкция*/}
        >
          <Typography variant='body2' thickness='medium'>
            {t(
              'core-legacy.autostop-info-for-non-comitent.enable-instructions-title',
              'Чтобы активировать ресторан на платформе:'
            )}
          </Typography>
          <Box ml='l' mt='s' flexDirection='column'>
            <ul>
              <li>
                <Typography variant='body2'>
                  <span>
                    {tRaw(
                      'core-legacy.autostop-info-for-non-comitent.step1',
                      'Добавьте Yandex Eats комиссионером в {linkToTaxWebsite} налогоплательщика',
                      {
                        linkToTaxWebsite: (
                          <OutsideLink
                            href={placeConfig.uz_tax_website_url}
                            target='_blank'
                            data-testid={
                              'non-comitent-outside-link-Личный кабинет' /*Попап для некомитентов в UZ | Ссылка на личный кабинет налогоплательщика в UZ*/
                            }
                          >
                            {t(
                              'core-legacy.autostop-info-for-non-comitent.step1-tax-website-link-text',
                              'личном кабинете'
                            )}
                          </OutsideLink>
                        )
                      }
                    )}
                  </span>
                </Typography>
              </li>
              <li>
                <Typography variant='body2'>
                  <span>
                    {tRaw('core-legacy.autostop-info-for-non-comitent.step2', '{helpLink} в Базе знаний', {
                      helpLink: (
                        <OutsideLink
                          href={placeConfig.uz_tax_comitent_help_url}
                          target='_blank'
                          data-testid={
                            'non-comitent-outside-link-Инструкция' /*Попап для некомитентов в UZ | Help link*/
                          }
                        >
                          {t('core-legacy.autostop-info-for-non-comitent.step2-help-link-text', 'Инструкция')}
                        </OutsideLink>
                      )
                    })}
                  </span>
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <OutsideLink
          style={{width: '100%'}}
          href={placeConfig.uz_tax_comitent_modal_btn_url}
          target='_blank'
          data-testid={
            'non-comitent-outside-link-Как активировать' /*Попап для некомитентов в UZ | Ссылка на личный кабинет налогоплательщика в UZ*/
          }
        >
          <Button variant='contained' size='large' color='primary' fullWidth>
            {t('core-legacy.autostop-description-modal.how-to-enable-button', 'Как активировать')}
          </Button>
        </OutsideLink>
      </Modal.Footer>
    </>
  )
}

export default AutostopInfoForNonComitent
