import {useExp3} from '@eda-restapp/configs'
import {Modal, MarkdownContainer} from '@eda-restapp/ui'

import styles from './MpdModal.module.css'

type MpdModalProps = {
  open: boolean
  onClose: () => void
}

export const MpdModal = ({open, onClose}: MpdModalProps) => {
  const mpdConfigBanner = useExp3('restapp_mpd_banner')

  return (
    <Modal open={open} onClose={onClose}>
      {!!mpdConfigBanner.dialogHeader && <Modal.Header title={mpdConfigBanner.dialogHeader} />}
      <Modal.Content className={styles.bannerModalContent}>
        {!!mpdConfigBanner.dialogContentImg && (
          <img style={{maxWidth: '100%'}} src={mpdConfigBanner.dialogContentImg} alt='' />
        )}

        <MarkdownContainer markdown={mpdConfigBanner.dialogContent || ''} withHTML />
      </Modal.Content>
    </Modal>
  )
}
