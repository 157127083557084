import {useContext} from 'react'

import {ApiDepsContext} from '../contexts/ApiDepsContext'

export function useApiDeps() {
  const deps = useContext(ApiDepsContext)

  if (!deps) {
    throw new Error('ApiDeps not found. You need to wrap components with ApiDepsProvider')
  }

  return deps
}
