export function parseFormattedInt(value: string) {
  const numbersWithoutSpaces = value.replace(/[\D\s]/g, '')
  const numberValue = parseInt(numbersWithoutSpaces, 10)

  if (isNaN(numberValue)) {
    return 0
  }

  return numberValue
}
