import {eventLogger} from '@eda-restapp/logger'
import {Modal} from '@eda-restapp/ui'
import React from 'react'

import type {LinkedAccount} from '@restapp/shared-api/types/passport'

import type {ExternalAccountSuggest} from '../../../../../types'

import {ModalWithLinkedAccounts} from './ModalWithLinkedAccounts'
import {ModalWithNewFeatures} from './ModalWithNewFeatures'

type PassportModalProps = {
  open: boolean
  accounts: LinkedAccount[]
  maxLinkedAccountLimitReached?: boolean
  hasLinkedAccounts?: boolean

  suggestedAccount?: ExternalAccountSuggest
  openDrawer(): void
  onClose(): void
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
  onAuthWithLinkedAccount(account: LinkedAccount): void
}
export const PassportModal = ({
  open,
  onClose,
  openDrawer,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onAuthWithLinkedAccount,
  hasLinkedAccounts,
  suggestedAccount,
  accounts,
  maxLinkedAccountLimitReached
}: PassportModalProps) => {
  const handleBindOtherAccount = () => {
    if (suggestedAccount) {
      eventLogger({name: 'passport:modal:click_bind_other_account'})
    } else {
      eventLogger({name: 'passport:modal:click_bind'})
    }

    onBindOtherAccount()
  }

  const handleBindSuggestedAccount = () => {
    eventLogger({name: 'passport:modal:click_bind_suggested_account'})
    onBindSuggestedAccount()
  }

  return (
    <Modal open={open} onClose={onClose}>
      {hasLinkedAccounts ? (
        <ModalWithLinkedAccounts
          accounts={accounts}
          openDrawer={openDrawer}
          suggestedAccount={suggestedAccount}
          maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
          onClose={onClose}
          onBindOtherAccount={handleBindOtherAccount}
          onBindSuggestedAccount={handleBindSuggestedAccount}
          onAuthWithLinkedAccount={onAuthWithLinkedAccount}
        />
      ) : (
        <ModalWithNewFeatures
          openDrawer={openDrawer}
          suggestedAccount={suggestedAccount}
          onClose={onClose}
          onBindOtherAccount={handleBindOtherAccount}
          onBindSuggestedAccount={handleBindSuggestedAccount}
        />
      )}
    </Modal>
  )
}
