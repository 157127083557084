import {useContext} from 'react'

import type {IEnv} from '@restapp/core-legacy/types'

import {EnvContext} from './context'

/**
 * @deprecated
 */
export function useEnv(): IEnv {
  const env = useContext(EnvContext)

  if (env === null) {
    throw new Error('Env not found. You should wrap components with EnvContext.Provider')
  }

  return env
}
