import {eventLogger, metrika} from '@eda-restapp/logger'
import {Box} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC, type RefObject, useEffect} from 'react'

import {usePlaces} from '@restapp/core-places'
import {ErrorBoundary, Suspense} from '@restapp/shared-boundary'

import styles from './Page.module.css'

export type PageProps = {
  className?: string
  contentRef?: RefObject<HTMLDivElement>
  slug?: string
  children?: React.ReactNode
}

export const Page: FC<PageProps> = ({className, contentRef, slug, children}) => {
  const {selectedPlaceId, selectedPlaceIds} = usePlaces()

  useEffect(() => {
    eventLogger({name: 'page_mount', value: slug, additional: {pageSlug: slug}})
    metrika({
      target: 'page_mount',
      params: {
        pageSlug: slug,
        selectedPlaceId,
        selectedPlaceIds
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <Box
      className={cn(styles.contentClass, className)}
      ref={contentRef}
      data-testid={'ui-page' /*UI | Страница целиком без сайдбара*/}
      flexDirection='column'
    >
      <ErrorBoundary withMenuButton slug={slug}>
        <Suspense slug={slug}>{children}</Suspense>
      </ErrorBoundary>
    </Box>
  )
}
