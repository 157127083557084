import {clearDirectToken, getDirectToken} from '@eda-restapp/microfrontend'
import {useCallback, useEffect} from 'react'
import {useQuery, useQueryClient} from 'react-query'

import {useCheckPartner} from './useCheckPartner'
import {useDirectCheck} from './useDirectCheck'

const useDirect = () => {
  const {data: directToken, isLoading: isLoadingDirectToken} = useQuery('directToken', getDirectToken)
  const queryClient = useQueryClient()

  const {partner, error, isLoading: isLoadingCheckPartner} = useCheckPartner({directToken})
  const {invalidAccount, creationDisabled, invalidAccountMessage} = useDirectCheck({directToken})

  const directLogout = useCallback(() => {
    clearDirectToken()

    void queryClient.invalidateQueries('directToken')
  }, [queryClient])

  // @TODO Убрать проверку на error?.responseData?.message когда на беке будет реализован статус 403
  const wrongDirectToken = error?.status === 403 || error?.responseData?.message === 'Missing X-Yandex-UID in header'

  useEffect(() => {
    if (wrongDirectToken) {
      directLogout()
    }
  }, [directLogout, wrongDirectToken])

  const token = !error && !isLoadingCheckPartner && !isLoadingDirectToken ? directToken : undefined

  return {
    directToken: token,
    directLogout,
    directUsername: partner?.login,
    isLoggedDirect: Boolean(directToken),
    partner,
    creationDisabled,
    invalidAccount,
    invalidAccountMessage,
    invalidateDirect: () => void queryClient.invalidateQueries('directToken')
  }
}

export default useDirect
