import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()({
  fullConstraintsBtn: {
    display: 'block',
    textAlign: 'start',
    color: '#0668BE'
  },
  content: {
    '& > * + *': {
      marginTop: 6
    }
  },
  reason: {
    '& > a': {
      color: '#0668BE'
    }
  }
})

export default useStyles
