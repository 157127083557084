import type {ComponentType} from 'react'
import React, {Component} from 'react'
import {wrapDisplayName} from 'recompose'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {debounce, throttle} from 'lodash-es'

const suspendedPromise = new Promise(() => {
  // tslint: disable-line
})

type Disposer = () => void
type WithDisposerProp<T = Disposer | Disposer[]> = {
  (callback: T): T
}
type SetTimeoutProp = (callback: () => void, ms: number) => number
type ClearTimeoutProp = (timeoutId?: number) => void
type DebounceProp = typeof debounce
type ThrottleProp = typeof throttle
type WithPromise = <T>(promise: Promise<T>) => Promise<T>

export type WithDisposerProps = {
  withDisposer: WithDisposerProp
  setTimeout: SetTimeoutProp
  clearTimeout: ClearTimeoutProp
  debounce: DebounceProp
  throttle: ThrottleProp
  withPromise: WithPromise
}

export default function withDisposer<P extends {}>(
  OriginalComponent: ComponentType<WithDisposerProps & P>
): ComponentType<P> {
  class WrappedComponent extends Component<P> {
    static displayName = wrapDisplayName(OriginalComponent, 'withDisposer')

    private callbacks: Array<() => void> = []
    private timeouts: {[k: string]: number} = {}
    private unmounted = false

    get disposerProps() {
      return {
        withDisposer: this.withDisposer,
        setTimeout: this.setTimeout,
        withPromise: this.withPromise,
        clearTimeout: this.clearTimeout,
        debounce: this.debounce,
        throttle: this.throttle
      }
    }

    componentWillUnmount() {
      while (this.callbacks.length) {
        const callback = this.callbacks.pop()

        if (callback !== undefined) {
          callback()
        }
      }
      for (const k in this.timeouts) {
        if (this.timeouts.hasOwnProperty(k)) {
          this.clearTimeout(this.timeouts[k])
        }
      }
      this.unmounted = true
    }

    debounce: DebounceProp = (func, wait, options) => {
      const debounced = debounce(func, wait, options)
      this.callbacks.push(debounced.cancel)
      return debounced
    }

    throttle: ThrottleProp = (func, wait, options) => {
      const throttled = throttle(func, wait, options)
      this.callbacks.push(throttled.cancel)
      return throttled
    }

    clearTimeout: ClearTimeoutProp = (timeoutId) => {
      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId)
        delete this.timeouts[String(timeoutId)]
      }
    }

    setTimeout: SetTimeoutProp = (callback, ms) => {
      const timeoutId = window.setTimeout(() => {
        delete this.timeouts[String(timeoutId)]
        callback()
      }, ms)

      this.timeouts[String(timeoutId)] = timeoutId

      return timeoutId
    }

    withDisposer: WithDisposerProp = (callback) => {
      if (Array.isArray(callback)) {
        callback.forEach((c) => this.callbacks.push(c))
      } else {
        this.callbacks.push(callback)
      }

      return callback
    }

    withPromise: WithPromise = (promise) =>
      promise.then(
        (result) => {
          if (this.unmounted) {
            return suspendedPromise as Promise<any>
          }

          return result
        },
        (error) => {
          if (this.unmounted) {
            return suspendedPromise as Promise<any>
          }

          return Promise.reject(error)
        }
      )

    render() {
      return <OriginalComponent {...this.props} {...this.disposerProps} />
    }
  }

  hoistNonReactStatics(WrappedComponent, OriginalComponent)

  return WrappedComponent
}
