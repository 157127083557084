import {cloneDeep} from 'lodash-es'
import type {UseInfiniteQueryResult} from 'react-query'
import {useInfiniteQuery} from 'react-query'

import type {IApiError, VendorApi as Api} from '@restapp/shared-api'

import type {ApiResponse, ApiQueryKey} from '../../types'
import {useApiDeps} from '../useApiDeps'

import {InfiniteQueryAdapters, ADAPTERS} from './adapters'
import type {ApiInfiniteQueryConfig, ApiInfiniteQueryOptions, InfiniteQueryAdapter, Cursor} from './types'

type OmittedOptions = 'queryKey' | 'queryFn' | 'getNextPageParam'

/**
 * По умолчанию автоматически пытается получить курсор из ответа по пути `meta.cursor`
 * и вставляет его при последующих запросах в `params.cursor`
 *
 * Этим поведением можно управлять добавив свой адаптер в `adapters.ts`и выбрать его в `options`
 *
 * _Курсор указанный в `config` используется только при первом вызове_
 */
export default function useApiInfiniteQuery<
  TUrl extends keyof Api,
  TMethod extends keyof Api[TUrl],
  TSelectedData = ApiResponse<Api[TUrl][TMethod]>
>(
  config: ApiInfiniteQueryConfig<TUrl, TMethod>,
  options: Omit<ApiInfiniteQueryOptions<TUrl, TMethod, TSelectedData>, OmittedOptions> = {}
): UseInfiniteQueryResult<TSelectedData, IApiError> {
  const {request} = useApiDeps()
  const {adapter = InfiniteQueryAdapters.DEFAULT, ...queryOptions} = options
  const {getCursor, setCursor} = ADAPTERS[adapter] as InfiniteQueryAdapter<Cursor, TUrl, TMethod>

  const querykey: ApiQueryKey<TUrl, TMethod> = [config.url, config.method, config.params, config.body]

  return useInfiniteQuery<ApiResponse<Api[TUrl][TMethod]>, IApiError, TSelectedData, ApiQueryKey<TUrl, TMethod>>(
    querykey,
    {
      ...queryOptions,
      queryFn: ({pageParam}) => {
        const typedPageParam = typeof pageParam === 'string' || typeof pageParam === 'number' ? pageParam : undefined
        const configWithCursor = setCursor(cloneDeep(config), typedPageParam) as unknown as ApiInfiniteQueryConfig

        return request({
          ...configWithCursor,
          data: configWithCursor.body
        })
      },
      getNextPageParam(lastPage, pages) {
        return getCursor(lastPage, pages)
      }
    }
  )
}
