import {
  CrossRoundFill,
  ChevronRightMIcon,
  useIsMobileStrict,
  Success,
  Error,
  ExclamationSharp,
  InfoIcon
} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type CSSProperties, type FC, type HTMLAttributes, type ReactNode} from 'react'

import {useStyles} from './InfoWidget.styles'

/** @deprecated */
export enum WidgetTypes {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface InfoWidgetProps extends HTMLAttributes<HTMLDivElement> {
  type?: 'info' | 'warning' | 'error' | 'success'
  icon?: ReactNode
  btn?: ReactNode
  noIcon?: boolean
  onClose?: () => void
  centerIcon?: boolean
  style?: CSSProperties
}

const backgroundColor = (type: InfoWidgetProps['type']) => {
  switch (type) {
    case 'info': {
      return '#F7F9FA'
    }
    case 'warning': {
      return '#F9F6EA'
    }
    case 'error': {
      return '#FFEEE3'
    }
    case 'success': {
      return '#E5FFD7'
    }
    default: {
      return '#F7F9FA'
    }
  }
}

/** @deprecated Instead of this component use <Informer /> from ui */
export const InfoWidget: FC<InfoWidgetProps> = ({
  className = '',
  children,
  type = 'info',
  btn,
  icon,
  noIcon,
  onClose,
  onClick,
  centerIcon,
  ...restProps
}) => {
  const isMobile = useIsMobileStrict()
  const {classes: c} = useStyles()

  const renderIcon = () => {
    if (icon) {
      return icon
    }

    switch (type) {
      case 'info': {
        return <InfoIcon className={cn(c.info, c.iconMinor, centerIcon && c.centerIcon)} />
      }
      case 'warning': {
        return <ExclamationSharp className={cn(c.info, c.iconWarning, centerIcon && c.centerIcon)} />
      }
      case 'error': {
        return (
          <Error
            data-testid={'error-icon' /*Информационный виджет | Иконка ошибки */}
            className={cn(c.info, centerIcon && c.centerIcon)}
          />
        )
      }
      case 'success': {
        return <Success className={cn(c.info, centerIcon && c.centerIcon)} />
      }
      default: {
        return <InfoIcon className={cn(c.info, c.iconMinor, centerIcon && c.centerIcon)} />
      }
    }
  }

  return (
    <div
      className={cn(c.root, className)}
      style={{backgroundColor: backgroundColor(type)}}
      onClick={isMobile ? onClick : undefined}
      {...restProps}
    >
      {!noIcon && renderIcon()}
      <div
        data-testid={'info-widget-text' /*Время готовки | Текст виджета с предложением поставить ресторан на паузу */}
        className={c.column}
      >
        {children}
        {!!btn && <div className={c.desktopButton}>{btn}</div>}
      </div>
      {onClose && (
        <div className={c.close} onClick={onClose}>
          <CrossRoundFill />
        </div>
      )}
      {onClick && isMobile && (
        <div className={c.mobileChevron}>
          <ChevronRightMIcon />
        </div>
      )}
    </div>
  )
}
