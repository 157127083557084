import {makeStyles} from 'tss-react/mui'

export default makeStyles({name: 'NotAvailableSelect'})({
  notAvailableContainer: {
    display: 'flex'
  },
  notAvailableText: {
    alignSelf: 'center',
    fontSize: 14
  },
  notAvailableSelect: {
    marginLeft: 6,
    alignSelf: 'center',
    whiteSpace: 'nowrap'
  },
  available: {
    color: '#B0B0B0'
  }
})
