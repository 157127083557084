import {useI18n} from '@eda-restapp/i18n'
import {Button, UserAvatar, Box, Typography, CrossLIcon, useIsMobileStrict} from '@eda-restapp/ui'
import React, {useState} from 'react'

import {ActivePartnerId} from '../ActivePartnerId'

import {AccountOnboardingTooltip} from './AccountOnboardingTooltip'
import styles from './AccountPopover.module.css'
import {ActivePartnerSkeleton} from './ActivePartnerSkeleton'

export type AccountPopoverProps = {
  avatarSrc: string
  login: string
  cabinetName: string
  placesCount: number
  linkedUsers: {login: string; avatarSrc?: string}[]
  showTooltip: boolean
  onCloseTooltip: React.MouseEventHandler<HTMLButtonElement>
  onClose: () => void
  onOpenAccount: () => void
  onLogout: () => void
  onSelectCabinet?: () => void
  isPlacesLoading: boolean
  isUsersLoading: boolean
}

export const AccountPopover: React.FC<AccountPopoverProps> = ({
  avatarSrc,
  login,
  cabinetName,
  placesCount,
  linkedUsers,
  showTooltip,
  onCloseTooltip,
  onClose,
  onLogout,
  onOpenAccount,
  onSelectCabinet,
  isPlacesLoading,
  isUsersLoading
}) => {
  const {t} = useI18n()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMobile = useIsMobileStrict()

  return (
    <div className={styles.root} data-testid='account-popover'>
      <CrossLIcon className={styles.cross} onClick={onClose} />
      <Box className={styles.content} flexDirection='column' alignItems='center'>
        <UserAvatar src={avatarSrc} size={80}></UserAvatar>
        <Typography
          variant='body1'
          thickness='bold'
          lineHeight='tight'
          mt='m'
          mb='l'
          className={styles.loginText}
          data-testid={'account-popover-login-text' /*Паспортный логин в поповере */}
        >
          {login}
        </Typography>
        {isPlacesLoading || isUsersLoading ? (
          <ActivePartnerSkeleton />
        ) : (
          <ActivePartnerId
            name={cabinetName}
            placesCount={placesCount}
            linkedUsers={linkedUsers}
            onChangePartnerId={onSelectCabinet}
          />
        )}
      </Box>
      <div className={styles.controls}>
        <Button.Text
          ref={setAnchorEl}
          data-testid='account-popover-manage-account-button'
          variant='secondary'
          fullWidth
          onClick={onOpenAccount}
        >
          {t('core-auth.passport.account-popover_manage-account-button', 'Управлять аккаунтом')}
        </Button.Text>
        {anchorEl && (
          <AccountOnboardingTooltip
            anchorEl={anchorEl}
            open={showTooltip}
            text={t(
              'core-auth.passport.account-popover.tooltip-text',
              'Здесь можно менять настройки личного кабинета и управлять пользователями'
            )}
            buttonText={t('core-auth.passport.account-popover.tooltip-button-text', 'Буду знать')}
            placement={isMobile ? 'top' : 'top-end'}
            offsetDistance={isMobile ? 8 : undefined}
            onClose={onCloseTooltip}
          />
        )}
        <Button.Text variant='transparent' fullWidth onClick={onLogout}>
          {t('core-auth.passport.account-popover_logout-button', 'Выйти из вендор')}
        </Button.Text>
      </div>
    </div>
  )
}
