import type {ConfigsWithDefaults} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Box, Tooltip, QuestionFill} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type ComponentProps, useState} from 'react'

import {Popover} from '@restapp/shared-ui'

import HelpMenu from '../HelpMenu'

import styles from './HelpButton.module.css'

export type HelpButtonProps = {
  section: ConfigsWithDefaults['restapp_support_links']['sections'][0]
  onOpen: () => void
  onClose: () => void
  className?: string
}

const HelpButton = ({section, onOpen, onClose, className}: HelpButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const {t} = useI18n()

  const closePopover = () => setOpen(false)
  const openPopover = () => setOpen(true)

  const openHandler = () => {
    openPopover()
    onOpen()
  }
  const closeHandler = () => {
    closePopover()
    onClose()
  }

  const PaperComponent: NonNullable<ComponentProps<typeof Popover>['PaperProps']>['component'] = React.forwardRef(
    function PaperComponent(props: Parameters<typeof Popover>[0], ref: React.Ref<HTMLDivElement>) {
      return (
        <HelpMenu
          ref={ref}
          {...props}
          section={section}
          className={cn(props.className, styles.paper)}
          onItemClick={closePopover}
        />
      )
    }
  )

  return (
    <Popover
      open={open}
      onClose={closeHandler}
      onOpen={openHandler}
      className={cn(styles.helpButton, className)}
      PaperProps={{
        component: PaperComponent
      }}
      control={(handleClick) => (
        <Box ref={setAnchorEl} onClick={handleClick}>
          <QuestionFill />
          {anchorEl && (
            <Tooltip.Info anchorEl={anchorEl} offsetDistance={16}>
              {t('shared-support.help-button.tooltip-label', 'Справка')}
            </Tooltip.Info>
          )}
        </Box>
      )}
    />
  )
}

export default HelpButton
