import {useI18n} from '@eda-restapp/i18n'
import {SurgeM, Button, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import type moment from 'moment'
import React from 'react'

import {Button as OldButton} from '@restapp/shared-ui'

import useBusyModeButtonStyles from './BusyModeButton.styles'
import {BusyModeCountdown} from './BusyModeCountdown'

export interface BusyModeButtonProps {
  className?: string
  isActive?: boolean
  activeUntil?: moment.Moment
  onClick: () => void
  onFinish?: () => Promise<void>
  id?: string
}

const BusyModeButton: React.FC<BusyModeButtonProps> = ({className, isActive, activeUntil, onClick, onFinish, id}) => {
  const {classes: c} = useBusyModeButtonStyles()
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  if (!isMobile) {
    return (
      <OldButton
        id={id}
        slug='busy_mode_button'
        className={cn(className, {[c.active]: isActive})}
        variant='contained'
        color='secondary'
        startIcon={<SurgeM />}
        onClick={onClick}
      >
        {t('main-orders.busy-mode.button-text', 'Высокая загрузка')}
        {isActive && activeUntil && (
          <BusyModeCountdown className={cn(c.countdown, c.countdownDesktop)} until={activeUntil} onFinish={onFinish} />
        )}
      </OldButton>
    )
  }

  return (
    <Button.Icon variant='secondary' onClick={onClick}>
      <SurgeM />
    </Button.Icon>
  )
}

export const BusyModeButtonSmall: React.FC<BusyModeButtonProps> = ({
  className,
  isActive,
  activeUntil,
  onClick,
  onFinish,
  id
}) => {
  const {classes: c} = useBusyModeButtonStyles()

  return (
    <OldButton
      id={id}
      size='small'
      slug='busy_mode_button'
      style={{borderRadius: 10}}
      data-testid={'busy-mode-small-button' /* Высокая загрузка | Кнопка вызова модалки. */}
      className={cn(c.smallButton, {[c.active]: isActive}, {[c.smallWidth]: !(isActive && activeUntil)}, className)}
      variant='contained'
      color='secondary'
      onClick={onClick}
    >
      <SurgeM fontSize='19px' />
      {isActive && activeUntil && <BusyModeCountdown className={c.countdown} until={activeUntil} onFinish={onFinish} />}
    </OldButton>
  )
}

export default BusyModeButton
