import {isNull} from 'lodash-es'
import moment from 'moment-timezone'

import {convertMinToTime, timeHumanize} from '@restapp/shared/utils'

import {PlaceDisableReason} from '../../../../DisablePlaceModal.constants'

export const getHumanMaxTime = (maxTime: string, reasonCode: PlaceDisableReason | null) => {
  if (isNull(reasonCode)) {
    return ''
  }

  const minutes = convertMinToTime(moment(maxTime).diff(moment(), 'minutes'))
  const {day, hour, min} = timeHumanize(minutes)

  const ReasonTimeSchemes = {
    [PlaceDisableReason.NOT_KEEP_UP]: `${hour.value} ${hour.label} ${min.value} ${min.label}`,
    [PlaceDisableReason.EVENT]: `${day.value} ${day.label}`,
    [PlaceDisableReason.OTHER]: `${day.value} ${day.label}`
  }

  return ReasonTimeSchemes[reasonCode]
}
