import type {FC} from 'react'
import {lazy} from 'react'

import {Suspense} from '@restapp/shared-boundary'

import type {SubscriptionModalsContainerProps} from './SubscriptionModalsContainer'

const SubscriptionModalsContainer = lazy(() =>
  import(
    /* webpackChunkName: "@restapp/subscription/SubscriptionModalsContainer" */ './SubscriptionModalsContainer'
  ).then((module) => {
    return {default: module.SubscriptionModalsContainer}
  })
)

export const SubscriptionModals: FC<SubscriptionModalsContainerProps> = (props) => {
  return (
    <Suspense fallback={''}>
      <SubscriptionModalsContainer {...props} />
    </Suspense>
  )
}
