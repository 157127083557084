import {useI18n} from '@eda-restapp/i18n'
import {Typography, Box, Button, Copy2Icon, Coupon, Tooltip} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useState, forwardRef} from 'react'

import styles from './TicketPromocode.module.css'

const OFFSET_DISTANCE = 7

export type TicketPromocodeProps = {
  expirationDate?: string
  promoDescription?: string
  promocode: string
  copied?: boolean
  isRow?: boolean
  placement?: 'top' | 'bottom'
  separationLineColor?: string
  onCopy: () => void
}
export type TicketPromocodetRef = HTMLDivElement

export const TicketPromocode = forwardRef<TicketPromocodetRef, TicketPromocodeProps>(
  (
    {
      expirationDate,
      promoDescription,
      promocode,
      copied,
      isRow,
      placement = 'bottom',
      separationLineColor = 'white',
      onCopy
    },
    forwardedRef
  ) => {
    const {t, tRaw} = useI18n()
    const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)

    return (
      <>
        <Box className={styles.root} ref={forwardedRef}>
          <div
            className={cn(styles.separationLine, styles[`placement_${placement}`])}
            style={{
              backgroundImage: `radial-gradient(circle, ${separationLineColor}, ${separationLineColor} 50%, transparent 50%, transparent 100%)`
            }}
          ></div>
          <Box flexDirection='column' gap='s' alignItems={isRow ? 'flex-start' : 'stretch'} flexGrow={1}>
            <Box flexDirection={isRow ? 'row-reverse' : 'column'} gap='s' alignItems='flex-start'>
              {!!expirationDate && (
                <div className={styles.date}>
                  {tRaw('promotion.ticket-promocode.date', 'до {date}', {date: expirationDate})}
                </div>
              )}
              {!!promoDescription && (
                <Typography variant='body2' lineHeight='tight' thickness='medium' color='invert'>
                  {promoDescription}
                </Typography>
              )}
            </Box>

            <Box
              flexDirection={isRow ? 'row' : 'column'}
              gap='s'
              alignItems={isRow ? 'center' : 'flex-start'}
              flexGrow={1}
            >
              <Button.Base
                variant='outline'
                size='m'
                baseClasses={styles.button}
                fullWidth={isRow ? false : true}
                onClick={onCopy}
                ref={setTriggerElement}
                baseStyles={{width: isRow ? 260 : undefined}}
              >
                <Box alignItems='center' gap='s' flexGrow={1}>
                  <Coupon />
                  {promocode}
                  <Copy2Icon className={styles.iconCopy} />
                </Box>
              </Button.Base>
              <Typography
                variant='caption2'
                color='invert'
                lineHeight='normal'
                className={cn(styles.manual, {[styles.isRow]: !!isRow})}
              >
                {t(
                  'promotion.ticket-promocode.manual',
                  'Скопируйте промокод, для оплаты как юр. лицо или ИП. Для физ. лиц применится автоматически'
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        {!!copied && triggerElement && (
          <Tooltip.Info
            offsetDistance={OFFSET_DISTANCE}
            anchorEl={triggerElement}
            placement={'bottom'}
            withPortal={false}
          >
            <Typography variant='caption1'>
              {t('promotion.ticket-promocode.copy-tooltip', 'Промокод скопирован')}
            </Typography>
          </Tooltip.Info>
        )}
      </>
    )
  }
)

TicketPromocode.displayName = 'TicketPromocode'
