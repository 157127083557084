import {makeStyles} from 'tss-react/mui'

type Props = {
  status?: string
  hasLowRatingError?: boolean
}

const useStyles = makeStyles<Props>({
  name: 'RestaurantListItemPlus'
})((theme, {hasLowRatingError, status}) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    mainWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsButton: {
      borderRadius: 12,
      padding: 8,
      minWidth: 0,

      '&:hover': {
        backgroundColor: '#E2E4E7'
      }
    },
    arrow: {
      alignSelf: 'center',
      backgroundSize: 'cover'
    },
    arrowUp: {
      composes: '$arrow',
      transform: 'rotate(180deg)'
    },
    condition: {
      marginTop: 18
    },
    status: {
      marginTop: 2,
      color: (() => {
        switch (status) {
          case 'active':
            return '#1CC052'
          case 'process':
            return '#999C9E'
          case 'suspended':
            return hasLowRatingError ? '#F5523A' : '#999C9E'
          case 'create_error':
          case 'can_not_be_activated':
            return '#F5523A'
          default:
            return '#999C9E'
        }
      })()
    }
  }
})

export default useStyles
