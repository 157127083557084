import {AppUpdateNoSwSupport} from './AppUpdateNoSwSupport'
import {ServiceWorker} from './ServiceWorker'
import {AppUpdate} from './AppUpdate'
import {useExp3} from '@eda-restapp/configs'
import {memo} from 'react'

const isServiceWorkerSupported = 'serviceWorker' in navigator

export const ServiceWorkerContainer = memo(() => {
  const {enableServiceWorker} = useExp3('restapp_common')

  if (!isServiceWorkerSupported || !enableServiceWorker) {
    // Fallback to legacy update mechanism
    return <AppUpdateNoSwSupport />
  }

  return (
    <ServiceWorker>
      {(workbox) => (
        <>
          <AppUpdate workbox={workbox} />
        </>
      )}
    </ServiceWorker>
  )
})
