import type {FC} from 'react'
import React from 'react'
import {useStyles} from './SliderInput.style'
import type {SliderClassKey} from '@mui/material/Slider'
import {Plus, Minus} from '@eda-restapp/ui'
import {ButtonBase, Slider} from '@mui/material'
import {clamp} from 'lodash-es'
import cn from 'classnames'

export type SliderInputProps = {
  withButtons?: boolean
  disabled?: boolean
  marks?: boolean | Array<{label?: React.ReactNode; value: number}>
  min?: number
  max?: number
  onChange?: (value: number) => void
  step?: number | null
  value?: number
  buttonStep?: number
  className?: string
  defaultValue?: number
  classes?: Partial<Record<SliderClassKey, string>>
}

const SliderInput: FC<SliderInputProps> = ({
  disabled,
  max = 100,
  min = 0,
  onChange,
  marks,
  step,
  value,
  withButtons,
  buttonStep,
  className,
  defaultValue,
  classes
}) => {
  const {classes: c} = useStyles({withBtns: withButtons})

  const btnClickHandler = (plus: boolean) => {
    const stepValue = buttonStep || step || 1
    const val = value || min || 0

    if (onChange) {
      const newValue = plus ? val + stepValue : val - stepValue
      onChange(clamp(newValue, min, max))
    }
  }

  const isMinusBtnDisabled = disabled || (typeof value === 'number' && value <= min)
  const isPlusBtnDisabled = disabled || (typeof value === 'number' && value >= max)

  return (
    <div className={cn(c.root, className)} data-swipe-disable>
      {withButtons && (
        <ButtonBase className={c.btn} disabled={isMinusBtnDisabled} onClick={() => btnClickHandler(false)}>
          <Minus />
        </ButtonBase>
      )}

      <Slider
        disabled={disabled}
        max={max}
        min={min}
        step={step}
        value={value}
        marks={marks}
        defaultValue={defaultValue}
        classes={{
          rail: classes?.rail ?? c.rail,
          track: classes?.track ?? c.track,
          thumb: classes?.thumb ?? c.thumb,
          ...classes
        }}
        onChange={(evt, value) => {
          if (onChange && !Array.isArray(value)) {
            onChange(value)
          }
        }}
      />

      {withButtons && (
        <ButtonBase className={c.btn} disabled={isPlusBtnDisabled} onClick={() => btnClickHandler(true)}>
          <Plus />
        </ButtonBase>
      )}
    </div>
  )
}

export default SliderInput
