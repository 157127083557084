export function loadScript(src: string, id?: string) {
  return new Promise<Event>((resolve) => {
    const script = document.createElement('script')
    script.src = src

    if (id) {
      script.id = id
    }

    script.async = true
    script.onload = resolve
    document.head?.appendChild(script)
  })
}
