import type {SupportTreeElement} from '../SupportTree.types'

export const getTreeNode = (nodes: SupportTreeElement[], path: string[]): SupportTreeElement => {
  if (path.length && !nodes.length) {
    throw new Error('Empty nodes argument, on not empty path')
  }

  const nextNode = nodes.find((currentNode) => currentNode.id === path[0])

  if (!nextNode) {
    throw new Error("Argument 'nodes' not includes node with current id")
  }

  if (path.length === 1) {
    return nextNode
  }

  if (!nextNode.tree_elements) {
    throw new Error('Empty tree_elements property on not leaf element')
  }

  return getTreeNode(nextNode.tree_elements, path.slice(1))
}
