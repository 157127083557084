import {CloudUploadIcon} from '@eda-restapp/ui'
import type {ButtonProps} from '@mui/material/Button'
import cn from 'classnames'
import React, {type FC} from 'react'

import {Button} from '../Button/Button'

import styles from './FileUpload.module.css'

type FileUploadProps = {
  /** @default 'image/jpeg,image/jpg' */
  accept?: string[]
  icon?: React.ReactNode
  disabled: boolean
  multiple?: boolean
  text: string
  onChange?: (file: File) => void
  className?: string
  loading?: boolean
  color?: ButtonProps['color']
}

const DEFAULT_MIME = 'image/jpeg,image/jpg'

export const FileUpload: FC<FileUploadProps> = ({
  multiple,
  text,
  disabled,
  accept,
  icon,
  onChange,
  className,
  loading,
  color = 'secondary'
}) => {
  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0]
    if (onChange && file) {
      onChange(file)
    }

    // Сбрасываем значение чтобы инпут реагировал на загрузку того же файла
    evt.target.value = ''
  }

  return (
    <div className={cn(styles.root, className)} data-testid={'ui-file-upload' /*UI | Загрузчик файлов*/}>
      <Button
        disabled={disabled}
        variant='contained'
        color={color}
        fullWidth
        startIcon={icon || icon === null ? icon : <CloudUploadIcon />}
        loading={loading}
      >
        {text}
      </Button>
      <input
        className={styles.input}
        accept={accept ? accept.join(',') : DEFAULT_MIME}
        disabled={disabled}
        onChange={changeHandler}
        multiple={multiple}
        type='file'
        data-testid={'ui-file-upload-input' /*UI | input элемент загрузки файлов*/}
      />
    </div>
  )
}
