import {useI18n} from '@eda-restapp/i18n'
import {Box} from '@eda-restapp/ui'
import {FormControlLabel} from '@mui/material'
import cn from 'classnames'
import React, {type FC, useState} from 'react'

import {Checkbox} from '../../../Checkbox'

import {TimeSelectorSwitch} from './TimeSelectorSwitch'
import styles from './TimeSelector.module.css'

/**
 * Компонент #TimeSelector
 * формирует селектор выбора (не) работаем весь день
 */

type TimeSelectorProps = {
  allDay?: boolean
  className?: string
  disabled?: boolean
  disabledAllDay?: boolean
  hasDisabledAllDay?: boolean
  setAllDay(status: boolean): void
  setDisabledAllDay?(status: boolean): void
}

export const TimeSelector: FC<TimeSelectorProps> = ({
  allDay,
  className,
  disabled,
  disabledAllDay,
  hasDisabledAllDay,
  setAllDay,
  setDisabledAllDay
}) => {
  const getDefaultEdgeSchedule = (allDay?: boolean, disabledAllDay?: boolean) => {
    if (allDay) {
      return 'work-24'
    }

    return disabledAllDay ? 'not-work' : ''
  }

  const [selectedEdgeSchedule, setSelectedEdgeSchedule] = useState(getDefaultEdgeSchedule(allDay, disabledAllDay))

  const {t} = useI18n()

  const switchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = ({target}) => {
    if (target.name === selectedEdgeSchedule) {
      setSelectedEdgeSchedule('')
      setAllDay(false)
      setDisabledAllDay?.(false)
    } else {
      setSelectedEdgeSchedule(target.name)
      target.name === 'work-24' ? setAllDay(true) : setDisabledAllDay?.(true)
    }
  }

  if (disabled) {
    return null
  }

  return (
    <div className={cn(styles.root, className)}>
      {hasDisabledAllDay && setDisabledAllDay ? (
        <Box flexDirection='column' gap='m'>
          <TimeSelectorSwitch
            name='work-24'
            active={selectedEdgeSchedule === 'work-24'}
            onChange={switchChangeHandler}
            label={t('shared-ui.time-range.rabotaem-kruglosutochno', 'Работаем круглосуточно')}
          />
          <TimeSelectorSwitch
            name='not-work'
            active={selectedEdgeSchedule === 'not-work'}
            onChange={switchChangeHandler}
            label={t('shared-ui.time-range.ne-rabotaem-ves-den', 'Не работаем весь день')}
          />
        </Box>
      ) : (
        <FormControlLabel
          classes={{root: styles.formControlLabelRoot, label: styles.formControlLabel}}
          control={
            <Checkbox
              data-testid={'shedule-all-day-checkbox' /*График работы | Чекбокс весь день*/}
              checked={allDay}
              onChange={(event, checked) => setAllDay(checked)}
            />
          }
          label={t('main-promos.time-promo.ves-den', 'Весь день')}
        />
      )}
    </div>
  )
}
