import {type ApiQueryConfig} from '@restapp/core-api'
import {useApiQuery} from '@restapp/core-api'
import type {OldFullRestaurant} from '@restapp/shared-api/types/places'

import type {CommonPlaces} from './usePlaces'
import {usePlacesMap} from './usePlacesMap'

type UseOldHeavyPlacesOptions = Omit<ApiQueryConfig<'/4.0/restapp-front/places/v1/search', 'GET'>, 'url' | 'method'> & {
  enabled?: boolean
  filterPlaces?: (place: OldFullRestaurant) => boolean
}

type FullPlaces = CommonPlaces & {
  places: OldFullRestaurant[]
  getPlaceById: (placeId: number) => OldFullRestaurant | undefined
  getPlacesByBusinessType: (businessType: string) => OldFullRestaurant[]
  dataUpdatedAt: number
}

export const useOldHeavyPlaces = ({enabled, filterPlaces, ...props}: UseOldHeavyPlacesOptions = {}): FullPlaces => {
  const oldHeavyPlacesQuery = useApiQuery<'/4.0/restapp-front/places/v1/search', 'GET'>({
    url: '/4.0/restapp-front/places/v1/search',
    method: 'GET',
    params: {cursor: 0, limit: 999},
    initialData: window.initialHeavyPlaces,
    enabled,
    select: filterPlaces
      ? (response) => {
          return {...response, payload: response.payload.filter(filterPlaces)}
        }
      : undefined,
    ...props
  })

  const places = oldHeavyPlacesQuery.data?.payload || []

  const getPlacesByBusinessType = (businessType: string) =>
    places.filter((place) => place.brand.business_type === businessType)

  const {getPlaceById} = usePlacesMap(places)

  return {
    dataUpdatedAt: oldHeavyPlacesQuery.dataUpdatedAt,
    error: oldHeavyPlacesQuery.error,
    isLoading: oldHeavyPlacesQuery.isLoading,
    isFetched: oldHeavyPlacesQuery.isFetched,
    refetch: oldHeavyPlacesQuery.refetch,
    places: places,
    getPlaceById,
    getPlacesByBusinessType
  }
}
