import {sortBy} from 'lodash-es'

import type {Category} from '@restapp/shared-api'

export const getEnabledCategories = (categories: Category[]) =>
  sortBy(
    categories.filter((category) => category.available),
    'sortOrder'
  )

export const isRequiredModifierGroup = (minSelectedModifiers: number) => minSelectedModifiers >= 1

export const getItemById = <T extends {id: string | number}>(items: T[], itemId: string | number) =>
  items.find((item) => item.id === itemId) ?? null
