import {type CountryCodeType, useDomainDividedCountriesInfo, useI18n} from '@eda-restapp/i18n'
import {MenuItem} from '@mui/material'
import React from 'react'

import {getCountryFlag} from '@restapp/shared/utils'
import {ContentContainer} from '@restapp/shared-ui/ContentContainer'

import {useServiceBrand} from '../../../hooks'

import useCountrySelectStyles from './CountrySelect.styles'

type CountrySelectProps = {
  onCountrySelect: (country: CountryCodeType) => void
}

export const CountrySelect: React.FC<CountrySelectProps> = ({onCountrySelect}) => {
  const isYangoBrand = useServiceBrand() === 'YangoEats'
  const {t} = useI18n()
  const {classes: c} = useCountrySelectStyles()
  const domainDividedCountriesInfo = useDomainDividedCountriesInfo()

  const COUNTRIES: Partial<Record<CountryCodeType, {countryName: string; localCountryName: string}>> = isYangoBrand
    ? {
        ...domainDividedCountriesInfo['YangoEats']
      }
    : {
        ...domainDividedCountriesInfo['YandexEda'],
        ...domainDividedCountriesInfo['YandexEats']
      }

  return (
    <ContentContainer className={c.root}>
      <div className={c.container}>
        <div className={c.header}>
          {isYangoBrand
            ? t('core-auth.country-select-auth.content-container-header-label-eng', 'Choose your country')
            : t('core-auth.country-select-auth.content-container-header-label', 'Выберите свою страну')}
        </div>
        <div
          data-testid={
            'auth-country-selector-auth-list' /* Auth | List of available countries to select for choosing right service domain*/
          }
          className={c.countryCardsBlock}
        >
          {Object.entries(COUNTRIES).map(([country, {localCountryName}]) => {
            const CountryFlag = getCountryFlag(country as CountryCodeType)

            return (
              <MenuItem
                className={c.menuItem}
                key={country}
                value={country}
                onClick={() => onCountrySelect(country as CountryCodeType)}
              >
                <div>{CountryFlag && <CountryFlag />}</div>
                <div className={c.menuItemCountryName}>{localCountryName}</div>
              </MenuItem>
            )
          })}
        </div>
      </div>
    </ContentContainer>
  )
}
