import moment from 'moment'

let timeDiff = 0

export function getServiceTime() {
  return moment().add(timeDiff, 'ms')
}

// Вызывается в файле bundleUpdate
// Подвязываемся на получение манифеста и вытаскиваем из хедеров ответа правильное время по utc,
// на основе которого корректируем текущее локальное время
export function updateTimeDiff(newDiff: number) {
  timeDiff = newDiff
}
