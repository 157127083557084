import {getHrefWithTarget} from '@eda-restapp/ui'

export function patchWindowOpen() {
  const originalOpen = window.open

  window.open = (href, target, features) => {
    if (target && href) {
      const hrefWithTarget = getHrefWithTarget(href, target)
      return originalOpen(hrefWithTarget, target, features)
    } else {
      return originalOpen(href, target, features)
    }
  }
}
