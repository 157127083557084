import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Box, Button, Dialog, Typography, Grid} from '@eda-restapp/ui'
import moment from 'moment'
import type {FC} from 'react'
import React from 'react'

import {useSupportChat} from '@restapp/main-chats'
import type {Service, PlacePlus} from '@restapp/shared-api'

import {TooltipIcon} from '../../TooltipIcon'
import {PlacesListPlus} from '../PlacesListPlus'

import styles from './EnablePlusModal.module.css'

type IContractPlaces = PlacePlus & {enableServices?: Service[]}

interface EnablePlusModalProps {
  services: Service[]
  close: () => void
  startDate: {
    eda?: string
    dc?: string
  }
  opened: boolean
  isOnePlace?: boolean
  isOneUnitedPlace?: boolean
  variant: 'success' | 'partSuccess' | 'onlyContract'
  contractPlaces: IContractPlaces[]
  amountPlacesWithEnablePlus: number
}

const EnablePlusModal: FC<EnablePlusModalProps> = ({
  opened,
  services,
  close,
  startDate,
  contractPlaces,
  isOnePlace,
  isOneUnitedPlace,
  variant,
  amountPlacesWithEnablePlus
}) => {
  const {t} = useI18n()
  const {openNewSupportChat} = useSupportChat()
  const {supportChatToPlusPath} = useExp3('restapp_plus')

  const dcDate =
    !startDate.dc || moment(startDate.dc).isBefore(moment().add(1, 'days')) ? undefined : moment(startDate.dc)
  const edaDate =
    !startDate.eda || moment(startDate.eda).isBefore(moment().add(1, 'days')) ? undefined : moment(startDate.eda)

  const contractServices = contractPlaces?.reduce<Service[]>((placesServices, place) => {
    if (placesServices.length > 1) {
      return placesServices
    }

    if (place.enableServices) {
      place.enableServices.forEach((service) => {
        if (!placesServices.includes(service)) {
          placesServices.push(service)
        }
      })
    }

    return placesServices
  }, [])

  const contractPlacesIds = contractPlaces.map((place) => place.id)

  const handleEnablePlusForContract = () => {
    eventLogger({name: 'plus-drawer-enable-contract-plus', additional: {contractPlacesIds}})

    openNewSupportChat({
      place_id: contractPlacesIds[0],
      place_ids: contractPlacesIds.join(', '),
      path: supportChatToPlusPath
    })
  }

  const renderText = () => {
    if (services.length === 2) {
      return edaDate && dcDate
        ? t(
            'shared-places.enable-plus-success-modal.plus-in-service-eda-and-dc-with-date',
            'Плюс на сервисе {dcServiceBrandLabel} начнёт действовать с {date}, а на сервисе {eatsServiceBrandLabel} — уже завтра.',
            {
              dcDate: dcDate.format('D MMMM YYYY'),
              edaDate: edaDate.format('D MMMM YYYY')
            }
          )
        : t(
            'shared-places.enable-plus-success-modal.plus-in-service-eda-and-dc-tomorrow',
            'Плюс на сервисах {dcServiceBrandLabel} и {eatsServiceBrandLabel} будет доступен завтра.'
          )
    }

    if (services[0] === 'dc') {
      return dcDate
        ? t(
            'shared-places.enable-plus-success-modal.plus-in-dc-service-with-date',
            'Плюс на сервисе {dcServiceBrandLabel} начнёт действовать с {date}.',
            {
              date: dcDate.format('D MMMM YYYY')
            }
          )
        : t(
            'shared-places.enable-plus-success-modal.plus-in-dc-service',
            'Плюс на сервисе {dcServiceBrandLabel} начнёт действовать уже завтра.'
          )
    }

    return edaDate
      ? t(
          'shared-places.enable-plus-success-modal.plus-in-dc-service-with-date',
          'Плюс на сервисе {dcServiceBrandLabel} начнёт действовать с {date}.',
          {
            date: edaDate.format('D MMMM YYYY')
          }
        )
      : t(
          'shared-places.enable-plus-success-modal.plus-in-eda-service',
          'Плюс на сервисе {eatsServiceBrandLabel} начнёт действовать уже завтра.'
        )
  }

  const serviceSuccessTitle =
    contractServices[0] === 'eda'
      ? t(
          'shared-places.enable-plus-modal.now-you-are-in-plus-in-dc',
          'Теперь вы в Плюсе на сервисе {dcServiceBrandLabel}!'
        )
      : t(
          'shared-places.enable-plus-modal.now-you-are-in-plus-in-eda',
          'Теперь вы в Плюсе на сервисе {eatsServiceBrandLabel}!'
        )

  const enablePlusUnitedPlaceSuccessTitle =
    isOneUnitedPlace && contractServices.length < 2
      ? serviceSuccessTitle
      : t('shared-places.enable-plus-modal.now-you-are-in-plus-restaurants-amount', '{count} ресторан/а/ов в Плюсе', {
          none: 'нет ресторанов',
          one: '{count} ресторан уже в Плюсе',
          some: '{count} ресторана уже в Плюсе',
          many: '{count} ресторанов уже в Плюсе',
          count: amountPlacesWithEnablePlus
        })

  const successTitles =
    contractPlaces.length < 1
      ? t('main-places.enable-plus-success-modal.now-you-are-in-plus', 'Теперь вы в Плюсе!')
      : enablePlusUnitedPlaceSuccessTitle

  const sendToPlusText =
    contractServices[0] === 'eda'
      ? t(
          'shared-places.enable-plus-modal.send-application-to-plus-eda',
          'Отправить заявку в Плюс на сервис {eatsServiceBrandLabel}'
        )
      : t(
          'shared-places.enable-plus-modal.send-application-to-plus-dc',
          'Отправить заявку в Плюс на сервис {dcServiceBrandLabel}'
        )

  const tooltipServiceText =
    contractServices[0] === 'eda'
      ? t(
          'shared-places.enable-plus-modal.contract-info-for-partner-eda',
          'Вы работаете на сервисе {eatsServiceBrandLabel} по бумажному договору, поэтому для Плюса подготовим дополнительное соглашение. Подпишите его и отправьте нам — Плюс подключится на следующий день.'
        )
      : t(
          'shared-places.enable-plus-modal.contract-info-for-partner-dc',
          'Вы работаете на сервисе {dcServiceBrandLabel} по бумажному договору, поэтому для Плюса подготовим дополнительное соглашение. Подпишите его и отправьте нам — Плюс подключится на следующий день.'
        )

  return (
    <Dialog open={opened} onClose={close}>
      <Dialog.Header
        text={
          variant === 'onlyContract'
            ? t('shared-places.contract-places-modal.apply-to-plus', 'Отправить заявку в Плюс?')
            : successTitles
        }
      />

      <Dialog.Content>
        {variant === 'success' || variant === 'partSuccess' ? (
          <Box
            flexDirection='column'
            data-testid={'plus-modal-description' /* Модальное окно при подключении Плюса | Описание */}
          >
            <Typography variant='body2' lineHeight='normal' thickness='regular'>
              {renderText()}
            </Typography>
            <br />
            <Typography variant='body2' lineHeight='normal' thickness='regular'>
              {t(
                'shared-places.enable-plus-success-modal.control-plus-location',
                'Управлять Плюсом можно в разделах «Рестораны» и «Инструменты».'
              )}
            </Typography>
          </Box>
        ) : (
          <Typography
            variant='body2'
            lineHeight='normal'
            data-testid={
              'plus-contract-modal-description' /* Модальное окно при подключении Плюса для бумажников| Описание */
            }
          >
            {t(
              'shared-places.contract-places-modal.apply-to-plus-description',
              'Вы работаете с нами по бумажному договору, поэтому для Плюса подготовим дополнительное соглашение. Подпишите его и отправьте нам — Плюс подключится на следующий день.'
            )}
          </Typography>
        )}

        {variant === 'partSuccess' && (
          <Box mt='m' flexDirection='column'>
            <div className={styles.line} />
            <Box mt='m'>
              <Typography variant='body2' lineHeight='normal' thickness='medium'>
                {contractServices.length < 2
                  ? sendToPlusText
                  : t('shared-places.enable-plus-modal.send-application-to-plus', 'Отправить заявку в Плюс')}
              </Typography>
              <TooltipIcon
                iconClassName={styles.infoIcon}
                data-testid={
                  'plus-contract-modal-tooltip' /* Модальное окно при подключении Плюса для бумажников| Подсказка */
                }
                tooltipText={
                  contractServices.length < 2
                    ? tooltipServiceText
                    : t(
                        'shared-places.enable-plus-modal.contract-info-for-partner-several-servives',
                        'Вы работаете с нами по бумажному договору, поэтому для Плюса подготовим дополнительное соглашение. Подпишите его и отправьте нам — Плюс подключится на следующий день.'
                      )
                }
              />
            </Box>
          </Box>
        )}

        {variant !== 'success' && !isOnePlace && (
          <Box mt='m' flexDirection='column'>
            <PlacesListPlus places={contractPlaces} initialExpanded={false} isSelect />
          </Box>
        )}
      </Dialog.Content>

      <Dialog.Footer>
        {variant === 'success' ? (
          <Button.Text data-testid='plus-modal-excellent-button' fullWidth onClick={close}>
            {t('main-places.enable-plus-success-modal.otlichno', 'Отлично')}
          </Button.Text>
        ) : (
          <>
            <Grid container spacing={8} columns={2} style={{width: '100%'}}>
              <Grid item>
                <Button.Text
                  data-testid='plus-contract-modal-later-button'
                  variant='secondary'
                  fullWidth
                  onClick={close}
                >
                  {t('shared-places.contract-places-modal.later-button-text', 'Позже')}
                </Button.Text>
              </Grid>
              <Grid item>
                <Button.Text
                  data-testid='plus-contract-modal-send-button'
                  fullWidth
                  onClick={handleEnablePlusForContract}
                >
                  {t('shared-places.contract-places-modal.send-button-text', 'Отправить')}
                </Button.Text>
              </Grid>
            </Grid>
          </>
        )}
      </Dialog.Footer>
    </Dialog>
  )
}

export default EnablePlusModal
