import type {HTMLAttributes, ReactNode} from 'react'
import React, {useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {ChevronDownLIcon} from '@eda-restapp/ui'
import {SectionStyles} from './Section.styles'

import {Collapse} from '@mui/material'
import {eventLogger} from '@eda-restapp/logger'
import cn from 'classnames'

export type SectionProps = {
  /* Используется в заголовке если не передан title */
  children: React.ReactNode
  'data-testid'?: string
  /* Кастомный тайтл если если не хватает просто строки в name */
  title: string
  titleJsx?: ReactNode
  tag?: React.ReactNode
  defaultExpanded?: boolean
  metrikaParams?: Record<string, unknown>
  slug?: string
  onExpand?(status: boolean): void
} & {
  className?: string
  classes?: Partial<Record<keyof typeof SectionStyles, string>>
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>

const useStyles = makeStyles()(SectionStyles)

function Section({
  onExpand,
  title,
  titleJsx,
  children,
  classes,
  tag,
  defaultExpanded = true,
  metrikaParams,
  slug,
  'data-testid': testid,
  ...restProps
}: SectionProps) {
  const {classes: c} = useStyles(undefined, {
    props: {
      classes: classes
    }
  })
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleClick = () => {
    const newExpanded = !expanded
    onExpand?.(newExpanded)
    setExpanded(newExpanded)
    eventLogger({
      name: 'ui_section_expand',
      value: slug,
      additional: {name: title, expanded: newExpanded, ...metrikaParams}
    })
  }

  return (
    <div className={c.root} {...restProps}>
      <div data-testid={testid} className={c.titleLayer} onClick={handleClick} role='button'>
        <div className={c.title}>{titleJsx || title}</div>
        {!!tag && <div className={c.tagContainer}>{tag}</div>}
        <ChevronDownLIcon className={cn(c.arrow, expanded && c.arrowUp)} />
      </div>
      <Collapse in={expanded} timeout='auto'>
        <div className={c.content}>{children}</div>
      </Collapse>
    </div>
  )
}

export default Section
