import {useExp3} from '@eda-restapp/configs'

import {useUserInfo} from '@restapp/core-auth/hooks'
import {useIsEnabledInCurrentNetwork} from '@restapp/shared/hooks'

export const usePassportEnabled = () => {
  const authConfig = useExp3('restapp_auth')

  const userInfo = useUserInfo()

  const passportEnabled = useIsEnabledInCurrentNetwork(authConfig.passportAuthEnabledType)
  const hideModalsAndDrawer = useIsEnabledInCurrentNetwork(authConfig.hideModalsAndDrawer)

  return {
    enabled: userInfo?.type === 'passport' || passportEnabled,
    hideModalsAndDrawers: userInfo?.type === 'passport' || hideModalsAndDrawer
  }
}
