import type {GwcSpinnerSize} from '@eda-restapp/ui'
import {GwcSpinner} from '@eda-restapp/ui'
import cn from 'classnames'

import styles from './OverlaySpinner.module.css'

type Props = {
  className?: string
  size?: GwcSpinnerSize
}

export const OverlaySpinner = ({className, ...args}: Props) => (
  <GwcSpinner {...args} className={cn(styles.spinner, className)} />
)
