import {getConfigsStore} from '@eda-restapp/configs'
import {type Store} from '@reduxjs/toolkit'
import {BehaviorSubject, NEVER, Observable} from 'rxjs'
import {switchMap, share, filter} from 'rxjs/operators'
import {WebSocketSubject} from 'rxjs/webSocket'

import {selectIsLoggedIn, selectPartnerId} from '@restapp/core-auth'
import {fromRedux} from '@restapp/shared/rx-utils'
import type {AppState} from '@restapp/store'

import guardConnection from './guardConnection'
import pingAudit, {isPing} from './pingAudit'
import type {ApiNotification, MeaningfulNotification} from './types'
import type {Services as UrlServices} from './XivaUrlObservable'
import XivaUrlObservable from './XivaUrlObservable'

export interface Services extends UrlServices {
  store: Store<AppState>
}
const configsStore = getConfigsStore()

export default class XivaWebsocketObservable extends Observable<MeaningfulNotification> {
  status$: Observable<boolean>

  constructor({store, ...services}: Services) {
    const isEnabled$ = fromRedux(store, (state) => selectIsLoggedIn(state) && selectPartnerId(state))
    const statusSubject = new BehaviorSubject(false)

    const event$ = isEnabled$.pipe(
      switchMap((isEnabled) => {
        if (!isEnabled) {
          return NEVER
        }

        const url$ = new XivaUrlObservable(services)

        return url$.pipe(
          switchMap((url) => new WebSocketSubject<ApiNotification>(url)),
          pingAudit({
            pingToleranceMs: () => configsStore.getConfigs().restapp_api.socketPingTolerance,
            firstPingToleranceMs: () => configsStore.getConfigs().restapp_api.socketFirstPingTolerance
          }),
          guardConnection({
            statusSubject,
            retryInterval: (retryCount = 1) => configsStore.getConfigs().restapp_api.socketRetryInterval * retryCount,
            maxRetryCount: configsStore.getConfigs().restapp_api.socketMaxRetryCount
          }),
          filter((message): message is MeaningfulNotification => !isPing(message)),
          // Не пропускаем месседж про протухшую подпись, переподписка осуществляется в url$
          filter((message): message is MeaningfulNotification => message.message !== 'sign expired')
        )
      }),
      share()
    )

    super((observer) => event$.subscribe(observer))
    this.status$ = statusSubject
  }
}
