import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'

type DefaultData = Parameters<
  NonNullable<ApiQueryConfig<'/4.0/restapp-front/eats-restapp-menu/v1/menu/active', 'GET'>['select']>
>[0]

type Options<TSelectedData> = Omit<
  ApiQueryConfig<'/4.0/restapp-front/eats-restapp-menu/v1/menu/active', 'GET', TSelectedData>,
  'url' | 'body' | 'method' | 'params'
>

const useMenu = <TSelectedData = DefaultData>(
  placeId: number,
  {enabled = true, ...options}: Options<TSelectedData> = {}
) =>
  useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-menu/v1/menu/active',
    method: 'GET',
    params: {
      place_id: placeId
    },
    enabled: !!placeId && enabled,
    staleTime: 5_000, // позволяет убрать лишний запрос из-за переключения enabled при заходе в раздел меню
    ...options
  })

export default useMenu
