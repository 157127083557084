import type {DefaultProps} from './ProgressCircle'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles<DefaultProps>({
  name: 'ProgressCircle'
})((theme, props) => {
  return {
    root: {
      width: {
        small: 40,
        default: 50,
        large: 70
      }[props.size],
      height: {
        small: 40,
        default: 50,
        large: 70
      }[props.size],
      display: 'inline-block',
      position: 'relative'
    },
    content: {
      position: 'absolute',
      top: 0,
      width: {
        small: 40,
        default: 50,
        large: 70
      }[props.size],
      height: {
        small: 40,
        default: 50,
        large: 70
      }[props.size],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: {
        small: 14,
        default: 14,
        large: 24
      }[props.size],
      lineHeight: '14px'
    }
  }
})
