import cn from 'classnames'
import React, {useCallback, useEffect, useRef, useState} from 'react'

import type {WithDisposerProps} from '@restapp/core-legacy/hocs/disposer'
import withDisposer from '@restapp/core-legacy/hocs/disposer'
import {useServiceBrand} from '@restapp/shared/hooks'

import useModalHeaderStyles from './ModalHeader.styles'
import {useIsMobileStrict} from '@eda-restapp/ui'

type IModalHeaderProps = {
  highlighted?: boolean
  children: React.ReactNode
  className?: string
  classes?: Partial<ReturnType<typeof useModalHeaderStyles>['classes']>
} & WithDisposerProps

const SCROLL_VALUE_TO_SHRINK = 50
const RESIZE_DELAY = 200

function ModalHeader(props: IModalHeaderProps) {
  const {debounce, highlighted, classes, children} = props

  const [modalBodyScrolled, setModalBodyScrolled] = useState<boolean>(false)
  const modalHeaderRef = useRef<HTMLDivElement>(null)

  const serviceBrand = useServiceBrand()
  const isMobile = useIsMobileStrict()
  const {classes: c} = useModalHeaderStyles(undefined, {props: {classes}})

  const getModalBodyEl = () => modalHeaderRef.current?.nextElementSibling as HTMLElement

  const setInitialMeasurements = () => {
    const modalBodyEl = getModalBodyEl()

    if (!highlighted || !isMobile || !modalHeaderRef.current || !modalBodyEl) {
      return
    }

    const modalHeaderHeight = modalHeaderRef.current.offsetHeight

    modalBodyEl.style.paddingTop = `${modalHeaderHeight}px`
    modalBodyEl.style.position = 'relative'
    modalBodyEl.style.transition = 'top .2s ease'
  }

  const addModalBodyScrollListener = () => {
    const modalBodyEl = getModalBodyEl()

    if (!highlighted || !isMobile || !modalBodyEl) {
      return
    }

    const updateModalBodyScroll = () => {
      setModalBodyScrolled((prevValue) => {
        if (prevValue && modalBodyEl.scrollTop < SCROLL_VALUE_TO_SHRINK) {
          return false
        }

        if (!prevValue && modalBodyEl.scrollTop >= SCROLL_VALUE_TO_SHRINK) {
          return true
        }

        return prevValue
      })
    }

    modalBodyEl.addEventListener('scroll', updateModalBodyScroll)

    return () => modalBodyEl.removeEventListener('scroll', updateModalBodyScroll)
  }

  const addModalBodyResizeListener = useCallback(() => {
    if (!highlighted || !isMobile) {
      return
    }

    const modalBodyEl = getModalBodyEl()

    if (!modalBodyEl) {
      return
    }

    const updateModalBodyPadding = debounce(setInitialMeasurements, RESIZE_DELAY)

    window.addEventListener('resize', updateModalBodyPadding)

    return () => window.removeEventListener('resize', updateModalBodyPadding)
  }, [])

  useEffect(setInitialMeasurements, [])
  useEffect(addModalBodyScrollListener, [])
  useEffect(addModalBodyResizeListener, [])

  return (
    <div
      ref={modalHeaderRef}
      className={cn(props.className, c.root, highlighted && c.rootHighlighted, modalBodyScrolled && c.rootShrinked)}
      data-testid={'notification-modal-header'} /* Нотификации | Заголовок модального окна нотификации */
    >
      <span className={cn(c.header, {[c.yangoFont]: serviceBrand === 'YangoEats'})}>{children}</span>
    </div>
  )
}

export default withDisposer(ModalHeader)
