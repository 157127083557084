import {useI18n} from '@eda-restapp/i18n'
import {toNumber} from 'lodash-es'
import React, {type FC, useCallback} from 'react'

import type {PlaceDisablingReasonCode} from '@restapp/shared-api'
import {Chip, ChipGroup} from '@restapp/shared-ui'

import {Timers} from '../../DisablePlaceModal.constants'
import type {PlaceDisableParams} from '../../DisablePlaceModal.types'

type IDisablePlacePeriodChipsGroupProps = {
  disablingParams?: PlaceDisableParams[]
  disablePeriodDuration: number | null
  disableReasonCode: PlaceDisablingReasonCode | null
  onChangeDisablePeriodDuration: (periodDuraation: number) => void
}

export const DisablePlacePeriodChipsGroup: FC<IDisablePlacePeriodChipsGroupProps> = ({
  disablingParams,
  disablePeriodDuration,
  disableReasonCode,
  onChangeDisablePeriodDuration
}) => {
  const {t} = useI18n()

  const defaultPeriodOptions = [
    {description: t('core-legacy.disable-reason-form.polchasa', 'Полчаса'), value: Timers.HALF_AN_HOUR},
    {description: t('core-legacy.disable-place-modal.hour', 'Час'), value: Timers.HOUR},
    {description: t('core-legacy.disable-place-modal.two-hours', 'Два часа'), value: Timers.TWO_HOUR},
    {
      description: t('core-legacy.disable-place-modal.till-end-of-the-day', 'До конца дня'),
      value: Timers.TILL_END_OF_DAY
    },
    {description: t('core-legacy.disable-place-modal.onw-period', 'Свой период'), value: Timers.OTHER}
  ]

  const handlePeriodSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeDisablePeriodDuration(toNumber(e.target.value))
    },
    [onChangeDisablePeriodDuration]
  )

  const renderPeriodChips = () => {
    const disableParamsForChosedReason =
      disablingParams && disablingParams.find((disablingParam) => disablingParam.value === disableReasonCode)

    // If there's no periods for such reason - show 30min chip as default
    if (disableReasonCode && !disableParamsForChosedReason?.durations.length) {
      onChangeDisablePeriodDuration(Timers.HALF_AN_HOUR)
      return [
        <Chip key={Timers.HALF_AN_HOUR} value={Timers.HALF_AN_HOUR}>
          {t('core-legacy.disable-reason-form.polchasa', 'Полчаса')}
        </Chip>
      ]
    }

    const disablePeriodChips = disableParamsForChosedReason
      ? disableParamsForChosedReason.durations
          .map((duration) => (
            <Chip key={duration.value} value={duration.value}>
              {duration.label}
            </Chip>
          ))
          .concat(
            <Chip key={Timers.TILL_END_OF_DAY} value={Timers.TILL_END_OF_DAY}>
              {t('core-legacy.disable-place-modal.till-end-of-the-day', 'До конца дня')}
            </Chip>,
            <Chip key={Timers.OTHER} value={Timers.OTHER}>
              {t('core-legacy.disable-place-modal.onw-period', 'Свой период')}
            </Chip>
          )
      : defaultPeriodOptions.map((defaultPeriodOption) => (
          <Chip key={defaultPeriodOption.value} value={defaultPeriodOption.value}>
            {defaultPeriodOption.description}
          </Chip>
        ))

    return disablePeriodChips.sort((a, b) => a.props['value'] - b.props['value'])
  }

  return (
    <ChipGroup name='period' onChange={handlePeriodSelectChange} value={disablePeriodDuration}>
      {renderPeriodChips()}
    </ChipGroup>
  )
}
