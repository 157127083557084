import {useApiQuery} from '@restapp/core-api'

export const usePartnerLocation = ({enabled}: {enabled: boolean} = {enabled: true}) => {
  const locationQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/location',
    method: 'GET',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 60_000 * 10,
    enabled
  })

  return {
    countryCode: locationQuery?.data?.country_code,
    inCorpNetwork: !!locationQuery?.data?.yandex_net
  }
}
