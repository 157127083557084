import {useExp3} from '@eda-restapp/configs'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'

/**
 * @deprecated - Нужно обновить на использование useMenuConfiguration и можно убрать deprecated
 */
const useMenuCategories = () => {
  const {is_using_international_category_handle} = useExp3('restapp_menu')
  const {selectedPlaceId} = usePlaces()

  const {data: dataOld} = useApiQuery({
    url: '/4.0/restapp-front/api/v1/client/categories/standard',
    method: 'GET',
    enabled: !is_using_international_category_handle,
    select: (data) => data.categories,
    staleTime: 10 * 60 * 1000,
    refetchOnMount: false,
    suspense: true
  })

  const {data} = useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-menu/v1/menu/configuration',
    method: 'GET',
    params: {
      place_id: selectedPlaceId
    },
    enabled: is_using_international_category_handle,
    select: (data) => data.categories,
    staleTime: 10 * 60 * 1000,
    refetchOnMount: false,
    suspense: true
  })

  return {
    defaultCategories: is_using_international_category_handle
      ? (data?.standard.list.map((category) => category.name) ?? [])
      : (dataOld ?? [])
  }
}

export default useMenuCategories
