import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {clamp} from 'lodash'

type Sounds = {
  volume: number
}

const initialState: Sounds = {
  volume: 100
}

export const soundsSlice = createSlice({
  name: 'sounds',
  initialState: () => initialState,
  reducers: {
    setSoundsVolume(state, action: PayloadAction<number>) {
      state.volume = action.payload
    }
  },
  selectors: {
    selectSoundsVolume(state) {
      return clamp(state.volume / 100, 0, 1)
    }
  }
})
