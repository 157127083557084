import {placesChannel} from '@eda-restapp/microfrontend'

import {useApiMutation} from '@restapp/core-api'

export function useBusyModeDisableMutation() {
  return useApiMutation(
    {url: '/4.0/restapp-front/places/v1/busy-mode', method: 'DELETE'},
    {
      affectedQueryKeys: [
        ['/4.0/restapp-front/places/v1/suggest', 'GET' as never],
        ['/4.0/restapp-front/places/v1/search', 'GET' as never],
        ['/4.0/restapp-front/places/v2/search', 'GET' as never],
        ['/4.0/restapp-front/places/v1/busy-mode', 'GET' as never]
      ],
      onSettled: () => {
        void placesChannel.broadcast({type: 'refresh'})
      }
    }
  )
}
