import type {VendorApi as Api} from '@restapp/shared-api'

import type {Response, Method, TVariables} from '../../types'

import type {AsyncMutationConfig} from './types'

const preparePollingParams = <
  TUrl extends [Response<TUrl, TMethod>] extends [never] ? never : keyof Api,
  TMethod extends Method<TUrl>,
  TUrlPolling extends keyof Api,
  TMethodPolling extends Method<TUrlPolling>
>(
  config: AsyncMutationConfig<TUrl, TMethod, TUrlPolling, TMethodPolling, TVariables<TUrl, TMethod>>,
  mutationSuccessOpts: readonly [Response<TUrl, TMethod>, TVariables<TUrl, TMethod>, null]
) => {
  const {
    polling: {params: pollingParams, body: pollingBody}
  } = config

  const params = pollingParams
    ? pollingParams instanceof Function
      ? pollingParams(...mutationSuccessOpts)
      : pollingParams
    : undefined

  const body = pollingBody
    ? pollingBody instanceof Function
      ? pollingBody(...mutationSuccessOpts)
      : pollingBody
    : undefined

  return {
    params,
    body
  }
}

export default preparePollingParams
