import {type Campaign} from '../types/campaign'
import type {ShortRestaurant} from '../types/places'

/**
 * Может такое случиться что в кампании используется плейс, доступа к которому в данный момент у пользователя нет.
 * В этом случае воссоздаем структуру плейса на основе имеющихся данных.
 */
export const getFixedPlace = (
  campaign: Campaign,
  place: ShortRestaurant | undefined
): Pick<ShortRestaurant, 'id' | 'name' | 'address' | 'type' | 'services'> => {
  if (place) {
    return place
  }

  if (campaign.has_access_vendor === false) {
    return {
      id: campaign.place_id,
      address: campaign?.place_address_full || '',
      name: campaign?.place_name || '—',
      type: campaign?.place_type || 'native',
      services: campaign?.place_services
    }
  }

  return {
    id: campaign.place_id,
    address: '',
    name: '—',
    services: undefined,
    type: 'native'
  }
}
