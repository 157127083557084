import React, {useState, type FC, type ReactNode, type ReactElement} from 'react'

import {OperatorInfoTooltip} from '../../../../OperatorInfoTooltip/OperatorInfoTooltip'

type DisablePlusTooltipProps = {
  disabled: boolean
  children: ReactNode
}

export const DisablePlusTooltip: FC<DisablePlusTooltipProps> = ({disabled, children}) => {
  const [disablePlusTooltipAnchorEl, setDisablePlusTooltipAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as ReactElement, {ref: setDisablePlusTooltipAnchorEl})
      )}

      {disablePlusTooltipAnchorEl && !disabled && (
        <OperatorInfoTooltip anchorEl={disablePlusTooltipAnchorEl} showDisableText />
      )}
    </>
  )
}
