import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import type {FC} from 'react'
import {useEffect} from 'react'
import {useNavigate} from 'react-router'

import {setNotificationTimeouts} from '../../../../helpers/setNotificationTimeouts'
import {useStoplistNotificationMetric} from '../../../../hooks/useStoplistNotificationMetric'
import {useWeekday} from '../../../../hooks/useWeekday'
import type {WeeklySchedule} from '../../../../StoplistNotifications.types'

type StoplistAvailabilityNotificationPushProps = {
  snackbarSchedule: WeeklySchedule
}

export const StoplistAvailabilityNotificationPush: FC<StoplistAvailabilityNotificationPushProps> = ({
  snackbarSchedule
}) => {
  const weekday = useWeekday()
  const {t} = useI18n()
  const navigate = useNavigate()
  const onSnackbarDisplayed = useStoplistNotificationMetric('snackbar')

  useEffect(() => {
    const showSnackbar = () => {
      Snackbar.show({
        type: 'info',
        text: t('stoplist-notifications.availability-notification.push-text', 'Проверьте доступность позиций'),
        background: '#FCE45E',
        onClick: () => {
          metrika({target: 'stoplist_availability_notification_push_click'})
          navigate('/menu')
        }
      })
      onSnackbarDisplayed()
    }

    const snackbarTimeoutIds = setNotificationTimeouts(weekday, snackbarSchedule, showSnackbar)

    return () => snackbarTimeoutIds?.forEach((tid) => clearTimeout(tid))
  }, [weekday, snackbarSchedule, t, navigate, onSnackbarDisplayed])

  return null
}
