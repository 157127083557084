import type {RefObject} from 'react'
import {useResizeDetector} from 'react-resize-detector'

function useHasScroll(elementRef: RefObject<HTMLElement>) {
  const {ref} = useResizeDetector<HTMLElement>({
    targetRef: elementRef,
    refreshMode: 'debounce',
    refreshRate: 100
  })

  const hasHorizontalScroll = (ref.current?.scrollWidth || 0) > (ref.current?.clientWidth || 0)
  const hasVerticalScroll = (ref.current?.scrollHeight || 0) > (ref.current?.clientHeight || 0)

  return {hasHorizontalScroll, hasVerticalScroll}
}

export default useHasScroll
