import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Typography, useIsMobileStrict, Modal, UserAvatar} from '@eda-restapp/ui'
import React from 'react'

import type {ExternalAccountSuggest} from '../../../../../../types'
import {usePassportName} from '../../../../../hooks'
import {PassportIcon} from '../../../../PassportIcon'
import {PassportDrawerCards} from '../../../PassportDrawer/PassportDrawerCards'

import styles from './ModalWithNewFeatures.module.css'

export type ModalWithNewFeaturesProps = {
  openDrawer: () => void
  suggestedAccount?: ExternalAccountSuggest
  onClose: () => void
  onBindOtherAccount: () => void
  onBindSuggestedAccount: () => void
}

export const ModalWithNewFeatures: React.FC<ModalWithNewFeaturesProps> = ({
  openDrawer,
  suggestedAccount,
  onClose,
  onBindOtherAccount,
  onBindSuggestedAccount
}) => {
  const {t, tRaw} = useI18n()
  const passportName = usePassportName()
  const isMobile = useIsMobileStrict()

  const handleOpenDrawer = () => {
    onClose()
    openDrawer()
  }

  return (
    <>
      <Modal.Header
        title={
          isMobile
            ? t(
                'core-auth.passport.modal-with-new-features.link-passport-to-account-mobile',
                'Привяжите {passportName} до 31 окт.',
                {passportName}
              )
            : t(
                'core-auth.passport.modal-with-new-features.link-passport-to-account-desktop',
                'Привяжите к личному кабинету свой {passportName} до 31 октября',
                {passportName}
              )
        }
      />
      <Modal.Content>
        <Box flexDirection='column' alignItems='center' alignSelf='stretch'>
          <Box flexDirection='column'>
            <PassportDrawerCards variant='modal' />

            <Typography variant='caption1' pt='m' color='minor' className={styles.moreAboutPassport}>
              {tRaw(
                'core-auth.passport.modal-with-new-features.features-caption',
                'У личных кабинетов, к которым не будет привязан {passportName}, с 31 октября останется доступ только к разделу Заказы. {moreAboutPassport}',
                {
                  passportName,
                  moreAboutPassport: (
                    <a onClick={handleOpenDrawer}>
                      {t(
                        'core-auth.passport.modal-with-new-features.more-about-passport',
                        'Подробнее о новой авторизации'
                      )}
                    </a>
                  )
                }
              )}
            </Typography>
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box gap={isMobile ? 's' : 'm'} className={styles.buttons} flexDirection={isMobile ? 'column' : 'row'}>
          {suggestedAccount ? (
            <>
              <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
                {t('core-auth.passport.modal-with-new-features.other-account', 'Выбрать другой аккаунт')}
              </Button.Text>

              <Button.MultiSpace
                variant='dark'
                startIcon={<PassportIcon />}
                endIcon={<UserAvatar className={styles.round} src={suggestedAccount?.avatar} />}
                onClick={onBindSuggestedAccount}
                fullWidth
              >
                {t('core-auth.passport.modal-with-new-features.login-with-suggested-account', 'Привязать {login}', {
                  login: suggestedAccount?.login
                })}
              </Button.MultiSpace>
            </>
          ) : (
            <Button.MultiCenter variant='dark' startIcon={<PassportIcon />} onClick={onBindOtherAccount} fullWidth>
              {t('core-auth.passport.modal-with-new-features.link-login', 'Привязать {passportName}', {
                passportName
              })}
            </Button.MultiCenter>
          )}
        </Box>
      </Modal.Footer>
    </>
  )
}
