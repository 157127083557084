import {makeStyles} from 'tss-react/mui'

const useBusyModeButtonStyles = makeStyles({name: 'useBusyModeButtonStyles'})({
  mobileButton: {
    '&&': {
      minWidth: 40,
      padding: 0
    }
  },
  smallButton: {
    '&&': {
      minWidth: 32,
      width: 'auto',
      padding: 8
    }
  },
  smallWidth: {
    '&&': {
      maxWidth: 32
    }
  },
  buttonIcon: {
    maxHeight: 32
  },
  active: {
    '&&': {
      background: '#FA6A3C',
      color: '#fff',

      '&:hover': {
        background: '#E25D33'
      }
    }
  },
  countdown: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 2
  },
  countdownDesktop: {
    marginLeft: 16,
    fontSize: 14,
    opacity: 0.5
  }
})

export default useBusyModeButtonStyles
