import {useI18n} from '@eda-restapp/i18n'
import {Modal} from '@eda-restapp/ui'
import React, {useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {Button} from '@restapp/shared-ui'

import type {Survey} from '../../../types'
import {checkIsVisiblePage, checkIsValidPage, surveyCanSubmitAnswers} from '../../../utils/survey'

import SurveyPage from './SurveyPage'

const useSurveyModalStyles = makeStyles()({
  button: {
    minWidth: 260
  }
})

interface SurveyModalProps {
  survey: Survey
  onSubmit: () => void
}

function SurveyModalLayout({survey, onSubmit}: SurveyModalProps) {
  const {t} = useI18n()
  const {classes: c} = useSurveyModalStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const isStory = survey.content.content_type === 'story'
  const hasNextPage =
    isStory && survey.content.pages.slice(pageIndex + 1).some((page) => checkIsVisiblePage(page, survey.content.pages))

  function nextPage() {
    const nextIndex = pageIndex + 1
    if (checkIsVisiblePage(survey.content.pages[nextIndex], survey.content.pages)) {
      setPageIndex(nextIndex)
    } else {
      const nextVisiblePageIndex =
        nextIndex +
        survey.content.pages.slice(nextIndex).findIndex((page) => checkIsVisiblePage(page, survey.content.pages))
      setPageIndex(nextVisiblePageIndex)
    }
  }

  function handleButtonClick() {
    if (hasNextPage) {
      nextPage()
    } else {
      setIsSubmitting(true)
      onSubmit()
    }
  }

  return (
    <>
      <Modal.Header title={survey.info.hide_title_other_screens && pageIndex > 0 ? ' ' : survey.preview.title} />
      <Modal.Content>
        {isStory ? (
          <SurveyPage page={survey.content.pages[pageIndex]} />
        ) : (
          survey.content.pages
            .filter((page) => checkIsVisiblePage(page, survey.content.pages))
            .map((page, i) => <SurveyPage key={i} page={page} />)
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button
          className={c.button}
          fullWidth
          variant='contained'
          loading={isSubmitting}
          color={hasNextPage ? 'secondary' : 'primary'}
          // FIXME
          disabled={isStory ? !checkIsValidPage(survey.content.pages[pageIndex]) : !surveyCanSubmitAnswers(survey)}
          size={'large'}
          onClick={() => void handleButtonClick()}
          data-testid='main-communications-survey-modal-button' /* Модальное окно опроса | Кнопка "Дальше"/"Готово" */
        >
          {hasNextPage
            ? t('main-communications.survey-modal.dalshe', 'Дальше')
            : t('main-communications.survey-modal.gotovo', 'Готово')}
        </Button>
      </Modal.Footer>
    </>
  )
}

export default SurveyModalLayout
