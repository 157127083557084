import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'

import {selectDeviceInfo} from '@restapp/bootstrap/native/selectors'
import {useApiMutation} from '@restapp/core-api'

export const useNativePushUnsubscription = () => {
  const deviceInfo = useSelector(selectDeviceInfo)
  const webPushConfig = useExp3('restapp_webpush')

  const unsubscribeMutation = useApiMutation(
    {
      method: 'POST',
      url: webPushConfig.newApiEnabled
        ? '/4.0/restapp-front/communications/v1/notify/unsubscribe'
        : '/4.0/restapp-front/notify/v1/unsubscribe'
    },
    {
      onError: () => {
        // do not show snackbar
      }
    }
  )

  const unsubscribe = useCallback(
    async ({partnerId, token}: {partnerId: string | null; token: string | null}) => {
      eventLogger({
        name: 'push-unsubscribe_passport-binding',
        value: 'native',
        additional: {partnerId, hasToken: Boolean(token)}
      })

      deviceInfo &&
        (await unsubscribeMutation.mutateAsync({
          body: {
            channel: {name: deviceInfo.pushNotificationChannel},
            ...(webPushConfig.newApiEnabled
              ? {
                  app_install_id: VERSION,
                  client_id: partnerId || undefined
                }
              : {
                  service: 'eats-partner',
                  client: {
                    device_id: deviceInfo.deviceId,
                    partner_uid: partnerId || undefined
                  }
                })
          }
        }))

      unsubscribeMutation.reset()
    },
    [deviceInfo, webPushConfig.newApiEnabled, unsubscribeMutation]
  )

  return {
    unsubscribe
  }
}
