import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import React, {useContext} from 'react'

import {RouteContext} from '@restapp/core-legacy/common/routes/utils'
import {HelpButton} from '@restapp/shared-support'

const HelpButtonContainer = (props: {className?: string}): JSX.Element | null => {
  const routeContext = useContext(RouteContext)
  const sectionSlug = routeContext?.sectionSlug

  const {sections} = useExp3('restapp_support_links')

  const section = sectionSlug && sections?.find(({slug, enabled}) => slug === sectionSlug && enabled)

  return section ? (
    <HelpButton
      {...props}
      section={section}
      onOpen={() => eventLogger({name: 'help_menu_open', value: sectionSlug})}
      onClose={() => eventLogger({name: 'help_menu_close', value: sectionSlug})}
    />
  ) : null
}

export default HelpButtonContainer
