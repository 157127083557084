import type {Reducer, Action, Slice} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export function makeReducerPersistent<TState, TAction extends Action>(
  reducer: Reducer<TState, TAction>,
  key: string,
  version = 0
) {
  return persistReducer({version, storage, key}, reducer)
}

export function makeSlicePersistent<TSlice extends Slice>(slice: TSlice, version = 0): TSlice {
  return {
    ...slice,
    reducer: persistReducer({version, storage, key: slice.name}, slice.reducer)
  }
}
