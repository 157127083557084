import React from 'react'
import cn from 'classnames'
import useStyles from './Skeleton.styles'

type ISkeletonProps = {
  className?: string
  aspectRatio?: string
  height?: number | string
  width?: number | string
}

const SkeletonComponent = ({className, height, width, aspectRatio, ...props}: ISkeletonProps) => {
  const {classes: c} = useStyles()
  const isSmallRadius = typeof height == 'number' ? height < 30 : false

  return (
    <div
      className={cn(c.root, {[c.smallRadius]: isSmallRadius}, className)}
      style={{aspectRatio, width, height}}
      {...props}
    />
  )
}

export default SkeletonComponent
