import type {BannerBadge} from '@eda-restapp/banners'
import {useI18n} from '@eda-restapp/i18n'
import {Banner, LegacyButton as Button, Typography} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import styles from './PlaceLeadersEntryBanner.module.css'

type PlaceLeadersEntryBannerProps = {
  backgroundImage?: string
  backgroundLayer: string
  badgesEnabled: boolean
  className?: string
  contentMainText: string
  headerText: string
  orderIncreaseBadgeNumber?: number
  onActionButtonClick: () => void
}

export const PlaceLeadersEntryBanner: FC<PlaceLeadersEntryBannerProps> = ({
  className,
  backgroundImage,
  backgroundLayer,
  badgesEnabled,
  contentMainText,
  headerText,
  orderIncreaseBadgeNumber,
  onActionButtonClick
}) => {
  const {t} = useI18n()

  const renderBadges = () => {
    if (!badgesEnabled) {
      return []
    }

    const badges: BannerBadge[] = []

    if (orderIncreaseBadgeNumber) {
      badges.push({
        bgColor: '#00ca50',
        textColor: 'invert',
        textVariant: 'caption1',
        text: t(
          'shared.reliable-partner-entry-banner.banner-increase-orders-badge-text',
          'Рост заказов до +{ordersIncreaseValue}%',
          {
            ordersIncreaseValue: orderIncreaseBadgeNumber
          }
        )
      })
    }

    badges.push({
      bgColor: '#000',
      textColor: 'invert',
      textVariant: 'caption1',
      text: t('shared.reliable-partner-entry-banner.banner-free-type-badge-text', 'Бесплатно')
    })

    return badges
  }

  return (
    <Banner
      className={className}
      illustrationClassName={styles.bannerIllustration}
      theme='light'
      illustrationBgImage={backgroundImage}
      backgroundLayer={backgroundLayer}
      badges={renderBadges()}
      header={headerText}
      content={contentMainText}
      footer={
        <Button className={styles.footerButton} size='m' onClick={onActionButtonClick}>
          <Typography variant='body2' thickness='medium'>
            {t('shared.reliable-partner-entry-banner.footer-button-label', 'Хочу попробовать')}
          </Typography>
        </Button>
      }
    />
  )
}
