import moment from 'moment'
import {useEffect, useState} from 'react'

import {getDelay} from '../helpers/getDelay'
import type {Weekdays} from '../StoplistNotifications.types'

const getWeekday = () => new Date().toLocaleString('en-us', {weekday: 'long'}).toLowerCase() as Weekdays

export const useWeekday = () => {
  const [weekday, setWeekday] = useState<Weekdays>(getWeekday())

  const updateWeekday = () => setWeekday(getWeekday())

  // обновляем в полуночь день недели, это вызывает апдейт хука, и хук еще раз навешивает таймаут
  useEffect(() => {
    const midnight = moment().add({days: 1}).startOf('day').toDate()
    const currentTime = new Date()
    const delay = getDelay(currentTime, midnight)
    const timeoutUntilMidnight = setTimeout(updateWeekday, delay)

    return () => clearTimeout(timeoutUntilMidnight)
  }, [weekday])

  return weekday
}
