import {useStyles} from 'tss-react/mui'
import {GlobalStyles as GlobalStylesTSS} from 'tss-react'

export const GlobalStyles = () => {
  const {theme} = useStyles()

  return (
    <GlobalStylesTSS
      styles={{
        '*': {
          boxSizing: 'border-box',
          WebkitOverflowScrolling: 'touch',
          WebkitTapHighlightColor: 'transparent',
          WebkitAppearance: 'none' as 'none',
          MozAppearance: 'none' as 'none',
          appearance: 'none' as 'none',
          MozTextSizeAdjust: 'none',
          WebkitTextSizeAdjust: '100%',
          '&:focus': {
            outline: 'none'
          }
        },

        'html, body, #root': {
          height: '100%',
          width: '100%',
          position: 'relative',
          margin: 0
        },

        h1: {
          fontSize: 24,
          lineHeight: '28px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          color: '#302F2D',
          margin: 0,
          padding: 0,

          // FIXME: в этом файле по какой-то причине не подтягиваются наши кастомные брекйпоинты
          [theme.breakpoints.up(768)]: {
            fontSize: 36,
            lineHeight: '40px'
          }
        },

        h3: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 18,
          lineHeight: '22px',
          color: '#302F2D',
          margin: 0,
          padding: 0
        },

        button: {
          lineHeight: 1
        },

        '::-ms-clear': {
          display: 'none' as 'none'
        },

        ['@media print']: {
          body: {
            transform: 'scale(1)'
          }
        }
      }}
    />
  )
}
