import type {RevisionResult} from '@restapp/shared-api'

/** Помогает вытащить ошибку из ответов ручек меню */
export function getErrorFromRevisionResult(data: RevisionResult) {
  const menuErrors = data.errors?.menu?.codes || []

  const categoryErrors = data.errors?.categories?.flatMap((err) => err.codes) || []

  const itemErrors = data.errors?.items?.flatMap((err) => err.codes || []) || []

  const modifierGroupErrors =
    data.errors?.items?.flatMap((err) => err.modifierGroups || []).flatMap((err) => err.codes || []) || []

  const modifierErrors =
    data.errors?.items
      ?.flatMap((err) => err.modifierGroups || [])
      .flatMap((err) => err.modifiers || [])
      .flatMap((err) => err.codes) || []

  // Ошибки со всего меню, в порядке от общего
  const errorCodes = [...menuErrors, ...categoryErrors, ...itemErrors, ...modifierGroupErrors, ...modifierErrors]

  if (data.status === 'failed' || !data.revision) {
    return {message: errorCodes[0]?.message || '', code: errorCodes[0]?.code}
  }
}
