import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {INewsItem} from '@restapp/shared-api'

import type {NewsState} from '../../types'
import {CommunicationStatus, NewsFilter, NewsSort} from '../../types'
import {mapNewsItem} from '../../utils/news'

const initialState: NewsState = {
  items: [],
  itemsIds: {},
  hasMore: true,
  pollingLimit: 25,
  filter: NewsFilter.ALL,
  sort: NewsSort.DATE_DESC
}

const communicationsNewsSlice = createSlice({
  name: 'communicationsNews',
  initialState,
  reducers: {
    setNews(state, action: PayloadAction<{items: INewsItem[]; isLoadingMore?: boolean}>) {
      const {items: newNews, isLoadingMore} = action.payload

      if (!isLoadingMore) {
        state.items = []
        state.itemsIds = {}
      }

      newNews.forEach((newsItem) => {
        if (!state.itemsIds[newsItem.id]) {
          state.itemsIds[newsItem.id] = true
          state.items.push(mapNewsItem(newsItem))
        }
      })
    },
    setNewsPollingCursor(state, action: PayloadAction<string | undefined>) {
      state.pollingCursor = action.payload
    },
    setNewsHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload
    },
    setNewsFilter(state, action: PayloadAction<NewsFilter>) {
      state.filter = action.payload
    },
    setNewsSort(state, action: PayloadAction<NewsSort>) {
      state.sort = action.payload
    },
    readAllNews(state) {
      state.items.forEach((item) => {
        // Коммуникации которые помечены как критические, не отмечаются прочитанными с помощью ручки read-all
        if (!item.info.important) {
          item.status = CommunicationStatus.READ
        }
      })
    },
    readOneNews(state, action: PayloadAction<string>) {
      const item = state.items.find((newsItem) => newsItem.id === action.payload)
      if (item) {
        item.status = CommunicationStatus.READ
      }
    },
    resetNews(state) {
      state.items = []
      state.itemsIds = {}
    }
  }
})

export const communicationsNewsReducer = communicationsNewsSlice.reducer
export const {
  setNews,
  setNewsFilter,
  setNewsSort,
  setNewsPollingCursor,
  setNewsHasMore,
  resetNews,
  readOneNews,
  readAllNews
} = communicationsNewsSlice.actions
