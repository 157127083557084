export enum PLACE_STATUS {
  SELF_REG_PENDING = 'SELF_REG_PENDING',
  SELF_REG_COMPLETE = 'SELF_REG_COMPLETE',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DISABLED_BY_VENDOR = 'DISABLED_BY_VENDOR',
  DISABLED_BY_YANDEX = 'DISABLED_BY_YANDEX',
  CAN_NOT_BE_ENABLED = 'CAN_NOT_BE_ENABLED',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  DISABLE_PENDING = 'DISABLE_PENDING',
  DISABLED_BY_AUTOSTOP = 'DISABLED_BY_AUTOSTOP',
  BUSY_MODE_ENABLED = 'BUSY_MODE_ENABLED'
}
