import React, {type FC} from 'react'

import type {FullRestaurant, OldFullRestaurant} from '@restapp/shared-api'
import {useEnablePlace} from '@restapp/shared-places'

import {PlaceAction} from './PlaceAction'

type PlaceActionContainerProps = {
  disabled?: boolean
  isHideDisableButton: boolean
  place: FullRestaurant | OldFullRestaurant
  size?: 's' | 'm' | 'l'
  smallDisableButtonMobile?: boolean
}

export const PlaceActionContainer: FC<PlaceActionContainerProps> = ({
  place,
  size = 's',
  isHideDisableButton,
  smallDisableButtonMobile = false
}) => {
  const enablePlaceMutation = useEnablePlace()

  const handleEnablePlace = () => {
    enablePlaceMutation.enablePlace([place.id])
  }

  return (
    <PlaceAction
      enablePlaceMutationLoading={enablePlaceMutation.isLoading}
      isHideDisableButton={isHideDisableButton}
      place={place}
      size={size}
      smallDisableButtonMobile={smallDisableButtonMobile}
      onEnableButtonClick={handleEnablePlace}
    />
  )
}
