import {useI18n} from '@eda-restapp/i18n'
import {toNumber} from 'lodash-es'
import React, {type FC, useCallback} from 'react'

import type {PlaceDisablingReasonCode} from '@restapp/shared-api'
import {Chip, ChipGroup} from '@restapp/shared-ui'

import {PlaceDisableReason} from '../../DisablePlaceModal.constants'
import type {PlaceDisableParams} from '../../DisablePlaceModal.types'

type IDisablePlaceReasonChipsGroupProps = {
  disablingParams?: PlaceDisableParams[]
  disableReasonCode: PlaceDisablingReasonCode | null
  onChangeDisableReasonCode: (reasonCode: PlaceDisablingReasonCode) => void
}

export const DisablePlaceReasonChipsGroup: FC<IDisablePlaceReasonChipsGroupProps> = ({
  disablingParams,
  disableReasonCode,
  onChangeDisableReasonCode
}) => {
  const {t} = useI18n()

  const defaultReasonOptions = [
    {
      description: t('core-legacy.disable-reason-form.ne-uspevaem', 'Не успеваем'),
      value: PlaceDisableReason.NOT_KEEP_UP
    },
    {
      description: t('core-legacy.disable-reason-form.meropriyatie-ch-p', 'Мероприятие, ЧП'),
      value: PlaceDisableReason.EVENT
    },
    {description: t('core-legacy.disable-reason-form.drugoe', 'Другое'), value: PlaceDisableReason.OTHER}
  ]

  const handleReasonSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeDisableReasonCode(toNumber(e.target.value) as PlaceDisablingReasonCode)
    },
    [onChangeDisableReasonCode]
  )

  return (
    <ChipGroup name='reason' onChange={handleReasonSelectChange} value={disableReasonCode}>
      {disablingParams
        ? disablingParams.map((disablingParam) => (
            <Chip key={disablingParam.value} value={disablingParam.value}>
              {disablingParam.label}
            </Chip>
          ))
        : defaultReasonOptions.map((defaultReasonOption) => (
            <Chip key={defaultReasonOption.value} value={defaultReasonOption.value}>
              {defaultReasonOption.description}
            </Chip>
          ))}
    </ChipGroup>
  )
}
