export enum RESTAURANT_DISABLE_REASONS {
  MENU_UPDATE = 0,
  NEW_MENU_AWAIT = 1,
  TECHNICAL = 2,
  EVENT = 3,
  HOLIDAYS = 4,
  TEMPORARY_PARTNERSHIP_OVER = 5,
  CLOSED = 6,
  ON_REPAIR = 7,
  MOVED = 8,
  OTHER = 9,
  NOT_RE_SIGNED_ON_YANDEX_EDA = 10,
  BACKFIRING = 13,
  CHANGE_OF_TIN = 14,
  EVACUATION = 15,
  BY_RESTAURANT_REQUEST = 16,
  APP_ISSUES = 17,
  INTEGRATION_ISSUES = 18,
  COMMUNICATION_TOOLS_ISSUES = 19,
  CLEANUP_DAY = 20,
  NO_INTERNET_CONNECTION = 21,
  TABLET_ISSUES = 22,
  NO_STAFF = 23,
  CASH_REGISTER_ISSUES = 24,
  OUT_OF_STOCK = 25,
  PACKAGING_ISSUES = 26,
  DESCRIPTION_ISSUES = 27,
  RUSH_HOUR = 28,
  KITCHEN_NOT_WORKING = 29,
  PAYMENT_METHOD_ISSUES = 30,
  STATE_OF_EMERGENCY = 31,
  CLOSED_EARLY = 32,
  RENT_ISSUES = 33,
  REBRANDING = 34,
  PERMANENTLY_PARTNERSHIP_OVER = 35,
  DELIVERY_ZONES_ISSUES = 36,
  TEST_RUN = 37,
  DUPLICATED_PLACE = 38,
  DELIVERY_ZONE_CHANGE = 39,
  LOW_RATING = 40,
  QUALITY_PROBLEMS = 41,
  RESTAURANT_NO_NEED_PARTNERSHIP = 42,
  OFFER_RECONSIDERATION = 43,
  NOT_SEE_ORDER = 44,
  NOT_CONFIRM_ORDER = 45,
  ERROR_PARSE = 46,
  SELF_BY_VENDOR = 47,
  SELF_REGISTRATION_PENDING = 51,
  SELF_REGISTRATION_DONE = 52,
  AUTOSTOP_ORDER_CANCELLATION = 90,
  AUTOSTOP_OTHER = 91,
  AUTOSTOP_WEEKENDS_EVENTS_EMERGENCY = 92,
  AUTOSTOP_HIGH_LOAD = 93,
  AUTOSTOP_CLOSED_EARLIER = 94,
  /** @description https://st.yandex-team.ru/EDARESTAPP-6639 ресторан был отключен, так как не указал наш сервис комиссионером в Узбекистане */
  AUTOSTOP_NON_COMITENT_UZ_PARTNER = 113,
  /** @description https://st.yandex-team.ru/EDAPARTNERS-6248 ресторан был отключен, так как не указал праздничное расписание */
  HAS_NO_HOLIDAY_SCHEDULE = 103
}

export const AUTOSTOP_REASON: number[] = [
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_ORDER_CANCELLATION,
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_OTHER,
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_WEEKENDS_EVENTS_EMERGENCY,
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_HIGH_LOAD,
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_CLOSED_EARLIER,
  RESTAURANT_DISABLE_REASONS.AUTOSTOP_NON_COMITENT_UZ_PARTNER
]

export const ENABLE_NOT_ALLOWED_REASONS: number[] = [
  RESTAURANT_DISABLE_REASONS.NEW_MENU_AWAIT,
  RESTAURANT_DISABLE_REASONS.DELIVERY_ZONE_CHANGE,
  RESTAURANT_DISABLE_REASONS.MENU_UPDATE,
  RESTAURANT_DISABLE_REASONS.TABLET_ISSUES,
  RESTAURANT_DISABLE_REASONS.CHANGE_OF_TIN,
  RESTAURANT_DISABLE_REASONS.NOT_SEE_ORDER,
  RESTAURANT_DISABLE_REASONS.NOT_CONFIRM_ORDER,
  RESTAURANT_DISABLE_REASONS.ERROR_PARSE,
  RESTAURANT_DISABLE_REASONS.QUALITY_PROBLEMS,
  RESTAURANT_DISABLE_REASONS.LOW_RATING,
  RESTAURANT_DISABLE_REASONS.RESTAURANT_NO_NEED_PARTNERSHIP,
  RESTAURANT_DISABLE_REASONS.PACKAGING_ISSUES
]

export const ENABLE_ALLOWED_REASONS: number[] = [
  RESTAURANT_DISABLE_REASONS.RUSH_HOUR,
  RESTAURANT_DISABLE_REASONS.HOLIDAYS,
  RESTAURANT_DISABLE_REASONS.SELF_BY_VENDOR,
  RESTAURANT_DISABLE_REASONS.HAS_NO_HOLIDAY_SCHEDULE
]

// 0: “Админка: обновление меню”
// 1: “Админка: ждем меню”
// 2: “Технические причины (укажите комментарий)”
// 3: “Закрыты на мероприятие (укажите комментарий)”
// 4: “Загрузка ресторана: выходные, мероприятия, ЧП”
// 5: “Мы приостановили сотрудничество (укажите комментарий)”
// 6: “Ресторан закрылся/остановил сотрудничество”
// 7: “Ремонт/Переезд/Ребрендинг/Аренда”
// 8: “Переезд (укажите комментарий)”
// 9: “Другое (укажите комментарий)”
// 10: ‘Не перешел на ООО “Яндекс Еда“’
// 13: “Работа ресторана: недозвон”
// 14: “Работа ресторана: смена юр.лица”
// 15: “Эвакуация”
// 16: “Нестандартный случай (не более 5%)”
// 17: “Проблемы с приложением (укажите комментарий)”
// 18: “Проблемы с парсингом и/или интеграцией (укажите комментарий)”
// 19: “Работа ресторана: нет света/воды”
// 20: “Работа ресторана: санитарный день / нет воды, света / продуктов”
// 21: “Работа ресторана: нет интернета”
// 22: “Работа ресторана: нет оборудования для приема заказов”
// 23: “Работа ресторана: нет поваров, курьеров, др. персонала”
// 24: “Не работает касса”
// 25: “Работа ресторана: кончилась продукция”
// 26: “Работа ресторана: нет упаковки”
// 27: “Меню без граммов и/или описаний”
// 28: “Загрузка ресторана: высокая загрузка”
// 29: “Работа ресторана: не работает касса/кухонное оборудование”
// 30: “Не принимают заказы за нал/безнал”
// 31: “Загрузка ресторана: ЧП”
// 32: “Нарушение: график работы”
// 33: “Проблемы с арендой помещения (укажите комментарий)”
// 34: “Ребрендинг (укажите комментарий)”
// 35: “Мы прекратили сотрудничество”
// 36: “Не работаем в этой зоне”
// 37: “Тестовый запуск”
// 38: “Дубль”
// 39: “Админка: ждем зону”
// 40: “Нарушение: низкий рейтинг”
// 41: “Нарушение: плохое качество блюд/доставки”
// 42: “Ресторан сливается с сотрудничества”
// 43: “Ресторан хочет расторгнуть договор/пересмотреть условия”
// 44: “Тех. поддержка: ресторан не видит заказ”
// 45: “Тех. поддержка: не меняется статус заказа на «Подтверждено рестораном»”
// 46: “Тех. поддержка: проблемы со стоп-листами/парсингом”
// 47: “Ресторан отключился сам”
// 90: "Автостоп: отмена заказа"
// 91: "Автостоп: не может приготовить заказ"
// 92: "Автостоп: выходные, мероприятия, ЧП"
// 93: "Автостоп: высокая загрузка"
// 94: "Автостоп: закрылся раньше"
// 103: "Новый год: нет праздничного расписания"
