export const enum ALL_PLACES_STATUS {
  AVAILABLE,
  PART_DISABLED,
  DISABLED
}

export const EMAIL_REGEXP = /^\S+@\S+\.\S+/

export const SUPPORT_MAIL = 'rest@eda.yandex.ru'

export const rootId = 'root'
