const mediaQuerySizes = {
  LARGE_DESKTOP_MIN: 1366,
  TABLET_MIN: 650,
  TABLET_MEDIUM_MIN: 768,
  SMALL_PHONE_MAX: 360,
  LARGE_TABLET_MIN: 1024
} as const

/**
 * @deprecated - Нельзя использовать эту глобальную константу, если есть действительно что-то глобальное следует вынести это в тему mui.
 * @see - https://v4.mui.com/ru/customization/theming/
 */
export const S = {
  /**
   * @deprecated - Используй mobile first подход и theme.breakpoints.up('md') для стилизации планшета и десктопа
   * @see - https://v4.mui.com/ru/customization/breakpoints/#api
   */
  mobileQuery: `@media (max-width:${mediaQuerySizes.TABLET_MEDIUM_MIN}px)`,
  /**
   * @deprecated - Используй theme.breakpoints
   * @see - https://v4.mui.com/ru/customization/breakpoints/#api
   */
  smallMobileQuery: `@media (max-width:${mediaQuerySizes.TABLET_MIN}px)`,
  /**
   * @deprecated - Используй theme.breakpoints
   * @see https://v4.mui.com/ru/customization/breakpoints/#api
   */
  xSmallMobileQuery: `@media (max-width:${mediaQuerySizes.SMALL_PHONE_MAX}px)`,
  /**
   * @deprecated - Используй theme.breakpoints
   * @see https://v4.mui.com/ru/customization/breakpoints/#api
   */
  tabletQuery: `@media (max-width:${mediaQuerySizes.LARGE_TABLET_MIN}px)`
} as const
