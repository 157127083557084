import {SmileFrowning, SmileGrinning, SmileNeutral, SmilePouting, SmileSlightlySmiling} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {Button} from '@restapp/shared-ui'

import {useEmojisStyles} from './Emojis.style'

export interface EmojisProps {
  emojiIndex: number | null
  onEmojiClick: (emojiIndex: number) => void
}

const emojis = [SmilePouting, SmileFrowning, SmileNeutral, SmileSlightlySmiling, SmileGrinning]

const Emojis: React.FC<EmojisProps> = ({emojiIndex, onEmojiClick}) => {
  const {classes: c} = useEmojisStyles()

  return (
    <div className={c.root}>
      {emojis.map((EmojiIcon, i) => (
        <Button
          slug={`emoji-button-${i}`}
          key={i}
          className={cn(c.emojiButton, {[c.emojiButtonActive]: emojiIndex === i})}
          onClick={() => onEmojiClick(i)}
        >
          <EmojiIcon className={c.emoji} />
        </Button>
      ))}
    </div>
  )
}

export default Emojis
