import {useI18n} from '@eda-restapp/i18n'
import React from 'react'

import {formatCurrency} from './formatCurrency'

export type CurrencyProps = {
  value: number
  /**
   * Код валюты ISO 4217
   *
   * https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list_one.xml
   * */
  code?: string
  /**
   * Отображаться только цифры без валюты
   * */
  onlyNumber?: boolean
  /**
   * Изменение дефолтного форматирования
   *
   * Не рекомендуется к использованию
   * */
  option?: Intl.NumberFormatOptions
}

/** Компоеннт отрисовки валют
 * @example
 * <Currency value={1234.56} code={'RUB'}>
 */
const Currency: React.FC<CurrencyProps> = ({value, code = 'RUB', option = {}, onlyNumber = false}) => {
  const {lang} = useI18n()

  if (onlyNumber) {
    const formattedValue = formatCurrency(value, code, lang, {...option, currencyDisplay: 'code'})
    const formattedValueWithoutCode = formattedValue.replace(code, '').trim()
    return <React.Fragment>{formattedValueWithoutCode}</React.Fragment>
  }

  return <React.Fragment>{formatCurrency(value, code, lang, option)}</React.Fragment>
}

export default Currency
