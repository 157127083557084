import {useI18n} from '@eda-restapp/i18n'
import {Box, MenuIcon, Button} from '@eda-restapp/ui'
import {resetNavigation} from '@eda-restapp/utils'
import {toString} from 'lodash-es'

import {useSidebar} from '@restapp/main-layout'

import styles from './ErrorPage.module.css'

interface ErrorPageProps {
  error: unknown
  eventId?: string
  onReset?: () => void
  withMenuButton?: boolean
}

function ErrorPage({error, onReset, eventId, withMenuButton = false}: ErrorPageProps) {
  const {t} = useI18n()

  const {setVisibility} = useSidebar()

  return (
    <Box flexDirection='column' flexGrow={1}>
      {withMenuButton && (
        <div className={styles.header}>
          <button
            className={styles.burger}
            onClick={() => setVisibility(true)}
            data-testid={'ui-sidebar-burger' /*UI | Кнопка показа/скрытия сайдбара*/}
          >
            <MenuIcon fontSize={40} />
          </button>
        </div>
      )}

      <Box className={styles.root} alignItems='center' justifyContent='center' p='m' flexDirection='column' gap='m'>
        <div className={styles.title}>{t('shared-boundary.error-page.title', 'Ошибка')}</div>

        <div className={styles.text}>{t('shared-boundary.error-page.text', 'Извините, что-то пошло не так.')}</div>

        <div className={styles.error}>{toString(error)}</div>

        {!!eventId && <div className={styles.error}>{`EventId: ${eventId}`}</div>}

        <Button.Text size='m' onClick={onReset}>
          {t('shared-boundary.error-page.button-retry', 'Попробовать еще раз')}
        </Button.Text>

        <Button.Text size='m' onClick={() => resetNavigation()} variant='secondary'>
          {t('shared-boundary.error-page.button-refresh', 'Перезагрузить приложение')}
        </Button.Text>
      </Box>
    </Box>
  )
}

export default ErrorPage
