import {useIsMobileStrict} from '@eda-restapp/ui'
import {useEffect} from 'react'
import {GlobalStyles} from 'tss-react'

import {isAppleDevice} from '../utils'

type FormElementType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLLabelElement | HTMLButtonElement

let count = 0
let scrollTop = 0
let overflowTouchCaptured = false
let startTouchScreenY: null | number = null
let startTouchScreenX: null | number = null
let scrollableAncestor: HTMLElement | null = null
let preventBlurCaptured = false

function hasAncestorOfType(element: Element | null, condition: (el: Element | null) => boolean): boolean {
  if (!element || element === document.documentElement) {
    return false
  }
  return condition(element) || condition(element.parentElement)
}

function findScrollableAncestor(element: HTMLElement): HTMLElement | null {
  const {overflowY, overflowX} = getComputedStyle(element)

  if ((overflowX === 'auto' || overflowX === 'scroll') && element.scrollWidth > element.clientWidth) {
    return element
  }

  if ((overflowY === 'auto' || overflowY === 'scroll') && element.scrollHeight > element.clientHeight) {
    return element
  }

  if (!element.parentElement || element === document.documentElement) {
    return null
  }

  return findScrollableAncestor(element.parentElement)
}

function isFormElement(element: Element | null) {
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLSelectElement ||
    hasAncestorOfType(element, (el) => el instanceof HTMLLabelElement) ||
    hasAncestorOfType(element, (el) => el instanceof HTMLButtonElement)
  )
}

function blurActiveElement() {
  // Скрываем клавиатуру на iOS при старте скролла,
  // т.к. иначе не всегда есть возможность подскроллить к нужному элементу
  // https://st.yandex-team.ru/EDADEV-11814
  // https://st.yandex-team.ru/EDADEV-11815

  if (isFormElement(document.activeElement)) {
    const activeElemenet = document.activeElement as FormElementType
    activeElemenet.blur()
  }
}

function onTouchStart(e: TouchEvent) {
  scrollableAncestor = findScrollableAncestor(e.target as HTMLElement)
  startTouchScreenY = e.touches[0].screenY
  startTouchScreenX = e.touches[0].screenX
  preventBlurCaptured = false

  if (isFormElement(document.activeElement) && !isFormElement(e.target as Element)) {
    blurActiveElement()
    preventBlurCaptured = true
  }
}

function onTouchEnd(e: TouchEvent) {
  startTouchScreenY = null
  overflowTouchCaptured = false
  scrollableAncestor = null
  if (preventBlurCaptured) {
    const {clientX, clientY} = e.changedTouches[e.changedTouches.length - 1]
    const touchEndElement = document.elementFromPoint(clientX, clientY)
    // Если тач завершился на элементе формы, и при этом мы пытались до этого скрыть клавиатуру,
    // не даем возможности сфокусироваться на новом элементе формы, иначе клавиатура будет подскакивать
    if (isFormElement(touchEndElement)) {
      e.preventDefault()
    }
  }
}

function onTouchMove(e: TouchEvent) {
  if (!scrollableAncestor || startTouchScreenY == null || startTouchScreenX == null) {
    e.preventDefault()
  } else {
    const {screenY, screenX} = e.touches[0]
    if (scrollableAncestor.scrollTop === 0) {
      if (screenY > startTouchScreenY || overflowTouchCaptured) {
        e.preventDefault()
        overflowTouchCaptured = true
      }
    } else if (scrollableAncestor.offsetHeight + scrollableAncestor.scrollTop >= scrollableAncestor.scrollHeight) {
      if (screenY < startTouchScreenY || overflowTouchCaptured) {
        e.preventDefault()
        overflowTouchCaptured = true
      }
    } else if (scrollableAncestor.scrollLeft === 0) {
      if (screenX > startTouchScreenX || overflowTouchCaptured) {
        e.preventDefault()
        overflowTouchCaptured = true
      }
    } else if (scrollableAncestor.offsetWidth + scrollableAncestor.scrollLeft >= scrollableAncestor.scrollWidth) {
      if (screenX < startTouchScreenX || overflowTouchCaptured) {
        e.preventDefault()
        overflowTouchCaptured = true
      }
    }
  }
  // Всегда при начала touchMove скрываем клавиатуру на iOS
  // При этом элемент не должен быть textarea, внутри которой мы пытаемся проскроллить
  if (document.activeElement !== e.target) {
    blurActiveElement()
  }
}

const touchListenerOptions: AddEventListenerOptions = {
  passive: false,
  capture: true
}

const HideBodyScroll = () => {
  const isMobile = useIsMobileStrict()

  useEffect(() => {
    if (++count > 1) {
      return
    }

    if (isMobile) {
      scrollTop = document.body.scrollTop + document.documentElement.scrollTop
      window.scrollTo(0, 0)
      document.documentElement.scrollTop = 0
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      document.body.style.top = -scrollTop + 'px'

      if (isAppleDevice()) {
        // По мотивам EDADEV-11224: Вернуть плавный скролл на iOS и избавится от залипаний при скролле
        // Для iOS прерываем тачи, которые начинаются на элементах без скролла,
        // Или на элементах со скроллом, но идущих в противоположном направлении возможному скроллу
        // Это все нужно чтобы полечить залипания скролла на iOS
        //
        // Данная механика НЕ должна работать в хромах, иначе пропадает возможность увидеть поисковый тулбар:
        // https://st.yandex-team.ru/EDADEV-11854
        document.addEventListener('touchstart', onTouchStart, touchListenerOptions)
        document.addEventListener('touchend', onTouchEnd, touchListenerOptions)
        document.addEventListener('touchmove', onTouchMove, touchListenerOptions)
      }
    }
    document.documentElement.setAttribute('data-hide-scroll', '')

    return () => {
      if (--count) {
        return
      }
      document.documentElement.removeAttribute('data-hide-scroll')
      if (isMobile) {
        document.body.style.top = ''
        window.scrollTo(0, scrollTop)

        if (isAppleDevice()) {
          document.removeEventListener('touchstart', onTouchStart, touchListenerOptions)
          document.removeEventListener('touchend', onTouchEnd, touchListenerOptions)
          document.removeEventListener('touchmove', onTouchMove, touchListenerOptions)
        }
      }
    }
  }, [isMobile])
  return (
    <GlobalStyles
      styles={{
        'html[data-hide-scroll], html[data-hide-scroll] body': {
          overflow: 'visible !important'
        },
        'html[data-device-mobile][data-hide-scroll], html[data-device-mobile][data-hide-scroll] body': {
          position: 'fixed',
          left: 0,
          right: 0
        }
      }}
    />
  )
}

export default HideBodyScroll
