import {Box, ChevronDownMIcon, ChevronUpMIcon, Typography} from '@eda-restapp/ui'
import {useState, type FC, type ReactNode} from 'react'

import styles from './SingleChatDropdown.module.css'

export type SingleChatDropdownProps = {
  title: string
  children?: ReactNode
}

export const SingleChatDropdown: FC<SingleChatDropdownProps> = ({title, children}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false)

  const handleDropdownStateChange = () => {
    setIsDropdownActive((prev) => !prev)
  }

  return (
    <div className={styles.dropdown}>
      <Box className={styles.header} px='l' py='m' justifyContent='space-between' onClick={handleDropdownStateChange}>
        <Typography thickness='medium' variant='body1'>
          {title}
        </Typography>

        <Box alignItems='center' justifyContent='center'>
          {isDropdownActive ? <ChevronUpMIcon /> : <ChevronDownMIcon />}
        </Box>
      </Box>

      {isDropdownActive && <div className={styles.children}>{children}</div>}
    </div>
  )
}
