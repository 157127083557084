import {useCopyToClipboard} from '@eda-restapp/hooks'
import {metrika} from '@eda-restapp/logger'
import moment from 'moment'
import type {FC} from 'react'
import React, {useEffect} from 'react'
import {useInView} from 'react-intersection-observer'
import {useLocation} from 'react-router-dom'

import {TicketPromocode} from './TicketPromocode'

type TicketPromocodeContainerProps = {
  expirationDate?: string
  promoDescription?: string
  promocode: string
  promocodeType?: string
  isRow?: boolean
  placement?: 'top' | 'bottom'
  separationLineColor?: string
}

export const TicketPromocodeContainer: FC<TicketPromocodeContainerProps> = ({
  expirationDate,
  promoDescription,
  promocode,
  promocodeType,
  isRow,
  placement,
  separationLineColor
}) => {
  const {ref, inView} = useInView({triggerOnce: true, threshold: 0})
  const {copy, copied} = useCopyToClipboard({copiedTimeout: 1500})
  const location = useLocation()

  const metrikParams = {promocodeType, path: location.pathname}

  const onCopy = () => {
    copy(promocode)
    metrika({target: 'promocode_copied', params: metrikParams})
  }

  const expirationDateMoment = moment(expirationDate)
  const isCurrentYear = moment().isSame(expirationDateMoment, 'year')
  const expirationDateFormatted = expirationDateMoment.format(isCurrentYear ? 'DD MMMM' : 'DD MMMM YYYY')

  useEffect(() => {
    if (inView) {
      metrika({target: 'promocode_viewed', params: metrikParams})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <TicketPromocode
      ref={ref}
      expirationDate={expirationDate ? expirationDateFormatted : undefined}
      promoDescription={promoDescription}
      promocode={promocode}
      copied={copied}
      isRow={isRow}
      placement={placement}
      separationLineColor={separationLineColor}
      onCopy={onCopy}
    />
  )
}
