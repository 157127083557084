import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Modal, Spinner, useIsMobileStrict} from '@eda-restapp/ui'
import React, {type FC, useEffect, useState} from 'react'

import type {IBusyModeResponse} from '@restapp/shared-api/types/places'

import {BusyModeModalLayout} from './components/BusyModeModalLayout/BusyModeModalLayout'
import {BusyModeUnavailable} from './components/BusyModeUnavailable/BusyModeUnavailable'

type BusyModeModalProps = {
  busyModeData?: IBusyModeResponse
  canEnableBusyMode: boolean
  isBusyModeDisabling: boolean
  isBusyModeEnabling: boolean
  isLoading: boolean
  placeId?: number
  onClose: () => void
  onDisableBusyModeClick: () => void
  onEnableBusyModeClick: ({
    additionalMinutes,
    durationMinutes
  }: {
    additionalMinutes: number
    durationMinutes: number
  }) => void
}

export const BusyModeModal: FC<BusyModeModalProps> = ({
  busyModeData,
  canEnableBusyMode,
  isBusyModeDisabling,
  isBusyModeEnabling,
  isLoading,
  placeId,
  onClose,
  onDisableBusyModeClick,
  onEnableBusyModeClick
}) => {
  // If <busymode> enabled, than remove pre-selection
  const [additionalMinutes, setAdditionalMinutes] = useState<number>(-1)
  const [durationMinutes, setDurationMinutes] = useState<number>(-1)

  // This effects is needed because default state-values calculates before busyModeData is loaded
  useEffect(() => {
    if (busyModeData && !busyModeData.busy_mode.enabled && additionalMinutes === -1) {
      setAdditionalMinutes(busyModeData.available_parameters.default.extra_time)
    }

    if (busyModeData && !busyModeData.busy_mode.enabled && additionalMinutes === -1) {
      setDurationMinutes(busyModeData.available_parameters.default.valid_for)
    }
  }, [additionalMinutes, busyModeData, durationMinutes])

  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  const busyModeModalHeader = busyModeData?.busy_mode.enabled
    ? t('main-orders.busy-mode-modal.busy-mode-enabled-header', 'Высокая загрузка включена')
    : t('main-orders.busy-mode-modal.header', 'Включить высокую загрузку')
  const enableFooterButtonLabel = busyModeData?.busy_mode.enabled
    ? t('main-orders.busy-mode-modal.change-btn', 'Изменить')
    : t('main-orders.busy-mode-modal.enable-btn', 'Включить')
  const isValid =
    busyModeData?.available_parameters.extra_time_intervals.find((option) => option.value === additionalMinutes) &&
    busyModeData?.available_parameters.valid_for_intervals.find((option) => option.value === durationMinutes)

  if (isLoading) {
    return (
      <Modal open onClose={onClose}>
        <Modal.Content>
          <Box justifyContent='center' style={{height: isMobile ? '100%' : 450}}>
            <Spinner />
          </Box>
        </Modal.Content>
      </Modal>
    )
  }

  if (!busyModeData || !placeId) {
    onClose()

    return null
  }

  if (!canEnableBusyMode) {
    return <BusyModeUnavailable open cookingMinutes={busyModeData.avg_preparation_time.text} onClose={onClose} />
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header title={busyModeModalHeader} />
      <Modal.Content>
        <BusyModeModalLayout
          additionalMinutes={additionalMinutes}
          busyModeData={busyModeData}
          durationMinutes={durationMinutes}
          placeId={placeId}
          setAdditionalMinutes={setAdditionalMinutes}
          setDurationMinutes={setDurationMinutes}
        />
      </Modal.Content>
      <Modal.Footer>
        <Box gap={isMobile ? 's' : 'm'}>
          {busyModeData.busy_mode.enabled && (
            <Button.Text
              disabled={isBusyModeDisabling || isBusyModeEnabling}
              fullWidth
              loading={isBusyModeDisabling}
              variant='secondary'
              onClick={onDisableBusyModeClick}
            >
              {t('main-orders.busy-mode-modal.disable-btn', 'Отключить')}
            </Button.Text>
          )}
          <Button.Text
            disabled={!isValid || isBusyModeEnabling || isBusyModeDisabling}
            fullWidth
            loading={isBusyModeEnabling}
            variant='primary'
            onClick={() => onEnableBusyModeClick({additionalMinutes, durationMinutes})}
          >
            {enableFooterButtonLabel}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}
