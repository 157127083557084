import {useI18n} from '@eda-restapp/i18n'
import {MoreIcon} from '@eda-restapp/ui'
import {IconButton, Menu, MenuItem} from '@mui/material'
import cn from 'classnames'
import React, {type MouseEvent, type ReactElement, useCallback, useState} from 'react'

import {useNewsHeadStyles} from './NewsHead.style'

interface NewsHeadProps {
  onMarkAllRead(): void
}

function NewsHead({onMarkAllRead}: NewsHeadProps): ReactElement {
  const {t} = useI18n()
  const {classes} = useNewsHeadStyles()

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null)
  const menuOpen = Boolean(menuAnchor)

  const handleClick = useCallback((event: MouseEvent) => setMenuAnchor(event.currentTarget), [])
  const handleClose = useCallback(() => setMenuAnchor(null), [])

  const handleMarkAllReadClick = useCallback(() => {
    setMenuAnchor(null)
    onMarkAllRead()
  }, [onMarkAllRead])

  return (
    <div className={classes.head}>
      <h2>{t('main-communications.news-head.novosti', 'Новости')}</h2>
      <IconButton
        className={cn(classes.moreButton, {[classes.moreButtonPressed]: menuOpen})}
        size='small'
        onClick={handleClick}
        data-testid={
          'main-communications-news-head-display-more'
        } /* Коммуникации | Кнопка "Показать больше" (три точки) */
      >
        <MoreIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        open={menuOpen}
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorPosition={{top: 8, left: 0}}
        onClose={handleClose}
      >
        <MenuItem
          dense
          onClick={handleMarkAllReadClick}
          data-testid={
            'main-communications-news-head-mark-everything-as-read'
          } /* Коммуникации | Кнопка "Отметить всё прочитанным" */
        >
          {t('main-communications.news-head.otmetit-vsyo-prochitannim', 'Отметить всё прочитанным')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default NewsHead
