import {isBoolean} from 'lodash-es'
import React from 'react'
import {keyframes} from 'tss-react'
import {withStyles} from 'tss-react/mui'

import Spinner from '../Spinner'

type DefaultProps = {
  type: 'yellow' | 'default'
}

type OuterProps = DefaultProps & {
  children?: React.ReactNode
  until: boolean | Promise<any>
} & {
  className?: string
  classes?: Partial<Record<keyof typeof styles, string>>
}

type InnerProps = OuterProps

type State = {
  loading: boolean
}

class LandingApp extends React.Component<InnerProps, State> {
  static defaultProps: DefaultProps = {
    type: 'default'
  }

  state: State = {
    loading: false
  }

  renderSpinnerType() {
    const {classes: c = {}, type} = this.props
    const spinnerType = {
      default: () => <Spinner size={40} />,
      yellow: () => <div className={c.yellowSpinner} />
    }

    return spinnerType[type]()
  }

  loadUntil = async () => {
    if (!isBoolean(this.props.until)) {
      try {
        this.setState({loading: false})
        await this.props.until
      } finally {
        this.setState({loading: true})
      }
    }
  }

  componentDidMount() {
    void this.loadUntil()
  }

  componentDidUpdate(prevProps: OuterProps) {
    if (prevProps.until !== this.props.until) {
      void this.loadUntil()
    }
  }

  render() {
    const {classes: c = {}, children, until} = this.props
    const loadUntil = isBoolean(until) ? until : this.state.loading

    return loadUntil ? children : <div className={c.root}>{this.renderSpinnerType()}</div>
  }
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    flex: '1 1 auto'
  },
  yellowSpinner: {
    border: '3px solid transparent',
    borderTop: '3px solid #ffe033',
    borderLeft: '3px solid #ffe033',
    borderRadius: '50%',
    width: 50,
    height: 50,
    boxSizing: 'border-box',
    animation: `${keyframes`
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
    `} 1000ms linear infinite`
  }
} as const

export default withStyles(LandingApp, styles)
