import type moment from 'moment'
import React from 'react'

import {useCountdown} from '@restapp/shared'

type BusyModeCountdownProps = {
  className?: string
  until: moment.Moment
  onFinish?: () => Promise<void>
}

const formatDuration = (duration: moment.Duration): string => {
  const minutes = duration.minutes().toString().padStart(2, '0')
  const seconds = duration.seconds().toString().padStart(2, '0')
  const minutesSeconds = `${minutes}:${seconds}`

  if (duration.hours() > 0) {
    return `${duration.hours()}:${minutesSeconds}`
  }

  return minutesSeconds
}

export const BusyModeCountdown: React.FC<BusyModeCountdownProps> = ({className, until, onFinish}) => {
  const countdown = useCountdown({until, onFinish})

  return (
    <span className={className} data-testid='busy-mode-time-counter'>
      {formatDuration(countdown.duration)}
    </span>
  )
}
