import {type Campaign, CampaignType} from '../types/campaign'

export const getCampaignInfo = (campaign: Campaign) => {
  return {
    hasLowRatingError: campaign.status === 'suspended' && campaign.is_rating_status_ok === false,
    hasAccessVendor: campaign.has_access_vendor ?? true,
    clicks: campaign.stats?.clicks.sum ?? 0,
    clicksDelta: campaign.stats?.clicks.delta ?? 0,
    orders: campaign.stats?.ad_orders.sum ?? 0,
    ordersDelta: campaign.stats?.ad_orders.delta ?? 0,
    shows: campaign.stats?.shows.sum ?? 0,
    showsDelta: campaign.stats?.shows.delta ?? 0,
    campaignType: campaign.campaign_type ?? CampaignType.cpc,
    yandexUID: campaign.owner?.yandex_uid,
    ownerLogin: campaign.owner?.login,
    placeName: campaign.has_access_vendor === false ? campaign.place_name : '',
    placeAddress: campaign.has_access_vendor === false ? campaign.place_address_full : ''
  }
}
