import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {resetAll} from '../actions'
import {MODERATION_SLICE_NAME} from '../constants'
import type {ModerationSliceState} from '../types'

const initialState: ModerationSliceState = {
  deleted: []
}

export const slice = createSlice({
  name: MODERATION_SLICE_NAME,
  initialState: () => initialState,
  reducers: {
    delete: (state, action: PayloadAction<ModerationSliceState['deleted'][number]>) => {
      state.deleted.push(action.payload)
    },
    clearDeleted: (state) => {
      state.deleted = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, (state) => {
      slice.caseReducers.clearDeleted(state)
    })
  }
})
