import {useI18n} from '@eda-restapp/i18n'
import {Tabs} from '@mui/material'
import React, {type ReactElement, useCallback} from 'react'

import {Option, Select} from '@restapp/shared-ui'

import {NewsFilter, NewsSort} from '../../../types'
import NewsTab from '../NewsTab/NewsTab'

import {useNewsTabsStyles} from './NewsTabs.style'

type NewsTabsProps = {
  filter: NewsFilter
  sort: NewsSort
  onChangeFilter(filter: NewsFilter): void
  onChangeSort(sort: NewsSort): void
}

function NewsTabs({filter, sort, onChangeFilter, onChangeSort}: NewsTabsProps): ReactElement {
  const {t} = useI18n()
  const {classes: c} = useNewsTabsStyles()

  const NEWS_TABS = [
    {label: t('main-communications.news-tabs.vse', 'Все'), value: NewsFilter.ALL},
    {label: t('main-communications.news-tabs.obuchenie', 'Обучение'), value: NewsFilter.TUTORIAL},
    {
      label: t('main-communications.news-tabs.novosti-servisa', 'Новости сервиса'),
      value: NewsFilter.NEWS
    },
    {label: t('main-communications.news-tabs.stati', 'Статьи'), value: NewsFilter.ARTICLE}
  ]

  const NEWS_SORT_OPTIONS = [
    {
      label: t('main-communications.news-tabs.snachala-novie', 'Сначала новые'),
      value: NewsSort.DATE_DESC
    },
    {
      label: t('main-communications.news-tabs.snachala-starie', 'Сначала старые'),
      value: NewsSort.DATE_ASC
    },
    {
      label: t('main-communications.news-tabs.snachala-vazhnie', 'Сначала важные'),
      value: NewsSort.PRIORITY_DESC
    },
    {
      label: t('main-communications.news-tabs.snachala-nevazhnie', 'Сначала неважные'),
      value: NewsSort.PRIORITY_ASC
    }
  ]

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, value: NewsFilter) => {
      onChangeFilter(value)
    },
    [onChangeFilter]
  )

  const SelectIgnoreTabsProps = () => (
    <div className={c.selectWrapper}>
      <Select
        value={sort}
        onChangeValue={onChangeSort}
        data-testid={'news-sort-options-button' /* /* Раздел "Новости" | Кнопка списка видов сортировки */}
      >
        {NEWS_SORT_OPTIONS.map(({label, value}) => (
          <Option
            key={value}
            value={value}
            data-testid={`news-sort-options-${label}` /* Раздел "Новости" | Фильтр сортировки */}
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>
  )

  return (
    <Tabs
      classes={{indicator: c.noIndicator, scrollableX: c.tabsScrollable}}
      className={c.tabs}
      variant='scrollable'
      scrollButtons={false}
      value={filter}
      onChange={handleTabChange}
    >
      {NEWS_TABS.map(({label, value}) => (
        <NewsTab
          key={label}
          label={label}
          value={value}
          data-testid={`news-tab-${label}` /* Раздел "Новости" | Фильтр типа новости */}
        />
      ))}
      <SelectIgnoreTabsProps />
    </Tabs>
  )
}

export default NewsTabs
