import type {StoryFn} from '@storybook/react'
import React, {useEffect} from 'react'

const originalDateNow = Date.now
const restore = () => {
  Date.now = originalDateNow
}

const withStaticDate = (date: Date) =>
  function StaticDateDecorator(Story: StoryFn) {
    Date.now = () => date.getTime()

    useEffect(() => restore, [])

    return <Story />
  }

export default withStaticDate
