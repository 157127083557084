import {useExp3} from '@eda-restapp/configs'

import {useApiQuery} from '@restapp/core-api'

type UseCheckPartnerProps = {
  directToken: string | null | undefined
}

export const useDirectCheck = ({directToken}: UseCheckPartnerProps) => {
  const config = useExp3('restapp_promotion')

  const {data, isLoading, isError} = useApiQuery({
    url: '/4.0/restapp-front/marketing/v1/ad/check-ord-info',
    method: 'GET',
    enabled: Boolean(directToken) && config.direct.enableOrdInfoCheck,
    ...(directToken && {
      headers: {
        Authorization: directToken
      }
    })
  })

  const invalidAccount = data?.code === '200'

  return {
    creationDisabled: isError,
    invalidAccount,
    invalidAccountMessage: invalidAccount ? data.message : undefined,
    isLoading,
    isError
  }
}
