import {Typography, CrossLIcon} from '@eda-restapp/ui'
import cn from 'classnames'

import {Spinner} from '@restapp/shared-ui'

import styles from './PassportFullScreenCloseButton.module.css'

type PassportFullScreenCloseButtonProps = {
  delay?: number
  onClick?(): void
}

export const PassportFullScreenCloseButton = ({delay, onClick}: PassportFullScreenCloseButtonProps) => (
  <div className={styles.root}>
    {Number(delay) > 0 ? (
      <>
        <div className={cn(styles.absoluteCenter, styles.absoluteCenterNumber)}>
          <Typography variant='body2'>{delay}</Typography>
        </div>
        <div className={styles.absoluteCenter}>
          <Spinner size={30} />
        </div>
      </>
    ) : (
      <button className={cn(styles.absoluteCenter, styles.closeBtn)} onClick={onClick}>
        <div className={styles.closeBtn}>
          <CrossLIcon />
        </div>
      </button>
    )}
  </div>
)
