import {useExp3} from '@eda-restapp/configs'
import {useCallback} from 'react'

import {useOldHeavyPlaces} from '@restapp/core-places'

const useIsAvailableBusinessType = () => {
  const {allowed_business_types} = useExp3('restapp_promotion')

  const {getPlaceById} = useOldHeavyPlaces()

  const isAvailableBusinessType = useCallback(
    (placeId: number) => {
      const businessType = getPlaceById(placeId)?.brand.business_type

      if (!businessType || !allowed_business_types.length) {
        return true
      }

      return allowed_business_types.includes(businessType)
    },
    [allowed_business_types, getPlaceById]
  )

  return {
    isAvailableBusinessType
  }
}

export default useIsAvailableBusinessType
