import {isTablet} from '../Device'

export const lockLandscapeTablet = async () => {
  if (isTablet) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await screen.orientation?.lock?.('landscape') // eslint-disable-line
    } catch {
      // Error handling is not required
    }
  }
}

export const unlockLandscapeTablet = () => {
  if (isTablet && screen.orientation && screen.orientation.unlock) {
    screen.orientation.unlock()
  }
}
