import {useI18n} from '@eda-restapp/i18n'
import {useState, useCallback, useEffect, useMemo} from 'react'

import type {ApiErrorResponse, VendorApi as Api} from '@restapp/shared-api'

import type {Params, Body, Response, Method, TVariables} from '../../types'
import useApiQuery from '../../useApiQuery'
import {useApiDeps} from '../useApiDeps'
import useApiMutation from '../useApiMutation'

import getStatus from './getStatus'
import preparePollingParams from './preparePollingParams'
import type {AsyncMutationConfig, AsyncMutationOptions} from './types'

export const DEFAULT_POLLING_INTERVAL = 2000

const useAsyncMutation = <
  // Обязательно должен быть response
  TUrl extends [Response<TUrl, TMethod>] extends [never] ? never : keyof Api,
  TMethod extends Method<TUrl>,
  TUrlPolling extends keyof Api,
  TMethodPolling extends Method<TUrlPolling>
>(
  config: AsyncMutationConfig<TUrl, TMethod, TUrlPolling, TMethodPolling, TVariables<TUrl, TMethod>>,
  options: AsyncMutationOptions<TUrl, TMethod> = {}
) => {
  const {t} = useI18n()
  const {snackbar} = useApiDeps()
  const {
    polling: {timeout, ...pollingConfig},
    preventPolling,
    getMutationError,
    ...mutationConfig
  } = config
  const [isPollingActive, setIsPollingActive] = useState(false)
  const [apiError, setApiError] = useState<ApiErrorResponse | null | undefined>(null)

  useEffect(() => {
    if (!isPollingActive || !timeout) {
      return
    }

    const timer = setTimeout(() => {
      setIsPollingActive(false)
      setApiError({
        code: 'timeout',
        message: t(
          'core-api.use-async-mutation.previsheno-vremya-ozhidaniya-zaprosa',
          'Превышено время ожидания запроса. Попробуйте еще раз'
        )
      })
    }, timeout)

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollingActive])

  // Вынимаем mutateAsync из mutation, поскольку его реализация нам не нужна, а mutation используется далее
  const {
    mutateAsync,
    isPaused: isMutationPaused,
    failureCount: mutationFailureCount,
    ...mutation
  } = useApiMutation(mutationConfig, {
    ...options,
    onError: (errorResponse) => {
      setIsPollingActive(false)
      setApiError(errorResponse.responseData)

      options.onError?.(errorResponse.responseData, snackbar, errorResponse.traceId)
    },
    onSuccess: (response) => {
      const error = getMutationError?.(response)

      if (error) {
        setIsPollingActive(false)
        setApiError(error)

        options.onError?.(error, snackbar)
        return
      }

      if (preventPolling?.(response)) {
        // тут просто повторяется поведение из onSuccess у поллинга
        setIsPollingActive(false)
        setApiError(null)

        void options.onSuccess?.(response, {body: config.body, params: config.params}, null)
        return
      }

      setIsPollingActive(true)
      setApiError(null)
    }
  })

  const mutationSuccessOpts =
    mutation.status === 'success'
      ? ([mutation.data, {body: config.body, params: config.params} as TVariables<TUrl, TMethod>, null] as const)
      : undefined

  const bodyAndParams = useMemo(
    () =>
      mutation.status === 'success' && mutationSuccessOpts
        ? preparePollingParams(config, mutationSuccessOpts)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.data]
  )

  const {interval, stopPredicate, getError, ...restPollingConfig} = pollingConfig

  const polling = useApiQuery({
    ...restPollingConfig,
    body: bodyAndParams?.body as Body<Api[TUrlPolling][TMethodPolling]>,
    params: bodyAndParams?.params as Params<Api[TUrlPolling][TMethodPolling]>,
    refetchInterval: interval || DEFAULT_POLLING_INTERVAL,
    enabled: isPollingActive && Boolean(bodyAndParams),
    onError: (errorResponse) => {
      setIsPollingActive(false)
      setApiError(errorResponse.responseData)

      options.onError?.(errorResponse.responseData, snackbar, errorResponse.traceId)
    },
    onSuccess: (response: Response<TUrlPolling, TMethodPolling>) => {
      if (stopPredicate(response)) {
        setIsPollingActive(false)
        setApiError(null)

        if (options.onSuccess && mutationSuccessOpts) {
          void options.onSuccess(...mutationSuccessOpts)
        }
      } else {
        const error = getError(response)
        if (error) {
          setIsPollingActive(false)
          setApiError(error)

          options.onError?.(error, snackbar)
        }
      }
    }
  })

  const reset = useCallback(() => {
    setIsPollingActive(false)
    setApiError(null)
    mutation.reset()
    polling.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.reset, polling.remove])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset, [])

  const status = getStatus(mutation.status, polling.status, apiError, isPollingActive)

  return {
    ...mutation,
    mutationFailureCount,
    pollingFailureCount: polling.failureCount,
    error: apiError,
    reset,
    status,
    isError: status === 'error',
    isIdle: status === 'idle',
    isLoading: status === 'loading',
    isPolling: status === 'polling',
    isSuccess: status === 'success',
    isMutationPaused
  }
}

export default useAsyncMutation
