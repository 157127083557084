import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {userLogout} from '../../slice'

const Logout: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userLogout())
    navigate('/')
  }, [dispatch, navigate])

  return null
}

export default Logout
