import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'
import {useDispatch} from 'react-redux'

import type {LegacyNewChatParams} from '../slice/chatsSlice'
import {chatsSlice} from '../slice/chatsSlice'

interface UseSupportChat {
  openNewSupportChat: (
    metadata?: LegacyNewChatParams & {
      /**
       * Слаг из конфига `restapp_support_multi_chat.slugToTopic`.
       * Нужен если хотим открывать конкретную ветку дерева тематик.
       */
      supportTreeSlug?: string
    }
  ) => void
  openChat: (chatId: string) => void
}

export function useSupportChat(): UseSupportChat {
  const {t} = useI18n()
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const dispatch = useDispatch()

  return {
    openNewSupportChat: (metadata = {}) => {
      if (supportMultiChatConfig.overrideChatCreation) {
        const multiChatPath = supportMultiChatConfig.slugToTopic[metadata.supportTreeSlug || '']
        const legacyChatFallbackPath = supportMultiChatConfig.legacyPathFallback[metadata.path || '']

        dispatch(
          chatsSlice.actions.openMultiChatDrawerWithSupportTree({
            path: multiChatPath || legacyChatFallbackPath,
            orderId: metadata.order_id,
            placeId: metadata.place_id
          })
        )
        return
      }

      if (supportSingleChatConfig.overrideChatCreation) {
        dispatch(
          chatsSlice.actions.openSingleChat({
            orderId: metadata.order_id,
            placeId: metadata.place_id
          })
        )
        return
      }

      dispatch(
        chatsSlice.actions.setLegacyNewChatParams({
          order_id: metadata.order_id,
          place_id: metadata.place_id,
          doccenter_id: metadata.doccenter_id,
          path: metadata.path,
          place_ids: metadata.place_ids
        })
      )
    },
    openChat: (chatId: string) => {
      if (supportSingleChatConfig.blockChatOpening) {
        Snackbar.error({
          text: t('main-chats.support-chats.chat-open-blocked', 'Извините, сейчас не можем открыть обращение')
        })
        return
      }

      dispatch(chatsSlice.actions.openLegacyChat(chatId))
    }
  }
}
