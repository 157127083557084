import type {FC, ReactNode} from 'react'

import {useOldAuthPartnerIdSetup, usePassportEnabled} from '../../../hooks'
import {useDeleteXTokenOnPartnerUidSetup} from '../hooks/useDeleteXTokenOnPartnerUidSetup'
import {PassportModalsContainer} from '../PassportModals'

type PassportEnabledGuardProps = {
  children: ReactNode
}
export const PassportEnabledGuard: FC<PassportEnabledGuardProps> = ({children}) => {
  const passportEnabled = usePassportEnabled()

  useDeleteXTokenOnPartnerUidSetup()
  useOldAuthPartnerIdSetup()

  if (passportEnabled.enabled) {
    return <PassportModalsContainer>{children}</PassportModalsContainer>
  }

  return <>{children}</>
}
