import {useCallback, useEffect} from 'react'
import type {ConnectedProps} from 'react-redux'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import axios from 'axios'
import {AppEvent} from '../../../../types'
import {baseURL} from '../../../../api'
import {selectIsOffline} from './selectIsOffline'
import type {NetworkSlice} from './networkSlice'
import {setIsOffline} from './networkSlice'
import {useEnv} from '@restapp/core-di/hooks'

type InnerProps = ConnectedProps<typeof reduxConnector>

const reduxConnector = connect(
  (state: NetworkSlice) => {
    return {isNetOffline: selectIsOffline(state)}
  },
  (dispatch) => {
    return {setIsNetOffline: (v: boolean) => dispatch(setIsOffline(v))}
  }
)

const checkForNetAvailability = () =>
  axios.get(`${baseURL}/manifest.json?_t=${Date.now()}`).catch(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          void checkForNetAvailability().then(resolve)
        }, 5e3)
      })
  )

function WatchNetworkStatus({isNetOffline, setIsNetOffline}: ConnectedProps<typeof reduxConnector>) {
  const env = useEnv()

  const onConnectionRestored = useCallback(() => {
    // Проверка в основном чтобы не слать лишние логи
    if (isNetOffline) {
      setIsNetOffline(false)
    }
  }, [isNetOffline, setIsNetOffline])

  const onConnectionLost = useCallback(async () => {
    if (isNetOffline) {
      return
    }

    setIsNetOffline(true)
    await checkForNetAvailability()
    onConnectionRestored()
  }, [isNetOffline, onConnectionRestored, setIsNetOffline])

  useEffect(() => {
    env.api.events.on(AppEvent.NetworkError, onConnectionLost)
    env.api.events.on(AppEvent.NetworkSuccess, onConnectionRestored)
    return () => {
      env.api.events.removeListener(AppEvent.NetworkError, onConnectionLost)
      env.api.events.removeListener(AppEvent.NetworkSuccess, onConnectionRestored)
    }
  }, [env.api.events, onConnectionLost, onConnectionRestored])

  return null
}

export default compose<InnerProps, {}>(reduxConnector)(WatchNetworkStatus)
