import {metrika} from '@eda-restapp/logger'
import {ChevronRightMIcon, LightningIcon, MessageIcon} from '@eda-restapp/ui'
import React, {useCallback, useEffect, useState} from 'react'
import {useInView} from 'react-intersection-observer'

import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'
import {Alert} from '@restapp/shared-ui/Alert'

import type {NotificationType} from '../../types'

import NotificationModal from './NotificationModal/NotificationModal'

interface NotificationProps {
  className?: string
  notification: NotificationType

  onMarkRead(id: string): void
}

type InnerProps = NotificationProps & WithPortalProps

function Notification({className, notification, onMarkRead, openPortal}: InnerProps) {
  const {ref, inView} = useInView()
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    if (!isShown && inView) {
      setIsShown(true)

      if (notification.info.important) {
        metrika({target: 'show_notifpage_notification_crit', params: {id: notification.id}})
      } else {
        metrika({target: 'show_notifpage_notification_normal', params: {id: notification.id}})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isShown])

  const handleClick = useCallback(async () => {
    if (notification.info.important) {
      metrika({target: 'click_notifpage_notification_crit', params: {id: notification.id}})
    } else {
      metrika({target: 'click_notifpage_notification_normal', params: {id: notification.id}})
    }

    await openPortal(<NotificationModal notification={notification} />).closed
    onMarkRead(notification.id)
  }, [onMarkRead, notification, openPortal])

  return (
    <Alert
      ref={ref}
      className={className}
      startIcon={notification.info.important ? <LightningIcon color='#FA6A3C' /> : <MessageIcon color='#FCB900' />}
      text={notification.preview.title}
      endIcon={<ChevronRightMIcon />}
      onClick={() => void handleClick()}
      data-testid={'notification-preview' /* Нотификации | Превью уведомления на экране "Новости" */}
    />
  )
}

export default withPortals(Notification)
