import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {compact} from 'lodash-es'
import React, {type FC} from 'react'
import {useNavigate} from 'react-router-dom'

import {useUserInfo} from '@restapp/core-auth/hooks'
import {useOldHeavyPlaces, usePlaces} from '@restapp/core-places/hooks'

import {PlaceLeadersEntryBanner} from './PlaceLeadersEntryBanner'

type PlaceLeadersEntryBannerContainerProps = {
  className?: string
}

export const PlaceLeadersEntryBannerContainer: FC<PlaceLeadersEntryBannerContainerProps> = ({className}) => {
  const placeLeadersConfig = useExp3('restapp_place_leaders')
  const {getPlaceById} = useOldHeavyPlaces({suspense: true})
  const {selectedPlaceIds} = usePlaces({suspense: true})
  const userCountryCode = useUserInfo()?.country_code
  const navigate = useNavigate()
  const {t} = useI18n()

  const selectedPlaces = compact(selectedPlaceIds.map(getPlaceById))
  const isAllPlacesRestaurants = selectedPlaces.find((place) => place.brand.business_type === 'restaurant')

  const handleActionButtonClick = () => {
    navigate('/place-leaders')
  }

  if (!placeLeadersConfig.showEntryPointBanner || !isAllPlacesRestaurants) {
    return null
  }

  const getHeaderText = () => {
    if (placeLeadersConfig.entryBannerHeaderText) {
      return placeLeadersConfig.entryBannerHeaderText
    }

    if (userCountryCode !== 'RU') {
      return t('shared.place-leaders-entry-banner.banner-header-text-international', 'Новый раздел — «Индекс качества»')
    }

    return t(
      'shared.reliable-partner-entry-banner.banner-header-text',
      'Статус «Топ» — для тех, кто хочет больше заказов'
    )
  }

  const getContentMainText = () => {
    if (placeLeadersConfig.promoBannerContentMainText) {
      return placeLeadersConfig.promoBannerContentMainText
    }

    if (userCountryCode !== 'RU') {
      return t(
        'shared.place-leaders-entry-banner.content-text-international',
        'Здесь — важные для пользователей показатели работы ресторана. Выполняйте цели по ним, чтобы быть на высоте.'
      )
    }

    return t(
      'shared.reliable-partner-entry-banner.content-text',
      'Выполняйте цели и поддерживайте качество на высоте. А мы бесплатно добавим вас в подборку и карусель — чтобы заметили все.'
    )
  }

  return (
    <PlaceLeadersEntryBanner
      className={className}
      backgroundImage={placeLeadersConfig.entryBannerBackgroundImageURL}
      backgroundLayer={placeLeadersConfig.entryBannerBackgroundLayerURL}
      badgesEnabled={placeLeadersConfig.entryBannerBadgesEnabled}
      contentMainText={getContentMainText()}
      headerText={getHeaderText()}
      orderIncreaseBadgeNumber={placeLeadersConfig.entryBannerBadgeOrderIncreaseNumber}
      onActionButtonClick={handleActionButtonClick}
    />
  )
}
