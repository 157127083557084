import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles({name: 'SelectExpandIcon'})({
  root: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none'
  },
  lock: {
    backgroundSize: 'cover!important',
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none'
  }
})

export default useStyles
