import {useI18n} from '@eda-restapp/i18n'
import React, {type ChangeEvent, type FC, useCallback} from 'react'

import {Input} from '@restapp/shared-ui/Input/Input'

import type {SurveyAnswerText, SetAnswer} from '../../../../types'

interface TextAnswerProps {
  answer: SurveyAnswerText

  setAnswer(answer: SetAnswer): void
}

export const TextAnswer: FC<TextAnswerProps> = ({answer, setAnswer}) => {
  const {t} = useI18n()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAnswer({
        type: answer.type,
        questionIndex: answer.questionIndex,
        pageIndex: answer.pageIndex,
        value: event.target.value
      })
    },
    [answer.pageIndex, answer.questionIndex, answer.type, setAnswer]
  )

  return (
    <Input
      multiline
      minRows={5}
      placeholder={t(
        'main-communications.text-answer.naprimer-opishite-vash-opit-ili-pod',
        'Например, опишите ваш опыт или поделитесь пожеланиями'
      )}
      fullWidth
      value={answer.text}
      onChange={handleChange}
      inputProps={{
        'data-testid': 'main-communications-text-answer-input'
      }} /* Модальное окно опроса | Блок для ввода текстового ответа */
    />
  )
}
