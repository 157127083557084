import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Button} from '@eda-restapp/ui'
import type {FC} from 'react'

import {createOauthUrl} from '../../helpers/createOauthUrl'
import {OauthStateEncoder} from '../../helpers/OauthStateEncoder'
import {usePassportName} from '../../hooks'
import {PassportIcon} from '../PassportIcon'

import styles from './OauthButton.module.css'

type OauthButtonProps = {
  loading?: boolean
}

export const OauthButton: FC<OauthButtonProps> = ({loading}) => {
  const {t} = useI18n()
  const restappAuthConfig = useExp3('restapp_auth')
  const passportName = usePassportName()

  return (
    <a
      data-testid='passport-auth-button'
      className={styles.link}
      href={createOauthUrl({
        passportUrl: restappAuthConfig.passport.passportUrl,
        oauthUrl: restappAuthConfig.passport.oauthUrl,
        clientId: restappAuthConfig.passport.oauthClientId,
        passportQuery: restappAuthConfig.passport.passportUrlAdditionalQuery,
        state: OauthStateEncoder.stringify({redirect_path: window.location.pathname})
      })}
      onClick={() => eventLogger({name: 'passport:click_passport_login_button'})}
    >
      <Button.MultiCenter
        variant='dark'
        startIcon={<PassportIcon className={styles.passportIcon} />}
        loading={loading}
        fullWidth
      >
        {t('core-auth.oauth-button.enter-passport', 'Войти с {passportName}', {passportName})}
      </Button.MultiCenter>
    </a>
  )
}
