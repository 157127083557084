import {useExp3} from '@eda-restapp/configs'
import {useUserInfo} from '@restapp/core-auth'
import {useIsEnabledInCurrentNetwork, isNativeVendor} from '@restapp/shared'
import {EATS_HOST, EDA_HOST, YANGO_HOST} from '@eda-restapp/i18n'

export const useBrandRedirect = () => {
  const userInfo = useUserInfo()
  const redirectBrandHostConfig = useExp3('restapp_redirect_brand_host')

  const enabledRedirect = useIsEnabledInCurrentNetwork(redirectBrandHostConfig.enableRedirect)

  const brandHost =
    redirectBrandHostConfig.countryHost ??
    (!!userInfo?.country_code ? getHostByCountry(userInfo?.country_code) : undefined)
  const enabled = !isNativeVendor && enabledRedirect && !!brandHost
  const needRedirect = enabled && window.location.host !== brandHost

  return {
    needRedirect,
    endUrl: `https://${brandHost}`
  }
}

const getHostByCountry = (country: string) => {
  switch (country) {
    case 'GE':
    case 'UZ':
    case 'AM':
      return EATS_HOST
    case 'CI':
    case 'ZM':
    case 'BO':
    case 'AZ':
      return YANGO_HOST
    default:
      return EDA_HOST
  }
}
