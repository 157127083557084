import {useI18n} from '@eda-restapp/i18n'
import {Button, Box, Typography} from '@eda-restapp/ui'
import {TextField} from '@mui/material'
import cn from 'classnames'
import type {FormikErrors, FormikHelpers} from 'formik'
import {Formik, Form} from 'formik'
import React, {type FC} from 'react'
import {Link} from 'react-router-dom'

import {usePassportName} from '@restapp/core-auth/passport/hooks'
import {Field} from '@restapp/shared-forms'

import styles from './EmailAuth.module.css'

interface LoginFormValues {
  email: string
  password: string
}

export type EmailAuthProps = {
  login: (email: string, password: string) => Promise<string | undefined>
  onSwitchAuth: () => void
}

export const EmailAuth: FC<EmailAuthProps> = ({login, onSwitchAuth}) => {
  const {t, tRaw} = useI18n()
  const passportName = usePassportName()

  // Боремся с подскроллом в WkWebview
  const onBlur = () => {
    window.setTimeout(() => window.scrollTo(0, 0), 100)
  }

  const handleSubmit = async (values: LoginFormValues, {setErrors}: FormikHelpers<LoginFormValues>) => {
    const error = await login(values.email, values.password)
    if (error) {
      setErrors({password: error})
    }
  }

  const validateForm = (values: LoginFormValues): FormikErrors<LoginFormValues> => {
    const errors: FormikErrors<LoginFormValues> = {}
    if (!values.email) {
      errors.email = t('core-auth.email-auth.email-empty', 'Эл.почта не указана')
    }
    if (!values.password) {
      errors.password = t('core-auth.email-auth.password-empty', 'Пароль не указан')
    }
    const hasCyrillicChars = /[\u0400-\u04FF]/.test(values.password)
    if (hasCyrillicChars) {
      errors.password = t(
        'core-auth.email-auth.password-has-forbidden-characters',
        'Пароль содержит недопустимые символы'
      )
    }

    return errors
  }

  return (
    <>
      <Formik<LoginFormValues>
        initialValues={{email: '', password: ''}}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({isSubmitting, isValid}) => (
          <Form>
            <div data-testid={'email-field' /*Авторизация | Блок с полем email*/} className={styles.inputWrap}>
              <Field name='email'>
                {({field, meta}) => (
                  <TextField
                    variant='standard'
                    {...field}
                    className={styles.input}
                    error={Boolean(meta.error)}
                    helperText={meta.error || ' '}
                    onBlur={onBlur}
                    label={t('core-auth.email-auth.elektronnaya-pochta', 'Электронная почта')}
                    type='email'
                    autoComplete='email'
                    inputProps={{'data-testid': 'auth-email' /*Авторизация | Поле ввода email*/}}
                  />
                )}
              </Field>
            </div>
            <div data-testid={'password-field' /*Авторизация | Блок с полем password*/} className={styles.inputWrap}>
              <Field name='password'>
                {({field, meta}) => (
                  <TextField
                    variant='standard'
                    {...field}
                    className={styles.input}
                    error={Boolean(meta.error)}
                    helperText={meta.error || ' '}
                    onBlur={onBlur}
                    label={t('core-auth.email-auth.parol', 'Пароль')}
                    type='password'
                    autoComplete='current-password'
                    inputProps={{'data-testid': 'auth-password' /*Авторизация | Поле ввода пароля*/}}
                  />
                )}
              </Field>
            </div>
            <div className={cn(styles.inputWrap, styles.bottomGroup)}>
              <Box flexDirection='column' className={styles.enterByEmail}>
                <Button.Text
                  disabled={!isValid}
                  loading={isSubmitting}
                  type='submit'
                  fullWidth
                  data-testid='auth-submit'
                >
                  {t('core-auth.email-auth.enter', 'Войти по электронной почте')}
                </Button.Text>

                <Box className={styles.badge} py='xs' px='s'>
                  <Typography variant='caption1' thickness='medium'>
                    {t('core-auth.email-auth.available-untill', 'доступно до 1 июня')}
                  </Typography>
                </Box>
              </Box>

              <Link
                className={styles.forgotPassword}
                to='/forgot-password'
                data-testid={'auth-forgot-password' /*Авторизация | Кнопка забыл пароль*/}
              >
                {t('core-auth.email-auth.forgot-password', 'Забыл пароль')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>

      <Box mt='m' justifyContent='space-around'>
        <Button.Text variant='transparent' onClick={onSwitchAuth}>
          {tRaw('core-auth.email-auth.or-login-with-passport', 'или войти {passport}', {
            passport: (
              <Typography color='main' variant='body2' thickness='medium'>
                {t('core-auth.email-auth.passport-name', 'с {passportName}', {
                  passportName
                })}
              </Typography>
            )
          })}
        </Button.Text>
      </Box>
    </>
  )
}
