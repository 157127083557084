import React from 'react'

import {useModerationStatus} from '@restapp/shared-menu'

import SidebarBadge, {BadgesType} from '../SidebarBadge'

const ModerationErrorsBadge: React.FC = () => {
  const moderationStatus = useModerationStatus()

  return moderationStatus.hasErrors ? <SidebarBadge type={BadgesType.NOTIFY_RED} /> : null
}

export default ModerationErrorsBadge
