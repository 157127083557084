import {Typography} from '@eda-restapp/ui'
import type {FC} from 'react'

import styles from './UnreadBadge.module.css'

export const UnreadBadge: FC<{count: number}> = ({count}) => (
  <Typography className={styles.badge} variant='caption1' thickness='medium'>
    {count > 9 ? '9+' : count}
  </Typography>
)
