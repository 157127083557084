import {isEmpty} from 'lodash-es'

import type {ApiQueryConfig} from '@restapp/core-api'
import {useApiQuery} from '@restapp/core-api'

type UseDiscountInfoOptions = Pick<
  ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/place/discount-info', 'POST'>,
  'enabled' | 'suspense'
>

export const useDiscountInfo = (placesIds: number[], options?: UseDiscountInfoOptions) =>
  useApiQuery({
    url: '/4.0/restapp-front/v1/place-subscriptions/v1/place/discount-info',
    method: 'POST',
    body: {
      place_ids: placesIds
    },
    enabled: !isEmpty(placesIds) && (options?.enabled ?? true)
  })
