import {useI18n} from '@eda-restapp/i18n'

import type {ApiQueryConfig} from '@restapp/core-api'
import {useApiQuery} from '@restapp/core-api'
import type {NavigationResponse} from '@restapp/shared-api'
import {SectionSlug} from '@restapp/shared-api'
import {useUserInfo} from '@restapp/core-auth'

type UseNavigationOptions<TSelectedData> =
  | Pick<
      ApiQueryConfig<'/4.0/restapp-front/api/v1/configuration/navigation', 'GET', TSelectedData>,
      'select' | 'enabled'
    >
  | Pick<
      ApiQueryConfig<'/4.0/restapp-front/eats-partner-settings/v1/navigation', 'GET', TSelectedData>,
      'select' | 'enabled'
    >

export default function useNavigation<TSelectedData>({select}: UseNavigationOptions<TSelectedData> = {}) {
  const {t, lang} = useI18n()
  const userInfo = useUserInfo()

  const INITIAL_SIDEBAR: NavigationResponse = {
    logo: {
      image: {path: 'https://avatars.mds.yandex.net/get-bunker/50064/bc068e99a661850e734b282f4e743a0c086ddf4f/orig'}
    },
    groups: [
      {
        id: 'main-navigation',
        nav: [
          {
            id: SectionSlug.active,
            type: 'component',
            label: t('main-layout.sidebar-container.zakazi', 'Заказы')
          },
          {
            id: SectionSlug.menu,
            type: 'component',
            label: t('main-layout.sidebar-container.menyu', 'Меню')
          }
        ]
      },
      {
        id: 'footer-navigation',
        nav: [
          {
            id: SectionSlug.logout,
            type: 'component',
            label: t('main-layout.sidebar-container.exit', 'Выйти')
          }
        ]
      }
    ],
    main: {component: {id: SectionSlug.active}}
  }

  return useApiQuery({
    url: '/4.0/restapp-front/eats-partner-settings/v1/navigation',
    method: 'GET',
    headers: {
      'X-Language': lang
    },
    queryHash: `${userInfo?.partner_uuid || 'no-auth'}-${lang}`,
    initialData: INITIAL_SIDEBAR,
    initialDataUpdatedAt: 0,
    staleTime: Infinity,
    refetchInterval: 3 * 60_000,
    select
  })
}
