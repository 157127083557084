import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {ChevronRightMIcon, Telegram} from '@eda-restapp/ui'
import cn from 'classnames'
import {compact, flatMap, uniq} from 'lodash-es'
import type {FC} from 'react'
import React from 'react'

import {useOldHeavyPlaces} from '@restapp/core-places'
import {useTelegramList} from '@restapp/shared-places'
import {LineClamp, Skeleton} from '@restapp/shared-ui'

import {useStyles} from './TelegramButton.styles'

type TelegramButtonProps = {
  /** Если переданы некоторые id будут показаны только логины для этих плесов */
  filterPlaceIds?: number[]
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const TelegramButton: FC<TelegramButtonProps> = ({filterPlaceIds = [], className, ...btnProps}) => {
  const {t} = useI18n()
  const {classes: c} = useStyles()

  const {enabledSubscription} = useExp3('restapp_tools')

  const {places} = useOldHeavyPlaces({
    enabled: enabledSubscription
  })
  const placesIds = places.map(({id}) => id)
  const {dataMap, isLoading} = useTelegramList(placesIds, {enabled: enabledSubscription && Boolean(placesIds.length)})

  if (!dataMap && isLoading) {
    return <Skeleton height={56} className={className} />
  }

  if (!dataMap) {
    return null
  }

  // Получаем фильтрованные placeId
  const selectedPlaceIds = filterPlaceIds.length ? filterPlaceIds : Array.from(dataMap.keys())
  // Извлекаем логины из dataMap по фильтру -> TelegramListItem[]
  const filteredLoginsItems = compact(selectedPlaceIds.map((id) => dataMap.get(id)))
  // string[][] -> string[]
  const loginsArr = uniq(flatMap(filteredLoginsItems, ({logins}) => logins.map(({login}) => login)))
  // string[] -> '@string, @string ... @string'
  const loginsString = loginsArr
    .map((v) => `@${v}`)
    .join(', ')
    .trim()

  const hasSomeTgLogins = Boolean(loginsArr.length)

  return (
    <button {...btnProps} className={cn(className, c.root, {[c.error]: !hasSomeTgLogins})}>
      <span>
        <Telegram />
      </span>
      <span className={c.content}>
        <span>{t('shared-places.telegram-button.nastroit-telegram', 'Настроить Telegram')}</span>
        <span className={c.sub}>
          <LineClamp lineClamp={1}>
            {hasSomeTgLogins ? loginsString : t('shared-places.telegram-button.dobavte-akkaunt', 'Добавьте аккаунт')}
          </LineClamp>
        </span>
      </span>
      <span>
        <ChevronRightMIcon />
      </span>
    </button>
  )
}
