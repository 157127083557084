import {useI18n} from '@eda-restapp/i18n'
import type {TypographyProps} from '@eda-restapp/ui'
import {Typography} from '@eda-restapp/ui'
import type {FC} from 'react'
import React from 'react'

import {TooltipIcon} from '../TooltipIcon'

import styles from './ContractRestaurantHint.module.css'

type ContractRestaurantHintProps = {
  tooltipText?: string
} & Partial<TypographyProps>

const ContractRestaurantHint: FC<ContractRestaurantHintProps> = ({tooltipText, ...props}) => {
  const {tRaw} = useI18n()

  return (
    <Typography mt='s' variant='caption1' alignItems='center' className={styles.contractInfo} {...props}>
      {tRaw(
        'shared-places.contract-restaurant-hint.contract-place-info',
        'Для этого ресторана подготовим дополнительное соглашение {icon}',
        {
          icon: <TooltipIcon tooltipText={tooltipText} />
        }
      )}
    </Typography>
  )
}

export default ContractRestaurantHint
