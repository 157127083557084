import {useEffect, useState} from 'react'

import {getClosePeriod} from './useCounter.utils'

export const useCounter = (
  /**
   * timestamp date to count for
   */
  countUntil: number
) => {
  const [savedCountUntil, setSavedCountUntil] = useState(countUntil)
  const [timeLeft, setTimeLeft] = useState(countUntil - Date.now())

  useEffect(() => {
    if (countUntil !== savedCountUntil) {
      setSavedCountUntil(countUntil)
      setTimeLeft(countUntil - Date.now())
    }
  }, [savedCountUntil, countUntil])

  useEffect(() => {
    const diff = savedCountUntil - Date.now()

    if (diff > 0) {
      let timeout = setTimeout(function counterCheck() {
        const diff = savedCountUntil - Date.now()
        setTimeLeft(diff)
        if (diff > 0) {
          timeout = setTimeout(counterCheck, getClosePeriod(diff))
        }
      }, getClosePeriod(diff))

      return () => clearTimeout(timeout)
    }
  }, [savedCountUntil])

  return timeLeft
}
