import React, {lazy} from 'react'
import {Route, Routes} from 'react-router-dom'
import {Suspense} from '@restapp/shared-boundary'

const AuthContainer = lazy(() =>
  import(/* webpackChunkName: "@restapp/core-auth" */ '@restapp/core-auth').then((module) => {
    return {default: module.AuthContainer}
  })
)
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "@restapp/core-legacy" */ '../../pages/Auth/ForgotPassword')
)
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "@restapp/core-legacy" */ '../../pages/Auth/ResetPassword')
)

export const UnauthorizedRouting: React.FC = () => (
  <>
    <Routes>
      <Route
        path='/forgot-password'
        element={
          <Suspense>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path='/password-reset'
        element={
          <Suspense>
            <ResetPassword />
          </Suspense>
        }
      />
    </Routes>
    <Suspense>
      <AuthContainer />
    </Suspense>
  </>
)
