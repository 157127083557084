import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: 'max-content',
    padding: '3px 10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: 21,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    whiteSpace: 'nowrap'
  },
  icon: {
    fontSize: '16px',
    alignSelf: 'center',
    flexBasis: '16px'
  },
  mobileIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2px !important'
  },
  tooltip: {
    textAlign: 'left'
  }
})

export default useStyles
