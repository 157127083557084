import type {ICommunicationWidget} from '@restapp/shared-api'

import type {Communication, CommunicationStatus} from './communications'
import {CommunicationType} from './communications'

export type NotificationType = {
  type: CommunicationType.NOTIFICATION
  id: string
  created: string
  status: CommunicationStatus
  info: {
    important: boolean
    priority: number
    show_as_fullscreen: boolean
  }
  preview: {
    title: string
  }
  content: {
    text: string
    media_id: string
  }
  widget?: ICommunicationWidget
}

export function isNotification(communication: Communication): communication is NotificationType {
  return communication.type === CommunicationType.NOTIFICATION
}
