import {errorLogger} from '@eda-restapp/logger'
import React from 'react'
import {useDispatch} from 'react-redux'

import {setSurveyAnswer} from '../../../slice'
import {isPredefinedAnswer, isRatingAnswer, isTextAnswer, type SetAnswer, type SurveyAnswerUnion} from '../../../types'

import PredefinedAnswer from './SurveyAnswers/PredefinedAnswer'
import RatingAnswer from './SurveyAnswers/RatingAnswer'
import {TextAnswer} from './SurveyAnswers/TextAnswer'

interface SurveyAnswerProps {
  answer: SurveyAnswerUnion
}

type InnerProps = SurveyAnswerProps

function SurveyAnswer({answer}: InnerProps) {
  const dispatch = useDispatch()
  const handleSetAnswer = (answer: SetAnswer) => {
    dispatch(setSurveyAnswer(answer))
  }

  if (isPredefinedAnswer(answer)) {
    return <PredefinedAnswer answer={answer} setAnswer={handleSetAnswer} />
  }

  if (isRatingAnswer(answer)) {
    return <RatingAnswer answer={answer} setAnswer={handleSetAnswer} />
  }

  if (isTextAnswer(answer)) {
    return <TextAnswer answer={answer} setAnswer={handleSetAnswer} />
  }

  errorLogger({
    message: 'communications:survey:unsupported_answer_type',
    error: new Error('communications:survey:unsupported_answer_type'),
    level: 'error',
    additional: {answer}
  })
  return null
}

export default SurveyAnswer
