import {useExp3} from '@eda-restapp/configs'
import {groupBy} from 'lodash-es'
import {useQuery} from 'react-query'

import {CampaignStatus, type Campaign, CampaignType} from '../../types/campaign'
import type {GroupType} from '../../types/group'
import useCampaignsApi from '../useCampaignsApi'
import useCPACampaignsApi from '../useCPACampaignsApi'

import {mergeCampaigns, getUUID, getGroupKeyByServicesAndPlaces} from './utils'

const useCampaignGroups = () => {
  const {isCpaEnabled} = useExp3('restapp_promotion')
  const {
    campaigns: cpcCampaigns,
    isLoading: isLoadingCpcCampaigns,
    dataUpdatedAt: dataCpcUpdatedAt,
    isFetched: isFetchedCpcCampaigns
  } = useCampaignsApi()

  const {
    campaigns: cpaCampaigns,
    isLoading: isLoadingCpaCampaigns,
    dataUpdatedAt: dataCpaUpdatedAt,
    isFetched: isFetchedCpaCampaigns
  } = useCPACampaignsApi({enabled: isCpaEnabled})

  const {
    data: [groupsHash, groups] = [],
    isLoading,
    isFetched
  } = useQuery(
    ['groupedCampaigns', dataCpcUpdatedAt, dataCpaUpdatedAt],
    () => {
      const allCampaigns = cpcCampaigns || cpaCampaigns ? [...(cpcCampaigns || []), ...(cpaCampaigns || [])] : undefined
      const groups = groupBy<Campaign>(isCpaEnabled ? allCampaigns : cpcCampaigns, getUUID)
      const groupsEntries = Object.entries(groups)

      // Список UUID групп рекламных кампаний разного типа по связи сервисы-рестораны
      const mergedGroupsHash: Record<string, Record<CampaignType, string>> = {}

      // Мержим кампании в группы, отфильтровывая группы без кампаний и уникального ключа
      // Дополнительно собираем список групп рекламных кампаний разного типа по связи сервисы-рестораны
      const mergedGroups = groupsEntries?.reduce<Array<[string, GroupType]>>((acc, [campaignUUID, campaigns]) => {
        // Фильтруем группы без уникального ключа или без кампаний
        if (!campaignUUID || !campaigns || !campaigns.length) {
          return acc
        }

        // Сортируем кампании внутри группы по ресторанам, чтобы порядок ресторанов совпадал
        // в схожих группах рекламных кампаний разных типов
        const sortedCampaigns = campaigns.sort((a, b) => a.place_id - b.place_id)
        const mergedCampaigns = mergeCampaigns(sortedCampaigns)

        // Фильтруем группы, если смерженных кампаний нет
        if (!mergedCampaigns) {
          return acc
        }

        acc.push([campaignUUID, mergedCampaigns] as [string, GroupType])

        // Собираем ключ группы по связке сервисы-рестораны
        const key = getGroupKeyByServicesAndPlaces(mergedCampaigns)

        // Добавляем ключ в список, если его еще нет
        if (!mergedGroupsHash[key]) {
          mergedGroupsHash[key] = {} as Record<CampaignType, string>
        }

        // Добавляем UUID группы в список ключей для каждого типа рекламной кампании
        mergedGroupsHash[key][mergedCampaigns.campaignType] = campaignUUID

        return acc
      }, [])

      const groupsHash = Object.fromEntries<GroupType>(mergedGroups)

      const groupsList = Object.keys(mergedGroupsHash)
        // Сортируем по совокупности статусов связки групп разных типов рекламный кампаний
        // по сервисам и ресторанам
        .sort((a, b) => {
          // Получаем UUID групп из обеих связок
          const aCpcUUID = mergedGroupsHash[a].cpc
          const aCpaUUID = mergedGroupsHash[a].cpa
          const bCpcUUID = mergedGroupsHash[b].cpc
          const bCpaUUID = mergedGroupsHash[b].cpa

          // Устанавливаем изначальный вес связок групп
          let aWeight = 0,
            bWeight = 0

          // Добавляем вес каждой связке групп в зависимости от групповых статусов рекламных кампаний
          aWeight += groupsHash[aCpcUUID]?.groupStatus === CampaignStatus.ACTIVE ? 3 : 0
          aWeight += groupsHash[aCpcUUID]?.groupStatus === CampaignStatus.SUSPENDED ? 1 : 0
          aWeight += groupsHash[aCpaUUID]?.groupStatus === CampaignStatus.ACTIVE ? 3 : 0
          aWeight += groupsHash[aCpaUUID]?.groupStatus === CampaignStatus.PAUSED ? 1 : 0

          bWeight += groupsHash[bCpcUUID]?.groupStatus === CampaignStatus.ACTIVE ? 3 : 0
          bWeight += groupsHash[bCpcUUID]?.groupStatus === CampaignStatus.SUSPENDED ? 1 : 0
          bWeight += groupsHash[bCpaUUID]?.groupStatus === CampaignStatus.ACTIVE ? 3 : 0
          bWeight += groupsHash[bCpaUUID]?.groupStatus === CampaignStatus.PAUSED ? 1 : 0

          // Чем выше вес – тем выше поднимаем связку групп в сортировке
          return bWeight - aWeight
        })
        // Собираем все группы в сортированном по весу порядке, добавляя признак связки
        .reduce<GroupType[]>((acc, groupKeyByServicesAndPlaces) => {
          const isLinkedCpaToCpc =
            !!mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpc] &&
            !!mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpa]

          if (mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpc]) {
            const groupsHashKey = mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpc]
            const groupToPush = groupsHash[groupsHashKey]
            groupToPush.linkedCPA = isLinkedCpaToCpc

            acc.push(groupToPush)
          }

          if (mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpa]) {
            const groupsHashKey = mergedGroupsHash[groupKeyByServicesAndPlaces][CampaignType.cpa]
            const groupToPush = groupsHash[groupsHashKey]
            groupToPush.linkedCPA = isLinkedCpaToCpc

            acc.push(groupToPush)
          }

          return acc
        }, [])

      return [groupsHash, groupsList] as const
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  )

  return {
    groups,
    groupsHash,
    isLoading: isLoading || isLoadingCpcCampaigns || (isCpaEnabled ? isLoadingCpaCampaigns : false),
    isFetched: isFetched && isFetchedCpcCampaigns && (isCpaEnabled ? isFetchedCpaCampaigns : true)
  }
}

export default useCampaignGroups
