import {eventLogger, metrika} from '@eda-restapp/logger'
import {RestaurantSelect} from '@eda-restapp/ui'
import React, {useCallback, useMemo} from 'react'

import {DeliveryIcon} from '@restapp/shared-ui'

import {usePlaces} from '../../hooks'

export interface MultiplePlacesDropdownProps {
  className?: string
  slug?: string
  getOptionDisabled?(type: 'native' | 'marketplace'): boolean
}

const MultiplePlacesDropdown: React.FC<MultiplePlacesDropdownProps> = ({className, slug, getOptionDisabled}) => {
  const {places, selectedPlaceIds, setSelectedPlaceIds} = usePlaces()

  const onChange = useCallback(
    (placeIds: number[]): void => {
      setSelectedPlaceIds(placeIds)
      if (slug) {
        eventLogger({
          name: 'select_restaurant',
          value: slug,
          additional: {
            value: JSON.stringify(placeIds)
          }
        })
        metrika({
          target: 'select_restaurant',
          params: {
            slug,
            placeIds: JSON.stringify(placeIds)
          }
        })
      }
    },
    [setSelectedPlaceIds, slug]
  )

  const options = useMemo(
    () =>
      places.map((place) => {
        return {
          id: place.id,
          title: place.name,
          address: place.address,
          icon: <DeliveryIcon place={place} fixZIndex />,
          disabled: getOptionDisabled?.(place.type) || false,
          annotation: '',
          hasAlert: false
        }
      }),
    [getOptionDisabled, places]
  )

  if (options.length <= 1) {
    return null
  }

  return (
    <RestaurantSelect multiple options={options} values={selectedPlaceIds} onChange={onChange} className={className} />
  )
}

export default MultiplePlacesDropdown
