import {Snackbar} from '@eda-restapp/snackbars'

import {useApiMutation} from '@restapp/core-api'

import {ERROR_PROMOTION, PROMOTION_SUCCESS} from '../constants'
import {CampaignType} from '../types/campaign'
import type {GroupType} from '../types/group'

import useCampaignsApi from './useCampaignsApi'

type Props = {
  directToken: string | null | undefined
  onSettled?: () => void
  campaign: GroupType
}

const useCampaignSuspend = ({campaign, directToken, onSettled}: Props) => {
  const {invalidate} = useCampaignsApi()

  const {isLoading, mutate} = useApiMutation(
    {
      url:
        campaign.campaignType === CampaignType.cpc
          ? '/4.0/restapp-front/marketing/v1/ad/suspend'
          : '/4.0/restapp-front/marketing/v1/ad/pause',
      method: 'POST'
    },
    {
      onSettled,
      onSuccess: () => {
        invalidate()

        Snackbar.success({
          text: PROMOTION_SUCCESS.suspend
        })
      },
      onError: () => {
        Snackbar.error({
          text: ERROR_PROMOTION.SUSPEND_PROMOTION
        })
      }
    }
  )

  const mutateCPC = () =>
    campaign.campaignType === CampaignType.cpc && campaign.advertId && campaign.campaignId && directToken
      ? mutate({
          body: {
            advert_id: campaign.advertId,
            campaign_id: campaign.campaignId
          },
          headers: {
            Authorization: directToken
          }
        })
      : undefined

  const mutateCPM = () =>
    campaign.campaignType === CampaignType.cpm && campaign.cpmAdvertId !== undefined && directToken
      ? mutate({
          body: {
            campaigns: [
              {
                campaign_id: campaign.cpmAdvertId,
                campaign_type: campaign.campaignType
              }
            ]
          },
          headers: {
            Authorization: directToken
          }
        })
      : undefined

  return {
    isLoading,
    handleSuspendCampaign: campaign.campaignType === CampaignType.cpc ? mutateCPC : mutateCPM
  }
}

export default useCampaignSuspend
