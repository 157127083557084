import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {CrossMIcon, StarOfBethlehem, Button} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useRef, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {usePlacesApi} from '@restapp/core-places'
import type {TariffInfo, TariffType} from '@restapp/shared-api'
import {useFeaturesByFeatures} from '@restapp/shared-places'
import {nbsp} from '@restapp/shared-ui'

import {useSubscriptionTariffCard} from '../../hooks'
import {getTariffCost} from '../../utils'
import TariffBadge from '../TariffBadge'

import {useSubscriptionTariffCardStyles} from './SubscriptionTariffCard.styles'

export interface SubscriptionTariffCardProps {
  tariffInfo: TariffInfo
  isActive: boolean
  trialTariffInfoType?: TariffType
  /** Какой формат карточки, маленький или обычный */
  isSmallFormat?: boolean
  isRecommended?: boolean
  className?: string
  scrollSelf?: boolean
  hasDiscount?: boolean
  disabled?: boolean
  onClick: () => void
  hasPaidNextTariff?: boolean
}

export const SubscriptionTariffCard: React.FC<SubscriptionTariffCardProps> = ({
  tariffInfo,
  isActive,
  isSmallFormat,
  isRecommended,
  onClick,
  className,
  scrollSelf,
  hasDiscount,
  trialTariffInfoType,
  disabled,
  hasPaidNextTariff
}) => {
  const {t, lang} = useI18n()
  const {classes: c} = useSubscriptionTariffCardStyles({isSmallFormat})
  const location = useLocation()
  const handleClick = () => {
    metrika({
      target: 'subscription_tariff_card_button_click',
      params: {
        tariffInfo,
        hasDiscount,
        hasPaidNextTariff,
        isRecommended,
        path: location.pathname
      }
    })
    onClick()
  }

  const restappTools = useExp3('restapp_tools')
  const cardConfig = restappTools.subscriptionPage.tariffCards.find((card) => card.type === tariffInfo.type)
  const hiddenFeaturesList = cardConfig?.hiddenFeatures || []

  const {tariffPriceText, descriptionCostText, description, featuresMap} = useSubscriptionTariffCard(
    tariffInfo,
    c,
    hasDiscount,
    trialTariffInfoType
  )
  const placesQuery = usePlacesApi()
  const featuresByFeaturesQuery = useFeaturesByFeatures(placesQuery.placesIds ?? [])

  const isNewYagpt = !!restappTools.features?.find((feature) => feature.slug === 'yagpt')?.isNew
  const hasYagpt = !!featuresByFeaturesQuery.data?.some((feature) => feature.slug === 'yagpt')

  const isPaidTariff = tariffInfo.type !== 'free'
  const showSmallText = (isPaidTariff || Boolean(isSmallFormat)) && Boolean(descriptionCostText)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && scrollSelf) {
      ref.current.scrollIntoView({
        block: 'center',
        inline: 'center'
      })
    }
  }, [scrollSelf])

  const isHiddenFeature = (feature: string) => {
    if (feature === 'personal_manager' && restappTools.isHideBusinessPlus) {
      return true
    }

    if (feature === 'menu_gpt_generation' && !hasYagpt) {
      return true
    }

    if (!featuresMap[feature]) {
      return true
    }

    if (hiddenFeaturesList.includes(feature)) {
      return true
    }

    return false
  }

  const availableFeatures = restappTools.sortedFeaturesTariffCards.filter(
    (feature) => !!tariffInfo.features.includes(feature) && !isHiddenFeature(feature)
  )
  const unavailableFeatures = restappTools.sortedFeaturesTariffCards.filter(
    (feature) => !!cardConfig?.unavailableFeatures.includes(feature) && !isHiddenFeature(feature)
  )

  if (!cardConfig || (restappTools.isHideBusinessPlus && tariffInfo.type === 'business_plus')) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cn(c.root, className, {
        [c.active]: isActive,
        [c.recommended]: isRecommended,
        [c.fewCards]: restappTools.isHideBusinessPlus
      })}
      data-testid={`block-tariff-${tariffInfo.type}` /* Подписка | Блок тарифа */}
    >
      <div className={c.recommendedBlock}>
        <div className={c.recommendedLabel}>
          {t('shared-places.subscription-tariff-card.recommended-badge', 'рекомендуем')}
        </div>
      </div>
      <div className={c.wrap}>
        <div className={cn(c.inner, c[tariffInfo.type])} style={{background: cardConfig.background}}>
          <TariffBadge className={c.badge} tariffType={tariffInfo.type} isPaidNextTariff={!!hasPaidNextTariff} />

          {hasDiscount && isPaidTariff && (
            <div className={c.discountBadge}>
              {t(
                'shared-places.subscription-tariff-card.discount-badge',
                'Бейджик со словом акция (должно быть такое же короткое)',
                {value: 'акция'}
              )}
            </div>
          )}

          {!isSmallFormat && <div className={c.description}>{description}</div>}

          <div className={c.price}>
            {tariffPriceText}
            {showSmallText && <span className={c.priceStar}>{'\u002A'}</span>}
          </div>
          {hasDiscount && (
            <div className={c.oldPriceWrap}>
              {isPaidTariff && (
                <div className={c.oldPrice}>
                  {getTariffCost(tariffInfo, lang, false, tariffInfo.type === trialTariffInfoType).arrayOfCost.join(
                    ' + '
                  )}
                </div>
              )}
            </div>
          )}

          <Button.Text
            data-testid={`button-tariff-${tariffInfo.type}` /* Подписка | Кнопка в блоке тарифа */}
            shadow={!disabled && !isActive}
            size='m'
            variant={!disabled && !isActive ? 'light' : 'outline'}
            disabled={disabled || isActive}
            onClick={handleClick}
          >
            {isActive
              ? t('shared-places.subscription-tariff-card.btn-text-active', 'Активна')
              : t('shared-places.subscription-tariff-card.btn-text-activate', 'Выбрать')}
          </Button.Text>

          <div className={c.featuresList}>
            {isPaidTariff &&
              availableFeatures.map((feature) => {
                return (
                  <div className={cn(c.feature)} key={feature}>
                    <StarOfBethlehem fontSize={'16'} />
                    <div>
                      <span className={cn(c.featureText, {[c.highlightedFeature]: feature === 'personal_manager'})}>
                        {featuresMap[feature]}
                        {!isSmallFormat && feature === 'personal_manager' && (
                          <span className={c.featureBadgeWrap}>
                            {nbsp}
                            <span className={cn(c.featureBadge, c.highlightedBadge)}>
                              {t('shared-places.subscription-tariff-card.top', 'Топ')}
                            </span>
                          </span>
                        )}
                        {feature === 'menu_gpt_generation' && isNewYagpt && (
                          <span className={c.featureBadgeWrap}>
                            {nbsp}
                            <span className={cn(c.featureBadge, c.newBadge)}>
                              {t('shared-places.subscription-tariff-card.new-badge', 'New')}
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            {unavailableFeatures.map((feature) => {
              return (
                <div className={cn(c.feature, c.unavailableFeature)} key={feature}>
                  <CrossMIcon fontSize={'16'} />
                  <div>
                    <span>{featuresMap[feature]}</span>
                  </div>
                </div>
              )
            })}
          </div>
          {showSmallText && (
            <div className={c.smallText}>
              {'\u002A'}
              {descriptionCostText}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
