import {Box, MoreIcon, MenuDropdown} from '@eda-restapp/ui'
import React, {type FC, type ReactNode, useState} from 'react'

import styles from './ItemAction.module.css'

type ItemActionProps = {
  actions: {
    id: string
    title: string
    prepend?: ReactNode
    disabled?: boolean
    dataTestid?: string
    showTooltip?: boolean
    tooltip?: string
    onClick?: () => void
  }[]
}

export const ItemAction: FC<ItemActionProps> = ({actions}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Box className={styles.actions} alignItems='flex-start' justifyContent='space-between'>
      <MenuDropdown
        items={actions}
        triggerElement={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        placement='bottom-end'
      >
        <div className={styles.buttonWrapper} ref={setAnchorEl}>
          <MoreIcon
            aria-label='MoreIcon'
            onClick={() => setOpen(true)}
            data-testid={'menu-item-action'} //Меню | Три точки для редактирования
          />
        </div>
      </MenuDropdown>
    </Box>
  )
}
