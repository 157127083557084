import {t} from '@eda-restapp/i18n'

export const PROMOTION_SUCCESS = {
  get enable() {
    return t('promotion.success.prodvizhenie-zapuskaetsya', 'Продвижение запускается')
  },
  get suspend() {
    return t('promotion.success.prodvizhenie-priostanovleno', 'Продвижение приостановлено')
  }
}
