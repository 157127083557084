import {useI18n} from '@eda-restapp/i18n'
import {Box} from '@eda-restapp/ui'
import moment from 'moment'
import React from 'react'

import {usePlaces} from '@restapp/core-places'
import {Dialog} from '@restapp/shared-modals'
import {RestaurantListItem} from '@restapp/shared-ui'

import type {SubscriptionOnSave} from '../../../types'
import {TelegramButton} from '../../TelegramButton'

import useSubscriptionSuccessDialogStyles from './SubscriptionSuccessDialog.styles'
import {useSubscriptionSuccessDialog} from './useSubscriptionSuccessDialog'

export interface SubscriptionSuccessDialogProps {
  selectedTariffType: SubscriptionOnSave | null
  disablePortal?: boolean
  onEditTg: () => void
  onClose: () => void
  placesIds: number[]
}

export const SubscriptionSuccessDialog: React.FC<SubscriptionSuccessDialogProps> = ({
  selectedTariffType,
  disablePortal,
  onEditTg,
  onClose,
  placesIds
}) => {
  const {t} = useI18n()
  const {getPlaceById} = usePlaces()
  const {classes: c} = useSubscriptionSuccessDialogStyles()

  const {currentSuccessModal, distinctTelegramButton} = useSubscriptionSuccessDialog(selectedTariffType, onClose)

  if (!selectedTariffType) {
    return null
  }

  const place = selectedTariffType.fromTrialTariff
    ? getPlaceById(selectedTariffType.fromTrialTariff?.placeId)
    : undefined

  const fromBusinessTrial = selectedTariffType?.fromTrialTariff?.type === 'business_plus'

  const renderDescription = () => {
    if (fromBusinessTrial) {
      const daysBeforeEndBusinessTrial = selectedTariffType?.fromTrialTariff
        ? moment.duration(moment(selectedTariffType?.fromTrialTariff.validUntilIso).diff(moment())).asDays().toFixed(0)
        : '0'

      return (
        <>
          {daysBeforeEndBusinessTrial !== '0' ? (
            <p>
              {t(
                'shared-places.subscription-success-dialog.business-plus-to-free',
                'Вот сколько дней ещё можно пользоваться преимуществами подписки: {days}',
                {
                  days: daysBeforeEndBusinessTrial
                }
              )}
            </p>
          ) : null}

          {!!place && (
            <Box mt='m'>
              <RestaurantListItem place={place} />
            </Box>
          )}
        </>
      )
    }

    return (
      <>
        {currentSuccessModal.text}
        {distinctTelegramButton && (
          <p>
            {t(
              'shared-places.subscription-success-dialog.description-tg',
              'Чтобы настроить бот для отчётов, ниже укажите ваш аккаунт в Telegram.'
            )}
          </p>
        )}
      </>
    )
  }

  return (
    <Dialog
      outsideClose={distinctTelegramButton}
      visible
      progress={false}
      disablePortal={disablePortal}
      title={currentSuccessModal.headerTitle}
      cancelText={currentSuccessModal.btnText}
      slug={'subscription_success_modal'}
      onConfirm={onEditTg}
      onClose={distinctTelegramButton ? onClose : onEditTg}
    >
      <div className={c.textBlock}>{renderDescription()}</div>
      {distinctTelegramButton && !fromBusinessTrial && (
        <TelegramButton className={c.telegramButton} onClick={onEditTg} filterPlaceIds={placesIds} />
      )}
    </Dialog>
  )
}
