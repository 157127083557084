import {useI18n} from '@eda-restapp/i18n'
import {Modal} from '@eda-restapp/ui'
import React, {type FC} from 'react'
import type {InfiniteQueryObserverBaseResult} from 'react-query'

import type {FullRestaurant, IApiError} from '@restapp/shared-api'
import type {SearchQueryResponse} from '@restapp/shared-api/types/search'

import {PlaceStatusListContent} from './components/PlaceStatusListContent/PlaceStatusListContent'

type PlaceStatusListProps = {
  canLoadMorePlaces: boolean
  isFetchingNextPlaceQueryPage: boolean
  isOpen: boolean
  places: FullRestaurant[]
  heavyPlacesQueryLoadMore: InfiniteQueryObserverBaseResult<SearchQueryResponse, IApiError>['fetchNextPage']
  onRefetch: () => Promise<void>
  onBusyModeButtonClick: (placeId: number) => void
  setIsOpen: (status: boolean) => void
}

export const PlaceStatusList: FC<PlaceStatusListProps> = ({
  canLoadMorePlaces,
  isFetchingNextPlaceQueryPage,
  isOpen,
  places,
  heavyPlacesQueryLoadMore,
  onRefetch,
  onBusyModeButtonClick,
  setIsOpen
}) => {
  const {t} = useI18n()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header title={t('core-legacy.place-status-modal.restorani', 'Рестораны')} />
      <Modal.Content>
        <PlaceStatusListContent
          canLoadMorePlaces={canLoadMorePlaces}
          isFetchingNextPlaceQueryPage={isFetchingNextPlaceQueryPage}
          places={places}
          heavyPlacesQueryLoadMore={heavyPlacesQueryLoadMore}
          onRefetch={onRefetch}
          onBusyModeButtonClick={onBusyModeButtonClick}
        />
      </Modal.Content>
    </Modal>
  )
}
