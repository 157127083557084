import {useI18n} from '@eda-restapp/i18n'
import {ChevronDownLIcon, LightningIcon} from '@eda-restapp/ui'
import React, {useCallback, useState} from 'react'

import {Alert} from '@restapp/shared-ui/Alert'

import type {NotificationType} from '../../types'

import Notification from './Notification'
import styles from './Notifications.module.css'

interface NotificationProps {
  notifications: NotificationType[]

  onMarkRead(id: string): void
}

function Notifications({notifications, onMarkRead}: NotificationProps) {
  const {t} = useI18n()
  const [collapsed, setCollapsed] = useState(notifications.length > 4)

  const items = collapsed ? notifications.slice(0, 4) : notifications

  const expand = useCallback(() => setCollapsed(false), [])

  return (
    <div data-testid={'notifications-wrapper' /* Нотификации | Блок уведомлений на экране "Новости" */}>
      {notifications.length > 0 ? (
        <div
          className={styles.notificationsList}
          data-testid={'notifications-list' /* Нотификации | Блок с уведомлениями на экране "Новости" */}
        >
          {items.map((notification) => (
            <Notification
              key={notification.id}
              className={styles.notification}
              notification={notification}
              onMarkRead={onMarkRead}
            />
          ))}
        </div>
      ) : (
        <Alert
          className={styles.noNotificationsAlert}
          startIcon={<LightningIcon color='#EDF0F1' />}
          data-testid={
            'notifications-list-there-are-no-notifications' /* Нотификации | Плашка об отсутствии уведомлений на экране "Новости" */
          }
          text={t('main-communications.notifications.uvedomlenii-poka-net', 'Уведомлений пока нет')}
        />
      )}
      {collapsed && (
        <div className={styles.expand} onClick={expand}>
          <span className={styles.showAll}>{t('main-communications.notifications.pokazat-vse', 'Показать все')}</span>
          <ChevronDownLIcon />
        </div>
      )}
    </div>
  )
}

export default Notifications
