import {createSlice} from '@reduxjs/toolkit'
import {omit} from 'lodash-es'

import {
  createItem,
  enableAll,
  removeCategory,
  removeItem,
  resetAll,
  setNormalizeItems,
  toggleItem,
  upsertItem,
  applyCurrentState
} from '../actions'
import {ITEMS_SLICE_NAME} from '../constants'
import {itemEntityAdapter, itemEntitySelectors} from '../entities'
import type {Item, ItemsSliceState} from '../types'

export const slice = createSlice({
  name: ITEMS_SLICE_NAME,
  initialState: (): ItemsSliceState => {
    return {
      current: itemEntityAdapter.getInitialState(),
      initial: itemEntityAdapter.getInitialState()
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNormalizeItems, (state, action) => {
      itemEntityAdapter.removeAll(state.current)
      itemEntityAdapter.removeAll(state.initial)

      const result: Item[] = []

      for (const item of action.payload) {
        result.push({
          ...omit(item, 'modifierGroups'),
          reactivatedAt: item.reactivatedAt ?? null
        })
      }

      itemEntityAdapter.setAll(state.current, result)
      itemEntityAdapter.setAll(state.initial, result)
    })

    builder.addCase(createItem, (state, action) => {
      const entity = {
        ...omit(action.payload, 'modifierGroups'),
        reactivatedAt: action.payload.reactivatedAt ?? null,
        _categoryId: action.payload.categoryId
      }

      itemEntityAdapter.setOne(state.current, entity)
    })

    builder.addCase(upsertItem, (state, action) => {
      const entity = {
        ...omit(action.payload, 'modifierGroups'),
        reactivatedAt: action.payload.reactivatedAt ?? null,
        _categoryId: action.payload.categoryId
      }

      itemEntityAdapter.upsertOne(state.current, entity)
    })

    builder.addCase(removeCategory, (state, action) => {
      const itemsInCategory = itemEntitySelectors
        .selectAll(state.current)
        .filter((item) => item.categoryId === action.payload)
        .map((item) => item.id)

      itemEntityAdapter.removeMany(state.current, itemsInCategory)
    })

    builder.addCase(removeItem, (state, action) => {
      itemEntityAdapter.removeOne(state.current, action.payload)
    })

    builder.addCase(resetAll, (state) => {
      state.current = state.initial
    })

    builder.addCase(applyCurrentState, (state) => {
      state.initial = state.current
    })

    builder.addCase(toggleItem, (state, action) => {
      itemEntityAdapter.updateOne(state.current, {
        id: action.payload.id,
        changes: {
          available: action.payload.value,
          reactivatedAt: action.payload.reactivatedAd
        }
      })
    })

    builder.addCase(enableAll, (state) => {
      for (const item of Object.values(state.current.entities)) {
        if (item) {
          item.available = true
          item.reactivatedAt = null
        }
      }
    })
  }
})
