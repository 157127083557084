type ParsedOauthRedirectData = {
  token: string | null
  state: string | null
}

export function parseOauthRedirectHash(oauthHash: string): ParsedOauthRedirectData {
  const queryLikeString = oauthHash.substring(1)
  const searchParams = new URLSearchParams(queryLikeString)

  const token = searchParams.get('access_token')
  const state = searchParams.get('state')

  return {token, state}
}
