import type {FormikValues} from 'formik'
import {Formik} from 'formik'
import React from 'react'

import type {FormConfig} from '../../types'

import {getInitialTouched} from './utils'

export default function Form<TValues extends FormikValues>({
  initialErrors,
  initialTouched,
  children,
  ...props
}: FormConfig<TValues>) {
  return (
    <Formik<TValues>
      initialErrors={initialErrors}
      initialTouched={initialTouched ?? getInitialTouched(initialErrors)}
      {...props}
    >
      {children}
    </Formik>
  )
}
