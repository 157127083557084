import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

type MutationBody = VendorApi['/4.0/restapp-front/promo/v1/plus/cashback/disable']['POST']['body']['disable']

export const useYaPlusDisable = () => {
  const {mutate, isLoading, isError} = useApiMutation(
    {
      url: '/4.0/restapp-front/promo/v1/plus/cashback/disable',
      method: 'POST'
    },
    {
      affectedQueryKeys: [['/4.0/restapp-front/promo/v1/plus/status', 'POST' as never]]
    }
  )

  return {
    isLoading,
    isError,
    disablePlus: (data: MutationBody, onSuccess?: () => void) =>
      mutate(
        {
          body: {
            disable: data
          }
        },
        {
          onSuccess
        }
      )
  }
}
