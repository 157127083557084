import React from 'react'
import useStyles from './PhoneNumber.style'
import OutsideLink from '../OutsideLink'
import cn from 'classnames'

export type Props = {
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  title?: string
  textValue?: string
  slug?: string
  value: string
  onClick?: () => void
}

const PhoneNumber: React.FC<Props> = (props) => {
  const {className, classes, textValue, title, value, slug = 'phone_link', onClick} = props
  const {classes: c} = useStyles(undefined, {props: {classes}})

  return (
    <div data-testid={'phone-number' /*Номер телефона */} className={cn(className, c.root)}>
      {!!title && <span className={c.title}>{title}</span>}
      <OutsideLink className={c.phone} href={`tel:${value}`} slug={slug} onClick={() => onClick?.()}>
        {textValue || value}
      </OutsideLink>
    </div>
  )
}

export default PhoneNumber
