import {useExp3} from '@eda-restapp/configs'

import type {OldFullRestaurant, ShortRestaurant} from '@restapp/shared-api'

/** Хук только для интеграции с DC */
export function useDisabledPlusForDCPlace(place?: OldFullRestaurant | ShortRestaurant) {
  const {enabledForDC} = useExp3('restapp_plus')

  if (!place || enabledForDC) {
    return false
  }

  return place.services?.enabled.length === 1 && place.services?.enabled.includes('dc')
}
