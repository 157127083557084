import type {PopperProps} from '@mui/material/Popper'
import type {TooltipProps} from '@mui/material/Tooltip/Tooltip'
import {QuestionOutlineIcon, InfoIcon} from '@eda-restapp/ui'
import React, {type FC, useState} from 'react'
import {WhiteTooltip} from '../Tooltip'

import useStyles from './Help.style'

type Props = Pick<TooltipProps, 'placement' | 'disableInteractive' | 'leaveDelay'> & {
  tooltip: NonNullable<React.ReactNode>
  PopperProps?: Partial<PopperProps>
  slug?: string
  type?: 'info' | 'help'
  icon?: React.ReactNode
  interactive?: boolean
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  'data-testid'?: String
}

export const Help: FC<Props> = ({
  className,
  classes,
  tooltip,
  PopperProps,
  placement,
  slug,
  type = 'help',
  icon,
  interactive,
  leaveDelay,
  'data-testid': testId = 'help-icon'
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const {classes: c} = useStyles(undefined, {props: {classes}})

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const Icon = type === 'info' ? InfoIcon : QuestionOutlineIcon

  return (
    <WhiteTooltip
      className={className}
      classes={{popper: c.popper, tooltip: c.tooltip}}
      title={tooltip}
      onOpen={handleOpen}
      onClose={handleClose}
      PopperProps={PopperProps}
      placement={placement}
      disableInteractive={!interactive}
      leaveDelay={leaveDelay}
      slug={slug}
    >
      <div data-testid={testId /*Иконка для тултипа с помощью*/} className={c.root}>
        {icon ? icon : <Icon color={isOpen ? '#1F2021' : '#BEC1C4'} className={c.icon} />}
      </div>
    </WhiteTooltip>
  )
}
