import {useMemo} from 'react'
import {useQueryState} from 'react-router-use-location-state'

export function useCourierFeedback() {
  const [orderNr, setOrderNr] = useQueryState('courier-feedback', '')

  return useMemo(() => {
    return {
      open: (orderNr: string) => {
        setOrderNr(orderNr)
      },
      close: () => {
        setOrderNr('')
      },
      isOpen: !!orderNr,
      orderNr: (orderNr ?? undefined) as string | undefined
    }
  }, [orderNr, setOrderNr])
}
