import {RadioGroup, FormControlLabel, FormGroup} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {Checkbox} from '@restapp/shared-ui/Checkbox'
import {Input} from '@restapp/shared-ui/Input/Input'
import Radio from '@restapp/shared-ui/Radio/Radio'

import type {SurveyAnswerPredefined, SetAnswer} from '../../../../types'

const usePredefinedAnswerStyles = makeStyles()({
  label: {
    fontSize: 18
  }
})

interface PredefinedAnswerProps {
  answer: SurveyAnswerPredefined

  setAnswer(answer: SetAnswer): void
}

function PredefinedAnswer({answer, setAnswer}: PredefinedAnswerProps) {
  const {classes} = usePredefinedAnswerStyles()
  const Container = answer.type === 'single' ? RadioGroup : FormGroup
  const Control = answer.type === 'single' ? Radio : Checkbox

  const handleClick = (answerIndex: number) => {
    setAnswer({
      answerIndex,
      questionIndex: answer.questionIndex,
      pageIndex: answer.pageIndex,
      type: answer.type
    })
  }

  const handleSetText = (answerIndex: number, text: string) => {
    setAnswer({
      answerIndex,
      questionIndex: answer.questionIndex,
      pageIndex: answer.pageIndex,
      type: answer.type,
      text
    })
  }
  return (
    <Container>
      {answer.answers.map((internalAnswer, internalAnswerIndex) => (
        <FormControlLabel
          key={internalAnswerIndex}
          classes={{label: classes.label}}
          // if name not passed, mui will generate random one and the tests will crash
          control={
            <Control
              name={`predefined-answer-${internalAnswerIndex}`}
              checked={internalAnswer.selected}
              onClick={() => handleClick(internalAnswerIndex)}
              data-testid={`predefined-answer-${internalAnswerIndex}`} /* Модальное окно опроса | Радиобатоны/Чекбоксы в модалке опросов */
            />
          }
          label={
            internalAnswer.selected && internalAnswer.type === 'other' ? (
              <Input
                autoFocus
                placeholder={internalAnswer.text}
                onChange={(event) => handleSetText(internalAnswerIndex, event.target.value)}
              />
            ) : (
              internalAnswer.text
            )
          }
        />
      ))}
    </Container>
  )
}

export default PredefinedAnswer
