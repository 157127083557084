import {useExp3} from '@eda-restapp/configs'
import {isUndefined} from 'lodash-es'

import {PLACE_STATUS} from '@restapp/main-places/types/status'
import type {FullRestaurant} from '@restapp/shared-api'

export const usePlaceStatus = (place: FullRestaurant): PLACE_STATUS => {
  const placeBlocksConfig = useExp3('restapp_place_blocks')

  if (place.blocks?.[0].reason === 'core_reason_incomplete_onboarding') {
    return PLACE_STATUS.SELF_REG_PENDING
  }

  if (place.blocks?.[0].reason === 'core_reason_completed_onboarding') {
    return PLACE_STATUS.SELF_REG_COMPLETE
  }

  if (place.is_switching_on_requested && place.blocks?.length) {
    return PLACE_STATUS.ACTIVATION_PENDING
  }

  if (place.blocks?.[0].reason && placeBlocksConfig.autostopBlocksTypeList.includes(place.blocks[0].reason)) {
    return PLACE_STATUS.DISABLED_BY_AUTOSTOP
  }

  if (
    place.blocks?.[0].reason &&
    placeBlocksConfig.notAllowedPlaceEnableBlockReasons.includes(place.blocks[0].reason) &&
    !place.is_switching_on_requested
  ) {
    return PLACE_STATUS.DISABLED_BY_YANDEX
  }

  if (!isUndefined(place.can_be_enabled) && !place.can_be_enabled) {
    return PLACE_STATUS.CAN_NOT_BE_ENABLED
  }

  if (place.blocks?.[0].reason && placeBlocksConfig.allowedPlaceEnableBlockReasons.includes(place.blocks[0].reason)) {
    return PLACE_STATUS.DISABLED_BY_VENDOR
  }

  if (place.busy_mode?.enabled && !place.blocks?.length) {
    return PLACE_STATUS.BUSY_MODE_ENABLED
  }

  if (place.is_available) {
    return PLACE_STATUS.ACTIVE
  }

  return PLACE_STATUS.DISABLED
}
