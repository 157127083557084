import {useI18n} from '@eda-restapp/i18n'
import {toString, compact} from 'lodash-es'
import React from 'react'

import {useApiQueryReset} from '@restapp/core-api'
import type {ApiError} from '@restapp/shared-api'
import {Button} from '@restapp/shared-ui'

import useStyles from './SuspenseErrorPage.styles'

type QueryKeyType = Parameters<typeof useApiQueryReset>['0']

type SuspenseErrorPageProps = {
  error?: ApiError
  eventId?: string
  onRetry?: () => void
}

function SuspenseErrorPage({error, onRetry, eventId}: SuspenseErrorPageProps) {
  const {classes: c} = useStyles()
  const {t} = useI18n()

  const errorQueryKey = compact([error?.requestConfig.url, error?.requestConfig.method?.toUpperCase()]) as
    | NonNullable<QueryKeyType>
    | []

  const {reset} = useApiQueryReset(errorQueryKey.length === 2 ? errorQueryKey : undefined)

  return (
    <div className={c.root}>
      <div className={c.title}>{t('shared-boundary.suspense-error-page.title', 'Ошибка')}</div>

      <div className={c.text}>{t('shared-boundary.suspense-error-page.text', 'Извините, что-то пошло не так.')}</div>

      <div className={c.error}>{toString(error?.traceId || error)}</div>

      {!!eventId && <div className={c.error}>{`EventId: ${eventId}`}</div>}

      <Button
        className={c.button}
        variant='contained'
        color='primary'
        onClick={() => {
          reset({
            onSuccess: () => {
              onRetry?.()
            }
          })
        }}
      >
        {t('shared-boundary.suspense-error-page.try-again', 'Попробовать еще раз')}
      </Button>
    </div>
  )
}

export default SuspenseErrorPage
