import React from 'react'

import {useStyles} from './SuggestionCard.style'

export type Props = {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  variant?: 'transparent' | 'filled' | 'compact'
  title: React.ReactNode
  icon?: React.ReactNode
  action?: React.ReactNode
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
}

const SuggestionCard: React.FC<Props> = ({classes, variant, icon, title, children, action, onClick}) => {
  const {classes: c, cx} = useStyles(undefined, {props: {classes}})

  if (variant === 'compact') {
    return (
      <article className={c.wrapper}>
        <section
          className={cx(c.root, c.compact, {
            [c.clickable]: Boolean(onClick)
          })}
          onClick={onClick}
        >
          {!!icon && <div className={c.iconWrapCompact}>{icon}</div>}
          <div className={c.cardMainCompact}>
            <div className={c.textWrap}>
              <h2 className={c.title}>{title}</h2>
              <div className={c.children}>{children}</div>
            </div>
            {!!action && onClick && <div className={c.actionsWrap}>{action}</div>}
          </div>
        </section>
      </article>
    )
  }

  return (
    <article
      className={cx(c.root, {
        [c.transparent]: variant === 'transparent'
      })}
    >
      {!!icon && (
        <div
          className={c.iconWrapDesktop}
          data-testid={'main-percentage-value-icon' /* Главная | Иконка со значением % в рекоммендации*/}
        >
          {icon}
        </div>
      )}
      <div className={c.cardMainDesktop}>
        <div className={c.textWrap}>
          <h2 className={c.title}>{title}</h2>
          <div className={c.children}>{children}</div>
        </div>
        {!!action && <div className={c.actionsWrap}>{action}</div>}
      </div>
      <div className={c.cardMainMobile}>
        <div className={c.titleWrapMobile}>
          {!!icon && <div className={c.iconWrapMobile}>{icon}</div>}
          <h2 className={c.title}>{title}</h2>
        </div>
        <div className={c.children}>{children}</div>
        {!!action && <div className={c.actionsWrap}>{action}</div>}
      </div>
    </article>
  )
}

export default SuggestionCard
