import {makeStyles} from 'tss-react/mui'

import {S} from '../style'

export const ItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 33%',
    minWidth: 0
  },
  icon: {
    marginRight: 8
  },
  iconDisabled: {
    opacity: 0.5
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    lineHeight: '18px',
    color: '#1F2021',
    flex: 1,
    alignItems: 'baseline',
    width: '100%',

    [S.mobileQuery]: {
      margin: 0
    }
  },
  widthLimit: {
    width: 'calc(100% - 32px)'
  },
  textDisabled: {
    color: '#999C9E'
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    textAlign: 'left',
    maxWidth: '100%'
  }
} as const

export const useStyles = makeStyles()(ItemStyles)
