import moment from 'moment'

const ENDLESS_REACTIVATED_AT_VALUE = ' '

export const formatReactivatedAt = (reactivatedAt?: string | null, controlDate?: string | null) => {
  if (reactivatedAt === null) {
    return ENDLESS_REACTIVATED_AT_VALUE
  }

  const isValidDate = (date?: string | null) => date && moment(date).isValid()
  if (!isValidDate(reactivatedAt) || !isValidDate(controlDate)) {
    return reactivatedAt
  }

  const offset = moment.parseZone(controlDate).format('Z')

  return moment(reactivatedAt).utcOffset(offset).format()
}
