import {useCallback} from 'react'
import {useQueryState} from 'react-router-use-location-state'

export function usePassportDrawer() {
  const [isOpen, setIsOpen] = useQueryState('passportDrawer', '')

  return {
    isOpen: !!isOpen,
    open: useCallback(() => setIsOpen('1'), [setIsOpen]),
    close: useCallback(() => setIsOpen(null), [setIsOpen])
  }
}
