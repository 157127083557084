import {useIsMobileStrict, useOnClickOutside} from '@eda-restapp/ui'
import React, {type CSSProperties, useRef} from 'react'
import {createPortal} from 'react-dom'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {useApiQuery} from '@restapp/core-api'
import {getPartnerUidSelectorPath, selectPartnerId, useUserInfo} from '@restapp/core-auth'

import {useGetAvatarUrl} from '../../hooks/useGetAvatarURL'

import {AccountPopover} from './AccountPopover'
import styles from './AccountPopover.module.css'

type AccountPopoverContainerProps = {
  open: boolean
  triggerElement: HTMLDivElement | null
  showTooltip: boolean
  onClose: () => void
  onCloseTooltip: React.MouseEventHandler<HTMLButtonElement>
  onNavigate: (path: string) => void
}

export const AccountPopoverContainer: React.FC<AccountPopoverContainerProps> = ({
  open,
  triggerElement,
  showTooltip,
  onCloseTooltip,
  onClose,
  onNavigate
}) => {
  const userInfo = useUserInfo()
  const isMobile = useIsMobileStrict()
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const getAvatarUrl = useGetAvatarUrl()

  useOnClickOutside(containerRef, onClose)

  const currentPartnerId = useSelector(selectPartnerId)

  const availablePartnersQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/available-partners',
    method: 'GET',
    enabled: open,
    staleTime: 5 * 60_000
  })

  const showSelectCabinetButton = availablePartnersQuery.data ? availablePartnersQuery.data?.partners.length > 1 : false

  const currentCabinet = availablePartnersQuery.data?.partners.find(
    (partner) => partner.partner_uuid === currentPartnerId
  )

  const linkedUsersQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/linked-accounts',
    method: 'GET',
    enabled: open,
    staleTime: 5 * 60_000,
    select: (response) => response.accounts
  })

  if (!userInfo || userInfo.type === 'eats' || !open) {
    return null
  }

  const avatarSrc = getAvatarUrl(userInfo.avatar)
  const currentCabinetName = userInfo.name ?? userInfo.email

  const linkedUsers = linkedUsersQuery.data?.map((user) => {
    return {...user, avatar: getAvatarUrl(user.avatar)}
  })

  const onSelectCabinet = () => {
    navigate(getPartnerUidSelectorPath())
  }

  const getPositioningForDesktop = (): Pick<CSSProperties, 'left' | 'bottom'> => {
    if (!triggerElement) {
      return {left: 0, bottom: 0}
    }

    const rect = triggerElement.getBoundingClientRect()
    return {
      left: rect.left + rect.width,
      bottom: window.innerHeight - rect.bottom
    }
  }

  const desktopPositioning = getPositioningForDesktop()

  return createPortal(
    <div ref={containerRef} className={styles.container} style={!isMobile ? desktopPositioning : undefined}>
      {!isMobile && <div className={styles.arrow} style={desktopPositioning} />}
      <AccountPopover
        avatarSrc={avatarSrc}
        login={userInfo?.login}
        cabinetName={currentCabinetName}
        isPlacesLoading={availablePartnersQuery.isLoading}
        isUsersLoading={linkedUsersQuery.isLoading}
        placesCount={currentCabinet?.places_count ?? 0}
        linkedUsers={linkedUsers || []}
        showTooltip={showTooltip}
        onCloseTooltip={onCloseTooltip}
        onClose={onClose}
        onLogout={() => onNavigate('/logout')}
        onOpenAccount={() => onNavigate('/account')}
        onSelectCabinet={showSelectCabinetButton ? onSelectCabinet : undefined}
      />
    </div>,
    document.body
  )
}
