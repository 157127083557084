import {errorLogger} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'
import {isApiError} from '@restapp/shared-api'

import {selectPool, selectWidgetShowState} from '../selectors'
import {
  resetPool,
  setPoolDataIsReady,
  setPoolNews,
  setPoolNotifications,
  setPoolPollingCursor,
  setPoolPollingInterval,
  setPoolPollingLimit
} from '../slice'
import type {Communication} from '../types'
import {CommunicationStatus, CommunicationType} from '../types'

export function usePool() {
  const dispatch = useDispatch()

  const poolModel = useSelector(selectPool)
  const widgetShowState = useSelector(selectWidgetShowState)

  const pollingMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications',
    method: 'POST'
  })

  const poll = useCallback(
    (selectedPlaceId?: number, customParams?: {pollingLimit?: number}) => {
      if (!selectedPlaceId) {
        return
      }

      dispatch(resetPool())
      dispatch(setPoolDataIsReady(false))
      pollingMutation.mutate(
        {
          body: {
            services: [CommunicationType.NEWS, CommunicationType.NOTIFICATION]
          },
          params: {
            place_id: selectedPlaceId,
            limit: customParams?.pollingLimit ?? poolModel.pollingLimit
          }
        },
        {
          onSuccess: (data) => {
            const newPollingInterval = data.polling_delay * 1000
            const newCursor = data.cursor

            const {notification, news} = data.feed

            if (notification) {
              dispatch(setPoolNotifications(notification))
            }
            if (news?.length) {
              dispatch(setPoolNews(news))
            }

            dispatch(setPoolDataIsReady(true))
            dispatch(setPoolPollingInterval(newPollingInterval))
            dispatch(setPoolPollingCursor(newCursor))
          },
          onError: (error) => {
            if (isApiError(error)) {
              errorLogger({message: 'communications:polling', error})

              Snackbar.show({
                type: 'error',
                text: error.message
              })
            }
          }
        }
      )
    },
    [dispatch, pollingMutation, poolModel.pollingLimit]
  )

  const reset = useCallback(() => {
    dispatch(resetPool())
  }, [dispatch])

  const setPollingLimit = useCallback(
    (pollingLimit: number) => {
      dispatch(setPoolPollingLimit(pollingLimit))
    },
    [dispatch]
  )

  const forFullScreen = useMemo(() => {
    if (!poolModel.dataIsReady) {
      return null
    }

    return [...poolModel.notificationsPool, ...poolModel.newsPool].reduce(
      (fullscreen: Communication | null, communication) => {
        // Хотя и должны прилетать communication, бывают логи с ошибкой на их отсутствие - для этого тут и добавляем проверку на наличие объекта и поля в массиве self.all при проходе
        if (communication?.info?.show_as_fullscreen && communication.status !== CommunicationStatus.READ) {
          if (!fullscreen || communication.info.priority > fullscreen.info.priority) {
            return communication
          }
        }

        return fullscreen
      },
      null
    )
  }, [poolModel.dataIsReady, poolModel.newsPool, poolModel.notificationsPool])

  const forWidget = useMemo(() => {
    if (!poolModel.dataIsReady) {
      return null
    }

    return [...poolModel.notificationsPool, ...poolModel.newsPool].reduce(
      (mostImportant: Communication | null, next) => {
        if (
          !next.info.important ||
          next.status === CommunicationStatus.READ ||
          !next.widget ||
          !(widgetShowState[next.id] ?? true)
        ) {
          return mostImportant
        }

        if (!mostImportant) {
          return next
        }

        if (next.info.priority > mostImportant.info.priority) {
          return next
        }

        return mostImportant
      },
      null
    )
  }, [poolModel.dataIsReady, poolModel.newsPool, poolModel.notificationsPool, widgetShowState])

  return useMemo(
    () => ({
      reset,
      poll,
      pollingInterval: poolModel.pollingInterval,
      ready: poolModel.dataIsReady,
      setPollingLimit,
      forWidget,
      forFullScreen
    }),
    [forFullScreen, forWidget, poll, poolModel.dataIsReady, poolModel.pollingInterval, reset, setPollingLimit]
  )
}
