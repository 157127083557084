import {ArrowUp} from '@eda-restapp/ui'
import type {TableCellProps} from '@mui/material/TableCell'
import cn from 'classnames'
import React from 'react'

import {TableCell} from '@restapp/shared-ui'

import {useStyles} from './TableHeaderCell.style'

export type Props = {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  children?: React.ReactNode
  className?: string
  sortType?: 'ASC' | 'DESC'
  onSortChange?: () => void
} & Partial<TableCellProps>

export const TableHeaderCell: React.FC<Props> = (props) => {
  const {children, classes, className, sortType = 'ASC', onSortChange, ...rest} = props
  const {classes: c} = useStyles({onSortChange}, {props: {classes}})

  return (
    <TableCell className={cn(className, c.root)} {...rest}>
      {!!children && (
        <div className={c.wrapper} onClick={onSortChange}>
          {children}
          {onSortChange && <ArrowUp color='#BEC1C4' className={cn(sortType === 'DESC' && c.arrowDown)} />}
        </div>
      )}
    </TableCell>
  )
}
