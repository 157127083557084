import {useI18n} from '@eda-restapp/i18n'
import {isUndefined, times} from 'lodash-es'
import moment from 'moment-timezone'
import type {FC} from 'react'
import React, {useEffect, useState} from 'react'

import {PlaceDisableReason} from '../../DisablePlaceModal.constants'
import type {CustomDayPeriodSet} from '../../DisablePlaceModal.types'

import {CustomPeriodSelector} from './CustomPeriodSelector'

type DatePeriodSet = {
  value: string
  text: string
}

type CustomPeriodSelectorContainerProps = {
  disableReasonCode: number | null
  maxTurnOffDaysCount?: number
  placeTimezone: string
  onChange: (customDate: moment.Moment) => void
}

export const CustomPeriodSelectorContainer: FC<CustomPeriodSelectorContainerProps> = ({
  disableReasonCode,
  maxTurnOffDaysCount = 2,
  placeTimezone,
  onChange
}) => {
  const [selectedDay, setSelectedDay] = useState<CustomDayPeriodSet['value'] | undefined>(
    disableReasonCode === PlaceDisableReason.NOT_KEEP_UP ? 0 : undefined
  )
  const [selectedTime, setSelectedTime] = useState<DatePeriodSet['value']>()
  const {t} = useI18n()

  useEffect(() => {
    if (isUndefined(selectedDay) || isUndefined(selectedTime)) {
      return
    }

    const customDay = moment().tz(placeTimezone).add(selectedDay, 'days')
    const customTime = moment(selectedTime, 'HH:mm:ss').tz(placeTimezone, true)

    onChange(
      customDay.set({hour: customTime.get('hour'), minute: customTime.get('minute'), second: customTime.get('second')})
    )
  }, [placeTimezone, selectedDay, selectedTime, onChange])

  const dayPeriodSet: CustomDayPeriodSet[] = [
    {value: 0, text: t('shared-ui.custom-period-selector.day-data-set-today-label', 'Сегодня')}
  ]

  if (disableReasonCode !== PlaceDisableReason.NOT_KEEP_UP) {
    dayPeriodSet.push(
      ...times(maxTurnOffDaysCount, (index: number) => {
        const day = moment()
          .tz(placeTimezone)
          .add(index + 1, 'days')

        return {value: index + 1, text: day.format('D MMMM')}
      })
    )
  }

  // Massive of time-periods in 30 minutes increment
  let timePeriodSet: DatePeriodSet[] = [
    ...times(48, (index) => {
      const time = moment('00:00:00', 'HH:mm:ss')
        .tz(placeTimezone, true)
        .add(index * 30, 'minute')

      return {value: time.format('HH:mm:ss'), text: time.format('HH:mm')}
    }),
    {value: moment().tz(placeTimezone).endOf('day').format('HH:mm:ss'), text: '23:59'}
  ]

  if (selectedDay === 0) {
    const placeCurrentTime = moment().tz(placeTimezone).format('HH:mm:ss')
    timePeriodSet = timePeriodSet.filter((timePeriod) => timePeriod.value > placeCurrentTime)
  }

  return (
    <CustomPeriodSelector
      dayPeriodSet={dayPeriodSet}
      placeTimezone={placeTimezone}
      selectedDay={selectedDay}
      timePeriodSet={timePeriodSet}
      onSelectedDayChange={setSelectedDay}
      onSelectedTimeChange={setSelectedTime}
    />
  )
}
