import {useExp3} from '@eda-restapp/configs'
import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {useIsEnabledInCurrentNetwork} from '@restapp/shared'

import {useUserInfo} from '../../../../hooks'
import {usePassportEnabled} from '../../../hooks'

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
export const usePassportRestrictionPage = () => {
  const authConfig = useExp3('restapp_auth')
  const userInfo = useUserInfo()
  const passportEnabled = usePassportEnabled()
  const blockPagesEnabled = useIsEnabledInCurrentNetwork(authConfig.passportBlockPagesEnabledType)
  const isAuthorizedOldWay = userInfo?.type === 'eats'
  const location = useLocation()
  const passportRestrictionPageEnabled =
    blockPagesEnabled && passportEnabled.enabled && !!userInfo && isAuthorizedOldWay

  const isPageBlocked = useMemo(() => {
    const pathName = location.pathname.split('/')[1]

    return passportRestrictionPageEnabled && !authConfig.passportEnabledPages.includes(pathName)
  }, [authConfig.passportEnabledPages, location.pathname, passportRestrictionPageEnabled])

  return {
    isBlockPagesEnabled: passportRestrictionPageEnabled,
    isPageBlocked
  }
}
