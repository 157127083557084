import {loadScript} from '../loadScript'

export type YMaps = typeof window.ymaps
const config = __COMMON_CONFIG__

let loadPromise: Promise<YMaps> | null = null

export function loadYMaps(lang: string = 'ru'): Promise<YMaps> {
  if (loadPromise !== null) {
    return loadPromise
  }

  const mapLang = lang === 'ka' ? 'en_US' : 'ru_RU'
  const domain = window.location.hostname.endsWith('.ru') ? 'ru' : 'com'

  loadPromise = new Promise((resolve) => {
    loadScript(`https://api-maps.yandex.${domain}/2.1/?lang=${mapLang}&apikey=${config.geocodeKey}`)
      .then(() => {
        // tslint:disable-next-line
        void window.ymaps.ready(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore https://st.yandex-team.ru/EDADUTY-2840#616ec9a3723c5031444ddb25
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.ymaps.modules.__modules._sandbox.env.hosts.api.services.suggest +=
            'suggest-geo?client_id=eats_vendorka&_='
          resolve(window.ymaps)
        })
      })
      .catch(() => null)
  })

  return loadPromise
}
