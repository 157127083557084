import {useCopyToClipboard} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {Copy2Icon} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC} from 'react'

import Tooltip from '../Tooltip'

import styles from './CopyButton.module.css'

type CopyButtonProps = {
  className?: string
  copyValue: string
}

export const CopyButton: FC<CopyButtonProps> = ({className, copyValue}) => {
  const {t} = useI18n()
  const clipboard = useCopyToClipboard({copiedTimeout: 2000})

  return (
    <Tooltip
      open={clipboard.copied}
      placement='top'
      classes={{tooltip: styles.tooltip}}
      title={t('main-chats.copy-text.skopirovano', 'Скопировано')}
    >
      <Copy2Icon
        data-testid={'copy-button' /*Номер телефона | Кнопка копирования телефона в буфер обмена */}
        className={cn(styles.copyIcon, className)}
        onClick={() => {
          clipboard.copy(copyValue)
        }}
      />
    </Tooltip>
  )
}
