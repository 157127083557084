import moment from 'moment'

import type {WeeklySchedule, Weekdays} from '../StoplistNotifications.types'

import {getDelay} from './getDelay'

export const setNotificationTimeouts = (weekday: Weekdays, schedule: WeeklySchedule, showNotification: () => void) => {
  const timeoutIds = schedule[weekday]?.flatMap((time: string) => {
    if (moment(time, 'HH:mm', true).format('HH:mm') !== time) {
      return []
    }

    const scheduledTime = moment(time, 'HH:mm').toDate()
    const currentTime = new Date()
    const delay = getDelay(currentTime, scheduledTime)

    if (delay < 0) {
      return []
    }

    return setTimeout(showNotification, delay)
  })
  return timeoutIds
}
