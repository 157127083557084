import type {ApiQueryConfig} from '@restapp/core-api'
import useApiQuery from '@restapp/core-api/useApiQuery'
import type {ShortRestaurant} from '@restapp/shared-api'

interface UsePlacesApiResult {
  places?: ShortRestaurant[]
  placesIds?: number[]
  isLoading: boolean
  isFetched: boolean
  isError?: boolean
}

type PlaceOptions = Omit<
  ApiQueryConfig<'/4.0/restapp-front/places/v1/suggest', 'GET'>,
  'select' | 'url' | 'method' | 'params' | 'refetchOnSocketEvent'
>

export function usePlacesApi(apiOptions: PlaceOptions = {}): UsePlacesApiResult {
  const {data, isLoading, isFetched, isError} = useApiQuery({
    url: '/4.0/restapp-front/places/v1/suggest',
    method: 'GET',
    params: {},
    refetchOnSocketEvent: 'place_pull_all',
    staleTime: 60_000,
    ...apiOptions
  })

  return {
    places: data?.payload,
    placesIds: data?.payload.map(({id}) => id),
    isLoading,
    isFetched,
    isError
  }
}
