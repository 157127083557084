import {CssBaseline as MuiCssBaseline} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import React from 'react'

import {GlobalStyles} from '@restapp/core-legacy/common/components/GlobalStyles'
import {ThemesProvider} from '@restapp/core-legacy/common/components/ThemesProvider'

export const StylesProviders: React.FC<{children?: React.ReactNode}> = ({children}) => (
  <ThemesProvider>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiCssBaseline />

      <GlobalStyles />

      {children}
    </LocalizationProvider>
  </ThemesProvider>
)
