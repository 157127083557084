import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Wallet, Feedback, Telegram, PeopleGroup, HumanUser, YandexGPT} from '@eda-restapp/ui'

import {usePlacesApi} from '@restapp/core-places'
import {useFeaturesByFeatures} from '@restapp/shared-places'

export const useSubscriptionConfirmFreeTariffFeatures = (isBusiness?: boolean) => {
  const {t} = useI18n()

  const restappTools = useExp3('restapp_tools')

  const placesQuery = usePlacesApi()
  const featuresByFeaturesQuery = useFeaturesByFeatures(placesQuery.placesIds ?? [])

  const hasYagpt = !!featuresByFeaturesQuery.data?.some((feature) => feature.slug === 'yagpt')

  const isHiddenFeature = (feature: string) => {
    if (feature === 'personal_manager' && restappTools.isHideBusinessPlus) {
      return true
    }

    if (feature === 'personal_manager' && !isBusiness) {
      return true
    }

    if (feature === 'menu_gpt_generation' && !hasYagpt) {
      return true
    }

    return false
  }

  const featuresMap = [
    {
      slug: 'daily_billing',
      title: t('shared-places.subscription-confirm-free-tariff.description-daily', 'Ежедневные выплаты'),
      icon: Wallet
    },
    {
      slug: 'sorry',
      title: t('shared-places.subscription-confirm-free-tariff.description-feedback', 'Ответы на отзывы'),
      icon: Feedback
    },
    {
      slug: 'boss_bot',
      title: t(
        'shared-places.subscription-confirm-free-tariff.description-telegram',
        'Отчёты о работе ресторана в Telegram'
      ),
      icon: Telegram
    },
    {
      slug: 'loyalty_program',
      title: t('shared-places.subscription-confirm-free-tariff.description-loyalty', 'Программа лояльности'),
      icon: PeopleGroup
    },
    {
      slug: 'menu_gpt_generation',
      title: t(
        'shared-places.subscription-confirm-free-tariff.description-yagpt',
        'Генерация описания блюд с YandexGPT'
      ),
      icon: YandexGPT
    },
    {
      slug: 'personal_manager',
      title: t('shared-places.subscription-confirm-free-tariff.personal-manager', 'Личный менеджер'),
      icon: HumanUser
    }
  ]

  return featuresMap.filter(
    (feature) => restappTools.sortedFeaturesTariffCards.includes(feature.slug) && !isHiddenFeature(feature.slug)
  )
}
