import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles<{onSortChange?: unknown}>()((theme, {onSortChange}) => {
  return {
    root: {
      backgroundColor: '#ffffff'
    },
    wrapper: {
      display: 'inline-flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: '14px',
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      color: '#999C9E',
      cursor: onSortChange ? 'pointer' : 'default'
    },
    arrowDown: {
      transform: 'rotate(180deg)'
    }
  }
})
