import type {Selector} from '@reduxjs/toolkit'

import type {AuthStateSlice} from '../types'

import {selectAuth} from './selectAuth'

export const selectIsLoggedIn: Selector<AuthStateSlice, boolean> = (state) => {
  const auth = selectAuth(state)

  if (auth.oauthToken) {
    return Boolean(auth.partnerId)
  }

  return Boolean(auth.token)
}
