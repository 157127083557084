import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'

import {getHumanMaxTime} from '../helpers/getHumanMaxTime'

export const usePauseTimeErrorMessage = (
  availableAt: string | null,
  maxTime: string | undefined,
  reasonCode: number | null
) => {
  const {t} = useI18n()

  const valid = moment(maxTime).isSameOrAfter(moment(availableAt))

  if (availableAt && maxTime && !valid && reasonCode) {
    return t('core-legacy.disable-reason-form.max-time-pause', 'Максимальное время паузы: {text}', {
      text: getHumanMaxTime(maxTime, reasonCode)
    })
  }

  return
}
