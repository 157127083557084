type AnimationNameGenerator = (name: string) => string
const createGenerateAnimationNameDev = (): AnimationNameGenerator => {
  let counter = 0
  return (name) => `${name}-${counter++}`
}
const createGenerateAnimationNameProduction = (): AnimationNameGenerator => {
  let counter = 0
  return () => `_${(counter++).toString(36)}`
}
/**
 * Генерирует имя для CSS Keyframes Animation
 */
export const generateAnimationName =
  process.env.NODE_ENV === 'production' ? createGenerateAnimationNameProduction() : createGenerateAnimationNameDev()
