import {get, toString} from 'lodash-es'
import type {ComponentType, ComponentProps} from 'react'
import React from 'react'

import ErrorBoundary from './components/ErrorBoundary'

type ErrorBoundaryProps = ComponentProps<typeof ErrorBoundary>

export default function withErrorBoundary<TProps>(
  OriginalComponent: ComponentType<TProps & JSX.IntrinsicAttributes>,
  boundaryProps?: ErrorBoundaryProps
) {
  return function WrappedComponent(props: TProps & JSX.IntrinsicAttributes) {
    return (
      <ErrorBoundary slug={toString(get(props, 'slug'))} {...boundaryProps}>
        <OriginalComponent {...props} />
      </ErrorBoundary>
    )
  }
}
