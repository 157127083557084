import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import {Button, Typography} from '@eda-restapp/ui'
import type {FC} from 'react'

import {PassportIcon} from '../../../../../passport/components/PassportIcon'
import {createOauthUrl} from '../../../../../passport/helpers/createOauthUrl'
import {OauthStateEncoder} from '../../../../../passport/helpers/OauthStateEncoder'
import {usePassportName} from '../../../../../passport/hooks'

import styles from './OauthButton.module.css'

type OauthButtonProps = {
  loading?: boolean
}

export const OauthButton: FC<OauthButtonProps> = ({loading}) => {
  const restappAuthConfig = useExp3('restapp_auth')
  const passportName = usePassportName()

  return (
    <a
      data-testid={'passport-auth-button' /*Страница авторизации | Кнопка авторизации через паспорт */}
      className={styles.link}
      href={createOauthUrl({
        passportUrl: restappAuthConfig.passport.passportUrl,
        oauthUrl: restappAuthConfig.passport.oauthUrl,
        clientId: restappAuthConfig.passport.oauthClientId,
        passportQuery: restappAuthConfig.passport.passportUrlAdditionalQuery,
        state: OauthStateEncoder.stringify({redirect_path: window.location.pathname})
      })}
      onClick={() => eventLogger({name: 'passport:click_passport_login_button'})}
    >
      <Button.MultiCenter
        variant='outline'
        startIcon={<PassportIcon className={styles.passportIcon} />}
        loading={loading}
        fullWidth
      >
        {passportName}
      </Button.MultiCenter>
      {restappAuthConfig.showNDABadgeOnPassportAuthButton && (
        <Typography variant='caption1' color='invert' thickness='medium' className={styles.ndaBadge}>
          NDA
        </Typography>
      )}
    </a>
  )
}
