import type {
  ICommunicationWidget,
  ISurveyAnswerEmoji,
  ISurveyAnswerRating,
  ISurveyAnswerSingleChoice,
  ISurveyAnswerText,
  ISurveyInfo,
  ISurveyAnswerMultipleChoice
} from '@restapp/shared-api'

import {CommunicationType} from './communications'
import type {Communication, CommunicationStatus} from './communications'

export type Survey = {
  type: CommunicationType.SURVEY
  id: string
  created: string
  status: CommunicationStatus
  info: ISurveyInfo
  preview: {
    title: string
    button: {
      text: string
    }
  }
  content: {
    content_type: 'simple' | 'story'
    pages: SurveyContentPage[]
  }
  widget?: ICommunicationWidget
}

export type SurveyContentPage = {
  questions: SurveyQuestion[]
  /**
   * @deprecated - use questions field
   */
  question: string
  /**
   * @deprecated - use questions field
   */
  description: string
  /**
   * @deprecated - use questions field
   */
  answer: SurveyAnswerUnion
}

export type SurveyQuestion = {
  question: string
  question_font?: number
  question_is_bold?: boolean

  description: string
  description_font?: number
  description_is_bold?: boolean

  answer_required?: boolean

  answer: SurveyAnswerUnion
}

type SurveyAnswerBase = {
  pageIndex: number
  questionIndex: number
}

export type PredefinedAnswer = {
  type: 'predefined' | 'other'
  text: string
  selected: boolean
}

export type SurveyAnswerPredefined = Omit<ISurveyAnswerMultipleChoice | ISurveyAnswerSingleChoice, 'answers'> & {
  answers: PredefinedAnswer[]
} & SurveyAnswerBase

export type SurveyAnswerRating = ISurveyAnswerRating & {
  rating: number | null
} & SurveyAnswerBase

export type SurveyAnswerText = ISurveyAnswerText & {
  text: string
} & SurveyAnswerBase

export type SurveyAnswerEmoji = ISurveyAnswerEmoji & {
  rating: number | null
} & SurveyAnswerBase

export type SetAnswer = SetTextAnswer | SetRatingAnswer | SetPredefinedAnswer

export type SetTextAnswer = {
  type: 'text'
  value: string
  pageIndex: number
  questionIndex: number
}

export type SetRatingAnswer = {
  type: 'rating'
  value: number
  pageIndex: number
  questionIndex: number
}

export type SetPredefinedAnswer = {
  type: 'single' | 'multiple'
  text?: string
  pageIndex: number
  questionIndex: number
  answerIndex: number
}

export type SurveyAnswerUnion = SurveyAnswerPredefined | SurveyAnswerRating | SurveyAnswerText | SurveyAnswerEmoji

export type SurveyAnswerToApi =
  | {
      type: 'single' | 'multiple'
      answers: Array<string>
    }
  | {
      type: 'rating'
      rating: number
    }
  | {
      type: 'text'
      answer: string
    }

export function isPredefinedAnswer(answer: SurveyAnswerUnion): answer is SurveyAnswerPredefined {
  return answer.type === 'single' || answer.type === 'multiple'
}

export function isRatingAnswer(answer: SurveyAnswerUnion): answer is SurveyAnswerRating {
  return answer.type === 'rating'
}

export function isTextAnswer(answer: SurveyAnswerUnion): answer is SurveyAnswerText {
  return answer.type === 'text'
}

export function isSurvey(communication: Communication): communication is Survey {
  return communication.type === CommunicationType.SURVEY
}
