import {useCallback, useState} from 'react'

import useMoneyInput from '@restapp/promotion/hooks/useMoneyInput'

import type {UseWeekBudgetReturnType} from './types'

type Props = {
  minWeekBudgetPrice?: number
  maxWeekBudgetPrice?: number
  clickValueToCompare?: number
  defaultValue?: number
  value?: number
  withDecimals?: boolean
}

export const useWeekBudget = (props: Props): UseWeekBudgetReturnType => {
  const {clickValueToCompare, defaultValue, minWeekBudgetPrice = 0, maxWeekBudgetPrice, value, withDecimals} = props

  const [isWeekBudgetOn, setIsWeekBudgetOn] = useState(!!value)

  const validate = useCallback(
    ({numberValue}: {numberValue: number}) => {
      if (minWeekBudgetPrice >= 0 && numberValue < minWeekBudgetPrice) {
        return false
      }

      if (maxWeekBudgetPrice && numberValue > maxWeekBudgetPrice) {
        return false
      }

      if (clickValueToCompare === undefined) {
        return false
      }

      if (numberValue < clickValueToCompare) {
        return false
      }

      return true
    },
    [minWeekBudgetPrice, maxWeekBudgetPrice, clickValueToCompare]
  )
  const budgetInput = useMoneyInput({
    defaultValue: isWeekBudgetOn ? value || defaultValue : undefined,
    validate,
    withDecimals
  })

  return {
    budgetInput,
    isWeekBudgetOn,
    setIsWeekBudgetOn
  }
}
