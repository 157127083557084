import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles({name: 'TariffBadgeContainer'})((theme) => {
  return {
    hideTariffBadgeOnMobile: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    }
  }
})
