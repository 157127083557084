import {errorLogger} from '@eda-restapp/logger'
import {useMemo} from 'react'

import {useOldHeavyPlaces} from '@restapp/core-places'

import {BudgetAllocation, CampaignType} from '../../types/campaign'
import type {MinMaxDefaults} from '../../types/common'
import usePromotionCostConfigs from '../usePromotionCostConfigs'

import groupPrices from './groupPrices'
import mergeSections from './mergeSections'
import type {UsePromotionRegionRatesReturnType} from './types'

export const usePromotionRegionRates = (
  placeIds: number[],
  campaignType: CampaignType = CampaignType.cpc,
  budgetPeriod: BudgetAllocation = BudgetAllocation.weekly
): UsePromotionRegionRatesReturnType => {
  const {places} = useOldHeavyPlaces()
  const costConfig = usePromotionCostConfigs()

  const rates = useMemo((): UsePromotionRegionRatesReturnType => {
    const {
      defaultCpaMaxPrice,
      defaultCpaMinPrice,
      defaultCpaPrice,

      defaultClickPrice,
      minClickPrice,
      maxClickPrice,

      defaultWeekBudgetPrice,
      minWeekBudgetPrice,

      defaultCpaBudgetPrice,
      minCpaBudgetPrice,
      maxCpaBudgetPrice,

      countryRates,
      regionRates,
      bannerBudget: defaultBannerBudget,
      bannerBudgetAllPeriod: defaultBannerBudgetAllPeriod,
      bannerPrice: defaultBannerPrice
    } = costConfig
    const defaultAdvPrice: MinMaxDefaults = {min: minClickPrice, max: maxClickPrice, default: defaultClickPrice}
    const defaultAdvBudget: MinMaxDefaults = {min: minWeekBudgetPrice, default: defaultWeekBudgetPrice}

    const processedPlaces = places.filter(({id}) => placeIds.includes(id))
    const countryRatesGrouped = countryRates.map(groupPrices)
    const regionRatesGrouped = regionRates.map(groupPrices)

    const processedRegionRates = mergeSections.mergeRegionRates(regionRatesGrouped, processedPlaces)
    const processedCountryRates = mergeSections.mergeCountryRates(countryRatesGrouped, processedPlaces)

    const advPrice = processedRegionRates.adv_price || processedCountryRates.adv_price || defaultAdvPrice
    const advBudget = processedRegionRates.adv_budget || processedCountryRates.adv_budget || defaultAdvBudget
    const bannerPrice = processedRegionRates.banner_price || processedCountryRates.banner_price || defaultBannerPrice
    const bannerBudgetWeekly =
      processedRegionRates.banner_budget || processedCountryRates.banner_budget || defaultBannerBudget
    const bannerBudgetAllPeriod =
      processedRegionRates.banner_budget_all_period ||
      processedCountryRates.banner_budget_all_period ||
      defaultBannerBudgetAllPeriod

    const defaultCpaBudget: MinMaxDefaults = {
      min: minCpaBudgetPrice,
      max: maxCpaBudgetPrice ? maxCpaBudgetPrice * processedPlaces.length : maxCpaBudgetPrice,
      default: defaultCpaBudgetPrice
    }

    const cpaBudget = processedRegionRates.cpa_budget || processedCountryRates.cpa_budget || defaultCpaBudget

    const bannerBudget =
      budgetPeriod === BudgetAllocation.weekly ? bannerBudgetWeekly : (bannerBudgetAllPeriod ?? bannerBudgetWeekly)

    return campaignType === CampaignType.cpc || campaignType === CampaignType.cpa
      ? {
          defaultCpaMaxPrice,
          defaultCpaMinPrice,
          defaultCpaPrice,
          defaultClickPrice: advPrice.default,
          defaultWeekBudgetPrice: advBudget.default,
          minClickPrice: advPrice.min,
          maxClickPrice: advPrice.max,
          minWeekBudgetPrice: advBudget.min,

          defaultCpaBudgetPrice: cpaBudget.default,
          minCpaBudgetPrice: cpaBudget.min,
          maxCpaBudgetPrice: cpaBudget.max
        }
      : {
          defaultCpaMaxPrice,
          defaultCpaMinPrice,
          defaultCpaPrice,
          defaultClickPrice: bannerPrice?.default,
          defaultWeekBudgetPrice: bannerBudget?.default,
          minClickPrice: bannerPrice?.min,
          maxClickPrice: bannerPrice?.max,
          minWeekBudgetPrice: bannerBudget?.min
        }
  }, [campaignType, budgetPeriod, costConfig, placeIds, places])

  // Проверяется непротиворечивость значений конфига
  if (
    (rates.defaultClickPrice !== undefined &&
      rates.maxClickPrice !== undefined &&
      rates.defaultClickPrice > rates.maxClickPrice) ||
    (rates.defaultClickPrice !== undefined &&
      rates.minClickPrice !== undefined &&
      rates.defaultClickPrice < rates.minClickPrice) ||
    (rates.defaultWeekBudgetPrice !== undefined &&
      rates.minWeekBudgetPrice &&
      rates.defaultWeekBudgetPrice < rates.minWeekBudgetPrice) ||
    (rates.defaultCpaPrice !== undefined &&
      rates.defaultCpaMinPrice !== undefined &&
      rates.defaultCpaPrice < rates.defaultCpaMinPrice) ||
    (rates.defaultCpaPrice !== undefined &&
      rates.defaultCpaMaxPrice !== undefined &&
      rates.defaultCpaPrice > rates.defaultCpaMaxPrice)
  ) {
    errorLogger({
      level: 'error',
      sourceType: 'validation',
      additional: {rates, costConfig, eventSlug: 'promotion:usePromotionRegionRates:check_config_wrong_default_values'},
      error: new Error('promotion:usePromotionRegionRates:check_config_wrong_default_values')
    })
  }

  return rates
}
