import {useI18n} from '@eda-restapp/i18n'

import {getQuerySearchLang} from '../helpers/getQuerySearchLang'

const isSpravkaLink = (link: string) => ['yandex.com/support', 'yandex.ru/support'].some(link.includes.bind(link))

/** Обрабатывает ссылки на я.справку, если это не справка ничего не делает */
export const useSupportLinkPrep = (link: string) => {
  const {lang} = useI18n()

  if (!isSpravkaLink(link)) {
    return link
  }

  const queryLang = getQuerySearchLang(lang)

  try {
    // Учитывая как работает new URL добавил тут trycatch с фоллбэком на link
    const parsed = link.startsWith('http') ? new URL(link) : new URL(`https://${link}`)

    // If config support link contains search lang-query, then skip setting lang in URL
    if (!parsed.searchParams.has('lang')) {
      parsed.searchParams.set('lang', queryLang)
    }

    return parsed.toString()
  } catch {
    return link
  }
}

export default useSupportLinkPrep
