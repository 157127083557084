import {mediaQuerySizes} from '../constants'

export const createRequisitesPaymentUrl = ({
  login,
  origin,
  paymentUrl,
  cookieResolverUrl,
  isUseCookieResolver,
  options = [],
  mobileOption,
  commandOption,
  promocode
}: {
  login: string
  origin: string
  paymentUrl: string
  cookieResolverUrl: string
  isUseCookieResolver: boolean
  options: string[]
  mobileOption: string
  commandOption: string
  promocode: string | undefined
}) => {
  const isMobile = window.innerWidth <= mediaQuerySizes.TABLET_MEDIUM_MIN
  const path = isUseCookieResolver ? cookieResolverUrl : paymentUrl
  const urlOptions = [
    isMobile ? mobileOption : '',
    isUseCookieResolver ? '' : commandOption,
    ...options,
    `ulogin=${encodeURIComponent(login)}`,
    promocode ? `promocode=${promocode}` : ''
  ].filter(Boolean)

  const urlOptionsStr = urlOptions.length ? urlOptions.join('&') : ''

  return `${origin}${path}?${urlOptionsStr}`
}
