import {errorLogger} from '@eda-restapp/logger'
import type {ComponentType} from 'react'
import React from 'react'

import ModerationErrorsBadge from './ModerationErrorsBadge'
import NewOrdersBadge from './NewOrdersBadge'
import UnreadChatsBadge from './UnreadChatsBadge'
import UnseenFeedbackBadge from './UnseenFeedbackBadge'

interface CustomSidebarBadgeProps {
  badgeId: string
}

type SupportedBadgeIds = 'new_orders_badge' | 'moderation_errors_badge' | 'unseen_feedback_badge' | 'unread_chats_badge'

const supportedBadges = new Set([
  'new_orders_badge',
  'moderation_errors_badge',
  'unseen_feedback_badge',
  'unread_chats_badge'
])

const badgeIdToComponent: Record<SupportedBadgeIds, ComponentType> = {
  new_orders_badge: NewOrdersBadge,
  moderation_errors_badge: ModerationErrorsBadge,
  unseen_feedback_badge: UnseenFeedbackBadge,
  unread_chats_badge: UnreadChatsBadge
}

function isSupportedBadge(badgeId: string): badgeId is SupportedBadgeIds {
  return supportedBadges.has(badgeId)
}

const CustomSidebarBadge: React.FC<CustomSidebarBadgeProps> = ({badgeId}) => {
  if (badgeId === 'unread_communications_badge') {
    return null
  }

  if (isSupportedBadge(badgeId)) {
    const Badge = badgeIdToComponent[badgeId]
    return <Badge />
  }

  errorLogger({
    level: 'warn',
    error: new Error('unsupported-sidebar-badge-id'),
    additional: {badgeId, eventSlug: 'unsupported-sidebar-badge-id'}
  })
  return null
}

export default CustomSidebarBadge
