import type {RectConfig} from './types'

const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  {top, left, width, height, radius, fill, filter, clip = true}: RectConfig & {clip?: boolean}
) => {
  ctx.save()
  ctx.beginPath()
  ctx.moveTo(left + radius, top)
  ctx.lineTo(left + width - radius, top)
  ctx.arcTo(left + width, top, left + width, top + radius, radius)
  ctx.lineTo(left + width, top + height - radius)
  ctx.arcTo(left + width, top + height, left + width - radius, top + height, radius)
  ctx.lineTo(left + radius, top + height)
  ctx.arcTo(left, top + height, left, top + height - radius, radius)
  ctx.lineTo(left, top + radius)
  ctx.arcTo(left, top, left + radius, top, radius)
  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
  if (filter) {
    ctx.filter = filter
  }
  if (clip) {
    ctx.clip()
  }
  ctx.restore()
}

export default drawRoundRect
