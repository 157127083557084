import {useEffect, useCallback} from 'react'

function useWindowEvent<K extends keyof WindowEventMap>(
  type: K,
  callback: (this: Window, ev: WindowEventMap[K]) => void,
  deps: unknown[] = []
) {
  const handler = useCallback(callback, [callback, ...deps])

  useEffect(() => {
    window.addEventListener(type, handler)

    return () => {
      window.removeEventListener(type, handler)
    }
  }, [handler, type])
}

export default useWindowEvent
