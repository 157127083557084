/* eslint-disable brace-style */
import {t} from '@eda-restapp/i18n'

export const WEEKDAYS_LIST = () => [
  t('core-legacy.date.ponedelnik', 'Понедельник'),
  t('core-legacy.date.vtornik', 'Вторник'),
  t('core-legacy.date.sreda', 'Среда'),
  t('core-legacy.date.chetverg', 'Четверг'),
  t('core-legacy.date.pyatnitsa', 'Пятница'),
  t('core-legacy.date.subbota', 'Суббота'),
  t('core-legacy.date.voskresene', 'Воскресенье')
]

export const WEEKDAYS_LIST_SHORT = () => [
  t('core-legacy.date.pn', 'Пн'),
  t('core-legacy.date.vt', 'Вт'),
  t('core-legacy.date.sr', 'Ср'),
  t('core-legacy.date.cht', 'Чт'),
  t('core-legacy.date.pt', 'Пт'),
  t('core-legacy.date.sb', 'Сб'),
  t('core-legacy.date.vs', 'Вс')
]

export const MINUTES_DAY_START = 0
export const MINUTES_DAY_END = 1440
