import cn from 'classnames'
import React from 'react'
import type {CSSProperties, FC} from 'react'

import {SupportTreeListItem} from '../SupportTreeListItem'

import styles from './SupportTree.module.css'

export type SupportTreeProps = {
  elements: {
    id: string
    title: string
  }[]
  onElementClick: (id: string) => void

  className?: string
  style?: CSSProperties
}

export const SupportTree: FC<SupportTreeProps> = ({elements, onElementClick, className, style}) => {
  return (
    <div className={cn(styles.container, className)} style={style}>
      {elements.map((element) => (
        <SupportTreeListItem key={element.id} title={element.title} onClick={() => onElementClick(element.id)} />
      ))}
    </div>
  )
}
