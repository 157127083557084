import {YandexRu, YandexInternational} from '@eda-restapp/ui'
import React, {type CSSProperties} from 'react'

import {useServiceBrand} from '@restapp/shared'

type PassportIconProps = {
  style?: CSSProperties
  className?: string
}

export const PassportIcon: React.FC<PassportIconProps> = ({style, className}) => {
  const serviceBrand = useServiceBrand()

  switch (serviceBrand) {
    case 'YandexEats':
      return <YandexInternational style={style} className={className} />
    case 'YangoEats':
      return <YandexInternational style={style} className={className} />
    case 'YandexEda':
      return <YandexRu style={style} className={className} />
  }
}
