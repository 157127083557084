import {useExp3} from '@eda-restapp/configs'
import React, {type FC} from 'react'

import {useSupportChat} from '@restapp/main-chats/hooks/useSupportChat'
import type {PlaceBlock} from '@restapp/shared-api'

import {BlockDescriptionModal} from './BlockDescriptionModal'

type BlockDescriptionModalContainerProps = {
  blockInfo?: PlaceBlock
  isAutostopInfo?: boolean
  isOpen: boolean
  placeId: number
  onModalClose: () => void
}

export const BlockDescriptionModalContainer: FC<BlockDescriptionModalContainerProps> = ({
  blockInfo,
  isAutostopInfo = false,
  isOpen,
  placeId,
  onModalClose
}) => {
  const placeBlocksConfig = useExp3('restapp_place_blocks')
  const {openNewSupportChat} = useSupportChat()

  if (!isOpen) {
    return null
  }

  const isBlockTypeInInfinityList =
    !!blockInfo?.reason && placeBlocksConfig.infinityBlocksTypeList.includes(blockInfo.reason)
  const isSupportButtonEnabled =
    !!blockInfo?.reason && placeBlocksConfig.supportButtonEnabledBlocksTypeList.includes(blockInfo.reason)

  const handleHelpButtonClick = () => {
    openNewSupportChat({place_id: placeId})
  }

  return (
    <BlockDescriptionModal
      blockInfo={blockInfo}
      isAutostopInfo={isAutostopInfo}
      isBlockTypeInInfinityList={isBlockTypeInInfinityList}
      isSupportButtonEnabled={isSupportButtonEnabled}
      isSupportLinkEnabled={
        !!blockInfo?.reason && !!placeBlocksConfig.descriptionModalTitleSupportLinks[blockInfo?.reason]?.enabled
      }
      onHelpButtonClick={handleHelpButtonClick}
      onModalClose={onModalClose}
    />
  )
}
