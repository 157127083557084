import {eventLogger, metrika} from '@eda-restapp/logger'
import {RestaurantSelect} from '@eda-restapp/ui'
import React from 'react'

import {DeliveryIcon} from '@restapp/shared-ui'

import {usePlaces} from '../../hooks'

export interface SinglePlaceDropdownProps {
  className?: string
  slug?: string
}

const SinglePlaceDropdown: React.FC<SinglePlaceDropdownProps> = ({className, slug}) => {
  const {places, selectedPlaceId, setSelectedPlaceId} = usePlaces()

  if (places.length <= 1) {
    return null
  }

  const handleRestaurantChange = (placeIds: number[]) => {
    const [placeId] = placeIds
    if (!places.some(({id}) => id === placeId)) {
      console.error(`Place with id ${placeId} not found in dropdownProps.options`)
      return
    }
    setSelectedPlaceId(placeId)
    if (slug) {
      eventLogger({
        name: 'select_restaurant',
        value: slug,
        additional: {
          value: JSON.stringify(placeIds)
        }
      })
      metrika({
        target: 'select_restaurant',
        params: {
          slug,
          placeIds: JSON.stringify(placeIds)
        }
      })
    }
  }

  const options = places.map((place) => {
    return {
      id: place.id,
      title: place.name,
      address: place.address,
      icon: <DeliveryIcon place={place} fixZIndex />,
      disabled: false,
      annotation: '',
      hasAlert: false
    }
  })

  return (
    <RestaurantSelect
      className={className}
      options={options}
      values={[selectedPlaceId]}
      onChange={handleRestaurantChange}
    />
  )
}

export default SinglePlaceDropdown
