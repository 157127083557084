import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {PlacesEditState} from '../../types/edit'

const initialState: PlacesEditState = {
  placeInModerationIds: {},
  placeActiveEditPollingIds: {}
}

export const placesEditSlice = createSlice({
  name: 'placesEditSlice',
  initialState,
  reducers: {
    addPlaceInModeration(state, action: PayloadAction<number>) {
      state.placeInModerationIds[action.payload] = true
    },
    addActiveEditPolling(state, action: PayloadAction<number>) {
      state.placeActiveEditPollingIds[action.payload] = true
    },
    removePlaceInModeration(state, action: PayloadAction<number>) {
      delete state.placeInModerationIds[action.payload]
    },
    removeActiveEditPolling(state, action: PayloadAction<number>) {
      delete state.placeActiveEditPollingIds[action.payload]
    }
  },
  selectors: {
    selectPlaceInModerationIds(state) {
      return state.placeInModerationIds
    },
    selectPlaceActiveEditPollingIds(state) {
      return state.placeActiveEditPollingIds
    }
  }
})
