import {createReducer} from '@reduxjs/toolkit'

import {userLogout} from '@restapp/core-auth/slice'
import {makeReducerPersistent} from '@restapp/store'

import {setSidebarVisibility, setSidebarSize} from '../actions'
import {SIDEBAR_VISIBILITY_PERSIST_KEY} from '../constants'

type StateType = {
  isSidebarVisible: boolean
  sidebarSize: number
}

const initialState: StateType = {
  isSidebarVisible: true,
  sidebarSize: 0
}

const sidebarReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSidebarVisibility, (state, action) => {
    return {
      ...state,
      isSidebarVisible: action.payload
    }
  })

  builder.addCase(setSidebarSize, (state, action) => {
    return {
      ...state,
      sidebarSize: action.payload
    }
  })

  builder.addCase(userLogout, () => initialState)
})

export default makeReducerPersistent(sidebarReducer, SIDEBAR_VISIBILITY_PERSIST_KEY)
