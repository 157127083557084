import {useI18n} from '@eda-restapp/i18n'
import {Modal} from '@eda-restapp/ui'
import React from 'react'
import type {FC} from 'react'

import {MultiChatInfoContainer} from '../MultiChatInfo/MultiChatInfo.container'

export type MultiChatInfoModalProps = {
  ticketId: string

  open: boolean
  onClose?: () => void
}

export const MultiChatInfoModal: FC<MultiChatInfoModalProps> = ({ticketId, open, onClose}) => {
  const {t} = useI18n()

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header title={t('main-chats.multi-chat-info-modal.info-about-appeal', 'Информация по обращению')} />
      <Modal.Content>
        <MultiChatInfoContainer ticketId={ticketId} />
      </Modal.Content>
    </Modal>
  )
}
