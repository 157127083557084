import {useExp3} from '@eda-restapp/configs'

import {useApiMutation} from '@restapp/core-api'

export const useSetPlacesOffers = () => {
  const {enabledOffersApi} = useExp3('restapp_tools')

  const {mutate} = useApiMutation({
    method: 'POST',
    url: '/4.0/restapp-front/v1/place-subscriptions/v1/place/set-offers'
  })

  const setOffers = (status: 'accepted' | 'rejected', placesIds?: number[], onSuccess?: () => void) => {
    if (!enabledOffersApi || !placesIds?.length) {
      return
    }
    mutate(
      {
        body: {
          status,
          place_ids: placesIds
        }
      },
      {
        onSuccess
      }
    )
  }

  return {
    setOffers
  }
}
