import type {DependencyList} from 'react'
import {useEffect, useRef} from 'react'

const useSecondEffect = (callback: () => void, deps?: DependencyList) => {
  const firstEffectFiredRef = useRef(false)

  useEffect(() => {
    const firstEffectFired = firstEffectFiredRef.current
    firstEffectFiredRef.current = true

    if (firstEffectFired) {
      return callback()
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, deps)
}

export default useSecondEffect
