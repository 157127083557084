import {useApiQuery} from '@restapp/core-api'
import {RESTAURANT_DISABLE_REASONS} from '@restapp/shared'

type AutoStopRulesConfig = {
  enabled?: boolean
}

export const useAutoStopRules = (placeId: number, config?: AutoStopRulesConfig) => {
  const {isLoading, data} = useApiQuery({
    url: '/4.0/restapp-front/place/v1/autostop-rules',
    method: 'GET',
    enabled: config?.enabled,
    params: {
      placeId
    },
    staleTime: 30 * 1000
  })

  return {
    RESTAURANT_DISABLE_REASONS,
    isLoading,
    data
  }
}
