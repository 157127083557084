import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import {getDirectToken} from '@eda-restapp/microfrontend'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {useApiQuery} from '@restapp/core-api'
import type {LinkedAccount} from '@restapp/shared-api/types/passport'

import {useUserInfo} from '../../../../hooks'
import {setOauthToken} from '../../../../slice'
import {createOauthUrl} from '../../../helpers/createOauthUrl'
import {OauthStateEncoder} from '../../../helpers/OauthStateEncoder'

import {usePushUnsubscription} from './usePushUnsubscription'

const BINDING_ROUTE = '/bind-passport'

export const usePassportBind = () => {
  const dispatch = useDispatch()

  const authConfig = useExp3('restapp_auth')
  const navigate = useNavigate()
  const pushUnsubscription = usePushUnsubscription()

  const userInfo = useUserInfo()
  const isAuthorizedOldWay = userInfo?.type === 'eats'

  const directToken = getDirectToken()

  const accountSuggestQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/account-suggest',
    method: 'GET',
    enabled: isAuthorizedOldWay,
    staleTime: 60_000 * 120,
    headers: directToken
      ? {
          Authorization: `Bearer ${directToken}`
        }
      : undefined
  })

  if (!accountSuggestQuery.data || !userInfo) {
    return null
  }

  const openPassportAuth = (suggestLogin?: string, state?: Record<string, string>) => {
    window.open(
      createOauthUrl({
        passportUrl: authConfig.passport.passportUrl,
        oauthUrl: authConfig.passport.oauthUrl,
        clientId: authConfig.passport.oauthClientId,
        passportQuery: authConfig.passport.passportUrlAdditionalQuery,
        login: suggestLogin,
        state: state ? OauthStateEncoder.stringify(state) : undefined
      }),
      '_self'
    )
  }

  const passportBindingRedirectPath = `${BINDING_ROUTE}?secret=${accountSuggestQuery.data.secret}&linked_partner=${userInfo?.partner_uuid}`

  const openPassportAuthForBind = async (suggestLogin?: string) => {
    await pushUnsubscription.unsubscribe()

    openPassportAuth(suggestLogin, {
      redirect_path: passportBindingRedirectPath
    })
  }

  const handleBindSuggestedAccount = async () => {
    if (accountSuggestQuery.data.primary) {
      await openPassportAuthForBind(accountSuggestQuery.data.primary.login)

      return
    }

    if (accountSuggestQuery.data?.advert_active && directToken) {
      await pushUnsubscription.unsubscribe()

      eventLogger({name: 'passport:bind_direct_account'})
      dispatch(setOauthToken(directToken))

      navigate(passportBindingRedirectPath)

      return
    }
  }

  const openPassportAuthForLinkedAccount = (account: LinkedAccount) => {
    eventLogger({name: 'passport:auth_to_already_binded_account'})
    openPassportAuth(account.can_use_login_hint ? account.login : undefined, {
      redirect_path: `/select-account?partnerId=${userInfo.partner_uuid}`
    })
  }

  return {
    suggestedAccount: accountSuggestQuery.data.primary || accountSuggestQuery.data.advert_active,
    onBindOtherAccount: () => void openPassportAuthForBind(),
    onBindSuggestedAccount: () => void handleBindSuggestedAccount(),
    onAuthWithLinkedAccount: openPassportAuthForLinkedAccount
  }
}
