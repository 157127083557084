import type {VendorApi} from '@restapp/shared-api'

export function mockEmptyPromosList() {
  return {payload: {promos: []}, meta: {fetched: 0, total: 0}}
}

export const PROMOS_MOCK = [
  {
    id: 150565,
    status: 'enabled',
    name: 'Скидка 10%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-09T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 10}]
  },
  {
    id: 150562,
    status: 'enabled',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-04-09T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [{item_ids: ['1234583', '1234595', '1234605']}],
    bonuses: []
  },
  {
    id: 150559,
    status: 'enabled',
    name: 'Скидка 10%',
    description: 'На ряд блюд',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [{item_ids: ['1234583', '1234595', '1234605', '1234617']}],
    bonuses: [{discount: 10}]
  },
  {
    id: 150556,
    status: 'approved',
    name: 'Скидка 35%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 35}]
  },
  {
    id: 150553,
    status: 'enabled',
    name: 'Скидка 35%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 35}]
  },
  {
    id: 150550,
    status: 'enabled',
    name: 'Скидка 15%',
    description: 'На ряд блюд',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [{item_ids: ['1234583', '1234595', '1234605']}],
    bonuses: [{discount: 15}]
  },
  {
    id: 150547,
    status: 'enabled',
    name: 'Скидка 15%',
    description: 'На ряд блюд',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [{item_ids: ['1234583', '1234595', '1234605']}],
    bonuses: [{discount: 15}]
  },
  {
    id: 150544,
    status: 'approved',
    name: 'Скидка 15%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-04-08T18:00:00+00:00',
    ends_at: '2021-04-29T18:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 15}]
  },
  {
    id: 150493,
    status: 'completed',
    name: 'Скидка 40%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-30T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 40}]
  },
  {
    id: 150490,
    status: 'approved',
    name: 'Скидка 5%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-24T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: [{discount: 5}]
  },
  {
    id: 150466,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-19T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150463,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-17T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150461,
    status: 'new',
    name: 'Скидка 35%',
    description: 'На ряд блюд',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-17T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150459,
    status: 'new',
    name: 'Скидка 10%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-17T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 720, to: 960},
      {day: 2, from: 720, to: 960},
      {day: 3, from: 720, to: 960},
      {day: 4, from: 720, to: 960},
      {day: 5, from: 720, to: 960},
      {day: 6, from: 720, to: 960},
      {day: 7, from: 720, to: 960}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150457,
    status: 'new',
    name: 'Скидка 5%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-17T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150454,
    status: 'new',
    name: 'Скидка 5%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-24T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 720, to: 1200},
      {day: 2, from: 720, to: 1200},
      {day: 3, from: 720, to: 1200},
      {day: 4, from: 720, to: 1200},
      {day: 5, from: 720, to: 1200},
      {day: 6, from: 720, to: 1200},
      {day: 7, from: 720, to: 1200}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150445,
    status: 'new',
    name: 'Скидка 5%',
    description: 'На всё меню',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-04T06:00:00+00:00',
    ends_at: '2021-03-06T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150442,
    status: 'new',
    name: 'Скидка 5%',
    description: 'На ряд блюд',
    place_ids: [162],
    type: 'discount',
    starts_at: '2021-03-23T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150436,
    status: 'new',
    name: 'Блюдо в подарок',
    description: 'Круассан с сыром в подарок при заказе от 500₽ без учёта доставки',
    place_ids: [162],
    type: 'gift',
    starts_at: '2021-03-23T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150433,
    status: 'new',
    name: 'Блюдо в подарок',
    description: 'Тартар из телятины в подарок при заказе от 500₽ без учёта доставки',
    place_ids: [162],
    type: 'gift',
    starts_at: '2021-03-23T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150430,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких бпюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150427,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по Բторому такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150424,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {day: 1, from: 0, to: 1440},
      {day: 2, from: 0, to: 1440},
      {day: 3, from: 0, to: 1440},
      {day: 4, from: 0, to: 1440},
      {day: 5, from: 0, to: 1440},
      {day: 6, from: 0, to: 1440},
      {day: 7, from: 0, to: 1440}
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150424,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {
        day: 1,
        from: 0,
        to: 1440
      },
      {
        day: 2,
        from: 0,
        to: 1440
      },
      {
        day: 3,
        from: 0,
        to: 1440
      },
      {
        day: 4,
        from: 0,
        to: 1440
      },
      {
        day: 5,
        from: 0,
        to: 1440
      },
      {
        day: 6,
        from: 0,
        to: 1440
      },
      {
        day: 7,
        from: 0,
        to: 1440
      }
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150421,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {
        day: 1,
        from: 0,
        to: 1440
      },
      {
        day: 2,
        from: 0,
        to: 1440
      },
      {
        day: 3,
        from: 0,
        to: 1440
      },
      {
        day: 4,
        from: 0,
        to: 1440
      },
      {
        day: 5,
        from: 0,
        to: 1440
      },
      {
        day: 6,
        from: 0,
        to: 1440
      },
      {
        day: 7,
        from: 0,
        to: 1440
      }
    ],
    requirements: [],
    bonuses: []
  },
  {
    id: 150418,
    status: 'new',
    name: 'Два по цене одного',
    description: 'При заказе одного или нескольких блюд по акции — по второму такому же бесплатно',
    place_ids: [162],
    type: 'one_plus_one',
    starts_at: '2021-03-22T06:00:00+00:00',
    ends_at: '2021-03-31T06:00:00+00:00',
    schedule: [
      {
        day: 1,
        from: 0,
        to: 1440
      },
      {
        day: 2,
        from: 0,
        to: 1440
      },
      {
        day: 3,
        from: 0,
        to: 1440
      },
      {
        day: 4,
        from: 0,
        to: 1440
      },
      {
        day: 5,
        from: 0,
        to: 1440
      },
      {
        day: 6,
        from: 0,
        to: 1440
      },
      {
        day: 7,
        from: 0,
        to: 1440
      }
    ],
    requirements: [],
    bonuses: []
  }
] as VendorApi['/4.0/restapp-front/promo/v1/promo/list']['POST']['response']['payload']['promos']

type Params = VendorApi['/4.0/restapp-front/promo/v1/promo/list']['POST']['query']

export function mockPromosListResponse({
  after,
  limit = 12
}: Params): VendorApi['/4.0/restapp-front/promo/v1/promo/list']['POST']['response'] {
  const from = after ? PROMOS_MOCK.findIndex(({id}) => id === after) : 0
  const to = from + limit
  const fetchedPromos = PROMOS_MOCK.slice(from, to)
  const nextCursor = PROMOS_MOCK[to]?.id

  return {
    payload: {
      promos: fetchedPromos
    },
    meta: {fetched: fetchedPromos.length, total: PROMOS_MOCK.length, cursor: nextCursor}
  }
}
