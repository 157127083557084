import type {TariffInfo, FixCostRules} from '@restapp/shared-api'

interface costProps {
  additionalCommission?: string
  fixCost?: string
  arrayOfCost: string[]
}

const getCost = (
  lang: string,
  fixCostRules?: FixCostRules,
  additionalCommission?: number,
  currencySign?: string
): costProps => {
  const tariffs: costProps = {additionalCommission: undefined, fixCost: undefined, arrayOfCost: []}

  if (additionalCommission) {
    const additionalCommissionFormatted = `${new Intl.NumberFormat(lang, {style: 'decimal'}).format(
      additionalCommission
    )}%`
    tariffs.additionalCommission = additionalCommissionFormatted
    tariffs.arrayOfCost.push(additionalCommissionFormatted)
  }

  if (fixCostRules) {
    let fixCostFormatted = `${new Intl.NumberFormat(lang, {style: 'decimal'}).format(fixCostRules.bundle_cost)}`
    if (currencySign) {
      fixCostFormatted += currencySign
    }
    tariffs.fixCost = fixCostFormatted
    tariffs.arrayOfCost.push(fixCostFormatted)
  }

  return tariffs
}

export const getTariffCost = (tariffInfo: TariffInfo, lang: string, isDiscount?: boolean, isTrial?: boolean) => {
  if (isTrial && tariffInfo.currency?.sign) {
    return {arrayOfCost: []}
  }

  if (isDiscount && tariffInfo.discount) {
    return getCost(
      lang,
      tariffInfo.discount.fix_cost_rules,
      tariffInfo.discount.additional_commission,
      tariffInfo.currency?.sign
    )
  }

  return getCost(lang, tariffInfo.fix_cost_rules, tariffInfo.additional_commission, tariffInfo.currency?.sign)
}
