import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Modal, useIsMobileStrict} from '@eda-restapp/ui'
import React from 'react'

import {GlobalAlert} from '@restapp/shared/components'
import type {LinkedAccount} from '@restapp/shared-api/types/passport'

import type {ExternalAccountSuggest} from '../../../../../types'

import {NewFeaturesContent} from './NewFeaturesContent'
import styles from './PassportFullScreen.module.css'
import {WithLinkedAccountsContent} from './WithLinkedAccountsContent'

export type PassportFullScreenProps = {
  open: boolean
  accounts: LinkedAccount[]
  closeDelay?: number
  maxLinkedAccountLimitReached?: boolean
  suggestedAccount?: ExternalAccountSuggest
  hasLinkedAccounts?: boolean
  onClose(): void
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
  onAuthWithLinkedAccount(account: LinkedAccount): void
}

export const PassportFullScreen = ({
  open,
  onClose,
  closeDelay,
  hasLinkedAccounts,
  accounts,
  maxLinkedAccountLimitReached,
  suggestedAccount,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onAuthWithLinkedAccount
}: PassportFullScreenProps) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  if (!open) {
    return null
  }

  const handleBindSuggestedAccount = () => {
    eventLogger({name: 'passport:fullscreen:click_bind_suggested_account'})
    onBindSuggestedAccount()
  }

  const handleBindOtherAccount = () => {
    if (suggestedAccount) {
      eventLogger({name: 'passport:fullscreen:click_bind_other_account'})
    } else {
      eventLogger({name: 'passport:fullscreen:click_bind'})
    }

    onBindOtherAccount()
  }

  return (
    <div className={styles.overlay}>
      <GlobalAlert
        backgroundColor='var(--theme-error)'
        text={t(
          'core_auth.passport.passport-full-screen.alert',
          'Без Яндекс ID доступ будет ограничен – вы сможете только принимать заказы'
        )}
        textColor='var(--theme-textInvert)'
        className={styles.alert}
      />
      <div className={styles.modalContainer}>
        <Modal
          open
          onClose={Number(closeDelay) > 0 ? () => {} : onClose}
          disablePortal
          portalClassName={styles.modalDialogRoot}
          disableBackdrop
          className={styles.modalBody}
          enableCross={!isMobile || Number(closeDelay) === 0}
        >
          {hasLinkedAccounts ? (
            <WithLinkedAccountsContent
              closeDelay={closeDelay}
              accounts={accounts}
              maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
              suggestedAccount={suggestedAccount}
              onBindOtherAccount={handleBindOtherAccount}
              onBindSuggestedAccount={handleBindSuggestedAccount}
              onAuthWithLinkedAccount={onAuthWithLinkedAccount}
              onClose={onClose}
            />
          ) : (
            <NewFeaturesContent
              closeDelay={closeDelay}
              suggestedAccount={suggestedAccount}
              onBindOtherAccount={handleBindOtherAccount}
              onBindSuggestedAccount={handleBindSuggestedAccount}
              onClose={onClose}
            />
          )}
        </Modal>
      </div>
    </div>
  )
}
