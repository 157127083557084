import {makeStyles} from 'tss-react/mui'

import {S} from '../style'

export default makeStyles({name: 'BackButtonStyles'})({
  backButton: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-16px',
    padding: '16px',
    textDecoration: 'none',
    color: 'inherit',
    fontSize: '14px',
    lineHeight: '18px',
    cursor: 'pointer',

    [S.smallMobileQuery]: {
      margin: '0 0 0 -14px',
      padding: '16px 8px 16px 16px'
    }
  }
})
