import {useApiQuery} from '@restapp/core-api'
import type {YaPlusStatusResponse} from '@restapp/shared-api'

interface useYaPlusStatusOptions<TSelectedData> {
  enabled?: boolean
  suspense?: boolean
  select?: (data: YaPlusStatusResponse) => TSelectedData
  refetchInterval?: number
}

export function useYaPlusStatus<TSelectedData = YaPlusStatusResponse>(
  placeIds: number[],
  options?: useYaPlusStatusOptions<TSelectedData>
) {
  return useApiQuery({
    url: '/4.0/restapp-front/promo/v1/plus/status',
    method: 'POST',
    body: {
      place_ids: placeIds
    },
    ...options
  })
}
