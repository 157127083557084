import {Badge} from '@eda-restapp/ui'
import React from 'react'
import type {FC} from 'react'

import {useBadgeOptionsByStatus} from '../../hooks/useBadgeOptionsByStatus'

export type MultiChatStatusBadgeProps = {
  status?: 'open' | 'solved' | 'pending'
  className?: string
}

export const MultiChatStatusBadge: FC<MultiChatStatusBadgeProps> = ({status, className}) => {
  const options = useBadgeOptionsByStatus(status)

  if (!options) {
    return
  }

  return (
    <Badge
      className={className}
      rounded={false}
      textColor='invert'
      text={options?.text}
      background={options.color}
      style={{minWidth: 'fit-content'}}
    />
  )
}
