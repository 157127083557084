import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Box, CalendarIcon, ChevronDownMIcon, LockMiniIcon} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'
import compose from 'recompose/compose'

import {
  DatePeriodModal,
  type PeriodTypeModal
} from '@restapp/core-legacy/common/portals/modals/DatePeriodModal/DatePeriodModal'
import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'

import type {DayRangeProps} from '../../types'

import styles from './DayRange.module.css'

type InnerProps = DayRangeProps & WithPortalProps

/**
 * Компонент DayRange
 * формирует селектор выбора периода дат DD MMMM YYYY -> DD MMMM YYYY
 */
const DayRange: React.FC<InnerProps> = (props) => {
  const {t} = useI18n()
  const {
    className,
    CustomDatePeriodModal,
    from,
    to,
    min,
    max,
    initialMonth,
    disabled,
    canChangeMonth,
    fixedStart = false,
    isCalendarIcon = false,
    disabledDays,
    slug,
    onBlur,
    onFromChange,
    onToChange,
    onChange,
    openPortal
  } = props
  const onChangeWithTracker = (range: {to: Date | undefined; from: Date | undefined}) => {
    eventLogger({
      name: 'day_range_change',
      value: slug,
      additional: {
        to: range.to?.toISOString(),
        from: range.from?.toISOString()
      }
    })
    onChange?.(range)
  }
  const isValidDate = from && to ? to.toISOString() >= from.toISOString() : true

  const openDatePeriodModal = async () => {
    if (disabled) {
      return
    }

    const result = await openPortal<PeriodTypeModal>(
      CustomDatePeriodModal ? (
        <CustomDatePeriodModal
          from={from}
          to={to}
          min={min}
          max={max}
          initialMonth={initialMonth}
          canChangeMonth={canChangeMonth}
        />
      ) : (
        // @ts-ignore
        <DatePeriodModal
          from={from}
          to={to}
          min={min}
          max={max}
          initialMonth={initialMonth}
          fixedStart={fixedStart}
          disabledDays={disabledDays}
        />
      ),
      {
        closeOnBack: false
      }
    ).closed

    onBlur?.()

    if (result) {
      if (onFromChange && onToChange) {
        onFromChange(result.from)
        onToChange(result.to)
      }

      onChangeWithTracker({to: result.to, from: result.from})
    }

    return
  }

  return (
    <Box
      className={cn(styles.root, disabled && styles.disabled, className)}
      alignItems='center'
      justifyContent='flex-start'
      onClick={openDatePeriodModal}
      data-testid={'schedule-day-range-picker' /*График работы | Кнопка "Выберите дни"*/}
    >
      {isCalendarIcon && <CalendarIcon className={styles.calendar} />}
      {!from || !to
        ? t('shared-ui.day-range.viberite-dni', 'Выберите дни')
        : `${moment(from).format('DD.MM.YYYY')} — ${moment(to).format('DD.MM.YYYY')}`}
      {disabled ? <LockMiniIcon className={styles.lock} /> : <ChevronDownMIcon className={styles.expand} />}
      {!isValidDate && (
        <div className={styles.error}>
          {t('shared-ui.day-range.nevalidnoe-znachenie-perioda', 'Невалидное значение периода')}
        </div>
      )}
    </Box>
  )
}

export default compose<InnerProps, DayRangeProps>(withPortals)(DayRange)
