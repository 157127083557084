import React from 'react'
import {withStyles} from 'tss-react/mui'

import {S} from '../style'
import type {CloseCallback, PortalComponentProps} from '@restapp/core-legacy/hocs/withPortals'
import Modal from './EdaModalDeprecated'

type ChildrenProps = {close: CloseCallback}
type OuterProps = {
  className?: string
  classes?: Partial<Record<keyof typeof styles, string>>
} & {
  outsideClose?: boolean
  children: React.ReactNode | ((props: ChildrenProps) => React.ReactNode)
}

class UIModal<T = void> extends React.Component<OuterProps & PortalComponentProps<T>> {
  render() {
    const {classes: c = {}, children, outsideClose = false, ...props} = this.props

    return (
      <Modal {...props} classes={{content: c.content, outsideArea: !outsideClose ? c.disableOutsideArea : ''}}>
        {children}
      </Modal>
    )
  }
}

const styles = {
  disableOutsideArea: {
    pointerEvents: 'none'
  },
  root: {
    padding: 0,
    background: '#ffffff',
    borderRadius: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    pointerEvents: 'auto'
  },
  content: {
    [S.mobileQuery]: {
      width: '100%',
      padding: 0,
      background: '#ffffff'
    }
  }
} as const

export default withStyles(UIModal, styles)
