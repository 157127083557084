import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'
import {createHeaders} from '@restapp/shared/utils/createAuthHeaders'

import {selectOauthToken} from '../../../../selectors'

export const useWebPushUnsubscription = () => {
  const webPushConfig = useExp3('restapp_webpush')
  const oauth = useSelector(selectOauthToken)

  const unsubscribeMutation = useApiMutation(
    {
      method: 'POST',
      url: webPushConfig.newApiEnabled
        ? '/4.0/restapp-front/communications/v1/notify/unsubscribe'
        : '/4.0/restapp-front/notify/v1/unsubscribe'
    },
    {
      onError: () => {
        // do not show snackbar
      }
    }
  )

  const unsubscribe = useCallback(
    async ({partnerId, token}: {partnerId: string | null; token: string | null}) => {
      eventLogger({
        name: 'webpush:unsubscribe_passport-binding',
        value: 'native',
        additional: {partnerId}
      })

      await unsubscribeMutation.mutateAsync({
        body: {
          channel: {name: 'webpush'},
          ...(webPushConfig.newApiEnabled
            ? {
                app_install_id: VERSION,
                client_id: partnerId || undefined
              }
            : {
                service: 'eats-partner',
                client: {app_install_id: VERSION, partner_uid: partnerId || undefined}
              })
        },
        ...createHeaders(token, oauth, partnerId)
      })

      unsubscribeMutation.reset()
    },
    [webPushConfig.newApiEnabled, oauth, unsubscribeMutation]
  )

  return {
    unsubscribe
  }
}
