import {useI18n} from '@eda-restapp/i18n'
import React, {type FC} from 'react'

import {Dialog} from '@restapp/shared-modals'

type BusyModeUnavailableProps = {
  open: boolean
  cookingMinutes?: string
  onClose: () => void
}

export const BusyModeUnavailable: FC<BusyModeUnavailableProps> = ({open, cookingMinutes, onClose}) => {
  const {t} = useI18n()

  return (
    <Dialog
      slug='busy_mode_unavailable_dialog'
      visible={open}
      title={t('main-orders.busy-mode-modal.cannot-enable-title', 'У вас максимальное время готовки')}
      progress={false}
      cancelText={t('main-orders.busy-mode-modal.cannot-enable-ok', 'Понятно')}
      onConfirm={onClose}
      onClose={onClose}
    >
      {t(
        'main-orders.busy-mode-modal.cannot-enable-description',
        'Ваше время готовки — {cookingDuration}, увеличить его не получится. Если этого времени не хватает, лучше отключить ресторан.',
        {
          cookingDuration: cookingMinutes
        }
      )}
    </Dialog>
  )
}
