/**
 * Вывод валют под общий формат
 * @example
 * formatCurrency(1234.56, 'RUB', 'ru')> // 1234,56 ₽
 */
export function formatCurrency(
  value: number | bigint,
  code: string,
  lang?: string,
  option?: Intl.NumberFormatOptions & {notation?: string}
) {
  return new Intl.NumberFormat([lang || 'ru', 'en'], {
    style: 'currency',
    currency: code,
    useGrouping: false,
    minimumFractionDigits: 0,
    ...option
  }).format(value)
}
