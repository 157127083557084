import {useCallback} from 'react'
import {useSelector} from 'react-redux'

import {isNativeVendor} from '@restapp/shared'

import {selectPartnerId, selectToken} from '../../../../selectors'

import {useNativePushUnsubscription} from './useNativePushSubscription'
import {useWebPushUnsubscription} from './useWebPushUnsubscription'

export const usePushUnsubscription = () => {
  const partnerId = useSelector(selectPartnerId)
  const token = useSelector(selectToken)

  const {unsubscribe: webPushUnsubscribe} = useWebPushUnsubscription()
  const {unsubscribe: nativePushUnsubscribe} = useNativePushUnsubscription()

  const unsubscribe = useCallback(async () => {
    const finalUnsubscribeFn = isNativeVendor ? nativePushUnsubscribe : webPushUnsubscribe

    await finalUnsubscribeFn({partnerId, token})
  }, [nativePushUnsubscribe, partnerId, token, webPushUnsubscribe])

  return {
    unsubscribe
  }
}
