import {combineReducers, createReducer, createSlice} from '@reduxjs/toolkit'

import * as commonActions from './actions'
import {MENU_SLICE_NAME} from './constants'
import * as commonSelectors from './selectors'
import {
  CategoriesSlice,
  GroupsSlice,
  ItemsSlice,
  OptionsSlice,
  ModerationSlice,
  CategoriesSelectors,
  GroupsSelectors,
  ItemsSelectors,
  ModerationSelectors,
  OptionsSelectors
} from './slice'
import type {GroupVirtualId, MenuSliceState} from './types'

export {getGroupVirtualId, getOptionVirtualId} from './utils'

export * as hooks from './hooks'

export const selectors = {
  common: commonSelectors,
  categories: CategoriesSelectors,
  groups: GroupsSelectors,
  items: ItemsSelectors,
  options: OptionsSelectors,
  moderation: ModerationSelectors
}

export const actions = {
  common: commonActions,
  categories: CategoriesSlice.slice.actions,
  groups: GroupsSlice.slice.actions,
  items: ItemsSlice.slice.actions,
  options: OptionsSlice.slice.actions,
  moderation: ModerationSlice.slice.actions
}

export const slice = createSlice({
  name: MENU_SLICE_NAME,
  initialState: (): MenuSliceState => {
    return {
      revision: '',
      categories: CategoriesSlice.slice.getInitialState(),
      groups: GroupsSlice.slice.getInitialState(),
      items: ItemsSlice.slice.getInitialState(),
      options: OptionsSlice.slice.getInitialState(),
      moderation: ModerationSlice.slice.getInitialState()
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(commonActions.toggleOption, (state, action) => {
      // Отключаем блюдо если отключение опции нарушает правила группы
      // FIXME: После автоотключения позиции ее всеравно можно обратно включить галочкой
      // также было и на старом стейте - поэтому не стал исправлять пока
      if (!action.payload.value) {
        const option = state.options.current.entities[action.payload.id]
        const group = state.groups.current.entities[option?._groupId || ('' as GroupVirtualId)]
        const item = state.items.current.entities[option?._itemId || '']

        if (option && group && item) {
          if (group.minSelectedModifiers > 0) {
            // Получаем кол-во включенных опций в группе
            const allEnabledOptionsInGroupCount =
              state.options.joinByGroup[option._groupId]?.filter((optionId) => {
                const optionEntity = state.options.current.entities[optionId]
                return optionEntity?.available || false
              }).length || 0

            // Если после отключения этой опции мы нарушим правило группы - отключаем весь item
            if (allEnabledOptionsInGroupCount - 1 < group.minSelectedModifiers) {
              item.available = false
              item.reactivatedAt = null
            }
          }
        }
      }
    })

    builder.addCase(commonActions.toggleManyOption, (state, action) => {
      for (const optionUpdate of action.payload) {
        const option = state.options.current.entities[optionUpdate.id]

        if (option) {
          if (!optionUpdate.value) {
            const group = state.groups.current.entities[option._groupId]
            const item = state.items.current.entities[option._itemId]

            if (group && item) {
              if (group.minSelectedModifiers > 0) {
                // Получаем кол-во включенных опций в группе
                const allEnabledOptionsInGroupCount =
                  state.options.joinByGroup[option._groupId]?.filter((optionId) => {
                    const optionEntity = state.options.current.entities[optionId]
                    return optionEntity?.available || false
                  }).length || 0

                // Если после отключения этой опции мы нарушим правило группы - отключаем весь item
                if (allEnabledOptionsInGroupCount - 1 < group.minSelectedModifiers) {
                  item.available = false
                  item.reactivatedAt = null
                }
              }
            }
          }

          option.available = optionUpdate.value
          option.reactivatedAt = optionUpdate.reactivatedAd
        }
      }
    })

    builder.addMatcher(
      () => true,
      combineReducers({
        revision: createReducer('', (builder) =>
          builder.addCase(commonActions.setMenuRevision, (state, action) => action.payload)
        ),
        categories: CategoriesSlice.slice.reducer,
        groups: GroupsSlice.slice.reducer,
        items: ItemsSlice.slice.reducer,
        options: OptionsSlice.slice.reducer,
        moderation: ModerationSlice.slice.reducer
      })
    )
  }
})
