import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'

import {useApiQueryInvalidate} from '@restapp/core-api'
import {DesktopDevice, MobileDevice} from '@restapp/shared-ui/Device'

import ChatsHeaderDesktop from './ChatsHeaderDesktop'
import ChatsHeaderMobile from './ChatsHeaderMobile'

export type ChatsHeaderProps = {
  onCreateChat(restaurantId: number): void
}

function ChatsHeader({onCreateChat}: Omit<ChatsHeaderProps, 'filtersActions'>) {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')

  const chatListInvalidateQuery = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v1/chat/list', 'GET'])
  const chatListInvalidateV2Query = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v2/chat/list', 'POST'])
  const notificationCountInvalidateQuery = useApiQueryInvalidate([
    '/4.0/restapp-front/support_chat/v1/partner/notifications/count',
    'GET'
  ])

  const refreshHandler = () => {
    metrika({target: 'chats_refresh_click'})

    return Promise.allSettled([
      notificationCountInvalidateQuery.invalidateAsync(),
      supportMultiChatConfig.enableUnifiedChatsListEndpoint
        ? chatListInvalidateV2Query.invalidateAsync()
        : chatListInvalidateQuery.invalidateAsync()
    ])
  }

  return (
    <>
      <DesktopDevice>
        <ChatsHeaderDesktop onCreateChat={onCreateChat} onRefresh={refreshHandler} />
      </DesktopDevice>
      <MobileDevice>
        <ChatsHeaderMobile onCreateChat={onCreateChat} onRefresh={refreshHandler} />
      </MobileDevice>
    </>
  )
}

export default ChatsHeader
