import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Informer, Modal, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import React, {type FC, type Dispatch} from 'react'

import type {FullRestaurant, PlaceDisablingReasonCode, ShortRestaurant} from '@restapp/shared-api'

import type {PlaceDisableParams} from '../../DisablePlaceModal.types'

import {DisablePlaceSettings} from './components/DisablePlaceSettings/DisablePlaceSettings'
import styles from './DisablePlaceWithOrdersModal.module.css'

export type DisablePlaceWithOrdersModalProps = {
  canCancelOrders: boolean
  disablePeriodDuration: number | null
  disableReasonCode: PlaceDisablingReasonCode | null
  disableReasons?: PlaceDisableParams[]
  isCancelOrdersButtonLoading: boolean
  isFastFood: boolean
  isNotCancelOrdersButtonLoading: boolean
  isNotSelectedAllDisableSettings: boolean
  placeAddress: ShortRestaurant['address']
  placeCountryCode: FullRestaurant['country_code']
  placeName: ShortRestaurant['name']
  placeTimezone: string
  placeType: ShortRestaurant['type']
  handleDisablingPlace: (shouldCancelOrders?: boolean) => void
  handlePeriodChange: (reasonCode: PlaceDisablingReasonCode) => void
  onCloseClick: () => void
  setCustomDisableDuration: Dispatch<React.SetStateAction<moment.Moment | null>>
  setDisablePeriodDuration: Dispatch<React.SetStateAction<number | null>>
}

export const DisablePlaceWithOrdersModal: FC<DisablePlaceWithOrdersModalProps> = ({
  canCancelOrders,
  disablePeriodDuration,
  disableReasonCode,
  disableReasons,
  isCancelOrdersButtonLoading,
  isFastFood,
  isNotCancelOrdersButtonLoading,
  isNotSelectedAllDisableSettings,
  placeAddress,
  placeCountryCode,
  placeName,
  placeTimezone,
  placeType,
  handleDisablingPlace,
  handlePeriodChange,
  onCloseClick,
  setCustomDisableDuration,
  setDisablePeriodDuration
}) => {
  const isMobile = useIsMobileStrict()
  const {t, tRaw} = useI18n()

  const isDisablingAutostopPossible = disableReasons?.find((disableParams) => disableParams.value === disableReasonCode)
    ?.autostop?.autostop_possible

  const headerLabel = canCancelOrders
    ? t(
        'core-legacy.disable-place-modal.modal-header-for-available-orders-cancellation',
        'Отключим ресторан. Приготовите заказы?'
      )
    : t(
        'core-legacy.disable-place-modal.modal-header-for-disabled-orders-cancellation',
        'Отключим ресторан, но заказы нужно приготовить'
      )

  const renderContentWarning = () => {
    const contentWarning: JSX.Element[] = []

    if (placeCountryCode !== 'RU') {
      return t(
        'core-legacy.disable-place-modal.content-warning-panel-label-for-international',
        'У вас есть активные заказы. Вы можете приготовить заказы и отключить ресторан. Новые заказы при этом приходить не будут. При необходимости обратитесь в поддержку.'
      )
    }

    if (!canCancelOrders) {
      contentWarning.push(
        ...tRaw(
          'core-legacy.disable-place-modal.content-warning-panel-label-for-disabled-cancellation',
          'У вас есть заказы в очереди на готовку. Они появятся в разделе «Заказы», как только курьер подойдёт к ресторану.{additionalText}',
          {
            additionalText: (
              <Box className={styles.contentWarningOptionsBlock}>
                {t(
                  'core-legacy.disable-place-modal.content-warning-panel-additional-text-for-disabled-cancellation',
                  'Отключим ресторан — новые заказы приходить не будут'
                )}
              </Box>
            )
          }
        )
      )
    }

    if (canCancelOrders && isFastFood) {
      contentWarning.push(
        ...tRaw(
          'core-legacy.disable-place-modal.content-warning-panel-label-with-cancellation',
          'У вас есть заказы в очереди на готовку. Они появятся в разделе «Заказы», как только курьер подойдёт к ресторану.{optionsList}',
          {
            optionsList: (
              <Box className={styles.contentWarningOptionsBlock} flexDirection='column'>
                {t(
                  'core-legacy.disable-place-modal.content-warning-panel-options-list-label-with-cancellation',
                  'Чтобы всё успеть, распределите нагрузку на кухню. Вы можете:'
                )}
                <Box flexDirection='column' gap='xs' mt='s'>
                  <Box>
                    {t(
                      'core-legacy.disable-place-modal.content-warning-panel-list-with-cancellation-option-1',
                      '・ Приготовить заказы и отключить ресторан. Новые заказы при этом приходить не будут'
                    )}
                  </Box>
                  <Box>
                    {t(
                      'core-legacy.disable-place-modal.content-warning-panel-list-with-cancellation-option-2',
                      '・ Отменить их и отключить — удержим плату за услуги сервиса и можем на час скрыть ресторан'
                    )}
                  </Box>
                </Box>
              </Box>
            )
          }
        )
      )
    }

    if (canCancelOrders && !isFastFood) {
      contentWarning.push(
        ...tRaw(
          'core-legacy.disable-place-modal.content-warning-panel-label-for-active-orders-existing',
          'У вас есть активные заказы.{optionsList}',
          {
            optionsList: (
              <Box className={styles.contentWarningOptionsBlock} flexDirection='column'>
                {t(
                  'core-legacy.disable-place-modal.content-warning-panel-options-list-label-for-active-orders-existing',
                  'Вы можете:'
                )}
                <Box flexDirection='column' gap='xs' mt='s'>
                  <Box>
                    {t(
                      'core-legacy.disable-place-modal.content-warning-panel-list-for-active-orders-existing-option-1',
                      '・ Приготовить заказы и отключить ресторан. Новые заказы при этом приходить не будут'
                    )}
                  </Box>
                  <Box>
                    {t(
                      'core-legacy.disable-place-modal.content-warning-panel-list-for-active-orders-existing-option-2',
                      '・ Отменить заказы и отключить ресторан — тогда удержим плату за услуги сервиса'
                    )}
                  </Box>
                </Box>
              </Box>
            )
          }
        )
      )
    }

    isDisablingAutostopPossible &&
      contentWarning.push(
        <Box className={styles.contentWarningOptionsBlock}>
          {t(
            'core-legacy.disable-place-modal.content-warning-panel-label-about-autostop',
            'Будьте внимательны, возможно вы попадете в автостоп'
          )}
        </Box>
      )

    return contentWarning
  }

  return (
    <Modal open onClose={onCloseClick}>
      <Modal.Header title={headerLabel} />
      <Modal.Content>
        <Box flexDirection='column' gap='m' pt='s'>
          <Informer type='warn'>
            <Typography variant='body2'>
              <Box
                data-testid={
                  'disable-place-modal-content-warning' /* Disable Place With Orders Modal | Информер о существующих заказах */
                }
                flexDirection='column'
              >
                {renderContentWarning()}
              </Box>
            </Typography>
          </Informer>

          <Box flexDirection='column' gap='l'>
            <Box flexDirection='column' gap='xs'>
              <Typography
                data-testid={
                  'disable-place-modal-place-info' /* Disable Place With Orders Modal | Информация о ресторане */
                }
                variant='body2'
              >{`${placeName} - ${placeAddress}`}</Typography>
              <Typography
                data-testid={
                  'disable-place-modal-delivery-hint-text' /* Disable Place With Orders Modal | Информация о доставке */
                }
                color='minor'
                variant='caption1'
              >
                {placeType === 'native'
                  ? t('core-legacy.disable-place-modal.content-native-place-delivery-hint-text', 'Доставка сервисом')
                  : t('core-legacy.disable-place-modal.content-marketplace-delivery-hint-text', 'Доставка ресторана')}
              </Typography>
            </Box>

            <DisablePlaceSettings
              disablingParams={disableReasons}
              disablePeriodDuration={disablePeriodDuration}
              disableReasonCode={disableReasonCode}
              placeTimezone={placeTimezone}
              onChangeCustomDisabledPeriodDuration={setCustomDisableDuration}
              onChangeDisablePeriodDuration={setDisablePeriodDuration}
              onChangeDisableReasonCode={handlePeriodChange}
            />
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 's' : 'm'} mt={isMobile ? undefined : 'm'}>
          {canCancelOrders && (
            <Button.Text
              data-testid={
                'disable-place-modal-cancel-orders-button' /* Disable Place With Orders Modal | Кнопка отменить и отключить */
              }
              disabled={isNotCancelOrdersButtonLoading || isNotSelectedAllDisableSettings}
              fullWidth
              loading={isCancelOrdersButtonLoading}
              size='l'
              variant='secondary'
              onClick={() => handleDisablingPlace(true)}
            >
              {t('core-legacy.disable-place-modal.cancel-footer-button-text', 'Отменить и отключить')}
            </Button.Text>
          )}
          <Button.Text
            data-testid={
              'disable-place-modal-prepare-orders-button' /* Disable Place With Orders Modal | Кнопка приготовить и отключить */
            }
            disabled={isCancelOrdersButtonLoading || isNotSelectedAllDisableSettings}
            fullWidth
            loading={isNotCancelOrdersButtonLoading}
            size='l'
            onClick={() => handleDisablingPlace()}
          >
            {t('core-legacy.disable-place-modal.action-footer-button-text', 'Приготовить и отключить')}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}
