import {combineReducers} from '@reduxjs/toolkit'

import {communicationsNewsReducer} from './newsSlice'
import {communicationsPoolReducer} from './poolSlice'
import {communicationsSurveyReducer} from './surveySlice'
import {communicationsWidgetReducer} from './widgetSlice'

export const communicationsReducer = combineReducers({
  widget: communicationsWidgetReducer,
  survey: communicationsSurveyReducer,
  pool: communicationsPoolReducer,
  news: communicationsNewsReducer
})

export const COMMUNICATIONS_REDUCER_KEY = 'communications'
