import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'
import React from 'react'

import {Link} from '@restapp/shared-ui'

import type {SubscriptionOnSave} from '../../../types'

export const useSubscriptionSuccessDialog = (tariffType: SubscriptionOnSave | null, onClose?: () => void) => {
  const {t, tRaw} = useI18n()

  const headerTitles: {
    [key: string]: (
      fromTariff?: SubscriptionOnSave['fromTariff'],
      fromTrialTariff?: SubscriptionOnSave['fromTrialTariff']
    ) => string
  } = {
    default: () => t('shared-places.use-subscription-success-dialog.header-title-default', 'Подписка подключена'),
    free: (fromTariff, fromTrialTariff) => {
      if (fromTrialTariff && fromTrialTariff.type === fromTariff) {
        return t('shared-places.use-subscription-success-dialog.header-title-free', 'Подписка отключится {date}', {
          date: moment(fromTrialTariff.validUntilIso).format('D MMMM')
        })
      }

      return t(
        'shared-places.use-subscription-success-dialog.subscribition-disable-tomorrow',
        'Подписка отключится завтра'
      )
    },
    business: (fromTariff, fromTrialTariff) => {
      if (fromTariff === 'business') {
        return t(
          'shared-places.use-subscription-success-dialog.header-title-business-to-business',
          'Подписка Стандарт продлена!'
        )
      }

      if (fromTrialTariff && fromTrialTariff.type === 'business_plus') {
        return t(
          'shared-places.use-subscription-success-dialog.header-title-business-plus-trial-to-business',
          'Подписка Стандарт включится {date}',
          {
            date: moment(fromTrialTariff.validUntilIso).format('D MMMM')
          }
        )
      }

      return t(
        'shared-places.use-subscription-success-dialog.header-title-business-plus-to-business',
        'Подписка Стандарт включится завтра'
      )
    },
    business_plus: (fromTariff) =>
      fromTariff === 'business_plus'
        ? t(
            'shared-places.use-subscription-success-dialog.header-title-business-plus-to-business-plus',
            'Подписка Бизнес продлена!'
          )
        : t(
            'shared-places.use-subscription-success-dialog.header-title-business-plus-enable-tomorrow',
            'Подписка Бизнес включится завтра'
          )
  }

  const descriptionCost: {[key: string]: (tariffCost?: SubscriptionOnSave['tariffCost']) => JSX.Element} = {
    default: () => (
      <>
        <p>
          {tRaw(
            'shared-places.use-subscription-success-dialog.description-settings-info',
            'Управлять можно в разделе {link}.',
            {
              link: (
                <Link to={'/tools/subscription'}>
                  {t('shared-places.use-subscription-success-dialog.link-text-subcription', '«Подписка»')}
                </Link>
              )
            }
          )}
        </p>
        <p>
          {t(
            'shared-places.use-subscription-success-dialog.description-business-info-2',
            'Если вдруг передумаете, сможете отключить подписку или поменять её тариф там же.'
          )}
        </p>
      </>
    ),
    business: (tariffCost) => (
      <>
        <p>
          {t(
            'shared-places.use-subscription-success-dialog.description-business-cost',
            'Стоимость подписки — {additionalCommission}% в месяц.',
            {
              additionalCommission: tariffCost?.additionalCommission
            }
          )}
        </p>
        <p>
          {tRaw(
            'shared-places.use-subscription-success-dialog.description-settings-info',
            'Управлять можно в разделе {link}.',
            {
              link: (
                <Link to={'/tools/subscription'} onClick={onClose}>
                  {t('shared-places.use-subscription-success-dialog.link-text-subcription', '«Подписка»')}
                </Link>
              )
            }
          )}
        </p>
      </>
    ),
    business_plus: (tariffCost) => (
      <>
        <p>
          {t(
            'shared-places.use-subscription-success-dialog.description-business-plus-cost',
            'Стоимость подписки — {additionalCommission}% + {bundleCost}{sign} в месяц за {bundleSize} точки и меньше + {extraCost}{sign} за каждую дополнительную.',
            {
              additionalCommission: tariffCost?.additionalCommission,
              bundleSize: tariffCost?.fixCostRules?.bundle_size,
              bundleCost: tariffCost?.fixCostRules?.bundle_cost,
              extraCost: tariffCost?.fixCostRules?.extra_cost,
              sign: tariffCost?.sign
            }
          )}
        </p>
        <p>
          {tRaw(
            'shared-places.use-subscription-success-dialog.description-settings-info',
            'Управлять можно в разделе {link}.',
            {
              link: (
                <Link to={'/tools/subscription'} onClick={onClose}>
                  {t('shared-places.use-subscription-success-dialog.link-text-subcription', '«Подписка»')}
                </Link>
              )
            }
          )}
        </p>
        <p>
          {tRaw(
            'shared-places.use-subscription-success-dialog.description-business-plus-tg-settings',
            'Настроить бот для личного менеджера можно в разделе {link} → вкладка Telegram-аккаунты.',
            {
              link: (
                <Link to={'/tools/subscription/bot'} onClick={onClose}>
                  {t('shared-places.use-subscription-success-dialog.link-text-subcription', '«Подписка»')}
                </Link>
              )
            }
          )}
        </p>
        {tariffType?.fromTariff === 'free' && (
          <p>
            {t(
              'shared-places.subscription-success-dialog.manager-communication-time-info',
              'Личный менеджер позвонит вам на следующий рабочий день после подключения подписки. Если вы подключили подписку в пятницу — свяжемся с вами в понедельник.'
            )}
          </p>
        )}
      </>
    )
  }

  const currentSuccessModal = {
    headerTitle:
      headerTitles[tariffType?.toTariff ?? 'default']?.(tariffType?.fromTariff, tariffType?.fromTrialTariff) ??
      headerTitles['default'](),
    text: descriptionCost[tariffType?.toTariff ?? 'default']?.(tariffType?.tariffCost) ?? descriptionCost['default'](),
    btnText:
      tariffType?.fromTrialTariff?.type === 'business_plus'
        ? t('shared-places.use-subscription-success-dialog.got-it', 'Хорошо')
        : t('shared-places.use-subscription-success-dialog.otlichno', 'Отлично')
  }

  return {
    currentSuccessModal,
    distinctTelegramButton: tariffType?.fromTariff !== 'free'
  }
}
