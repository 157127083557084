import type {UseQueryOptions} from 'react-query'

import type {ApiErrorResponse, VendorApi as Api} from '@restapp/shared-api'

import type {ApiDeps, ApiQueryConfig, ApiQueryKey, ApiResponse} from './types'

/**
 * @deprecated Временный костыль, до выпила MST. Не использовать напрямую!
 */
export default function createReactQueryOptions<
  TUrl extends keyof Api,
  TMethod extends keyof Api[TUrl],
  TSelectedData = ApiResponse<Api[TUrl][TMethod]>
>(
  config: Pick<ApiQueryConfig<TUrl, TMethod, TSelectedData>, 'url' | 'method' | 'params' | 'body' | 'headers'>,
  {request}: ApiDeps
) {
  const {url, method, params, body, headers} = config
  const queryKey = [url, method, params, body, headers] as unknown as ApiQueryKey<TUrl, TMethod>
  const queryFn: UseQueryOptions<
    ApiResponse<Api[TUrl][TMethod]>,
    ApiErrorResponse,
    TSelectedData,
    ApiQueryKey
  >['queryFn'] = ({queryKey: [url, method, params, body]}) => request({url, method, params, data: body, headers})

  return {
    queryKey,
    queryFn: queryFn as unknown as UseQueryOptions<
      ApiResponse<Api[TUrl][TMethod]>,
      ApiErrorResponse,
      TSelectedData,
      ApiQueryKey<TUrl, TMethod>
    >['queryFn']
  }
}
