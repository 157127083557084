import type {FC, PropsWithChildren} from 'react'
import {Provider} from 'react-redux'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

import {store} from './store'

const persistor = persistStore(store)

export const ReduxProvider: FC<PropsWithChildren> = ({children}) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  )
}
