import {makeStyles} from 'tss-react/mui'

const useAppStyles = makeStyles({name: 'App'})((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      left: 0,
      paddingBottom: 'env(safe-area-inset-bottom)',
      width: '100%',
      height: '100%',

      [theme.breakpoints.up('md')]: {
        paddingBottom: 'initial',
        background: '#ffffff'
      }
    },
    contentClass: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      height: '100%',
      backfaceVisibility: 'hidden',
      backgroundColor: '#fff',

      [theme.breakpoints.up('md')]: {
        backgroundColor: 'initial'
      }
    },
    offlineWarning: {
      top: 0,
      width: '100%',
      height: 20,
      lineHeight: '20px',
      fontSize: 16,
      color: '#ffffff',
      background: '#e85b34',
      textAlign: 'center',

      ['@media print']: {
        display: 'none'
      },

      [theme.breakpoints.up('md')]: {
        top: 'initial',
        height: 25,
        lineHeight: '25px',
        fontSize: 'initial'
      }
    }
  }
})

export default useAppStyles
