import type {FC, ReactNode} from 'react'

import {PassportBindingContainer} from './PassportBinding'
import {PassportEnabledGuard} from './PassportEnabledGuard'

type PassportMigrationContainerProps = {
  children: ReactNode
}
export const PassportMigrationContainer: FC<PassportMigrationContainerProps> = ({children}) => (
  <PassportBindingContainer>
    <PassportEnabledGuard>{children}</PassportEnabledGuard>
  </PassportBindingContainer>
)
