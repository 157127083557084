import {useI18n} from '@eda-restapp/i18n'

import type {PlaceBlock} from '@restapp/shared-api'

import {getBlockDateLabel} from '../helpers/getBlockDateLabel'

type UseBlockInfoElementLabelProps = {
  blockInfo?: PlaceBlock
  blockInfoId: string
  isBlockTypeInInfinityList: boolean
}

export const useBlockInfoElementLabel = ({
  blockInfo,
  blockInfoId,
  isBlockTypeInInfinityList
}: UseBlockInfoElementLabelProps) => {
  const {t} = useI18n()

  switch (blockInfoId) {
    case 'block-start-date':
      return {
        label: t('core-legacy.use-block-info-element-label.blocking-start-date-info-label', 'Начало блокировки'),
        data: blockInfo?.started_at ? getBlockDateLabel({date: blockInfo.started_at}) : '-'
      }

    case 'block-end-date': {
      const defaulDataLabel = isBlockTypeInInfinityList
        ? t(
            'core-legacy.use-block-info-element-label.blocking-end-date-info-infinity-block-label',
            'При устранении причины блокировки'
          )
        : '-'

      return {
        label: t('core-legacy.use-block-info-element-label.blocking-end-date-info-label', 'Конец блокировки'),
        data: blockInfo?.finished_at
          ? getBlockDateLabel({date: blockInfo.finished_at, isFinishDate: true})
          : defaulDataLabel
      }
    }

    case 'block-source':
      return {
        label: t('core-legacy.use-block-info-element-label.blocking-source-info-label', 'Кем установлена'),
        data: blockInfo?.source_text ?? '-'
      }

    case 'block-reason':
      return {
        label: t('core-legacy.use-block-info-element-label.blocking-reason-info-label', 'Причина блокировки'),
        data: blockInfo?.reason_text ?? '-'
      }

    default:
      return {
        label: '-',
        data: '-'
      }
  }
}
