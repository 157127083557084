import {makeStyles} from 'tss-react/mui'

const SelectStyles = {
  baseInput: {
    '&&': {
      padding: 0,
      borderRadius: '12px',
      height: 'fit-content'
    }
  },
  select: {
    '&&': {
      minHeight: '1.1876em',
      paddingRight: '40px!important',
      borderRadius: '12px',
      lineHeight: '1.1876em'
    }
  },
  padding: {
    paddingRight: '8px !important'
  }
}

const useStyles = makeStyles({name: 'Select'})(SelectStyles)

export default useStyles
