import moment from 'moment'
import {useEffect, useState, useCallback} from 'react'

export type CountdownOptions = {
  until: moment.Moment
  onFinish?: () => unknown
}

function useCountdown({until, onFinish}: CountdownOptions) {
  const calculateDuration = useCallback(() => {
    const diff = until.diff(moment())
    return moment.duration(diff < 0 ? 0 : diff)
  }, [until])

  const [duration, setDuration] = useState<moment.Duration>(calculateDuration)

  useEffect(() => {
    if (until.isBefore(moment())) {
      setDuration(moment.duration(0))
      return
    }

    const updateDuration = () => {
      const newDuration = calculateDuration()
      setDuration(newDuration)

      if (onFinish && newDuration.asSeconds() <= 0) {
        void onFinish()
      }
    }

    const timeout = setTimeout(updateDuration, 1000)

    return () => clearTimeout(timeout)
  }, [until, duration, onFinish, calculateDuration])

  return {duration, isCounting: duration.asSeconds() >= 1}
}

export default useCountdown
