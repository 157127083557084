import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Tooltip, Typography, useIsMobileStrict, UserAvatar, Modal} from '@eda-restapp/ui'
import type {ExternalAccountSuggest} from 'packages/core-auth/types'
import React, {useState} from 'react'

import {useServiceTitle} from '@restapp/shared'
import {type LinkedAccount} from '@restapp/shared-api/types/passport'

import {usePassportName} from '../../../../../hooks'
import {PassportIcon} from '../../../../PassportIcon'
import idIcon from '../../../PassportDrawer/PassportDrawerCards/images/idIcon.png'

import {LinkedAccounts} from './LinkedAccounts'
import styles from './ModalWithLinkedAccounts.module.css'

export type ModalWithLinkedAccountsProps = {
  onClose: () => void
  openDrawer: () => void
  accounts: LinkedAccount[]
  maxLinkedAccountLimitReached?: boolean
  suggestedAccount?: ExternalAccountSuggest
  onBindOtherAccount: () => void
  onBindSuggestedAccount: () => void
  onAuthWithLinkedAccount: (account: LinkedAccount) => void
}

export const ModalWithLinkedAccounts: React.FC<ModalWithLinkedAccountsProps> = ({
  accounts,
  suggestedAccount,
  onClose,
  openDrawer,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onAuthWithLinkedAccount,
  maxLinkedAccountLimitReached
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const isMobile = useIsMobileStrict()
  const {t} = useI18n()
  const passportName = usePassportName()
  const serviceTitle = useServiceTitle()

  const handleOpenDrawer = () => {
    onClose()
    openDrawer()
  }

  return (
    <>
      <Modal.Header
        title={
          isMobile
            ? t('core-auth.passport.modal-with-accounts.title-mobile', 'Используйте {domain}', {
                domain: passportName
              })
            : t('core-auth.passport.modal-with-accounts.title-desktop', 'Используйте {domain} для входа', {
                domain: passportName
              })
        }
      />
      <Modal.Content>
        <Box flexDirection={'column'} gap='m'>
          <Box py={'m'} pl={'m'} pr={'l'} className={styles.message}>
            <Box className={styles.messageImage}>
              <img src={idIcon} loading='lazy' />
            </Box>

            <Box flexDirection={'column'} gap={'s'}>
              <Box flexDirection='column' gap='xs'>
                <Typography variant={'caption1'} thickness={'medium'}>
                  {t(
                    'core-auth.passport.modal-with-accounts.new-features-title',
                    'Новые возможности с {passportName}',
                    {passportName}
                  )}
                </Typography>

                <Typography variant={'caption1'}>
                  {t(
                    'core-auth.passport.modal-with-accounts.new-features',
                    'Управление пользователями, один ID для разных личных кабинетов и безопасный вход по телефону – все это доступно с новой авторизацией'
                  )}
                </Typography>
              </Box>

              <a onClick={handleOpenDrawer} className={styles.linkButton}>
                <Typography variant='caption1'>
                  {t('core-auth.passport.modal-with-accounts.about-auth', 'О новой авторизации')}
                </Typography>
              </a>
            </Box>
          </Box>

          <Box flexDirection={'column'} gap={'s'}>
            <Typography variant={'body2'} thickness={'medium'}>
              {t('core-auth.passport.modal-with-accounts.linked-accounts', 'Привязанные аккаунты')}
            </Typography>

            <Typography variant={'body2'}>
              {t(
                'core-auth.passport.modal-with-accounts.about-linked-accounts',
                'Вы авторизовались в этом личном кабинете {serviceTitle} с этими аккаунтами. Войдите через один из них или привяжите другой аккаунт',
                {
                  serviceTitle
                }
              )}
            </Typography>
          </Box>

          <Box flexDirection='column'>
            <LinkedAccounts onAuthWithLinkedAccount={onAuthWithLinkedAccount} accounts={accounts} />
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box gap={isMobile ? 's' : 'm'} className={styles.buttons} flexDirection={isMobile ? 'column' : 'row'}>
          {suggestedAccount && !maxLinkedAccountLimitReached ? (
            <>
              <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
                {t('core-auth.passport.modal-with-linked-accounts.other-account', 'Выбрать другой аккаунт')}
              </Button.Text>

              <Button.MultiSpace
                variant='dark'
                startIcon={<PassportIcon />}
                endIcon={<UserAvatar className={styles.round} src={suggestedAccount?.avatar} />}
                onClick={onBindSuggestedAccount}
                fullWidth
              >
                {t('core-auth.passport.modal-with-new-features.login-with-suggested-account', 'Привязать {login}', {
                  login: suggestedAccount?.login
                })}
              </Button.MultiSpace>
            </>
          ) : (
            <>
              {anchorEl && maxLinkedAccountLimitReached && (
                <Tooltip.Info anchorEl={anchorEl} placement='top'>
                  <Typography variant={'caption1'} thickness={'medium'}>
                    {t(
                      'core-auth.passport.modal-with-accounts.too-much-accounts-linked',
                      'Уже привязано максимальное количество аккаунтов'
                    )}
                  </Typography>
                </Tooltip.Info>
              )}

              <Button.MultiCenter
                ref={setAnchorEl}
                variant='dark'
                startIcon={<PassportIcon style={maxLinkedAccountLimitReached ? {opacity: 0.5} : {}} />}
                onClick={onBindOtherAccount}
                disabled={maxLinkedAccountLimitReached}
                fullWidth
              >
                {t('core-auth.passport.modal-with-linked-accounts.link-login', 'Привязать {passportName}', {
                  passportName
                })}
              </Button.MultiCenter>
            </>
          )}
        </Box>
      </Modal.Footer>
    </>
  )
}
