import type {ICommunicationWidget} from '@restapp/shared-api'

import {CommunicationType} from './communications'
import type {Communication, CommunicationStatus} from './communications'

export type NewsItem = {
  type: CommunicationType.NEWS
  id: string
  created: string
  status: CommunicationStatus
  info: {
    topic: string
    important: boolean
    priority: number
    show_as_fullscreen: boolean
    url: string
    url_title: string
    section: string
  }
  preview: {
    title: string
    media_id: string
    media_type?: string
  }
  content: {
    content_type: 'simple' | 'story'
    pages: {text: string; media_id?: string; media_type?: string}[]
  }
  widget?: ICommunicationWidget
}

export type News = {
  items: NewsItem[]
  itemsIds: Record<string, boolean>
  hasMore: boolean
  pollingLimit: number
  filter: NewsFilter
  sort: NewsSort
  pollingCursor?: string
}

export const enum NewsFilter {
  ALL = '',
  NEWS = 'news',
  TUTORIAL = 'tutorial',
  ARTICLE = 'article'
}

export const enum NewsSort {
  DEFAULT = 'default',
  DATE_DESC = 'date_desc',
  DATE_ASC = 'date_asc',
  PRIORITY_DESC = 'priority_desc',
  PRIORITY_ASC = 'priority_asc'
}

export function isNews(communication: Communication): communication is NewsItem {
  return communication.type === CommunicationType.NEWS
}
