import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'

type UseSubscriptionsTariffsOptions = Pick<
  ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/get-tariffs', 'GET'>,
  'enabled'
>

export const useSubscriptionsTariffs = (options?: UseSubscriptionsTariffsOptions) =>
  useApiQuery({
    url: '/4.0/restapp-front/v1/place-subscriptions/v1/get-tariffs',
    method: 'GET',
    ...options
  })
