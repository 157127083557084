import type {Selector} from 'react-redux'

import type {AuthStateSlice} from '../types'

import {selectAuth} from './selectAuth'

/**
 * Селектор исключительно для получения значения токена, по нему нельзя определять залогинен ли пользователь. Для проверки залогинености используй selectIsLoggedIn
 */
export const selectToken: Selector<AuthStateSlice, string | null> = (state) => selectAuth(state).token
