import {useI18n} from '@eda-restapp/i18n'
import {Button, Box, useIsMobileStrict, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC, CSSProperties} from 'react'

import {SingleChatBadge} from '../SingleChatBadge'

import styles from './SingleChatPlate.module.css'

type SingleChatPlateProps = {
  style?: CSSProperties
  onClick?: () => void
}

export const SingleChatPlate: FC<SingleChatPlateProps> = ({style, onClick}) => {
  const {t} = useI18n()

  const isMobile = useIsMobileStrict()

  const text = t('main-chats.messenger.single-chat-plate-title', 'Чат с оператором')

  return (
    <Box style={style} flexDirection='column'>
      {!isMobile && (
        <div className={cn(styles.row, styles.sheet)}>
          <Box alignItems='center'>
            <SingleChatBadge />
          </Box>

          <Box gap='xl'>
            <Typography variant='body2' thickness='medium'>
              {text}
            </Typography>

            <div className={styles.button}>
              <Button.Text size='s' variant='secondary' fullWidth onClick={onClick}>
                {t('main-chats.messenger.single-chat-plate-button-text', 'Открыть')}
              </Button.Text>
            </div>
          </Box>

          <div />
        </div>
      )}

      {isMobile && (
        <Button.Action size='l' startIcon='' variant='light' shadow onClick={onClick}>
          <Box gap='s' alignItems='center'>
            {text}
            <SingleChatBadge />
          </Box>
        </Button.Action>
      )}
    </Box>
  )
}
