import {withStyles} from 'tss-react/mui'
import {Tooltip as MUTooltip} from '@mui/material'

export default withStyles(MUTooltip, {
  popper: {},
  tooltip: {
    backgroundColor: '#57595C',
    color: '#ffffff',
    fontSize: 14,
    lineHeight: '18px',
    padding: '10px 16px',
    borderRadius: 16,
    textAlign: 'center'
  },
  arrow: {
    color: '#57595C'
  }
})
