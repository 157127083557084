import {selectAuth} from '@restapp/core-auth'
import {setSensitiveStrings} from '@eda-restapp/logger'
import {getDirectToken} from '@eda-restapp/microfrontend'
import {startAppListening} from '@restapp/store'

export const initSecretsWatcher = () => {
  const currentDirectToken = getDirectToken()

  if (currentDirectToken) {
    setSensitiveStrings(currentDirectToken)
  }

  startAppListening({
    predicate: (_action, currentState, originalState) => {
      const current = selectAuth(currentState)
      const original = selectAuth(originalState)

      return current.oauthToken !== original.oauthToken || current.token !== original.token
    },
    effect: (_action, listenerApi) => {
      const {token, oauthToken} = selectAuth(listenerApi.getState())

      if (token) {
        setSensitiveStrings(token)
      }

      if (oauthToken) {
        setSensitiveStrings(oauthToken)
      }
    }
  })
}
