import React, {useState, useEffect} from 'react'
import {withStyles} from 'tss-react/mui'
import type {TooltipProps} from '@mui/material'
import {Tooltip} from '@mui/material'
import {eventLogger} from '@eda-restapp/logger'

type WhiteTooltipProps = TooltipProps & {
  children: React.ReactElement
  slug?: string
  classes?: Partial<Record<keyof typeof styles, string>>
}

function WhiteTooltip(props: WhiteTooltipProps) {
  const {children, classes: c = {}, slug, onOpen, onClose, ...restProps} = props

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      eventLogger({
        name: 'tooltip',
        value: slug,
        additional: {eventData: 'show'}
      })
    }
  }, [isOpen, slug])

  const handleOpen: TooltipProps['onOpen'] = (e) => {
    setIsOpen(true)
    onOpen?.(e)
  }

  const handleClose: TooltipProps['onClose'] = (e) => {
    setIsOpen(false)
    onClose?.(e)
  }

  return (
    <Tooltip
      classes={{popper: c.popper, tooltip: c.tooltip}}
      onOpen={(e) => handleOpen(e)}
      onClose={(e) => handleClose(e)}
      enterTouchDelay={0}
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom']
              }
            }
          ]
        }
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}

const styles = {
  tooltip: {
    border: '1px solid #E7EEF2',
    boxShadow: '0px 8px 20px rgba(122, 136, 150, 0.2)',
    color: '#1F2021',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    padding: '16px'
  },
  popper: {}
} as const

export default withStyles(WhiteTooltip, styles)
