import {eventLogger} from '@eda-restapp/logger'
import {Box} from '@eda-restapp/ui'
import React, {type ChangeEvent, type FC} from 'react'

type ChipGroupProps = {
  className?: string
  children: React.ReactElement<React.InputHTMLAttributes<HTMLInputElement>>[]
  name: string
  value: number | string | boolean | null
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  slug?: string
}

export const ChipGroup: FC<ChipGroupProps> = ({className = '', children, value, name, onChange, slug}) => {
  const onChangeTracked = (e: ChangeEvent<HTMLInputElement>) => {
    eventLogger({
      name: 'chip_group',
      value: slug,
      additional: {
        type: 'change',
        value: String(e.target.value)
      }
    })
    onChange(e)
  }

  return (
    <Box
      className={className}
      data-testid={`ui-chip-group-${name}` /* UI | ChipGroup. name - выбираемое поле*/}
      gap='s'
      mt='s'
      mb='s'
      flexWrap='wrap'
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null
        }

        return React.cloneElement(child, {
          name,
          checked: value === child.props.value,
          onChange: onChangeTracked
        })
      })}
    </Box>
  )
}
