import type {CSSProperties, ReactNode} from 'react'
import React, {createElement} from 'react'
import cn from 'classnames'
import {withStyles} from 'tss-react/mui'

type OuterProps = {
  className?: string
  classes?: Partial<Record<keyof typeof styles, string>>
  top?: number
  bottom?: number
  left?: number
  right?: number
  as?: string
  children?: ReactNode
}

class NativeSticky extends React.Component<OuterProps> {
  render() {
    const {classes: c = {}, children, top, bottom, left, right, as, className} = this.props

    const style: CSSProperties = {}

    if (top !== undefined) {
      style.top = `${top}px`
    }

    if (bottom !== undefined) {
      style.top = `${bottom}px`
    }

    if (left !== undefined) {
      style.top = `${left}px`
    }

    if (right !== undefined) {
      style.top = `${right}px`
    }

    return createElement(
      as || 'div',
      {
        className: cn(c.root, className),
        style
      },
      children
    )
  }
}

const styles = {
  root: {
    position: 'sticky'
  }
} as const

export default withStyles(NativeSticky, styles)
