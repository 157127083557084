import {useExp3} from '@eda-restapp/configs'
import {type CountryCodeType} from '@eda-restapp/i18n'
import React, {useEffect, type FC} from 'react'
import {useDispatch} from 'react-redux'

import {useCountryCode} from '../../hooks/useCountryCode'
import {setCountryCode} from '../../slice'
import {isNativeVendor} from '../../utils'

import {CountrySelect} from './CountrySelect'

type CountrySelectSplitPageProps = {
  navigate: (url: string) => void
  children?: React.ReactNode
}

// TODO: Удалим тут EDARESTAPP-10413
export const CountrySelectSplitPage: FC<CountrySelectSplitPageProps> = ({navigate, ...props}) => {
  const commonConfig = useExp3('restapp_common')
  const {countryCode} = useCountryCode()
  const dispatch = useDispatch()

  let currentPath = window.location.pathname
  // This check is for unstable - to delete unstable label from pathname
  if (currentPath.includes('/restapp-')) {
    currentPath = currentPath.substring(1)
    const slashPositions = [...currentPath.matchAll(new RegExp('/', 'gi'))].map((symbol) => symbol.index)

    currentPath = slashPositions.length ? currentPath.substring(currentPath.indexOf('/')) : ''
  }

  // Принимает параметр из нативного разводящего экрана
  useEffect(() => {
    const countryCodeFromURL = new URL(window.location.href).searchParams.get('countryCode') as CountryCodeType | null
    if (!countryCode && countryCodeFromURL) {
      dispatch(setCountryCode(countryCodeFromURL))
    }
  }, [])

  const handleCountryClick = (selectedCountryCode: CountryCodeType) => {
    dispatch(setCountryCode(selectedCountryCode))
  }

  if (isNativeVendor && commonConfig.countrySelectAuthEnabled && !countryCode) {
    return <CountrySelect onCountrySelect={handleCountryClick} />
  }

  return <>{props.children}</>
}
