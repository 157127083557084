import React from 'react'
import {ArrowUp, ArrowDown} from '@eda-restapp/ui'
import cn from 'classnames'

import {numberWithSpaces} from '@restapp/shared/utils'

import styles from './Delta.module.css'

export type ArrowPosition = 'left' | 'right'

type Props = {
  className?: string
  arrowPosition?: ArrowPosition
  hideValue?: boolean
  period?: string
  isNegativeDeltaIsGood?: boolean
  title?: string
  value: number
}

const NO_VALUE = '\u2004—'

export const Delta: React.FC<Props> = ({
  arrowPosition = 'left',
  hideValue = false,
  period,
  title,
  value,
  isNegativeDeltaIsGood,
  className
}) => {
  const renderArrow = (): JSX.Element | null => {
    if (hideValue && !value) {
      return (
        <div
          className={cn(
            styles.noValueArrow,
            arrowPosition === 'left' ? styles.leftArrowPosition : styles.rightArrowPosition
          )}
        >
          {NO_VALUE}
        </div>
      )
    }

    if (value) {
      return value > 0 ? (
        <ArrowUp
          fontSize={16}
          className={arrowPosition === 'left' ? styles.leftArrowPosition : styles.rightArrowPosition}
        />
      ) : (
        <ArrowDown
          fontSize={16}
          className={arrowPosition === 'left' ? styles.leftArrowPosition : styles.rightArrowPosition}
        />
      )
    }

    return null
  }

  const renderValue = () => {
    if (hideValue) {
      return null
    }

    if (title && value !== 0) {
      return title
    }

    if (!value) {
      return NO_VALUE
    }

    const normalizedValue = numberWithSpaces(Math.abs(value))

    return `${normalizedValue}${period ? `\xa0${period}` : ''}`
  }

  return (
    <span
      className={cn(
        styles.delta,
        {
          [styles.positiveColor]: isNegativeDeltaIsGood ? value < 0 : value > 0,
          [styles.negativeColor]: isNegativeDeltaIsGood ? value > 0 : value < 0,
          [styles.bold]: !value
        },
        className
      )}
    >
      {arrowPosition === 'left' && renderArrow()}
      {renderValue()}
      {arrowPosition === 'right' && renderArrow()}
    </span>
  )
}
