import {useInfiniteLoader} from '@eda-restapp/hooks'
import {metrika} from '@eda-restapp/logger'
import {Box, useIsMobileStrict} from '@eda-restapp/ui'
import {flatMapDeep} from 'lodash-es'
import {useCallback, type FC, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {UnreadCounterByChatsWatcher} from '@restapp/main-chats/messenger/components/UnreadCounterByChatsWatcher'
import {useInfinityChatsListQuery} from '@restapp/main-chats/messenger/hooks/useInfinityChatsListQuery'
import {messengerEventLogger, messengerErrorLogger} from '@restapp/main-chats/messenger/logger'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import Spinner from '@restapp/shared-ui/Spinner'

import {ChatsList} from '../../components/ChatsList'
import {ChatsTable} from '../../components/ChatsTable'
import NoChats from '../../components/NoChats'
import {useSupportChat} from '../../hooks/useSupportChat'

type ChatsListContainerProps = {
  className?: string
}

export const ChatsListContainer: FC<ChatsListContainerProps> = ({className}) => {
  const isMobile = useIsMobileStrict()
  const dispatch = useDispatch()
  const {openChat} = useSupportChat()

  const chatsListQuery = useInfinityChatsListQuery()

  const chatsList = useMemo(
    () => flatMapDeep(chatsListQuery.data?.pages, (page) => page.chats),
    [chatsListQuery.data?.pages]
  )

  const infiniteLoader = useInfiniteLoader<HTMLDivElement>({
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.75,
    loadMore: chatsListQuery.fetchNextPage,
    canLoadMore: chatsListQuery.hasNextPage
  })

  const chatClickHandler = useCallback(
    (chat: {chat_id?: string; ticket_id?: string}) => {
      if (!chat.chat_id && !chat.ticket_id) {
        messengerErrorLogger({error: 'no_chat_id_or_ticket_id'})
        return
      }

      metrika({target: 'chats_chat_click', params: {id: chat.chat_id, ticket_id: chat.ticket_id}})

      // 0/ признак id мультичата
      const isMultiChatId = chat.chat_id?.startsWith('0/')

      if (chat.ticket_id || isMultiChatId) {
        dispatch(chatsSlice.actions.openMultiChatDrawer({chatId: chat.chat_id, ticketId: chat.ticket_id}))
        messengerEventLogger({
          name: 'chat_click_multi_chat',
          value: chat.chat_id,
          additional: {
            ticketId: chat.ticket_id,
            chatId: chat.chat_id
          }
        })
        return
      }

      if (chat.chat_id) {
        openChat(chat.chat_id)
        messengerEventLogger({name: 'chat_click_legacy', value: chat.chat_id})
        return
      }
    },
    [dispatch, openChat]
  )

  if (chatsList.length < 1) {
    return <NoChats />
  }

  return (
    <Box className={className} flexDirection='column' pt={isMobile ? 'm' : undefined}>
      <UnreadCounterByChatsWatcher />

      {isMobile && <ChatsList chats={chatsList} onClickChat={chatClickHandler} />}

      {!isMobile && <ChatsTable chats={chatsList} onClickChat={chatClickHandler} />}

      {chatsListQuery.hasNextPage && (
        <Box ref={infiniteLoader.loaderRef} alignContent='center' justifyContent='center' p='m'>
          {<Spinner style={{visibility: chatsListQuery.isFetchingNextPage ? 'visible' : 'hidden'}} size={32} />}
        </Box>
      )}
    </Box>
  )
}
