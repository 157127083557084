import {createTheme} from '@mui/material'

const colors = {
  primary: {
    light: '#FCE45E',
    main: '#F7D101',
    dark: '#E6B403'
  },
  secondary: {
    main: '#292B31',
    dark: '#EDF0F1'
  }
}

export const theme = createTheme({
  palette: colors,
  breakpoints: {
    values: {
      xs: 0, // Mobile
      sm: 600, // Just for Support of MUI internal styles
      md: 768, // Tablet
      lg: 1152, // Desktop
      xl: 99999
    }
  },
  typography: {
    fontFamily: ['YS Text', 'Noto Sans', 'helvetica', 'arial', 'sans-serif'].join(',')
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          backgroundColor: '#F2F4F5',
          boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.25)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '13px 18px',
          height: '1.1876em'
        },
        multiline: {
          padding: '0px'
        },
        root: {
          '&.Mui-focused': {
            notchedOutline: {
              borderRadius: 8,
              borderColor: '#929292'
            }
          }
        },
        notchedOutline: {
          borderRadius: 8,
          borderColor: '#C4C4C4'
        },
        adornedEnd: {
          paddingRight: 8
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: 'translate(14px, -9px) scale(0.75)'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: 8,
          paddingBottom: 4
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          marginBottom: 4,

          '&.Mui-selected': {
            backgroundColor: '#D8DBDD'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: '1.1876em',
          '.MuiFormControl-root.MuiTextField-root label+&&': {
            marginTop: '13px'
          }
        },
        input: {
          lineHeight: '1.1876em',
          height: '1.1876em'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        },
        input: {
          fontSize: 14,
          fontWeight: 'bold',
          padding: '11px 16px'
        },

        underline: {
          borderBottom: 'none',
          '&&&:before': {
            transition: 'none',
            borderBottom: 'none'
          },
          '&&:after': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: '#F2F4F5',
          borderRadius: 8,
          padding: '11px 16px',
          fontSize: 14,
          lineHeight: '18px',
          fontWeight: '500',
          border: 'none',

          '&:focus': {
            borderRadius: 8,
            backgroundColor: '#BEC1C3'
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // Portals has zIndex=100001, selectors in pop-ups should be higher
          zIndex: '130000!important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: 500,
          fontSize: 14,
          height: 40,
          borderRadius: 12,
          padding: '0 16px',
          boxShadow: 'none'
        },
        contained: {
          backgroundColor: '#f0f0f0'
        },
        sizeSmall: {
          height: 32,
          width: 106,
          boxShadow: 'none',
          borderRadius: 8
        },
        sizeLarge: {
          height: 56,
          fontSize: 18,
          fontWeight: 500,
          borderRadius: 16
        },
        containedPrimary: {
          backgroundColor: colors.primary.light,
          color: '#21201F',

          '&:hover': {
            backgroundColor: '#F7D101',
            boxShadow: 'none'
          }
        },
        containedSecondary: {
          backgroundColor: colors.secondary.dark,
          color: '#21201F',

          '&:hover': {
            backgroundColor: '#D8DBDD',
            boxShadow: 'none'
          },

          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: `${colors.secondary.dark} !important;`
            }
          }
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'rgba(0, 0, 0, 0.87)'
        }
      }
    }
  }
})
