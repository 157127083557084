import type {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const modalFooterStyles = (theme: Theme) =>
  ({
    root: {
      padding: '12px 16px',
      display: 'flex',
      flexDirection: 'column-reverse',
      flexShrink: 0,

      [theme.breakpoints.up('md')]: {
        padding: '8px 40px 24px',
        display: 'block',
        zIndex: 1
      }
    }
  }) as const

export default makeStyles({name: 'ModalFooter'})(modalFooterStyles)
