import {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'

import {getPromotionPlacesPath, getPromotionStartPagePath} from '../constants'

const usePromotionRoutes = () => {
  const navigate = useNavigate()

  const redirectToPlaces = useCallback(() => {
    navigate(getPromotionPlacesPath())
  }, [navigate])

  const redirectToStart = useCallback(() => {
    navigate(getPromotionStartPagePath())
  }, [navigate])

  return {
    history,
    redirectToPlaces,
    redirectToStart
  }
}

export default usePromotionRoutes
