import {useExp3} from '@eda-restapp/configs'

import type {ApiQueryConfig} from '@restapp/core-api'
import {useApiQuery} from '@restapp/core-api'

type DefaultData = Parameters<
  NonNullable<ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/place/get-offers', 'POST'>['select']>
>

type UseOrderByIdOptions<TSelectedData> = Partial<
  Omit<
    ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/place/get-offers', 'POST', TSelectedData>,
    'url' | 'body' | 'method'
  >
>

export const useGetPlacesOffers = <TSelectedData = DefaultData>(
  placesIds: number[],
  options?: UseOrderByIdOptions<TSelectedData>
) => {
  const {enabledOffersApi} = useExp3('restapp_tools')

  const enabled = Boolean(enabledOffersApi) && (options?.enabled ?? true) && Boolean(placesIds.length)

  return useApiQuery({
    method: 'POST',
    url: '/4.0/restapp-front/v1/place-subscriptions/v1/place/get-offers',
    body: {
      place_ids: placesIds
    },
    retry: false,
    ...options,
    enabled
  })
}
