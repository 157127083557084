import {useExp3} from '@eda-restapp/configs'
import type {FC} from 'react'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useOldHeavyPlaces} from '@restapp/core-places'

import {BannerHolidaySchedule} from './BannerHolidaySchedule'

type BannerHolidayScheduleContainerProps = {
  className?: string
}

const NO_HOLIDAY_SCHEDULE = 103

export const BannerHolidayScheduleContainer: FC<BannerHolidayScheduleContainerProps> = ({className}) => {
  const newYearConfig = useExp3('restapp_new_year')

  const navigate = useNavigate()

  const {places} = useOldHeavyPlaces()

  const hasBadHolidaySchedule = places.find((place) => place.disable_details?.reason === NO_HOLIDAY_SCHEDULE)

  const goToSchedule = () => navigate('/schedule')

  if (!newYearConfig.bannerHolidayScheduleEnabled || !hasBadHolidaySchedule) {
    return null
  }

  return (
    <BannerHolidaySchedule
      className={className}
      backgroundImage={newYearConfig.holidayScheduleBannerBackgroundImage}
      backgroundLayer={newYearConfig.holidayScheduleBannerBackgroundLayer}
      onClick={goToSchedule}
    />
  )
}
