import type {ReactElement} from 'react'
import React from 'react'
import {keyframes} from 'tss-react'
import {makeStyles} from 'tss-react/mui'

const useStoryProgressStyles = makeStyles<{
  pause: boolean
  pageDuration: number
}>()((theme, {pageDuration, pause}) => {
  return {
    root: {
      maxWidth: 85,
      height: '100%',
      width: '100%',
      margin: '0 2px',
      background: 'rgba(33, 32, 31, 0.2)',
      borderRadius: 10,
      overflow: 'hidden'
    },
    progress: {
      height: '100%',
      width: '100%',
      borderRadius: 10,
      background: 'rgb(33, 32, 31)',
      transformOrigin: 'left',
      transform: 'scaleX(0)'
    },
    progressComplete: {
      transform: 'unset'
    },
    animateProgress: {
      // animation: () => ... dont work, because can't resolve keyframes name
      // https://github.com/mui-org/material-ui/issues/16673
      // seems to be fixed in v5 https://github.com/mui-org/material-ui/pull/22460#issuecomment-686384507
      animationName: `${keyframes`
        0% {
          transform: scaleX(0);
          border-radius: 100%;
        }
        100% {
          transform: scaleX(1);
          border-radius: 24px;
        }
      `}`,
      animationDuration: `${pageDuration}ms`,
      animationPlayState: pause ? 'paused' : 'running',
      animationTimingFunction: 'linear'
    }
  }
})

interface StoryProgressProps {
  active: boolean
  pause: boolean
  complete: boolean
  pageDuration: number
  onPageTimeout(): void
}

function PageProgress({active, pause, complete, pageDuration, onPageTimeout}: StoryProgressProps): ReactElement {
  const {classes, cx} = useStoryProgressStyles({pageDuration, pause})

  return (
    <div className={classes.root}>
      <div
        className={cx({
          [classes.progress]: true,
          [classes.animateProgress]: active,
          [classes.progressComplete]: complete
        })}
        onAnimationEnd={onPageTimeout}
      />
    </div>
  )
}

export default PageProgress
