import {useExp3} from '@eda-restapp/configs'
import {toString, trimEnd} from 'lodash-es'

import {useSupportLinkPrep} from './useSupportLinkPrep'

// Удаляет лишние слеши
const normalizePath = (path: string) => trimEnd(path.replace(/\/+/g, '/'), '/')

const useHelpLink = (key: string) => {
  const {linksCommonPath, links} = useExp3('restapp_support_links')

  const linkPath = toString(links.find(({id}) => id === key)?.path)

  return {
    link: useSupportLinkPrep(normalizePath(`${linksCommonPath}/${linkPath}`))
  }
}

export default useHelpLink
