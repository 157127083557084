import {groupBy} from 'lodash-es'
import {useMemo} from 'react'

import {type Campaign, CampaignType} from '../types/campaign'

type IUseAvailableCampaignTypes = {
  campaigns: Campaign[] | undefined
}

const useAvailableCampaignTypes = ({campaigns}: IUseAvailableCampaignTypes) => {
  const baseCampaignTypes = useMemo(
    () =>
      (Object.keys(CampaignType) as CampaignType[]).filter(
        (campaignType) => campaignType !== CampaignType.cpm || false
      ),
    []
  )

  const availableCampaignTypesByPlaceId = useMemo(() => {
    const campaignGroupsByPlace = campaigns && groupBy(campaigns, (campaign) => campaign?.place_id)

    return (
      campaignGroupsByPlace &&
      Object.fromEntries<CampaignType[]>(
        Object.entries<Campaign[]>(campaignGroupsByPlace).map(([placeId, campaigns]) => {
          const availableCampaignTypes =
            campaigns.reduce((acc, campaign) => {
              const currentCampaignType = campaign?.campaign_type

              // @TODO нужно поправить типы, campaign_type не может быть undefined из-за значения по умолчанию
              if (!currentCampaignType) {
                return acc
              }

              return acc.includes(currentCampaignType) && campaign.status !== 'archived'
                ? acc.filter((campaignType) => campaignType !== currentCampaignType)
                : acc
            }, baseCampaignTypes) ?? baseCampaignTypes

          return [placeId, availableCampaignTypes] as const
        })
      )
    )
  }, [campaigns, baseCampaignTypes])

  const getAvailableCampaignTypes = useMemo(
    () =>
      availableCampaignTypesByPlaceId &&
      ((placeId: number) => availableCampaignTypesByPlaceId[placeId] ?? baseCampaignTypes),
    [availableCampaignTypesByPlaceId, baseCampaignTypes]
  )

  return {getAvailableCampaignTypes}
}

export default useAvailableCampaignTypes
