import {getNumberFormatSeparators} from './getNumberFormatSeparators'

/**
 * Возвращает число из форматированной строки
 * @param value {string} число в формате строки с форматированием
 * @param lang {string | string[]} локаль форматирования числа
 * @returns {number}
 */
export function parseFormattedFloat(value: string, lang: string | string[]) {
  const langNumberFormatSeparators = getNumberFormatSeparators(lang)
  // Экранируем точки для последующей передачи в RegExp
  const thousands = langNumberFormatSeparators.thousands === '.' ? '\\.' : langNumberFormatSeparators.thousands
  const decimal = langNumberFormatSeparators.decimal === '.' ? '\\.' : langNumberFormatSeparators.decimal
  // Подготавливаем RegExp
  const thousandsSeparatorRegExp = new RegExp(thousands, 'g')
  const decimalSeparatorRegExp = new RegExp(decimal)
  // Приводим форматированное число к float-формату
  const cleanValue = value
    .replace(thousandsSeparatorRegExp, '')
    .replace(decimalSeparatorRegExp, '.')
    .replace(/[^0-9.]/g, '')
  const numberValue = parseFloat(cleanValue)

  if (isNaN(numberValue)) {
    return 0
  }

  return numberValue
}
