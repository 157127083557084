import {useI18n} from '@eda-restapp/i18n'
import {Plus} from '@eda-restapp/ui'
import type {PopoverOrigin} from '@mui/material'
import {Popover} from '@mui/material'
import cn from 'classnames'
import type {MouseEvent, ReactNode} from 'react'
import React, {useState} from 'react'

import {usePlaces} from '@restapp/core-places'
import {Button, DeliveryIcon, LineClamp} from '@restapp/shared-ui'

import {useNewChatButtonStyle} from './NewChatButton.style'

type INewChatButtonProps = {
  anchorOrigin?: PopoverOrigin
  className?: string
  label?: ReactNode
  transformOrigin?: PopoverOrigin
  onCreateChat(placeId?: number): void
}

function NewChatButton({anchorOrigin, className, label, transformOrigin, onCreateChat}: INewChatButtonProps) {
  const {classes: c} = useNewChatButtonStyle()
  const {t} = useI18n()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const {places} = usePlaces()

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (places.length === 1) {
      onCreateChat(places[0].id)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function createRestaurantSelectHandler(restaurantId?: number) {
    return () => {
      handleClose()
      onCreateChat(restaurantId)
    }
  }

  return (
    <>
      <Button
        className={cn(c.newChatBtn, className)}
        variant='contained'
        color='primary'
        onClick={handleClick}
        data-testid={'chats-new-chat-btn' /*Обращения | Кнопка создания нового чата*/}
      >
        {label || <Plus fontSize={25} />}
      </Button>
      <Popover
        classes={{root: c.popoverRoot, paper: c.popoverPaper}}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        marginThreshold={5}
      >
        <div className={c.popoverTitle}>
          {t(
            'main-chats.new-chat-button.viberite-po-kakomu-restoranu-budet',
            'Выберите, по какому ресторану будет обращение:'
          )}
        </div>
        {places.map((place) => (
          <div key={place.id} className={c.restaurant}>
            <DeliveryIcon place={place} />
            <div className={c.restaurantInfo}>
              <div className={c.restaurantName}>{place.name}</div>
              <LineClamp lineClamp={1}>{place.address}</LineClamp>
            </div>
            <Button
              data-testid={`button-for-choice-restaurant-${place.name}`}
              className={c.chooseButton}
              variant='contained'
              color='primary'
              onClick={createRestaurantSelectHandler(place.id)}
              size='small'
            >
              {t('main-chats.new-chat-button.vibrat', 'Выбрать')}
            </Button>
          </div>
        ))}
      </Popover>
    </>
  )
}

export default NewChatButton
