import {createSelector} from '@reduxjs/toolkit'
import {isEqual} from 'lodash-es'

import {categoryEntitySelectors} from '../entities'
import {selectMenu, addIdArg} from '../utils'

export const selectCurrentCategories = createSelector(selectMenu, (state) => state.categories.current)
export const selectInitialCategories = createSelector(selectMenu, (state) => state.categories.initial)

export const all = createSelector(selectCurrentCategories, categoryEntitySelectors.selectAll)
export const byId = createSelector([selectCurrentCategories, addIdArg], categoryEntitySelectors.selectById)
export const total = createSelector(selectCurrentCategories, categoryEntitySelectors.selectTotal)
export const isDirty = createSelector(
  [selectCurrentCategories, selectInitialCategories],
  (current, initial) =>
    // Эти проверки будут работать т.к optionEntitySelectors соблюдает сортировку
    !isEqual(categoryEntitySelectors.selectAll(current), categoryEntitySelectors.selectAll(initial))
)
