import {useMemo} from 'react'

import {useApiQuery, useApiQueryInvalidate} from '@restapp/core-api'

type UseBalanceProps = {
  directToken?: string | null
  onBalanceUpdated?: () => void
}

const useBalance = ({onBalanceUpdated, directToken}: UseBalanceProps) => {
  const config = {
    url: '/4.0/restapp-front/marketing/v1/ad/balance' as const,
    method: 'GET' as const,
    enabled: !!directToken,
    headers: directToken
      ? {
          Authorization: directToken
        }
      : undefined
  }

  const {data: balance, isLoading} = useApiQuery(config)

  const {invalidate, isInProgress: isInvalidating} = useApiQueryInvalidate(
    [config.url, config.method as never],
    undefined,
    onBalanceUpdated
  )

  const balanceObject = useMemo(
    () =>
      balance?.balance && balance?.currency
        ? {balance: Number(balance?.balance), currency: balance?.currency}
        : undefined,
    [balance?.balance, balance?.currency]
  )

  return {
    isBalanceLoading: isLoading,
    isBalanceUpdating: isInvalidating,
    balance: balanceObject,
    updateBalance: invalidate
  }
}

export default useBalance
