import React from 'react'

import useSidebarBadgeStyles from './SidebarBadge.styles'

export enum BadgesType {
  NEW = 'NEW',
  BETA = 'BETA',
  NOTIFY_RED = 'NOTIFY_RED',
  NOTIFY_COUNT = 'NOTIFY_COUNT'
}

interface SidebarBadgeProps {
  type: BadgesType
  children?: React.ReactNode
}

const SidebarBadge: React.FC<SidebarBadgeProps> = ({type, children}) => {
  const {classes: c} = useSidebarBadgeStyles()

  return (
    <div className={c[type]} data-testid={'sidebar-badge' /*Сайдбар | Значение бейджа*/}>
      {children}
    </div>
  )
}

export default SidebarBadge
