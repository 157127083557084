import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles({name: 'HelpMenu'})((theme) => {
  return {
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    title: {
      fontSize: '10px',
      color: '#999C9E',
      lineHeight: '32px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    list: {
      display: 'flex',
      flexDirection: 'column'
    },
    itemCommon: {
      minHeight: '32px',
      paddingLeft: 8,
      paddingRight: 0
    },
    item: {
      fontSize: '14px',
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'space-between',
      color: '#1F2021',
      padding: '9px 0 9px 8px',
      lineHeight: '16px',

      '&> span': {
        alignSelf: 'center',
        paddingRight: '25px'
      },

      '&:hover': {
        background: '#F4F6F7',
        borderRadius: '8px'
      }
    },
    chevron: {
      alignSelf: 'center'
    },
    paper: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        '&&': {
          display: 'block',
          width: 297,
          padding: '8px',
          transitionDuration: '0s!important',
          boxShadow: '0px 8px 20px rgba(122, 136, 150, 0.2)',
          borderRadius: 16
        }
      }
    }
  }
})

export default useStyles
