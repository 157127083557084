import {useI18n} from '@eda-restapp/i18n'
import type {SectionFeedbackMessageType, SectionFeedbackVariant, FeedbackScore} from '@eda-restapp/ui'
import {SectionFeedback, SmileSmiling} from '@eda-restapp/ui'
import type {CSSProperties} from 'react'
import React, {useState} from 'react'

import {useApiMutation, useApiQuery} from '@restapp/core-api'
import type {SectionSlug} from '@restapp/shared-api'

import styles from './SectionFeedbackContainer.module.css'

/*
  Компонент, служащий для выставления рейтинга и оставления пользователем отзыва и представляющий собой небольшой блок со шкалой рейтинга
(настраиваемое, в настоящее время доступны звездочки (по умолчанию) и смайлики), который после выбора элемента на шкале отображает текстовое
поля для оставления комментарияю
*/

export type SectionFeedbackProps = {
  variant?: SectionFeedbackVariant
  className?: string
  slug: SectionSlug
  messages?: Partial<SectionFeedbackMessageType>
  style?: CSSProperties
  disableBackground?: boolean
}

export const SectionFeedbackContainer = ({
  variant,
  className,
  messages,
  slug,
  style,
  disableBackground
}: SectionFeedbackProps) => {
  const {t, tRaw} = useI18n()

  const [score, setScore] = useState<FeedbackScore>()
  const [comment, setComment] = useState<string>('')

  const defaultPlaceholder = t(
    'main-home.section-feedback-pure.napishite-kommentarii-ili-ostavte-sv',
    'Напишите комментарий или оставьте свои контакты – мы с вами свяжемся'
  )

  const messageLabels = {
    highRate:
      messages?.highRate ||
      tRaw('main-home.section-feedback-pure.spasibo-chto-mozhno-uluchshit', 'Спасибо {smile} Что можно улучшить?', {
        smile: <SmileSmiling className={styles.smileSmiling} />
      }),
    lowRate:
      messages?.lowRate ||
      t('main-home.section-feedback-pure.rasskazhite-chto-poshlo-ne-tak', 'Расскажите, что пошло не так?'),
    final:
      messages?.final ||
      tRaw('main-home.section-feedback-pure.spasibo-za-otziv', 'Спасибо за отзыв! {smile}', {
        smile: <SmileSmiling className={styles.smileSmiling} />
      }),
    initial:
      messages?.initial ||
      t('main-home.section-feedback-pure.vam-nravitsya-novii-razdel', 'Вам нравится новый раздел?'),
    placeholderLowRate: messages?.placeholderLowRate || defaultPlaceholder,
    placeholderHighRate: messages?.placeholderHighRate || defaultPlaceholder
  }

  const {data, isSuccess} = useApiQuery({
    url: '/4.0/restapp-front/communications/v1/feedback',
    method: 'GET',
    params: {slug}
  })

  const {mutate, status: mutationStatus} = useApiMutation({
    url: '/4.0/restapp-front/communications/v1/feedback',
    method: 'POST'
  })

  if (!isSuccess || data?.is_fresh) {
    return null
  }

  const isSubmitted = mutationStatus === 'success'

  const messageByScore = score && (score >= 4 ? messageLabels.highRate : messageLabels.lowRate)
  const placeholderByScore =
    score && (score >= 4 ? messageLabels.placeholderHighRate : messageLabels.placeholderLowRate)

  const placeholder = placeholderByScore || defaultPlaceholder
  const finalMessage = isSubmitted && messageLabels.final
  const message = finalMessage || messageByScore || messageLabels.initial

  return (
    <SectionFeedback
      variant={variant}
      disableBackground={disableBackground}
      isLoading={mutationStatus === 'loading'}
      isSubmitted={isSubmitted}
      className={className}
      message={message}
      placeholder={placeholder}
      style={style}
      comment={comment}
      setComment={setComment}
      score={score}
      setScore={setScore}
      onSubmit={() =>
        score &&
        mutate({
          body: {slug, score, comment}
        })
      }
    />
  )
}
