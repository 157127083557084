import {omitBy} from 'lodash-es'

import {MENU_SLICE_NAME} from './constants'
import type {OptionVirtualId, GroupVirtualId, MenuSliceState} from './types'

/**
 * Из-за того что нельзя гарантировать уникальность id у групп и опций в меню,
 * а хранить их в нормализованном виде хочется, нужны виртуальные id чтобы гарантировать уникальность каждой сущности
 */
export function getGroupVirtualId(itemId: string, groupId: string): GroupVirtualId {
  return `${itemId}/${groupId}` as GroupVirtualId
}

/**
 * Из-за того что нельзя гарантировать уникальность id у групп и опций в меню,
 * а хранить их в нормализованном виде хочется, нужны виртуальные id чтобы гарантировать уникальность каждой сущности
 */
export function getOptionVirtualId(itemId: string, groupId: string, optionId: string): OptionVirtualId {
  return `${itemId}/${groupId}/${optionId}` as OptionVirtualId
}

/** Утилитарный селектор: Заглушка для createSelector - возвращает первый аргумент */
export const selectSelf = (state: unknown) => state

/** Утилитарный селектор: Достает слайс меню из глобального стора или падает с ошибкой если его там нет */
export const selectMenu = (state: unknown) => {
  if (!!state && typeof state === 'object' && MENU_SLICE_NAME in state) {
    return state[MENU_SLICE_NAME] as MenuSliceState
  }

  throw new Error(`[redux-menu-slice]: key ${MENU_SLICE_NAME} is undefined in root state`)
}

// утилиты для добавления параметров к селекторам
export const addIdArg = (_state: unknown, id: string) => id
export const addGroupIdArg = (_state: unknown, id: GroupVirtualId) => id

export function omitMeta<T extends Record<string, unknown>>(entity: T): T {
  return omitBy(entity, (_val, key) => key.startsWith('_')) as T
}

export function setJoin<T extends string = string, K extends unknown[] = unknown[]>(
  obj: Partial<Record<T, K>>,
  path: T,
  value: K[number]
) {
  obj[path] = obj[path] || ([] as unknown as K)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  obj[path]!.push(value)
}
