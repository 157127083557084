import type {CSSProperties} from 'react'
import React, {useEffect} from 'react'
import cn from 'classnames'
import useStyles from './Spinner.style'
import {SpinnerIcon, Typography} from '@eda-restapp/ui'
import {errorLogger, eventLogger} from '@eda-restapp/logger'

type SpinnerProps = {
  /**
   * Размер спиннера.
   */
  size?: number
  slug?: string
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  className?: string
  absolute?: boolean
  color?: string
  caption?: string
  style?: CSSProperties
}

const SPINNER_DURATION_THRESHOLD = 10_000

const Spinner = ({size = 24, slug, className, classes, absolute, caption, color = 'black', style}: SpinnerProps) => {
  const {classes: c} = useStyles(undefined, {props: {classes}})

  useEffect(() => {
    const showedAt = Date.now()
    eventLogger({name: 'spinner', value: slug || 'spinner-event', additional: {eventData: 'show'}})

    return () => {
      const spinnerDuration = Date.now() - showedAt
      if (spinnerDuration > SPINNER_DURATION_THRESHOLD) {
        errorLogger({
          sourceType: 'spinner',
          additional: {spinnerDuration, eventSlug: slug || 'spinner-event'},
          level: 'warn',
          error: new Error(slug || 'spinner-event'),
          message: slug || 'spinner-event'
        })
      } else {
        eventLogger({
          name: 'spinner',
          value: slug || 'spinner-event',
          additional: {spinnerDuration}
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={cn(c.root, className, {[c.absolute]: absolute})}
      style={style}
      data-testid={'ui__spinner' /* UI | Spinner */}
    >
      <SpinnerIcon className={c.spinner} color={color} fontSize={size} />

      {!!caption && (
        <Typography variant='caption1' className={c.caption}>
          {caption}
        </Typography>
      )}
    </div>
  )
}

export default Spinner
