import {makeStyles} from 'tss-react/mui'

export const useRatingStyles = makeStyles({name: 'RatingAnswer'})(() => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    star: {
      fontSize: 56,
      margin: '0 8px',
      color: '#E2E4E7',
      cursor: 'pointer'
    },
    starFilled: {
      color: '#FCB900'
    }
  }
})
