import {Typography, Box} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC, type ReactNode} from 'react'

import type {ShortRestaurant} from '@restapp/shared-api/types/places'
import {RestaurantListItem} from '@restapp/shared-ui'

import styles from './PlacesListItem.module.css'

interface PlacesListItemProps {
  subtitle?: ReactNode | string
  className?: string
  subtitleClassName?: string
  actions?: ReactNode | string
  place: ShortRestaurant
}

export const PlacesListItem: FC<PlacesListItemProps> = ({subtitle, subtitleClassName, place, actions, className}) => (
  <Box flexDirection='column' className={cn(styles.root, className)}>
    <Box justifyContent='space-between'>
      <RestaurantListItem key={place.id} place={place} />

      {actions}
    </Box>

    {!!subtitle &&
      (typeof subtitle === 'string' ? (
        <Typography variant='caption1' mt='s' className={subtitleClassName}>
          {subtitle}
        </Typography>
      ) : (
        <>{subtitle}</>
      ))}
  </Box>
)
