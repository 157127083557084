import {lazy} from 'react'

import type {InnerRoute} from '@restapp/core-legacy/common/routes'

const PlaceContainer = lazy(
  () => import(/* webpackChunkName: "@restapp/main-places/PlaceContainer" */ './containers/PlaceContainer')
)
const PlacesContainer = lazy(() =>
  import(/* webpackChunkName: "@restapp/main-places/PlacesContainer" */ './pages/Places/PlacesContainer').then(
    (module) => {
      return {default: module.PlacesContainer}
    }
  )
)

const PlacesRoutes: InnerRoute[] = [
  {
    enabled: true,
    name: 'places.all',
    path: '/',
    component: PlacesContainer
  },
  {
    enabled: true,
    name: 'places.id',
    path: ':id/*',
    component: PlaceContainer
  }
]

export default PlacesRoutes
