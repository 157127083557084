import {useCallback} from 'react'
import {useQueryState} from 'react-router-use-location-state'

interface UseModalOptions {
  modalName: string
}

interface UseModalResult {
  isOpen: boolean
  open: () => void
  close: () => void
}

export const useModal = ({modalName}: UseModalOptions): UseModalResult => {
  const [currentModal, setCurrentModal] = useQueryState('modal', '')

  const open = useCallback(() => {
    if (currentModal !== modalName) {
      setCurrentModal(modalName)
    }
  }, [currentModal, modalName, setCurrentModal])

  const close = useCallback(() => {
    if (currentModal !== '') {
      setCurrentModal('')
    }
  }, [currentModal, setCurrentModal])

  return {
    isOpen: currentModal === modalName,
    open,
    close
  }
}
