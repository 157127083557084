import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import type {BoxConfig} from '@eda-restapp/ui'
import {Box, Typography, ListItem, Text, Trails} from '@eda-restapp/ui'
import React from 'react'

import {OutsideLink} from '@restapp/shared-ui'

interface LearnMoreAboutPlusProps extends BoxConfig {
  className?: string
}

const LearnMoreAboutPlus: React.FC<LearnMoreAboutPlusProps> = ({...boxProps}) => {
  const {t} = useI18n()
  const {aboutPlusLink, advantagesLink, connectLink} = useExp3('restapp_plus')

  const links: Array<{title: string; url: string}> = [
    {
      title: t('shared-places.learn-more-about-plus.about-plus', 'О Яндекс Плюсе'),
      url: aboutPlusLink
    },
    {
      title: t('shared-places.learn-more-about-plus.advantages', 'Преимущества'),
      url: advantagesLink
    },
    {
      title: t('shared-places.learn-more-about-plus.enable-conditions', 'Условия подключения'),
      url: connectLink
    }
  ]

  return (
    <Box
      data-testid={'more-about-plus-block' /* Плюс | Блок Подробнее */}
      flexDirection='column'
      pt='m'
      pb='l'
      {...boxProps}
    >
      <Typography variant='body1' lineHeight='tight' thickness='medium' px='l' mb='m'>
        {t('main-places.plus-section.learn-more-about-plus', 'Подробнее о Яндекс Плюсе')}
      </Typography>
      {links.map((link) => (
        <ListItem key={link.url} mx='m' Component={OutsideLink} href={link.url} target='_blank'>
          <Text>
            <Typography variant='body2' lineHeight='tight' thickness='regular'>
              {link.title}
            </Typography>
          </Text>
          <Trails.Nav />
        </ListItem>
      ))}
    </Box>
  )
}

export default LearnMoreAboutPlus
