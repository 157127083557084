import React, {type CSSProperties, type FC} from 'react'

import {PlusBanner} from './components/PlusBanner/PlusBanner'
import {usePlusBannerForPlace} from './usePlusBannerForPlace'

type PlusBannerForPlaceProps = {
  placeId: number
  style?: CSSProperties
}

export const PlusBannerForPlace: FC<PlusBannerForPlaceProps> = ({style, placeId}) => {
  const {visible} = usePlusBannerForPlace(placeId)

  if (!visible) {
    return null
  }

  return <PlusBanner style={style} placeId={placeId} />
}
