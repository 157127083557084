export const capitalizeFirstLetter = (value: string) => {
  if (!value) {
    return ''
  }

  const firstLetter = value[0]
  const isFirstLetterLowerCase = value && /[a-zа-яё]/.test(firstLetter)

  return isFirstLetterLowerCase ? `${firstLetter.toUpperCase()}${value.substring(1)}` : value
}

export const insertInString = (value: string, otherValue: string | string[], regexp: string | string[]) => {
  const isRegexpArray = Array.isArray(regexp)
  const isOtherValueArray = Array.isArray(otherValue)

  if (isRegexpArray && isOtherValueArray) {
    if (regexp.length === otherValue.length) {
      let newValue = value

      regexp.forEach((regx, index) => {
        newValue = newValue.replace(new RegExp(`${regx}`, 'g'), otherValue[index])
      })

      return newValue
    }

    return value
  }

  if (!isOtherValueArray && !isRegexpArray) {
    return value.replace(new RegExp(`${regexp}`, 'g'), otherValue)
  }

  return value
}
