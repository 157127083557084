import {eventLogger} from '@eda-restapp/logger'
import React, {type FC, useEffect} from 'react'

import {
  useBusyModeDisableMutation,
  useBusyModeEnableMutation,
  useBusyModeModal,
  useBusyModeQuery,
  useBusyModeTracker
} from '@restapp/shared-places'

import {BusyModeModal} from './BusyModeModal'
import type {BusyModeParams} from './BusyModeModal.types'

const LOAD_SPINNER_DURATION_THRESHOLD = 10_000

export const BusyModeModalContainer: FC = () => {
  const busyModeModal = useBusyModeModal()
  const {placeId} = busyModeModal

  const busyModeQuery = useBusyModeQuery({placeId})
  const busyModeEnableMutation = useBusyModeEnableMutation()
  const busyModeDisableMutation = useBusyModeDisableMutation()
  const busyModeTracker = useBusyModeTracker()

  useEffect(() => {
    const showedAt = Date.now()

    if (busyModeQuery.isLoading) {
      eventLogger({name: 'spinner', value: 'busy_mode_loading', additional: {eventData: 'show'}})

      return () => {
        const spinnerDuration = Date.now() - showedAt
        eventLogger({
          name: 'spinner',
          value: 'busy_mode_loading',
          additional: {
            eventData: {spinnerDuration},
            eventLevel: spinnerDuration > LOAD_SPINNER_DURATION_THRESHOLD ? 'warn' : 'info'
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (busyModeModal.isOpen) {
      eventLogger({
        name: 'modal',
        value: 'busy_mode_modal',
        additional: {
          eventData: 'open'
        }
      })
    }
  }, [busyModeModal.isOpen])

  const onCloseWithTracker = () => {
    eventLogger({name: 'modal', value: 'busy_mode_modal', additional: {eventData: 'close'}})
    busyModeModal.close()
  }

  const validAdditionalMinutes = busyModeQuery.data?.available_parameters.extra_time_intervals || []
  const canEnableBusyMode = validAdditionalMinutes.length > 0

  const enableBusyMode = ({extraMinutes, validForMinutes}: BusyModeParams) => {
    if (placeId) {
      busyModeEnableMutation.mutate(
        {
          params: {place_id: placeId},
          body: {extra_time: extraMinutes, valid_for: validForMinutes}
        },
        {
          onSuccess: () => {
            busyModeModal.close()
            busyModeTracker({
              placeId,
              additionalMinutes: extraMinutes,
              durationMinutes: validForMinutes,
              prevAdditionalMinutes: busyModeQuery.data?.busy_mode?.extra_time,
              prevDurationMinutes: busyModeQuery.data?.busy_mode?.valid_until
            })
          }
        }
      )
    }
  }

  const handleEnableBusyMode = ({
    additionalMinutes,
    durationMinutes
  }: {
    additionalMinutes: number
    durationMinutes: number
  }) => {
    eventLogger({name: 'busy_mode_enable_btn'})
    enableBusyMode({extraMinutes: additionalMinutes, validForMinutes: durationMinutes})
  }

  const disableBusyMode = () => {
    if (placeId) {
      busyModeDisableMutation.mutate(
        {params: {place_id: placeId}},
        {
          onSuccess: () => {
            busyModeModal.close()
            busyModeTracker({
              placeId,
              prevAdditionalMinutes: busyModeQuery.data?.busy_mode?.extra_time,
              prevDurationMinutes: busyModeQuery.data?.busy_mode?.valid_until
            })
          }
        }
      )
    }
  }

  const handleDisableBusyMode = () => {
    eventLogger({
      name: 'busy_mode_disable_btn'
    })
    disableBusyMode()
  }

  if (!busyModeModal.isOpen) {
    return null
  }

  return (
    <BusyModeModal
      busyModeData={busyModeQuery.data}
      canEnableBusyMode={canEnableBusyMode}
      isBusyModeDisabling={busyModeDisableMutation.isLoading}
      isBusyModeEnabling={busyModeEnableMutation.isLoading}
      isLoading={busyModeQuery.isLoading}
      placeId={placeId}
      onClose={onCloseWithTracker}
      onDisableBusyModeClick={handleDisableBusyMode}
      onEnableBusyModeClick={handleEnableBusyMode}
    />
  )
}
