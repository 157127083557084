import {CampaignStatus, CampaignType} from '../../types/campaign'

const campainStatusOrder: Record<CampaignStatus, number> = {
  [CampaignStatus.ACTIVE]: 0,
  [CampaignStatus.SUSPENDED]: 1,
  [CampaignStatus.PAUSED]: 2,
  [CampaignStatus.PROCESS]: 3,
  [CampaignStatus.UPDATING]: 4,
  [CampaignStatus.CREATE_ERROR]: 5,
  [CampaignStatus.ARCHIVED]: 6,
  [CampaignStatus.ACTIVATION_REQUIRED]: 7
}

export function campaignStatusSorter(a: CampaignStatus, b: CampaignStatus): number {
  return campainStatusOrder[a] - campainStatusOrder[b]
}

const campainTypeOrder: Record<CampaignType, number> = {
  [CampaignType.cpc]: 0,
  [CampaignType.cpa]: 1,
  [CampaignType.cpm]: 2
}

export function campaignTypeSorter(a: CampaignType, b: CampaignType): number {
  return campainTypeOrder[a] - campainTypeOrder[b]
}
