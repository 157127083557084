import {useCallback} from 'react'
import {useQueryState} from 'react-router-use-location-state'

type UsePromocodeTypeResult = {
  value: string
  set: (value: string) => void
  reset: () => void
}

export const usePromocodeType = (): UsePromocodeTypeResult => {
  const [value, setValue] = useQueryState('promocode_type', '')

  const reset = useCallback(() => {
    if (value !== '') {
      setValue('')
    }
  }, [value, setValue])

  return {
    value,
    set: setValue,
    reset
  }
}
