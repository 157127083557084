import {get, set} from 'lodash-es'

import type {InfiniteQueryAdapter, Cursor} from './types'

export const defaultAdapter: InfiniteQueryAdapter = {
  getCursor: (page) => {
    const cursor = get(page, 'meta.cursor') as unknown
    return ['string', 'number'].includes(typeof cursor) ? (cursor as Cursor) : undefined
  },
  setCursor(cfg, cursor) {
    set(cfg, 'params.cursor', cursor ?? get(cfg, 'params.cursor'))
    return cfg
  }
}

export const visibilityIndexAdapter: InfiniteQueryAdapter<
  string,
  '/4.0/restapp-front/reports/v1/index-visibility/places'
> = {
  getCursor: (page) => page.meta.cursor,
  setCursor(cfg, cursor) {
    if (cfg.body) {
      cfg.body.meta.cursor = cursor ?? cfg.body?.meta?.cursor
    }
    return cfg
  }
}

export const placeLeadersInfoListAdapter: InfiniteQueryAdapter<
  string | undefined,
  '/4.0/restapp-front/eats-place-leaders/v1/states'
> = {
  getCursor: (page) => page.cursor,
  setCursor: (cfg, cursor) => {
    if (cfg.body) {
      cfg.body.cursor = cursor ?? cfg.body?.cursor
    }
    return cfg
  }
}

export const placesSearchAdapter: InfiniteQueryAdapter<string | undefined, '/4.0/restapp-front/places/v2/search'> = {
  getCursor: (page) => page.meta.cursor,
  setCursor: (cfg, cursor) => {
    cfg.params.cursor = cursor ?? cfg.params.cursor
    return cfg
  }
}

export const promoListAdapter: InfiniteQueryAdapter<number | undefined, '/4.0/restapp-front/promo/v1/promo/list'> = {
  getCursor: (page) => page.meta.cursor,
  setCursor: (cfg, cursor) => {
    cfg.params.after = cursor ?? cfg.params.after
    return cfg
  }
}

export const oldChatsListAdapter: InfiniteQueryAdapter<
  number | undefined,
  '/4.0/restapp-front/support_chat/v1/chat/list'
> = {
  getCursor: (lastPage, pages) => {
    if (lastPage.chats.length === 0) {
      return undefined
    }

    return pages.reduce((chatsCount, page) => chatsCount + page.chats.length, 0)
  },
  setCursor: (cfg, cursor) => {
    cfg.params.offset = cursor ?? cfg.params.offset
    return cfg
  }
}

export const chatsListAdapter: InfiniteQueryAdapter<
  string | undefined,
  '/4.0/restapp-front/support_chat/v2/chat/list'
> = {
  getCursor: (lastPage) => lastPage.meta?.next_page_token,
  setCursor: (cfg, cursor) => {
    cfg.body = {
      ...cfg.body,
      meta: {
        next_page_token: cursor
      }
    }

    return cfg
  }
}

export const reportsListAdapter: InfiniteQueryAdapter<
  string | undefined,
  '/4.0/restapp-front/restapp-reports/v1/reports/list'
> = {
  getCursor: (lastPage) => lastPage.meta.cursor,
  setCursor: (cfg, cursor) => {
    cfg.body = {
      ...cfg.body,
      cursor
    }

    return cfg
  }
}

export enum InfiniteQueryAdapters {
  DEFAULT = 'default',
  PLACES_LEADERS_LIST = 'placeLeadersInfoList',
  PLACES_SEARCH = 'placesSearch',
  PROMO_LIST = 'promoList',
  OLD_CHATS_LIST = 'oldChatsList',
  CHATS_LIST = 'chatsList',
  VISIBILITY_INDEX = 'visibilityIndex',
  REPORTS_LIST = 'reportsList'
}

export const ADAPTERS: Record<InfiniteQueryAdapters, InfiniteQueryAdapter> = {
  default: defaultAdapter,
  placeLeadersInfoList: placeLeadersInfoListAdapter as InfiniteQueryAdapter,
  placesSearch: placesSearchAdapter as InfiniteQueryAdapter,
  promoList: promoListAdapter as InfiniteQueryAdapter,
  oldChatsList: oldChatsListAdapter as InfiniteQueryAdapter,
  chatsList: chatsListAdapter as InfiniteQueryAdapter,
  visibilityIndex: visibilityIndexAdapter as InfiniteQueryAdapter,
  reportsList: reportsListAdapter as InfiniteQueryAdapter
}
