import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'

interface UseSubscriptionsParams {
  placesIds: number[]
}

type DefaultData = Parameters<
  NonNullable<ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/place/get-subscriptions', 'POST'>['select']>
>[0]

type UseSubscriptionsOptions<TSelectedData> = Pick<
  ApiQueryConfig<'/4.0/restapp-front/v1/place-subscriptions/v1/place/get-subscriptions', 'POST', TSelectedData>,
  'select' | 'enabled' | 'suspense'
>

export function useSubscriptions<TSelectedData = DefaultData>(
  {placesIds}: UseSubscriptionsParams,
  options?: UseSubscriptionsOptions<TSelectedData>
) {
  const sortedPlacesIds = [...placesIds].sort((a, b) => a - b)

  return useApiQuery({
    url: '/4.0/restapp-front/v1/place-subscriptions/v1/place/get-subscriptions',
    method: 'POST',
    body: {place_ids: sortedPlacesIds},
    ...options
  })
}
