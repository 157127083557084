import {makeStyles} from 'tss-react/mui'

import {S} from '../style'

export const useStyles = makeStyles<void, 'transparent' | 'iconWrap' | 'title' | 'children' | 'actionsWrap'>({
  name: 'SuggestionCard'
})((_theme, _props, classes) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 120,

      padding: '0 24px',
      background: '#F2F4F5',
      border: '2px solid #F2F4F5',
      borderRadius: '24px',
      boxSizing: 'border-box',

      [`&.${classes.transparent}`]: {
        background: 'transparent',
        borderColor: '#EDF0F1',

        [`& .${classes.iconWrap}`]: {
          background: '#F2F4F5'
        }
      },

      [S.mobileQuery]: {
        padding: 16
      }
    },
    transparent: {},
    iconWrap: {},
    cardMainDesktop: {
      display: 'flex',
      flex: 1,

      [S.mobileQuery]: {
        display: 'none'
      }
    },
    cardMainMobile: {
      display: 'none',
      flexDirection: 'column',
      width: '100%',

      [S.mobileQuery]: {
        display: 'flex'
      }
    },
    iconWrapDesktop: {
      position: 'relative',
      display: 'flex',
      width: 70,
      height: 70,
      marginRight: 18,
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,

      [S.mobileQuery]: {
        display: 'none'
      }
    },
    iconWrapMobile: {
      display: 'none',
      flexShrink: 0,
      width: 40,
      height: 40,
      marginRight: 8,

      [S.mobileQuery]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    titleWrapMobile: {
      display: 'none',
      alignItems: 'center',

      [S.mobileQuery]: {
        display: 'flex'
      }
    },
    textWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1
    },
    title: {
      margin: 0,
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '23px',

      [S.mobileQuery]: {
        fontSize: '16px',
        lineHeight: '19px'
      }
    },
    children: {
      marginTop: 6,
      fontSize: '16px',
      lineHeight: '19px',

      [S.mobileQuery]: {
        marginTop: 12,
        fontSize: '14px',
        lineHeight: '18px'
      }
    },
    actionsWrap: {
      minWidth: 180,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 55,
      flexShrink: 0,

      [S.mobileQuery]: {
        width: '100%',
        marginTop: 16,
        marginLeft: 0,
        justifyContent: 'flex-start'
      }
    },

    clickable: {
      cursor: 'pointer'
    },

    wrapper: {
      padding: '4px 0',
      '& + &': {
        borderTop: '1px solid #D2D0CC'
      }
    },

    compact: {
      background: 'transparent',
      padding: '12px 8px',
      minHeight: 'auto',
      border: 'none',
      borderRadius: 0,

      [`& .${classes.title}`]: {
        fontSize: '16px',
        lineHeight: '19px'
      },

      [`& .${classes.children}`]: {
        marginTop: 1,
        fontSize: '13px',
        lineHeight: '15px'
      },

      [`& .${classes.actionsWrap}`]: {
        minWidth: 'auto',
        width: 'auto',
        marginTop: 0
      },

      '&:hover': {
        background: '#F4F6F7',
        borderRadius: 16,
        transition: 'background-color .3s ease'
      }
    },
    cardMainCompact: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 1
    },
    iconWrapCompact: {
      position: 'relative',
      display: 'flex',
      width: 40,
      height: 40,
      marginRight: 16,
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0
    }
  }
})
