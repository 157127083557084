import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {UserAvatar, Typography, Clamp} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type MouseEventHandler, forwardRef} from 'react'

import {Tooltip} from '@restapp/shared-ui'

import styles from './SidebarAccountElement.module.css'

type SidebarAccountElementProps = {
  avatarSrc: string
  login: string
  mini: boolean
  cabinet?: string
  selected?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

export const SidebarAccountElement = forwardRef<HTMLDivElement, SidebarAccountElementProps>(
  ({avatarSrc, login, mini, cabinet, selected = false, onClick}, ref) => {
    const {t} = useI18n()

    const newYearConfig = useExp3('restapp_new_year')

    return (
      <Tooltip
        ref={ref}
        classes={{tooltip: styles.tooltip}}
        title={t('main-layout.sidebar.account-cabinet-tooltip', 'Личный кабинет')}
        disableHoverListener={!mini}
        disableFocusListener
        disableTouchListener
        placement='right'
      >
        <div
          data-testid={'sidebar-account-element' /*Сайдбар | Элемент с информацией об аккаунте */}
          className={cn(styles.nameWrapper, {
            [styles.newYearNameWrapper]: newYearConfig.enabled,
            [styles.paddingWhenMini]: mini,
            [styles.active]: selected
          })}
          onClick={onClick}
        >
          <div className={styles.iconLink}>
            <UserAvatar src={avatarSrc} />
          </div>
          {!mini && (
            <div className={styles.logoutWrapper}>
              <Typography variant='caption1' thickness='bold' style={{color: '#fff', marginBottom: 2}}>
                {login}
              </Typography>
              {!!cabinet && (
                <Typography variant='caption1'>
                  <Clamp className={styles.clamp} lines={1}>
                    {cabinet}
                  </Clamp>
                </Typography>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    )
  }
)
SidebarAccountElement.displayName = 'SidebarAccountElement'
