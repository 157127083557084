import {Box, Clamp, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {forwardRef, type HTMLAttributes, type ReactNode} from 'react'

import styles from './Alert.module.css'

type AlertProps = {
  className?: string
  startIcon?: ReactNode
  endIcon?: ReactNode
  text: ReactNode
  onClick?(): void
}

type InnerProps = AlertProps & HTMLAttributes<HTMLDivElement>

export const Alert = forwardRef<HTMLDivElement, InnerProps>(
  ({className, startIcon, endIcon, text, onClick, ...divAttributes}, ref) => (
    <Box
      ref={ref}
      className={cn(styles.root, className, Boolean(onClick) && styles.clickAble)}
      alignItems='center'
      gap='m'
      px='m'
      onClick={onClick}
      {...divAttributes}
    >
      {!!startIcon && <div className={styles.iconWrapper}>{startIcon}</div>}
      <Typography className={styles.text} flex={1} lineHeight='normal' variant='body2'>
        <Clamp lines={2}>{text}</Clamp>
      </Typography>
      {!!endIcon && <div className={styles.iconWrapper}>{endIcon}</div>}
    </Box>
  )
)
