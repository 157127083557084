import {useI18n} from '@eda-restapp/i18n'
import {errorLogger} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'
import React from 'react'

import {useApiQuery} from '@restapp/core-api'
import {useServiceTitle} from '@restapp/shared'
import {Spinner} from '@restapp/shared-ui'

import {usePassportName} from '../../../hooks'

type PassportBindingProps = {
  secret: string
  onBindingFinished: (isNewBind: boolean) => void
  onBindingFail: () => void
}

export const PassportBinding: React.FC<PassportBindingProps> = ({onBindingFinished, onBindingFail, secret}) => {
  const {t} = useI18n()
  const serviceTitle = useServiceTitle()
  const passportName = usePassportName()

  useApiQuery({
    url: '/4.0/restapp-front/partners/v2/passport/bind',
    body: {secret},
    method: 'POST',
    onSuccess: ({is_new_bind}) => {
      if (is_new_bind) {
        Snackbar.success({
          text: t('core-auth.passport-binding.success-bind-message', 'Успешная привязка аккаунта', {
            value: 'Аккаунт привязан. Теперь вы можете входить в {serviceTitle} через ваш аккаунт в {passportName}',
            serviceTitle,
            passportName
          })
        })
      } else {
        Snackbar.success({
          text: t('core-auth.passport-binding.success-login-message', 'Попытались привязать уже привязанный аккаунт', {
            value: 'Вы успешно авторизовались через {passportName}. Используйте его для входа в следующий раз',
            passportName
          })
        })
      }

      onBindingFinished(is_new_bind)
    },
    onError: (error) => {
      errorLogger({
        level: 'error',
        error: error instanceof Error ? error : new Error('passport:bind_error'),
        message: error instanceof Error ? error.message : 'passport:bind_error',
        additional: {eventSlug: 'passport:bind_error'}
      })

      Snackbar.error({
        text: error.responseData?.message || error.message,
        errorCode: error.traceId
      })

      onBindingFail()
    }
  })

  return (
    <Spinner
      slug='passport-binding-spinner'
      caption={t('core-auth.passport-binding.spinner', 'Привязываем паспортный аккаунт')}
      absolute
    />
  )
}
