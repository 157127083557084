import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {ISurvey} from '@restapp/shared-api'

import type {
  SetAnswer,
  SurveyAnswerPredefined,
  SurveyAnswerRating,
  SurveyAnswerText,
  SurveyState,
  CommunicationStatus
} from '../../types'
import {mapSurvey} from '../../utils/survey'

const initialState = (): SurveyState => null

const communicationsSurveySlice = createSlice({
  name: 'communicationsSurvey',
  initialState,
  reducers: {
    setSurveyStatus(state, action: PayloadAction<CommunicationStatus>) {
      if (!state) {
        return state
      }

      state.status = action.payload
    },
    setSurvey(state, action: PayloadAction<ISurvey>) {
      return mapSurvey(action.payload)
    },
    resetAnswers(state) {
      if (!state) {
        return state
      }

      state.content.pages.forEach((page) =>
        page.questions.forEach((question) => {
          const answer = question.answer

          switch (answer.type) {
            case 'text':
              answer.text = ''
              break
            case 'rating':
            case 'emoji':
              answer.rating = null
              break
            case 'single':
            case 'multiple':
              answer.answers.forEach((internalAnswer) => {
                if (internalAnswer.type === 'other') {
                  internalAnswer.text = ''
                }
                internalAnswer.selected = false
              })
          }
        })
      )
    },
    setSurveyAnswer(state, action: PayloadAction<SetAnswer>) {
      if (!state) {
        return state
      }

      const setAnswer = action.payload

      const page = state.content.pages[setAnswer.pageIndex]
      const question = page.questions[setAnswer.questionIndex]
      const answer = question.answer

      if (!answer || setAnswer.type !== answer.type) {
        return state
      }

      switch (setAnswer.type) {
        case 'text': {
          const textAnswer = answer as SurveyAnswerText
          textAnswer.text = setAnswer.value
          break
        }
        case 'rating': {
          const ratingAnswer = answer as SurveyAnswerRating
          const newRating = setAnswer.value
          if (newRating > ratingAnswer.max_rating || newRating < 1) {
            throw new Error('Rating must be lower or equal to max_rating and greater or equal 1')
          }
          ratingAnswer.rating = newRating
          break
        }
        case 'single': {
          const singleAnswer = answer as SurveyAnswerPredefined
          const internalAnswer = singleAnswer.answers[setAnswer.answerIndex]
          if (internalAnswer.type === 'other' && internalAnswer.selected && setAnswer.text) {
            internalAnswer.text = setAnswer.text
          } else {
            const prevSelected = singleAnswer.answers.find((answer) => answer.selected)
            if (prevSelected) {
              prevSelected.selected = false
            }
            internalAnswer.selected = true
          }
          break
        }
        case 'multiple': {
          const singleAnswer = answer as SurveyAnswerPredefined
          const internalAnswer = singleAnswer.answers[setAnswer.answerIndex]
          if (internalAnswer.type === 'other' && internalAnswer.selected && setAnswer.text) {
            internalAnswer.text = setAnswer.text
          } else {
            internalAnswer.selected = !internalAnswer.selected
          }
          break
        }
        default: {
          return state
        }
      }
    }
  }
})

export const {setSurvey, setSurveyStatus, setSurveyAnswer, resetAnswers} = communicationsSurveySlice.actions
export const communicationsSurveyReducer = communicationsSurveySlice.reducer
