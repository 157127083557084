import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, Drawer} from '@eda-restapp/ui'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import {useRelatedPartnersQuery} from '@restapp/main-chats/messenger/hooks/useRelatedPartnersQuery'
import {Button} from '@restapp/shared-ui/Button'
import {type DatePeriod, DatePeriodSelector, type DatePeriodValue} from '@restapp/shared-ui/DatePeriodSelector'
import {Option, Select} from '@restapp/shared-ui/Select'

import {chatsSlice, type ChatsFilters} from '../../slice/chatsSlice'
import type {IChat} from '../../types'
import {getChatStatusText} from '../../utils/getChatStatusText'

type ChatsFiltersModalProps = {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles()({
  bottomMargin: {
    marginBottom: 16
  }
})

function ChatsFiltersModal({open, onClose}: ChatsFiltersModalProps) {
  const filters = useSelector(chatsSlice.selectors.selectFilters)
  const dispatch = useDispatch()

  const {t} = useI18n()
  const {chatsTopicFilterEnabled} = useExp3('restapp_support')
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const {classes: c} = useStyles()

  const {selectedPlaceIds, setSelectedPlaceIds, places} = usePlaces()
  const authorsQuery = useRelatedPartnersQuery(selectedPlaceIds)
  const topicsQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/topics',
    method: 'GET',
    initialData: {topics: []}
  })

  const isAllPlacesSelected = selectedPlaceIds.length === places.length
  const isDirty = useSelector(chatsSlice.selectors.selectFiltersDirty) || !isAllPlacesSelected

  const resetHandler = () => {
    dispatch(chatsSlice.actions.resetFilters())
    setSelectedPlaceIds([])
    metrika({target: 'chat_reset_filter_click'})
    onClose()
  }

  function handlePeriodChange({
    period,
    from,
    to
  }: {
    period: Exclude<DatePeriod, 'all' | 'days' | 'day'>
    from: Required<DatePeriodValue>['from']
    to: Required<DatePeriodValue>['to']
  }) {
    dispatch(chatsSlice.actions.setPeriodFilter(period))
    dispatch(chatsSlice.actions.setDateFromFilter(from))
    dispatch(chatsSlice.actions.setDateToFilter(to))
  }

  const statuses: IChat['status'][] = ['open', 'pending', 'solved']

  return (
    <Drawer.Panel opened={open} onClose={onClose}>
      <Drawer.Container>
        <Drawer.Header
          onClose={onClose}
          crossBtn
          text={t('main-chats.chats-filters-modal.filtri-i-sortirovka', 'Фильтры и сортировка')}
        />
        <Drawer.Content>
          <Box p='m' flexDirection='column'>
            {!supportMultiChatConfig.enableUnifiedChatsListEndpoint && (
              <Select
                className={c.bottomMargin}
                value={filters.sortOrder}
                onChangeValue={(sortOrder: ChatsFilters['sortOrder']) =>
                  dispatch(chatsSlice.actions.setSortOrderFilter(sortOrder))
                }
                fullWidth
              >
                <Option value='desc'>{t('main-chats.chats-filters-modal.snachala-novie', 'Сначала новые')}</Option>
                <Option value='asc'>{t('main-chats.chats-filters-modal.snachala-starie', 'Сначала старые')}</Option>
              </Select>
            )}
            <Select
              className={c.bottomMargin}
              value={filters.status}
              onChangeValue={(status: ChatsFilters['status']) => dispatch(chatsSlice.actions.setStatusFilter(status))}
              fullWidth
            >
              <Option value='all'>{t('main-chats.chats-filters-modal.vse-statusi', 'Все статусы')}</Option>
              {statuses.map((status) => (
                <Option key={status} value={status}>
                  {getChatStatusText(status)}
                </Option>
              ))}
            </Select>
            {chatsTopicFilterEnabled && (
              <Select
                className={c.bottomMargin}
                fullWidth
                value={filters.topic}
                onChangeValue={(topic: ChatsFilters['topic']) => dispatch(chatsSlice.actions.setTopicFilter(topic))}
              >
                <Option value='all'>{t('main-chats.chats-filters-modal.vse-temi', 'Все темы')}</Option>
                {topicsQuery.data?.topics.map((topic) => (
                  <Option key={topic} value={topic}>
                    {topic}
                  </Option>
                ))}
              </Select>
            )}
            <Select
              className={c.bottomMargin}
              value={filters.author}
              onChangeValue={(author: ChatsFilters['author']) => dispatch(chatsSlice.actions.setAuthorFilter(author))}
              fullWidth
            >
              <Option value='all'>{t('main-chats.chats-filters-modal.vse-avtori', 'Все авторы')}</Option>
              {authorsQuery.data?.partners.map((author) => (
                <Option
                  key={'partner_id' in author ? author.partner_id : author.partner_uuid}
                  value={'partner_id' in author ? author.partner_id : author.partner_uuid}
                >
                  {author.email}
                </Option>
              ))}
            </Select>
            <DatePeriodSelector
              value={{period: filters.period, from: filters.dateFrom, to: filters.dateTo || undefined}}
              onChange={handlePeriodChange}
              fullWidth
            />
          </Box>
        </Drawer.Content>
        {isDirty && (
          <Drawer.Footer>
            <Button onClick={resetHandler} fullWidth variant='contained' size='large'>
              {t('main-chats.chats-filters-modal.sbrosit-filtri', 'Сбросить фильтры')}
            </Button>
          </Drawer.Footer>
        )}
      </Drawer.Container>
    </Drawer.Panel>
  )
}

export default ChatsFiltersModal
