import type {ApiErrorResponse} from '@restapp/shared-api'

type QueryStatus = 'idle' | 'loading' | 'error' | 'success'

/**
 * If the mutation is loading, idle, or errored, return that status. If the polling
 * is errored or there's an error, return error. If the polling is successful and
 * polling is not active, return success. If the mutation is successful and polling
 * is not active, return success. Otherwise, return polling
 * @param {QueryStatus} mutationStatus - The status of the mutation.
 * @param {QueryStatus} pollingStatus - The status of the polling query
 * @param {ApiErrorResponse | null | undefined} error - The error from the mutation
 * @param {boolean} pollingActive - boolean
 * @returns A function that takes in 4 arguments and returns a string.
 */
const getStatus = (
  mutationStatus: QueryStatus,
  pollingStatus: QueryStatus,
  error: ApiErrorResponse | null | undefined,
  pollingActive: boolean
): QueryStatus | 'polling' => {
  if (['idle', 'loading', 'error'].includes(mutationStatus)) {
    return mutationStatus
  } else if (pollingStatus === 'error' || error) {
    return 'error'
  } else if (pollingStatus === 'success' && !pollingActive) {
    return 'success'
  }

  if (mutationStatus === 'success' && !pollingActive) {
    return 'success'
  }

  return 'polling'
}

export default getStatus
