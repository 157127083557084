import type {INewsItem} from '@restapp/shared-api'

import type {CommunicationStatus, NewsItem} from '../types'
import {CommunicationType, NewsFilter} from '../types'

export function mapNewsItem(newsItem: INewsItem): NewsItem {
  return {
    type: CommunicationType.NEWS,
    status: newsItem.status as CommunicationStatus,
    id: newsItem.id,
    created: newsItem.created,
    ...newsItem.payload
  }
}

export function getTopic(filter: NewsFilter) {
  return filter === NewsFilter.ALL ? undefined : filter
}
