import React, {type FC} from 'react'

import type {modalFooterStyles} from './ModalFooter.styles'
import useModalFooterStyles from './ModalFooter.styles'

type ModalFooterProps = {
  children: React.ReactNode
} & {
  className?: string
  classes?: Partial<Record<keyof typeof modalFooterStyles, string>>
}

export const ModalFooter: FC<ModalFooterProps> = ({children, classes}) => {
  const {classes: c} = useModalFooterStyles(undefined, {props: {classes}})

  return <div className={c.root}>{children}</div>
}
