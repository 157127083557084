import {useExp3} from '@eda-restapp/configs'
import {useMemo} from 'react'

import {useOldHeavyPlaces} from '@restapp/core-places'

const useIsPromotionAvailable = () => {
  const {allowed_business_types, enabled} = useExp3('restapp_promotion')
  const {places} = useOldHeavyPlaces({enabled})

  const hasPlacesWithAllowedTypes = useMemo(
    () =>
      allowed_business_types.length
        ? places?.some((place) => allowed_business_types.includes(place.brand.business_type))
        : true,
    [places, allowed_business_types]
  )

  return enabled && hasPlacesWithAllowedTypes
}

export default useIsPromotionAvailable
