import type {TariffType} from 'packages/shared-api'
import {useLocation, useNavigate} from 'react-router-dom'

type TState = {
  prevPath?: string
  isOpenTariffModal?: boolean
  placesIds?: number[]
  tab?: TariffType
}

export const useSubscriptionPagesLocationState = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as TState | null

  const goTo = ({pathname, state}: {pathname: string; state: TState}) => {
    navigate({pathname}, {state})
  }

  return {
    goTo,
    ...locationState,
    tab:
      locationState?.tab && ['free', 'business', 'business_plus'].includes(locationState.tab)
        ? locationState.tab
        : undefined
  }
}
