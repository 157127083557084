import {makeStyles} from 'tss-react/mui'

const cardBorderRadius = '24px'

export const useSubscriptionTariffCardStyles = makeStyles<
  {isSmallFormat?: boolean},
  'button' | 'recommendedBlock' | 'recommendedLabel' | 'root' | 'badge' | 'price' | 'pricePeriod'
>({
  name: 'SubscriptionTariffCard'
})((theme, _params, classes) => {
  return {
    root: {
      position: 'relative',
      borderRadius: cardBorderRadius,
      flexShrink: 0,
      width: _params.isSmallFormat ? 220 : undefined,
      minHeight: 356,

      [theme.breakpoints.up('md')]: {
        width: _params.isSmallFormat ? 180 : undefined,
        minHeight: 275
      }
    },
    wrap: {
      height: '100%',
      position: 'relative',
      borderRadius: cardBorderRadius,
      overflow: 'hidden',
      background:
        'radial-gradient(261.47% 80.7% at 93.59% 19.3%, rgba(227, 227, 227, 0.26) 0%, rgba(255, 255, 255, 0) 100%), #F7F9FA'
    },
    inner: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: _params.isSmallFormat ? '16px' : '24px 24px 16px',
      borderRadius: cardBorderRadius,

      [theme.breakpoints.up('md')]: {
        padding: _params.isSmallFormat ? '16px 8px 16px' : '24px 24px 16px'
      }
    },
    free: {
      background: 'transparent'
    },
    business: {
      background:
        'linear-gradient(150deg, rgba(255, 255, 255, 0) 0%, rgba(149, 66, 255, 0.13) 21%, rgba(149, 66, 255, 0.22) 32%, rgba(149, 66, 255, 0.1) 63%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%)'
    },
    business_plus: {
      background:
        'linear-gradient(150deg, rgba(255, 255, 255, 0) 0%, rgba(29,79,153, 0.13) 21%, rgba(29,79,153, 0.22) 32%, rgba(29,79,153, 0.1) 63%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%)'
    },
    price: {
      marginTop: _params.isSmallFormat ? 8 : 12,
      padding: _params.isSmallFormat ? '10px 0' : '14px 0',
      textAlign: _params.isSmallFormat ? 'center' : 'left',
      fontWeight: 700,
      fontSize: _params.isSmallFormat ? '20px' : '32px',
      lineHeight: _params.isSmallFormat ? '23px' : 1,
      letterSpacing: '-0.01em',
      fontFeatureSettings: "'pnum' on, 'lnum' on"
    },
    pricePeriod: {
      fontWeight: 400,
      fontSize: _params.isSmallFormat ? '16px' : '24px'
    },
    priceStar: {
      fontWeight: 400,
      fontSize: _params.isSmallFormat ? '16px' : '24px'
    },
    oldPriceWrap: {
      display: 'flex',
      marginTop: '-7px',
      marginBottom: 10,
      minHeight: 16,
      justifyContent: _params.isSmallFormat ? 'center' : undefined
    },
    oldPrice: {
      position: 'relative',
      fontSize: _params.isSmallFormat ? '14px' : '20px',
      lineHeight: _params.isSmallFormat ? '16px' : 1,
      color: '#9E9B98',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: '1.5px',
        top: '50%',
        left: '-1px',
        right: '-1px',
        backgroundColor: '#FC5230',
        opacity: '0.6',
        transform: 'rotate(-3deg)',
        transformOrigin: 'left'
      }
    },
    badge: {
      margin: _params.isSmallFormat ? '0 auto' : 0
    },
    description: {
      marginTop: 8,
      fontSize: '13px',
      lineHeight: '14px',
      fontFeatureSettings: '"pnum" on, "lnum" on',
      color: '#999C9E'
    },
    featuresList: {
      marginTop: 34,

      [theme.breakpoints.up('md')]: {
        marginTop: 16
      }
    },
    feature: {
      display: 'grid',
      gridTemplateColumns: '16px auto',
      columnGap: _params.isSmallFormat ? 8 : 16,
      padding: _params.isSmallFormat ? '4px 0' : '12px 0',
      alignItems: 'center',
      fontSize: _params.isSmallFormat ? '13px' : '16px',
      lineHeight: '16px',

      '& + &': {
        marginTop: _params.isSmallFormat ? 8 : 4
      }
    },
    featureText: {
      position: 'relative'
    },
    unavailableFeature: {
      color: '#BEC1C4'
    },
    highlightedFeature: {
      fontWeight: 700
    },
    featureBadge: {
      transform: 'rotate(10deg) translateY(-2px)',
      textAlign: 'center',
      width: 28,
      height: 14,
      borderRadius: '100px',
      fontSize: 9,
      lineHeight: '13px',
      fontWeight: 500,
      display: 'inline-block'
    },
    featureBadgeWrap: {
      whiteSpace: 'nowrap'
    },
    highlightedBadge: {
      color: '#000',
      background: '#FAE436',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'
    },
    newBadge: {
      color: '#FFFFFF',
      backgroundColor: '#FC5230',
      boxShadow: '0px 2px 5px rgba(212, 65, 46, 0.3)'
    },
    button: {
      marginTop: _params.isSmallFormat ? 8 : 12
    },
    discountBadge: {
      position: 'absolute',
      top: '-5px',
      right: '-32px',
      width: 108,
      padding: '26px 0 4px',
      backgroundColor: '#FA6A3C',
      color: '#FFFFFF',
      transform: 'rotate(43deg)',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '15px',

      [theme.breakpoints.up('md')]: {
        top: '-5px',
        right: '-26px',
        width: 82,
        padding: '18px 0 4px'
      }
    },
    active: {
      [`& .${classes.button}`]: {
        color: '#999C9E',
        border: _params.isSmallFormat ? '1px solid #BEC1C4' : undefined,
        backgroundColor: _params.isSmallFormat ? 'transparent' : '#EDF0F1',
        boxShadow: 'none'
      }
    },
    smallText: {
      padding: _params.isSmallFormat ? '4px' : '7px 0',
      marginTop: _params.isSmallFormat ? 8 : 4,
      fontSize: _params.isSmallFormat ? 9 : 11,
      lineHeight: _params.isSmallFormat ? '11px' : '13px',
      color: '#999C9E'
    },
    recommended: {
      position: 'relative',
      [`& .${classes.recommendedBlock}`]: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: '-3px',
        right: '-3px',
        bottom: '-3px',
        background: 'linear-gradient(105.05deg, #8142D2 19.87%, #590DBC 74.17%)',
        borderRadius: '0 0 24px 24px'
      },
      [`& .${classes.recommendedLabel}`]: {
        display: 'block',
        padding: 8,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '100%',
        background: 'linear-gradient(105.05deg, #8142D2 19.87%, #590DBC 74.17%)',
        borderRadius: '24px 24px 0 0',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '18px',
        color: '#FFFFFF',
        textAlign: 'center'
      }
    },
    recommendedBlock: {
      display: 'none'
    },
    recommendedLabel: {
      display: 'none'
    },
    fewCards: {
      [`&.${classes.root}`]: {
        width: 270
      },

      [`& .${classes.badge}`]: {
        margin: 0
      },

      [`& .${classes.price}`]: {
        textAlign: 'left',
        fontSize: 32
      },

      [`& .${classes.pricePeriod}`]: {
        fontSize: 24
      }
    }
  }
})
