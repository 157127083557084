export const roundClosestTenth = (num: number, decimalQuantity = 1): number => {
  if (decimalQuantity <= 0) {
    return num
  }

  const newStringLength = 1 + decimalQuantity
  const decimal = +'10'.padEnd(newStringLength, '0')

  return Math.round(num * decimal) / decimal
}
