import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => {
  return {
    root: {
      color: '#1F2021',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
      borderRadius: '12px',
      backgroundColor: '#EDF0F1',
      padding: '11px 16px',
      marginRight: 8,
      marginBottom: 8,
      minWidth: 'auto',
      maxWidth: 'auto',
      textTransform: 'unset',
      opacity: 1,

      '&:hover': {
        backgroundColor: '#E2E4E7'
      }
    },

    selected: {
      '&&': {
        backgroundColor: '#FCE45E',
        color: '#1F2021',
        '&:hover': {
          backgroundColor: '#FCE45E'
        }
      }
    }
  }
})
