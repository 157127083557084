import type {ComponentClass, ComponentType, ReactNode} from 'react'
import React from 'react'

import type {SectionSlug} from '@restapp/shared-api'
import {
  Home,
  Place,
  Statistics,
  Feedback,
  Clock,
  LocationPoint,
  Timer,
  Trending,
  Tags,
  Notifications,
  CafeIcon,
  ChatIcon,
  Settings,
  Tools,
  QuestionFill,
  LikeFill,
  DocTextFill
} from '@eda-restapp/ui'
import {FINANCE_PATH_PREFIX} from '@restapp/finance/constants'

type AppMap = Partial<
  Record<
    SectionSlug,
    {
      path: string
      icon: ReactNode
      component:
        | React.LazyExoticComponent<ComponentType>
        | React.LazyExoticComponent<React.FC>
        | React.LazyExoticComponent<ComponentClass>
    }
  >
>

type LinkMap = Partial<
  Record<
    SectionSlug,
    {
      icon: ReactNode
    }
  >
>

// TODO: сделать хук
export const AppMapping: AppMap = {
  dashboard: {
    path: '/home',
    icon: <Home />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-home" */ '@restapp/main-home'))
  },
  places: {
    path: '/places',
    icon: <Place />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-places" */ '@restapp/main-places'))
  },
  menu: {
    path: '/menu',
    icon: <CafeIcon />,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "@restapp/main-menu/components/MenuPage" */ '@restapp/main-menu/components/Menu/MenuPage'
        )
    )
  },
  metrics: {
    path: '/metrics',
    icon: <Statistics />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-statistics" */ '@restapp/main-statistics'))
  },
  place_leaders: {
    path: '/place-leaders',
    icon: <Statistics />,
    component: React.lazy(
      () => import(/* webpackChunkName: "@restapp/main-place-leaders" */ '@restapp/main-place-leaders')
    )
  },
  tools: {
    path: '/tools',
    icon: <Tools />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-tools" */ '@restapp/main-tools'))
  },
  feedback: {
    path: '/feedback',
    icon: <Feedback />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-feedback" */ '@restapp/main-feedback'))
  },
  schedule: {
    path: '/schedule',
    icon: <Clock />,
    component: React.lazy(() => import(/* webpackChunkName: "Schedule" */ '../../pages/Settings/Schedule/Schedule'))
  },
  'shipping-zone': {
    path: '/shipping-zone',
    icon: <LocationPoint />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/delivery-zones" */ '@restapp/delivery-zones'))
  },
  cooking: {
    path: '/cooking',
    icon: <Timer />,
    component: React.lazy(() => import(/* webpackChunkName: "CookingTime" */ '@restapp/cooking-time'))
  },
  promotion: {
    path: '/promotion',
    icon: <Trending />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/promotion" */ '@restapp/promotion'))
  },
  promos: {
    path: '/promos',
    icon: <Tags />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-promos" */ '@restapp/main-promos'))
  },
  notifications: {
    path: '/notifications',
    icon: <Notifications />,
    component: React.lazy(
      () => import(/* webpackChunkName: "@restapp/main-communications" */ '@restapp/main-communications')
    )
  },
  chats: {
    path: '/chats',
    icon: <ChatIcon />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-chats" */ '@restapp/main-chats'))
  },
  finance: {
    path: FINANCE_PATH_PREFIX,
    icon: <DocTextFill />,
    component: React.lazy(() =>
      import(/* webpackChunkName: "@restapp/finance */ '@restapp/finance/pages/FinanceRoot').then((module) => {
        return {default: module.FinanceRootContainer}
      })
    )
  },
  'integrations-testing': {
    path: '/teststand',
    icon: <Settings />,
    component: React.lazy(() => import(/* webpackChunkName: "@restapp/main-teststand" */ '@restapp/main-teststand'))
  }
}

export const LinkMapping: LinkMap = {
  support: {
    icon: <QuestionFill />
  },
  suggest_idea: {
    icon: <LikeFill />
  }
}
