import {makeStyles} from 'tss-react/mui'

const useAuthStyles = makeStyles({name: 'Auth'})((theme) => {
  return {
    root: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        maxWidth: 506
      }
    },
    inputWrap: {
      width: '100%',
      position: 'relative'
    },
    input: {
      width: '100%',

      '& .MuiInputLabel-formControl': {
        position: 'inherit',
        color: '#9E9B98'
      }
    },
    bottomGroup: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    buttonOnLogin: {
      height: '52px',
      fontSize: 16,
      width: '100%'
    },
    forgotPassword: {
      color: '#999C9E',
      fontWeight: 500,
      height: 56,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    divider: {
      width: '32px',
      height: '1px',
      backgroundColor: '#999C9E'
    },
    passportAuthContainer: {
      width: '100%'
    },
    passportAuthCaption: {
      display: 'inline',
      textAlign: 'center'
    }
  }
})

export default useAuthStyles
