import {errorLogger} from '@eda-restapp/logger'

import {useApiQueryInvalidate, useAsyncMutation} from '@restapp/core-api'
import type {CategoryUpdate, MenuItemUpdate} from '@restapp/shared-api'

import {getErrorFromRevisionResult} from '../utils'

type CategoryStopListUpdate = Pick<CategoryUpdate, 'id' | 'reactivatedAt' | 'available'>[]
type MenuItemStopListUpdate = Pick<MenuItemUpdate, 'id' | 'reactivatedAt' | 'available'>[]

type UpdateMutation = {
  items?: MenuItemStopListUpdate
  categories?: CategoryStopListUpdate
}

type Params = {
  placeId: number
  revision: string
}

type Options = {
  onSuccess?: () => void
  onError?: () => void
}

const SAVE_MENU_TIMEOUT = 30000

const useToggleStopList = ({placeId, revision}: Params, opt?: Options) => {
  const {invalidate: invalidateMenu, isInProgress: menuInvalidationInProgress} = useApiQueryInvalidate(
    ['/4.0/restapp-front/eats-restapp-menu/v1/menu/active', 'GET'],
    {},
    opt?.onSuccess
  )

  const {mutate, isLoading, isPolling} = useAsyncMutation(
    {
      url: '/4.0/restapp-front/eats-restapp-menu/v1/menu/update',
      method: 'PATCH',
      polling: {
        url: '/4.0/restapp-front/eats-restapp-menu/v1/menu/status',
        method: 'GET',
        params: (response) => {
          return {
            place_id: placeId,
            revision: response.revision
          }
        },
        stopPredicate: ({status}) => status === 'applied',
        getError: getErrorFromRevisionResult,
        interval: 3000,
        timeout: SAVE_MENU_TIMEOUT,
        retry: false
      },
      // если ручка ответила applied сразу же, то можно не поллиться а сразу завершить запрос
      preventPolling: (response) => response.status === 'applied' || !response.revision,
      getMutationError: getErrorFromRevisionResult
    },
    {
      onSuccess: () => invalidateMenu(),
      onError: (err, snackbar, traceId) => {
        errorLogger({
          sourceType: 'useToggleStopList',
          level: 'error',
          additional: {err, traceId},
          error: new Error(err?.message || 'error toggling stop list')
        })
        snackbar?.enqueue({type: 'error', error: {traceId: traceId || err?.code}, message: err?.message})
        opt?.onError?.()
      }
    }
  )

  const updateMenu = ({categories = [], items = []}: UpdateMutation) =>
    mutate({
      params: {place_id: placeId, revision},
      body: {menu: {categories, items}}
    })

  return {
    toggleStopListLoading: isPolling || isLoading || menuInvalidationInProgress,
    toggleStopList: updateMenu
  }
}

export default useToggleStopList
