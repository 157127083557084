import {useDispatch, useSelector} from 'react-redux'

import {useUserInfo} from '../../hooks'
import {selectPartnerId} from '../../selectors'
import {setPartnerId} from '../../slice'

export const useOldAuthPartnerIdSetup = () => {
  const dispatch = useDispatch()
  const partnerId = useSelector(selectPartnerId)
  const userInfo = useUserInfo()

  const isAuthorizedOldWay = userInfo?.type === 'eats'
  const isNeedToDispatch = isAuthorizedOldWay && partnerId !== userInfo?.partner_uuid

  if (isNeedToDispatch) {
    dispatch(setPartnerId(userInfo?.partner_uuid))
  }
}
