import {createSlice} from '@reduxjs/toolkit'

type NetworkState = {
  isOffline: boolean
}

const initialState: NetworkState = {isOffline: false}

const networkSlice = createSlice({
  name: 'network',
  initialState: () => initialState,
  reducers: {
    setIsOffline(state, action) {
      state.isOffline = action.payload
    }
  }
})

export type NetworkSlice = {[networkSlice.name]: ReturnType<typeof networkSlice.reducer>}
export const {setIsOffline} = networkSlice.actions
export default networkSlice
