import type {Service} from './places'
import type {SectionSlug} from './sections'

export type FeedbackResponse = {
  /**
   * Является ли оценка свежей
   */
  is_fresh: boolean
  /**
   * Дата оценки (если есть)
   */
  created?: string
  score?: number
  /**
   * Отзыв партнера (если есть)
   */
  comment?: string
}

export type PlacesFeedbacksRequest = {
  place_ids: number[]
  predefined_comment_ids?: number[]
  cursor?: string
  limit?: number
  ratings?: number[]
  user_locale?: string
  to?: string
  from?: string
  services?: Service[]
  eater_name?: string
}

type CommentStatus = 'raw' | 'approved' | 'rejected'
export type PlacesFeedback = {
  feedback_answer_on: boolean
  place_id: number
  feedback: UserFeedback
}

export type UserFeedback = {
  id: number
  comment_status?: CommentStatus
  comment?: string
  rating: number
  service: 'dc' | 'eda'
  feedback_filled_at: string
  predefined_comments: Array<{
    id: number
    title: string
  }>
  order_nr: string
  actual: boolean
  feedback_weight?: number
  eater_name?: string
  feedback_answers?: Array<{
    answer_to_feedback: string
    answer_moderation_status: CommentStatus
    coupon?: {
      value: number
      limit?: string
      expire_at: string
      percent: boolean
      currency_code: string
      answer_moderation_status?: CommentStatus
    }
  }>
  delivered_at?: string
}

export type FeedbackRequest = {
  slug: SectionSlug
  score: number
  /**
   * Отзыв партнера обрежется до первых N символов (из конфига)
   */
  comment?: string
}

export type FeedbackAnswer = {
  answer_to_feedback: string
  answer_moderation_status: 'raw' | 'approved' | 'rejected'
  coupon?: {
    value: number
    limit?: string
    expire_at: string
    percent: boolean
    currency_code: string
    answer_moderation_status?: 'raw' | 'approved' | 'rejected'
  }
}

export type ExportReviewsRequest = {
  filter: ReviewsFilters
  file_format: ReviewsFileFormat
  timezone?: string
  locale?: string
}

/*
 * No specs from 'arc
 * Фильтр отзывов для формирования отчета
 */
type ReviewsFilters = {
  predefined_comment_ids: number[]
  ratings: number[]
  /**
   * Id ресторана
   */
  place_ids: number[]
  /**
   * Начало периода выгрузки отзывов
   */
  dttm_start: string
  /**
   * Конец периода выгрузки отзывов
   */
  dttm_finish: string
}

export enum ReviewsFileFormat {
  CSV = 'csv',
  XLS = 'xls'
}

export type FeedbackAnswerResponse = {
  id: number
  text: string
  coupon?: {
    value: number
    reason: string
    type?: string
  }
}
