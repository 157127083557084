import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {useMemo} from 'react'
import {useQueryState} from 'react-router-use-location-state'

import {iframeId} from './AssentSubscriptionUzbekistan.const'

export function useAssentSubscriptionUzbekistan() {
  const [isOpen, setIsOpen] = useQueryState('assent-subscription', '')

  return useMemo(() => {
    return {
      open: () => setIsOpen('open'),
      close: () => setIsOpen(''),
      isOpen: !!isOpen
    }
  }, [isOpen, setIsOpen])
}

export function useTimeoutLogger() {
  return useMemo(() => {
    let id = 0

    return {
      start: () => {
        id = window.setTimeout(() => {
          errorLogger({
            sourceType: `${iframeId}-loading-error`,
            level: 'error',
            error: new Error(`${iframeId}-loading-timeout`)
          })
        }, 3_000)
      },

      stop: () => {
        window.clearTimeout(id)
        eventLogger({name: `${iframeId}-loading-successfully`})
      }
    }
  }, [])
}
