import drawRoundRect from './drawRoundRect'
import type {RectConfig} from './types'

const drawBlurredRoundRect = (
  ctx: CanvasRenderingContext2D,
  {top, left, width, height, fill, filter, radius}: RectConfig
) => {
  drawRoundRect(ctx, {top, left, width, height, radius, fill, filter})

  /**
   * Очерчивает четкую границу, испорченную блюром
   */
  if (filter) {
    drawRoundRect(ctx, {top, left, width, height, radius, clip: true})
  }
}

export default drawBlurredRoundRect
