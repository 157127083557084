import React from 'react'

import {useHasUnseenFeedback} from '@restapp/shared-feedback'

import SidebarBadge, {BadgesType} from '../SidebarBadge'

const UnseenFeedbackBadge: React.FC = () => {
  const {data: hasUnseenFeedback} = useHasUnseenFeedback()

  return hasUnseenFeedback ? <SidebarBadge type={BadgesType.NOTIFY_RED} /> : null
}

export default UnseenFeedbackBadge
