import type {ChangeEvent, FC} from 'react'
import React from 'react'
import type {RadioGroupProps as MUIRadioGroupProps} from '@mui/material'
import {RadioGroup as MUIRadioGroup} from '@mui/material'

import {eventLogger} from '@eda-restapp/logger'

export type RadioGroupProps = MUIRadioGroupProps & {
  slug?: string
}

const RadioGroup: FC<RadioGroupProps> = ({children, slug, onChange, ...props}) => {
  const onChangeWithTracker = (evt: ChangeEvent<HTMLInputElement>, value: string) => {
    eventLogger({name: 'radio_group_change', value: slug, additional: {value}})
    onChange?.(evt, value)
  }

  return (
    <MUIRadioGroup {...props} onChange={onChangeWithTracker}>
      {children}
    </MUIRadioGroup>
  )
}

export default RadioGroup
