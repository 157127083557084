import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

const URL = '/4.0/restapp-front/marketing/v1/ad/update-price'

type UseUpdateCPCProps = {
  onSuccess: () => void
}

export const useUpdateCPC = ({onSuccess}: UseUpdateCPCProps) => {
  const {isLoading, mutate} = useApiMutation(
    {
      url: URL,
      method: 'POST'
    },
    {
      onSuccess
    }
  )

  const update = (directToken: string, body: VendorApi[typeof URL]['POST']['body']) =>
    mutate({
      headers: {
        Authorization: directToken
      },
      body
    })

  return {
    updateCPC: update,
    isUpdatingCPC: isLoading
  }
}
