import {ChevronLeftMIcon, useIsMobileStrict} from '@eda-restapp/ui'
import React, {type FC} from 'react'
import {Link, type LinkProps, useNavigate} from 'react-router-dom'

import useBackButtonStyles from './BackButton.style'

type BackButtonProps = {
  /**
   * Если есть `to`, то onBack без дефолтного значения, т.е. если не задать явно, вызван не будет
   * Если `to` нет, то если onBack отсутствует, дефолтным будет возврат по истории
   */
  onBack?: () => void
  text?: string
  to?: LinkProps['to']
}

export const BackButton: FC<BackButtonProps> = ({onBack, text = '', to}) => {
  const isMobile = useIsMobileStrict()
  const navigate = useNavigate()
  const {classes: c} = useBackButtonStyles()

  const onClick = () => (onBack ? onBack() : navigate(-1))

  const content = (
    <>
      <ChevronLeftMIcon fontSize={isMobile ? 24 : 18} data-testid={'ui-backbutton'} />
      {!isMobile ? text : ''}
    </>
  )

  if (!!to) {
    return (
      <Link to={to} className={c.backButton}>
        {content}
      </Link>
    )
  }

  return (
    <div className={c.backButton} onClick={onClick}>
      {content}
    </div>
  )
}
