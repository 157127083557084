import {createSlice} from '@reduxjs/toolkit'
import {omit} from 'lodash-es'

import {
  applyCurrentState,
  createItem,
  removeCategory,
  removeItem,
  resetAll,
  setNormalizeItems,
  upsertItem
} from '../actions'
import {GROUPS_SLICE_NAME} from '../constants'
import {groupEntityAdapter, groupEntitySelectors} from '../entities'
import type {Group, GroupsSliceState} from '../types'
import {getGroupVirtualId} from '../utils'

export const slice = createSlice({
  name: GROUPS_SLICE_NAME,
  initialState: (): GroupsSliceState => {
    return {
      current: groupEntityAdapter.getInitialState(),
      initial: groupEntityAdapter.getInitialState()
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNormalizeItems, (state, action) => {
      groupEntityAdapter.removeAll(state.current)
      groupEntityAdapter.removeAll(state.initial)

      const result: Group[] = []

      for (const item of action.payload) {
        for (const group of item.modifierGroups) {
          result.push({
            ...omit(group, 'modifiers'),
            _categoryId: item.categoryId,
            _itemId: item.id,
            _virtualId: getGroupVirtualId(item.id, group.id)
          })
        }
      }

      groupEntityAdapter.setAll(state.current, result)
      groupEntityAdapter.setAll(state.initial, result)
    })

    builder.addCase(createItem, (state, action) => {
      for (const group of action.payload.modifierGroups) {
        const entity = {
          ...omit(group, 'modifiers'),
          _categoryId: action.payload.categoryId,
          _itemId: action.payload.id,
          _virtualId: getGroupVirtualId(action.payload.id, group.id)
        }

        groupEntityAdapter.setOne(state.current, entity)
      }
    })

    builder.addCase(upsertItem, (state, action) => {
      const groupsInItem = groupEntitySelectors
        .selectAll(state.current)
        .filter((group) => group._itemId === action.payload.id)
        .map((group) => group._virtualId)

      groupEntityAdapter.removeMany(state.current, groupsInItem)

      for (const group of action.payload.modifierGroups) {
        const entity = {
          ...omit(group, 'modifiers'),
          _categoryId: action.payload.categoryId,
          _itemId: action.payload.id,
          _virtualId: getGroupVirtualId(action.payload.id, group.id)
        }

        groupEntityAdapter.setOne(state.current, entity)
      }
    })

    builder.addCase(removeCategory, (state, action) => {
      const groupsInCategory = groupEntitySelectors
        .selectAll(state.current)
        .filter((group) => group._categoryId === action.payload)
        .map((group) => group._virtualId)

      groupEntityAdapter.removeMany(state.current, groupsInCategory)
    })

    builder.addCase(removeItem, (state, action) => {
      const groupsInItem = groupEntitySelectors
        .selectAll(state.current)
        .filter((group) => group._itemId === action.payload)
        .map((group) => group._virtualId)

      groupEntityAdapter.removeMany(state.current, groupsInItem)
    })

    builder.addCase(resetAll, (state) => {
      state.current = state.initial
    })

    builder.addCase(applyCurrentState, (state) => {
      state.initial = state.current
    })
  }
})
