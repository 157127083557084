import {useI18n} from '@eda-restapp/i18n'
import {Box, Button} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import {OauthButton} from '../../../../passport'

import styles from './PassportAuth.module.css'

export type PassportAuthProps = {
  onSwitchAuth?: () => void
}

export const PassportAuth: FC<PassportAuthProps> = ({onSwitchAuth}) => {
  const {t} = useI18n()

  return (
    <Box className={styles.passportAuthContainer} flex={1} flexDirection='column' gap='m' alignItems='center'>
      <OauthButton />
      {onSwitchAuth && (
        <Button.Text variant='transparent' onClick={onSwitchAuth}>
          {t('core-auth.passport-auth.or-login-with-email', 'или войти по электронной почте', {
            value: 'или войти по электронной почте'
          })}
        </Button.Text>
      )}
    </Box>
  )
}
