import type {TextBox} from '../../../types/common'

const splitTextToLines = ({
  lineHeight,
  text,
  box,
  ctx,
  canvasRect
}: {
  lineHeight: number
  text: string
  box: TextBox
  canvasRect: {width: number; height: number}
  ctx: CanvasRenderingContext2D
}) => {
  const words = text.split(' ').filter(Boolean)
  const lines = words.reduce(
    (acc: string[], word: string): string[] => {
      const [linePrevText, ...rest] = acc

      const lineText = `${linePrevText} ${word}`.trim()
      const metrics = ctx.measureText(lineText)
      const currentLineWidth = metrics.width

      if (!!linePrevText && currentLineWidth > box.width) {
        return [word].concat(acc)
      } else {
        return [lineText, ...rest]
      }
    },
    ['']
  )

  return lines.map((lineText, lineNumber) => {
    return {
      text: lineText,
      x: 'left' in box ? box.left : canvasRect.width - box.right,
      y:
        'top' in box
          ? box.top + (lines.length - lineNumber) * lineHeight
          : canvasRect.height - box.bottom - lineNumber * lineHeight
    }
  })
}

export default splitTextToLines
