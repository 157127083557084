import {useEffect, useState} from 'react'

import {useApiDeps} from '../useApiDeps'

type IsSocketConnected = boolean

export default function useSocketStatus(): IsSocketConnected {
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const {socketEvent$} = useApiDeps()

  useEffect(() => {
    const subscription = socketEvent$.status$.subscribe(setIsSocketConnected)

    return () => subscription.unsubscribe()
  }, [])

  return isSocketConnected
}
