import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {SnackbarDescription} from '@eda-restapp/snackbars'

type SnackbarState = SnackbarDescription[]

const initialState: SnackbarState = []

export const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    addSnackbar(state, action: PayloadAction<SnackbarDescription>) {
      state.push(action.payload)
    },
    removeSnackbar(state, action: PayloadAction<Pick<SnackbarDescription, 'id'>>) {
      return state.filter((snackbar) => snackbar.id !== action.payload.id)
    },
    removeOldestSnackbar(state) {
      return state.slice(1)
    },
    removeAllSnackbars() {
      return []
    }
  }
})

export const {addSnackbar, removeSnackbar, removeOldestSnackbar, removeAllSnackbars} = snackbarsSlice.actions
