import type {PlaceSmartPricesStatus} from '@restapp/shared-api'

import type {SmartPriceFormValues} from '../types'

export const SELECTED_TAB_OTHER_PRICE = 'other'
export const NO_SELECTED_TAB = 'no_selected'

export const getLastSmartPrice = (history: PlaceSmartPricesStatus['history']) => history[0]?.max_percent ?? ''

export const getPlacesSmartPricesFormattedList = (
  placesSmartPricesList?: PlaceSmartPricesStatus[]
): SmartPriceFormValues['placesSmartPricesFormattedList'] => {
  if (!placesSmartPricesList || !placesSmartPricesList.length) {
    return []
  }
  return placesSmartPricesList
    .filter((placeSmartPricesList) => placeSmartPricesList.status !== 'can_not_be_activated')
    .map(({place_id, status, history}: PlaceSmartPricesStatus) => {
      return {
        place_id,
        enabled: status === 'active',
        max_percent: getLastSmartPrice(history)
      }
    })
}
export const getPlacesSmartPricesCanNotBeActivated = (
  placesSmartPricesList?: PlaceSmartPricesStatus[]
): PlaceSmartPricesStatus[] => {
  if (!placesSmartPricesList || !placesSmartPricesList.length) {
    return []
  }
  return placesSmartPricesList.filter((placeSmartPricesList) => placeSmartPricesList.status === 'can_not_be_activated')
}

export const checkIsAllActive = (
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
) => placesSmartPricesFormattedList.every((placeSmartPrices) => placeSmartPrices.enabled)

export const checkIsAllDisable = (
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
) => placesSmartPricesFormattedList.every((placeSmartPrices) => !placeSmartPrices.enabled)

export const checkEmptyAllHistory = (placesSmartPricesList?: PlaceSmartPricesStatus[]) => {
  if (!placesSmartPricesList || !placesSmartPricesList.length) {
    return true
  }
  return placesSmartPricesList.every((placeSmartPrices) => placeSmartPrices.history.length === 0)
}

export const isEqualSmartPrices = (
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
) => {
  if (!placesSmartPricesFormattedList.length) {
    return true
  }
  return placesSmartPricesFormattedList.every(
    (placeSmartPrices) =>
      parseFloat(placeSmartPrices.max_percent) === parseFloat(placesSmartPricesFormattedList[0]?.max_percent)
  )
}

export const getCommonSmartPrice = ({
  placesSmartPricesFormattedList
}: {
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
}): string => {
  if (isEqualSmartPrices(placesSmartPricesFormattedList)) {
    return placesSmartPricesFormattedList[0]?.max_percent
  }
  return ''
}

export const getPlacesSmartPricesListWithDefaultPrice = ({
  placesSmartPricesFormattedList,
  defaultSmartPrice
}: {
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
  defaultSmartPrice: string
}): SmartPriceFormValues['placesSmartPricesFormattedList'] =>
  placesSmartPricesFormattedList.map((placeSmartPrice) => {
    return {
      ...placeSmartPrice,
      ...{
        enabled: true,
        max_percent: defaultSmartPrice
      }
    }
  })

export const getDefaultSmartPrice = ({
  placesSmartPricesFormattedList,
  smartPricesList
}: {
  placesSmartPricesFormattedList: SmartPriceFormValues['placesSmartPricesFormattedList']
  smartPricesList?: string[]
}): string => {
  const commonSmartPrice = getCommonSmartPrice({placesSmartPricesFormattedList})
  if (commonSmartPrice && smartPricesList?.includes(commonSmartPrice)) {
    return commonSmartPrice
  }
  if (commonSmartPrice || placesSmartPricesFormattedList.length === 1) {
    return SELECTED_TAB_OTHER_PRICE
  }
  return NO_SELECTED_TAB
}
