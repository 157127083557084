import {Box, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC} from 'react'

import styles from './ContentContainer.module.css'

type ContentContainerProps = {
  className?: string
  children?: React.ReactNode
}

export const ContentContainer: FC<ContentContainerProps> = ({className, children}) => {
  const isMobile = useIsMobileStrict()

  return (
    <Box className={cn(styles.root, className)} flexDirection='column' mt={isMobile ? 'm' : 'l'}>
      {children}
    </Box>
  )
}
