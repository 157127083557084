import {eventLogger, metrika} from '@eda-restapp/logger'
import {Snackbar} from '@eda-restapp/snackbars'

import {useApiMutation} from '@restapp/core-api'
import type {VendorApi} from '@restapp/shared-api'

import {PROMOTION_SUCCESS} from '../constants'

import useCampaignsApi from './useCampaignsApi'
import usePromotionRoutes from './usePromotionRoutes'

const URL = '/4.0/restapp-front/marketing/v1/ad/create-bulk'

export const useCreateCPC = () => {
  const {redirectToPlaces} = usePromotionRoutes()

  const {invalidate, isInvalidating} = useCampaignsApi({
    onInvalidationSuccess: () => {
      Snackbar.success({
        text: PROMOTION_SUCCESS.enable
      })
      redirectToPlaces()
    }
  })

  const sendPriceStartMetrika = () => metrika({target: 'show_promotion_restaurants_price_start'})
  const {isLoading, mutate} = useApiMutation(
    {
      url: URL,
      method: 'POST'
    },
    {
      onSuccess: () => {
        sendPriceStartMetrika()
        eventLogger({name: 'promotion:show_promotion_restaurants_price_start'})

        invalidate()
      }
    }
  )

  const createCPC = (directToken: string, body: VendorApi[typeof URL]['POST']['body']) =>
    mutate({
      headers: {
        Authorization: directToken
      },
      body
    })

  return {
    createCPC,
    isCreatingCPC: isLoading || isInvalidating
  }
}
