import {BehaviorSubject, Observable, Subject} from 'rxjs'

import type {SocketEvents} from '@restapp/core-legacy/types/socket'
import type {SocketEvent} from '@restapp/core-notifications'
import type {MeaningfulNotification} from '@restapp/core-notifications/XivaWebsocketObservable/types'

export default class SocketEventsMock extends Observable<MeaningfulNotification> {
  status$ = new BehaviorSubject(false)
  private event$ = new Subject<MeaningfulNotification>()

  constructor(event$?: Observable<MeaningfulNotification>) {
    super((observer) => {
      const sub = this.event$.subscribe(observer)

      if (event$) {
        sub.add(event$.subscribe(this.event$))
      }

      return sub
    })
  }

  send<K extends keyof SocketEvents>(event: SocketEvent<K>) {
    // Небольщой костыль - превращаем SocketEvent обратно в MeaningfullNotification, поскольку преобразование из MeaningfullNotification в SocketEvent теперь в useSocketEvent
    this.event$.next({
      operation: event.event as keyof PushEvents,
      message: JSON.stringify({eventData: event, id: 'id'})
    })
  }

  sendBrokenPlacePullAll() {
    this.event$.next({
      operation: 'place_pull_all',
      message: 'undefined'
    })
  }

  sendOrderChangedItems() {
    this.send({
      event: 'order_changed_items',
      orderId: 'abcdef-123456',
      orderNr: '123456-987654'
    })
  }

  sendOrderNew() {
    this.send({
      event: 'order_new',
      orderId: 'abcdef-123456',
      orderNr: '123456-987654'
    })
  }
}
