import {makeStyles} from 'tss-react/mui'

export const useLandingAppStyles = makeStyles({name: 'LandingApp'})((theme) => {
  return {
    root: {
      flex: '0 0 100%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: '#F4F6F7',
      overflow: 'auto',
      scrollbarWidth: 'none' /* Mozilla */,

      '&::-webkit-scrollbar': {
        display: 'none' /* Chrome */
      },

      [theme.breakpoints.up('md')]: {
        padding: 0,
        backgroundColor: '#FFFFFF'
      }
    },
    countrySelectorAuthBlock: {
      position: 'relative',
      width: '100%',
      zIndex: 1
    },
    countrySelectorButton: {
      position: 'absolute',
      top: 16,
      left: 16,
      display: 'flex',
      width: 48,
      minWidth: 'auto',
      height: 48,
      alignItems: 'center',
      background: '#EDF0F1',

      '&:hover, &:active, &.active': {
        color: '#21201F',
        background: '#E2E4E7'
      }
    },
    languageSelectorBlock: {
      position: 'relative',
      width: '100%',
      zIndex: 1
    },
    languageSelectorRoot: {
      position: 'absolute',
      top: 16,
      right: 16,
      '& .MuiButton-root': {
        color: '#21201F',
        display: 'block',
        background: '#EDF0F1',
        marginLeft: 'auto'
      },
      '&:hover .MuiButton-root, &:active .MuiButton-root': {
        color: '#21201F',
        background: '#E2E4E7'
      }
    },
    container: {
      padding: '0 16px',
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      paddingTop: 70,
      paddingBottom: 32,
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      borderRadius: '0 0 24px 24px',
      justifyContent: 'center',
      marginBottom: 8,

      [theme.breakpoints.up('md')]: {
        borderRadius: 0,
        paddingBottom: 0,
        paddingTop: 122
      }
    },

    containerWithOldAuthInformer: {
      paddingTop: '35vh',
      paddingBottom: 16,
      justifyContent: 'flex-start',

      '@media (max-height: 900px)': {
        paddingTop: '30vh'
      },

      '@media (max-height: 800px)': {
        paddingTop: '25vh'
      },

      '@media (max-height: 700px)': {
        paddingTop: '18vh'
      }
    },
    childrenBlock: {
      width: '100%'
    },
    oldAuthInformerBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    oldAuthInformer: {
      width: '100%',
      marginBottom: 8,

      [theme.breakpoints.up('md')]: {
        maxWidth: 506,
        marginBottom: 12
      }
    },
    footer: {
      padding: '12px 16px 0 16px',
      width: '100%',
      minHeight: '112px',
      backgroundColor: '#FFFFFF',
      borderRadius: '24px 24px 0 0',

      [theme.breakpoints.up('md')]: {
        margin: 'auto auto 0',
        paddingTop: 32,
        maxWidth: 506,
        borderRadius: 0,
        minHeight: '152px'
      }
    },
    footerWithOldAuthInformer: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 24
      }
    }
  }
})
