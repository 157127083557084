import {useI18n} from '@eda-restapp/i18n'
import {Box, Drawer} from '@eda-restapp/ui'
import {useCallback, type FC, lazy} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {ErrorBoundary, Suspense} from '@restapp/shared-boundary'

import {chatsSlice} from '../../../slice/chatsSlice'
import {SingleChatBadge} from '../SingleChatBadge'

const Widget = lazy(() =>
  import(/* webpackChunkName: "@restapp/main-chats" */ '../SingleChatWidget').then((module) => {
    return {default: module.SingleChatWidget}
  })
)

export const SingleChatDrawer: FC = () => {
  const {t} = useI18n()
  const opened = useSelector(chatsSlice.selectors.selectSingleChatOpen)
  const dispatch = useDispatch()
  const closeHandler = useCallback(() => dispatch(chatsSlice.actions.closeSingleChat()), [dispatch])

  return (
    <Drawer.Panel opened={opened} onClose={closeHandler}>
      <Drawer.Container>
        <Drawer.Header border crossBtn onClose={closeHandler}>
          {t('main-chats.messenger.single-chat-header', 'Поддержка')}

          <Box ml='s' style={{display: 'inline-flex', verticalAlign: 'middle'}}>
            <SingleChatBadge />
          </Box>
        </Drawer.Header>
        <Drawer.Content>
          <Box flexGrow={1} style={{position: 'relative'}}>
            <ErrorBoundary slug='messenger_single_chat_error_boundary'>
              <Suspense slug='messenger_single_chat_error_suspense'>
                <Widget />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Drawer.Content>
      </Drawer.Container>
    </Drawer.Panel>
  )
}
