const MS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24

const MS_IN_MINUTE = MS_IN_SECOND * SECONDS_IN_MINUTE // 1000 * 60
const MS_IN_HOUR = MS_IN_MINUTE * MINUTES_IN_HOUR // 1000 * 60 * 60
const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY // 1000 * 60 * 60 * 24

/**
 *  Return days, hours and minutes in timestamp
 */
export const getDaysHoursMinutes = (timeLeft: number) => {
  if (timeLeft <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0
    }
  }

  const days = Math.floor(timeLeft / MS_IN_DAY)
  const timeLeftHours = timeLeft - days * MS_IN_DAY

  const hours = Math.floor(timeLeftHours / MS_IN_HOUR)
  const timeLeftMinutes = timeLeftHours - hours * MS_IN_HOUR

  const minutes = Math.floor(timeLeftMinutes / MS_IN_MINUTE)

  return {
    days,
    hours,
    minutes
  }
}
