import {useExp3} from '@eda-restapp/configs'
import {
  type CountryCodeType,
  DEFAULT_SERVICE_BRAND_LANGUAGE,
  SUPPORTED_LANGUAGES,
  useDomainDividedCountriesInfo
} from '@eda-restapp/i18n'

import {useServiceBrand} from './useServiceBrand'

type UseAvailableForCountryLanguagesProps = {
  countryCode?: CountryCodeType | null
  isLoggedIn?: boolean
  canSeeKKLang?: boolean
}

export const useAvailableForCountryLanguages = ({
  countryCode,
  isLoggedIn,
  canSeeKKLang
}: UseAvailableForCountryLanguagesProps): SUPPORTED_LANGUAGES[] | null => {
  const commonConfig = useExp3('restapp_common')
  const serviceBrand = useServiceBrand()
  const domainDividedCountriesInfo = useDomainDividedCountriesInfo()

  if (!countryCode) {
    return null
  }

  const configAvailableCountryLanguages =
    commonConfig.configAvailableCountriesLanguages && commonConfig.configAvailableCountriesLanguages[countryCode]

  if (configAvailableCountryLanguages) {
    return configAvailableCountryLanguages as SUPPORTED_LANGUAGES[]
  }

  if (!serviceBrand) {
    return null
  }

  let resultAvailableLanguageCodes = domainDividedCountriesInfo[serviceBrand][countryCode]?.availableLanguageCodes

  // Adds additional KK-lang as available for KZ-operators
  if (
    isLoggedIn &&
    countryCode === 'KZ' &&
    canSeeKKLang &&
    resultAvailableLanguageCodes &&
    !resultAvailableLanguageCodes.includes(SUPPORTED_LANGUAGES.KK)
  ) {
    resultAvailableLanguageCodes = [...resultAvailableLanguageCodes, SUPPORTED_LANGUAGES.KK]
  }

  // Fallback on [<default serviceBrand-language>] if some mismatch
  return resultAvailableLanguageCodes || [DEFAULT_SERVICE_BRAND_LANGUAGE[serviceBrand]]
}
