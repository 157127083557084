import {useI18n} from '@eda-restapp/i18n'
import {Box, ChevronDownLIcon, ChevronUpLIcon, Informer, Typography} from '@eda-restapp/ui'
import React, {useState} from 'react'

import styles from './OldAuthInformer.module.css'

export const OldAuthInformer = () => {
  const {t} = useI18n()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClick = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <Informer type='info' className={styles.root}>
      <Box data-testid='old-auth-informer' flexDirection='column' className={styles.content} onClick={handleClick}>
        <Typography variant='body2' className={styles.title}>
          {t('core-auth.old-auth-informer.title', 'Не можете попасть в личный кабинет?')}
        </Typography>
        <Box gap='xs'>
          <Typography variant='caption1' thickness='medium'>
            {t('core-auth.old-auth-informer.how-to-restore-old-auth', 'Как восстановить доступ')}
          </Typography>
          {isExpanded ? <ChevronUpLIcon /> : <ChevronDownLIcon />}
        </Box>

        {isExpanded && (
          <ol data-testid='auth-options-list' className={styles.optionsList}>
            <li className={styles.optionsListItem}>
              <Typography variant='body2'>
                {t(
                  'core-auth.old-auth-informer.restore-old-auth-option1',
                  'Обратиться в поддержку с текущим логином от ЛК (email-ом)'
                )}
              </Typography>
            </li>
            <li className={styles.optionsListItem}>
              <Typography variant='body2'>
                {t(
                  'core-auth.old-auth-informer.restore-old-auth-option2',
                  'Привязать Яндекс ID по инструкции из письма'
                )}
              </Typography>
            </li>
            <li className={styles.optionsListItem}>
              <Typography variant='body2'>
                {t(
                  'core-auth.old-auth-informer.restore-old-auth-option3',
                  'Входить в ЛК с новым логином-паролем от Яндекс ID (на новой странице авторизации)'
                )}
              </Typography>
            </li>
          </ol>
        )}
      </Box>
    </Informer>
  )
}
