import {t} from '@eda-restapp/i18n'

import type {IChat} from '../types'

export function getChatStatusText(status: IChat['status'], waitingCsat = false) {
  switch (status) {
    case 'open':
      return t('main-chats.chat-status.novii', 'Новый')
    case 'pending':
      return t('main-chats.chat-status.v-rabote', 'В работе')
    case 'solved':
      return t('main-chats.chat-status.solved-chat-status-text', '{count} Оцените/Завершён', {
        none: 'Оцените',
        one: 'Завершён',
        some: 'Завершён',
        many: 'Завершён',
        count: Number(waitingCsat)
      })
  }
}
