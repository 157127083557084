import {LockMiniIcon, CrossRoundFill} from '@eda-restapp/ui'
import FormControl from '@mui/material/FormControl'
import FormHelperText, {type FormHelperTextProps} from '@mui/material/FormHelperText'
import InputBase from '@mui/material/InputBase'
import type {InputProps as MuiInputProps} from '@mui/material/Input/Input'
import InputLabel, {type InputLabelProps} from '@mui/material/InputLabel'
import React, {
  type ChangeEvent,
  type ChangeEventHandler,
  type FC,
  type FocusEventHandler,
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useRef
} from 'react'

import Spinner from '../Spinner'

import useInputStyles, {type InputClasses} from './styles'

export type InputProps = {
  className?: string
  classes?: Partial<Record<InputClasses, string>>
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  loading?: boolean
  clearable?: boolean
  variant?: 'filled' | 'outlined'

  autoComplete?: string
  autoFocus?: boolean
  defaultValue?: unknown
  disabled?: boolean
  locked?: boolean
  error?: boolean
  FormHelperTextProps?: Partial<FormHelperTextProps>
  fullWidth?: boolean
  helperText?: string | JSX.Element | false
  id?: string
  InputLabelProps?: Partial<InputLabelProps>
  inputRef?: React.Ref<any>
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  label?: React.ReactNode
  multiline?: boolean
  name?: string
  placeholder?: string
  required?: boolean
  minRows?: string | number
  maxRows?: string | number
  type?: string
  value?: unknown
  InputProps?: Partial<MuiInputProps>
  inputProps?: MuiInputProps['inputProps']
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onClick?: MouseEventHandler<HTMLInputElement>
}

export const Input: FC<InputProps> = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      id,
      className,
      classes,
      fullWidth,
      clearable,
      variant = 'outlined',
      disabled,
      locked,
      required,
      error,
      label,
      helperText,
      loading,
      startAdornment,
      endAdornment,
      InputLabelProps,
      InputProps,
      FormHelperTextProps,
      onChange,
      onClick,
      inputRef: parentInputRef,
      ...restProps
    },
    ref
  ) => {
    const {classes: c, cx} = useInputStyles(undefined, {
      props: {
        classes: classes
      }
    })
    const innerInputRef = useRef<HTMLInputElement | null>(null)

    const normalizedDisabled = disabled || locked

    const inputRef = (el: HTMLInputElement) => {
      innerInputRef['current'] = el

      if (!parentInputRef) {
        return
      }
      // https://github.com/facebook/react/issues/13029
      if (typeof parentInputRef === 'function') {
        parentInputRef(el)
      } else {
        // @ts-ignore
        parentInputRef['current'] = el
      }
    }

    function clearInput() {
      const input = innerInputRef.current

      if (!input || !onChange) {
        return
      }

      input.value = ''
      onChange({target: input} as ChangeEvent<HTMLInputElement>)
      input.focus()
    }

    function getEndAdornment() {
      if (loading) {
        return (
          <div>
            <Spinner size={16} />
          </div>
        )
      }

      if (locked) {
        return (
          <div>
            <LockMiniIcon />
          </div>
        )
      }

      const input = innerInputRef.current

      if (clearable && input?.value) {
        return <CrossRoundFill className={c.clearIcon} onClick={clearInput} />
      }

      return endAdornment
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
      restProps.onBlur && restProps.onBlur(e)
    }

    function handleFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const input = e.target

      if (input.selectionStart !== null && input.selectionEnd !== null) {
        input.selectionStart = input.selectionEnd = input.value.length
      }

      restProps.onFocus && restProps.onFocus(e)
    }

    const {className: inputClassName, classes: inputClasses, ...InputPropsWithoutClassName} = InputProps || {}

    return (
      <FormControl
        variant='standard'
        ref={ref}
        className={className}
        classes={{root: c.root}}
        disabled={normalizedDisabled}
        fullWidth={fullWidth}
        required={required}
      >
        {!!label && (
          <InputLabel
            htmlFor={id}
            classes={{
              asterisk: c.labelAsterisk,
              root: c.labelRoot,
              focused: c.labelFocused,
              error: c.labelError,
              disabled: c.labelDisabled,
              shrink: c.labelShrink
            }}
            disableAnimation
            shrink
            error={error}
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <InputBase
          id={id}
          inputRef={inputRef}
          className={cx(c[variant], inputClassName)}
          classes={{
            formControl: c.inputFormControl,
            error: c.inputError,
            focused: c.inputFocused,
            disabled: c.inputDisabled,
            input: c.inputInput,
            adornedStart: c.inputAdornedStart,
            adornedEnd: c.inputAdornedEnd,
            ...inputClasses
          }}
          error={error}
          startAdornment={startAdornment}
          endAdornment={getEndAdornment()}
          fullWidth={fullWidth}
          onChange={onChange}
          {...restProps}
          {...InputPropsWithoutClassName}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={onClick}
        />
        <FormHelperText
          data-testid={'input-helper-text' /*Поле ввода | Подсказка/Ощибка внизу поля*/}
          error={error}
          {...FormHelperTextProps}
          classes={{
            ...FormHelperTextProps?.classes,
            root: cx(c.helperTextRoot, helperText ? c.helperText : c.noHelperText, FormHelperTextProps?.classes?.root)
          }}
        >
          {helperText || <>&nbsp;</>}
        </FormHelperText>
      </FormControl>
    )
  }
)

Input.displayName = 'Input'
