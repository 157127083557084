import {makeStyles} from 'tss-react/mui'

export const useCommunicationStyles = makeStyles({name: 'Communications'})({
  surveysWrapper: {
    margin: '20px 0'
  },
  notificationsWrapper: {
    margin: '20px 0 24px'
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 1200
  },
  restaurantSelectDesktop: {
    width: '100%',
    maxWidth: 350,
    marginLeft: 16
  },
  restaurantSelectMobile: {
    width: '100%',
    display: 'block'
  },
  refreshButton: {
    marginLeft: 16
  },
  refreshButtonOnTouchDevice: {
    display: 'none'
  },
  rightControls: {
    marginLeft: 'auto'
  },
  yangoFont: {
    fontFamily: 'Yango Headline'
  }
})
