import {useEffect, useRef} from 'react'

import type {LinkedAccount} from '@restapp/shared-api/types/passport'

import type {ExternalAccountSuggest} from '../../../../../types'
import {useFullScreenOnCloseDelay} from '../../../../hooks'

import {PassportFullScreen} from './PassportFullScreen'

type PassportFullScreenContainerProps = {
  open: boolean
  accounts: LinkedAccount[]
  closeSecondsDelay?: number
  maxLinkedAccountLimitReached?: boolean
  suggestedAccount?: ExternalAccountSuggest
  hasLinkedAccounts?: boolean
  onClose(): void
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
  onAuthWithLinkedAccount(account: LinkedAccount): void
}
// TODO: https://st.yandex-team.ru/EDARESTAPP-9217
export const PassportFullScreenContainer = ({
  open,
  onClose,
  closeSecondsDelay,
  hasLinkedAccounts,
  accounts,
  maxLinkedAccountLimitReached,
  suggestedAccount,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onAuthWithLinkedAccount
}: PassportFullScreenContainerProps) => {
  const wasOppenedRef = useRef(false)
  // при первом открытии есть таймер, при последующих - нет
  const canStartTimer = open && !wasOppenedRef.current
  const {delay} = useFullScreenOnCloseDelay({initialDelay: closeSecondsDelay, canStartTimer})

  useEffect(() => {
    if (open && !wasOppenedRef.current) {
      wasOppenedRef.current = true
    }
  }, [open])

  return (
    <PassportFullScreen
      open={open}
      accounts={accounts}
      onClose={onClose}
      onBindOtherAccount={onBindOtherAccount}
      onBindSuggestedAccount={onBindSuggestedAccount}
      onAuthWithLinkedAccount={onAuthWithLinkedAccount}
      hasLinkedAccounts={hasLinkedAccounts}
      maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
      suggestedAccount={suggestedAccount}
      closeDelay={delay}
    />
  )
}
