import type {MutableRefObject} from 'react'
import {useEffect, useCallback, useRef} from 'react'

type TargetType = HTMLElement | null | undefined

type UseScrollToElementOptions = {
  elementRef?: MutableRefObject<TargetType>
  immediately?: boolean
  scrollOptions?: ScrollIntoViewOptions
}

const useScrollToElement = ({elementRef, immediately, scrollOptions = {}}: UseScrollToElementOptions) => {
  const _scrollToElement = useCallback(() => {
    elementRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center', ...scrollOptions})
  }, [elementRef, scrollOptions])

  // Просто переменная которая не даст компоненту скроллится при каждом рендере
  const immediatelyToggleRef = useRef(true)

  useEffect(() => {
    if (immediatelyToggleRef.current && immediately) {
      _scrollToElement()

      immediatelyToggleRef.current = false
    }
  }, [_scrollToElement, immediately])

  return {
    scrollToElement: () => {
      _scrollToElement()
    }
  }
}

export default useScrollToElement
