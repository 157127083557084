import type {ApiQueryKey} from 'packages/core-api/types'
import type {UseQueryResult} from 'react-query'

import {useApiQuery} from '@restapp/core-api'
import type {IApiError, PlaceSmartPricesStatus} from '@restapp/shared-api'

export type UseApiQueryResult<TSelectedData, TError = IApiError> = UseQueryResult<TSelectedData, TError> & {
  queryKey: ApiQueryKey<'/4.0/restapp-front/smart-prices/v1/status', 'POST'>
}

interface SmartPricesOptions {
  enabled?: boolean
  suspense?: boolean
}

function useSmartPrices(placeId: number, options?: SmartPricesOptions): UseApiQueryResult<PlaceSmartPricesStatus>
function useSmartPrices(placeIds: number[], options?: SmartPricesOptions): UseApiQueryResult<PlaceSmartPricesStatus[]>
function useSmartPrices(
  idOrIds: number | number[],
  options?: SmartPricesOptions
): UseApiQueryResult<PlaceSmartPricesStatus | PlaceSmartPricesStatus[]> {
  const isSinglePlace = !Array.isArray(idOrIds)
  const placeIds: number[] = isSinglePlace ? [idOrIds] : idOrIds

  return useApiQuery({
    url: '/4.0/restapp-front/smart-prices/v1/status',
    method: 'POST',
    body: {
      place_ids: placeIds
    },
    select: (data) => (isSinglePlace ? data?.places[0] : data?.places),
    ...options
  })
}

export default useSmartPrices
