import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {usePassportName} from '@restapp/core-auth/passport/hooks'

import idIcon from './images/idIcon.png'
import phoneIcon from './images/phoneIcon.png'
import usersIcon from './images/usersIcon.png'
import styles from './PassportDrawerCards.module.css'

export type PassportDrawerCardsProps = {
  variant?: 'drawer' | 'modal'
}
export const PassportDrawerCards: React.FC<PassportDrawerCardsProps> = ({variant}) => {
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()
  const passportName = usePassportName()

  const manageUsersModal = t('core-auth.passport-drawer-cards.manage-users-modal', 'Управление пользователями')
  const manageUsersDrawer = t('core-auth.passport-drawer-cards.manage-users-drawer', 'Управляйте пользователями')
  const oneId = t('core-auth.passport-drawer-cards.one-id', 'Один ID для разных личных кабинетов')
  const secureEntry = t('core-auth.passport-drawer-cards.secure-entry-by-phone', 'Безопасный вход по телефону')

  const renderModal = () => (
    <Box flexDirection='column'>
      <Box pb='m'>
        <Typography variant={isMobile ? 'body2' : 'body1'} style={{lineHeight: isMobile ? '19px' : '20px'}}>
          {t('core-auth.passport-drawer-cards.new-features', 'С {passportName} вам будут доступны новые возможности:', {
            passportName
          })}
        </Typography>
      </Box>
      <Box
        pt={isMobile ? undefined : 'm'}
        gap='m'
        justifyContent='center'
        alignSelf='stretch'
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          className={styles.modalCard}
          pt={isMobile ? 'm' : 'l'}
          pr='m'
          pb='m'
          pl='m'
          flex={1}
          gap={isMobile ? 'm' : 'l'}
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          alignItems='center'
        >
          <img src={usersIcon} className={cn(styles.modalImg, isMobile && styles.mobileImg)} />
          <Typography variant='body2' thickness='medium' className={styles.textWrap}>
            {manageUsersModal}
          </Typography>
        </Box>
        <Box
          className={cn(styles.modalCard, styles.central)}
          pt={isMobile ? 'm' : 'l'}
          pr='m'
          pb='m'
          pl='m'
          flex={1}
          gap={isMobile ? 'm' : 'l'}
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          alignItems='center'
        >
          <img src={idIcon} className={cn(styles.modalImg, isMobile && styles.mobileImg)} />
          <Typography variant='body2' thickness='medium' className={styles.textWrap}>
            {oneId}
          </Typography>
        </Box>
        <Box
          className={styles.modalCard}
          pt={isMobile ? 'm' : 'l'}
          pr='m'
          pb='m'
          pl='m'
          flex={1}
          gap={isMobile ? 'm' : 'l'}
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          alignItems='center'
        >
          <img src={phoneIcon} className={cn(styles.modalImg, isMobile && styles.mobileImg)} />
          <Typography variant='body2' thickness='medium' className={styles.textWrap}>
            {secureEntry}
          </Typography>
        </Box>
      </Box>
    </Box>
  )

  const renderDrawer = () => (
    <Box className={styles.cardsWrapper} gap='m' flexDirection='column'>
      <Box flexDirection={isMobile ? 'column' : 'row'} gap='m' alignSelf='stretch'>
        <Box
          className={styles.card}
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          justifyContent={isMobile ? 'space-between' : 'flex-start'}
          flex={1}
          pt={isMobile ? 'm' : 'l'}
          pb={isMobile ? 'm' : 'l'}
          pl='m'
          pr='m'
          gap='l'
        >
          <img src={usersIcon} />
          <Box flexDirection='column' gap='s'>
            <Typography variant='body2' thickness='medium'>
              {manageUsersDrawer}
            </Typography>
            <Typography variant='caption1' thickness='regular'>
              {t(
                'core-auth.passport-drawer-cards.manage-users-explanation',
                'Отправляйте персональные приглашения сотрудникам и управляйте доступами на отдельной странице.'
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          className={styles.card}
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          flex={1}
          pt={isMobile ? 'm' : 'l'}
          pb={isMobile ? 'm' : 'l'}
          pl='m'
          pr='m'
          gap='l'
        >
          <img src={idIcon} />
          <Box className={styles.textBlock} flexDirection='column' gap='s'>
            <Typography variant='body2' thickness='medium'>
              {oneId}
            </Typography>
            <Typography variant='caption1' thickness='regular'>
              {t(
                'core-auth.passport-drawer-cards.one-id-explanation',
                'Привяжите один {passportName} ко всему – настройки личных кабинетов сохранятся, а помнить и вводить разные пароли не понадобится',
                {passportName}
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box gap='m'>
        <Box
          className={styles.card}
          alignItems='center'
          pt={isMobile ? 'm' : 'l'}
          pb={isMobile ? 'm' : 'l'}
          pl='m'
          pr='m'
          gap='l'
        >
          <Box className={styles.textBlock} flexDirection='column' gap='s'>
            <Typography variant='body2' thickness='medium'>
              {secureEntry}
            </Typography>
            <Typography variant='caption1' thickness='regular'>
              {t(
                'core-auth.passport-drawer-cards.secure-entry-explanation',
                'После привязки {passportName} появится возможность войти в личный кабинет по номеру телефона, а ещё менять данные об учётной записи и пароль самостоятельно',
                {passportName}
              )}
            </Typography>
          </Box>
          <img src={phoneIcon} />
        </Box>
      </Box>
    </Box>
  )

  return variant == 'modal' ? renderModal() : renderDrawer()
}
