import type {Currency} from './common'
import type {ModerationStatus} from './moderation'
import type {PassportData} from './passport'
import type {IServices, Service} from './places'

export type EnableCampaignItem = {
  place_id: number
  averagecpc: number
  campaign_uuid: string
  weekly_spend_limit?: number
}

export enum BudgetAllocation {
  all = 'all_period',
  weekly = 'weekly'
}

export enum CampaignStatus {
  CREATE_ERROR = 'create_error',
  PROCESS = 'process',
  SUSPENDED = 'suspended',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  UPDATING = 'updating',
  PAUSED = 'paused',
  ACTIVATION_REQUIRED = 'activation_required'
}

export type CampaignResponse = {
  campaigns: Campaign[]
  meta: {
    rating_threshold?: number
  }
}

export type CPACampaignResponse = {
  campaigns: CampaignCPA[]
  meta: {
    rating_threshold?: number
  }
}

export enum CampaignType {
  'cpc' = 'cpc',
  'cpm' = 'cpm',
  'cpa' = 'cpa'
}

type CampaignCommon = {
  /**
   * id рекламной кампании (внутренний - НЕ директовый)
   * advert_id одинаковый для всех кампаний с одним и тем же campaign_uuid
   */
  advert_id: number
  /**
   * id места, для которого заводится рекламная кампания
   */
  place_id: number
  /**
   * Статус рекламной кампании
   */
  status: CampaignStatus
  /**
   * Сервис для которой создается кампания (Яндекс Еда или DC)
   * Если не задан выбирается eda
   */
  service?: Service
  /**
   * есть ли доступ из текущего паспортного аккаунта к кампании
   */
  has_access: boolean
  /**
   * Директ-пользователь владелец кампаниии
   */
  owner?: PassportData //'../definitions.yaml#/components/schemas/PassportData'
  /**
   * id кампании в Директе
   */
  campaign_id?: number
  /**
   * устаревший uuid кампании
   */
  campaign_uuid?: string
  /**
   * сообщение ошибки, при наличии
   */
  error?: string
  /**
   * Соответствие ресторана рейтингу из конфига
   * default: true
   */
  is_rating_status_ok?: boolean
  /**
   * время последнего запуска кампании
   */
  started_at?: string
  /**
   * время остановки кампании, если кампания неактивна
   */
  suspended_at?: string
  /**
   * Дата и время создания рекламной кампании
   */
  created_at?: string
  /**
   * валюта рекламной кампании
   */
  currency: Currency
  /**
   * статистика (delta, sum, points{})
   */
  stats?: {
    /**
     * время вычисления статистики
     * format: date-time
     */
    calculated_at?: string
    /**
     * количество кликов
     */
    clicks: ChartInfoInteger // $ref: "#/components/schemas/ChartInfoInteger"
    /**
     * количество показов баннера
     */
    shows: ChartInfoInteger // $ref: "#/components/schemas/ChartInfoInteger"

    /**
     * общее количество рекламных заказов
     */
    ad_orders: ChartInfoInteger // $ref: "#/components/schemas/ChartInfoInteger"
  }
} & (
  | {
      /**
       * Есть ли доступ текущего пользователя Еды к ресторану данной кампании, по умолчанию есть
       */
      has_access_vendor: false
      /**
       * Отдается только если has_access_vendor === false
       */
      place_name: string
      /**
       * Отдается только если has_access_vendor === false
       */
      place_address_full: string
      /**
       * Отдается только если has_access_vendor === false
       */
      place_type: 'native' | 'marketplace'
      /**
       * Отдается только если has_access_vendor === false
       */
      place_services: IServices
    }
  | {
      has_access_vendor?: true
    }
)

export type CampaignCPC = CampaignCommon & {
  campaign_type: CampaignType.cpc | undefined
  /**
   * Недельный лимит рекламной кампании
   * для поддержки копеек префикс decimal_
   */
  weekly_spend_limit?: number
  decimal_weekly_spend_limit?: string
  /**
   * Стоимость клика по объявлению, устанавливаемая рекламодателем.
   * minimum: 0
   * для поддержки копеек префикс decimal_
   */
  average_cpc: number
  decimal_averagecpc?: string
}

export type CampaignCPM = CampaignCommon & {
  campaign_type: CampaignType.cpm
  /**
   * Ссылка на готовый баннер в аватарнице
   */
  image?: string
  /**
   * Ссылка на исходник в аватарнице
   */
  source_image?: string
  /**
   * текст для баннера
   */
  text?: string
  /**
   * id рекламной кампании CPM (внутренний - НЕ директовый), для CPM одинаковый на всю группу
   */
  cpm_advert_id: string
  /**
   * Средняя цена не более X рублей за 1000 показов
   * minimum: 0
   * cpm_advert_id одинаковый для всех кампаний с одним и тем же campaign_uuid (и равен campaign_uuid)
   */
  averagecpm: number
  /**
   * Бюджет на период
   * minimum: 0
   */
  spend_limit: number
  budget_allocation?: BudgetAllocation
  moderation_status?: ModerationStatus
  moderation_rejection_reason?: string
  /**
   * дата начала баннерной рекламной кампании
   * format: date
   */
  start_date?: string
  /**
   * дата окончания баннерной рекламной кампании
   * format: date
   */
  finish_date?: string
}

export type CampaignCPA = CampaignCommon & {
  campaign_type: CampaignType.cpa
  /**
   * Недельный лимит рекламной кампании
   */
  weekly_spend_limit?: number
  /**
   * Стоимость заказа, устанавливаемая рекламодателем.
   * minimum: 0
   */
  bid: number
  /**
   * Активирована ли кампания пользователем явно
   */
  activated_by_user: boolean
  /**
   * Статус кампании (включена / приостановлена)
   */
  is_active: boolean
}

/**
 * Описание рекламного объявления
 */
export type Campaign = CampaignCPC | CampaignCPM | CampaignCPA

type ChartInfoInteger = {
  /**
   * разница между значением в первый и последний день
   */
  delta: number
  /**
   * сумма значений за выбранный период
   */
  sum: number
  /**
   * массив точек графика
   */
  points: ChartPointInteger[] // $ref: "#/components/schemas/ChartPointInteger"
}

type ChartPointInteger = {
  /**
   * конец периода, для которого посчитаны параметры.
   * Формат "YYYY-MM-DD"
   */
  dt_from: string
  /**
   * начало периода, для которого посчитаны параметры. Формат "YYYY-MM-DD"
   */
  dt_to: string
  value: number
}

export type RecommendationType = {
  weight_cpc: number
  weight_drr: number
  shows_share: number
}

export type RecommendatorType = {
  currency: {
    code: string
    sign: string
    template: string
    text: string
  }
  titles: {
    weight_cpc: string
    weight_drr: string
    shows_share: string
  }
  recommendations: RecommendationType[]
}

/**
 * Тело запроса на остановку/запуск рекламных кампаний
 */
export type StartPauseRequest = {
  /**
   * Кампании, которые можно останавливать/запускать.
   */
  campaigns: {
    /**
     * Идентификатор рекламной кампании в сервисе. Это именно
       рекламная кампания, а не объявление (баннер/ресторан).
       В Campaign ему соответствует cpm_advert_id
     */
    campaign_id: string
    /**
     * Тип рекламной кампании.
     */
    campaign_type: CampaignType
  }[]
}
