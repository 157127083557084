import {useApiMutation} from '@restapp/core-api'

export const useYaPlusEnable = () =>
  useApiMutation(
    {
      url: '/4.0/restapp-front/promo/v1/plus/cashback/activate',
      method: 'POST'
    },
    {
      affectedQueryKeys: [['/4.0/restapp-front/promo/v1/plus/status', 'POST' as never]]
    }
  )
