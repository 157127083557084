import {isNull} from 'lodash-es'
import moment from 'moment-timezone'

import type {Timers} from '../../../../DisablePlaceModal.constants'

export const validateForm = (
  availableAt: moment.Moment | null,
  maxTime: string | undefined,
  reasonCode: number,
  timeId: Timers | null
) => {
  if (availableAt && maxTime) {
    const valid = availableAt.isBetween(moment(), moment(maxTime))

    return !isNull(reasonCode) && !isNull(timeId) && valid
  }

  return false
}
