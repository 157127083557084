import type {COMMUNICATIONS_REDUCER_KEY} from '../slice'

import type {NewsItem, News} from './news'
import type {NotificationType} from './notifications'
import type {CommunicationsPool} from './pool'
import type {Survey} from './survey'

export type WidgetState = {
  showState: Record<string, boolean>
}

export type SurveyState = Survey | null

export type NewsState = News
export type PoolState = CommunicationsPool

export type CommunicationsState = {
  widget: WidgetState
  survey: SurveyState
  news: NewsState
  pool: PoolState
}
export type CommunicationsStateSlice = {[COMMUNICATIONS_REDUCER_KEY]: CommunicationsState}

export type Communication = NewsItem | NotificationType | Survey

export const enum CommunicationType {
  NOTIFICATION = 'notification',
  NEWS = 'news',
  SURVEY = 'survey'
}

export const enum CommunicationStatus {
  PUBLISHED = 'published',
  READ = 'read'
}
