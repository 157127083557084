import {createSelector} from '@reduxjs/toolkit'

import {CategoriesSelectors, ItemsSelectors, GroupsSelectors, OptionsSelectors, ModerationSelectors} from './slice'
import type {CategoryType, MenuItemType} from './types'
import {selectSelf, omitMeta, selectMenu} from './utils'

export const isEmpty = createSelector(selectSelf, (state) => {
  const categoriesTotal = CategoriesSelectors.total(state)
  const itemsTotal = ItemsSelectors.total(state)
  return categoriesTotal + itemsTotal < 1
})

export const isDirty = createSelector(selectSelf, (state) => {
  // обрати внимание тут соблюдается порядок проверок, от быстрых к медленным
  // медленные не будут выполнены если сработает что то выше
  if (
    ModerationSelectors.deletedTotal(state) ||
    CategoriesSelectors.isDirty(state) ||
    ItemsSelectors.isDirty(state) ||
    GroupsSelectors.isDirty(state) ||
    OptionsSelectors.isDirty(state)
  ) {
    return true
  }

  return false
})

export const selectMenuRevision = createSelector(selectMenu, (state): string => state.revision)

/**
 *  @deprecated Медленный селектор для старого кода которому обязательно нужен полный item
 *  не используй в новом коде, для нового кода нужно писать свои селекторы
 *
 *  Также тут выпиливаем все исскуственные поля которые начинаются с _
 */
export const currentState = createSelector(selectSelf, (state): {categories: CategoryType[]; items: MenuItemType[]} => {
  const currentCategories = CategoriesSelectors.all(state).map(omitMeta)
  const currentItems = ItemsSelectors.all(state).map(omitMeta)

  return {
    categories: currentCategories,
    items: currentItems.map((item) => {
      const currentGroups = GroupsSelectors.byItemId(state, item.id)
      const currentGroupsWithoutMeta = currentGroups.map(omitMeta)

      return {
        ...item,
        modifierGroups: currentGroups.map((group, i) => {
          const currentOptions = OptionsSelectors.byGroupId(state, group._virtualId).map(omitMeta)

          return {
            ...currentGroupsWithoutMeta[i],
            modifiers: currentOptions
          }
        })
      }
    })
  }
})
