import {
  type ConfigsFetcherError,
  type ConfigsFetcherResponse,
  getConfigsStore,
  type PartialConfigs
} from '@eda-restapp/configs'
import {getBrand, t} from '@eda-restapp/i18n'

import {getApplication} from '@restapp/shared/utils'
import {getBaseUrl} from '@restapp/core-legacy/api/Api'
import {errorLogger, eventLogger} from '@eda-restapp/logger'

const LOCAL_STORAGE_KEY_PREVIOUS_CONFIGS_EXP3 = 'previous-configs-exp3'
const INITIAL_RETRY_COUNT = 2
const TIMEOUT_INIT_CONFIGS = 5_000

const configsStore = getConfigsStore()

configsStore.fetcher.setBaseUrl(getBaseUrl())

// Передаем t для перевода конфигов которые все еще от нее зависят
configsStore.setTranslateFn(t)

const sleep = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout))

const getPreviousConfigs = (): PartialConfigs | null => {
  try {
    const {params, config} = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_KEY_PREVIOUS_CONFIGS_EXP3) || '{}') || {}
    if (!params || !params.timestamp || !params.version) {
      return null
    }
    const diffInDays = (new Date().getTime() - new Date(params.timestamp).getTime()) / (24 * 3600 * 1000)
    const isValidVersion = params.version === VERSION

    if (isValidVersion && diffInDays <= 3) {
      return config
    }
    return null
  } catch {
    return null
  }
}

const setPreviousConfigs = (config: PartialConfigs) => {
  const params = {
    version: VERSION,
    timestamp: new Date().toISOString()
  }
  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY_PREVIOUS_CONFIGS_EXP3)
    localStorage.setItem(
      LOCAL_STORAGE_KEY_PREVIOUS_CONFIGS_EXP3,
      JSON.stringify({
        params,
        config
      })
    )
  } catch (error) {
    const sizeLocalStorage = () => {
      try {
        let allStrings = ''
        for (let key in localStorage) {
          if (window.localStorage.hasOwnProperty(key)) {
            allStrings += window.localStorage[key]
          }
        }
        return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) + ' KB' : 'Empty (0 KB)'
      } catch {
        return 'Disabled storage'
      }
    }

    errorLogger({
      level: 'error',
      error: error instanceof Error ? error : new Error('local storage quota exceeded'),
      message: 'bootstrap:init_configs_error_quota_exceeded',
      additional: {
        localStorageSize: sizeLocalStorage()
      }
    })

    // Пытаемся очистить хранилище и сохранить авторизацию
    try {
      const persistAuth = localStorage.getItem('persist:auth')
      localStorage.clear()

      if (persistAuth) {
        localStorage.setItem('persist:auth', persistAuth)
      }

      eventLogger({
        name: 'initConfigs',
        value: 'bootstrap:init_configs_flush_localstorage_with_auth'
      })
    } catch (error) {
      errorLogger({
        level: 'error',
        message: 'bootstrap:init_configs_flush_localstorage_without_auth',
        error: error instanceof Error ? error : new Error('error auth restore when flushing local storage')
      })
    }
  }
}

export async function initConfigs() {
  let configsRetryCounter = INITIAL_RETRY_COUNT
  let configsRetryTimeout = 0
  let result: ConfigsFetcherError | ConfigsFetcherResponse | Error = new Error('error stub')

  const previousConfigs = getPreviousConfigs()
  if (previousConfigs) {
    configsStore.setConfigs(previousConfigs)
    return
  }

  while (configsRetryCounter--) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_INIT_CONFIGS)
    const fetchResult = await configsStore.fetcher.fetch({
      args: {
        application: getApplication(),
        domainHost: getBrand()
      },
      signal: controller.signal
    })
    clearTimeout(timeoutId)

    result = fetchResult
    if (fetchResult instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(fetchResult)

      configsRetryTimeout = configsRetryTimeout + 1000 * (INITIAL_RETRY_COUNT - configsRetryCounter)

      await sleep(configsRetryTimeout) // 1s - 3s - 6s
      continue
    } else {
      break
    }
  }

  if (result instanceof Error) {
    errorLogger({
      error: result,
      message: 'initConfigs retries runout',
      sourceType: 'configsStore',
      additional: {
        configsRetryCounter,
        configsRetryTimeout
      }
    })
  }
}

configsStore.fetcher.events.subscribe({
  name: 'fetchError',
  callback(payload) {
    if (payload.error.message.includes('AbortError')) {
      // fetch выбрасывает эту ошибку когда запрос был прерван через AbortSignal
      eventLogger({
        name: 'configsStore',
        value: 'configsStore:AbortError',
        additional: {
          requestBody: payload.request.body
        }
      })
      return
    }

    errorLogger({
      level: 'error',
      error: payload.error,
      sourceType: 'configsStore',
      message: `configsStore:fetchError:${payload.error.status}`,
      additional: {
        requestBody: payload.request.body,
        traceId: payload.error.traceId
      }
    })
  }
})

configsStore.fetcher.events.subscribe({
  name: 'fetchSuccess',
  callback(payload) {
    eventLogger({
      name: 'configsStore',
      value: 'configsStore:fetchSuccess',
      additional: {
        requestBody: payload.request.body
      }
    })

    setPreviousConfigs(payload.response.data)
  }
})
