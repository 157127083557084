import {type CountryCodeType, useDomainDividedCountriesInfo, useI18n} from '@eda-restapp/i18n'
import {ChevronDownMIcon} from '@eda-restapp/ui'
import {Menu, MenuItem, type MenuProps} from '@mui/material'
import React from 'react'

import {useServiceBrand} from '@restapp/shared/hooks/useServiceBrand'
import {getCountryFlag} from '@restapp/shared/utils'

import {Button} from '../Button'

import useCountrySelectorStyles from './CountrySelector.styles'

type CountrySelectorProps = Omit<MenuProps, 'open'> & {open?: boolean} & {
  supportCountry: CountryCodeType | null
  setSupportCountry: React.Dispatch<React.SetStateAction<CountryCodeType | null>>
}

export const CountrySelector = ({
  supportCountry,
  setSupportCountry,
  ...props
}: CountrySelectorProps): React.ReactElement<CountrySelectorProps> => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const domainDividedContacts = useDomainDividedCountriesInfo()

  const {t} = useI18n()
  const serviceBrand = useServiceBrand()
  const {classes: c} = useCountrySelectorStyles()

  const isCountryListOpen = Boolean(anchorEl)
  const internationalDomainSupportContacts = domainDividedContacts[serviceBrand]

  const chosenSupportCountry = supportCountry && {
    countryName: internationalDomainSupportContacts[supportCountry]?.countryName,
    flag: getCountryFlag(supportCountry)
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.classList.toggle('active')
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    document.getElementById('country-selector-button')?.classList.remove('active')
  }

  const handleCountryClick = (country: CountryCodeType) => {
    setSupportCountry(country)
    handleMenuClose()
  }

  return (
    <div>
      <Button
        id='country-selector-button'
        data-testid={
          'shared-ui-support-contacts-choose-country-button-empty'
        } /* Экран авторизации | Дефолтная кнопка выбора страны */
        aria-controls={isCountryListOpen ? 'country-selector-menu-list' : undefined}
        aria-haspopup='true'
        aria-expanded={isCountryListOpen ? 'true' : undefined}
        className={c.button}
        disableElevation
        endIcon={<ChevronDownMIcon />}
        onClick={handleButtonClick}
      >
        {chosenSupportCountry ? (
          <div className={c.countrySelectButtonLabel}>
            <div className={c.countrySelectButtonLabelFlag}>
              {chosenSupportCountry.flag && <chosenSupportCountry.flag />}
            </div>
            <span>{chosenSupportCountry.countryName}</span>
          </div>
        ) : (
          t('shared-ui.support-contacts.support-contacts-button-empty-label', 'Выберите страну')
        )}
      </Button>
      <Menu
        {...props}
        id='country-selector-menu-list'
        data-testid={
          'ui__country-selector-menu-list' /* UI | List of available countries to select for displaying support contacts*/
        }
        aria-labelledby='country-selector-button'
        anchorEl={anchorEl}
        open={isCountryListOpen}
        onClose={handleMenuClose}
      >
        {Object.entries(internationalDomainSupportContacts).map(([country, {countryName}]) => {
          const CountryFlag = getCountryFlag(country as CountryCodeType)

          return (
            <MenuItem
              data-testid={`shared-ui-support-contacts-choose-${country}`} /* Экран авторизации | Кнопки страны */
              key={country}
              className={c.menuItem}
              value={country}
              selected={country === supportCountry}
              onClick={() => handleCountryClick(country as CountryCodeType)}
            >
              {CountryFlag && <CountryFlag />}
              {countryName}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
