import {useQueryClient} from 'react-query'
import {useEffect} from 'react'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {Snackbar} from '@eda-restapp/snackbars'
import {useNavigate} from 'react-router-dom'
import {useI18n} from '@eda-restapp/i18n'
import {isSilentNotification} from './utils'

export const WatchSilentPushes = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {t} = useI18n()

  useEffect(() => {
    const unsubscribe = notificationsChannel.subscribe((notification) => {
      if (notification.meta.source !== 'socket' || !isSilentNotification(notification)) {
        return
      }

      switch (notification.event) {
        case 'bonus-points.low_balance_push': {
          if (!notification.data.threshold) {
            return
          }

          Snackbar.show({
            id: 'bonus-points.low_balance',
            type: 'info',
            text: t(
              'bootstrap.watch-silent-pushes.bonus-points-low-balance',
              'Осталось/остался {count} бонус/бонуса/бонусов, скоро начнётся оплата из выручки',
              {
                one: 'Остался {count} бонус, скоро начнётся оплата из выручки',
                some: 'Осталось {count} бонуса, скоро начнётся оплата из выручки',
                many: 'Осталось {count} бонусов, скоро начнётся оплата из выручки',
                count: notification.data.threshold
              }
            )
          })
          break
        }
        case 'bonus-points.burn_soon_push': {
          if (!notification.data.threshold) {
            return
          }

          Snackbar.show({
            id: 'bonus-points.burn_soon',
            type: 'info',
            text: t(
              'bootstrap.watch-silent-pushes.bonus-points-burn-soon',
              'Осталось/остался {count} день/дня/дней до сгорания бонусов, успейте запустить кампанию',
              {
                one: 'Остался {count} день до сгорания бонусов, успейте запустить кампанию',
                some: 'Осталось {count} дня до сгорания бонусов, успейте запустить кампанию',
                many: 'Осталось {count} дней до сгорания бонусов, успейте запустить кампанию',
                count: notification.data.threshold
              }
            ),
            onClick: () => {
              navigate('/promotion')
            },
            background: 'var(--theme-warning)'
          })
          break
        }
        case 'bonus-points.end_soon_push': {
          if (!notification.data.threshold) {
            return
          }

          Snackbar.show({
            id: 'bonus-points.end_soon',
            type: 'info',
            text: t(
              'bootstrap.watch-silent-pushes.bonus-points-end-soon',
              'Осталось/остался {count} день/дня/дней до сгорания бонусов, скоро начнётся оплата  из выручки',
              {
                one: 'Остался {count} день до сгорания бонусов, скоро начнётся оплата  из выручки',
                some: 'Осталось {count} дня до сгорания бонусов, скоро начнётся оплата  из выручки',
                many: 'Осталось {count} дней до сгорания бонусов, скоро начнётся оплата  из выручки',
                count: notification.data.threshold
              }
            )
          })

          break
        }
        case 'direct_low_balance': {
          Snackbar.show({
            id: 'direct_low_balance',
            type: 'info',
            text: t('promotion.watch-low-balance.direct_low_balance-message', 'Низкий баланс в Директе!'),
            onClick: () => {
              navigate('/promotion/places/?modal=payment')
            }
          })

          break
        }
        case 'place_toggle':
        case 'place_pull_all': {
          void queryClient.invalidateQueries(['/4.0/restapp-front/places/v1/search', 'GET'])

          break
        }
        default:
          break
      }
    })

    return unsubscribe
  }, [navigate, queryClient, t])

  return null
}
