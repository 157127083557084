import {Tab} from '@mui/material'
import type {TabProps} from '@mui/material/Tab'
import type {ReactElement} from 'react'
import React from 'react'

import {useNewsTabStyles} from './NewsTab.style'

function NewsTab({...props}: TabProps): ReactElement {
  const {classes: c} = useNewsTabStyles()

  return <Tab classes={c} {...props} />
}

export default NewsTab
