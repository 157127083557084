import {keyframes} from 'tss-react'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles({name: 'Skeleton'})({
  root: {
    borderRadius: 16,
    overflow: 'hidden',
    position: 'relative',
    transform: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',

    '&::before': {
      content: '"\\00a0"'
    },

    '&::after': {
      content: '""',
      background:
        'linear-gradient(90deg, transparent 0%, transparent 32.73%, rgba(255, 255, 255, 100%) 68.31%, transparent 101.75%, transparent 136.61%)',
      transform: 'translateX(-100%)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      animation: `${keyframes`
      0% {
        transform: translateX(-100%);
      }
      60% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(100%);
      }`} 1.3s ease-in-out 0s infinite`
    }
  },
  smallRadius: {
    borderRadius: 4
  }
})

export default useStyles
