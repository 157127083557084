import {useI18n} from '@eda-restapp/i18n'
import {Drawer} from '@eda-restapp/ui'
import type {FC, ReactNode} from 'react'
import React from 'react'

type AssentSubscriptionUzbekistanProps = {
  isOpen?: boolean
  children?: ReactNode
  onClose?: () => void
}

export const AssentSubscriptionUzbekistan: FC<AssentSubscriptionUzbekistanProps> = ({isOpen, children, onClose}) => {
  const {t} = useI18n()

  return (
    <Drawer.Panel opened={isOpen} onClose={onClose}>
      <Drawer.Container scroll='scroll'>
        <Drawer.Header
          onClose={onClose}
          crossBtn
          text={t('shared.consent-subscription-uzbekistan.title', 'Заявка на подключение к подписке Плюс')}
        />

        <Drawer.Content>{children}</Drawer.Content>
      </Drawer.Container>
    </Drawer.Panel>
  )
}
