import {useI18n} from '@eda-restapp/i18n'
import React from 'react'

import type {TariffInfo, TariffType} from '@restapp/shared-api'

import {getTariffCost} from '../utils'

export const useSubscriptionTariffCard = (
  tariffInfo: TariffInfo,
  c: Partial<Record<string, string>>,
  hasDiscount?: boolean,
  trialTariffInfoType?: TariffType
) => {
  const {t, tRaw, lang} = useI18n()

  const descriptionCost: {[key: string]: ((tariffInfo?: TariffInfo, hasDiscount?: boolean) => string) | undefined} = {
    free: () =>
      t('shared-places.subscription-tariff-card.description-cost-free', 'Описание стоимости тарифа Без подписки', {
        value: 'Стандартные условия сотрудничества'
      }),
    business: undefined /** явно прописал, чтобы видеть, что для данного тарифа нет описания */,
    business_plus: (tariffInfo, hasDiscount) => {
      const fixCostRules = hasDiscount ? tariffInfo?.discount?.fix_cost_rules : tariffInfo?.fix_cost_rules
      return t(
        'shared-places.subscription-tariff-card.description-cost-business-plus',
        'Подсказка формирования дополнительной платы за тариф',
        {
          value: '{bundleCost} {bundleSize} {extraCost}',
          bundleSize: fixCostRules?.bundle_size
            ? t(
                'shared-places.subscription-tariff-card.description-cost-business-plus-bundle-size',
                'плата за определенное количество точек',
                {
                  many: 'за {count} точек и меньше',
                  some: 'за {count} точки и меньше',
                  one: 'за {count} точку и меньше',
                  count: fixCostRules.bundle_size
                }
              )
            : '',
          bundleCost: fixCostRules?.bundle_cost ? `${fixCostRules.bundle_cost}${tariffInfo?.currency?.sign ?? ''}` : '',
          extraCost: fixCostRules?.extra_cost
            ? t(
                'shared-places.subscription-tariff-card.description-cost-business-plus-extra-cost',
                'дополнительная плата за точку',
                {
                  value: '+ {extraCost}{sign} за каждую дополнительную',
                  extraCost: fixCostRules?.extra_cost,
                  sign: tariffInfo?.currency?.sign
                }
              )
            : ''
        }
      )
    }
  }

  const description = {
    free: () => t('shared-places.subscription-tariff-card.description-free', 'на стандартных условиях сотрудничества'),
    business: () => t('shared-places.subscription-tariff-card.description-business', 'если нужно больше инструментов'),
    business_plus: () =>
      t('shared-places.subscription-tariff-card.description-business-plus', 'если хочется максимальных результатов')
  }

  const getTariffPriceText = (tariffInfo: TariffInfo, hasDiscount?: boolean) => {
    const tariffCost = getTariffCost(tariffInfo, lang, hasDiscount, tariffInfo.type === trialTariffInfoType)

    const tariffCostArray =
      tariffCost.arrayOfCost.length > 0 ? tariffCost.arrayOfCost : [`0${tariffInfo.currency?.sign || ''}`]

    const tariffCostText = (
      <>
        <span>{tariffCostArray[0]}</span>
        {tariffCostArray[1] && (
          <>
            {' '}
            <span className={c.pricePeriod}>+ {tariffCostArray[1]}</span>
          </>
        )}
      </>
    )

    if (tariffCost.additionalCommission && tariffCost.fixCost) {
      return tariffCostText
    }

    return tRaw('shared-places.subscription-tariff-card.tariff-price-text', '{cost} {period}', {
      cost: <span>{tariffCostText}</span>,
      period: (
        <span className={c.pricePeriod}>
          {trialTariffInfoType === tariffInfo.type
            ? ''
            : t('shared-places.subscription-tariff-card.tariff-price-period', 'в месяц')}
        </span>
      )
    })
  }

  const featuresMap: {[key: string]: string} = {
    sorry: t('shared-places.default-subscription-config.otveti-na-otzivi', 'Ответы на отзывы'),
    boss_bot: t('shared-places.default-subscription-config.otchyoti-v-telegram', 'Отчёты в Telegram'),
    daily_billing: t('shared-places.default-subscription-config.viplati-raz-v-den', 'Выплаты раз в день'),
    personal_manager: t('shared-places.default-subscription-config.lichnii-menedzher', 'Личный менеджер'),
    weekly_billing: t('shared-places.default-subscription-config.viplati-raz-v-nedelyu', 'Выплаты раз в неделю'),
    loyalty_program: t('shared-places.default-subscription-config.loyalty-program', 'Программа лояльности'),
    index_visibility_show_competitors: t(
      'shared-places.default-subscription-config.visibility-index-comparison',
      'Индекс видимости'
    ),
    menu_gpt_generation: t('shared-places.default-subscription-config.yagpt', 'Генерация описания блюд с YandexGPT')
  }

  return {
    featuresMap,
    tariffPriceText: getTariffPriceText(tariffInfo, hasDiscount),
    descriptionCostText: descriptionCost[tariffInfo.type]?.(tariffInfo, hasDiscount),
    description: description[tariffInfo.type]()
  }
}
