import React, {type FC, type MouseEvent} from 'react'
import type {LinkProps as RouterLinkProps} from 'react-router-dom'
import {Link as RouterLink} from 'react-router-dom'

import {eventLogger} from '@eda-restapp/logger'

export interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  to: RouterLinkProps['to']
  slug?: string
}

export const Link: FC<LinkProps> = ({to, slug, onClick, ...linkProps}) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    eventLogger({name: 'click_link', value: slug, additional: {eventData: to}})
    onClick?.(event)
  }

  return <RouterLink data-testid={'link'} to={to} onClick={handleClick} {...linkProps} />
}
