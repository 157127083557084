import cn from 'classnames'
import React, {forwardRef} from 'react'
import type {NavLinkProps} from 'react-router-dom'
import {NavLink} from 'react-router-dom'

import type {NavTabStylesProps} from './NavTab.styles'
import {useNavTabStyles} from './NavTab.styles'

export type NavTabProps = {
  className?: string
  classes?: NavTabStylesProps['classes']
  to: NavLinkProps['to']
  children: string
  /* Mobile only */
  hasNotification?: boolean
} & Partial<Omit<NavLinkProps, 'to' | 'className'>>

export type NavTabRef = HTMLAnchorElement

const NavTab = forwardRef<NavTabRef, NavTabProps>(
  ({children, className, classes, hasNotification, ...restProps}, forwardedRef) => {
    const {classes: c} = useNavTabStyles(undefined, {props: {classes}})

    return (
      <NavLink
        ref={forwardedRef}
        className={({isActive}) => cn(c.link, className, {[c.linkActive]: isActive})}
        {...restProps}
      >
        <span className={c.inner}>
          {children}
          {hasNotification && <span className={c.mark} />}
        </span>
      </NavLink>
    )
  }
)

export default NavTab
