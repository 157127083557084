import {makeStyles} from 'tss-react/mui'

type TagInputClasses =
  | 'inputRoot'
  | 'tag'
  | 'input'
  | 'root'
  | 'error'
  | 'label'
  | 'labelRoot'
  | 'hintRoot'
  | 'beforeInput'
  | 'appendInput'
  | 'inputWrap'

export type TagInputStyleProps = {
  classes?: Partial<Record<TagInputClasses, string>>
}

export const useTagInputStyles = makeStyles<void, TagInputClasses>({name: 'TagInput'})((theme, props, classes) => {
  return {
    root: {},
    error: {},
    beforeInput: {},
    appendInput: {},
    inputWrap: {},

    inputRoot: {
      background: '#F2F4F5',
      borderRadius: '12px',
      minHeight: '41px',
      border: '2px solid #F2F4F5',

      '&::before': {
        border: 'none !important'
      },

      '&::after': {
        display: 'none'
      },

      [`.${classes.error} &`]: {
        borderColor: '#F5523A'
      }
    },

    tag: {
      '&&': {
        background: '#FFFFFF',
        border: '1px solid #DCDEE0',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 500,
        margin: '2px'
      }
    },

    input: {
      maxWidth: 'calc(100% - 12px)',
      margin: '0 auto',
      fontSize: '14px',
      fontWeight: 500,
      height: '1.1876em'
    },

    labelRoot: {
      position: 'relative',
      transform: 'none'
    },

    label: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
      marginBottom: 4,
      color: '#1F2021',
      display: 'inline-flex',
      alignItems: 'center',

      '& > :not(:first-child)': {
        marginLeft: '8px'
      }
    },

    hintRoot: {
      color: '#999C9E',
      fontSize: '14px',
      lineHeight: '18px'
    }
  }
})
