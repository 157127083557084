import type {ReactNode, FC} from 'react'
import React from 'react'
import {useMatch} from 'react-router-dom'

import Logout from '@restapp/core-auth/components/LogoutMatcher/Logout'

const LOGOUT_ROUTE = '/logout'

type LogoutMatcherProps = {
  children: ReactNode
}

export const LogoutMatcher: FC<LogoutMatcherProps> = ({children}) => {
  const logoutMatch = useMatch(LOGOUT_ROUTE)

  if (logoutMatch) {
    return <Logout />
  }

  return children
}
