import type {CountryCodeType} from '@eda-restapp/i18n'
import {useSelector} from 'react-redux'

import {selectCountryCode} from '../selectors/selectCountryCode'

export interface CountryCodeHookResult {
  countryCode: CountryCodeType | null
}

export const useCountryCode = () => {
  const countryCode = useSelector(selectCountryCode)

  return {
    countryCode
  }
}
