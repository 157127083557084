import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Typography} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useInView} from 'react-intersection-observer'

import {NewsModal} from '@restapp/shared/components'

import type {NewsItem} from '../../../types'

import styles from './NewsPreview.module.css'

interface NewsPreviewProps {
  className?: string
  newsItem: NewsItem

  onMarkRead(id: string): void
}

function NewsPreview({className, newsItem, onMarkRead}: NewsPreviewProps) {
  const {t} = useI18n()
  const {ref, inView} = useInView({triggerOnce: true, threshold: 1})
  const [isOpen, setIsOpen] = useState(false)

  const TOPIC_TO_TEXT: Record<NewsItem['info']['topic'], string> = {
    news: t('main-communications.news-preview.novosti-servisa', 'Новости сервиса'),
    article: t('main-communications.news-preview.stati', 'Статьи'),
    tutorial: t('main-communications.news-preview.obuchenie', 'Обучение')
  }

  useEffect(() => {
    if (inView) {
      metrika({target: 'show_notifpage_news', params: {id: newsItem.id}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  function handleClick() {
    metrika({target: 'click_notifpage_news', params: {id: newsItem.id}})
    setIsOpen(true)
  }

  function formatDate() {
    const date = moment(newsItem.created)
    const format = date.year() !== moment().year() ? 'D MMMM YYYY' : 'D MMMM'
    return date.format(format)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const imageId = newsItem.preview.media_id

  return (
    <>
      <div
        ref={ref}
        className={cn(styles.root, className)}
        onClick={handleClick}
        data-id={newsItem.id}
        data-testid={`main-communications-news-item-${newsItem.id}`} /* Коммуникации | Карточка новости/опроса/обучения */
      >
        {newsItem.status === 'published' && (
          <div
            className={styles.newBadge}
            data-testid={
              'main-communications-news-preview-badge-new'
            } /* Коммуникации | Бейдж "New" на карточке превью новости/опроса/обучения */
          >
            {t('main-communications.news-preview.new-published-item-badge-text', 'New')}
          </div>
        )}
        {!!imageId && (
          <div
            className={styles.image}
            style={{backgroundImage: `url(${imageId})`}}
            data-testid='main-communications-news-image'
          />
        )}
        <div className={styles.content}>
          <p className={styles.topic}>{TOPIC_TO_TEXT[newsItem.info.topic]}</p>
          <h2 className={styles.title}>{newsItem.preview.title}</h2>
          <Typography className={styles.date}>{formatDate()}</Typography>
        </div>
      </div>
      <NewsModal
        open={isOpen}
        id={newsItem.id}
        isStory={newsItem.content.content_type === 'story'}
        title={newsItem.preview.title}
        pages={newsItem.content.pages}
        onClose={() => {
          if (newsItem.status === 'published') {
            void onMarkRead(newsItem.id)
          }
          closeModal()
        }}
      />
    </>
  )
}

export default NewsPreview
