import {Box} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type CSSProperties} from 'react'

import styles from './Badge.module.css'

export type Props = {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
  testid?: string
}

export const Badge: React.FC<Props> = (props) => {
  const {children, className, testid} = props

  return (
    <Box className={cn(styles.root, className)} data-testid={testid} alignItems='center' style={props.style}>
      <span className={styles.text}>{children}</span>
    </Box>
  )
}
