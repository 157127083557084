import {useExp3} from '@eda-restapp/configs'
import {type CountryCodeType, useI18n} from '@eda-restapp/i18n'
import React from 'react'

import {useServiceBrand} from '@restapp/shared'
import {useCountryCode} from '@restapp/shared/hooks'
import {ChevronRightMIcon, useIsMobileStrict} from '@eda-restapp/ui'

import {CountrySelector} from '../CountrySelector'
import OutsideLink from '../OutsideLink'

import useSupportContactsStyles from './SupportContacts.styles'

/**
    Component of the contact data block. It's a block that includes "Partner Support" label and a resizable button (depending on the name of the selected country) with the flag-icon of the selected country
    and its name (desktop only), by clicking on which a list of countries available for selection drops down (list depends on mapping-constant of International), as well as a block with contact information
    related to selection in country selector.
*/

export const SupportContacts = (): React.ReactElement => {
  const {countryCode} = useCountryCode()

  // TODO: While there's no libGeo -> initial selected country only in Native; as libGeo will be available, add initial selected country for Desktop
  const [supportCountry, setSupportCountry] = React.useState<CountryCodeType | null>(countryCode)

  const commonConfig = useExp3('restapp_common')
  const {t} = useI18n()
  const serviceBrand = useServiceBrand()
  const isMobile = useIsMobileStrict()
  const {classes: c} = useSupportContactsStyles()

  const renderSupportContacts = () => {
    if (!supportCountry) {
      return null
    }

    const selectedCountrySupportContacts = commonConfig.internationalSupportContacts.supportContacts[serviceBrand]

    return (
      <div className={c.supportCardsBlock}>
        {!!selectedCountrySupportContacts[supportCountry]?.supportPhoneNumber && (
          <div className={c.supportInfo}>
            <span
              className={c.supportInfoLabel}
              data-testid={
                'shared-ui-support-info-phone-label'
              } /* Support Contacts | Блок поддержки, подпись блока с телефоном */
            >
              {t('shared-ui.support-contacts.support-info-phone-label', 'Помощь и поддержка')}
            </span>
            <span
              className={c.supportInfoData}
              data-testid={'shared-ui-support-info-phone'} /* Support Contacts | Блок поддержки, телефон */
            >
              {selectedCountrySupportContacts[supportCountry].supportPhoneNumber}
            </span>
          </div>
        )}
        {!!selectedCountrySupportContacts[supportCountry]?.supportEmail && (
          <div className={c.supportInfo}>
            <span
              className={c.supportInfoLabel}
              data-testid={
                'shared-ui-support-info-email-label'
              } /* Support Contacts | Блок поддержки, подпись блока с эл.почтой */
            >
              {t('shared-ui.support-contacts.support-info-email-label', 'Электронный адрес')}
            </span>
            <span
              className={c.supportInfoData}
              data-testid={'shared-ui-support-info-email'} /* Support Contacts | Блок поддержки, электронная почта */
            >
              {selectedCountrySupportContacts[supportCountry].supportEmail}
            </span>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className={c.supportContactsBlock}>
        <div className={c.supportContactsLabel}>
          <div data-testid='auth-reset-password-support-text'>
            {t('core-legacy.landing-app.partner-support', 'Поддержка партнёров')}
          </div>
          <CountrySelector
            anchorOrigin={{
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: isMobile ? 'bottom' : 'top',
              horizontal: 'right'
            }}
            supportCountry={supportCountry}
            setSupportCountry={setSupportCountry}
          />
        </div>
        {renderSupportContacts()}
      </div>
      {commonConfig.internationalSupportContacts.signLink.enable && (
        <OutsideLink
          className={c.signLink}
          target='_blank'
          href={commonConfig.internationalSupportContacts.signLink.link}
        >
          <span>
            {t(
              'shared-ui.support-contacts.outside-sign-link-text',
              'Как стать партнёром сервиса {eatsServiceBrandLabel}'
            )}
          </span>
          <ChevronRightMIcon />
        </OutsideLink>
      )}
    </>
  )
}
