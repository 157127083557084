import {metrika} from '@eda-restapp/logger'
import type {RefObject} from 'react'
import React, {useCallback, useEffect} from 'react'

import type {NewsFilter, NewsSort, NewsItem} from '../../types'

import styles from './News.module.css'
import NewsHead from './NewsHead/NewsHead'
import NewsPreview from './NewsPreview/NewsPreview'
import NewsTabs from './NewsTabs/NewsTabs'

type NewsProps = {
  newsItems: NewsItem[]
  hasMore: boolean
  filter: NewsFilter
  sort: NewsSort
  isLoading: boolean
  scrollableRef: RefObject<HTMLElement>

  onLoadMore(): void

  onMarkRead(id: string): void

  onReadAll(): void

  onSetFilter(filter: NewsFilter): void

  onSetSort(sort: NewsSort): void
}

function News({
  scrollableRef,
  isLoading,
  onLoadMore,
  onSetFilter,
  onSetSort,
  onReadAll,
  onMarkRead,
  newsItems,
  sort,
  filter,
  hasMore
}: NewsProps) {
  useEffect(() => {
    const element = scrollableRef.current

    if (!element) {
      return
    }

    function handleScroll() {
      if (!element || isLoading || !hasMore) {
        return
      }

      const SCROLL_OFFSET = 200

      if (element.clientHeight + element.scrollTop >= element.scrollHeight - SCROLL_OFFSET) {
        onLoadMore()
      }
    }

    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [scrollableRef, isLoading, onLoadMore, hasMore])

  const handleChangeFilter = useCallback(
    (filter: NewsFilter) => {
      metrika({target: 'click_notifpage_news_filter', params: {filter}})

      onSetFilter(filter)
    },
    [onSetFilter]
  )

  const handleChangeSort = useCallback(
    (sort: NewsSort) => {
      metrika({target: 'click_notifpage_news_sort', params: {sort}})

      onSetSort(sort)
    },
    [onSetSort]
  )

  return (
    <div className={styles.pageContainer}>
      <NewsHead onMarkAllRead={onReadAll} />

      <NewsTabs filter={filter} sort={sort} onChangeFilter={handleChangeFilter} onChangeSort={handleChangeSort} />

      <div
        className={styles.newsContainer}
        data-testid={'notifications-news-container' /* Нотификации | Блок уведомлений на экране "Новости" */}
      >
        {newsItems.map((item) => (
          <NewsPreview key={item.id} className={styles.newsItem} newsItem={item} onMarkRead={onMarkRead} />
        ))}
      </div>
    </div>
  )
}

export default News
