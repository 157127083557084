import '@eda-restapp/ui/src/css/global.css'
import '@eda-restapp/ui/src/css/fonts.css'
import '@restapp/shared-assets/styles/fonts.css'
import '@restapp/shared-assets/styles/global.css'
import '@restapp/shared-assets/styles/global.mobile.css'
import {LangProvider, SUPPORTED_LANGUAGES, createFetcher} from '@eda-restapp/i18n'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/hy-am'
import 'moment/locale/ka'
import 'moment/locale/kk'
import 'moment/locale/ky'
import 'moment/locale/ru'
import 'moment/locale/uz-latn'
import 'moment/locale/az'
import React, {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import {createBrowserRouter, RouterProvider, useRouteError} from 'react-router-dom'
import {QueryClient} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {isAnyOf} from '@reduxjs/toolkit'

import {ApiDepsProvider} from '@restapp/core-api'
import {EnvContext} from '@restapp/core-di/context'
import App from '@restapp/core-legacy/common/components/App/App'
import {rootId} from '@restapp/core-legacy/constants'
import SnackbarNotifier from '@restapp/core-legacy/lib/snackbar-notifier'
import type {IEnv} from '@restapp/core-legacy/types'
import {XivaWebsocketObservable} from '@restapp/core-notifications'
import {CountrySelectSplitPage} from '@restapp/shared/components'
import {GlobalErrorBoundary} from '@restapp/shared-boundary'
import '@restapp/shared-polyfills'

import {selectToken, setToken, userLogout, unauthorized, setOauthToken} from '@restapp/core-auth'
import {initConfigs} from './initConfigs'
import {api, configsStore} from './coreDeps'
import {patchWindowOpen} from './native/utils/patchWindowOpen'
import {ServiceWorkerContainer} from './sw'
import {broadcastWebSocketNotifications} from './notifications'
import {clearDirectToken, nativeCommunicationChannel, notificationsChannel} from '@eda-restapp/microfrontend'
import {SnackbarsExperiment} from './SnackbarsExperiment'
import {RedirectHandler} from './RedirectHandler'
import {SeamlessTransferToOtherDomain} from './SeamlessTransferToOtherDomain'
import {initSecretsWatcher} from './initSecretsWatcher'
import {errorLogger, eventLogger, metrika, RUM} from '@eda-restapp/logger'
import {PermissionsProvider, StylesProviders} from './providers'
import {Communications} from '@eda-restapp/communications'
import {getTranslationFetcherUrl} from '@eda-restapp/utils'
import {startAppListening} from '@restapp/store'
import {ReduxProvider} from '@restapp/store/ReduxProvider'
import {store} from '@restapp/store/store'
import {useReactQueryDevBadgePosition} from '@eda-restapp/debug-menu/stateHooks'

eventLogger({
  name: 'bootstrap:init_app'
})

if (window.location.host.endsWith('.')) {
  location.host = location.host.slice(0, -1)
}

const i18nErrorLogger = errorLogger.namespace('i18n')

// version 130.0.0
const MAJOR_VERSION = VERSION.split('.')

const keysetsFolderUrl = getTranslationFetcherUrl('eda-restapp-core', MAJOR_VERSION[0] || '1')

const fetchKeyset = createFetcher(keysetsFolderUrl)

// Preload RU lang
const PromisePreloadRULocale = fetchKeyset(SUPPORTED_LANGUAGES.RU)
moment.locale(SUPPORTED_LANGUAGES.RU)

patchWindowOpen()

const root = createRoot(document.getElementById(rootId) as HTMLElement)

const initApp = async () => {
  initSecretsWatcher()

  try {
    await initConfigs()
  } catch (error) {
    errorLogger({
      level: 'error',
      error: error instanceof Error ? error : new Error('error initializing configs'),
      additional: {eventSlug: 'bootstrap:init_configs_error'}
    })
  }

  const snackbarNotifier = new SnackbarNotifier(() => {
    const configs = configsStore.getConfigs()
    const legacySnackbarConfig = configs.restapp_common.snackbar
    return {
      ...legacySnackbarConfig,
      enableNewSnackbars: configs.restapp_snackbars.enableNewSnackbars
    }
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      // Retries are configured in /packages/core-legacy/api/Api.ts
      queries: {
        retry: false,
        useErrorBoundary: configsStore.getConfigs().restapp_api.enableUseErrorBoundary
      },
      mutations: {retry: false}
    }
  })

  const env: Omit<IEnv, 'socketEvent$'> = {
    api,
    snackbarNotifier
  }

  //#region store configuration

  // Logger
  const isSensitiveAction = isAnyOf(setToken, setOauthToken)
  startAppListening({
    predicate: (action) =>
      Boolean(configsStore.getConfigs().restapp_logs.enable_redux_actions_logging) && !isSensitiveAction(action),
    effect: (action) => {
      eventLogger({
        name: 'redux-action',
        value: action.type,
        additional: {action, eventLevel: 'debug'}
      })
    }
  })

  // Logout cleanup
  startAppListening({
    matcher: isAnyOf(userLogout, unauthorized),
    effect: async (action, {getOriginalState}) => {
      if (userLogout.match(action)) {
        // User initiated logout
        metrika({target: 'log_out'})

        const token = selectToken(getOriginalState())

        if (token) {
          await api.request.request({
            url: '/4.0/restapp-front/api/v1/client/logout',
            method: 'POST',
            headers: {'X-Token': token}
          })
        }
      }

      nativeCommunicationChannel.broadcast({type: 'userLogout'})

      clearDirectToken()

      queryClient.removeQueries()
    }
  })
  //#endregion store configuration

  const socketEvent$ = new XivaWebsocketObservable({
    api,
    store
  })

  // TODO: Move to Notifications module
  broadcastWebSocketNotifications({socketEvent$, channel: notificationsChannel})

  const nativeApi = window.AndroidInterface || window.webkit?.messageHandlers.restappNative
  let NativeContainer: React.FC<{children?: React.ReactNode}> = ({children}) => <>{children}</>

  if (nativeApi) {
    const nativeModule = await import('./native')
    NativeContainer = nativeModule.getNativeContainer(nativeApi)
  }

  function RootRouter() {
    return (
      <SeamlessTransferToOtherDomain>
        <RedirectHandler>
          <App />
        </RedirectHandler>
      </SeamlessTransferToOtherDomain>
    )
  }

  // Для 17 реакта
  const router = createBrowserRouter([{path: '*', Component: RootRouter, ErrorBoundary: RootRouteErrorBoundary}], {
    basename: __COMMON_CONFIG__.BASENAME
  })

  const initedLocale = {
    [SUPPORTED_LANGUAGES.RU]: await PromisePreloadRULocale
  }

  const commonConfig = configsStore.getConfigs().restapp_common

  const handleErrorLogger = ({error, additional}: {error: Error; additional: Record<string, unknown>}) => {
    i18nErrorLogger({error, additional})
  }

  root.render(
    <StrictMode>
      <StylesProviders>
        <GlobalErrorBoundary>
          <LangProvider
            errorLogger={handleErrorLogger}
            defaultKeysets={initedLocale}
            fetcher={fetchKeyset}
            configs={{
              maxErrorTranslationKeysThreshold: commonConfig.maxErrorTranslationKeysThreshold,
              enabledCountries: commonConfig.enabledCountries,
              configAvailableServicesLanguages: commonConfig.configAvailableServicesLanguages
            }}
            onChangeLanguage={(lang) => {
              api.setLanguage(lang)
              // Set all queries as obsolete to refetch them to pull up current translations
              queryClient.invalidateQueries()

              eventLogger({name: 'lang-change', value: lang})

              switch (lang) {
                case SUPPORTED_LANGUAGES.EN:
                  moment.locale('en-gb')
                  break
                case SUPPORTED_LANGUAGES['ES-LA']:
                  moment.locale('es')
                  break
                case SUPPORTED_LANGUAGES.FR:
                  moment.locale('fr')
                  break
                case SUPPORTED_LANGUAGES.HY:
                  moment.locale('hy-am')
                  break
                case SUPPORTED_LANGUAGES.KA:
                  moment.locale('ka')
                  break
                case SUPPORTED_LANGUAGES.KK:
                  moment.locale('kk')
                  break
                case SUPPORTED_LANGUAGES.KY:
                  moment.locale('ky')
                  break
                case SUPPORTED_LANGUAGES.UZ:
                  moment.locale('uz-latn')
                  break
                case SUPPORTED_LANGUAGES.AZ:
                  moment.locale('az')
                  break
                case SUPPORTED_LANGUAGES.DEV:
                  moment.locale('en')
                  break
                default:
                  moment.locale([lang, 'ru'])
                  break
              }
            }}
          >
            <EnvContext.Provider value={env}>
              <ApiDepsProvider
                request={api.request.request}
                socketEvent$={socketEvent$}
                queryClient={queryClient}
                snackbar={snackbarNotifier}
              >
                <ReactQueryDevBadge />

                <ReduxProvider>
                  <Communications.Provider>
                    <PermissionsProvider>
                      <ServiceWorkerContainer />
                      <SnackbarsExperiment />
                      <CountrySelectSplitPage navigate={router.navigate}>
                        <NativeContainer>
                          <RouterProvider router={router} />
                        </NativeContainer>
                      </CountrySelectSplitPage>
                    </PermissionsProvider>
                  </Communications.Provider>
                </ReduxProvider>
              </ApiDepsProvider>
            </EnvContext.Provider>
          </LangProvider>
        </GlobalErrorBoundary>
      </StylesProviders>
    </StrictMode>
  )
  if ('requestIdleCallback' in window && typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(() => RUM.sendTimeMark(RUM.Counter.ReactInited), {timeout: 1200000})
  } else {
    setTimeout(() => RUM.sendTimeMark(RUM.Counter.ReactInited), 0)
  }
}

function RootRouteErrorBoundary() {
  const error = useRouteError()

  if (error) {
    // Выкидываем ошибку выше чтобы она попала в GlobalErrorBoundary и залоггировалась там
    throw error
  }

  return null
}

function ReactQueryDevBadge() {
  const [position] = useReactQueryDevBadgePosition()

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  return <ReactQueryDevtools initialIsOpen={false} position={position} />
}

void initApp().finally(() => {
  RUM.timeEnd(RUM.Counter.MainJS)
})
