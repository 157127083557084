import {useSearchParams} from 'react-router-dom'

interface IBusyModeModal {
  isOpen: boolean
  placeId?: number
  open: (placeId: number) => void
  close: () => void
}

const BUSY_MODE_QUERY_NAME = 'busyModePlaceId'

export function useBusyModeModal(): IBusyModeModal {
  const [searchParams, setSearchParams] = useSearchParams()
  const placeIdQueryParam = searchParams.get(BUSY_MODE_QUERY_NAME)
  const placeId: number | undefined = placeIdQueryParam !== null ? Number(placeIdQueryParam) : undefined

  return {
    isOpen: Boolean(placeId),
    placeId: placeId,
    open: (placeId: number) => setSearchParams({...searchParams, [BUSY_MODE_QUERY_NAME]: placeId.toString()}),
    close: () => {
      searchParams.delete(BUSY_MODE_QUERY_NAME)
      setSearchParams(searchParams)
    }
  }
}
