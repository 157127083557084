import type {ReactNode} from 'react'
import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'

import {setSidebarSize} from '../../actions'

import {usePageRootWrapperStyles} from './PageRootWrapper.styles'

interface PageRootWrapperProps {
  Sidebar: ReactNode
  children?: React.ReactNode
}

const PageRootWrapper: React.FC<PageRootWrapperProps> = ({children, Sidebar}) => {
  const {classes: c} = usePageRootWrapperStyles()
  const sidebarWatcherRef = useReportSidebarResize()

  return (
    <div id={undefined} className={c.root}>
      <aside ref={sidebarWatcherRef} className={c.sidebar}>
        {Sidebar}
      </aside>

      <div className={c.content}>{children}</div>
    </div>
  )
}

export default PageRootWrapper

export function useReportSidebarResize() {
  const ref = useRef<HTMLElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const refLatest = ref.current

    if (ResizeObserver !== undefined && refLatest) {
      try {
        const observer = new ResizeObserver(([entry]) => void dispatch(setSidebarSize(entry?.contentRect.width || 0)))
        observer.observe(refLatest)
        return () => void observer.disconnect()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  return ref
}
