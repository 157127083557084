import {useApiQuery} from '@restapp/core-api'
import type {ApiQueryConfig} from '@restapp/core-api/types'

type DefaultData = Parameters<
  NonNullable<ApiQueryConfig<'/4.0/restapp-front/places/v1/country-config', 'GET'>['select']>
>[0]

type Options<TSelectedData> = Omit<
  ApiQueryConfig<'/4.0/restapp-front/places/v1/country-config', 'GET', TSelectedData>,
  'url' | 'body' | 'method' | 'params'
>

export const useCountryConfig = <TSelectedData = DefaultData>(
  countries: string[],
  options: Options<TSelectedData> = {}
) =>
  useApiQuery({
    url: '/4.0/restapp-front/places/v1/country-config',
    method: 'GET',
    params: {countries},
    ...options
  })
