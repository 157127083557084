/**
 * Возвращает разделители разряда и десятичной части в зависимости от переданной локали
 * @param {string | string[]} lang Локаль
 * @returns {object}
 */
export const getNumberFormatSeparators = (
  lang: string | string[]
): {
  thousands: string
  decimal: string
} => {
  const numberWithGroupAndDecimalSeparator = 1000.1
  const data = Intl.NumberFormat(lang).formatToParts(numberWithGroupAndDecimalSeparator)

  return {
    thousands: data.find((part) => part.type === 'group')?.value || ' ',
    decimal: data.find((part) => part.type === 'decimal')?.value || '.'
  }
}
