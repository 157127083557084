import {useApiQuery} from '@restapp/core-api'

type UseCheckPartnerProps = {
  directToken: string | null | undefined
}

export const useCheckPartner = ({directToken}: UseCheckPartnerProps) => {
  const {
    data: partner,
    error,
    isLoading
  } = useApiQuery({
    url: '/4.0/restapp-front/marketing/v1/check-partner',
    method: 'POST',
    enabled: Boolean(directToken),
    keepPreviousData: true,
    staleTime: 60_000,
    headers: directToken
      ? {
          Authorization: `Bearer ${directToken}`
        }
      : undefined
  })

  return {
    error,
    isLoading,
    partner
  }
}
