import {t} from '@eda-restapp/i18n'

export const CAMPAIGNS_STATUSES_HAS_STATS = ['active', 'suspended']

export const PROMOTION_CONSTANTS = {
  get defaultChartEmptyReason() {
    return t('promotion.common.net-dannikh-za-vibrannii-period', 'Нет данных за выбранный период')
  },
  get editedDescription() {
    return t('promotion.common.otredaktirovano-moderatorom', 'Отредактировано модератором')
  },
  get editedExplanation() {
    return t(
      'promotion.common.veroyatno-vi-dopustili-oshibku-i-te',
      'Вероятно, Вы допустили ошибку и текст был отредактирован, но на показы это не повлияло'
    )
  },
  get processDescription() {
    return t(
      'promotion.common.banner-na-moderatsii-obichno-eto-za',
      'Баннер на модерации. Обычно это занимает 2-3 часа.'
    )
  },
  get rejectedDescription() {
    return t('promotion.common.zagruzhennii-banner-ne-proshel-moder', 'Загруженный баннер не прошел модерацию')
  }
}

export const SUPPORT_MAIL = 'rest@eda.yandex.ru'

export const CPA_FULLSCREEN_QUERY_NAME = 'showCPAFullscreen'
