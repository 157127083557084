import {useExp3} from '@eda-restapp/configs'
import type {FC} from 'react'
import {useEffect, useRef, useState} from 'react'

import {useApiQuery} from '@restapp/core-api'
import {useIsEnabledInCurrentNetwork} from '@restapp/shared'
import type {LinkedAccount} from '@restapp/shared-api/types/passport'

import type {ExternalAccountSuggest} from '../../../../types'
import {usePassportDrawer} from '../../../hooks'
import {useGetAvatarUrl} from '../../../hooks/useGetAvatarURL'
import {PassportDrawer} from '../PassportDrawer'

import {PassportFullScreenContainer} from './PassportFullScreen'
import {PassportModal} from './PassportModal'

type PassportModalsProps = {
  suggestedAccount?: ExternalAccountSuggest
  onBindOtherAccount: () => void
  onBindSuggestedAccount: () => void
  onAuthWithLinkedAccount: (account: LinkedAccount) => void
}

export const PassportModals: FC<PassportModalsProps> = ({
  suggestedAccount,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onAuthWithLinkedAccount
}) => {
  const authConfig = useExp3('restapp_auth')
  const passportDrawer = usePassportDrawer()
  const fullScreenEnabled = useIsEnabledInCurrentNetwork(authConfig.passportFullScreenEnabledType)
  const [isPassportModalOpen, setIsPassportModalOpen] = useState(false)
  const wasAutoOpenedRef = useRef(false)
  const getAvatarUrl = useGetAvatarUrl()

  const linkedAccountsQuery = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/linked-accounts',
    method: 'GET',
    staleTime: 60_000 * 60,
    onSuccess: (data) => {
      // Auto open only once, on app open
      const wasOpened = wasAutoOpenedRef.current
      if (wasOpened) {
        return
      }

      if (fullScreenEnabled) {
        setIsPassportModalOpen(true)
        passportDrawer.open()

        wasAutoOpenedRef.current = true
        return
      }

      const hasLinkedAccounts = data.accounts.length > 0
      if (hasLinkedAccounts) {
        if (passportDrawer.isOpen) {
          passportDrawer.close()
        }

        setIsPassportModalOpen(true)
      } else {
        passportDrawer.open()
      }
      wasAutoOpenedRef.current = true
    }
  })

  const linkedAccounts = linkedAccountsQuery.data?.accounts.length || 0
  const sendedInvites = linkedAccountsQuery.data?.invites?.length || 0
  const maxLinkedAccountLimitReached =
    linkedAccounts + sendedInvites >= (linkedAccountsQuery.data?.meta.accounts_limit ?? 0)
  const modalPushingTimeoutMinutes = fullScreenEnabled
    ? authConfig.passportPushingFullScreenTimeoutMinutes * 60_000
    : authConfig.passportPushingModalTimeoutMinutes * 60_000

  useEffect(() => {
    // Drawer and modal closed. Setup timer for pushing modal
    if (!passportDrawer.isOpen && !isPassportModalOpen) {
      const timeout = setTimeout(() => {
        setIsPassportModalOpen(true)
      }, modalPushingTimeoutMinutes)

      return () => clearTimeout(timeout)
    }
  }, [passportDrawer.isOpen, isPassportModalOpen, modalPushingTimeoutMinutes])

  const handleDrawerClose = () => {
    passportDrawer.close()
  }

  const handleModalClose = () => {
    setIsPassportModalOpen(false)
  }

  if (linkedAccountsQuery.isLoading) {
    return null
  }

  const hasLinkedAccounts = linkedAccountsQuery.data && linkedAccountsQuery.data?.accounts.length > 0

  const linkedAccountsWithAvatars =
    linkedAccountsQuery.data?.accounts.map((account) => {
      return {...account, avatar: getAvatarUrl(account.avatar)}
    }) ?? []

  return (
    <>
      <PassportDrawer
        open={passportDrawer.isOpen}
        suggestedAccount={suggestedAccount}
        maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
        onClose={handleDrawerClose}
        onBindOtherAccount={onBindOtherAccount}
        onBindSuggestedAccount={onBindSuggestedAccount}
      />
      {fullScreenEnabled ? (
        <PassportFullScreenContainer
          open={isPassportModalOpen}
          onClose={handleModalClose}
          closeSecondsDelay={authConfig.passportFullScreenCloseTimeoutSeconds}
          hasLinkedAccounts={hasLinkedAccounts}
          accounts={linkedAccountsWithAvatars}
          suggestedAccount={
            suggestedAccount && {
              ...suggestedAccount,
              avatar: getAvatarUrl(suggestedAccount.avatar)
            }
          }
          maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
          onBindOtherAccount={onBindOtherAccount}
          onBindSuggestedAccount={onBindSuggestedAccount}
          onAuthWithLinkedAccount={onAuthWithLinkedAccount}
        />
      ) : (
        <PassportModal
          openDrawer={passportDrawer.open}
          open={isPassportModalOpen}
          onClose={handleModalClose}
          hasLinkedAccounts={hasLinkedAccounts}
          accounts={linkedAccountsWithAvatars}
          suggestedAccount={
            suggestedAccount && {
              ...suggestedAccount,
              avatar: getAvatarUrl(suggestedAccount.avatar)
            }
          }
          maxLinkedAccountLimitReached={maxLinkedAccountLimitReached}
          onBindOtherAccount={onBindOtherAccount}
          onBindSuggestedAccount={onBindSuggestedAccount}
          onAuthWithLinkedAccount={onAuthWithLinkedAccount}
        />
      )}
    </>
  )
}
