import {Box, LegacyButton as Button, CrossRoundFill, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import type {ReactNode} from 'react'
import React from 'react'

import styles from './GlobalAlert.module.css'

export type GlobalAlertProps = {
  backgroundColor: string
  text: string
  textColor: string
  textAlign?: 'left' | 'center'
  className?: string
  button?: ReactNode
  onClose?: () => void
  crossButtonColor?: string
  crossButtonOpacity?: number
}

const GlobalAlertInternal: React.FC<GlobalAlertProps> = ({
  backgroundColor,
  text,
  textColor,
  textAlign = 'left',
  button,
  onClose,
  className,
  crossButtonColor = '#999C9E',
  crossButtonOpacity = 1
}) => {
  const isMobile = useIsMobileStrict()

  const hasCloseButton = !!onClose

  const renderAlertTypography = () => {
    if (isMobile) {
      return (
        <Typography
          variant='caption1'
          style={{color: textColor, textAlign: 'center', minHeight: button ? undefined : '30px'}}
          className={styles.text}
        >
          {text}
        </Typography>
      )
    }

    return (
      <Typography variant='body2' style={{color: textColor, textAlign: textAlign}} className={styles.text} my='s'>
        {text}
      </Typography>
    )
  }

  return (
    <Box
      data-testid='global-alert'
      justifyContent='center'
      py='s'
      className={cn(styles.container, hasCloseButton && styles.containerWithCrossButton, className)}
      style={{
        background: backgroundColor
      }}
    >
      {hasCloseButton && (
        <Box onClick={onClose} className={styles.closeButton}>
          <CrossRoundFill opacity={crossButtonOpacity} color={crossButtonColor} />
        </Box>
      )}

      <Box
        gap={isMobile ? 's' : 'l'}
        alignItems='center'
        justifyContent='center'
        flexGrow={1}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {renderAlertTypography()}

        {button}
      </Box>
    </Box>
  )
}

type GlobalAlertButtonProps = {
  iconSrc?: string
  background: string
  text: string
  textColor: string
  onClick: () => void
}

const GlobalAlertButton = ({background, onClick, text, textColor, iconSrc}: GlobalAlertButtonProps) => (
  <Button
    size='s'
    className={styles.button}
    onClick={onClick}
    start={iconSrc ? <img loading='lazy' width={18} height={18} src={iconSrc} /> : undefined}
    styles={{background}}
  >
    <Typography variant='caption1' thickness='medium' style={{color: textColor}}>
      {text}
    </Typography>
  </Button>
)

export const GlobalAlert = Object.assign(GlobalAlertInternal, {Button: GlobalAlertButton})
