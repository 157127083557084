import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import type {Workbox, WorkboxLifecycleWaitingEvent} from 'workbox-window'
import {selectUpdateAccepted, updateAvailable} from '../serviceSlice'
import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'

type AppUpdateProps = {
  workbox: Workbox
}

const logEvent = eventLogger.namespace('appUpdate')

export const AppUpdate = ({workbox}: AppUpdateProps) => {
  const dispatch = useDispatch()
  const isUpdateAccepted = useSelector(selectUpdateAccepted)
  const {maxUpdateAlertDelayInMinutes, workboxCheckForUpdatesIntervalInMinutes} = useExp3('restapp_service_updates')

  useEffect(() => {
    const interval = setInterval(workbox.update, workboxCheckForUpdatesIntervalInMinutes)

    return () => clearInterval(interval)
  }, [workbox, workboxCheckForUpdatesIntervalInMinutes])

  useEffect(() => {
    const handleWaiting = async (event: WorkboxLifecycleWaitingEvent) => {
      logEvent({name: 'newServiceWorkerWaiting'})

      if (event.wasWaitingBeforeRegister) {
        // 1. User reloaded page
        // 2. workbox tried to register
        // 3. but new service worker was in waiting state
        // 4. skip waiting to new version
        workbox.messageSkipWaiting()
        logEvent({name: 'wasWaitingBeforeRegister_skipWaiting'})
      } else {
        const randomDelayOfUpdate = 60_000 * Math.round(Math.random() * maxUpdateAlertDelayInMinutes)
        setTimeout(() => {
          dispatch(updateAvailable())
          logEvent({name: 'updateButtonAvailable', additional: {delayedFor: randomDelayOfUpdate}})
        }, randomDelayOfUpdate)
      }
    }

    workbox.addEventListener('waiting', handleWaiting)

    return () => {
      workbox.removeEventListener('waiting', handleWaiting)
    }
  }, [workbox, dispatch, maxUpdateAlertDelayInMinutes])

  useEffect(() => {
    if (isUpdateAccepted) {
      logEvent({name: 'updateAccepted'})
      workbox.messageSkipWaiting()
    }
  }, [isUpdateAccepted, workbox])

  useEffect(() => {
    const handleNewServiceWorkerControl = () => {
      // Сменился активный ServiceWorker нужно перезагрузить страницу
      logEvent({name: 'newSwTookControl_pageWillReload'})
      window.location.reload()
    }

    workbox.addEventListener('controlling', handleNewServiceWorkerControl)

    return () => workbox.removeEventListener('controlling', handleNewServiceWorkerControl)
  }, [workbox])

  return null
}
