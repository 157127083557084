import {useDispatch, useSelector} from 'react-redux'

import {selectPlaces} from '../actions'
import {selectPlaceIds} from '../selectors/selectPlaceIds'

type UsePlacesSelect = {
  selectedPlaceId: number | undefined
  selectedPlaceIds: number[]
  setSelectedPlaceId: (placeId: number) => void
  setSelectedPlaceIds: (placeIds: number[]) => void
}

export const usePlacesSelect = (): UsePlacesSelect => {
  const dispatch = useDispatch()

  const selectedPlaceIds = useSelector(selectPlaceIds)
  const selectedPlaceId = selectedPlaceIds[0] || undefined

  const setSelectedPlaceId = (placeId: number) => dispatch(selectPlaces([placeId]))
  const setSelectedPlaceIds = (placeIds: number[]) => dispatch(selectPlaces(placeIds))

  return {
    selectedPlaceId,
    setSelectedPlaceId,
    selectedPlaceIds,
    setSelectedPlaceIds
  }
}
