import {useI18n} from '@eda-restapp/i18n'
import {Box, type BoxConfig, Typography} from '@eda-restapp/ui'
import moment from 'moment'
import React from 'react'

import type {Service} from '@restapp/shared-api'
import {ItemAction} from '@restapp/shared-ui/ContextMenu'

export interface PlusConditionsProps extends BoxConfig {
  service?: Service
  commission: string
  startDate: moment.Moment
  hasAccessToEditPlus?: boolean
  isShowInSelect?: boolean
  isDeactivating?: boolean
  hideNotActivateService?: boolean
  showDisableAllServices?: boolean
  onDisable?: (service: Service[]) => void
}

const PlusConditions: React.FC<PlusConditionsProps> = ({
  service,
  commission,
  startDate,
  isShowInSelect,
  isDeactivating,
  onDisable,
  showDisableAllServices,
  hideNotActivateService,
  hasAccessToEditPlus,
  ...boxProps
}) => {
  const {t} = useI18n()

  const getCaption = () => {
    switch (service) {
      case 'eda':
        return t('shared-places.plus-conditions.conditions-in-eda', 'Условия на сервисе {eatsServiceBrandLabel}')
      case 'dc':
        return t('shared-places.plus-conditions.conditions-in-dc', 'Условия на сервисе {dcServiceBrandLabel}')
      default:
        return t('shared-places.plus-conditions.conditions', 'Условия')
    }
  }

  const willStartFrom = t('shared-places.plus-conditions.will-start-from', 'Начнёт действовать:')
  const startedFrom = t('shared-places.plus-conditions.started-from', 'Действует с:')

  const disableOneService = () => {
    if (isDeactivating || !hasAccessToEditPlus || !onDisable || !service) {
      return
    }

    onDisable([service])
  }

  const disableAllServices = () => {
    if (isDeactivating || !hasAccessToEditPlus || !onDisable) {
      return
    }

    onDisable(['eda', 'dc'])
  }

  const getItemActions = () => {
    if (!onDisable) {
      return []
    }

    const itemActions = []

    const disabled = isDeactivating || !hasAccessToEditPlus
    const tooltip = t('shared-places.operator-info-tooltip.disable', 'Отключить Плюс могут только \nадминистраторы')

    if (showDisableAllServices) {
      itemActions.push({
        id: '0',
        title: t('shared-places.plus-conditions.disable-in-both-services', 'Отключить в двух сервисах'),
        showTooltip: !hasAccessToEditPlus,
        onClick: disableAllServices,
        disabled,
        tooltip
      })
    }

    if (service) {
      itemActions.push({
        id: '1',
        title:
          service === 'eda'
            ? t('shared-places.plus-conditions.disable-in-eda', 'Отключить на сервисе {eatsServiceBrandLabel}')
            : t('shared-places.plus-conditions.disable-in-dc', 'Отключить на сервисе {dcServiceBrandLabel}'),
        showTooltip: !hasAccessToEditPlus,
        onClick: disableOneService,
        disabled,
        tooltip
      })
    }

    return itemActions
  }

  return (
    <Box flexDirection='column' {...boxProps}>
      {isShowInSelect && (
        <Box flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Box flexDirection='column'>
            <Typography variant='caption1' lineHeight='normal'>
              {startDate.isAfter(moment(), 'date') ? willStartFrom : startedFrom} {startDate.format('D MMMM YYYY')}
            </Typography>

            <Typography variant='caption1' lineHeight='normal' color='minor'>
              {service === 'eda' &&
                t(
                  'shared-places.plus-conditions.plus-commission-value-in-eda',
                  'Комиссия сервиса {eatsServiceBrandLabel}: {commission}% от заказов с накопленными баллами',
                  {
                    commission
                  }
                )}
              {service === 'dc' &&
                t(
                  'shared-places.plus-conditions.plus-commission-value-in-dc',
                  'Комиссия сервиса {dcServiceBrandLabel}: {commission}% от заказов с накопленными баллами',
                  {
                    commission
                  }
                )}
            </Typography>
          </Box>

          <ItemAction actions={getItemActions()} />
        </Box>
      )}

      {!isShowInSelect && (
        <>
          <Typography
            variant='caption1'
            lineHeight='normal'
            thickness='regular'
            color='minor'
            mb='s'
            data-testid={`condition-caption-${service ?? ''}` /* Условия Плюса | Заголовок */}
          >
            {getCaption()}
          </Typography>
          <Typography
            variant='body2'
            lineHeight='normal'
            thickness='medium'
            mb={hideNotActivateService ? undefined : 's'}
          >
            {t('shared-places.plus-conditions.plus-commission', 'Комиссия Плюса:')}{' '}
            <Typography variant='body2' lineHeight='normal' thickness='regular' inline>
              {commission}%
            </Typography>
          </Typography>

          {!hideNotActivateService && (
            <Typography variant='body2' lineHeight='normal' thickness='medium'>
              {startDate.isAfter(moment(), 'date') ? willStartFrom : startedFrom}{' '}
              <Typography variant='body2' lineHeight='normal' thickness='regular' inline>
                {startDate.format('D MMMM YYYY')}
              </Typography>
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default PlusConditions
