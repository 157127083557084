import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Typography, useIsMobileStrict, PortalWithBackdrop, OutsideLink, CrossLIcon} from '@eda-restapp/ui'
import React, {useEffect, useState} from 'react'

import {ModalBody, ModalHeader, ModalLayout} from '@restapp/shared-ui'

import {usePromocode, usePromocodeType} from '../../hooks'
import {OverlaySpinner} from '../OverlaySpinner'
import {TicketPromocodeContainer} from '../TicketPromocode'

import styles from './DirectPaymentPopup.module.css'
import {useDirectMessage} from './useDirectMessage'

type IDirectPaymentPopup = {
  close: (success: boolean) => void
  directUsername: string
}

const DEFAULT_WIDTH = 740
const DEFAULT_HEIGHT = 461

const INITIAL_IFRAME_INDEX = 1

export default function DirectPaymentPopup({close, directUsername}: IDirectPaymentPopup) {
  const {t, tRaw} = useI18n()

  const isMobile = useIsMobileStrict()
  const config = useExp3('restapp_promotion')

  const [frameIsLoading, setFrameIsLoading] = useState<boolean>(true)
  const [frameReloadCount, setFrameReloadCount] = useState(INITIAL_IFRAME_INDEX)

  const promocodeType = usePromocodeType()
  const promocodeQuery = usePromocode(promocodeType.value)

  const updateIframeLoadTooLong = () => {
    if (
      isContentReady ||
      !config.payment_stuck_retry_quantity ||
      frameReloadCount > config.payment_stuck_retry_quantity
    ) {
      return
    }

    const timer = setTimeout(() => {
      eventLogger({name: 'promotion:direct_payment_iframe_reload'})
      setFrameReloadCount((prev) => prev + 1)
    }, config.payment_stuck_timeout)

    return () => clearTimeout(timer)
  }

  const {isSelfContainedFrame, frameUrl, frameWidth, frameHeight, frameRef} = useDirectMessage({close, directUsername})
  const isContentReady = frameWidth !== undefined && frameHeight !== undefined && !frameIsLoading

  useEffect(updateIframeLoadTooLong, [
    frameReloadCount,
    isContentReady,
    config.payment_stuck_retry_quantity,
    config.payment_stuck_timeout
  ])

  return (
    <PortalWithBackdrop backdrop={false}>
      <ModalLayout
        visible
        resizable
        close={() => close(false)}
        classes={{root: styles.root, content: styles.content, close: styles.close, wrap: styles.wrap}}
      >
        {!isSelfContainedFrame && (
          <ModalHeader>{t('promotion.direct-payment-popup.popolnenie-schyota', 'Пополнение счёта')}</ModalHeader>
        )}

        <ModalBody withoutPadding className={styles.body}>
          <div className={styles.modalWrapper}>
            {isContentReady || !config.direct.enablePaymentModalSpinner ? null : (
              <div className={styles.container}>
                <OverlaySpinner />
                {!!config.direct.manualPaymentLink && (
                  <Typography variant='caption1' className={styles.directTimeoutNotice}>
                    {tRaw(
                      'promotion.direct-payment-popup.direct-timeout-notice',
                      'Если в течение 5 секунд окно с оплатой не загрузилось, {directLink} по ссылке и произведите оплату',
                      {
                        directLink: (
                          <OutsideLink target='_blank' href={config.direct.manualPaymentLink} rel='noopener noreferrer'>
                            {t('promotion.direct-payment-popup.go-to-direct', 'перейдите в Директ')}
                          </OutsideLink>
                        )
                      }
                    )}
                  </Typography>
                )}
              </div>
            )}
            {!!frameUrl && (
              <iframe
                key={`${frameReloadCount}${frameUrl}`}
                src={frameUrl}
                frameBorder='0'
                ref={frameRef}
                style={{
                  width: isMobile ? '100vw' : `${frameWidth ?? DEFAULT_WIDTH}px`,
                  height: `${frameHeight ?? DEFAULT_HEIGHT}px`,
                  minHeight: isSelfContainedFrame ? 'auto' : '654px', // хардкод минимальной высоты, директ не возвращает правильного значения
                  visibility: isContentReady || !config.direct.enablePaymentModalSpinner ? 'visible' : 'hidden'
                }}
                onLoad={() => {
                  setFrameIsLoading(false)
                }}
              />
            )}
          </div>

          <CrossLIcon
            className={styles.cross}
            onClick={() => close(false)}
            data-testid={'ui-modal-close' /*UI | Modal close icon*/}
          />
        </ModalBody>
        {!!promocodeQuery.promocode && (
          <div className={styles.promocode}>
            <TicketPromocodeContainer
              placement={isMobile ? 'top' : 'bottom'}
              isRow={!isMobile}
              promocode={promocodeQuery.promocode}
              expirationDate={promocodeQuery.expireAt}
              promoDescription={promocodeQuery.information?.description}
              promocodeType={promocodeType.value}
              separationLineColor={'#eff2f7'}
            />
          </div>
        )}
      </ModalLayout>
    </PortalWithBackdrop>
  )
}
