import {errorLogger, eventLogger} from '@eda-restapp/logger'
import queryString from 'query-string'
import {defer, Observable} from 'rxjs'
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators'

import type {IApi} from '@restapp/core-legacy'
import {fromConfigs} from '@restapp/shared/rx-utils/fromConfigs'
import type {Api} from '@restapp/shared-api'

export interface Services {
  api: IApi
}

const SERVICE_NAME = 'eats-partner'
const CHANNEL_NAME = 'xiva_websocket'

type Response = Api['/4.0/restapp-front/notify/v1/get-token']['POST']['response']
type NewResponse = Api['/4.0/restapp-front/communications/v1/notify/get-token']['POST']['response']

export default class XivaUrlObservable extends Observable<string> {
  constructor({api}: Services) {
    const isNewApiEnabled$ = fromConfigs((configs) => configs.restapp_webpush.newApiEnabled)

    let lastResponse: Response | NewResponse | null = null
    let index = 0

    const url$ = isNewApiEnabled$.pipe(
      distinctUntilChanged((A, B) => {
        if (A !== B) {
          index = 0
        }
        return A === B
      }),
      switchMap((isNewApiEnabled) =>
        defer(async () => {
          // если конфиги не поменялись, то возвращается кешированное значение, иначе перезапрос ручки
          if (index > 0 && lastResponse && calculateWebsocketTs(lastResponse.websocket_url) > Date.now()) {
            return lastResponse
          }

          try {
            const data = await api.request.post(
              isNewApiEnabled
                ? '/4.0/restapp-front/communications/v1/notify/get-token'
                : '/4.0/restapp-front/notify/v1/get-token',
              {
                ...(isNewApiEnabled
                  ? {device_type: 'web'}
                  : {
                      service: SERVICE_NAME,
                      client: {
                        device_type: 'web'
                      },
                      channel: {
                        name: CHANNEL_NAME
                      }
                    })
              }
            )
            lastResponse = data
            index++

            eventLogger({name: 'notifications:notifier_get_token', additional: {data}})

            return data
          } catch (e) {
            void errorLogger({
              message: 'notifications:notifier_get_token',
              level: 'error',
              error: e instanceof Error ? e : new Error('notifications:notifier_get_token', {cause: e})
            })

            throw e
          }
        }).pipe(
          map(({websocket_url: url}) => {
            const {url: baseUrl, query} = queryString.parseUrl(url)

            return `${baseUrl}?${queryString.stringify({
              ...query,
              client: 'restapp_web',
              session: '321'
            })}`
          })
        )
      )
    )

    super((observer) => url$.subscribe(observer))
  }
}

function calculateWebsocketTs(url: string) {
  const {query} = queryString.parseUrl(url)

  return parseInt(String(query.ts), 10) * 1000
}
