export function createHeaders(token: string | null, oauth: string | null, clientId: string | null) {
  const headers: Record<string, string> = {}

  if (token) {
    headers['X-Token'] = token
  }
  if (oauth) {
    headers['X-Oauth'] = `Bearer ${oauth}`

    if (clientId) {
      headers['X-Partner-Id'] = clientId
    }
  }

  return headers
}
