import {t} from '@eda-restapp/i18n'

export const ERROR_PROMOTION = {
  get FORBIDDEN_PLACE_TYPE() {
    return t('promotion.errors.cannot-start-campaign-for-this-place', 'Нельзя начать продвижение для этой точки')
  },
  get FORBIDDEN_BY_DEVICE() {
    return t('promotion.errors.desktop-only', 'Доступно только на компьютере')
  },
  get CANNOT_ENABLE_PROMOTION() {
    return t('promotion.errors.cannot-start-campaign', 'Извините, не удалось начать продвижение')
  },
  get CANNOT_ENABLE_PROMOTION_LOW_RATING() {
    return t(
      'promotion.errors.failed-start-campaign-because-of-place-low-rating',
      'Не получилось запустить кампанию из-за низкого рейтинга одного из ресторанов'
    )
  },
  get EDIT_PROMOTION() {
    return t('promotion.errors.campaign-params-update-failed', 'Ошибка. Не удалось изменить параметры продвижения')
  },
  get SUSPEND_PROMOTION() {
    return t('promotion.errors.campaign-suspend-failed', 'Ошибка. Не удалось остановить продвижение')
  },
  get NO_ACCESS_TO_CAMPAIGN() {
    return t('promotion.errors.campaign-access-denied', 'Доступ к кампании запрещён')
  },
  get CAMPAIGN_ARCHIVE() {
    return t('promotion.errors.archive-failed', 'При архивации кампании произошла ошибка')
  },
  get NO_PLACES_AVAILABLE() {
    return t('promotion.errors.no-places-for-promotion', 'Нет ресторанов, для которых можно начать продвижение')
  },
  get FILL_ALL_REQUIRED_DIRECT_FIELDS() {
    return t(
      'promotion.errors.fill-all-fields',
      'Заполните все необходимые данные в кабинете Яндекс Директа, чтобы создать рекламную кампанию.'
    )
  }
}
