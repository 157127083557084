import {t} from '@eda-restapp/i18n'

export const SELECTABLE_PERIODS = {
  get day() {
    return t('shared-ui.constants.za-den', 'За день')
  },
  get week() {
    return t('shared-ui.constants.za-nedelyu', 'За неделю')
  },
  get month() {
    return t('shared-ui.constants.za-mesyats', 'За месяц')
  },
  get year() {
    return t('shared-ui.constants.za-god', 'За год')
  },
  get all() {
    return t('shared-ui.constants.za-vsyo-vremya', 'За всё время')
  },
  get custom() {
    return t('shared-ui.constants.svoi-period', 'Свой период')
  }
}

export const CUSTOM_DATE_PERIOD = 'custom'
export const ALL_DATE_PERIOD = 'all'
// Используется для показа кастомного периода, напр. 01.01.21 - 01.02.21
export const USER_DATE_PERIOD = 'user_period'
export const WEEK_DATE_PERIOD = 'week'
export const MONTH_DATE_PERIOD = 'month'
export const YEAR_DATE_PERIOD = 'year'
export const DAYS_DATE_PERIOD = 'days'

export const DEFAULT_DATE_PERIODS: ('week' | 'month' | 'year' | 'custom')[] = [
  WEEK_DATE_PERIOD,
  MONTH_DATE_PERIOD,
  YEAR_DATE_PERIOD,
  CUSTOM_DATE_PERIOD
]
