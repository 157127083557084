import {useEffect} from 'react'

import type {PortalOptions, WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'

import type {DialogProps} from '../../types'

interface Props extends DialogProps {
  children: () => JSX.Element
  options?: PortalOptions
}

export function NakedPortalAdapter({
  openPortal,
  options,
  children,
  visible,
  onClose,
  onConfirm
}: Props & WithPortalProps) {
  useEffect(() => {
    if (!visible) {
      return
    }

    const portal = openPortal<boolean>(children(), options)

    portal.closed.then((result) => (result ? onConfirm() : onClose()), onClose)

    return () => portal.close()
  }, [visible])

  return null
}

export default withPortals<Props>(NakedPortalAdapter)
