import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {RestaurantSelectModalContainer} from '@eda-restapp/ui'
import {useEffect, useMemo, useState, type FC} from 'react'

import {usePlaces} from '@restapp/core-places'
import type {ShortRestaurant, TariffInfo, TariffType} from '@restapp/shared-api'
import {useSubscriptions} from '@restapp/shared-places'
import {DeliveryIcon} from '@restapp/shared-ui'

import {getTariffCost} from '../../../utils'

import {SubscriptionConfirmSaveModal} from './SubscriptionConfirmSaveModal'
import {useSubscriptionConfirmSaveModalTexts} from './useSubscriptionConfirmSaveModalTexts'

export type SubscriptionConfirmSaveModalContainerProps = {
  visible: boolean
  selectedTariffInfo: TariffInfo
  trialTariffInfoType?: TariffType
  initialTariffType?: TariffType
  initialPlacesIds: number[]
  hideMultiselectChoice?: boolean
  isAvailablePlacesChoice?: boolean
  hasDiscount?: boolean
  onConfirm: (selectedPlacesIds: number[]) => void
  onClose: () => void
}

export const SubscriptionConfirmSaveModalContainer: FC<SubscriptionConfirmSaveModalContainerProps> = ({
  visible,
  initialPlacesIds,
  selectedTariffInfo,
  trialTariffInfoType,
  initialTariffType,
  hasDiscount,
  isAvailablePlacesChoice,
  hideMultiselectChoice,
  onConfirm,
  onClose
}) => {
  const {lang} = useI18n()
  const [localSelectedPlacesIds, setLocalSelectedPlacesIds] = useState<number[]>(initialPlacesIds)
  const cost = getTariffCost(selectedTariffInfo, lang, hasDiscount)

  const selectedTariffType = selectedTariffInfo.type
  const modalTexts = useSubscriptionConfirmSaveModalTexts(selectedTariffType, initialTariffType, trialTariffInfoType)

  const {placesIds} = usePlaces()

  const subscriptionQuery = useSubscriptions({placesIds}, {enabled: !!initialPlacesIds.length})

  const allAvailableSubscriptionPlacesIds =
    subscriptionQuery?.data?.subscriptions
      ?.filter((place) => place.tariff_info.type === 'free' && !place.next_tariff)
      .map(({place_id}) => place_id) ?? []

  const isOnePlaceOnAccount = placesIds.length === 1

  const hideMultiselect = hideMultiselectChoice || isOnePlaceOnAccount

  const {getPlaceById} = usePlaces()

  const isInitialPlacesAllAvailable = initialPlacesIds.length === allAvailableSubscriptionPlacesIds.length

  const [isOpenRestaurantsModal, setIsOpenRestaurantsModal] = useState(false)

  const allAvailableSubscriptionPlaces = allAvailableSubscriptionPlacesIds
    .map((placeId) => getPlaceById(placeId))
    .filter((place): place is ShortRestaurant => Boolean(place))

  const isAllAvailableDefault = hideMultiselect ? false : isAvailablePlacesChoice
  const [isAllAvailableState, setIsAllAvailableState] = useState(
    isAllAvailableDefault ?? isInitialPlacesAllAvailable ?? false
  )

  const isLocalSelectedPlacesIdsNotEmpty = localSelectedPlacesIds.length > 0 && !isAllAvailableState

  const localSelectedPlaces = localSelectedPlacesIds
    .map((placeId) => getPlaceById(placeId))
    .filter((place): place is ShortRestaurant => Boolean(place))

  const initialPlaces = isAllAvailableState
    ? allAvailableSubscriptionPlaces
    : allAvailableSubscriptionPlaces.filter((place) => initialPlacesIds.includes(place.id))

  const selectedPlaces = isLocalSelectedPlacesIdsNotEmpty ? localSelectedPlaces : initialPlaces

  const handleChangeSwitch = () => {
    setLocalSelectedPlacesIds([])
    setIsAllAvailableState(!isAllAvailableState)
  }

  const options = useMemo(
    () =>
      allAvailableSubscriptionPlaces.map((place) => {
        return {
          address: place.address,
          annotation: '',
          disabled: false,
          hasAlert: false,
          icon: <DeliveryIcon place={place} />,
          id: place.id,
          services: place.services,
          title: place.name
        }
      }),
    [allAvailableSubscriptionPlaces]
  )

  const handleChooseSubscriptionPlaces = () => {
    metrika({
      target: 'tariff_save_confirm_modal_choose_subscription_places'
    })

    setIsOpenRestaurantsModal(true)
  }

  const handleChangeSubscriptionPlaces = () => {
    metrika({
      target: 'tariff_save_confirm_modal_change_subscription_places'
    })

    setIsOpenRestaurantsModal(true)
  }

  const handleCloseRestaurantsModal = () => {
    metrika({
      target: 'tariff_save_confirm_modal_close_restaurants_modal'
    })

    setIsOpenRestaurantsModal(false)
  }

  const handleSelectPlacesRestaurantModal = (placesIds: number[]) => {
    metrika({
      target: 'tariff_save_confirm_modal_select_places_restaurants_modal',
      params: {
        placesIds
      }
    })

    setLocalSelectedPlacesIds(placesIds)
  }

  const handleClose = () => {
    metrika({
      target: 'tariff_save_confirm_modal_close',
      params: {
        hasDiscount,
        placesCount: initialPlacesIds.length,
        path: location.pathname
      }
    })

    onClose()
  }

  const handleConfirm = () => {
    metrika({
      target: 'tariff_save_confirm_modal_confirm',
      params: {
        hasDiscount,
        placesCount: initialPlacesIds.length,
        path: location.pathname
      }
    })

    const selectedPlacesIds = selectedPlaces.map((place) => place.id)
    onConfirm(selectedPlacesIds)
  }

  useEffect(() => {
    if (visible) {
      metrika({
        target: 'tariff_save_confirm_modal_open',
        params: {
          hasDiscount,
          placesCount: initialPlacesIds.length,
          path: location.pathname,
          multiSelectChoice: !hideMultiselectChoice && !isOnePlaceOnAccount
        }
      })
    }
  }, [visible, hasDiscount, initialPlacesIds, hideMultiselectChoice, isOnePlaceOnAccount])

  useEffect(() => {
    metrika({
      target: 'tariff_save_confirm_modal_places_toggle',
      params: {
        placesToggleOn: isAllAvailableState
      }
    })
  }, [isAllAvailableState])

  return (
    <>
      <SubscriptionConfirmSaveModal
        cost={cost.arrayOfCost.join(' + ')}
        onConfirm={handleConfirm}
        onClose={handleClose}
        description={modalTexts.description}
        title={modalTexts.title}
        selectedPlaces={selectedPlaces}
        allAvailableSubscriptionPlacesAmount={allAvailableSubscriptionPlaces.length}
        visible={visible}
        hideMultiselectChoice={hideMultiselect}
        isAllAvailableState={isAllAvailableState}
        isAllAvailableDefault={!!isInitialPlacesAllAvailable}
        isEmptyLocalSelectedPlacesIds={localSelectedPlacesIds.length === 0}
        onChangeSwitch={handleChangeSwitch}
        onChangeSubscriptionPlaces={handleChangeSubscriptionPlaces}
        onChooseSubscriptionPlaces={handleChooseSubscriptionPlaces}
      />

      <RestaurantSelectModalContainer
        isOpen={isOpenRestaurantsModal}
        options={options || []}
        values={isInitialPlacesAllAvailable ? localSelectedPlacesIds : initialPlacesIds}
        onChange={handleSelectPlacesRestaurantModal}
        onClose={handleCloseRestaurantsModal}
      />
    </>
  )
}
