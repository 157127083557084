const dayPickerStyle = {
  '& .DayPicker': {
    '&-wrapper': {
      paddingBottom: 0
    },
    '&-Month': {
      margin: '12px 0'
    },
    '&-Footer': {
      padding: '0 12px'
    },
    '&-Weekday': {
      display: 'table-cell',
      padding: '12px 16px',
      color: '#8B9898',
      textAlign: 'center',
      fontSize: 16,

      ['@media (max-width: 424px)']: {
        padding: 12
      },

      ['@media (max-width: 370px)']: {
        padding: '12px 8px'
      }
    },
    '&-Caption': {
      marginBottom: 0,
      padding: 10
    },
    '&-TodayButton': {
      width: '100%',
      padding: 12,
      maxWidth: 300,
      display: 'block',
      margin: '0 auto 10px auto',
      border: '1px solid #eeeeee',
      boxSizing: 'border-box',
      borderRadius: 4,
      color: '#000000',
      fontWeight: 'bold'
    },
    '&-NavButton': {
      position: 'absolute',
      top: 10,
      right: 'auto',
      left: 'auto',
      width: 14,
      height: 14,
      filter: 'brightness(0)',
      '&--prev': {
        left: 0,
        top: 0,
        marginRight: 0,
        width: 50,
        height: 50,
        backgroundSize: 11
      },
      '&--next': {
        right: 0,
        top: 0,
        marginRight: 0,
        width: 50,
        height: 50,
        backgroundSize: 11
      }
    },
    '&-Caption > div': {
      letterSpacing: '1.6px',
      fontWeight: 500,
      fontSize: 14,
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    '&-Day': {
      padding: '5px 10px',
      borderRadius: 0,
      '&--outside': {
        opacity: 0
      },
      '&--selected': {
        background: 'rgba(251, 223, 90, 0.16) !important',
        color: `${'#000000'} !important`
      },
      '&--start, &--end': {
        boxShadow: 'inset 0px 0px 0px 2px #FBDF5A'
      },
      '&:hover': {
        background: 'rgba(251, 223, 90, 0.16) !important'
      }
    }
  }
} as const

export default dayPickerStyle
