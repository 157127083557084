import React, {useCallback} from 'react'
import {NumericFormat, type NumericFormatProps, type NumberFormatValues} from 'react-number-format'

import {Input, type InputProps} from './Input'

type TypeSafeNumberFormatProps = Pick<
  NumericFormatProps,
  | 'thousandSeparator'
  | 'decimalSeparator'
  | 'thousandsGroupStyle'
  | 'decimalScale'
  | 'fixedDecimalScale'
  | 'displayType'
  | 'prefix'
  | 'suffix'
  | 'value'
  | 'defaultValue'
  | 'customInput'
  | 'allowNegative'
  | 'allowLeadingZeros'
  | 'onValueChange'
  | 'isAllowed'
  | 'renderText'
  | 'getInputRef'
  | 'allowedDecimalSeparators'
  | 'valueIsNumericString'
>

export type NumberInputProps = TypeSafeNumberFormatProps &
  Omit<InputProps, 'classes' | 'value' | 'defaultValue'> & {
    max?: number
    min?: number
  }

export default function NumberInput({type, min, max, ...restProps}: NumberInputProps) {
  const checkMin = useCallback((value?: number) => value === undefined || min === undefined || value >= min, [min])
  const checkMax = useCallback((value?: number) => value === undefined || max === undefined || value <= max, [max])

  const isAllowed = ({floatValue}: NumberFormatValues): boolean => checkMin(floatValue) && checkMax(floatValue)

  return (
    <NumericFormat type={type as NumericFormatProps['type']} customInput={Input} isAllowed={isAllowed} {...restProps} />
  )
}
