import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {useSelector, useDispatch} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {useApiQuery} from '@restapp/core-api'
import {MultiplePlacesDropdown, usePlaces} from '@restapp/core-places'
import {useRelatedPartnersQuery} from '@restapp/main-chats/messenger/hooks/useRelatedPartnersQuery'
import {Header, HeaderRow, SidebarBurger} from '@restapp/main-layout'
import {useServiceBrand} from '@restapp/shared'
import {Button} from '@restapp/shared-ui/Button'
import {type DatePeriod, DatePeriodSelector, type DatePeriodValue} from '@restapp/shared-ui/DatePeriodSelector'
import RefreshButton from '@restapp/shared-ui/RefreshButton'
import {Option, Select} from '@restapp/shared-ui/Select'

import {chatsSlice, type ChatsFilters} from '../../slice/chatsSlice'
import type {IChat} from '../../types'
import {getChatStatusText} from '../../utils/getChatStatusText'
import NewChatButton from '../NewChatButton/NewChatButton'

const useStyles = makeStyles()({
  restaurantSelect: {
    maxWidth: 350
  },
  newChatBtn: {
    marginLeft: 16,
    marginRight: 16
  },
  refreshBtn: {
    marginRight: 16
  },
  resetFiltersBtn: {
    fontSize: 14,
    lineHeight: '18px',
    color: '#999C9E'
  },
  filtersRow: {
    margin: '0 -4px',
    '& > *': {
      margin: '0 4px'
    }
  },
  yangoTitle: {
    fontFamily: 'Yango Headline'
  }
})

type ChatsHeaderDesktopProps = {
  onCreateChat: (restaurantId: number) => void
  onRefresh: () => Promise<unknown>
}

function ChatsHeaderDesktop({onCreateChat, onRefresh}: ChatsHeaderDesktopProps) {
  const filters = useSelector(chatsSlice.selectors.selectFilters)
  const dispatch = useDispatch()

  const {t} = useI18n()
  const {chatsTopicFilterEnabled} = useExp3('restapp_support')
  const {classes: c, cx} = useStyles()
  const serviceBrand = useServiceBrand()

  const {selectedPlaceIds, setSelectedPlaceIds, places} = usePlaces()
  const authorsQuery = useRelatedPartnersQuery(selectedPlaceIds)
  const topicsQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/topics',
    method: 'GET',
    initialData: {topics: []}
  })

  const isAllPlacesSelected = selectedPlaceIds.length === places.length
  const isDirty = useSelector(chatsSlice.selectors.selectFiltersDirty) || !isAllPlacesSelected

  const resetHandler = () => {
    dispatch(chatsSlice.actions.resetFilters())
    setSelectedPlaceIds([])
    metrika({target: 'chat_reset_filter_click'})
  }

  function handlePeriodChange({
    period,
    from,
    to
  }: {
    period: Exclude<DatePeriod, 'all' | 'days' | 'day'>
    from: Required<DatePeriodValue>['from']
    to: Required<DatePeriodValue>['to']
  }) {
    dispatch(chatsSlice.actions.setPeriodFilter(period))
    dispatch(chatsSlice.actions.setDateFromFilter(from))
    dispatch(chatsSlice.actions.setDateToFilter(to))
  }

  const statuses: IChat['status'][] = ['open', 'pending', 'solved']

  return (
    <Header>
      <HeaderRow stickyPosition={0}>
        <SidebarBurger />
        <h1 className={cx({[c.yangoTitle]: serviceBrand === 'YangoEats'})}>
          {t('main-chats.chats-header-desktop.podderzhka', 'Поддержка')}
        </h1>
        <NewChatButton
          className={c.newChatBtn}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onCreateChat={onCreateChat}
        />
        <RefreshButton classes={{root: c.refreshBtn}} onUpdate={onRefresh} />
        <MultiplePlacesDropdown className={c.restaurantSelect} />
      </HeaderRow>
      <HeaderRow classes={{root: c.filtersRow}}>
        <Select
          data-testid='status-filter'
          value={filters.status}
          onChangeValue={(status: ChatsFilters['status']) => dispatch(chatsSlice.actions.setStatusFilter(status))}
        >
          <Option value='all'>{t('main-chats.chats-header-desktop.vse-statusi', 'Все статусы')}</Option>
          {statuses.map((status) => (
            <Option key={status} value={status}>
              {getChatStatusText(status)}
            </Option>
          ))}
        </Select>
        {chatsTopicFilterEnabled && (
          <Select
            data-testid='theme-filter'
            value={filters.topic}
            onChangeValue={(topic: ChatsFilters['topic']) => dispatch(chatsSlice.actions.setTopicFilter(topic))}
          >
            <Option value='all'>{t('main-chats.chats-header-desktop.vse-temi', 'Все темы')}</Option>
            {topicsQuery.data?.topics.map((topic) => (
              <Option key={topic} value={topic}>
                {topic}
              </Option>
            ))}
          </Select>
        )}
        <Select
          data-testid='author-filter'
          value={filters.author}
          onChangeValue={(author: ChatsFilters['author']) => dispatch(chatsSlice.actions.setAuthorFilter(author))}
        >
          <Option value='all'>{t('main-chats.chats-header-desktop.vse-avtori', 'Все авторы')}</Option>
          {authorsQuery.data?.partners.map((author) => (
            <Option
              key={'partner_id' in author ? author.partner_id : author.partner_uuid}
              value={'partner_id' in author ? author.partner_id : author.partner_uuid}
            >
              {author.email}
            </Option>
          ))}
        </Select>
        <DatePeriodSelector
          value={{period: filters.period, from: filters.dateFrom, to: filters.dateTo || undefined}}
          onChange={handlePeriodChange}
        />
        {isDirty && (
          <Button className={c.resetFiltersBtn} onClick={resetHandler} data-testid='reset-filters-button'>
            {t('main-chats.chats-header-desktop.sbrosit-filtri', 'Сбросить фильтры')}
          </Button>
        )}
      </HeaderRow>
    </Header>
  )
}

export default ChatsHeaderDesktop
