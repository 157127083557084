import {defaults} from 'lodash-es'

import useApiQuery from '@restapp/core-api/useApiQuery'

import useAuth from './useAuth'

type Options = {
  enabled?: boolean
  suspense?: boolean
  refetchOnWindowFocus?: boolean
}

export default function useUserInfo(opt?: Options) {
  const options = defaults(opt, {enabled: true})

  const {isLoggedIn} = useAuth()

  const {data} = useApiQuery({
    url: '/4.0/restapp-front/partners/v2/info',
    method: 'GET',
    initialData: window.initialUserInfo,
    staleTime: 60_000 * 10,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
    enabled: isLoggedIn && options.enabled
  })

  if (!data || !isLoggedIn) {
    return null
  }

  return data.partner
}
