import {useExp3} from '@eda-restapp/configs'
import {usePermission} from '@eda-restapp/permissions'
import type {FC} from 'react'

import {StoplistAvailabilityNotificationModal} from './components/StoplistAvailabilityNotificationModal'
import {StoplistAvailabilityNotificationPush} from './components/StoplistAvailabilityNotificationPush'

export const StoplistAvailabilityNotificationContainer: FC = () => {
  const stoplistNotifications = useExp3('restapp_stoplist_notifications')
  const canChangeMenu = usePermission('permission.restaurant.menu')

  if (!canChangeMenu) {
    return null
  }

  return (
    <>
      {stoplistNotifications.availabilityNotificationPush && (
        <StoplistAvailabilityNotificationPush
          snackbarSchedule={stoplistNotifications.availabilityNotificationPushSchedule}
        />
      )}
      {stoplistNotifications.availabilityNotificationModal && (
        <StoplistAvailabilityNotificationModal
          modalSchedule={stoplistNotifications.availabilityNotificationModalSchedule}
        />
      )}
    </>
  )
}
