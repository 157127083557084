import {Card, CardMedia, CardActions, CardContent} from '@mui/material'
import cn from 'classnames'
import React, {type FC} from 'react'

import {Badge} from '../Badge'

import useStyles from './BasicCard.style'

export type Props = {
  className?: string
  badgeText?: string
  dcBadge?: boolean
  image: string
  title: string
  body: string | JSX.Element
  actions: JSX.Element
  footer?: JSX.Element
}

export const BasicCard: FC<Props> = ({badgeText, dcBadge = false, image, title, body, actions, footer, className}) => {
  const {classes: c} = useStyles()

  return (
    <Card className={cn(c.cardRoot, className)}>
      <div className={c.mediaContainer}>
        <CardMedia className={c.cardMedia} image={image} />
        {!!badgeText && (
          <Badge testid={'bage-on-card' /*Бейджик на карточке */} className={cn(c.badge, dcBadge && c.dcBadge)}>
            {badgeText}
          </Badge>
        )}
      </div>
      <div className={c.contentContainer}>
        <CardContent className={c.cardContent}>
          <div className={c.titleContainer}>
            <h5 data-testid={'card-title' /*Заголовок карточки */} className={c.title}>
              {title}
            </h5>
          </div>
          <div className={c.bodyContainer}>
            <div className={c.body}>{body}</div>
          </div>
        </CardContent>
        <CardActions className={c.cardActions}>{actions}</CardActions>
        <div className={cn(c.cardFooter, !footer && c.empty)}>{footer}</div>
      </div>
    </Card>
  )
}
