import type {AnchorHTMLAttributes, MouseEvent} from 'react'
import React, {forwardRef} from 'react'
import {OutsideLink as OutsideLinkBase} from '@eda-restapp/ui'
import {eventLogger} from '@eda-restapp/logger'

type OutsideLinkProps = {
  target?: '_blank'
  slug?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

const OutsideLink = forwardRef<HTMLAnchorElement, OutsideLinkProps>(function OutsideLinkWithRef(
  {children, href, target, onClick, slug, ...anchorProps},
  ref
) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(e)
  }

  const onClickWithTracker = (e: MouseEvent<HTMLAnchorElement>) => {
    eventLogger({
      name: 'link_click',
      value: slug,
      additional: {href, text: typeof children === 'string' ? children : undefined}
    })
    handleClick(e)
  }

  return (
    <OutsideLinkBase
      data-testid={'outside-link' /*Текст с внешней ссылкой */}
      ref={ref}
      href={href}
      target={target}
      onClick={onClickWithTracker}
      {...anchorProps}
    >
      {children}
    </OutsideLinkBase>
  )
})

export default OutsideLink
