import type {FC} from 'react'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import {OutsideLink} from '@restapp/shared-ui'

import useHelpLink from '../../hooks/useHelpLink'

type HelpLinkProps = {
  linkKey: Parameters<typeof useHelpLink>['0']
  className?: string
  slug?: string
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const HelpLink: FC<HelpLinkProps> = ({children, linkKey, className, onClick, slug}) => {
  const {link} = useHelpLink(linkKey)
  const navigate = useNavigate()
  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (link.startsWith('/')) {
      e.preventDefault()
      navigate(link)
    }

    onClick?.(e)
  }

  return (
    <OutsideLink
      slug={slug}
      data-testid={'help-link' /*Меню | Ссылка на советы*/}
      className={className}
      onClick={clickHandler}
      target='_blank'
      rel='noreferrer'
      href={link}
    >
      {children}
    </OutsideLink>
  )
}

export default HelpLink
