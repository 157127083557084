import {useRef, useEffect} from 'react'

/**
 * @deprecated - Используй useInfiniteLoader из eda-restapp/hooks
 */
export default function useInfiniteLoader<T extends HTMLElement>({
  loadMore,
  canLoadMore = false,
  rootMargin = '100px 0px 0px 0px',
  threshold = 0
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadMore: () => any
  canLoadMore?: boolean
  rootMargin?: string
  threshold?: number
}) {
  const loaderRef = useRef<T>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(
        ([target]) => {
          if (!canLoadMore || target.intersectionRatio <= 0) {
            return
          }

          loadMore()
        },
        {rootMargin, threshold}
      )

      if (loaderRef.current) {
        observer.current.observe(loaderRef.current)
      }
    }

    return () => {
      if (observer && observer.current) {
        observer.current.disconnect()
        observer.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadMore, loadMore])

  return {loaderRef}
}
