import {useEffect} from 'react'
import type {OperatorFunction} from 'rxjs'
import {filter, map} from 'rxjs/operators'

import type {SocketEvents} from '@restapp/core-legacy/types/socket'
import type {SocketEvent} from '@restapp/core-notifications'
import type {EventContainer} from '@restapp/core-notifications/types'
import type {MeaningfulNotification} from '@restapp/core-notifications/XivaWebsocketObservable/types'

import {useApiDeps} from './useApiDeps'

interface UseSocketEventsParams {
  eventFilter?: keyof SocketEvents | Array<keyof SocketEvents> | ((socketEvent: SocketEvent) => boolean)
  eventHandler: (data: SocketEvent) => unknown
}

/**
 * @deprecated Нужно использовать useNotification https://st.yandex-team.ru/EDARESTAPP-7172
 */
export function useSocketEvents({eventFilter, eventHandler}: UseSocketEventsParams) {
  const {socketEvent$} = useApiDeps()

  useEffect(() => {
    if (!eventFilter) {
      return
    }

    const sub = socketEvent$
      .pipe(
        mapMessagesToEvents(),
        filter((eventData): eventData is SocketEvent => filterEvent(eventFilter, eventData))
      )
      .subscribe((data) => eventHandler(data))
    return () => sub.unsubscribe()
  }, [eventFilter, eventHandler, socketEvent$])
}

function filterEvent(
  eventFilter: keyof SocketEvents | Array<keyof SocketEvents> | ((socketEvent: SocketEvent) => boolean),
  socketEvent: SocketEvent
) {
  if (Array.isArray(eventFilter)) {
    return eventFilter.includes(socketEvent.event)
  }
  if (typeof eventFilter === 'function') {
    return eventFilter(socketEvent)
  }
  return eventFilter === socketEvent.event
}

function mapMessagesToEvents(): OperatorFunction<MeaningfulNotification, SocketEvent> {
  return (message$) =>
    message$.pipe(
      map(({message}) => {
        try {
          const data = JSON.parse(message) as EventContainer
          const result = data.eventData

          return result
        } catch {
          return null
        }
      }),
      filter((eventData): eventData is SocketEvent => !!eventData)
    )
}
