import {makeStyles} from 'tss-react/mui'

export const DelimiterStyle = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 24,
    padding: '0 16px',
    backgroundColor: '#F2F4F5',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#999C9E',
    textTransform: 'uppercase',
    letterSpacing: '0.08em'
  }
} as const

const useStyles = makeStyles()(DelimiterStyle)

export default useStyles
