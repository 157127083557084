import {Box, Clamp, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import {MultiChatStatusBadge} from '@restapp/main-chats/messenger/components/MultiChatStatusBadge/MultiChatStatusBadge'
import {useAppSelector} from '@restapp/store'

import {chatsSlice} from '../../slice/chatsSlice'
import type {IChat} from '../../types'
import {UnreadBadge} from '../UnreadBadge'

import styles from './ChatListItem.module.css'

export type ChatListItemProps = {
  className?: string
  chat: IChat
  onClick(): void
}

export function ChatListItem({className, chat, onClick}: ChatListItemProps) {
  const multiChatUnreadCount = useAppSelector((state) =>
    chatsSlice.selectors.selectMultiChatUnreadCountById(state, chat.chat_id || '')
  )

  const unreadCount = chat.new_messages_counter || multiChatUnreadCount

  return (
    <div className={cn(className, styles.root)} onClick={onClick}>
      <Box alignItems='center' gap='s'>
        <Box alignItems='center' gap='xs'>
          <Typography variant='body2' thickness='bold'>
            <Clamp className={styles.clamp} title={chat.topic}>
              {chat.topic}
            </Clamp>
          </Typography>

          {!!unreadCount && <UnreadBadge count={unreadCount} />}
        </Box>

        <MultiChatStatusBadge status={chat.status} />

        {!!chat.created_at && (
          <Typography variant='caption1' color='minor' className={styles.date}>
            {moment(chat.created_at).format('DD.MM')}
          </Typography>
        )}
      </Box>

      <Typography variant='caption1' my='xs'>
        <div>{chat.author_email}</div>
      </Typography>
    </div>
  )
}
