import {useEffect, useRef} from 'react'

import type {Disposable} from '../types'

export default function useInstance<T>(factory: () => T): T {
  const ref = useRef<null | T>(null)
  useEffect(
    () => () => {
      const instance = ref.current

      if (isDisposable(instance)) {
        instance.dispose()
      }
    },
    []
  )

  if (!ref.current) {
    ref.current = factory()
  }

  return ref.current
}

function isDisposable(object: unknown): object is Disposable {
  return typeof (object as Disposable).dispose === 'function'
}
