// Based on restyped-axios typings

// Modules
import axios from 'axios'
import type {
  AxiosInstance,
  Method as AxiosMethod,
  AxiosRequestConfig,
  AxiosResponse,
  CancelStatic,
  CancelTokenStatic
} from 'axios'
import type {RestypedBase, RestypedIndexedBase, RestypedRoute} from 'restyped/spec'

import type {ApiResponse} from '@restapp/core-api/types'

export interface TypedAxiosRequestConfig<
  API extends RestypedIndexedBase,
  Path extends Extract<keyof API, string>,
  Method extends keyof API[Path],
  RouteDef extends RestypedRoute = API[Path][Method]
> extends AxiosRequestConfig {
  url?: Path
  method?: RequestMethod<Method>
  params?: RouteDef['query']
  data?: RouteDef['body']
}

export type RequestMethod<Method> = Extract<Method, AxiosMethod>

export interface TypedAxiosResponse<
  API extends RestypedIndexedBase,
  Path extends Extract<keyof API, string>,
  Method extends keyof API[Path],
  RouteDef extends RestypedRoute = API[Path][Method]
> extends AxiosResponse<ApiResponse<API[Path][Method]>> {
  data: RouteDef['response']
}

interface TypedAxiosInstanceOverride<API extends RestypedBase = any> {
  request<Path extends Extract<keyof API, string>, Method extends keyof API[Path] = 'GET'>(
    config: TypedAxiosRequestConfig<API, Path, Method>
  ): Promise<TypedAxiosResponse<API, Path, Method>>

  get<Path extends Extract<keyof API, string>>(
    url: Path | string,
    config?: TypedAxiosRequestConfig<API, Path, 'GET'>
  ): Promise<TypedAxiosResponse<API, Path, 'GET'>>

  delete<Path extends Extract<keyof API, string>>(
    url: Path | string,
    config?: TypedAxiosRequestConfig<API, Path, 'DELETE'>
  ): Promise<TypedAxiosResponse<API, Path, 'DELETE'>>

  head<Path extends Extract<keyof API, string>>(
    url: Path | string,
    config?: TypedAxiosRequestConfig<API, Path, 'HEAD'>
  ): Promise<TypedAxiosResponse<API, Path, 'HEAD'>>

  post<Path extends Extract<keyof API, string>>(
    url: Path | string,
    data?: API[Path]['POST']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'POST'>
  ): Promise<TypedAxiosResponse<API, Path, 'POST'>>

  put<Path extends Extract<keyof API, string>>(
    url: Path | string,
    data?: API[Path]['PUT']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'PUT'>
  ): Promise<TypedAxiosResponse<API, Path, 'PUT'>>

  patch<Path extends Extract<keyof API, string>>(
    url: Path | string,
    data?: API[Path]['PATCH']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'PATCH'>
  ): Promise<TypedAxiosResponse<API, Path, 'PATCH'>>
}

export type TypedAxiosInstance<API extends RestypedBase = any> = TypedAxiosInstanceOverride<API> &
  Omit<AxiosInstance, keyof TypedAxiosInstanceOverride>

interface TypedAxiosStrictInstanceOverride<API extends RestypedBase = any> {
  request<Path extends Extract<keyof API, string>, Method extends keyof API[Path] = 'GET'>(
    config: TypedAxiosRequestConfig<API, Path, Method>
  ): Promise<TypedAxiosResponse<API, Path, Method>['data']>

  get<Path extends Extract<keyof API, string>>(
    url: Path,
    config?: TypedAxiosRequestConfig<API, Path, 'GET'>
  ): Promise<TypedAxiosResponse<API, Path, 'GET'>['data']>

  delete<Path extends Extract<keyof API, string>>(
    url: Path,
    config?: TypedAxiosRequestConfig<API, Path, 'DELETE'>
  ): Promise<TypedAxiosResponse<API, Path, 'DELETE'>['data']>

  head<Path extends Extract<keyof API, string>>(
    url: Path,
    config?: TypedAxiosRequestConfig<API, Path, 'HEAD'>
  ): Promise<TypedAxiosResponse<API, Path, 'HEAD'>['data']>

  post<Path extends Extract<keyof API, string>>(
    url: Path,
    data?: API[Path]['POST']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'POST'>
  ): Promise<TypedAxiosResponse<API, Path, 'POST'>['data']>

  put<Path extends Extract<keyof API, string>>(
    url: Path,
    data?: API[Path]['PUT']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'PUT'>
  ): Promise<TypedAxiosResponse<API, Path, 'PUT'>['data']>

  patch<Path extends Extract<keyof API, string>>(
    url: Path,
    data?: API[Path]['PATCH']['body'],
    config?: TypedAxiosRequestConfig<API, Path, 'PATCH'>
  ): Promise<TypedAxiosResponse<API, Path, 'PATCH'>['data']>
}

export type TypedAxiosStrictInstance<API extends RestypedBase = any> = TypedAxiosStrictInstanceOverride<API> &
  Omit<AxiosInstance, keyof TypedAxiosStrictInstanceOverride>

export interface TypedAxiosStatic<API extends RestypedBase = any> extends TypedAxiosInstance<API> {
  <Path extends Extract<keyof API, string>, Method extends keyof API[Path] = 'GET'>(
    config: TypedAxiosRequestConfig<API, Path, Method>
  ): Promise<TypedAxiosResponse<API, Path, Method>>

  <Path extends Extract<keyof API, string>, Method extends keyof API[Path]>(
    url: Path | string,
    config?: TypedAxiosRequestConfig<API, Path, Method>
  ): Promise<TypedAxiosResponse<API, Path, Method>>

  Cancel: CancelStatic
  CancelToken: CancelTokenStatic

  create<T extends API>(config?: AxiosRequestConfig): TypedAxiosInstance<T>

  isCancel(value: any): boolean
  all<T>(values: Array<T | Promise<T>>): Promise<T[]>
  spread<T, R>(callback: (...args: T[]) => R): (array: T[]) => R
}

const TypedAxios: TypedAxiosStatic = axios as any

export default TypedAxios
