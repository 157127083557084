import {useEffect} from 'react'
import {selectPartnerId, useUserInfo} from '@restapp/core-auth'
import {sessionStore, setSensitiveStrings} from '@eda-restapp/logger'
import {useSelector} from 'react-redux'

export const SetupUserTracking: React.FC = () => {
  const userInfo = useUserInfo()
  const partnerUuid = useSelector(selectPartnerId)

  useEffect(() => {
    if (userInfo) {
      setSensitiveStrings(userInfo.email)
      sessionStore.setData({partnerUuid: partnerUuid || undefined, countryCode: userInfo.country_code})
    }
  }, [userInfo, partnerUuid])

  return null
}
