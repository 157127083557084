import {makeStyles} from 'tss-react/mui'

const PlusInfoStyles = {
  root: {
    lineHeight: '28px'
  },
  row: {
    display: 'flex',
    '& + &': {
      marginTop: 8
    }
  },
  label: {
    marginRight: 8,
    whiteSpace: 'nowrap',
    color: '#999C9E'
  },
  value: {
    color: '#1F2021'
  }
}

export const usePlusInfoStyles = makeStyles({name: 'PlusInfo'})(PlusInfoStyles)
