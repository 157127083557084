import cn from 'classnames'
import React, {type FC} from 'react'

import styles from './Loader.module.css'

type LoaderProps = {
  active: boolean
}

// Loader parent needs to have position:relative style for proper placement
export const Loader: FC<LoaderProps> = ({active}) => (
  <div className={cn(styles.wrapper, {'g-hide': !active})}>
    <div className={styles.spinner} />
  </div>
)
