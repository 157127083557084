import {combineReducers} from '@reduxjs/toolkit'

import {placesHolidayScheduleSlice} from './holidayScheduleSlice'
import {placesRegularScheduleSlice} from './regularScheduleSlice'

export const placesScheduleReducer = combineReducers({
  regular: placesRegularScheduleSlice.reducer,
  holiday: placesHolidayScheduleSlice.reducer
})

export const placesScheduleActions = {
  regular: placesRegularScheduleSlice.actions,
  holiday: placesHolidayScheduleSlice.actions
}
