import {errorLogger} from '@eda-restapp/logger'
import {lazy, type ReactElement} from 'react'

export const BrowserVersionAlertLazy = lazy<() => ReactElement | null>(async () => {
  try {
    const {BrowserVersionAlert} = await import('./BrowserVersionAlert')
    return {default: BrowserVersionAlert}
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    errorLogger({
      level: 'warn',
      message: 'browser-version-alert_module-init-error',
      error: error instanceof Error ? error : new Error('browser-version-alert_module-init-error')
    })
    return {default: () => null}
  }
})
