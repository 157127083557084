import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import React, {type FC, useState, useEffect} from 'react'

import type {TariffType} from '@restapp/shared-api'
import {useSubscriptionRoute} from '@restapp/shared-places'

import {useSubscriptionEditTariff} from '../../../hooks'
import type {SubscriptionOnSave} from '../../../types'
import {SubscriptionConfirmFreeTariffModal} from '../SubscriptionConfirmFreeTariffModal'
import {SubscriptionConfirmSaveModalContainer} from '../SubscriptionConfirmSaveModal'

import {SubscriptionEditModal} from './SubscriptionEditModal'

export interface SubscriptionEditModalContainerProps {
  placesIds: number[]
  isOpen: boolean
  onClose: () => void
  onSubscribed: (value: SubscriptionOnSave) => void
  isFeatureSubscription?: boolean
  isAvailablePlacesChoice?: boolean
}

export const SubscriptionEditModalContainer: FC<SubscriptionEditModalContainerProps> = ({
  placesIds,
  isOpen,
  onClose,
  onSubscribed,
  isFeatureSubscription,
  isAvailablePlacesChoice
}) => {
  const isManyPlacesEditing = placesIds.length > 1
  const {isHideBusinessPlus} = useExp3('restapp_tools')

  const {
    subscriptionsIds,
    initialTariff,
    tariffs,
    hasDiscount,
    trialTariffInfo,
    isLoading,
    daysBeforeFinishDiscount,
    disabledEditingSubscription,
    defaultTariffCount,
    hasPaidNextTariff,
    getTariffByType,
    updateSubscription,
    reachSubmit
  } = useSubscriptionEditTariff({placesIds})

  const [isOpenFreeTariffConfirmation, setIsOpenFreeTariffConfirmation] = useState(false)
  const [selectedTariffType, setSelectedTariffType] = useState<TariffType | undefined>(undefined)

  const selectedTariffInfo = getTariffByType(selectedTariffType)

  const handleSubmit = (placesIds: number[], tariff?: TariffType) => {
    if (tariff) {
      const isSameTariff = tariff === initialTariff
      const nextTariff = getTariffByType(tariff)

      return updateSubscription(
        {
          places:
            placesIds.map((placeId) => {
              return {place_id: placeId, tariff}
            }) ?? []
        },
        () => {
          reachSubmit({
            tariff,
            placeIds: placesIds,
            isSameTariffSubmitted: isSameTariff,
            fromTariff: initialTariff
          })
          onSubscribed({
            toTariff: tariff,
            fromTariff: initialTariff,
            fromTrialTariff: trialTariffInfo,
            tariffCost: nextTariff
              ? {
                  additionalCommission:
                    hasDiscount && nextTariff.discount
                      ? nextTariff.discount.additional_commission
                      : nextTariff.additional_commission,
                  fixCostRules:
                    hasDiscount && nextTariff.discount ? nextTariff.discount.fix_cost_rules : nextTariff.fix_cost_rules,
                  sign: nextTariff.currency?.sign
                }
              : undefined
          })
        }
      )
    }
  }

  const handleClose = () => {
    onClose()
    metrika({
      target: 'subscription_edit_modal_close',
      params: {
        hasDiscount,
        hasPaidNextTariff,
        path: location.pathname
      }
    })
  }

  const {goToSubcription} = useSubscriptionRoute()

  const handleNavigateSubscriptionPage = () => {
    goToSubcription()

    metrika({
      target: 'subscription_edit_modal_go_to_subscription_page',
      params: {
        hasDiscount,
        hasPaidNextTariff,
        path: location.pathname
      }
    })
  }

  const showFreeTariffConfirmation = () => setIsOpenFreeTariffConfirmation(true)
  const openTariffSaveConfirmation = (tariff?: TariffType) => setSelectedTariffType(tariff)
  const closeFreeTariffConfirmation = () => setIsOpenFreeTariffConfirmation(false)
  const closeTariffSaveConfirmation = () => setSelectedTariffType(undefined)

  useEffect(() => {
    if (isOpen) {
      metrika({
        target: 'subscription_edit_modal_open',
        params: {
          hasDiscount,
          hasPaidNextTariff,
          path: location.pathname
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      <SubscriptionEditModal
        open={isOpen}
        daysBeforeFinishDiscount={daysBeforeFinishDiscount}
        defaultTariffCount={defaultTariffCount}
        disabledEditingSubscription={disabledEditingSubscription}
        hasDiscount={hasDiscount}
        hasPaidNextTariff={!!hasPaidNextTariff}
        initialTariffType={initialTariff}
        isHideBusinessPlus={isHideBusinessPlus}
        isLoading={isLoading}
        isManyPlacesEditing={isManyPlacesEditing}
        onChangeSubscriptionTariff={openTariffSaveConfirmation}
        onClose={handleClose}
        onDisableSubscription={showFreeTariffConfirmation}
        tariffs={tariffs}
        trialTariffType={trialTariffInfo?.type}
        isFeatureSubscription={isFeatureSubscription}
        onNavigateSubscriptionPage={handleNavigateSubscriptionPage}
      />

      <SubscriptionConfirmFreeTariffModal
        isBusiness={initialTariff === 'business_plus'}
        placeId={subscriptionsIds.length === 1 ? subscriptionsIds[0] : undefined}
        visible={isOpenFreeTariffConfirmation}
        onConfirm={() => {
          handleSubmit(subscriptionsIds, 'free')
          closeFreeTariffConfirmation()
        }}
        onClose={closeFreeTariffConfirmation}
      />

      {selectedTariffInfo && (
        <SubscriptionConfirmSaveModalContainer
          visible={!!selectedTariffType}
          hideMultiselectChoice={initialTariff !== 'free' || hasPaidNextTariff}
          initialPlacesIds={subscriptionsIds}
          initialTariffType={initialTariff}
          trialTariffInfoType={trialTariffInfo?.type}
          selectedTariffInfo={selectedTariffInfo}
          hasDiscount={hasDiscount && !!selectedTariffInfo.discount}
          isAvailablePlacesChoice={isAvailablePlacesChoice}
          onConfirm={(selectedPlacesIds) => {
            handleSubmit(selectedPlacesIds, selectedTariffType)
            closeTariffSaveConfirmation()
          }}
          onClose={closeTariffSaveConfirmation}
        />
      )}
    </>
  )
}
