import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles({
  name: 'PlacesListPlus'
})({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  select: {
    overflow: 'auto',
    background: '#F4F6F7',
    maxHeight: 400,
    padding: 16,
    borderRadius: 16
  },
  openSelect: {
    borderRadius: '16px 16px 0 0'
  },
  selectDivider: {
    borderBottom: '1px solid #DCDEE0',
    margin: '0 8px'
  },
  errorText: {
    color: '#F5523A'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '20px',
    marginRight: 'auto',
    cursor: 'pointer'
  },
  counter: {
    lineHeight: '14px',
    fontSize: 13
  },
  listWrapper: {
    position: 'relative',
    marginTop: 8
  },
  selectListWrapper: {
    marginTop: 0
  },
  listWrapperOverflowed: {
    '&:after': {
      bottom: 0,
      left: 16,
      content: '""',
      height: 1,
      backgroundColor: 'rgb(0 0 0 / 12%)',
      boxShadow: '0px -4px 20px 13px rgb(0 0 0 / 12%)',
      position: 'absolute',
      width: 'calc(100% - 32px)'
    }
  },
  list: {
    background: '#F4F6F7',
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 8,
    maxHeight: 400,
    overflow: 'auto',

    '& > *': {
      padding: '11px 8px'
    },

    '& > * + *': {
      borderTop: '1px solid #DCDEE0'
    }
  },
  selectList: {
    borderRadius: '0 0 16px 16px'
  }
})

export default useStyles
