import {makeStyles} from 'tss-react/mui'

import {CommunicationType} from '../../types'

import CloseIcon from './close.svg'

export const useWidgetStyles = makeStyles({name: 'CriticalMessageWidget'})({
  root: {
    position: 'relative',
    padding: 16,
    borderRadius: 16,
    marginTop: 10, // Close icon reserved space
    marginRight: 5 // Fast fix for close icon
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: 20,
    whiteSpace: 'normal'
  },
  button: {
    '&&': {
      fontWeight: 500,
      color: 'inherit'
    }
  },
  closeIconWrapper: {
    position: 'absolute',
    top: -10,
    right: -7,
    width: 22,
    height: 22,
    cursor: 'pointer',
    backgroundImage: `url(${CloseIcon})`
  }
})

export const useWidgetVariantsStyles = makeStyles({name: 'CriticalMessageWidgetVariants'})({
  [CommunicationType.NOTIFICATION]: {
    background: 'linear-gradient(121.52deg, #FA6A3C 0%, #FFC690 98.78%)',
    color: '#fff',
    '& > button': {
      background: '#FA6A3C',
      '&:hover': {
        background: '#FC5230'
      }
    }
  },
  [CommunicationType.NEWS]: {
    background: 'linear-gradient(121.52deg, #5C5CDA 0%, #8498FA 98.78%)',
    color: '#fff',
    '& > button': {
      background: '#324CF0',
      '&:hover': {
        background: '#2041B0',
        color: '#fff'
      }
    }
  },
  [CommunicationType.SURVEY]: {
    background: 'linear-gradient(121.52deg, #FCE45E 0%, #FFF3C5 98.78%)',
    color: '#1F2021',
    '& > button': {
      background: '#FCDA30',
      '&:hover': {
        background: '#FFCA38'
      }
    }
  }
})
