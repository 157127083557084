import {createContext} from 'react'

import type {HeaderStickyRowContextType} from '../types'

export const HeaderStickyRowContext = createContext<HeaderStickyRowContextType>({
  registerStickyRowHeight: () => {
    /* tslint:disable:no-empty */
  },
  changeStickyRowHeightRegistration: () => {
    /* tslint:disable:no-empty */
  },
  unregisterStickyRowHeight: () => {
    /* tslint:disable:no-empty */
  },
  getStickyOffset: () => 0
})
