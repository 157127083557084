import type {OldFullRestaurant, VendorApi} from '@restapp/shared-api'
import {createMockFactory} from '@restapp/shared-test-utils'

export const mockFullRestaurant = createMockFactory<OldFullRestaurant>({
  id: 46874,
  name: 'Что-то с чем-то',
  is_available: true,
  is_switching_on_requested: false,
  address: 'Какая-то улица, 9к1, Москва, Российская Федерация',
  type: 'native',
  services: {enabled: ['eda'], available: []},
  integration_type: 'native',
  is_plus_enabled: true,
  disable_details: {
    disable_at: '2022-01-25T13:09:27+00:00',
    available_at: '2022-01-25T13:23:25+00:00',
    status: 0,
    reason: 91
  },
  currency: {
    code: 'RUB',
    sign: '₽',
    decimal_places: 2
  },
  country_code: 'RU',
  region_slug: 'moscow',
  show_shipping_time: true,
  slug: 'chto-to-s-chem-to',
  brand: {
    slug: 'chto-to-s-chem-to',
    business_type: 'restaurant'
  },
  can_be_enabled: true,
  subscription: {
    is_trial: false,
    need_alerting_about_finishing_trial: false,
    tariff_type: 'free',
    valid_until_iso: '10'
  }
})

export const FULL_PLACES_MOCK: OldFullRestaurant[] = [
  {
    id: 46874,
    name: 'Что-то с чем-то',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Какая-то улица, 9к1, Москва, Российская Федерация',
    type: 'native',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    disable_details: {
      disable_at: '2022-01-25T13:09:27+00:00',
      available_at: '2022-01-25T13:23:25+00:00',
      status: 0,
      reason: 91
    },
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: 'chto-to-s-chem-to',
    brand: {
      slug: 'chto-to-s-chem-to',
      business_type: 'restaurant'
    },
    can_be_enabled: true,
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 129840,
    name: 'Мощный пицца',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Пиццевая улица, 4В, Пермь, Российская Федерация',
    type: 'marketplace',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: 'pizza',
    brand: {
      slug: 'pizza',
      business_type: 'restaurant'
    },
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 356767,
    name: 'Place Native  gtbdrm122038',
    is_available: true,
    is_switching_on_requested: false,
    address: 'улица Алроса, 2Д, Орёл, Российская Федерация',
    type: 'native',
    services: {enabled: ['eda'], available: []},
    integration_type: 'custom',
    is_plus_enabled: true,
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: false,
    slug: 'curcum_kafe_4j_syromyatnicheskij_pereulok_1_8s6',
    brand: {
      slug: 'curcum_kafe',
      business_type: 'restaurant'
    },
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 48116,
    name: 'The Mad Cock',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Пресненская набережная, 12, Москва, Российская Федерация',
    type: 'native',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: '228',
    brand: {
      slug: 'masala_house',
      business_type: 'restaurant'
    },
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 10524,
    name: 'Пииндус',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Чистопрудный бульвар, 12к4, Москва, Российская Федерация',
    type: 'native',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    disable_details: {
      disable_at: '2022-02-15T09:38:29+00:00',
      status: 0,
      reason: 0,
      available_at: '2022-02-15T09:38:29+00:00'
    },
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: 'place_native__ankneo105612',
    brand: {
      slug: '',
      business_type: 'restaurant'
    },
    can_be_enabled: false,
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 11172,
    name: 'Пицца Плюс',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 1, Рязань, Российская Федерация',
    type: 'marketplace',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: 'place_native__ankneo105612',
    brand: {
      slug: '',
      business_type: 'restaurant'
    },
    can_be_enabled: false,
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  },
  {
    id: 123,
    name: 'Пицца Плюс (тест)',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 1, Рязань, Российская Федерация',
    type: 'marketplace',
    services: {enabled: ['eda'], available: []},
    integration_type: 'native',
    is_plus_enabled: true,
    currency: {
      code: 'RUB',
      sign: '₽',
      decimal_places: 2
    },
    country_code: 'RU',
    region_slug: 'moscow',
    show_shipping_time: true,
    slug: 'place_native__ankneo105612',
    brand: {
      slug: '',
      business_type: 'restaurant'
    },
    can_be_enabled: false,
    subscription: {
      is_trial: false,
      need_alerting_about_finishing_trial: false,
      tariff_type: 'free',
      valid_until_iso: '10'
    }
  }
]

export const PLACE_SEARCH_RESPONSE_MOCK: VendorApi['/4.0/restapp-front/places/v1/search']['GET']['response'] = {
  meta: {
    can_fetch_next: false
  },
  payload: FULL_PLACES_MOCK
}
