import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {INewsItem, INotification} from '@restapp/shared-api'

import type {PoolState} from '../../types'
import {CommunicationStatus} from '../../types'
import {mapNewsItem} from '../../utils/news'
import {mapNotificationsItem} from '../../utils/notifications'

const DEFAULT_POLLING_INTERVAL = 60 * 1000
const DEFAULT_POLLING_LIMIT = 100

const initialState: PoolState = {
  newsPool: [],
  notificationsPool: [],
  itemIds: {
    news: {},
    notifications: {}
  },
  dataIsReady: false,
  pollingInterval: DEFAULT_POLLING_INTERVAL,
  pollingLimit: DEFAULT_POLLING_LIMIT
}

const communicationsPoolSlice = createSlice({
  name: 'communicationsPool',
  initialState,
  reducers: {
    setPoolNews(state, action: PayloadAction<INewsItem[]>) {
      const news = action.payload
      const newsPoolItemIds = state.itemIds.news
      const newsPool = state.newsPool

      news.forEach((newsItem) => {
        if (!newsPoolItemIds[newsItem.id]) {
          newsPoolItemIds[newsItem.id] = true
          newsPool.push(mapNewsItem(newsItem))
        }
      })
    },
    setPoolNotifications(state, action: PayloadAction<INotification[]>) {
      const notifications = action.payload
      const notificationsPoolItemIds = state.itemIds.notifications
      const notificationsPool = state.notificationsPool

      notifications.forEach((notificationItem) => {
        if (!notificationsPoolItemIds[notificationItem.id]) {
          notificationsPoolItemIds[notificationItem.id] = true
          notificationsPool.push(mapNotificationsItem(notificationItem))
        }
      })
    },
    readOnePoolNotification(state, action: PayloadAction<string>) {
      const notificationItem = state.notificationsPool.find((notification) => notification.id === action.payload)
      if (notificationItem) {
        notificationItem.status = CommunicationStatus.READ
      }
    },
    readOnePoolNews(state, action: PayloadAction<string>) {
      const newsFound = state.newsPool.find((newsItem) => newsItem.id === action.payload)
      if (newsFound) {
        newsFound.status = CommunicationStatus.READ
      }
    },
    setPoolPollingCursor(state, action: PayloadAction<string>) {
      state.pollingCursor = action.payload
    },
    setPoolPollingInterval(state, action: PayloadAction<number>) {
      state.pollingInterval = action.payload
    },
    setPoolPollingLimit(state, action: PayloadAction<number>) {
      state.pollingLimit = action.payload
    },
    setPoolDataIsReady(state, action: PayloadAction<boolean>) {
      state.dataIsReady = action.payload
    },
    resetPool(state) {
      state.newsPool = []
      state.itemIds.news = {}
      state.itemIds.notifications = {}
      state.notificationsPool = []
      state.pollingCursor = undefined
    }
  }
})

export const communicationsPoolReducer = communicationsPoolSlice.reducer

export const {
  setPoolDataIsReady,
  setPoolNotifications,
  resetPool,
  setPoolPollingInterval,
  setPoolPollingCursor,
  setPoolPollingLimit,
  setPoolNews,
  readOnePoolNotification,
  readOnePoolNews
} = communicationsPoolSlice.actions
