import {useI18n} from '@eda-restapp/i18n'
import {useIsMobileStrict} from '@eda-restapp/ui'
import {useState, useEffect} from 'react'

import {ResultType, type useFileUploadType, type UseFileUploadReturnType} from './types'
import validateImage from './validateImage'
import {DEFAULT_RESTRICTIONS} from './constants'

function blobToBase64(blob: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string)
      }
    }
    reader.readAsDataURL(blob)
  })
}

export const useFileUpload = ({
  disabled = false,
  validate,
  restrictions,
  resultType = ResultType.base64,
  buttonText: buttonTextCustom,
  buttonTextReplace: buttonTextReplaceCustom,
  forceReplaceText = false,
  forceCancelOnChange
}: useFileUploadType): UseFileUploadReturnType => {
  const {t} = useI18n()

  const buttonText = buttonTextCustom || t('shared-ui.use-file-upload.add-photo', 'Добавить фотографию')
  const buttonTextReplace =
    buttonTextReplaceCustom || t('shared-ui.use-file-upload.replace-photo', 'Заменить фотографию')

  const [file, setFile] = useState<File>()
  const [fileAsString, setString] = useState<string>()
  const [fileAsBase64, setBase64] = useState<string>()
  const [error, setError] = useState<string>()

  const isMobile = useIsMobileStrict()

  const allRestrictions = {...DEFAULT_RESTRICTIONS, ...restrictions}
  const mimeTypes = allRestrictions?.fileTypes?.map((fileType) => `image/${fileType}`)
  const cancel = () => setFile(undefined)

  useEffect(() => {
    if (forceCancelOnChange) {
      cancel()
    }
  }, [forceCancelOnChange])

  const changeHandler = (data: File | File[]) => {
    const file = Array.isArray(data) ? data.find(Boolean) : data
    if (file) {
      validateImage(file, allRestrictions, validate)
        .then(() => {
          setFile(file)

          if (resultType === ResultType.base64) {
            blobToBase64(file).then(setBase64)
          } else {
            file.text().then(setString)
          }
        })
        .catch(setError)
    }
  }

  return {
    dirty: !!file,
    file,
    fileAsString,
    fileAsBase64,
    error,
    props: {
      disabled: !!disabled,
      text: Boolean(file) || forceReplaceText ? buttonTextReplace : buttonText,
      accept: mimeTypes,
      onChange: changeHandler,
      width: isMobile ? '100%' : '200px'
    }
  }
}
