import {metrika} from '@eda-restapp/logger'
import {Typography} from '@mui/material'
import cn from 'classnames'
import React from 'react'

import type {ICommunicationWidget} from '@restapp/shared-api'
import {Button} from '@restapp/shared-ui/Button'

import type {CommunicationType} from '../../types'

import {useWidgetStyles, useWidgetVariantsStyles} from './CriticalMessageWidget.style'

export interface CriticalMessageWidgetProps {
  className?: string
  variant: CommunicationType
  widget: ICommunicationWidget

  onClose(): void

  onClick(): void
}

function CriticalMessageWidget({className, variant, widget, onClick, onClose}: CriticalMessageWidgetProps) {
  const {classes} = useWidgetStyles()
  const widgetVariantClass = useWidgetVariantsStyles().classes[variant]

  const onClickHandler = () => {
    metrika({target: 'button_click', params: {eventSlug: 'communications_widget_button'}})
    onClick()
  }

  return (
    <div
      className={cn(classes.root, widgetVariantClass, className)}
      data-testid={'widget-in-sidebar' /* Виджет с новостью в сайдбаре | Виджет Критической Новости */}
    >
      <div
        className={classes.closeIconWrapper}
        onClick={onClose}
        data-testid={
          'closing-cross-widget-in-sidebar' /* Крестик закрытия виджета в сайдбаре | Виджет Критической Новости */
        }
      />
      <Typography
        className={classes.text}
        data-testid={'text-in-widget-in-sidebar' /* Текст в виджете в сайдбаре | Виджет Критической Новости */}
      >
        {widget.title}
      </Typography>
      <Button
        className={classes.button}
        data-testid={'button-in-widget-in-sidebar' /* Кнопка в виджете в сайдбаре | Виджет Критической Новости */}
        fullWidth
        onClick={onClickHandler}
      >
        {widget.button.text}
      </Button>
    </div>
  )
}

export default CriticalMessageWidget
