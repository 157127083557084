import {type IServices} from '@restapp/shared-api'

export type AvailablePlaceFilterOption = {
  address: string
  annotation: string
  disabled: boolean
  hasAlert: boolean
  icon: JSX.Element
  id: number
  services: IServices | undefined
  title: string
}

export enum ReportFileFormat {
  CSV = 'csv',
  XLS = 'xls'
}
