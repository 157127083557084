import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles({name: 'PlusBanner'})({
  root: {
    padding: 16,
    background: 'radial-gradient(100% 283.68% at 0% 100%, #FEE8E2 0%, #F5E2F6 50%, #E5E6FA 100%)',
    borderRadius: 16,
    display: 'grid',
    rowGap: 8,
    columnGap: 12,
    gridTemplateColumns: '40px 1fr',
    gridTemplateRows: 'auto auto',
    fontSize: 14,
    lineHeight: '18px'
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',
    gridRow: '1/-1'
  },

  link: {
    color: '#0668BE',
    cursor: 'pointer'
  }
})
