import {useI18n} from '@eda-restapp/i18n'
import {notificationsChannel, type AppNotifications} from '@eda-restapp/microfrontend'
import {usePermission} from '@eda-restapp/permissions'
import {memo, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useSnackbarNotification} from './useSnackbarNotification'
import {isSilentNotification} from '../utils'

export const DisplayWebSocketNotifications = memo(() => {
  const navigate = useNavigate()
  const {t} = useI18n()
  const hasAccess = usePermission('permission.orders.active')
  const {isLeader} = useNotificationChannelLeaderState()
  const {showSnackbarNotification} = useSnackbarNotification()

  useEffect(() => {
    if (!isLeader) {
      return
    }

    return notificationsChannel.subscribe((notification) => {
      if (notification.meta.source !== 'socket' || isSilentNotification(notification) || notification.meta.clicked) {
        return
      }

      switch (notification.event) {
        case 'new_yamb_csat_message':
        case 'new_yamb_message':
        case 'new_message': {
          showSnackbarNotification({
            message: notification.meta.notification_body,
            onClick: () => markClicked(notification)
          })

          break
        }

        case 'order_changed_courier':
        case 'order_changed_items':
        case 'order_changed_status':
        case 'order_updated_courier':
        case 'order_new': {
          if (!hasAccess) {
            break
          }

          showSnackbarNotification({
            message: notification.meta.notification_body,
            onClick: () => markClicked(notification)
          })
          break
        }

        case 'place_leader_changed': {
          showSnackbarNotification({
            message: notification.meta.notification_body,
            type: ['leader', 'leader_can_taken_away'].includes(notification.data.new_status) ? 'success' : 'error',
            onClick: () => markClicked(notification)
          })

          break
        }

        case 'place_blocked': {
          showSnackbarNotification({
            message: notification.meta.notification_body,
            type: 'error',
            onClick: () => markClicked(notification)
          })

          break
        }

        default:
          break
      }
    })
  }, [isLeader, showSnackbarNotification, t, navigate, hasAccess])

  return null
})

function markClicked(notification: AppNotifications) {
  return notificationsChannel.broadcast({
    ...notification,
    meta: {...notification.meta, clicked: true}
  })
}

function useNotificationChannelLeaderState() {
  const [isLeader, setIsLeader] = useState(false)
  useEffect(() => {
    if (!isLeader) {
      void notificationsChannel.leaderElector
        .awaitLeadership()
        .then(() => setIsLeader(true))
        // eslint-disable-next-line no-console
        .catch(console.error)
    }
  }, [isLeader])
  return {isLeader}
}
