import {makeStyles} from 'tss-react/mui'

type HeaderRowClasses = 'root' | 'sticky' | 'yangoFontHeader'

export type HeaderRowStylesProps = {
  classes?: Partial<Record<HeaderRowClasses, string>>
}

export const useHeaderRowStyles = makeStyles({name: 'HeaderRow'})({
  root: {
    width: '100%',
    background: '#ffffff'
  },
  sticky: {
    width: '100%',
    zIndex: 1
  },
  yangoFontHeader: {
    '& > h1': {
      fontFamily: 'Yango Headline'
    }
  }
})
