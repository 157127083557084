import {makeStyles} from 'tss-react/mui'

export const useEmojisStyles = makeStyles({name: 'Emojis'})((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    emojiButton: {
      '&&': {
        width: 56,
        height: 56,
        minWidth: 'unset',
        padding: 0,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FFF',
        opacity: 0.8,
        transition: theme.transitions.create(['opacity'], {duration: theme.transitions.duration.short}),

        '&:hover': {
          opacity: 1
        },

        '& + &': {
          marginLeft: 16,

          [theme.breakpoints.up('md')]: {
            marginLeft: 32
          }
        }
      }
    },
    emojiButtonActive: {
      '&&': {
        background: '#FCE45E !important',
        opacity: 1
      }
    },
    emoji: {
      '&&': {
        fontSize: 40,
        cursor: 'pointer'
      }
    }
  }
})
