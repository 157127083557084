import {usePullToRefresh} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, GwcSpinner, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useCallback, useEffect, useRef} from 'react'

import {SinglePlaceDropdown, usePlaces} from '@restapp/core-places'
import {Header, HeaderRow, Page, PageContent, SidebarBurger} from '@restapp/main-layout'
import HelpButtonContainer from '@restapp/main-layout/containers/HelpButtonContainer'
import {useServiceBrand} from '@restapp/shared'
import {DesktopDevice, MobileDevice} from '@restapp/shared-ui/Device'
import {Loader} from '@restapp/shared-ui/Loader/Loader'
import RefreshButton from '@restapp/shared-ui/RefreshButton'

import News from '../../components/News/News'
import Notifications from '../../components/Notifications/Notifications'
import {useNews} from '../../hooks/useNews'
import {useNotifications} from '../../hooks/useNotifications'
import {usePool} from '../../hooks/usePool'

import {useCommunicationStyles} from './Communications.style'

function Communications() {
  const {t} = useI18n()
  const {classes: c} = useCommunicationStyles()

  const contentRef = useRef<HTMLDivElement>(null)
  const {selectedPlaceId, placesIds} = usePlaces()
  const serviceBrand = useServiceBrand()
  const pool = usePool()
  const news = useNews()
  const notifications = useNotifications()
  const isMobile = useIsMobileStrict()

  const notificationsCount = notifications.notifications.length

  const handleRefresh = useCallback(() => {
    if (!selectedPlaceId) {
      return Promise.resolve()
    }

    return Promise.all([pool.poll(selectedPlaceId), news.reload()])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId])

  const pullToRefresh = usePullToRefresh<HTMLDivElement>({
    refreshing: news.isLoading,
    onRefresh: () => void handleRefresh()
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => metrika({target: 'show_notifpage'}), [])

  useEffect(() => {
    if (!selectedPlaceId) {
      return
    }

    news.reload()
    pool.poll(selectedPlaceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId])

  return (
    <Page contentRef={contentRef}>
      <Header>
        <HeaderRow stickyPosition={0}>
          <SidebarBurger />
          <h1 className={cn({[c.yangoFont]: serviceBrand === 'YangoEats'})}>
            {notificationsCount > 0
              ? t('main-communications.communications.header-notifications-count-text', '{count} новость/и/ей', {
                  none: '{count} новостей',
                  one: '{count} новость',
                  some: '{count} новости',
                  many: '{count} новостей',
                  count: notificationsCount
                })
              : t('main-communications.communications.novosti', 'Новости')}
          </h1>
          <RefreshButton
            classes={{root: cn(c.refreshButton, isMobile && c.refreshButtonOnTouchDevice)}}
            onUpdate={handleRefresh}
          />
          <DesktopDevice>
            <SinglePlaceDropdown className={c.restaurantSelectDesktop} />
            <HelpButtonContainer className={c.rightControls} />
          </DesktopDevice>
        </HeaderRow>
        {placesIds.length > 1 && (
          <MobileDevice>
            <HeaderRow stickyPosition={0}>
              <SinglePlaceDropdown className={c.restaurantSelectMobile} />
            </HeaderRow>
          </MobileDevice>
        )}
      </Header>
      <PageContent>
        <div ref={pullToRefresh.ref} className={c.contentWrapper}>
          <Box
            justifyContent='center'
            alignItems='center'
            style={{overflow: 'hidden', height: 0}}
            ref={pullToRefresh.pullerRef}
          >
            <GwcSpinner />
          </Box>
          {pool.ready ? (
            <>
              <section className={c.notificationsWrapper}>
                <Notifications notifications={notifications.notifications} onMarkRead={notifications.markRead} />
              </section>
              <News
                newsItems={news.items}
                sort={news.sort}
                filter={news.filter}
                hasMore={news.hasMore}
                isLoading={news.isLoading}
                onSetSort={news.setSort}
                onSetFilter={news.setFilter}
                onLoadMore={news.loadMore}
                onMarkRead={(id) => {
                  news.markRead(id)
                }}
                onReadAll={news.readAll}
                scrollableRef={contentRef}
              />
            </>
          ) : (
            <Loader active />
          )}
        </div>
      </PageContent>
    </Page>
  )
}

export default Communications
