import React, {useEffect, useRef} from 'react'

export const FocusMe = <T extends Record<string, unknown>>({
  render,
  ...props
}: {
  render: (ref: React.RefObject<HTMLDivElement>) => JSX.Element
} & T) => {
  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.focus()
    }
  }, [wrapper.current])

  return <div {...props}>{render(wrapper)}</div>
}
