import type {CountryCodeType} from '@eda-restapp/i18n'
import {
  CountryFlagAm,
  CountryFlagAz,
  CountryFlagBo,
  CountryFlagBy,
  CountryFlagCi,
  CountryFlagKg,
  CountryFlagKz,
  CountryFlagUz,
  CountryFlagZm,
  CountryFlagGe,
  FlagRu
} from '@eda-restapp/ui'

const INTERNATIONAL_COUNTRY_FLAGS: Record<CountryCodeType, typeof FlagRu> = {
  AM: CountryFlagAm,
  AZ: CountryFlagAz,
  BO: CountryFlagBo,
  BY: CountryFlagBy,
  CI: CountryFlagCi,
  GE: CountryFlagGe,
  KG: CountryFlagKg,
  KZ: CountryFlagKz,
  RU: FlagRu,
  UZ: CountryFlagUz,
  ZM: CountryFlagZm
}

export function getCountryFlag(flag: CountryCodeType) {
  if (!Object.keys(INTERNATIONAL_COUNTRY_FLAGS).includes(flag)) {
    return null
  }

  return INTERNATIONAL_COUNTRY_FLAGS[flag]
}
