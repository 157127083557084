export const tabsStyles = {
  root: {
    // Компенсирует отступ у табов
    paddingTop: 8
  },

  flexContainer: {
    flexWrap: 'wrap'
  },

  indicator: {
    display: 'none'
  }
} as const
