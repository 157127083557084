import {eventLogger} from '@eda-restapp/logger'
import {useIsMobileStrict} from '@eda-restapp/ui'
import MuiButton, {type ButtonProps as MUIButtonProps} from '@mui/material/Button'
import cn from 'classnames'
import {omit} from 'lodash-es'
import React, {forwardRef, type MouseEvent} from 'react'
import type {LinkProps} from 'react-router-dom'

import Spinner from '../Spinner'
import Tooltip from '../Tooltip'

import useStyles from './Button.style'

export type ButtonProps = MUIButtonProps & {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>
  loading?: boolean
  component?: React.ElementType
  tooltipClassName?: string
  to?: LinkProps['to']
  state?: LinkProps['state']
  tooltipText?: string
  slug?: string
}

const TOOLTIP_DELAY = 500

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {loading, children, disabled, classes, className, tooltipClassName, size = 'medium', tooltipText, slug, ...props},
    ref
  ) => {
    const isMobile = useIsMobileStrict()
    const {classes: c} = useStyles(undefined, {props: {classes}})

    const onClickWithTracker = (evt: MouseEvent<HTMLButtonElement>) => {
      eventLogger({
        name: 'button_click',
        value: slug,
        additional: {eventData: typeof children === 'string' ? children : undefined}
      })
      props.onClick?.(evt)
    }

    const spinnerSize = {
      large: 24,
      medium: 20,
      small: 18
    }[size]

    const renderButton = () => (
      <MuiButton
        color='secondary'
        {...props}
        ref={ref}
        size={size}
        classes={{...omit(c, ['tooltip', 'loadingClass', 'childrenSpan']), root: cn(className, c.root)}}
        className={cn({[c.loadingClass]: loading})}
        disabled={disabled || loading}
        disableElevation
        disableRipple={!isMobile}
        onClick={onClickWithTracker}
      >
        {/*https://github.com/facebook/react/issues/11538 - reason of span around children. React will break when page translate enabled and if text not wrapped in some node*/}
        {loading ? <Spinner size={spinnerSize} /> : <span className={c.childrenSpan}>{children}</span>}
      </MuiButton>
    )

    return tooltipText ? (
      <Tooltip className={cn(c.tooltip, tooltipClassName)} title={tooltipText} enterDelay={TOOLTIP_DELAY}>
        <span>{renderButton()}</span>
      </Tooltip>
    ) : (
      renderButton()
    )
  }
)
