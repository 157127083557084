import React from 'react'
import {useStyles} from './ProgressCircle.style'

export type DefaultProps = {
  /**
   *  цвет активного состояния
   * */

  colorActive: string
  colorOutline: string // цвет прошедшего состояния
  colorFill: string // цвет подложки
  size: 'small' | 'default' | 'large'
}

export type Props = {
  percent: number
  /**
   *  цвет текста активного состояния
   */
  colorActiveText?: string
  children?: React.ReactElement<any> | string
  /** Указывает пороговое(целевое) значение */
  threshold?: number
  /** Цвет после порогового значения */
  thresholdColor?: string
  /** Цвет после порогового значения для текста*/
  thresholdColorText?: string
} & Partial<DefaultProps> & {
    className?: string
    classes?: Partial<Record<keyof typeof useStyles, string>>
  }

const COLOR_THRESHOLD = '#FCB900'

const ProgressCircle = ({
  children,
  percent,
  threshold,
  thresholdColor = COLOR_THRESHOLD,
  thresholdColorText = COLOR_THRESHOLD,
  colorActive = '#288fe5',
  colorActiveText,
  colorOutline = '#e5e5e5',
  colorFill = 'transparent',
  size = 'default',
  classes
}: Props) => {
  const {classes: c} = useStyles(
    {
      colorActive,
      colorOutline,
      colorFill,
      size
    },
    {
      props: {
        classes: classes
      }
    }
  )

  const dashArray = Math.PI * 46
  const progress = dashArray * (1 - percent * -1)
  const progressThreshold = threshold && dashArray * (1 - threshold * -1)
  const beyondThreshold = threshold ? percent >= threshold : false

  const sizeInPixels = {
    small: 40,
    default: 50,
    large: 70
  }[size]

  return (
    <div className={c.root}>
      <svg width={sizeInPixels} height={sizeInPixels} viewBox='0 0 50 50'>
        <circle cx='25' cy='25' r='23' fill='none' strokeWidth='3.5' stroke={colorOutline} />
        <circle cx='25' cy='25' r='23' fill={colorFill} stroke='transparent' strokeWidth='3' strokeLinecap='round' />
        {!!threshold && !beyondThreshold && (
          <circle
            cx='25'
            cy='25'
            r='23'
            opacity='0.2'
            transform='rotate(-90 25 25)'
            fill='none'
            stroke={thresholdColor}
            strokeWidth='3'
            strokeLinecap='round'
            strokeDasharray={dashArray}
            strokeDashoffset={progressThreshold}
          />
        )}
        <circle
          cx='25'
          cy='25'
          r='23'
          transform='rotate(-90 25 25)'
          fill='none'
          stroke={threshold && !beyondThreshold ? thresholdColor : colorActive}
          strokeWidth='3'
          strokeLinecap='round'
          strokeDasharray={dashArray}
          strokeDashoffset={progress}
        />
      </svg>
      <div
        className={c.content}
        style={{
          color:
            threshold && !beyondThreshold ? (thresholdColorText ?? thresholdColor) : (colorActiveText ?? colorActive)
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default ProgressCircle
