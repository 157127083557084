import {makeStyles} from 'tss-react/mui'

export const useNewsTabStyles = makeStyles({name: 'NewsTab'})({
  root: {
    backgroundColor: '#F2F4F5',
    color: '#302F2D',
    minHeight: 40,
    minWidth: 'unset',
    borderRadius: 8,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'unset',
    marginRight: 8,
    padding: '0 16px',

    '@media (hover)': {
      '&:hover': {
        backgroundColor: '#CDD0D2',
        boxShadow: 'none'
      }
    }
  },
  selected: {
    '&& ': {
      backgroundColor: '#CDD0D2',
      color: 'unset'
    }
  },
  textColorInherit: {
    opacity: 1
  }
})
