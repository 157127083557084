import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'
import {useIsDesktopStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import type {ReactNode, CSSProperties} from 'react'
import React, {forwardRef} from 'react'
import {NavLink} from 'react-router-dom'

import {useSupportLinkPrep} from '@restapp/shared-support'
import {OutsideLink, Tooltip} from '@restapp/shared-ui'

import styles from './SidebarNavElement.module.css'

interface SidebarNavElementProps {
  className?: string
  path: string
  label: string
  color?: string
  onClick: () => void
  mini?: boolean
  icon?: ReactNode
  badge?: React.ReactNode
  external?: boolean
  target?: 'self' | 'blank'
}

const SidebarNavElement: React.FC<SidebarNavElementProps> = ({
  className,
  path,
  label,
  icon,
  badge,
  mini,
  onClick,
  external,
  target,
  color
}) => {
  const isDesktop = useIsDesktopStrict()
  const newYearConfig = useExp3('restapp_new_year')

  const handleClick = () => {
    eventLogger({name: 'click-sidebar-element', additional: {path, label}})
    onClick()
  }

  return (
    <Tooltip
      classes={{tooltip: styles.tooltip}}
      title={label}
      disableHoverListener={!mini || !isDesktop}
      disableFocusListener={!isDesktop}
      disableTouchListener={!isDesktop}
      placement='right'
    >
      <Link
        label={label}
        path={path}
        onClick={handleClick}
        className={cn(styles.nav, className, {
          [styles.newYearNav]: newYearConfig.enabled,
          [styles.paddingWhenMini]: mini
        })}
        style={{color}}
        external={target === 'blank' || external === true}
      >
        <div className={styles.nameWrapper}>
          {!!icon && <div className={styles.icon}>{icon}</div>}
          {!mini && <span className={styles.label}>{label}</span>}
        </div>
        {badge}
      </Link>
    </Tooltip>
  )
}

interface LinkProps {
  path: string
  className: string
  onClick: () => void
  label: string
  external: boolean
  style?: CSSProperties
  children?: React.ReactNode
}

const Link: React.FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(function LinkWithRef(
  {children, style, path, className, onClick, label, external, ...tooltipChildProps},
  ref
) {
  const href = useSupportLinkPrep(path)

  if (external) {
    return (
      <OutsideLink
        ref={ref}
        target='_blank'
        rel='noreferrer'
        href={href}
        className={className}
        onClick={onClick}
        data-testid={`ui-sidebar-section-${label}` /*UI | Ссылка на раздел в сайдбаре. link.name - название раздела*/}
        {...tooltipChildProps}
      >
        {children}
      </OutsideLink>
    )
  }

  return (
    <NavLink
      ref={ref}
      className={({isActive}) => cn(className, isActive && styles.active)}
      to={path}
      onClick={onClick}
      data-testid={`ui-sidebar-section-${label}` /*UI | Ссылка на раздел в сайдбаре. link.name - название раздела*/}
      {...tooltipChildProps}
    >
      {children}
    </NavLink>
  )
})

export default React.memo(SidebarNavElement)
