import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useEvent from 'react-use-event-hook'

import {useEnv} from '@restapp/core-di/hooks'

import {toggleOption, toggleManyOption} from '../actions'
import {optionEntitySelectors} from '../entities'
import type {OptionVirtualId} from '../types'
import {selectMenu} from '../utils'

export function useOptionToggle() {
  const {t} = useI18n()
  const {snackbarNotifier} = useEnv()
  const menuConfig = useExp3('restapp_menu')
  const dispatch = useDispatch()
  const menuState = useSelector(selectMenu)

  const optionsSnackbarRef = useRef<string>('')

  return useEvent((payload: {id: OptionVirtualId; value: boolean; reactivatedAd?: string | null}) => {
    if (menuConfig.enableSameNameOptionsControl) {
      const optionName = menuState.options.current.entities[payload.id]?.name || ''

      const sameNameOptions = optionEntitySelectors
        .selectAll(menuState.options.current)
        .filter((option) => option.name.trim().toLowerCase() === optionName.trim().toLowerCase())
        .map((option) => {
          return {
            id: option._virtualId,
            value: payload.value,
            reactivatedAd: payload.reactivatedAd || null
          }
        })

      dispatch(toggleManyOption(sameNameOptions))

      if (optionsSnackbarRef.current) {
        snackbarNotifier.remove(optionsSnackbarRef.current)
      }

      optionsSnackbarRef.current = snackbarNotifier.enqueue({
        showTime: 5_000,
        type: 'success',
        showCloseBtn: true,
        message: payload.value
          ? t('shared-menu.use-menu-edit.options-enable-notification', 'Нотификация после включения галочки у опции', {
              count: sameNameOptions.length,
              one: 'Опция включится у 1 блюда',
              some: 'Опция включится у {count} блюд',
              many: 'Опция включится у {count} блюд'
            })
          : t(
              'shared-menu.use-menu-edit.options-disable-notification',
              'Нотификация после отключения галочки у опции',
              {
                count: sameNameOptions.length,
                one: 'Опция отключится у 1 блюда',
                some: 'Опция отключится у {count} блюд',
                many: 'Опция отключится у {count} блюд'
              }
            )
      })
    } else {
      dispatch(
        toggleOption({
          ...payload,
          reactivatedAd: payload.reactivatedAd || null
        })
      )
    }
  })
}
