import {
  APP_LANGUAGES_LABELS,
  type CountryCodeType,
  getServiceBrandByCountryCode,
  type ServiceBrandType,
  type SUPPORTED_LANGUAGES,
  useDomainDividedCountriesInfo
} from '@eda-restapp/i18n'

/** Hook returns array of languages, that's available for selected country-code. If there's no <countryCode> in params, than hook returns nothing */
export const useAvailableCountryLanguages = (countryCode?: CountryCodeType | null, serviceBrand?: ServiceBrandType) => {
  const domainDividedCountriesInfo = useDomainDividedCountriesInfo()

  if (!countryCode) {
    return
  }

  if (serviceBrand) {
    return domainDividedCountriesInfo[serviceBrand][countryCode]?.availableLanguageCodes
  }

  const selectedCountryCodeParams = Object.values(domainDividedCountriesInfo).find(
    (serviceBrand) => serviceBrand[countryCode]
  )

  return selectedCountryCodeParams ? selectedCountryCodeParams[countryCode]?.availableLanguageCodes : []
}

/** Hook returns array of languages, that's available on selected service (domain). If there's no <serviceName> in params, than hook returns all available languages in app (in all domains together) */
const useAvailableServiceBrandLanguages = (serviceBrand: ServiceBrandType | null) => {
  const domainDividedCountriesInfo = useDomainDividedCountriesInfo()
  const availableLanguagesSet = serviceBrand
    ? Object.values(domainDividedCountriesInfo[serviceBrand]).reduce((result, country) => {
        country.availableLanguageCodes.forEach((languageCode) => result.add(languageCode))

        return result
      }, new Set<SUPPORTED_LANGUAGES>())
    : Object.values(domainDividedCountriesInfo).reduce((result, service) => {
        Object.values(service).map((country) =>
          country.availableLanguageCodes.forEach((language) => result.add(language))
        )

        return result
      }, new Set<SUPPORTED_LANGUAGES>())

  return Array.from(availableLanguagesSet)
}

/** Hook returns array of languages, that's available on selected country (country-code). If there's no <country-code> in params, than hook returns null */
export const useAvailableLanguagesByCountryCode = (countryCode: CountryCodeType | null) => {
  const serviceBrand = getServiceBrandByCountryCode(countryCode)

  const availableServiceBrandLanguages = useAvailableServiceBrandLanguages(serviceBrand)

  if (!countryCode) {
    return null
  }

  return availableServiceBrandLanguages
}

export const getLanguageLabelByCode = (languageCode: SUPPORTED_LANGUAGES) => APP_LANGUAGES_LABELS[languageCode]
