import {useOldHeavyPlaces} from './useOldHeavyPlaces'
import {usePlaces} from './usePlaces'

type HeavyPlaceOptions = Parameters<typeof useOldHeavyPlaces>[0]

function useHeavyPlace(options: HeavyPlaceOptions = {}) {
  const {selectedPlaceId, isLoading: isLoadingPlaces, places} = usePlaces(options as Parameters<typeof usePlaces>[0])
  const {getPlaceById, isLoading: isLoadingHeavyPlaces} = useOldHeavyPlaces({refetchOnMount: false, ...options})

  return {
    isLoading: isLoadingPlaces || isLoadingHeavyPlaces,
    place: getPlaceById(selectedPlaceId),
    places
  }
}

export default useHeavyPlace
