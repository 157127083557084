import {useContext} from 'react'

import {HeaderStickyRowContext} from '../context/HeaderStickyRowContext'
import type {HeaderStickyRowContextType} from '../types'

function useHeaderContext(): HeaderStickyRowContextType {
  const headerStickyRowContext = useContext(HeaderStickyRowContext)

  if (!headerStickyRowContext) {
    throw new Error('HeaderStickyRowContext not found. Wrap your components in HeaderStickyRowContextProvider')
  }

  return headerStickyRowContext
}

export default useHeaderContext
