import {useExp3} from '@eda-restapp/configs'
import React, {useState} from 'react'

import {useIsEnabledInCurrentNetwork} from '@restapp/shared'

import styles from './Auth.module.css'
import {EmailAuth} from './components/EmailAuth/EmailAuth'
import {PassportAuth} from './components/PassportAuth/PassportAuth'

export interface AuthProps {
  login: (email: string, password: string) => Promise<string | undefined>
}

export function Auth({login}: AuthProps) {
  const [showEmailAuth, setShowEmailAuth] = useState(false)
  const authConfig = useExp3('restapp_auth')
  const disableEmailAuth = useIsEnabledInCurrentNetwork(authConfig.disableEmailAuthOnNewAuthPage)

  const handleSwitchToEmailAuth = () => {
    setShowEmailAuth(true)
  }

  const handleSwitchToPassportAuth = () => {
    setShowEmailAuth(false)
  }

  return (
    <div className={styles.root}>
      {showEmailAuth ? (
        <EmailAuth login={login} onSwitchAuth={handleSwitchToPassportAuth} />
      ) : (
        <PassportAuth onSwitchAuth={disableEmailAuth ? undefined : handleSwitchToEmailAuth} />
      )}
    </div>
  )
}
