import React, {type FC} from 'react'

import {useSubscriptionButton} from '../../hooks/useSubscriptionButton'

import {SubscriptionButton} from './SubscriptionButton'

type SubscriptionButtonContainerProps = {
  hideTooltip?: boolean
}

export const SubscriptionButtonContainer: FC<SubscriptionButtonContainerProps> = ({hideTooltip}) => {
  const {isShow} = useSubscriptionButton()

  if (!isShow) {
    return null
  }

  return <SubscriptionButton hideTooltip={hideTooltip} />
}
