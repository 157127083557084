import {useExp3} from '@eda-restapp/configs'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {AppLogo, clearCountryCode, useIsEnabledInCurrentNetwork} from '@restapp/shared'
import {LanguageSelector} from '@restapp/shared/components'
import {isNativeVendor} from '@restapp/shared/utils'
import {Globe} from '@eda-restapp/ui'
import {Button, SupportContacts} from '@restapp/shared-ui'

import {useLandingAppStyles} from './LandingApp.styles'
import {OldAuthInformer} from '@restapp/core-auth/components/AuthContainer/components/PassportAuth/OldAuthInformer/OldAuthInformer'
import cn from 'classnames'

type LandingAppProps = {
  hideLogo?: boolean
  isAuthPage?: boolean
  children?: React.ReactNode
}

export const LandingApp: React.FC<LandingAppProps> = ({hideLogo, isAuthPage = false, children}) => {
  const commonConfig = useExp3('restapp_common')
  const authConfig = useExp3('restapp_auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {classes: c} = useLandingAppStyles()

  const disableEmailAuthOnNewPageEnabled = useIsEnabledInCurrentNetwork(authConfig.disableEmailAuthOnNewAuthPage)
  const newAuthPageEnabled = useIsEnabledInCurrentNetwork(authConfig.enableNewAuthPage)

  const showOldAuthInformer = isAuthPage && disableEmailAuthOnNewPageEnabled && newAuthPageEnabled

  const handleCountrySelectClick = () => {
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    ;(window.AndroidInterface || window.webkit?.messageHandlers.restappNative)?.postMessage(
      JSON.stringify({
        name: 'changeCountry',
        type: 'event',
        version: 1,
        payload: {}
      })
    )
    // nativeCommunicationChannel.broadcast({type: 'changeCountry'})
    // TODO: Удалим тут EDARESTAPP-10413
    dispatch(clearCountryCode())
    navigate('/country-select-auth')
  }

  return (
    <div className={c.root}>
      {isNativeVendor && commonConfig.countrySelectAuthEnabled && (
        <div className={c.countrySelectorAuthBlock}>
          <Button
            id='country-selector-button'
            className={c.countrySelectorButton}
            disableElevation
            onClick={handleCountrySelectClick}
          >
            <Globe />
          </Button>
        </div>
      )}
      <div className={c.languageSelectorBlock}>
        <LanguageSelector
          classes={{root: c.languageSelectorRoot}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </div>
      <div className={cn(c.container, {[c.containerWithOldAuthInformer]: showOldAuthInformer})}>
        {!hideLogo && <AppLogo />}

        <div className={c.childrenBlock}>{children}</div>
      </div>
      {showOldAuthInformer && (
        <div className={c.oldAuthInformerBlock}>
          <div className={c.oldAuthInformer}>
            <OldAuthInformer />
          </div>
        </div>
      )}
      <div
        className={cn(c.footer, {
          [c.footerWithOldAuthInformer]: showOldAuthInformer
        })}
      >
        <SupportContacts />
      </div>
    </div>
  )
}
