import {createSelector} from '@reduxjs/toolkit'
import {isEqual} from 'lodash-es'

import {groupEntitySelectors} from '../entities'
import {selectMenu, addIdArg, addGroupIdArg} from '../utils'

export const selectCurrentGroups = createSelector(selectMenu, (state) => state.groups.current)
export const selectInitialGroups = createSelector(selectMenu, (state) => state.groups.initial)

export const all = createSelector(selectCurrentGroups, groupEntitySelectors.selectAll)
export const byId = createSelector([selectCurrentGroups, addGroupIdArg], groupEntitySelectors.selectById)
// TODO: этот селектор можно ускорить добавив join для быстрой выборки по itemId
export const byItemId = createSelector([all, addIdArg], (groups, id) => groups.filter((group) => group._itemId === id))
export const isDirty = createSelector(
  [selectCurrentGroups, selectInitialGroups],
  (current, initial) =>
    // Эти проверки будут работать т.к optionEntitySelectors соблюдает сортировку
    !isEqual(groupEntitySelectors.selectAll(current), groupEntitySelectors.selectAll(initial))
)
