import type {ReactNode} from 'react'
import React from 'react'
import {List} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

import {PlacesListStyles} from './PlacesList.styles'
import PlacesListItem from './PlacesListItem'
import type {ShortRestaurant} from '@restapp/shared-api'

export interface PlacesListProps<TPlace extends ShortRestaurant = ShortRestaurant> {
  places: TPlace[]
  renderStart?: (place: TPlace, index: number) => ReactNode
  renderEnd?: (place: TPlace, index: number) => ReactNode
}

const useStyles = makeStyles()(PlacesListStyles)

function PlacesList<TPlace extends ShortRestaurant>({places, renderStart, renderEnd}: PlacesListProps<TPlace>) {
  const {classes: c} = useStyles()

  return (
    <List className={c.root} classes={{padding: c.listPadding}}>
      {places.map((place, index) => (
        <PlacesListItem
          key={place.id}
          place={place}
          renderStart={() => renderStart?.(place, index)}
          renderEnd={() => renderEnd?.(place, index)}
        />
      ))}
    </List>
  )
}

export default PlacesList
