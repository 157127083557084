import {useApiQuery} from '@restapp/core-api'

interface Options {
  enabled?: boolean
}

const useFeaturesByFeatures = (placeIds: number[], options?: Options) =>
  useApiQuery({
    url: '/4.0/restapp-front/places/v1/features-statuses-by-features',
    method: 'GET',
    params: {
      place_ids: placeIds
    },
    enabled: !!placeIds.length && (options?.enabled ?? true),
    select: (data) => data.features
  })

export default useFeaturesByFeatures
