import {t} from '@eda-restapp/i18n'
import {includes, isUndefined} from 'lodash-es'
import moment from 'moment'

import {ALL_PLACES_STATUS} from '@restapp/core-legacy/constants'
import {PLACE_STATUS} from '@restapp/main-places/types/status'
import {
  AUTOSTOP_REASON,
  ENABLE_ALLOWED_REASONS,
  ENABLE_NOT_ALLOWED_REASONS,
  RESTAURANT_DISABLE_REASONS
} from '@restapp/shared'
import {getServiceTime} from '@restapp/shared/utils'
import type {ShortRestaurant} from '@restapp/shared-api'

export function isDisabledByVendor(place: ShortRestaurant) {
  return includes(ENABLE_ALLOWED_REASONS, place.disable_details?.reason)
}

function isDisabledByYandex(place: ShortRestaurant) {
  return includes(ENABLE_NOT_ALLOWED_REASONS, place.disable_details?.reason)
}

function isDisabledByAutostop(place: ShortRestaurant) {
  return includes(AUTOSTOP_REASON, place.disable_details?.reason)
}

export const getOldPlaceStatus = (place: ShortRestaurant): PLACE_STATUS => {
  if (isSelfRegPending(place)) {
    return PLACE_STATUS.SELF_REG_PENDING
  }

  if (isSelfRegComplete(place)) {
    return PLACE_STATUS.SELF_REG_COMPLETE
  }

  if (place.is_switching_on_requested && place.disable_details) {
    return PLACE_STATUS.ACTIVATION_PENDING
  }

  if (isDisabledByAutostop(place)) {
    return PLACE_STATUS.DISABLED_BY_AUTOSTOP
  }

  if (place.disable_details && isDisabledByYandex(place) && !place.is_switching_on_requested) {
    return PLACE_STATUS.DISABLED_BY_YANDEX
  }

  if (!isUndefined(place.can_be_enabled) && !place.can_be_enabled) {
    return PLACE_STATUS.CAN_NOT_BE_ENABLED
  }

  if (place.disable_details && isDisabledByVendor(place)) {
    return PLACE_STATUS.DISABLED_BY_VENDOR
  }

  if (place.busy_mode?.enabled) {
    return PLACE_STATUS.BUSY_MODE_ENABLED
  }

  if (place.is_available) {
    return PLACE_STATUS.ACTIVE
  }

  return PLACE_STATUS.DISABLED
}

export function isSelfRegPending(place: ShortRestaurant) {
  return place.disable_details
    ? place.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_PENDING
    : false
}

export function isSelfRegComplete(place: ShortRestaurant) {
  return place.disable_details
    ? place.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_DONE
    : false
}

export function getPlacesStatus(places: ShortRestaurant[]): ALL_PLACES_STATUS {
  const enabledPlaces = places.filter((place) => place.is_available)
  const disabledPlaces = places.filter((place) => !place.is_available)

  if (enabledPlaces.length === places.length) {
    return ALL_PLACES_STATUS.AVAILABLE
  } else if (
    disabledPlaces.filter((place) => !isSelfRegComplete(place) && !isSelfRegPending(place)).length === places.length
  ) {
    return ALL_PLACES_STATUS.DISABLED
  } else {
    return ALL_PLACES_STATUS.PART_DISABLED
  }
}

function isDateToday(date: moment.Moment) {
  const currentDate = getServiceTime().toDate().toDateString()

  return currentDate === date.toDate().toDateString()
}

export function getPlaceDisabledHumanDate(disableAt?: string): string {
  const disabledMoment = moment(disableAt)

  return isDateToday(disabledMoment)
    ? t('core-legacy.disable-details.disabled-today-from', 'Отключён сегодня в {disabledMoment}', {
        disabledMoment: disabledMoment.format('HH:mm')
      })
    : t('core-legacy.disable-details.disabled-from', 'Отключён {disabledMoment}', {
        disabledMoment: disabledMoment.format('D MMMM HH:mm')
      })
}

export function getPlaceEnabledHumanDate(available_at: string): string | null {
  const diff = moment(available_at).diff(getServiceTime())

  if (moment.duration(diff).asMinutes() < 0) {
    return null
  }

  if (moment.duration(diff).asMinutes() <= 1) {
    return t('core-legacy.disable-details.enabled-in-a-minute-label', 'Включится через 1 минуту')
  }

  if (moment.duration(diff).asDays() > 1) {
    return t('core-legacy.disable-details.enabled-from', 'Включится через {from}', {
      from: moment.duration(diff).humanize()
    })
  } else {
    const hours = moment.utc(diff).hours()
    const minutes = moment.utc(diff).minutes()

    const hoursText = hours
      ? t('core-legacy.disable-details.disabled-hours-count-text', '{count} час/а/ов', {
          none: '{count} часов',
          one: '{count} час',
          some: '{count} часа',
          many: '{count} часов',
          count: hours
        })
      : ''
    const minutesText = minutes
      ? t('core-legacy.disable-details.disabled-minutes-count-text', '{count} минут/у/ы', {
          none: '{count} минут',
          one: '{count} минуту',
          some: '{count} минуты',
          many: '{count} минут',
          count: minutes
        })
      : ''

    return t('core-legacy.disable-details.enabled-by-time', 'Включится через {hoursText} {minutesText}', {
      hoursText,
      minutesText
    })
  }
}

export const getAutostopFinishTime = (placeBlockFinishDate?: string) => {
  if (!placeBlockFinishDate) {
    return null
  }

  if (moment(placeBlockFinishDate).isAfter(moment())) {
    return t('shared-places.place-getters.autostop-info-enabled-time-modal-header', 'Ресторан включится в {time}', {
      time: moment(placeBlockFinishDate).format('HH:mm')
    })
  }

  return null
}
