import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, useIsMobileStrict, Button} from '@eda-restapp/ui'
import React from 'react'

import type {AvailablePartner} from '@restapp/core-auth/types'
import {AppLogo} from '@restapp/shared'

import {BackButton} from './components/BackButton/BackButton'
import {PartnerUidItem} from './components/PartnerUidItem/PartnerUidItem'
import styles from './SelectPartnerUid.module.css'

export type SelectPartnerUidProps = {
  availablePartners: AvailablePartner[]
  showBackButton: boolean
  showLogoutButton: boolean
  onBackButtonClick: () => void
  onLogoutButtonClick: () => void
  onPartnerUidSelect: (partner_uuid: string) => void
}

export const SelectPartnerUid: React.FC<SelectPartnerUidProps> = ({
  availablePartners,
  onBackButtonClick,
  showBackButton,
  showLogoutButton,
  onLogoutButtonClick,
  onPartnerUidSelect
}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  const renderContent = () => (
    <Box flexDirection='column' className={styles.contentContainer} data-testid='select-partner'>
      <Box flexDirection='column' className={styles.contentContainer2} alignItems='center' gap='l'>
        <AppLogo style={{margin: 0}} />

        <Typography thickness='medium' variant='body2'>
          {t('core-auth.select-partner-uid.select-personal-cabinet', 'Выберите личный кабинет')}
        </Typography>

        <Box className={styles.partnerUidContainer} flexDirection='column' gap='xs'>
          {availablePartners.map((partner) => (
            <>
              <PartnerUidItem
                key={partner.partner_uuid}
                email={partner.name || partner.email}
                restaurantCount={partner.places_count}
                onClick={() => {
                  onPartnerUidSelect(partner.partner_uuid)
                }}
              />

              <div className={styles.divider} />
            </>
          ))}
        </Box>
      </Box>
    </Box>
  )

  const renderDesktop = () => (
    <Box className={styles.rootContainer} flex={1} p='l' flexDirection='row' alignItems='start'>
      <Box
        className={styles.crossContainerBack}
        flex={1}
        alignItems='start'
        justifyContent='start'
        flexDirection='column'
      >
        {showBackButton && <BackButton onClick={onBackButtonClick} />}
      </Box>

      {renderContent()}

      <Box
        className={styles.crossContainerExit}
        flex={1}
        justifyContent='start'
        alignItems='end'
        flexDirection='column'
      >
        {showLogoutButton && (
          <Button.Text
            variant='transparent'
            size='m'
            onClick={onLogoutButtonClick}
            data-testid='select-partner-exit-desktop'
          >
            {t('core-auth.select-partner-uid.logout', 'Выйти')}
          </Button.Text>
        )}
      </Box>
    </Box>
  )

  const renderMobile = () => (
    <Box className={styles.rootContainer} pl='s' pr='s' flexDirection='column' alignItems='center'>
      {(showBackButton || showLogoutButton) && (
        <Box
          className={styles.header}
          flexDirection='row'
          pt='m'
          pb='m'
          justifyContent={!showLogoutButton ? 'flex-start' : 'flex-end'}
        >
          {showBackButton && <BackButton onClick={onBackButtonClick} />}

          {showLogoutButton && (
            <Button.Text
              variant='transparent'
              onClick={onLogoutButtonClick}
              size='m'
              data-testid='select-partner-exit-mobile'
            >
              {t('core-auth.select-partner-uid.logout', 'Выйти')}
            </Button.Text>
          )}
        </Box>
      )}

      {renderContent()}
    </Box>
  )

  return isMobile ? renderMobile() : renderDesktop()
}
