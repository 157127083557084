const drawTextLine =
  ({
    textBaseline,
    font,
    ctx,
    color,
    textAlign
  }: {
    textBaseline?: CanvasTextBaseline
    font: CanvasTextDrawingStyles['font']
    ctx: CanvasRenderingContext2D
    color: string
    textAlign: CanvasTextDrawingStyles['textAlign']
  }) =>
  ({text, x, y}: {text: string; x: number; y: number}) => {
    ctx.fillStyle = color
    if (textBaseline) {
      ctx.textBaseline = textBaseline
    }
    if (textAlign) {
      ctx.textAlign = textAlign
    }
    if (font) {
      ctx.font = font
    }

    ctx.fillText(text, x, y)
  }

export default drawTextLine
