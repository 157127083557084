import React from 'react'
import type {TableRowProps} from '@mui/material/TableRow/TableRow'
import {TableRow} from '@mui/material'

const TableRowUI: React.FC<TableRowProps> = (props) => {
  const {className, children, ...rest} = props

  return (
    <TableRow className={className} {...rest}>
      {children}
    </TableRow>
  )
}

export default TableRowUI
