import {useApiQuery} from '@restapp/core-api'
import type {IApiError} from '@restapp/shared-api'

type Options = {
  enabled?: boolean
  onError?: (err: IApiError) => void
}

export const useDisablingParams = (placeId: number, options?: Options) => {
  const isEnabled = options?.enabled ?? true
  const isValidPlaceId = !!placeId && placeId > 0

  const {data, isLoading} = useApiQuery({
    url: '/4.0/restapp-front/places/v1/disabling-params',
    method: 'GET',
    params: {
      place_id: placeId
    },
    enabled: isEnabled && isValidPlaceId,
    onError: options?.onError
  })

  return {
    data,
    isLoading
  }
}
