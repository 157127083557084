import type {NonReadonly} from '../../types'

const noop = () => undefined

export class Defer<T> {
  readonly resolve: (value?: T | PromiseLike<T>) => void = noop
  readonly reject: (reason?: unknown) => void = noop
  readonly promise: Promise<T | undefined>

  constructor() {
    this.promise = new Promise<T | undefined>((resolve, reject) => {
      ;(this as NonReadonly<Defer<T>>).resolve = resolve
      ;(this as NonReadonly<Defer<T>>).reject = reject
    })

    Object.freeze(this)
  }
}
