import {ListItem, ListItemIcon, ListItemText} from '@mui/material'
import type {ReactNode} from 'react'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {DeliveryIcon} from '../DeliveryIcon'
import LineClamp from '../LineClamp'
import {PlacesListStyles} from './PlacesList.styles'
import type {ShortRestaurant} from '@restapp/shared-api'

export interface PlacesListItemProps<TPlace extends ShortRestaurant> {
  place: TPlace
  iconSize?: string | number
  renderStart?: () => ReactNode
  renderEnd?: () => ReactNode
}

const useStyles = makeStyles()(PlacesListStyles)

function PlacesListItem<TPlace extends ShortRestaurant>({
  place,
  renderStart,
  renderEnd,
  iconSize
}: PlacesListItemProps<TPlace>) {
  const {classes: c} = useStyles()

  return (
    <ListItem className={c.listItem} classes={{gutters: c.itemGutters}}>
      {renderStart?.()}
      <ListItemIcon classes={{root: c.icon}}>
        <DeliveryIcon size={iconSize} place={place} />
      </ListItemIcon>
      <ListItemText
        classes={{primary: c.placeName, secondary: c.placeAddress}}
        primary={<LineClamp lineClamp={1}>{place.name}</LineClamp>}
        secondary={<LineClamp lineClamp={1}>{place.address}</LineClamp>}
      />
      {renderEnd?.()}
    </ListItem>
  )
}

export default PlacesListItem
