import {useExp3} from '@eda-restapp/configs'
import {Snackbar} from '@eda-restapp/snackbars'

import {useApiMutation} from '@restapp/core-api'

import {ERROR_PROMOTION, PROMOTION_SUCCESS} from '../constants'
import {CampaignType} from '../types/campaign'
import type {EnableCampaignItem} from '../types/campaign'
import type {GroupType} from '../types/group'

import useCampaignsApi from './useCampaignsApi'

type Props = {
  directToken: string | null | undefined
  params: EnableCampaignItem[]
  onSettled?: () => void
  campaign: GroupType
}

const useCampaignResume = ({directToken, params, onSettled, campaign}: Props) => {
  const {invalidate} = useCampaignsApi()

  const {isUseOperationForResume} = useExp3('restapp_promotion')

  const cpcResumeUrl = isUseOperationForResume
    ? '/4.0/restapp-front/marketing/v1/ad/resume'
    : '/4.0/restapp-front/marketing/v1/ad/create-bulk'

  const {isLoading, mutate} = useApiMutation(
    {
      url: campaign.campaignType === 'cpc' ? cpcResumeUrl : '/4.0/restapp-front/marketing/v1/ad/start',
      method: 'POST'
    },
    {
      onSettled,
      onSuccess: () => {
        invalidate()

        Snackbar.success({
          text: PROMOTION_SUCCESS.enable
        })
      },
      onError: (errorResponse) => {
        Snackbar.error({
          text: errorResponse.responseData?.message || ERROR_PROMOTION.CANNOT_ENABLE_PROMOTION
        })
      }
    }
  )

  const mutateCPCWithCreate =
    campaign.campaignType === CampaignType.cpc && !isUseOperationForResume && directToken
      ? () =>
          mutate({
            body: {
              places: params
            },
            headers: {
              Authorization: directToken
            }
          })
      : undefined

  const advertId = campaign.advertId
  const campaignId = campaign.campaignId
  const mutateCPC =
    campaign.campaignType === CampaignType.cpc && isUseOperationForResume && advertId && campaignId && directToken
      ? () =>
          mutate({
            body: {
              advert_id: advertId,
              campaign_id: campaignId,
              campaign_type: CampaignType.cpc
            },
            headers: {
              Authorization: directToken
            }
          })
      : undefined

  const cpmAdvertId = campaign.cpmAdvertId
  const mutateCPM =
    campaign.campaignType === CampaignType.cpm && cpmAdvertId && directToken
      ? () =>
          mutate({
            body: {
              campaigns: [
                {
                  campaign_id: cpmAdvertId,
                  campaign_type: campaign.campaignType
                }
              ]
            },
            headers: {
              Authorization: directToken
            }
          })
      : undefined

  return {
    isLoading,
    handleResumeCampaign: mutateCPCWithCreate || mutateCPC || mutateCPM
  }
}

export default useCampaignResume
