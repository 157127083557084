import {useExp3} from '@eda-restapp/configs'
import {usePermission} from '@eda-restapp/permissions'

import {useOldHeavyPlaces} from '@restapp/core-places'

import {useSubscriptions} from './useSubscriptions'

export function useSubscriptionButton() {
  const hasAccess = usePermission(['permission.tools.common', 'permission.tools.subscription.common'])
  const {enabledSubscription} = useExp3('restapp_tools')

  const {places} = useOldHeavyPlaces()
  const {data} = useSubscriptions(
    {placesIds: places.map((place) => place.id)},
    {enabled: Boolean(places.length) && enabledSubscription}
  )

  return {
    isShow: hasAccess && Boolean(data?.subscriptions.length)
  }
}
