import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {WidgetState} from '../../types'

const initialState: WidgetState = {
  showState: {}
}

const communicationsWidgetSlice = createSlice({
  name: 'communicationsWidget',
  initialState,
  reducers: {
    setWidgetShowState: (state: WidgetState, action: PayloadAction<{id: string; showState: boolean}>) => {
      const {id, showState} = action.payload
      state.showState[id] = showState
    }
  }
})

export const {setWidgetShowState} = communicationsWidgetSlice.actions
export const communicationsWidgetReducer = communicationsWidgetSlice.reducer
