import {useExp3} from '@eda-restapp/configs'
import {useEffect, type FC, type ReactNode} from 'react'

import {useSidebar} from '../../hooks'

const X_SWIPE_THRESHOLD = 120
const Y_SWIPE_THRESHOLD = 120

const SwipeHandler: FC<{children?: ReactNode}> = ({children}) => {
  const {setVisibility} = useSidebar()
  const {disable_swipe} = useExp3('restapp_common')

  useEffect(() => {
    if (disable_swipe) {
      return
    }

    let swipeStartX: number | undefined
    let swipeStartY: number | undefined

    const handleTouchStart = (event: TouchEvent) => {
      swipeStartX = undefined
      swipeStartY = undefined

      if (event.target instanceof Element && !event.target.closest('[data-swipe-disable]')) {
        swipeStartX = event.changedTouches[0].pageX
        swipeStartY = event.changedTouches[0].pageY
      }
    }

    const handleTouchEnd = (event: TouchEvent) => {
      if (swipeStartX == null || swipeStartY == null) {
        return
      }

      const swipeDistanceX = event.changedTouches[0].pageX - swipeStartX
      const swipeDistanceY = event.changedTouches[0].pageY - swipeStartY

      if (Math.abs(swipeDistanceY) > Y_SWIPE_THRESHOLD) {
        return // есть достаточно большой вертикальный свайп - скипаем
      }

      if (Math.abs(swipeDistanceX) < X_SWIPE_THRESHOLD) {
        return // горизонтальный свайп недостаточно большой - скипаем
      }

      if (event.target instanceof Element === false) {
        return
      }

      let currentEl: Element | null = event.target
      while (currentEl) {
        if (currentEl.scrollWidth > window.screen.width) {
          return
        }

        currentEl = currentEl.parentElement
      }

      const swipeDirectionX = Math.sign(swipeDistanceX)
      setVisibility(swipeDirectionX > 0)
      event.preventDefault()
    }

    document.body.addEventListener('touchstart', handleTouchStart)
    document.body.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.body.removeEventListener('touchstart', handleTouchStart)
      document.body.removeEventListener('touchend', handleTouchEnd)
    }
  }, [disable_swipe, setVisibility])

  return children
}

export default SwipeHandler
