import {useI18n} from '@eda-restapp/i18n'

import {PLACE_STATUS} from '@restapp/main-places/types/status'

import {getAutostopFinishTime, getPlaceEnabledHumanDate} from '../../../../placeGetters'

type UsePlaceStatuseHintTextProps = {
  placeBlockFinishDate?: string
  placeStatus?: PLACE_STATUS
}

export const useOldPlaceStatusHintText = ({placeBlockFinishDate, placeStatus}: UsePlaceStatuseHintTextProps) => {
  const {t} = useI18n()

  switch (placeStatus) {
    case PLACE_STATUS.SELF_REG_PENDING:
      return t('main-orders.place-status-list.dannie-proveryayutsya', 'Данные проверяются')
    case PLACE_STATUS.SELF_REG_COMPLETE:
      return t('main-orders.place-status-list.restoran-gotov-k-vklyucheniyu', 'Ресторан готов к включению')
    case PLACE_STATUS.DISABLED_BY_AUTOSTOP:
      return getAutostopFinishTime(placeBlockFinishDate) || t('main-orders.place-status-list.otklyuchen', 'Отключен')
    case PLACE_STATUS.DISABLED_BY_VENDOR:
      return (
        (placeBlockFinishDate && getPlaceEnabledHumanDate(placeBlockFinishDate)) ||
        t('main-orders.place-status-list.otklyuchen', 'Отключен')
      )
    case PLACE_STATUS.DISABLED_BY_YANDEX:
      return t('main-orders.place-status-list.zaprosit-vklyuchenie', 'Запросить включение')
    case PLACE_STATUS.CAN_NOT_BE_ENABLED:
      return t('main-orders.place-status-list.viklyuchen', 'Выключен')
    case PLACE_STATUS.ACTIVATION_PENDING:
      return t('main-orders.place-status-list.zapros-na-vklyuchenie-otpravlen', 'Запрос на включение отправлен')
    case PLACE_STATUS.BUSY_MODE_ENABLED:
      return t('main-orders.place-status-list.busy-mode-enabled', 'Высокая загрузка включена')
    default:
      return ''
  }
}
