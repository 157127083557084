import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'
import React from 'react'

import {Tooltip} from '@restapp/shared-ui'

import {useStyles} from './PlaceAlertTrialTooltip.styles'

interface TPlaceAlertTrialTooltipProps {
  validUntilIso?: string
  className?: string
  children: React.ReactElement
  isPaidNextTariff: boolean
}

export const PlaceAlertTrialTooltip: React.FC<TPlaceAlertTrialTooltipProps> = ({
  validUntilIso,
  className,
  children,
  isPaidNextTariff
}) => {
  const {tRaw} = useI18n()
  const {classes: c} = useStyles()

  const getTitle = () =>
    isPaidNextTariff
      ? tRaw(
          'shared-places.place-alert-trial-tooltip.tooltip-title',
          'Бесплатный период подписки заканчивается {dateEnd}. С {date} продлим подписку автоматически на стандартных условиях',
          {
            dateEnd: <b>{moment(validUntilIso).subtract(1, 'days').format('D MMMM')}</b>,
            date: <b>{moment(validUntilIso).format('D MMMM')}</b>
          }
        )
      : tRaw(
          'shared-places.place-alert-trial-tooltip.tooltip-title-from-trial',
          'Бесплатный период подписки заканчивается {date}.',
          {
            date: <b>{moment(validUntilIso).subtract(1, 'days').format('D MMMM')}</b>
          }
        )

  return (
    <Tooltip className={className} classes={{tooltip: c.tooltip}} title={getTitle()}>
      {children}
    </Tooltip>
  )
}
