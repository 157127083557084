import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'

import {selectPool} from '../selectors'
import {readOnePoolNotification} from '../slice'
import type {NotificationType} from '../types'

export const useNotifications = () => {
  const dispatch = useDispatch()

  const poolModel = useSelector(selectPool)

  const markReadMutation = useApiMutation({
    url: '/4.0/restapp-front/places/v1/notifications/log-status',
    method: 'POST'
  })

  const markRead = useCallback(
    (id: string) => {
      markReadMutation.mutate({
        body: {
          id,
          service: 'notification'
        }
      })
      dispatch(readOnePoolNotification(id))
    },
    [dispatch, markReadMutation]
  )

  return useMemo(
    () => ({
      notifications: poolModel.notificationsPool.slice().sort(defaultNotificationsSort),
      markRead
    }),
    [markRead, poolModel.notificationsPool]
  )
}

function defaultNotificationsSort(a: NotificationType, b: NotificationType) {
  if (a.info.important !== b.info.important) {
    return Number(b.info.important) - Number(a.info.important)
  }

  if (a.info.priority !== b.info.priority) {
    return b.info.priority - a.info.priority
  }

  return new Date(b.created).getTime() - new Date(a.created).getTime()
}
