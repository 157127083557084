import type {FormikErrors} from 'formik'
import * as pointer from 'json-pointer'

import type {ValidationError} from '@restapp/shared-api'

interface Options {
  prefix?: string
}

export default function transformApiErrors<T>(
  apiErrors: ValidationError[],
  {prefix}: Options = {}
): {
  errors: string[]
  fieldErrors: FormikErrors<T>
} {
  const errors: string[] = []
  const allFieldErrors: object = {}

  for (const {source, message} of apiErrors) {
    if (!source) {
      if (message) {
        errors.push(message)
      }

      continue
    }

    try {
      pointer.set(allFieldErrors, source.pointer, message)
    } catch {
      // Просто пропускаем невалидные поинтеры
    }
  }

  let fieldErrors: FormikErrors<T> = {}

  try {
    fieldErrors = prefix ? (pointer.get(allFieldErrors, prefix) as FormikErrors<T>) : allFieldErrors
  } catch {
    // Если не получилось взять ошибки - возможно их просто нет
  }

  return {
    errors,
    fieldErrors
  }
}
