import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()({
  paper: {
    boxShadow: '0px 8px 20px rgba(122, 136, 150, 0.2)',
    borderRadius: 16
  }
})

export default useStyles
