import cn from 'classnames'
import React, {type FC} from 'react'

import LineClamp from '../LineClamp'

import {useStyles, type ItemStyles} from './Item.style'

type ItemProps = {
  classes?: Partial<Record<keyof typeof ItemStyles, string>>
  className?: string
  children?: React.ReactNode
  disabled?: boolean
  icon?: React.ReactNode
  title: string
  subTitle: string
  controls?: JSX.Element
}

export const Item: FC<ItemProps> = ({children, classes, className, disabled, title, subTitle, icon, controls}) => {
  const {classes: c} = useStyles(undefined, {props: {classes}})

  return (
    <div className={cn(c.root, className)}>
      {!!icon && <div className={cn(c.icon, disabled && c.iconDisabled)}>{icon}</div>}
      <div className={cn(c.text, disabled && c.textDisabled, icon && c.widthLimit)}>
        <LineClamp lineClamp={1} className={c.title}>
          <span data-testid={'item-title' /* Item | Title */}>{title}</span>
        </LineClamp>
        <LineClamp lineClamp={1}>{subTitle}</LineClamp>
        {children}
      </div>
      {controls}
    </div>
  )
}
